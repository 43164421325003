import React, { useMemo } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { defaultSelectedViolationFilters } from "../../constants/violationFiltersConstants";
import { reliabilityArialLabels } from "../../reliabilityArialLabels";

import { separateFilterTypes } from "./filtersStateHandler/filterStateHadlerUtils";
import { useResetAllFilters } from "./filtersStateHandler/useResetAllFilters";

import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

const HiddenButton = styled(Button)<{ $isHidden: boolean }>`
  ${({ $isHidden }) =>
    $isHidden &&
    `
  opacity: 0;
  pointer-events: none;
`}
`;

const { resetAllButton: resetAllAriaLabel } = reliabilityArialLabels.filters;

const getTotalFilterValuesLength = (filters: FilterOptionInUrl[]) =>
  filters.reduce((acc, filter) => {
    return acc + filter.values.length;
  }, 0);

export const ResetAllButton: React.FC = () => {
  const filtersDataInUrl = useFiltersDataInUrl();
  const resetAllFilters = useResetAllFilters();

  const totalSelectedOptions = useMemo(() => {
    return filtersDataInUrl
      ? Object.entries(filtersDataInUrl).reduce((acc, [category, filters]) => {
          const { filters: relevantFilters, searchOptions } =
            separateFilterTypes(filters);

          const filterDefaults = relevantFilters.filter((filter) => {
            if (defaultSelectedViolationFilters[category]) {
              const defaultValues =
                defaultSelectedViolationFilters[category][0].values;
              filter.values = filter.values.filter(
                (value: string) => !defaultValues.includes(value)
              );
            }
            return true;
          });

          const totalSearchOptions = getTotalFilterValuesLength(searchOptions);
          const totalFilterValues = getTotalFilterValuesLength(filterDefaults);
          return acc + totalFilterValues + totalSearchOptions;
        }, 0)
      : 0;
  }, [filtersDataInUrl]);

  return (
    <HiddenButton
      variant={"text"}
      $isHidden={totalSelectedOptions === 0}
      onClick={resetAllFilters}
      aria-label={resetAllAriaLabel}
    >
      {`Reset all (${totalSelectedOptions})`}
    </HiddenButton>
  );
};
