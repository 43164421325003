import React, { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";
import useUserMetadata from "../../../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { PreInstallError } from "../steps.styles";
import useAnalyticsApi from "../../../../../../shared/context/analyticsProvider";
import { useUserLoginInfo } from "../../../../../../shared/hooks/useUserMetadata/useUserLoginInfo";

import ClusterInstalled from "./ClusterInstalled";
import InstallationError from "./InstallationError";
import WaitingForConnection from "./WaitingForConnection";

const contactMessageWaitingTime = 5000;
const installationWaitingTime = 90000;

const StepCheckConnection: React.FC<{
  isAgentInstalled: boolean;
  clusterName: string | null;
  closeModalCallback: () => void;
  errorMessage: string;
}> = ({ isAgentInstalled, clusterName, closeModalCallback, errorMessage }) => {
  const [showHelpMessage, setShowHelpMessage] = useState(false);
  const [installError, setInstallError] = useState("");
  const { showNewMessage } = useIntercom();
  const installationFailedMessage =
    "We can't map and display the services on your cluster.\n Talk to us, we'll help you";

  const { accountOrigin } = useUserMetadata();
  const user = useUserLoginInfo();
  const analytics = useAnalyticsApi();
  useEffect(() => {
    let contactUsMessageTimer: NodeJS.Timeout, installWaitTimer: NodeJS.Timeout;
    if (!user?.email) return;
    if (!isAgentInstalled) {
      contactUsMessageTimer = setTimeout(() => {
        setShowHelpMessage(true);
      }, contactMessageWaitingTime);
      installWaitTimer = setTimeout(() => {
        setInstallError(installationFailedMessage);
      }, installationWaitingTime);
    } else {
      analytics.dispatchEventViaBackend(
        AnalyticEvents.Integrations.Kubernetes.KomodorAgentConnected,
        { email: user.email },
        true
      );
      analytics.identifySegment(
        {
          AgentConnected: true,
          userId: user?.email,
        },
        "identify",
        user.email
      );
      setShowHelpMessage(false);
      setInstallError("");
    }
    return () => {
      clearTimeout(contactUsMessageTimer);
      clearTimeout(installWaitTimer);
    };
  }, [isAgentInstalled, user?.email, installError, analytics]);
  return errorMessage ? (
    <PreInstallError>{errorMessage}</PreInstallError>
  ) : (
    <>
      {isAgentInstalled ? (
        <ClusterInstalled
          closeModalCallback={closeModalCallback}
          clusterName={clusterName}
        />
      ) : installError ? (
        <InstallationError
          accountOrigin={accountOrigin}
          installError={installError}
          showHelpMessage={showNewMessage}
          setInstallError={setInstallError}
        />
      ) : (
        <WaitingForConnection
          showHelpMessage={showHelpMessage}
          showHelpMessages={showNewMessage}
        />
      )}
    </>
  );
};

export default StepCheckConnection;
