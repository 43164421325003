import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { add, format, subYears } from "date-fns";
import { AccountPlan as AccountPlanType } from "komodor-types";

import { useGetNodesCounts } from "@/shared/hooks/accounts-service/client/customers-pricing/useGetNodesCounts";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { ClusterTable } from "@/components/Settings/General/NodesCount/ClustersTable";
import { PlanContainer } from "@/components/Settings/styles";
import PlanTitle from "@/components/Settings/General/AccountPlan/PlanTitle";
import QuotaInformation from "@/components/Settings/General/AccountPlan/QuotaInformation";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import useGetAccountPlanData from "@/components/Freemium/useGetAccountPlanData";
import useTiersVisible from "@/components/Freemium/useTiersVisible";
import useEventsLimit from "@/components/Freemium/useEventsLimit";
import { openPlansPage } from "@/shared/utils/freemium";

export const PlanDetails: React.FC = () => {
  const { trialEndAt, accountPlan } = useUserMetadata();
  const planData = useGetAccountPlanData();
  const isTiersVisible = useTiersVisible();
  const eventsLimitsData = useEventsLimit();

  if (!(planData && eventsLimitsData && isTiersVisible)) {
    return null;
  }

  const { eventsTotalSum, eventsCountStartEpoch } = eventsLimitsData;

  if (!(eventsCountStartEpoch && accountPlan)) {
    return null;
  }

  const nextCountCycleDateString = () => {
    const nextCountDate = add(new Date(eventsCountStartEpoch), { months: 1 });
    return format(nextCountDate, "MM/dd/yyyy");
  };

  const showUpgrade =
    accountPlan === AccountPlanType.free ||
    accountPlan === AccountPlanType.trial;

  return (
    <Stack direction="column">
      <Typography variant="body1">Your current plan</Typography>
      <PlanContainer>
        <PlanTitle trialEnd={trialEndAt} accountPlan={accountPlan} />
        <Divider />
        <QuotaInformation
          accountPlan={accountPlan}
          nextCountCycleDateString={nextCountCycleDateString()}
          clustersLimit={planData?.clustersLimit ?? null}
          eventsLimit={planData?.eventsLimit ?? null}
          usersLimit={planData?.usersLimit ?? null}
          eventsTotalSum={eventsTotalSum ?? 0}
        />
        {showUpgrade && <Button onClick={openPlansPage}>Upgrade</Button>}
      </PlanContainer>
    </Stack>
  );
};

export const NodeCount: React.FC = () => {
  const oneYearBack = useMemo(() => {
    const currentDate = new Date();
    return subYears(currentDate, 1).toISOString();
  }, []);

  const {
    data: nodesCounts,
    isFetching,
    isError,
  } = useGetNodesCounts({
    collectedFrom: String(oneYearBack.valueOf()),
  });

  const { showClusterTable } = useOverridableFlags();
  if (!showClusterTable) {
    return;
  }
  return (
    <Stack direction="column">
      <Typography variant="h3">Nodes Count</Typography>
      <ClusterTable
        data={nodesCounts || []}
        isFetching={isFetching}
        isError={isError}
      />
    </Stack>
  );
};
