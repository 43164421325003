import React, { useState, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";

import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { useStateInSearchParams } from "../../../shared/hooks/state";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import ResourceSelector from "../ResourceSelector";
import { Grid as _Grid } from "../common";
import type { ServiceYamlFile, YamlFile } from "../common/types";
import {
  COMPARISON_COMPARAND_PARAM_KEY,
  COMPARISON_COMPARATOR_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";

import {
  equalByContent,
  findServiceBy,
  refresh,
  replaceElementAt,
} from "./utils";

const Grid = styled(_Grid)`
  height: 100%;
`;

interface ComparisonControllerProps {
  clusters: string[];
  services: ServiceInfo[];
  servicesByCluster: { [cluster: string]: ServiceInfo[] };
  yamls: (ServiceYamlFile | undefined)[];
  setYamls: (yamls: (ServiceYamlFile | undefined)[]) => void;
}

// TODO: Consider unifying the `comparatorId` and `comparandId` variables within a single array.
// This way, we can avoid all the `if` statements and use loops to set or perform checks on their values.
// When converting the variables to an array, make sure that the value in the 0 (zero) index
// maps represents the comparator, while the element in 1 (one) index is the comparand.
const ComparisonController: React.FC<ComparisonControllerProps> = ({
  clusters,
  services,
  servicesByCluster,
  yamls,
  setYamls: setServiceYamls,
}) => {
  const [selectedClusters, setSelectedClusters] = useState<
    (string | undefined)[]
  >(new Array(2).fill(undefined));
  const [configFiles, setConfigFiles] = useState<(YamlFile | undefined)[]>(
    new Array(2).fill(undefined)
  );
  const [comparatorId, setComparatorId] = useStateInSearchParams(
    COMPARISON_COMPARATOR_PARAM_KEY
  );
  const [comparandId, setComparandId] = useStateInSearchParams(
    COMPARISON_COMPARAND_PARAM_KEY
  );
  const [expectDefaultValue, setExpectDefaultValue] = useState(true);
  const comparatorServiceHealthy = useMemo(
    () =>
      services.find((service) => service.id === comparatorId)?.healthy ?? false,
    [comparatorId, services]
  );
  const comparandServiceHealthy = useMemo(
    () =>
      services.find((service) => service.id === comparandId)?.healthy ?? false,
    [comparandId, services]
  );

  useEffect(() => {
    const yamlsAreOutdated = configFiles.some(
      (configFile, index) => configFile?.content !== yamls[index]?.content
    );
    if (!yamlsAreOutdated) {
      return;
    }

    setServiceYamls(
      refresh(yamls, configFiles, [
        comparatorServiceHealthy,
        comparandServiceHealthy,
      ])
    );
  }, [
    configFiles,
    setServiceYamls,
    yamls,
    comparatorServiceHealthy,
    comparandServiceHealthy,
  ]);

  useEffect(() => {
    setExpectDefaultValue(false);
  }, []);

  const handleClusterSelection = useCallback(
    (newlySelectedCluster: string, selectorIndex: number) => {
      if (selectedClusters[selectorIndex] !== newlySelectedCluster) {
        setSelectedClusters((prevSelectedClusters) =>
          replaceElementAt(
            selectorIndex,
            prevSelectedClusters,
            newlySelectedCluster
          )
        );
      }
    },
    [selectedClusters]
  );

  const handleNamespaceSelection = useCallback(
    (namespace: string, selectorIndex: number) => {
      const { id: newlySelectedServiceId } =
        findServiceBy(selectedClusters[selectorIndex], namespace, services) ??
        {};

      if (!newlySelectedServiceId) return;

      if (selectorIndex === 0 && newlySelectedServiceId !== comparatorId) {
        setComparatorId(newlySelectedServiceId, true);
        setComparandId(null, true);
      } else if (
        selectorIndex === 1 &&
        newlySelectedServiceId !== comparandId
      ) {
        setComparandId(newlySelectedServiceId, true);
      }
    },
    [
      comparatorId,
      comparandId,
      selectedClusters,
      services,
      setComparatorId,
      setComparandId,
    ]
  );

  const handleYamlAddition = useCallback(
    (addedConfigFile: YamlFile, selectorIndex: number) => {
      if (!equalByContent(configFiles[selectorIndex], addedConfigFile)) {
        setConfigFiles((prevConfigFiles) =>
          replaceElementAt(selectorIndex, prevConfigFiles, addedConfigFile)
        );
      }
    },
    [configFiles, setConfigFiles]
  );

  const comparator = useMemo(
    () => services.find((service) => service.id === comparatorId),
    [services, comparatorId]
  );
  const comparand = useMemo(
    () => services.find((service) => service.id === comparandId),
    [services, comparandId]
  );

  const withAnalyticsEventDispatchOnComparandChange =
    (handler: (namespace: string) => void) => (namespace: string) => {
      if (namespace !== comparand?.env) {
        dispatchEvent(
          AnalyticEvents.ComparisonView.Comparison_view_comparand_selected
        );
      }

      return handler(namespace);
    };

  const possibleComparatorNamespaces = useMemo(
    () =>
      selectedClusters[0]
        ? servicesByCluster[selectedClusters[0]].map(({ env }) => env)
        : [],
    [selectedClusters, servicesByCluster]
  );

  const possibleComparandNamespaces = useMemo(
    () =>
      selectedClusters[1]
        ? servicesByCluster[selectedClusters[1]]
            ?.filter((service) => service.id !== comparatorId)
            .map(({ env }) => env)
        : [],
    [selectedClusters, servicesByCluster, comparatorId]
  );

  return (
    <Grid gridTemplateColumns="1fr 1fr" gridColumnGap="1rem">
      <ResourceSelector
        expectDefaultValue={expectDefaultValue}
        clusters={clusters}
        namespaces={possibleComparatorNamespaces}
        selectedCluster={comparator?.k8sCluster ?? selectedClusters[0]}
        selectedNamespace={comparator?.env}
        selectedServiceName={comparator?.title}
        selectedKind={comparator?.kind ?? undefined}
        onClusterSelection={(cluster: string) =>
          handleClusterSelection(cluster, 0)
        }
        onNamespaceSelection={(namespace: string) =>
          handleNamespaceSelection(namespace, 0)
        }
        onServiceConfigFetch={(configFile: YamlFile) =>
          handleYamlAddition(configFile, 0)
        }
      />
      <ResourceSelector
        expectDefaultValue={expectDefaultValue}
        clusters={clusters}
        namespaces={possibleComparandNamespaces}
        selectedCluster={comparand?.k8sCluster ?? selectedClusters[1]}
        selectedNamespace={comparand?.env}
        selectedServiceName={comparand?.title}
        selectedKind={comparand?.kind ?? undefined}
        onClusterSelection={(cluster: string) =>
          handleClusterSelection(cluster, 1)
        }
        onNamespaceSelection={withAnalyticsEventDispatchOnComparandChange(
          (namespace: string) => handleNamespaceSelection(namespace, 1)
        )}
        onServiceConfigFetch={(configFile: YamlFile) =>
          handleYamlAddition(configFile, 1)
        }
      />
    </Grid>
  );
};

export default ComparisonController;
