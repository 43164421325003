import { useMemo } from "react";
import lodash from "lodash";

import { useNodeTerminationsByOwnerKomodorUidsFromAPIQuery } from "../../../EventGroup/nodeEvent/useNodeChangeEvents";
import { groupNodeChangeEvents } from "../../../EventGroup/nodeEvent/NodeChangeGroup";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";
import Pod from "../../../../ResourceView/resources/pod";
import { buildServiceId } from "../../../../../shared/utils/serviceHelpers";
import useUserMetadata from "../../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { useMapPodToService } from "../../../../../shared/hooks/resources-api/client/komodor-services/useMapPodToService";
import { KomodorServicePodMapParamsOwnerTypeEnum } from "../../../../../generated/resourcesApi";
import { buildKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";

export const useGetCorrelatedNodeTerminations = (
  resource: Pod | undefined,
  timeWindow: Pick<TimeWindow, "start" | "end">
) => {
  const podUid = useMemo(() => {
    if (
      resource?.name &&
      resource?.cluster &&
      resource?.namespace &&
      resource?.kind
    ) {
      return buildKomodorUid({
        resourceName: resource?.name ?? "",
        clusterName: resource?.cluster ?? "",
        namespace: resource?.namespace ?? "",
        kind: resource?.kind ?? "",
      });
    }
    return "";
  }, [resource]);

  const isValidPodUid = useMemo(() => podUid?.length > 5, [podUid]);

  const { data: podServicesMap } = useMapPodToService(
    {
      podsKomodorUids: [podUid],
      ownerType: KomodorServicePodMapParamsOwnerTypeEnum.WithRootOwner,
    },
    isValidPodUid
  );

  const ownerKomodorUid = useMemo(
    () => lodash.get(podServicesMap?.data, podUid),
    [podUid, podServicesMap?.data]
  );

  const { accountName } = useUserMetadata();

  const serviceIds = useMemo(
    () => [
      buildServiceId(
        accountName,
        resource?.cluster ?? "",
        resource?.namespace ?? "",
        resource?.name ?? ""
      ),
    ],
    [accountName, resource]
  );

  const [nodeTerminations, isLoadingNodeTerminations] =
    useNodeTerminationsByOwnerKomodorUidsFromAPIQuery(
      [ownerKomodorUid],
      timeWindow
    );

  const nodeTerminationsGroups = groupNodeChangeEvents(
    nodeTerminations?.flatMap((e) => e) ?? [],
    serviceIds[0]
  );

  return {
    nodeTerminationsGroups,
    isLoadingNodeTerminations,
  };
};
