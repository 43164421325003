import { useMemo } from "react";

import { useAddCreatedAtFilter } from "./useAddCreatedAtFilter";
import { useAddImpactGroupTypeFilter } from "./useAddImpactGroupTypeFilter";

import {
  GenericViolationFilterCategory,
  ViolationCountBy,
} from "@/generated/reliabilityApi";
import { useFilterObjectListFromUrl } from "@/shared/hooks/filtersDataInUrl/useFilterObjectListFromUrl";

export const useFiltersInUrlAsGenericViolationFilterCategory = (
  filterCategory: ViolationCountBy
) => {
  const addCreatedAtFilter = useAddCreatedAtFilter();

  const impactGroupTypeFilter = useAddImpactGroupTypeFilter();

  const filterObjectListFromUrl = useFilterObjectListFromUrl(filterCategory);

  return useMemo<GenericViolationFilterCategory[]>(() => {
    return filterObjectListFromUrl
      .map((filterCategory) => {
        if (filterCategory.category === ViolationCountBy.CreatedAt) {
          // add time window filter with updated timestamps
          return addCreatedAtFilter({
            filter: {
              values: filterCategory.values,
              operator: filterCategory.operator,
            },
            filterCategory: filterCategory.category,
          });
        }
        return {
          category: filterCategory.category,
          operator: filterCategory.operator,
          value: filterCategory.values,
        };
      })
      .filter(
        (filterCategory): filterCategory is GenericViolationFilterCategory =>
          !!filterCategory
      )
      .concat(impactGroupTypeFilter)
      .flat();
  }, [addCreatedAtFilter, filterObjectListFromUrl, impactGroupTypeFilter]);
};
