import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Code24, InfoCircle24 } from "@komodorio/design-system/icons";
import { Status } from "@komodorio/design-system/komodor-ui";
import { noop } from "lodash";
import { parseISO } from "date-fns";

import { AgentDrawerKeyValue } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawerKeyValue";
import { agentDataGetters } from "@/pages/organization-settings/account/AgentsPage/gridHelpers";
import { APIKeyCell } from "@/pages/organization-settings/account/AgentsPage/components";
import { Kubernetes_Agent_Info } from "@/generated/graphql";
import { LazyEditor } from "@/components/common/LazyEditor";
import { AgentDrawerSectionHeader } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawerSectionHeader";
import useDateFormatter from "@/shared/hooks/useDateFormatter";
import { ariaLabels } from "@/pages/organization-settings/account/AgentsPage/constants";

const MARGIN_LEFT = "58px";

export interface AgentDrawerProps {
  agent: Kubernetes_Agent_Info | null;
  onClose: () => void;
}

export const AgentDrawer: React.FC<AgentDrawerProps> = ({ agent, onClose }) => {
  const stringifiedConfiguration = useMemo(
    () => JSON.stringify(agent?.configuration || {}, null, 2),
    [agent]
  );

  const { format } = useDateFormatter();
  const { createdAt, lastSeenAlive } = useMemo(() => {
    return {
      createdAt: agent ? format(parseISO(agent.createdAt)) : "",
      lastSeenAlive: agent ? format(parseISO(agent.lastSeenAlive)) : "",
    };
  }, [agent, format]);

  if (!agent) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={!!agent}
      onClose={onClose}
      aria-label={ariaLabels.drawer}
    >
      <Stack sx={{ width: 800, padding: 2 }} direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <AgentDrawerSectionHeader Icon={InfoCircle24} title="Details" />
          <Grid
            container
            sx={{ marginLeft: MARGIN_LEFT, width: "auto" }}
            rowGap={2}
            columnGap={2}
            columns={2}
          >
            <AgentDrawerKeyValue
              title="Status"
              ValueComponent={
                <Status
                  color={agent.inactive ? "error" : "success"}
                  label={agent.inactive ? "Inactive" : "Active"}
                />
              }
              value={agent.clusterName || ""}
            />
            <AgentDrawerKeyValue
              title="Cluster Name"
              value={agent.clusterName || ""}
            />
            <AgentDrawerKeyValue
              title="Agent Version"
              value={agentDataGetters.getAgentVersion({
                row: agent,
              })}
            />
            <AgentDrawerKeyValue title="Agent ID" value={agent.id || ""} />
            <AgentDrawerKeyValue title="Created At" value={createdAt} />
            <AgentDrawerKeyValue
              title="Last Heartbeat Recieved"
              value={lastSeenAlive}
            />
            <AgentDrawerKeyValue
              title="Chart Version"
              value={agentDataGetters.getChartVersion({
                row: agent,
              })}
            />
            <AgentDrawerKeyValue
              title="K8s Version"
              value={agentDataGetters.getK8sVersion({
                row: agent,
              })}
            />
            <AgentDrawerKeyValue
              title="API Key"
              value={agent.installationId}
              ValueComponent={
                <APIKeyCell row={agent} visibility="visible" refetch={noop} />
              }
            />
          </Grid>
        </Stack>
        <Stack direction="column" rowGap={1}>
          <AgentDrawerSectionHeader Icon={Code24} title="JSON" />
          <Box sx={{ marginLeft: MARGIN_LEFT }}>
            <LazyEditor
              value={stringifiedConfiguration}
              readOnly
              width="100%"
              height="50vh"
              mode="json"
              variant="dark"
            />
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  );
};
