import React, { useCallback, useMemo, useState } from "react";
import { Plus16 } from "@komodorio/design-system/icons";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { DataGridPro, GridPaginationModel } from "@mui/x-data-grid-pro";

import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import { ACCOUNT_ADMIN_RBAC_ROLE } from "../Roles/Roles";
import { Buttons, Header } from "../styles";
import { ButtonSupportFreemium } from "../../Freemium/Buttons";
import { restrictionsNames } from "../../Freemium/Restrictions";
import { useGetUsers } from "../../../shared/hooks/auth-service/client/users/useGetUsers";
import { User } from "../../../generated/auth";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import BulkUsersFormModal from "./BulkUsersFormModal";
import DeleteUserModal from "./DeleteUserModal";
import UserFormModal from "./UserFormModal";

import { RestoreUserModal } from "@/components/Settings/Users/RestoreUserModal";
import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";
import { getColumnDefinitions } from "@/components/Settings/Users/gridHelpers";
import { useAgentsPage } from "@/pages/organization-settings/account/AgentsPage/useAgentsPage";
import {
  DEFAULT_PAGINATION_MODEL,
  PAGE_SIZE_OPTIONS,
} from "@/pages/organization-settings/account/AgentsPage/constants";
import { getPaginationModel } from "@/components/ClustersView/utils";

const Users: React.FC = () => {
  const [search, setSearch] = useState("");
  const { data: users, refetch } = useGetUsers();
  const isOnlyOneAccountAdmin =
    users &&
    users.filter((u) =>
      u.rbacRoles?.some((role) => role.name === ACCOUNT_ADMIN_RBAC_ROLE)
    ).length === 1;

  const { showTemporaryRoles: showTemporaryRolesFF } = useOverridableFlags();

  const [openAddUser, setOpenAddUser] = useState(false);
  const [openBulkAddUsers, setOpenBulkAddUsers] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const [userToRestore, setUserToRestore] = useState<User>();
  const { ref, width } = useAgentsPage();

  const [filters, setFilters] = useState<{ limit: number; offset: number }>({
    limit: DEFAULT_PAGINATION_MODEL.pageSize,
    offset: DEFAULT_PAGINATION_MODEL.page,
  });

  const paginationModel = useMemo<GridPaginationModel>(
    () => getPaginationModel(filters.limit, filters.offset),
    [filters]
  );

  const handlePaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setFilters({
        ...filters,
        limit: newPaginationModel.pageSize,
        offset: newPaginationModel.page * newPaginationModel.pageSize,
      });
    },
    [filters]
  );

  const { canManageUsers } = useHasPermissions();

  const filteredUsers = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return users
      ? sortBy(
          users.filter((u) => {
            return [u.displayName, u.email].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          [(u) => u.deletedAt !== null, (u) => u.displayName]
        )
      : [];
  }, [users, search]);

  const columns = useMemo(() => {
    const toReturn = getColumnDefinitions({
      onEdit: setUserToEdit,
      onDelete: setUserToDelete,
      onRestore: setUserToRestore,
    });

    if (!showTemporaryRolesFF) {
      return toReturn.filter((column) => column.field !== "tempRoles");
    }

    return toReturn;
  }, [showTemporaryRolesFF]);

  const tableContent = useMemo(() => {
    return (
      <Paper variant="elevation" elevation={1} sx={{ width, overflow: "auto" }}>
        <DataGridPro
          disableVirtualization
          pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          columns={columns}
          disableColumnMenu
          rows={filteredUsers.map((user) => {
            return { disabled: !!user.deletedAt, ...user };
          })}
        />
      </Paper>
    );
  }, [
    filteredUsers,
    width,
    paginationModel,
    handlePaginationModelChange,
    columns,
  ]);

  return (
    <SettingsViewLayoutWrapper title={"Users"}>
      <Stack ref={ref}>
        <Header>
          <SearchField
            size="medium"
            width="15rem"
            placeholder="Search"
            value={search}
            showCloseIcon={true}
            ariaLabel="Search users"
            onSearch={(value) => setSearch(value)}
          />
          {canManageUsers && (
            <Buttons>
              <ButtonSupportFreemium
                variant="primary"
                icon={Plus16}
                onClick={() => setOpenBulkAddUsers(true)}
                freemiumKey={restrictionsNames.USERS}
              >
                Bulk add
              </ButtonSupportFreemium>
              <ButtonSupportFreemium
                variant="primary"
                icon={Plus16}
                onClick={() => setOpenAddUser(true)}
                freemiumKey={restrictionsNames.USERS}
              >
                Add user
              </ButtonSupportFreemium>
            </Buttons>
          )}
        </Header>
        <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
        {tableContent}
        {openBulkAddUsers && (
          <ValidationsProvider>
            <BulkUsersFormModal
              open
              handleClose={() => setOpenBulkAddUsers(false)}
              refreshUsers={refetch}
            />
          </ValidationsProvider>
        )}
        {openAddUser && (
          <ValidationsProvider>
            <UserFormModal
              open
              handleClose={() => setOpenAddUser(false)}
              refreshUsers={refetch}
            />
          </ValidationsProvider>
        )}
        {!!userToEdit && (
          <ValidationsProvider>
            <UserFormModal
              open
              handleClose={() => setUserToEdit(undefined)}
              user={userToEdit}
              refreshUsers={refetch}
              isOnlyOneAccountAdmin={isOnlyOneAccountAdmin}
            />
          </ValidationsProvider>
        )}
        {!!userToRestore && (
          <RestoreUserModal
            open
            handleClose={() => setUserToRestore(undefined)}
            user={userToRestore}
            refreshUsers={refetch}
          />
        )}
        {userToDelete && (
          <DeleteUserModal
            open
            handleClose={() => setUserToDelete(undefined)}
            user={userToDelete}
            refreshUsers={refetch}
          />
        )}
      </Stack>
    </SettingsViewLayoutWrapper>
  );
};

export default Users;
