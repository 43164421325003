import { useCallback } from "react";

import { useOpenPolicyDrawer } from "../../../policies/policiesHooks";
import { MetadataItemContent } from "../violationDrawerTypes";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

type UseAddPolicyLinkArgs = {
  policyName?: string;
  policyId?: string;
};

export const useAddPolicyLink = ({
  policyName,
  policyId,
}: UseAddPolicyLinkArgs) => {
  const onPolicyNameClick = useOpenPolicyDrawer();
  const { showReliabilityPolicies } = useOverridableFlags();

  return useCallback(
    (metadataItems: MetadataItemContent[]) => {
      if (showReliabilityPolicies && policyName && policyId) {
        metadataItems.push({
          label: "policy",
          value: policyName,
          onClick: () => onPolicyNameClick(policyId),
        });
      }
    },
    [onPolicyNameClick, policyId, policyName, showReliabilityPolicies]
  );
};
