import React from "react";
import { parseISO } from "date-fns";
import { PagerDutyEventData } from "komodor-types";

import { Event_Pagerduty } from "../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PagerdutyEventDetails from "../../common/ProcessList/details/PagerDutyEventDetails";
import { pinkBrand, pinkForUI } from "../../../Colors";

import AlertScatterShape from "./shapes/alert.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupEventsByKey, mapAndSort, withServices } from "./groupEvents";

import EventGroup, { EventBase } from ".";

export const pagerDutyType = "PagerDuty";

export type PagerDutyEvent = EventBase &
  Omit<Event_Pagerduty, keyof EventBase | "services" | "properties"> & {
    properties?: PagerDutyEventData;
  };

const ifString = (v?: unknown) => (typeof v === "string" ? v : "");

const toPagerDutyEvent = (
  e: Event_Pagerduty,
  serviceId: string
): PagerDutyEvent => ({
  ...e,
  serviceId,
  eventTime: parseISO(e.eventTime),
  type: pagerDutyType,
  properties: e.properties as PagerDutyEventData | undefined,
});

export default class PagerDutyEventGroup implements EventGroup {
  readonly backgroundColor = pinkForUI;
  readonly fillColor = pinkBrand;
  readonly icon = AlertScatterShape;

  readonly events;
  readonly id;
  readonly type;
  readonly startTime;
  readonly endTime;
  readonly details = "";
  readonly status;
  readonly serviceId;

  constructor(events: PagerDutyEvent[]) {
    const lastEvent = events[events.length - 1];
    this.id = `${events[0].serviceId}:${events[0].id}`;
    this.type = events[0].type;
    this.startTime = events[0].eventTime;
    this.endTime = lastEvent.eventTime;
    this.status = ifString(lastEvent.properties?.incidentStatus);
    this.serviceId = events[0].serviceId;

    this.events = events;
  }

  get summary(): string {
    return ifString(this.events[0].properties?.incidentTitle);
  }

  renderEventDetails(): JSX.Element {
    return <PagerdutyEventDetails events={this.events} />;
  }
}

export const groupPagerDutyEvents = (
  events: Event_Pagerduty[],
  serviceId: string
): PagerDutyEventGroup[] =>
  groupEventsByKey(
    mapAndSort(withServices(events, serviceId), toPagerDutyEvent),
    (e) => `${e.serviceId}:${e.properties?.incidentUrl ?? ""}`
  ).map((es) => new PagerDutyEventGroup(es));
