import { useMemo } from "react";

import { useResourcesAPIGet } from "../../../../shared/hooks/resources-api/client";
import {
  buildKomodorUid,
  DESC,
} from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { TimeWindow } from "../../../../shared/types/TimeWindow";
import {
  CustomEventResponse,
  EventsApiApiV1CustomEventsSearchGetRequest,
} from "../../../../generated/resourcesApi";
import { CUSTOM_SERVICE_EVENT_SEARCH } from "../../../../shared/hooks/resources-api/requestResponseMaps";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { UseResourceParams } from "../../EventsPagination/types";
import { extractServiceNameFromServiceId } from "../../../../shared/utils/extractServiceNameFromServiceId";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../shared/constants/systemWideServiceId";
import { buildServiceId } from "../../../../shared/utils/serviceHelpers";
import { useAccountName } from "../../../../shared/hooks/useAccountName";
import { useCustomEvents } from "../../../../shared/hooks/resources-api/client/events/customEvents/useCustomEvents";

import { CustomEventWithServiceId } from "./types";
import { eventHasSingleService } from "./utils";

const eventFields: EventsApiApiV1CustomEventsSearchGetRequest["fields"] = [
  "id",
  "clusters",
  "namespaces",
  "servicesNames",
  "details",
  "severity",
  "summary",
  "eventType",
  "eventTime",
];

const useHasCustomEventsPermission = (): boolean => {
  const { showCustomEvents } = useOverridableFlags();
  return !!showCustomEvents;
};

const getCustomEventsParams = (
  timeWindow: Pick<TimeWindow, "start" | "end">
): EventsApiApiV1CustomEventsSearchGetRequest => ({
  order: DESC,
  fromEpoch: timeWindow.start.getTime().toString(),
  toEpoch: timeWindow.end.getTime().toString(),
  fields: eventFields,
});

export const useFetchCustomEvents = ({
  timeWindow,
}: {
  timeWindow: Pick<TimeWindow, "start" | "end">;
}): CustomEventWithServiceId[] => {
  const hasCustomEventsPermission = useHasCustomEventsPermission();
  const { data: customEvents } = useCustomEvents(
    getCustomEventsParams(timeWindow),
    { enabled: hasCustomEventsPermission }
  );

  return useAddServiceIdToCustomEvents(customEvents);
};

type UseFetchCustomServiceEventParams = Omit<
  UseResourceParams,
  "selectedResourceNamesForEvents"
> & { serviceId: string };

export const useFetchCustomServiceEvent = ({
  serviceId,
  clusterName = "",
  namespace = "",
  resourceViewKind,
  timeWindow,
}: UseFetchCustomServiceEventParams): CustomEventWithServiceId[] => {
  const hasCustomEventsPermission = useHasCustomEventsPermission();
  const serviceName = extractServiceNameFromServiceId(serviceId);

  const { data: customEvents } = useResourcesAPIGet(
    CUSTOM_SERVICE_EVENT_SEARCH,
    {
      komodorUids: [
        buildKomodorUid({
          kind: resourceViewKind,
          clusterName,
          namespace,
          resourceName: serviceName,
        }),
      ],
      ...getCustomEventsParams(timeWindow),
    },
    !hasCustomEventsPermission
  );

  return useMemo(() => {
    return (
      customEvents?.data.map<CustomEventWithServiceId>((event) => {
        return { ...event, serviceId };
      }) ?? []
    );
  }, [customEvents?.data, serviceId]);
};

const useAddServiceIdToCustomEvents = (
  customEvents?: CustomEventResponse
): CustomEventWithServiceId[] => {
  const accountName = useAccountName();

  return (
    useMemo(() => {
      return customEvents?.data.map<CustomEventWithServiceId>((event) => {
        let serviceId = SYSTEM_WIDE_SERVICE_ID;

        if (eventHasSingleService(event)) {
          serviceId = buildServiceId(
            accountName,
            event.clusters?.[0] ?? "",
            event.namespaces?.[0] ?? "",
            event.servicesNames?.[0] ?? ""
          );
        }
        return { ...event, serviceId };
      });
    }, [accountName, customEvents?.data]) ?? []
  );
};
