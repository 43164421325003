import { useEffect, useMemo, useState } from "react";

import { useRefetchIntervalFromContext } from "./useRefetchIntervalFromContext";

import { useGetAddonAggregations } from "@/shared/hooks/k8s-add-ons/useGetAddonAggregations";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { Operator } from "@/generated/addonsApi";

const filterCategory = "clusterName";

export const useSetInitialCluster = (): boolean => {
  const [isPaginating, setIsPaginating] = useState(true);

  const [dataInUrl, setDataInUrl] = useStateInUrl();
  const {
    addonType,
    addonEntity,
    setInitialClustersFilter,
    initialClustersFilterWasSet,
    setInitialClustersFilterWasSet,
  } = useAddonContext();

  const hasFiltersInUrl = useMemo(
    () => !!Object.values(dataInUrl.filters ?? []).length,
    [dataInUrl.filters]
  );

  const enableRequest =
    setInitialClustersFilter &&
    !hasFiltersInUrl &&
    !initialClustersFilterWasSet;

  const refetchInterval = useRefetchIntervalFromContext(isPaginating);

  const { data, error, isLoading } = useGetAddonAggregations(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityAggregationsRequest: {
        groupBy: filterCategory,
        aggregation: "count",
        filter: [],
      },
    },
    {
      refetchInterval: isPaginating ? refetchInterval : undefined,
      enabled: enableRequest,
    }
  );

  useEffect(() => {
    if (!setInitialClustersFilter || initialClustersFilterWasSet) return;
    if (data?.data.isPaginating === false) {
      setInitialClustersFilterWasSet(true);
    }
    if (hasFiltersInUrl) {
      setInitialClustersFilterWasSet(true);
      return;
    }
    const hasData = data && data.data.items.length > 0;
    if (hasData) {
      setIsPaginating(false);
      if (enableRequest) {
        const firstClusterName = data.data.items[0].key ?? "";
        setDataInUrl({
          filters: {
            ...dataInUrl.filters,
            [filterCategory]: [
              { values: [firstClusterName], operator: Operator.In },
            ],
          },
        });
        setInitialClustersFilterWasSet(true);
      }
    }
  }, [
    data,
    data?.data.items,
    dataInUrl.filters,
    setDataInUrl,
    hasFiltersInUrl,
    setInitialClustersFilter,
    initialClustersFilterWasSet,
    setInitialClustersFilterWasSet,
    isLoading,
    enableRequest,
  ]);

  return (
    !setInitialClustersFilter ||
    !!error || // do not disable all live-state requests if this request fails
    hasFiltersInUrl ||
    initialClustersFilterWasSet
  );
};
