import Modal from "react-modal";
import React from "react";
import styled from "styled-components";

import { CloseIcon } from "../CloseIcon";
import { H2 } from "../typography";

const customModalStylesContentDefault = {
  boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.5)",
  top: "40%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#fafbfc",
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const customModalStyles = {
  overlay: { zIndex: 10000, background: "rgb(0,0,0,0)" },
  content: customModalStylesContentDefault,
};

const HeaderContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template:
    "title . addOns close" auto
    "content content content content" 1fr / auto 1fr auto;
  gap: 0.5rem 1rem;
`;

const ModalTitle = styled(H2)<{
  titleMarginHorizontal?: number;
  titlePaddingVertical?: string;
}>`
  grid-area: title;
  margin: 0;
  color: black;
  max-width: 60vw;
  ${({ titleMarginHorizontal }) =>
    titleMarginHorizontal
      ? `
  margin-top:${titleMarginHorizontal}rem;
  margin-bottom:${titleMarginHorizontal + 0.7}rem;`
      : `
  margin-bottom:1.5rem;`}
  ${({ titlePaddingVertical }) =>
    titlePaddingVertical ? `padding: 0 ${titlePaddingVertical}` : ""}
`;
const ContentArea = styled.div`
  grid-area: content;
  overflow: auto;
  max-height: 60vh;
  max-width: 95vw;
`;

interface ModalComponentProps {
  isOpen: boolean;
  handleCloseModal?: () => void;
  title?: string;
  headerChildren?: React.ReactElement;
  customStyle?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
  className?: string;
  titleMarginHorizontal?: number;
  titlePaddingVertical?: string;
  children?: React.ReactNode;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  isOpen,
  handleCloseModal,
  title,
  headerChildren,
  children,
  customStyle = {},
  overlayStyle = {},
  className,
  titleMarginHorizontal,
  titlePaddingVertical,
}) => {
  customModalStyles.content = Object.assign(
    {},
    customModalStylesContentDefault,
    customStyle || {}
  );
  customModalStyles.overlay = Object.assign(
    {},
    customModalStyles.overlay,
    overlayStyle
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={customModalStyles}
      contentLabel={title}
    >
      <HeaderContainer onClick={(e) => e.stopPropagation()}>
        <ModalTitle
          titleMarginHorizontal={titleMarginHorizontal}
          titlePaddingVertical={titlePaddingVertical}
        >
          {title}
        </ModalTitle>
        {headerChildren ?? null}
        {handleCloseModal && (
          <CloseIcon
            data-e2e-selector="modal-close-button"
            titleMargin={titleMarginHorizontal}
            style={{ gridArea: "close", marginRight: titlePaddingVertical }}
            onClick={handleCloseModal}
          />
        )}
        <ContentArea className={className}>{children}</ContentArea>
      </HeaderContainer>
    </Modal>
  );
};

export default ModalComponent;
