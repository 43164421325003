import React, { useEffect, useMemo, useState } from "react";
import { palette } from "@komodorio/design-system";
import { Button } from "@komodorio/design-system/deprecated";
import { Nodes16 } from "@komodorio/design-system/icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import EventDetailsSection from "../components/EventDetailsSection";
import DetailsList from "../components/DetailsList";
import useQueryWithVariables from "../../../../../shared/hooks/useQueryWithVariables";
import {
  useCountNodeWorkflowConfigurationQuery,
  useCountNodeWorkflowResultsQuery,
} from "../../../../../generated/graphql";
import { getMonitorLink } from "../WorkflowIssueEventDetails";

const StyledButton = styled(Button)`
  margin: 1rem 0;
`;

const useWorkflowResults = (
  clusterName: string,
  nodeName: string,
  runQuery: boolean
): { id: string; closedAt?: string | null }[] | null => {
  const workflowResultsVars = useMemo(() => {
    return runQuery
      ? null
      : {
          clusterName: clusterName,
          nodeName: nodeName,
        };
  }, [clusterName, runQuery, nodeName]);
  const workflowResults = useQueryWithVariables(
    useCountNodeWorkflowResultsQuery,
    workflowResultsVars
  )?.workflow_results_aggregate.nodes;
  return workflowResults ?? null;
};

const useHasWorkflowConfigurations = (
  clusterName: string,
  runQuery: boolean
): boolean => {
  const workflowConfigsVars = useMemo(() => {
    return { clusterName: clusterName };
  }, [clusterName]);
  const [countNodeWorkflowConfiguration] =
    useCountNodeWorkflowConfigurationQuery({
      variables: workflowConfigsVars,
      pause: !runQuery,
    });
  return !!countNodeWorkflowConfiguration;
};

const NodeSection: React.FC<{
  clusterName: string;
  nodeName: string;
  nodeHealthStatus?: string | null;
}> = ({ clusterName, nodeName, nodeHealthStatus }): JSX.Element => {
  const navigate = useNavigate();
  const baseDetails = useMemo(() => {
    return [{ label: "Name", value: nodeName }];
  }, [nodeName]);
  const [details, setDetails] = useState(baseDetails);
  const [fillColor, setFillColor] = useState<string>();
  const [button, setButton] = useState<JSX.Element | null>(null);

  const isHealthy = useMemo(
    () => nodeHealthStatus === "Healthy",
    [nodeHealthStatus]
  );

  const workflowResults = useWorkflowResults(clusterName, nodeName, !isHealthy);

  const hasWorkflowResults = !!workflowResults?.length;
  const hasOpenWorkflowResults = workflowResults?.some(
    (node) => node.closedAt === null
  );

  const hasWorkflowConfiguration = useHasWorkflowConfigurations(
    clusterName,
    !hasWorkflowResults && !nodeHealthStatus
  );

  useEffect(() => {
    if (isHealthy || (hasWorkflowConfiguration && !hasOpenWorkflowResults)) {
      setFillColor(palette.green[700]);

      setDetails([...baseDetails, { label: "Health", value: "Healthy" }]);
    } else if (nodeHealthStatus === "Unhealthy" || hasOpenWorkflowResults) {
      setFillColor(palette.pink[500]);
      setDetails([...baseDetails, { label: "Health", value: "Unhealthy" }]);

      if (hasWorkflowResults)
        setButton(
          <StyledButton
            size={"small"}
            onClick={() => navigate(getMonitorLink(workflowResults[0].id))}
          >
            View node issue
          </StyledButton>
        );
    }
  }, [
    baseDetails,
    hasOpenWorkflowResults,
    hasWorkflowConfiguration,
    hasWorkflowResults,
    isHealthy,
    navigate,
    nodeHealthStatus,
    workflowResults,
  ]);
  return (
    <EventDetailsSection
      icon={Nodes16}
      iconDimensions={{ height: "50%", width: "50%" }}
      title={"Node"}
      fillColor={fillColor}
    >
      <DetailsList details={details} rows={1} />
      {button}
    </EventDetailsSection>
  );
};

export default NodeSection;
