import React, { useMemo } from "react";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";

import useUserMetadata from "../../../../../shared/hooks/useUserMetadata/useUserMetadata";
import datadogIcon from "../../../../integrations/management/logos/datadog.svg";
import sentryIcon from "../../../../integrations/management/logos/sentry.svg";
import { External_Link_Context_Enum } from "../../../../../generated/graphql";
import { useHasPermissions } from "../../../../../shared/hooks/useUserMetadata/rbac";
import Resource from "../../../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceResourceKind } from "../../../../../shared/types/Resource";

import useNewSpec from "./links/useNewSpec";
import { useExternalLinks } from "./links/useExternalLinks";
import { linkAnnotations } from "./links/useKomodorAnnotations";
import { AddLink } from "./ManageExternalLinks/AddLink/AddLink";
import { dispatchLinkMetric, showLink } from "./ManageExternalLinks/utils";
import EditableExternalLink, {
  isEditableLink,
} from "./ManageExternalLinks/EditableExternalLink";
import MetadataCustomLink from "./MetadataCustomLink";

const BREX_ACCOUNT_ID = "aca9f971-2164-4398-959d-fac184d20f5c";

export const MetadataLink = styled.a`
  display: flex;
  align-items: center;

  color: inherit;
  font-weight: bold;
  font-size: 12px;
  padding: 0.3rem;

  img {
    width: 1rem;
    margin-inline-end: 0.5rem;
  }

  padding-bottom: 0.75rem;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid #c6cddf;
  }
`;

const Title = styled(Typography).attrs({ variant: "title", size: "large" })`
  margin-block-end: 1rem;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useServiceRelatedLinks = (resource: Resource | undefined) => {
  const externalLinks = useExternalLinks(
    resource?.cluster,
    resource?.id,
    resource?.namespace
  );
  return useMemo(
    () => [
      ...linkAnnotations(resource?.annotations, "service.link"),
      ...externalLinks,
    ],
    [externalLinks, resource?.annotations]
  );
};

const getDatadogHost = (accountId: string, datadogEnv: string) => {
  if (accountId === BREX_ACCOUNT_ID) {
    return `brex-${datadogEnv}.datadoghq.com`;
  }
  return "app.datadoghq.com";
};

const ServiceAdditionalLinks: React.FC<{
  resource: KomodorServiceResourceKind;
}> = ({ resource }) => {
  const userMetadata = useUserMetadata();
  const { accountId } = userMetadata;
  const { canManageIntegrations } = useHasPermissions();

  const serviceRelatedLinks = useServiceRelatedLinks(resource);

  const { cluster, namespace, name, datadogData, sentryData } = resource;
  const { datadogEnv, datadogServiceName } = datadogData ?? {};
  const sentryProjectId = sentryData?.sentryProjectId;
  const datadogHost = getDatadogHost(accountId, datadogEnv ?? "");
  const newSpec = useNewSpec(cluster, namespace, name);
  return (
    <>
      <Title>Additional Links</Title>
      <LinksContainer>
        {datadogEnv && datadogServiceName ? (
          <MetadataLink
            href={`https://${datadogHost}/apm/service/${datadogServiceName}?env=${datadogEnv}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={datadogIcon} alt="datadog" />
            View APM on Datadog
          </MetadataLink>
        ) : null}
        {sentryProjectId ? (
          <MetadataLink
            href={`https://sentry.io/?project=${sentryProjectId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={sentryIcon} alt="sentry" />
            View issues on Sentry
          </MetadataLink>
        ) : null}
        {serviceRelatedLinks.map((link, i) => {
          return (
            showLink(link, External_Link_Context_Enum.Service) && (
              <EditableExternalLink
                link={link}
                isEditable={isEditableLink(link, canManageIntegrations)}
                context={External_Link_Context_Enum.Service}
                key={i}
              >
                <MetadataCustomLink
                  link={link}
                  resource={resource}
                  newSpec={newSpec}
                  dispatchLinkMetric={dispatchLinkMetric(
                    External_Link_Context_Enum.Service,
                    link
                  )}
                />
              </EditableExternalLink>
            )
          );
        })}
        <AddLink
          context={External_Link_Context_Enum.Service}
          k8sClusterName={cluster}
          namespace={namespace}
          serviceId={resource.id}
          serviceDisplayName={resource.name}
        />
      </LinksContainer>
    </>
  );
};

export default ServiceAdditionalLinks;
