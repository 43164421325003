import { CheckType, Severity } from "@/generated/reliabilityApi";

export const reliabilityArialLabels = {
  groupBySelector: "group violations by",
  timeWindowSelector: "created at",
  violationOptionsMenu: "violation menu",
  violationStatusMenuButton: "violation status menu button",
  totalResults: "total results",
  severities: {
    container: "violations severities container",
    chip: "severity chip",
  },
  dependentViolations: {
    container: "dependent violations container",
    totalMisconfigurations: "total misconfigurations",
    relatedViolations: "related violations",
  },
  aggregations: {
    container: "impact group aggregations container",
    item: "impact group aggregation item",
    itemTooltip: "impact group aggregation item tooltip",
  },
  impactGroups: {
    mainContainer: "impact groups container",
  },
  clusterGroups: {
    mainContainer: "clusters container",
  },
  checkTypeGroups: {
    mainContainer: "check type groups container",
  },
  groups: {
    singleGroup: "group for",
    Title: "group title",
    Explanation: "group explanation",
    expandHitArea: "expand hit area",
  },
  violationsTable: {
    searchFilter: "violations search filter",
    checkTypeFilter: "violations check type filter",
    statusFilter: "violations status filter",
    severityTabsContainer: "violations severities",
    severityTab: "violations severity tab",
    violationsTable: "violations table",
    columns: {
      dependent: "dependent column",
    },
  },
  violationsTableByIds: {
    container: "violations table by ids container",
  },
  violationDrawer: {
    tabs: { overview: "overview tab", history: "history tab" },
    sections: { what: "what section", why: "why section", how: "how section" },
    header: "violation drawer header",
    title: "violation drawer title",
    headerDates: "violation drawer header dates",
    metadataContainer: "violation drawer metadata container",
    content: "drawer content",
    graphsContainer: "violation graphs container",
    violationSummary: "violation summary",
    history: {
      historyItemTextContent: "history item text content",
    },
    correlatedEvents: "correlated events",
    noisyNeighbors: {
      metadata: {
        noisyContainers: "noisy containers",
        affectedNodes: "affected nodes",
        totalVictims: "total victims",
        affectedDeployments: "affected deployments",
        affectedJobs: "affected jobs",
      },
      graphs: {
        nodeUsage: "node usage line",
        nodeCapacity: "node capacity line",
        workloadUsage: "workload usage line",
      },
      tables: {
        affectedNodes: "affected nodes table",
      },
    },
    k8sEol: {
      summary: "k8sEOL summary",
    },
    containerRestarts: {
      graphLine: "container restarts - count line",
      description: "container restarts description",
      graphLegend: "container restarts graph legend",
      restartReasonsBreakdown: "restart reasons breakdown",
      whyIsItImportantSection: "why is it important section",
      howToFixSection: "how to fix it section",
    },
    certificateExpiration: {
      CertificateExpirationTable: "certificate expiration table",
      certificateRelatedResourcesTable: "certificate related resources table",
      certificateStatus: "certificate status",
    },
    feedback: {
      likeButton: "violation drawer feedback - like",
      dislikeButton: "violation drawer feedback - dislike",
      feedbackDialog: "violation drawer feedback dialog",
      submitButton: "violation drawer feedback submit",
    },
    metricsGraphs: {
      deployEvent: {
        viewDetails: "view details button",
      },
    },
    singlePointOfFailure: {
      whatHappenedSummary: {
        availabilityIssue: "what happened availability issue container",
        nodeTerminations: "what happened node terminations container",
        unhealthyMinutes: "what happened unhealthy minutes container",
      },
      replicasOverTimeGraph: {
        Graph: "Replicas over time",
        Name: "Replicas",
        Xaxis: "Time",
        Yaxis: "Replicas",
      },
    },
  },
  ignoreChecksDialog: {
    ignoreCheckDialogContainer: "ignore checks dialog container",
  },
  ignoreChecks: {
    checksContainer: "checks container",
    manageExclusions: "manage exclusions for",
    addExceptionButton: "Add exception",
    editRuleIconButton: "open edit rule dialog",
    deleteRuleIconButton: "open delete rule dialog",
    editRuleButton: "edit rule",
    addRuleButton: "add rule",
    cancelButton: "cancel",
    deleteButton: "remove exception",
    drawerContainer: "ignore checks drawer container",
    drawerTable: "ignore checks drawer table",
    overviewTable: "ignore checks overview table",
  },
  collapsibleContainer: {
    header: "collapsible container header",
  },
  filters: {
    filtersContainer: "filters container",
    resetAllButton: "reset all",
  },
  scopedViolationsDrawer: {
    title: "Reliability Violations title",
  },
  policies: {
    newReliabilityPolicyButton: "new reliability policy button",
    policiesTable: {
      stateLoading: "policies table loading state",
      stateEmpty: "policies table empty state",
      stateResults: "policies table results state",
      doubleClickCell: "policies table double click cell",
      priorityCell: "policy priority",
      deletePolicy: "delete policy",
      editPolicy: "edit policy",
      viewPolicy: "view policy",
    },
    policiesDrawer: {
      close: "close reliability policy drawer",
      container: "policies drawer container",
      errorText: "error message",
      addConfigurationButton: "add configuration",
      saveButton: "Save Changes",
      header: "policies drawer header",
      configurationsList: "configurations list",
      configurationItem: {
        container: "configuration item container",
        deleteButton: "Delete configuration",
      },
      configurationEmptyState: {
        description: "configuration empty state description",
      },
      tabs: {
        runtimeChecks: "runtime checks",
        bestPracticeChecks: "best practice checks",
      },
      impactGroupSelector: "select impact group",
      getConfigurationControlsContainer: (checkType: CheckType) =>
        `configuration controls container for ${checkType}`,
      getConfigurationControlSeverityContainer: (
        checkType: CheckType,
        severity: Severity
      ) => `control for ${checkType} and ${severity} severity`,
      getControlContainer: (checkType: CheckType) =>
        `control container for ${checkType}`,
      getSelectControl: (stateName: string) => `select for ${stateName}`,
      getInputControl: (stateName: string) => `input for ${stateName}`,
    },
  },
};
