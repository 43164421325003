import { useEffect, useState } from "react";
import { debounce } from "lodash";

import useAuthorization from "../hooks/roles/useAuthorization";
import {
  useWebSocketContext,
  WebSocketCloseCode,
} from "../context/WebSocketsProvider";
import { setCookie } from "../utils/cookie/cookie";
import { notifyDDError } from "../hooks/exceptionManagement";
import { ONE_DAY_IN_MINUTES } from "../constants/time";

import { CLOSE_DEBOUNCE_TIMEOUT_MS } from "./constants/debounceTimeout";
import { handleConnectionOnOpen } from "./handleWsConnectionIsOpen";
import { getWebSocketsHubURL } from "./utils";
import { SocketMessagePayload, SocketMessageType } from "./types";

const shouldReconnectByCode = (code: number) => {
  return ![
    WebSocketCloseCode.ClientSideTermination,
    WebSocketCloseCode.AgentTermination,
  ].includes(code);
};

export interface Args {
  identifier: string;
  containerName?: string;
  agentId?: string | null;
  cluster: string;
  namespace: string;
  podName: string;
  handleIncomingMessage: (message: MessageEvent<SocketMessagePayload>) => void;
  refreshKeepAliveSession: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initData: Record<string, any>;
  handleReconnect: (connect: () => Promise<void>, id: string) => Promise<void>;
  initMessageType: SocketMessageType;
  shouldReconnect?: boolean;
}

interface Res {
  isSessionInitialized: boolean;
  isReconnecting: boolean;
}

export const useInitiateSocketSession = ({
  identifier,
  agentId,
  cluster,
  namespace,
  podName,
  handleIncomingMessage,
  refreshKeepAliveSession,
  containerName,
  initData,
  initMessageType,
  handleReconnect,
  shouldReconnect = true,
}: Args): Res => {
  const sockets = useWebSocketContext();
  const authorization = useAuthorization();
  const [isSessionInitialized, setIsInitialized] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    if (
      !agentId ||
      !cluster ||
      !namespace ||
      !podName ||
      !identifier ||
      !containerName
    ) {
      return;
    }

    const connect = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        const { sessionId } = sockets.getSocketContext(identifier) || {};

        if (!identifier) {
          resolve();
          return;
        }

        authorization &&
          setCookie("JWT_TOKEN", authorization, ONE_DAY_IN_MINUTES);
        const newConnection = sockets.addSocket({
          id: identifier,
          accessToken: authorization,
          path: getWebSocketsHubURL({
            agentId: agentId || "",
            query: sessionId ? { sessionId } : undefined,
            authorization,
          }),
        });

        if (!newConnection) {
          return;
        }

        newConnection.addEventListener("message", handleIncomingMessage);

        handleConnectionOnOpen({
          sessionId,
          connection: newConnection,
          onInitialized: () => setIsInitialized(true),
          resolve,
          reject,
          initData,
          messageType: initMessageType,
        });

        newConnection.addEventListener(
          "close",
          debounce(({ code }: CloseEvent) => {
            if (shouldReconnectByCode(code) && shouldReconnect) {
              setIsReconnecting(true);

              handleReconnect(() => {
                // remove the socket, keep its context
                sockets.removeSocket(identifier, false);
                return connect()
                  .then(() => {
                    sockets.addContextToSocket(identifier, { sessionId });
                    refreshKeepAliveSession();
                  })
                  .catch(() => {
                    // context cleanup, since we didn't do so once we deleted the socket
                    sockets.removeSocketContext(identifier);
                  })
                  .finally(() => {
                    setIsReconnecting(false);
                  });
              }, identifier);
            }
          }, CLOSE_DEBOUNCE_TIMEOUT_MS)
        );
      });
    };

    connect().catch((err: Error) => {
      notifyDDError(err, {
        session_details: {
          agentId,
          cluster,
          namespace,
          podName,
          identifier,
          containerName,
        },
      });
    });
  }, [
    sockets,
    authorization,
    identifier,
    agentId,
    cluster,
    namespace,
    podName,
    handleIncomingMessage,
    setIsInitialized,
    refreshKeepAliveSession,
    containerName,
    initData,
    handleReconnect,
    initMessageType,
    shouldReconnect,
  ]);

  return { isSessionInitialized, isReconnecting };
};
