import React from "react";
import styled from "styled-components";

import { TextRegular } from "../../../../common/typography";
import { getOpenBrowserCommand } from "../../../../../shared/utils/os/os";
import { StrictExternalLink } from "../../../../common/Link/ExternalLink";
import CopyCode from "../CopyCode";
import { blueBrand } from "../../../../../Colors";
import { getAppConfig } from "../../../../../shared/config/appConfig";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { UpgradeCommands } from "../../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { useGetHelmInstallationCommand } from "../../../../../shared/hooks/useGetHelmInstallationCommand";
import { ACTIONS_DOCS_LINK } from "../../../../../shared/constants/docsLinks";

import { StepContent } from "./steps.styles";

const PreInstallError = styled.p`
  white-space: pre-line;
`;

const StyledCopy = styled(CopyCode)`
  margin: 0;
  margin-bottom: 1.25em;
`;

export const StyledBoxText = styled(TextRegular)`
  background: #f4f7fa;
  border: 1px solid #dcdddf;
  border-radius: 0.25rem;
  padding: 0.5rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const links = {
  helmCommandUrl: "https://helm.sh/",
  installationGuidUrl:
    "https://github.com/komodorio/helm-charts/tree/master/charts/komodor-agent#komodor-agent",
  defaultK8sPermissions:
    "https://github.com/komodorio/helm-charts/tree/master/charts/komodor-agent#values",
  actionsUrl: ACTIONS_DOCS_LINK,
};

const StyledExternalLink = styled(StrictExternalLink)`
  color: ${blueBrand};
`;

export const NoUnderExternalLink = styled(StyledExternalLink)`
  text-decoration: none;
`;

const LeftContainer = styled.div`
  align-self: flex-start;
`;

const getWatcherServerHost = (): string => {
  if (getAppConfig().env === "development")
    return "--set communications.serverHost=http://localhost:8101 --set communications.tasksServerHost=http://localhost:8091";

  if (getAppConfig().env === "staging")
    return "--set communications.serverHost=https://staging.app.komodor.com";
  return "";
};

const fillTemplate = (template: string, values: { [key: string]: string }) => {
  return template.replace(/\$\{(\w+)\}/g, (_placeholder, key) => {
    return String(values[key]);
  });
};

const StepInstallKomodorAgent: React.FC<{
  clusterName: string;
  apiKey: string | null;
  errorMessage: string;
}> = ({ clusterName, apiKey, errorMessage }) => {
  const komodorUrl = "https://app.komodor.com/main/services";

  return errorMessage ? (
    <PreInstallError>{errorMessage}</PreInstallError>
  ) : (
    <StepContent>
      <TextRegular>
        The following{" "}
        <StyledExternalLink href={links.helmCommandUrl}>
          helm
        </StyledExternalLink>{" "}
        command installs Komodor’s agent on your cluster
      </TextRegular>
      <ActionsInstallationCommand
        apiKey={apiKey}
        clusterName={clusterName}
        komodorUrl={komodorUrl}
      />
    </StepContent>
  );
};

const sendAnalytics = () => {
  dispatchEvent(AnalyticEvents.Integrations.Kubernetes.CopyInstallScript, {
    scriptType: "helm",
  });
};

export const ActionsInstallationCommand: React.FC<{
  apiKey: string | null;
  komodorUrl: string;
  clusterName?: string;
}> = ({ apiKey, clusterName, komodorUrl }) => {
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.INSTALL_COMMAND
  );
  const installCommand = fillTemplate(commandStr, {
    apiKey: apiKey ?? "",
    clusterName: clusterName ?? "default",
    watcherServerHost: getWatcherServerHost(),
    openBrowserCommand: getOpenBrowserCommand(),
    komodorUrl: komodorUrl,
  });
  return (
    <>
      <StyledCopy onClick={sendAnalytics} value={installCommand} />

      <LeftContainer>
        <TextRegular>
          Learn more about{" "}
          <NoUnderExternalLink href={links.installationGuidUrl}>
            how to install
          </NoUnderExternalLink>
          {" & "}{" "}
          <NoUnderExternalLink href={links.defaultK8sPermissions}>
            required permissions
          </NoUnderExternalLink>
        </TextRegular>
      </LeftContainer>
    </>
  );
};

export default StepInstallKomodorAgent;
