import { useMemo } from "react";

import {
  NodeIssueDataParsed,
  NodeTerminationDataParsed,
} from "../../common/types";
import useQueryWithVariables from "../../../../../../../../shared/hooks/useQueryWithVariables";
import { useIssuesClosedAtByIdsQuery } from "../../../../../../../../generated/graphql";
import { WorkflowIssueStatus } from "../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";

export const useUpdatedNodeIssuesInsight = (
  issuesInsights: NodeIssueDataParsed[]
): NodeIssueDataParsed[] => {
  const variables = useMemo(
    () =>
      issuesInsights.length > 0
        ? { eventIds: issuesInsights.map((e) => e.id) }
        : null,
    [issuesInsights]
  );

  const issuesClosedAt = useQueryWithVariables(
    useIssuesClosedAtByIdsQuery,
    variables
  );
  return useMemo(() => {
    return issuesInsights.map((issue) => {
      const issueClosedAt = issuesClosedAt?.workflow_results?.find(
        (issueUpdated) => issue.id === issueUpdated.id
      )?.closedAt;
      return {
        ...issue,
        status: issueClosedAt
          ? WorkflowIssueStatus.closed
          : issueClosedAt === null
          ? WorkflowIssueStatus.open
          : issue.status,
      };
    });
  }, [issuesClosedAt?.workflow_results, issuesInsights]);
};

export const useCorrelatedNodeEventsInsight = (
  latestNodeIssuesInsights: NodeIssueDataParsed[],
  latestNodeTerminationsInsights: NodeTerminationDataParsed[]
) => {
  const latestNodeIssuesInsightsUpdated = useUpdatedNodeIssuesInsight(
    latestNodeIssuesInsights
  );
  const nodeIssuesLength = latestNodeIssuesInsightsUpdated.length;
  const nodeTerminationsLength = latestNodeTerminationsInsights.length;

  return {
    shouldShow: nodeIssuesLength + nodeTerminationsLength > 0,
    text: `Correlated node events detected - ${
      nodeIssuesLength > 0 &&
      `${nodeIssuesLength} node issue${nodeIssuesLength > 1 ? "s" : ""}`
    }
    ${nodeTerminationsLength > 0 && nodeIssuesLength > 0 && ", "}
    ${
      nodeTerminationsLength > 0 &&
      `${nodeTerminationsLength} node termination${
        nodeTerminationsLength > 1 ? "s" : ""
      }`
    }`,
    latestNodeIssuesInsightsUpdated,
  };
};
