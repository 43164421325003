import { parseISO } from "date-fns";

import { IngressFragment } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";

import { CommonEventBase, CommonEventGroup } from "./commonEvents";
import { ingressType } from "./types";

const toIngressEvent = (
  e: IngressFragment,
  serviceId: string
): CommonEventBase => ({
  id: e.id,
  serviceId,
  action: e.action,
  eventTime: parseISO(e.eventTime),
  type: ingressType,
  name: e.name,
  namespace: e.namespace,
  clusterName: e.clusterName,
});

export default class IngressEventGroup extends CommonEventGroup {
  constructor(e: CommonEventBase) {
    super(e, "Ingress");
  }
}

export const groupIngressEvents = (
  events: IngressFragment[],
  serviceId: string
): IngressEventGroup[] =>
  mapAndSort(withServices(events, serviceId), toIngressEvent).map(
    (e) => new IngressEventGroup(e)
  );
