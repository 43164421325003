import { useMemo, useState } from "react";
import { TaskType, ResourceTableModel } from "komodor-types";

import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";

import useAgentTaskLogic from "./useAgentTaskLogic";

interface ResourceListProps {
  agentId: string | undefined;
  kubernetesResource: KubernetesResource;
  agentUpgradeRequired: boolean;
  agentInfo: AgentProperties | null;
  maxRetries: number;
  labelSelector: string;
  customColumns: string[];
  namespace?: string;
}

const useResourceListFromAgentTasks = ({
  agentId,
  agentInfo,
  agentUpgradeRequired,
  kubernetesResource,
  maxRetries,
  labelSelector,
  customColumns,
  namespace,
}: ResourceListProps): {
  result: ResourceTableModel | null;
  resourceTableModel?: ResourceTableModel;
  failureMessage: string;
  retries: number;
  refresh: () => void;
} => {
  const [retries, setRetries] = useState(0);
  const [triggerManually, setTriggerManually] = useState(false);

  const additionalColumns = useMemo(() => {
    return customColumns
      .concat(kubernetesResource?.CustomColumns || [])
      .join(",");
  }, [customColumns, kubernetesResource.CustomColumns]);
  const executeTaskVars = useMemo(() => {
    if (!agentInfo || agentUpgradeRequired) return null;
    setTriggerManually(true);
    setRetries(0);

    const namespaceFlag: { [_: string]: string } = {};
    if (namespace) {
      namespaceFlag["namespace"] = namespace;
    }
    if (
      agentInfo.watchNamespace !== "all" &&
      !Array.isArray(agentInfo.watchNamespace)
    ) {
      namespaceFlag["namespace"] = agentInfo.watchNamespace;
    }
    const req = {
      agentId: agentId,
      type: TaskType.RESOURCE_LIST,
      data: Object.assign(
        {
          resourceName: kubernetesResource.NameInK8S,
          outputType: "wide",
          showLabels: true,
          labelSelector,
        },
        namespaceFlag
      ),
    };
    if (additionalColumns) {
      req.data.customColumns = additionalColumns;
    }
    return req;
  }, [
    agentInfo,
    agentUpgradeRequired,
    agentId,
    kubernetesResource.NameInK8S,
    labelSelector,
    additionalColumns,
    namespace,
  ]);

  const { refresh, resultFromQuery, failureMessage } = useAgentTaskLogic({
    retries,
    setRetries,
    maxRetries,
    executeTaskVars,
    triggerManually,
    setTriggerManually,
  });

  const result = useMemo(() => {
    if (resultFromQuery?.agent_task_result.length) {
      return resultFromQuery.agent_task_result[0].result as ResourceTableModel;
    }
    return null;
  }, [resultFromQuery]);

  return {
    result,
    failureMessage,
    retries,
    refresh,
  };
};

export default useResourceListFromAgentTasks;
