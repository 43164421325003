/* eslint-disable max-lines */
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { capitalize, sortBy } from "lodash";

import useBatches from "../../../shared/hooks/useBatches";
import IntersectionDetector from "../../common/IntersectionDetector";
import { SetTimeWindow, TimeWindow } from "../../../shared/types/TimeWindow";
import { H3, Text } from "../../common/typography";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";
import Arrow from "../../common/ProcessList/Arrow";
import Table, { Row } from "../../common/table/Table";
import FetchDataLoading from "../../common/loaders/LoadingState";
import { DateTimeSelector } from "../../common/DateTimeSelector";
import SensorLabels from "../common/SensorLabels";
import EmptyState from "../../ResourceView/tabs/EventsTab/content/emptyState";
import { WorkflowRun } from "../useWorkflowsRuns";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { OpenTag, ClosedTag } from "../common/StatusTag";
import { gray10 } from "../../../Colors";
import ToggleSwitch from "../common/toggleSwitch";
import { typeTriggers } from "../workflowRunView/IssueDetailsDrawer";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import MonitorFilters from "../MonitorsFilters";
import Divider from "../common/Divider";
import { Spacer } from "../common/styles";

const FiltersHeader = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const FieldName = styled(Text)`
  font-size: 14px;
`;

const LightH3 = styled(H3)`
  font-weight: 400;
  font-size: 12px;
  color: #3b3d45;
`;

const GreyH3 = styled(LightH3)`
  color: ${gray10};
`;

const DatePickerContainer = styled.div`
  grid-area: header;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-left: 0.7rem;
`;

const OpenCloseToggleContainer = styled.div`
  grid-area: header;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-left: auto;
`;

const Panel = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  min-height: 70vh;
`;

const StyledCol = styled.td`
  width: 0.75rem;
`;

const StyledTable = styled(Table)`
  th {
    font-weight: bold;
    font-size: 0.75rem;
    color: #72747a;
  }
  td {
    font-size: 0.75rem;
    padding: 1.2rem 0.75rem;
  }
`;

const Trigger = styled.div`
  display: grid;
  grid-gap: 10px;
  align-items: center;
`;

const SummaryTag = styled.div`
  color: #007aff;
  font-weight: 400;
  font-size: 14px;
  display: grid;
  grid-gap: 10px;
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const RunRow: React.FC<{
  workflowRun: WorkflowRun;
}> = ({ workflowRun }) => {
  const navigate = useNavigate();
  const { format } = useDateFormatter({ timeZoneName: undefined });

  const sensors =
    workflowRun && workflowRun.workflowConfiguration
      ? workflowRun.workflowConfiguration.sensors
      : null;

  const referrer = useQuery().get("referrer");

  return (
    <Row
      selected={false}
      onClick={() => {
        navigate({
          pathname: `/main/monitors/run/${workflowRun.id}`,
        });
        dispatchEvent(
          AnalyticEvents.WorkflowsView.Run_Row_Clicked_With_Source,
          {
            type: workflowRun.type,
            runId: workflowRun.id,
            shortResourceName: workflowRun.shortResourceName,
            sourceEventId: workflowRun.sourceEventId,
            workflowName: workflowRun.workflowConfiguration?.name,
            referrerType: referrer,
          }
        );
      }}
    >
      <td>{workflowRun.closedAt ? <ClosedTag /> : <OpenTag />}</td>
      <td>
        <Trigger>
          <b>
            {capitalize(workflowRun.type)} {workflowRun.shortResourceName}{" "}
            {typeTriggers[workflowRun.type]}
          </b>
        </Trigger>
      </td>
      <td>{sensors && <SensorLabels sensor={sensors[0]} />}</td>
      <td>{format(workflowRun.eventTime)}</td>
      <td>{workflowRun.closedAt ? format(workflowRun.closedAt) : ""}</td>
      <td>
        <SummaryTag>
          {
            Object.entries(workflowRun.resultsSummary).filter(
              ([type, passed]) => !passed && type !== "provisioner-data"
            ).length
          }{" "}
          Possible causes found
          <LightH3>
            {Object.entries(workflowRun.resultsSummary)
              .filter(
                ([type, passed]) => !passed && type !== "provisioner-data"
              )
              .map(([type]) => type)
              .join(", ")}
          </LightH3>
        </SummaryTag>
      </td>
      <StyledCol>
        <Arrow width="0.75rem" color="#A8AFC0" direction="right" />
      </StyledCol>
    </Row>
  );
};

const RunsTable: React.FC<{
  workflowRuns: WorkflowRun[];
  timeWindow: TimeWindow;
  setTimeWindow: SetTimeWindow;
  className?: string;
  isFetching: boolean;
}> = ({ workflowRuns, timeWindow, setTimeWindow, className, isFetching }) => {
  const [selectedToggle, setSelectedToggle] = useState<string>("All");

  const selectedRuns = useMemo(
    () =>
      selectedToggle === "Open"
        ? workflowRuns.filter((run) => !run.closedAt)
        : selectedToggle === "Closed"
        ? workflowRuns.filter((run) => run.closedAt)
        : workflowRuns,
    [workflowRuns, selectedToggle]
  );

  const [cluster, setCluster] = useState<string | undefined>();
  const [ruleType, setRuleType] = useState<string | undefined>();

  const filteredRuns = useMemo(
    () =>
      selectedRuns
        .filter(
          (run) =>
            cluster === "" ||
            run.workflowConfiguration?.sensors[0].cluster === cluster
        )
        .filter((run) => ruleType === "" || run.type === ruleType),
    [selectedRuns, cluster, ruleType]
  );

  const sortedRuns = useMemo(
    () => sortBy(filteredRuns, (a) => -a.eventTime.getTime()),
    [filteredRuns]
  );

  const { limited, resetBatches, loadMore } = useBatches(20, sortedRuns);

  return (
    <>
      <FiltersHeader>
        <MonitorFilters
          clusters={Array.from(
            new Set(
              workflowRuns
                ?.flatMap((r) => r.workflowConfiguration?.sensors ?? [])
                .flatMap((s) => s.cluster)
            )
          ).sort()}
          cluster={cluster}
          setCluster={setCluster}
          ruleTypes={Array.from(new Set(workflowRuns?.map((r) => r.type))).sort(
            (t1, t2) => t1.toLowerCase().localeCompare(t2.toLowerCase())
          )}
          ruleType={ruleType}
          setRuleType={setRuleType}
        />
        <OpenCloseToggleContainer>
          <FieldName>Status:</FieldName>
          <ToggleSwitch
            values={["All", "Open", "Closed"]}
            selected={selectedToggle}
            setSelected={setSelectedToggle}
          />
        </OpenCloseToggleContainer>
        <DatePickerContainer>
          <FieldName> Showing:</FieldName>
          <DateTimeSelector
            timeWindow={timeWindow}
            setTimeWindow={setTimeWindow}
          />
        </DatePickerContainer>
      </FiltersHeader>
      <Divider />
      <Spacer />
      {filteredRuns.length !== 0 && (
        <>
          <GreyH3 data-e2e-selector="triggered-title">
            Showing {filteredRuns.length} issues
          </GreyH3>
          <Spacer />
        </>
      )}
      <Panel className={className}>
        {isFetching ? (
          <FetchDataLoading dataName="triggered monitors" />
        ) : !filteredRuns?.length ? (
          <EmptyState timeWindow={timeWindow} setTimeWindow={setTimeWindow} />
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <th>Status</th>
                <th>Trigger</th>
                <th>Sensors</th>
                <th>Start Time</th>
                <th>Closed Time</th>
                <th>Summary</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <IntersectionDetector onShow={resetBatches} />
              {limited.map((run) => (
                <RunRow key={run.id} workflowRun={run} />
              ))}
              <IntersectionDetector onShow={loadMore} />
              <br />
            </tbody>
          </StyledTable>
        )}
      </Panel>
    </>
  );
};

export default RunsTable;
