import { useEffect } from "react";

import { AnalyticEvents } from "../config/analyticsEvents";
import useAnalyticsApi from "../context/analyticsProvider";
import { fetchLoginCount } from "../utils/auth0/utils";
import { LocalStorageItem } from "../utils/localStorageSettings";

import { useStateInLocalStorage } from "./state";
import { useUserLoginInfo } from "./useUserMetadata/useUserLoginInfo";

const sentEventOfUserSignin = new LocalStorageItem("sentEventOfUserSignin");

const useDispatchFirstLogin = (): void => {
  const [hasSentEventOfUserSignin, setHasSentEventOfUserSignin] =
    useStateInLocalStorage(sentEventOfUserSignin, "");

  const user = useUserLoginInfo();
  const analytics = useAnalyticsApi();
  useEffect(() => {
    if (!user || hasSentEventOfUserSignin !== "") return;
    const loginCount = fetchLoginCount(user);
    if (loginCount === 1) {
      analytics.dispatchEventViaBackend(
        AnalyticEvents.Signup.FirstTimeLogin,
        {},
        true
      );
    }
    setHasSentEventOfUserSignin(loginCount.toString());
  }, [analytics, hasSentEventOfUserSignin, setHasSentEventOfUserSignin, user]);
};

export default useDispatchFirstLogin;
