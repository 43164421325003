import React from "react";
import Box from "@mui/material/Box";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Check from "@mui/icons-material/Check";
import { muiColors } from "@komodorio/design-system";

export const UnhealthyServicesCell: React.FC<{
  unhealthyServices: number;
}> = ({ unhealthyServices }) => {
  if (!unhealthyServices) return <Check sx={{ color: muiColors.teal[900] }} />;

  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
      <WarningAmberOutlined sx={{ fill: muiColors.pink[700] }} />
      {unhealthyServices.toLocaleString()}
    </Box>
  );
};
