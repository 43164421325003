import React, { useMemo } from "react";

import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";
import { StyledOrderedList } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureStyles";
import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SectionContainer";

export const GroupingReasons: React.FC = () => {
  const { grouping_reasons } = useGetCascadingFailureSupportingData() ?? {};

  const content = useMemo(() => {
    if (!grouping_reasons?.length) return null;

    return grouping_reasons.map((reason, index) => (
      <li key={index}>{reason.reason}</li> // temp
    ));
  }, [grouping_reasons]);

  if (!grouping_reasons?.length) return null;

  return (
    <SectionContainer title={"Grouping Reasons:"}>
      <StyledOrderedList>{content}</StyledOrderedList>
    </SectionContainer>
  );
};
