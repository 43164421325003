import styled, { css } from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

export const sectionDivider = css`
  > div:not(:last-child) {
    border-bottom: 1px solid ${muiColors.gray[200]};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledOrderedList = styled.ol`
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  margin-left: 16px;
  & > li {
    line-height: 1.5;
  }
`;

export const ColoredTypography = styled(Typography)<{
  $color: string;
  $fontSize?: string;
}>`
  && {
    font-size: ${({ $fontSize }) => $fontSize || "13px"};
    color: ${({ $color }) => $color};
  }
`;
