/* eslint-disable max-lines */
import { muiColors } from "@komodorio/design-system";

import { Dictionary } from "../../../shared/types/Dictionary";
import {
  CheckType,
  ImpactGroupCategory,
  ImpactGroupIdentifier,
  ViolationStatus,
} from "../../../generated/reliabilityApi";
import {
  ColorScheme,
  Group,
  PageRoutes,
  ReliabilityError,
  ReliabilityErrorPayload,
  Severity,
  ViolationsGroupBy,
} from "../ReliabilityTypes";
import { ViolationDrawerValue } from "../components/pages/violations/ViolationsDrawer/violationDrawerTypes";

export const ReliabilityRoutes: Dictionary<PageRoutes> = {
  violations: "violations",
  checks: "checks",
  policies: "policies",
  bestPractices: "best-practices",
};

export const relatedViolationsKey = "true";
export const unrelatedViolationsKey = "false";

export const sectionGap = "8px";

export const ALL_SELECTOR = "All (*)";

export const FILTER_UPDATE_DELAY = 500;

export const DEFAULT_VIOLATION_TAB_GROUP_BY: ViolationsGroupBy = "Impact";

export const DEFAULT_BEST_PRACTICES_TAB_GROUP_BY: ViolationsGroupBy =
  "CheckType";

export const DEFAULT_DRAWER_TAB: ViolationDrawerValue = "overview";

export const SeverityToColorScheme: Record<Severity, ColorScheme> = {
  high: {
    backgroundColor: muiColors.pink[25],
    color: muiColors.pink[800],
    borderColor: muiColors.pink[300],
  },
  medium: {
    backgroundColor: muiColors.orange[25],
    color: muiColors.orange[700],
    borderColor: muiColors.orange[300],
  },
  low: {
    backgroundColor: muiColors.yellow[50],
    color: muiColors.yellow[800],
    borderColor: muiColors.yellow[500],
  },
};

export const checkTypeToLabel: Record<CheckType, string> = {
  deprecatedApis: "API Deprecation",
  restartingContainers: "Container restarts count",
  requestsLimitsRatio: "High request to limit ratio",
  kubernetesVersionDeprecated: "Kubernetes end of life",
  nodeTerminationAutoScaling: "Node terminations due to Autoscaling",
  nodeTerminationSpotInstance: "Node terminations due to Spot Interruptions",
  noisyNeighbor: "Noisy Neighbor",
  throttledCPU: "CPU throttled",
  unschedulableTime: "Unschedulable time",
  kubernetesEndOfLife: "Kubernetes end of life",
  HPAMax: "HPA at Max capacity",
  underProvisionedWorkloads: "Under provisioned workloads",
  singlePointOfFailure: "Single Point of Failure",
  deploymentMissingReplicas: "Low number of replicas",
  missingPDB: "Missing Pod Disruption Budget",
  missingTopologySpreadConstraint: "Missing Topology Spread Constraints",
  HPAMinAvailability: "HPA Min Availability",
  missingHPA: "Missing Horizontal Pod Autoscaler",
  priorityClassNotSet: "Priority Class Not Set",
  cpuRequestsMissing: "CPU Requests Missing",
  memoryRequestsMissing: "Memory Requests Missing",
  cpuLimitsMissing: "CPU Limits Missing",
  memoryLimitsMissing: "Memory Limits Missing",
  livenessProbeMissing: "Liveness Probe Missing",
  readinessProbeMissing: "Readiness Probe Missing",
  certificateExpiration: "Certificate Expiration",
  syntheticDegradedService: "Other Degraded Service",
  syntheticEOLDeprecationOutdated: "Other EOL Deprecation Outdated",
  syntheticNodePressure: "Other Node Pressure",
  cascadingFailure: "Cascading Failure",
};

type ImpactGroupInfo = {
  impactGroupIdentifier: ImpactGroupIdentifier;
  title: string;
  tooltipInfo: string;
  generateInformativeUnit?: (formattedUnit: string) => string;
};

export const impactGroupToInfo: Record<ImpactGroupCategory, ImpactGroupInfo> = {
  NoisyNeighbors: {
    impactGroupIdentifier: "NodePressure",
    title: "Noisy Neighbors",
    tooltipInfo:
      "Noisy neighbor refers to a situation where certain pods or containers excessively consume shared resources, disrupting the performance of other workloads on the same cluster.",
  },
  Victims: {
    impactGroupIdentifier: "NodePressure",
    title: "Victims",
    tooltipInfo:
      "The total number of pods that were evicted from the nodes due availability issues.",
  },
  UnderProvisionedWorkloads: {
    impactGroupIdentifier: "NodePressure",
    title: "Under provisioned workloads",
    tooltipInfo: "",
  },
  UnplannedResourceUsage: {
    impactGroupIdentifier: "NodePressure",
    title: "Unplanned resource usage",
    tooltipInfo: "",
  },
  UnschedulableTime: {
    impactGroupIdentifier: "DegradedService",
    title: "Unschedulable time",
    tooltipInfo: "",
  },
  UnschedulablePods: {
    impactGroupIdentifier: "DegradedService",
    title: "Unschedulable pods",
    tooltipInfo: "",
  },
  HPAsReachedMax: {
    impactGroupIdentifier: "DegradedService",
    title: "Reached max capacity",
    tooltipInfo: "",
    generateInformativeUnit: (formattedUnit) => `${formattedUnit} HPA(s)`,
  },
  DeprecatedAPIsInUse: {
    impactGroupIdentifier: "EOLDeprecationOutdated",
    title: "Deprecated APIs in use",
    tooltipInfo: "",
  },
  DeprecatedAPIsInFuture: {
    impactGroupIdentifier: "EOLDeprecationOutdated",
    title: "APIs approaching removal",
    tooltipInfo: "",
  },
  RemovedAPIsInUse: {
    impactGroupIdentifier: "EOLDeprecationOutdated",
    title: "Removed APIs in use",
    tooltipInfo: "",
  },
  DeprecatedClustersDetected: {
    title: "Reached EOL",
    impactGroupIdentifier: "EOLDeprecationOutdated",
    tooltipInfo: "",
    generateInformativeUnit: (formattedUnit) => `${formattedUnit} Cluster(s)`,
  },
  ClustersWillDeprecateInFuture: {
    title: "Approaching EOL",
    impactGroupIdentifier: "EOLDeprecationOutdated",
    tooltipInfo: "",
    generateInformativeUnit: (formattedUnit) => `${formattedUnit} Cluster(s)`,
  },
  NodeAutoscaling: {
    impactGroupIdentifier: "NodeTerminations",
    title: "Node Autoscaling",
    tooltipInfo: "",
  },
  SpotInterruptions: {
    impactGroupIdentifier: "NodeTerminations",
    title: "Spot interruptions",
    tooltipInfo: "",
  },
  RelatedAvailabilityIssues: {
    impactGroupIdentifier: "NodeTerminations",
    title: "Related availability issues",
    tooltipInfo: "",
  },
  ServicesThrottled: {
    impactGroupIdentifier: "FlakyServices",
    title: "Throttled services",
    tooltipInfo: "",
  },
  ContainerRestarts: {
    impactGroupIdentifier: "FlakyServices",
    title: "Container restarts",
    tooltipInfo: "",
  },
  SinglePointOfFailureServices: {
    impactGroupIdentifier: "DegradedService",
    title: "Single point of failures",
    tooltipInfo: "",
  },
  BestPractices: {
    impactGroupIdentifier: "BestPractices",
    title: "Best practices",
    tooltipInfo: "",
  },
  ExpiredWorkloadCertificates: {
    impactGroupIdentifier: "Addons",
    title: "Expired workload certificates",
    tooltipInfo: "",
  },
  AboutToExpireWorkloadCertificates: {
    impactGroupIdentifier: "Addons",
    title: "Workload certificates that are about to expire",
    tooltipInfo: "",
  },
};

export const defaultViolationStatuses: ViolationStatus[] = [
  ViolationStatus.Open,
  ViolationStatus.Confirmed,
];

export const allowedStatusesSelectorValues: ViolationStatus[] = [
  ViolationStatus.Open,
  ViolationStatus.Confirmed,
  ViolationStatus.Resolved,
  ViolationStatus.ManuallyResolved,
];

export const reliabilityErrors: Record<
  ReliabilityError,
  ReliabilityErrorPayload
> = {
  deleteIgnoreRule: {
    action: "delete ignore rule",
    message: "Failed to delete exception",
  },
  addIgnoreRule: {
    action: "create new ignore rule",
    message: "Failed to create exception",
  },
  editIgnoreRule: {
    action: "edit ignore rule",
    message: "Failed to edit exception",
  },
  updateViolationStatus: {
    action: "update violation status",
    message: "Failed to update status",
  },
  createViolationFeedback: {
    action: "create violation feedback",
    message: "Failed to send feedback",
  },
  deletePolicy: {
    action: "delete policy",
    message: "Failed to delete policy",
  },
  createPolicy: {
    action: "create policy",
    message: "Failed to create policy",
  },
  fetchPolicyData: {
    action: "fetch policy data",
    message: "Something went wrong while fetching policy data",
  },
};

export const reliabilitySuccessMessages = {
  policyCreated: "Reliability policy created successfully",
  policyUpdated: "Reliability policy updated successfully",
  policyDeleted: "Reliability policy deleted successfully",
};

export const impactGroupItemsOrder: Record<
  ImpactGroupIdentifier,
  { [key in ImpactGroupCategory]?: number }
> = {
  NodePressure: {
    NoisyNeighbors: 0,
    Victims: 1,
    UnderProvisionedWorkloads: 2,
  },
  DegradedService: {
    HPAsReachedMax: 0,
    ServicesThrottled: 1,
    ContainerRestarts: 3,
  },
  EOLDeprecationOutdated: {
    DeprecatedClustersDetected: 0,
    ClustersWillDeprecateInFuture: 1,
    DeprecatedAPIsInUse: 2,
    DeprecatedAPIsInFuture: 3,
  },
  NodeTerminations: {},
  FlakyServices: {},
  BestPractices: {},
  Addons: {
    ExpiredWorkloadCertificates: 0,
    AboutToExpireWorkloadCertificates: 1,
  },
};

export const initialGroupState: Omit<Group, "isExpanded" | "paginationModel"> =
  {
    statuses: defaultViolationStatuses,
    checkTypes: [],
    searchTerm: "",
  };

export const checkTypeToImpactGroup: Record<CheckType, ImpactGroupCategory> = {
  [CheckType.NoisyNeighbor]: "NoisyNeighbors",
  [CheckType.NodeTerminationAutoScaling]: "Victims",
  [CheckType.NodeTerminationSpotInstance]: "Victims",
  [CheckType.UnderProvisionedWorkloads]: "UnderProvisionedWorkloads",
  [CheckType.UnschedulableTime]: "UnschedulableTime",
  [CheckType.HpaMax]: "HPAsReachedMax",
  [CheckType.DeprecatedApis]: "DeprecatedAPIsInUse",
  [CheckType.KubernetesVersionDeprecated]: "DeprecatedClustersDetected",
  [CheckType.ThrottledCpu]: "ServicesThrottled",
  [CheckType.RestartingContainers]: "ContainerRestarts",
  [CheckType.RequestsLimitsRatio]: "UnplannedResourceUsage",
  [CheckType.KubernetesEndOfLife]: "DeprecatedClustersDetected",
  [CheckType.SinglePointOfFailure]: "SinglePointOfFailureServices",
  [CheckType.DeploymentMissingReplicas]: "BestPractices",
  [CheckType.MissingPdb]: "BestPractices",
  [CheckType.MissingTopologySpreadConstraint]: "BestPractices",
  [CheckType.HpaMinAvailability]: "BestPractices",
  [CheckType.MissingHpa]: "BestPractices",
  [CheckType.PriorityClassNotSet]: "BestPractices",
  [CheckType.CpuRequestsMissing]: "BestPractices",
  [CheckType.MemoryRequestsMissing]: "BestPractices",
  [CheckType.CpuLimitsMissing]: "BestPractices",
  [CheckType.MemoryLimitsMissing]: "BestPractices",
  [CheckType.LivenessProbeMissing]: "BestPractices",
  [CheckType.ReadinessProbeMissing]: "BestPractices",
  [CheckType.CertificateExpiration]: "ExpiredWorkloadCertificates",
  [CheckType.SyntheticDegradedService]: "BestPractices",
  [CheckType.SyntheticEolDeprecationOutdated]: "BestPractices",
  [CheckType.SyntheticNodePressure]: "BestPractices",
  [CheckType.CascadingFailure]: "BestPractices",
  // TODO: Only used in mock data, should be moved under the __test__ folder
};

export const initialSeveritiesCount = {
  high: 0,
  medium: 0,
  low: 0,
};
