import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ResourceConsumptionDetails from "./detailsComponents/ResourceConsumptionDetails";

interface Metric {
  value: number;
  unit: string;
  percentage: string;
}
export interface ResourceConsumptionOutput {
  podsMetrics: {
    pods: {
      name: string;
      namespace?: string;
      serviceId: string;
      totalMemory: Metric;
      totalCpu: Metric;
      containers: {
        name: string;
        memory: Metric;
        cpu: Metric;
      }[];
    }[];
    hiddenPods?: number;
    failureMessage: string | null;
  };
  nodeMetrics: {
    name: string;
    memory: Metric;
    cpu: Metric;
    allocatableMemory: Metric;
    allocatableCpu: Metric;
    failureMessage: string | null;
  };
}

export default class ResourceConsumptionCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.icon = check.passed ? "info" : "warn";
    this.title = "Resource Consumption";
    this.shortOutput = check.passed
      ? "Pods consumption on the node"
      : "Pods could be consuming too much resources";
    this.description =
      "We are fetching live data from node to understand the most resource consuming pods.";
    this.action = (
      <>
        We ran{" "}
        <GrayHighlight>
          kubectl get pods -o wide | {"<NODE>"} | awk {"{'print $1'}"} | xargs
          -n1 kubectl top pods
        </GrayHighlight>{" "}
        to check Memory/Disk/PID pressure.
      </>
    );
    this.question = "What is the pods and node consumption?";
    this.output = check.output as ResourceConsumptionOutput;
    this.isFinding = true;
  }

  renderCheckDetails(): JSX.Element {
    return <ResourceConsumptionDetails check={this} />;
  }
}
