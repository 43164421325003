import styled from "styled-components";

export const RelativeContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  & > img {
    width: 100%;
    user-select: none;
  }
`;

export const ClickableArea = styled.div`
  position: absolute;
  cursor: pointer;
`;

export const CloseButtonArea = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 33px;
  left: 22.2px;
  border-radius: 4px;
`;
