import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import { User } from "@/generated/auth";
import { createFilteredRoleGetter } from "@/components/Settings/Users/createFilteredRoleGetter";
import { ActionsCell as actionsCell } from "@/components/Settings/Users/components/ActionsCell";
import { usersTableActions } from "@/components/Settings/Users/types";

const getPermanentRoles = (params: Partial<GridValueGetterParams>) => {
  return createFilteredRoleGetter(params.row as User, false);
};

const getTemporaryRoles = (params: Partial<GridValueGetterParams>) => {
  return createFilteredRoleGetter(params.row as User, true);
};

const getStatus = (params: Partial<GridValueGetterParams>) => {
  return params.row.deletedAt
    ? ""
    : params.row.lastLoginAt
    ? "Active"
    : "Awaiting first login";
};

export const getColumnDefinitions = ({
  onRestore,
  onEdit,
  onDelete,
}: usersTableActions) => [
  {
    field: "displayName",
    headerName: "Name",
    flex: 1,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    sortable: false,
  },
  {
    field: "roles",
    headerName: "Roles",
    renderCell: getPermanentRoles,
    flex: 2,
    sortable: false,
  },
  {
    field: "tempRoles",
    headerName: "Temporary Roles",
    renderCell: getTemporaryRoles,
    flex: 2,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: getStatus,
    flex: 1,
    sortable: false,
  },
  {
    field: "actions",
    headerName: "",
    renderCell: (params: GridRenderCellParams<User>) =>
      actionsCell({ params, onEdit, onDelete, onRestore }),
    flex: 0.5,
    sortable: false,
  },
];
