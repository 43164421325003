import { useState } from "react";

import certExpiresDrawer from "./images/certificate-expires-drawer.png";
import { CloseButton, RelativeContainer, SelectableRow } from "./styles";
import certManagerImgSrc from "./images/cert-manager.png";
import { MockDrawer } from "./MockDrawer";

export const CertManagerView: React.FC = () => {
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  return (
    <RelativeContainer>
      <img src={certManagerImgSrc}></img>
      <SelectableRow
        height="6%"
        top="42.6%"
        onClick={() => {
          setDrawerImgSrc(certExpiresDrawer);
        }}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        onClose={() => setDrawerImgSrc(null)}
        imgSrc={drawerImgSrc}
        closeButton={<CloseButton onClick={() => setDrawerImgSrc(null)} />}
      ></MockDrawer>
    </RelativeContainer>
  );
};
