import React from "react";
import { StorageClass as StorageClassType } from "kubernetes-types/storage/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { SC } from "@komodorio/design-system/icons";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

export default class StorageClass implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [{ label: ResourceTabName.Yaml }];
  readonly actions = [AvailableActions.Delete, AvailableActions.Describe];
  readonly fullObj;
  readonly defaultTab;

  constructor(cluster: string, sc: StorageClassType, agentId: string) {
    this.agentId = agentId;
    this.id = sc.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = sc.metadata?.namespace ?? "";
    this.name = sc.metadata?.name ?? "";
    this.kind = sc.kind ?? "";
    this.labels = sc.metadata?.labels ?? {};
    this.annotations = sc.metadata?.annotations ?? {};
    this.icon = SC;
    this.fullObj = sc;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
