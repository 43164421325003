import React from "react";
import styled from "styled-components";

import { gray10 } from "../../../Colors";

import Arrow from "./Arrow";

const Container = styled.div`
  display: grid;
  justify-content: start;
  gap: 0.4rem;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;
`;

const ArrowsContainer = styled.div`
  display: grid;
  gap: 0.2rem;
  align-items: center;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum Direction {
  down = -1,
  up = 1,
}

interface SortTitleProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  direction: Direction;
  active: boolean;
}
const SortTitle: React.FC<SortTitleProps> = ({
  title,
  direction,
  active,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      {title}
      <ArrowsContainer>
        <Arrow
          width="0.5rem"
          color={active && direction === Direction.up ? gray10 : "#B3B6BC"}
          direction="up"
        />
        <Arrow
          width="0.5rem"
          color={active && direction === Direction.down ? gray10 : "#B3B6BC"}
          direction="down"
        />
      </ArrowsContainer>
    </Container>
  );
};

export default SortTitle;
