import { useMemo, useState } from "react";

import { LineGroup } from "@/components/common/EventsChart/TimelineChart/types";

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 20;

export const useSwimlanesPagination = (
  swimlanes: LineGroup[],
  paginateSwimlanes: boolean
) => {
  const linesCount = useMemo(() => swimlanes.length, [swimlanes.length]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const pageOffset = useMemo(
    () => (linesCount === 0 ? 0 : (page * rowsPerPage) % linesCount),
    [linesCount, page, rowsPerPage]
  );
  const swimlanesPerPage = useMemo(() => {
    let swimlanesPerPage = swimlanes;
    if (paginateSwimlanes) {
      swimlanesPerPage = swimlanes.slice(pageOffset, pageOffset + rowsPerPage);
    }
    return swimlanesPerPage.reverse();
  }, [swimlanes, pageOffset, paginateSwimlanes, rowsPerPage]);

  const totalRows = swimlanes.length;
  const shouldShowPagination = totalRows > DEFAULT_PAGE_SIZE;

  return {
    swimlanesPerPage,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    shouldShowPagination,
  };
};
