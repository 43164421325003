import { palette, theme } from "@komodorio/design-system";
import {
  ActionItem,
  Divider,
  MenuList,
  Popover,
  Typography,
} from "@komodorio/design-system/deprecated";
import {
  ChevronDown16,
  Cog16,
  List16,
  Logout16,
  Share16,
} from "@komodorio/design-system/icons";
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import useUserMetadata from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { isSandbox } from "../../shared/utils/sandbox";
import isElectronContext from "../../shared/context/electron/isElectronContext";
import { useGetUserById } from "../../shared/hooks/auth-service/client/users/useGetUserById";
import {
  AUDIT_ROUTE,
  SETTINGS_ROUTE,
  USER_EMAIL_SETTINGS_ROUTE,
} from "../routes/routes";

import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { ProfileIcon } from "@/components/ProfileIcon/ProfileIcon";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: inherit;
  margin-left: 1.25rem;
  height: 2.5rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 2rem 0.5rem 0;
`;

const WidthConstrainer = styled.div`
  & > div {
    width: inherit;
  }
`;

const TruncatableTypography = styled(Typography)`
  margin-left: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NonShrinkingContainer = styled.div`
  flex-shrink: 0;
`;

const ProfileButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { canViewAudit } = useHasPermissions();
  const { showUserEmailSettings } = useOverridableFlags();
  const { userId } = useUserMetadata();
  const { data: user } = useGetUserById({ id: userId });
  const { displayName, email } = user ?? {};

  const sandboxMode = isSandbox();

  const handleClose = () => setOpen(false);

  const popoverContent = (
    <MenuList>
      <Header>
        <ProfileIcon />
        <div>
          <Typography variant="title" size="medium">
            {displayName}
          </Typography>
          {!isElectronContext() && (
            <Typography size="medium" color={theme.foreground.fgSubtle}>
              {email}
            </Typography>
          )}
        </div>
      </Header>
      <Divider spacing="0.25rem" />
      {!isElectronContext() && canViewAudit && (
        <>
          <ActionItem
            icon={List16}
            disabled={sandboxMode}
            onClick={() => {
              navigate(AUDIT_ROUTE);
              handleClose();
            }}
          >
            Audit
          </ActionItem>
          <Divider spacing="0.25rem" />
        </>
      )}
      <ActionItem
        icon={Cog16}
        disabled={sandboxMode}
        onClick={() => {
          navigate(SETTINGS_ROUTE);
          handleClose();
        }}
      >
        Settings
      </ActionItem>
      {!!showUserEmailSettings && (
        <>
          <Divider spacing="0.25rem" />
          <ActionItem
            icon={Share16}
            disabled={sandboxMode}
            onClick={() => {
              navigate(USER_EMAIL_SETTINGS_ROUTE);
              handleClose();
            }}
          >
            Email settings
          </ActionItem>
        </>
      )}
      {!isElectronContext() && (
        <>
          <Divider spacing="0.25rem" />
          <ActionItem
            icon={Logout16}
            disabled={sandboxMode}
            onClick={() => navigate("/logout")}
          >
            Logout
          </ActionItem>
        </>
      )}
    </MenuList>
  );

  return (
    <WidthConstrainer aria-label="profile button">
      <Popover
        isOpen={open}
        handleClose={handleClose}
        content={popoverContent}
        positions={["top"]}
        align="start"
        containerStyle={{ zIndex: "101" }}
      >
        <ButtonContainer onClick={() => setOpen(!open)}>
          <NonShrinkingContainer>
            <ProfileIcon />
          </NonShrinkingContainer>
          <TruncatableTypography
            variant="text"
            size="medium"
            color={palette.white[0]}
          >
            {displayName}
          </TruncatableTypography>
          <NonShrinkingContainer>
            <ChevronDown16 />
          </NonShrinkingContainer>
        </ButtonContainer>
      </Popover>
    </WidthConstrainer>
  );
};

export default ProfileButton;
