import Button from "@mui/material/Button";
import styled from "styled-components";
import Link from "@mui/material/Link";

import { EmptyState } from "../../common/UpgradeAgentVersionAlert/EmptyState";
import { useNavigateToWorkspace } from "../../workspaces/WorkspacesTopBar/hooks";
import { ALL_CLUSTERS } from "../../workspaces/WorkspacesTopBar/constants";
import { AriaLabels } from "../../../shared/config/ariaLabels";

const EmptyStateContainer = styled.div`
  display: grid;
`;

interface ClustersEmptyState {
  onActionClick: () => void;
}
export const ClustersEmptyState: React.FC<ClustersEmptyState> = ({
  onActionClick,
}) => (
  <EmptyStateContainer>
    <EmptyState title="No clusters connected">
      <Button variant="text" color="primary" onClick={onActionClick}>
        Connect new cluster
      </Button>
    </EmptyState>
  </EmptyStateContainer>
);

export const NonClusterWorkspaceEmptyState: React.FC = () => {
  const navigateToWorkspace = useNavigateToWorkspace();
  const navigateToAllClusters = () => navigateToWorkspace(undefined);

  return (
    <EmptyStateContainer
      aria-label={AriaLabels.ClustersView.NonClusterWorkspaceEmptyState}
    >
      <EmptyState
        title={
          <>
            You are viewing a specific workspace.
            <br />
            Switch to "{ALL_CLUSTERS}" to see the full list.
          </>
        }
      >
        <Link
          color="primary"
          underline="none"
          sx={{ cursor: "pointer", fontSize: "14px" }}
          onClick={navigateToAllClusters}
        >
          Switch to {ALL_CLUSTERS.toLowerCase()}
        </Link>
      </EmptyState>
    </EmptyStateContainer>
  );
};
