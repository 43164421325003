import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { PolicyDrawerHeader } from "./PolicyDrawerHeader/PolicyDrawerHeader";
import { PolicyDrawerDetails } from "./PolicyDrawerDetails/PolicyDrawerDetails";
import { PolicyDrawerTableTopSection } from "./PolicyDrawerConfigurations/PolicyDrawerTableTopSection";
import { CheckConfigurationsTable } from "./PolicyDrawerConfigurations/CheckConfigurationsTable";
import { PolicyDrawerFooter } from "./PolicyDrawerFooter/PolicyDrawerFooter";
import { PolicyDrawerTabs } from "./PolicyDrawerTabs";
import { StyledDivider } from "./PolicyDrawerStyles";

import { usePopulatePolicyById } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/usePopulatePolicyById";
import { ConfirmationDialogListener } from "@/components/reliability/components/pages/policies/PolicyDrawer/ConfirmationDialogListener";
import { useFetchDefaultPolicyData } from "@/components/reliability/components/pages/policies/policiesHooks";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { useOnCloseDrawerWithConfirmation } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useOnCloseDrawerWithConfirmation";
import useEscape from "@/shared/hooks/useEscape";

const Container = styled.div`
  padding: 16px 24px;
  height: 100%;
  overflow-y: auto;
`;

const ClickAwayListenerContent = styled.div`
  display: contents;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckTitle = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

const ChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const {
  policies: {
    policiesDrawer: { container: containerAriaLabel },
  },
} = reliabilityArialLabels;

export const PolicyDrawerContent: React.FC = () => {
  const { isFetching } = usePopulatePolicyById();
  useFetchDefaultPolicyData();

  const onCloseDrawerWithConfirmation = useOnCloseDrawerWithConfirmation();
  useEscape(onCloseDrawerWithConfirmation);

  if (isFetching) {
    return (
      <Container aria-label={`${containerAriaLabel} fetching`}>
        <LoaderContainer>
          <LinesLoader />
        </LoaderContainer>
      </Container>
    );
  }

  return (
    <ClickAwayListener onClickAway={onCloseDrawerWithConfirmation}>
      <ClickAwayListenerContent>
        <Container aria-label={`${containerAriaLabel} content`}>
          <PolicyDrawerHeader />
          <PolicyDrawerDetails />
          <StyledDivider $marginBottom={32} />
          <ChecksContainer>
            <CheckTitle variant={"h4"}>Checks configurations</CheckTitle>
            <PolicyDrawerTabs />
            <PolicyDrawerTableTopSection />
            <CheckConfigurationsTable />
          </ChecksContainer>
          <ConfirmationDialogListener />
        </Container>
        <PolicyDrawerFooter />
      </ClickAwayListenerContent>
    </ClickAwayListener>
  );
};
