import React, { useState } from "react";
import styled from "styled-components";

import CopyComponent from "../../../../../common/Copy";
import {
  blackGreyText,
  blueForUIText,
  gray10,
  gray9,
} from "../../../../../../Colors";
import { TextRegular } from "../../../../../common/typography";
import { integrationLinks } from "../utils";
import { StyledExternalLink } from "../styles";
import GuideTooltip, { SentryImageGuide } from "../GuideToolTip";
import permissions from "../assets/permissions.png";
import resourceSubscription from "../assets/resource-subscription.png";
import { LeftSpace } from "../../../../../common/Spaces/leftSpace";

const StyledTextRegular = styled(TextRegular)`
  color: ${blackGreyText};
`;

const StyledCopy = styled(CopyComponent)`
  color: ${blackGreyText};
  border-radius: 2px;
  font-size: 12px;
  margin-top: 0.25rem;
  font-family: "Roboto Mono";
`;

const RelativePositionText = styled(TextRegular)`
  position: relative;
`;

const NumberGrid = styled(TextRegular)`
  grid-column-start: 1;
  font-weight: bold;
`;

const TextGrid = styled.div`
  grid-column: 2 / -1;
`;

const Break = styled.div`
  height: 0.25rem;
`;

export const FirstInstruction: React.FC = () => {
  return (
    <>
      <NumberGrid>01</NumberGrid>
      <TextGrid>
        <StyledTextRegular>
          Create a new Internal Integration in{" "}
          <StyledExternalLink
            color={blueForUIText}
            href={integrationLinks.sentryWeb}
          >
            Sentry
          </StyledExternalLink>
          <br />
          In Sentry, go to:
          <br />
          <b>Settings {">"} Developer Settings</b>
          <br />
          From there, click on <b>New Internal Integration</b>
          <br />
          <TextRegular>
            <StyledExternalLink
              color={gray10}
              href={integrationLinks.sentryWebDocs}
            >
              How to create a new Internal Integration in Sentry
            </StyledExternalLink>
          </TextRegular>
        </StyledTextRegular>
      </TextGrid>
    </>
  );
};

export const SecondInstruction: React.FC<{ webhookUrl: string }> = ({
  webhookUrl,
}) => {
  return (
    <>
      <NumberGrid>02</NumberGrid>
      <TextGrid>
        <StyledTextRegular>
          In the Webhook URL field enter the following value:
          <StyledCopy value={webhookUrl} backgroundColor={gray9} />
        </StyledTextRegular>
      </TextGrid>
    </>
  );
};

export const ThirdInstruction: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <NumberGrid>03</NumberGrid>
      <TextGrid>
        <StyledTextRegular>
          Define the following permissions:
          <Break />
          <StyledTextRegular>
            Project:
            <LeftSpace spaces={2}>
              <b>Read</b>
            </LeftSpace>
          </StyledTextRegular>
          <Break />
          <StyledTextRegular>
            Issue {`&`} Event:
            <LeftSpace spaces={2}>
              <b>Read</b>
            </LeftSpace>
          </StyledTextRegular>
          <Break />
          <RelativePositionText>
            <GuideTooltip
              show={show}
              handleClose={() => {
                setShow(false);
              }}
            >
              <SentryImageGuide imageSource={permissions} maxHeight={"15rem"} />
            </GuideTooltip>
            <StyledExternalLink
              color={gray10}
              onClick={() => {
                setShow(true);
              }}
            >
              Where is it?
            </StyledExternalLink>
          </RelativePositionText>
        </StyledTextRegular>
      </TextGrid>
    </>
  );
};

export const FourthInstruction: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <NumberGrid>04</NumberGrid>
      <TextGrid>
        <StyledTextRegular>
          Check “issue webhook” checkbox
          <Break />
          <RelativePositionText>
            <GuideTooltip
              show={show}
              handleClose={() => {
                setShow(false);
              }}
            >
              <SentryImageGuide
                imageSource={resourceSubscription}
                maxHeight={"7rem"}
              />
            </GuideTooltip>
            <StyledExternalLink
              color={gray10}
              onClick={() => {
                setShow(true);
              }}
            >
              Where is it?
            </StyledExternalLink>
          </RelativePositionText>
        </StyledTextRegular>
      </TextGrid>
    </>
  );
};

const instructions = [
  FirstInstruction,
  SecondInstruction,
  ThirdInstruction,
  FourthInstruction,
];

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export default instructions;
