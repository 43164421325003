import axios, { AxiosInstance, AxiosResponse } from "axios";
import { datadogRum } from "@datadog/browser-rum";

class AxiosClient {
  private readonly axiosInstance: AxiosInstance;
  private readonly name: string;

  constructor(
    name: string,
    baseURL: string,
    headers?: { [key: string]: string | undefined }
  ) {
    this.name = name;
    this.axiosInstance = axios.create({
      baseURL,
      headers,
    });

    this.setupInterceptors();
  }

  private setupInterceptors(): void {
    let requestStartTime: Date;

    this.axiosInstance.interceptors.request.use(
      (config) => {
        requestStartTime = new Date();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const endTime = new Date();
        const requestDuration = endTime.getTime() - requestStartTime.getTime();

        datadogRum.addAction(this.name, {
          url: response.config.url,
          operation_name: response.config.method,
          duration: requestDuration,
          status: response.status,
          size: response.headers["content-length"],
        });

        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public getInstance(): AxiosInstance {
    return this.axiosInstance;
  }
}

export default AxiosClient;
