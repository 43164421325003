import React from "react";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { parseISO } from "date-fns";
import { get } from "lodash";

import { extractLastModifyFromService } from "../../../shared/utils/postgresMigrationUtils";
import { extractJobsStatusandLastRunTime } from "../../../shared/utils/extractJobsStatusandLastRunTime";
import { Header } from "../common/Header/Header";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoWFEventsTab from "../tabs/EventsTab/ArgoWFEventsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ArgoWorkflowDetailsTab } from "../tabs/ArgoWorkflows/ArgoWorkflowDetailsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
import { ArgoStatus } from "../../argoWorkflowsView/ArgoStatus";
import { ArgoPhases } from "../../argoWorkflowsView/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ArgoWFPodsTab } from "../tabs/PodsTab/ArgoWFPodsTab";
import { Workflow as ArgoWorkflowType } from "../../argoWorkflowsView/kubernetes-types";

import { isCurrentlyDeploying } from "./utils";

import Resource, { KomodorServiceType, ResourceTab, ResourceTabName } from ".";

export const ARGO_WORKFLOW_KIND = "Workflow";
export const ARGO_WORKFLOW_KOMODOR_KIND = "ArgoWorkflows.Workflows";

export default class ArgoWorkflow implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind = ARGO_WORKFLOW_KIND;
  readonly komodorKind = ARGO_WORKFLOW_KOMODOR_KIND;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Describe];

  readonly selector;
  readonly k8sUid;
  readonly currentlyDeploying;
  readonly lastModified;
  readonly lastDeployStartTime;
  readonly lastDeployEndDate;
  readonly datadogData;
  readonly sentryData;
  readonly controlledBy: string | undefined;
  readonly ownerReferences;
  readonly workflowState: ArgoPhases;
  readonly workflowStatusReason;
  readonly workflowStatusMessage;
  readonly workflowLastRunTime;
  readonly isDeleted;
  readonly inactive;
  readonly fullObj;
  readonly finishedAt: Date | undefined;
  readonly startedAt: Date | undefined;
  readonly defaultTab;

  constructor(
    cluster?: string,
    argoWf?: ArgoWorkflowType,
    agentId?: string,
    komodorService?: KomodorServiceType
  ) {
    if (komodorService) {
      this.agentId = komodorService?.agentId ?? "";
      this.id = komodorService.id;
      this.cluster = komodorService.k8sClusterName;
      this.namespace = komodorService.namespace;
      this.name = komodorService.displayName;
      this.labels = (komodorService.k8s_metadata?.labels ?? {}) as {
        [key: string]: string;
      };
      this.annotations = (komodorService.k8s_metadata?.annotations ?? {}) as {
        [key: string]: string;
      };
      this.k8sUid = komodorService?.k8s_metadata?.k8sUid;
      this.selector = Object.keys(
        (komodorService?.k8s_metadata?.selector as LabelSelector) ?? {}
      ).length
        ? (komodorService?.k8s_metadata?.selector as LabelSelector)
        : ({
            matchLabels: { "workflows.argoproj.io/workflow": this.name },
          } as LabelSelector);
      this.datadogData = komodorService.datadog_data;
      this.sentryData = komodorService.sentry_data;

      const lastDeployEndTime = komodorService.deploy_state?.lastDeployEndTime;
      const lastDeployStartTime =
        komodorService.deploy_state?.lastDeployStartTime;
      this.currentlyDeploying = isCurrentlyDeploying(
        lastDeployStartTime,
        lastDeployEndTime
      );
      this.lastModified = extractLastModifyFromService(komodorService);
      this.lastDeployStartTime = lastDeployStartTime
        ? parseISO(lastDeployStartTime)
        : undefined;
      this.lastDeployEndDate = lastDeployEndTime
        ? parseISO(lastDeployEndTime)
        : undefined;

      const { jobLastRunTime, jobStatusReason, jobStatusMessage } =
        extractJobsStatusandLastRunTime(
          komodorService.k8s_metadata?.status ?? ""
        );
      this.workflowState = (komodorService.k8s_metadata?.jobState ??
        "") as ArgoPhases;
      this.workflowLastRunTime = jobLastRunTime;
      this.workflowStatusReason = jobStatusReason;
      this.workflowStatusMessage = jobStatusMessage;

      const status = JSON.parse(komodorService.k8s_metadata?.status ?? "{}");

      this.startedAt = status?.startedAt
        ? parseISO(status.startedAt)
        : this.lastDeployStartTime;
      this.finishedAt = status?.finishedAt
        ? parseISO(status.finishedAt)
        : undefined;

      this.isDeleted = komodorService.isDeleted;
      this.inactive = komodorService.inactive;
      this.drawerTabs = [
        { label: ResourceTabName.Events },
        {
          label: ResourceTabName.Pods,
          disabled: this.isDeleted || this.inactive,
        },
        { label: ResourceTabName.Details },
        { label: ResourceTabName.Yaml },
      ];
    } else {
      this.fullObj = argoWf;
      this.agentId = agentId ?? "";
      this.id = argoWf?.metadata?.uid ?? "";
      this.cluster = cluster ?? "";
      this.namespace = argoWf?.metadata?.namespace ?? "";
      this.name = argoWf?.metadata?.name ?? "";
      this.labels = argoWf?.metadata?.labels ?? {};
      this.annotations = argoWf?.metadata?.annotations ?? {};
      this.selector =
        get(argoWf?.metadata, "selector") ??
        ({
          matchLabels: {
            "workflows.argoproj.io/workflow": this.name,
          },
        } as LabelSelector);
      this.controlledBy = undefined;
      this.ownerReferences = [];
      this.workflowState = (argoWf?.status?.phase ?? "") as ArgoPhases;
      this.startedAt = argoWf?.status?.startedAt
        ? parseISO(argoWf.status.startedAt)
        : undefined;
      this.finishedAt = argoWf?.status?.finishedAt
        ? parseISO(argoWf.status.finishedAt)
        : undefined;
      this.lastDeployStartTime = this.startedAt;
    }
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ArgoWFEventsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ArgoWFPodsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={2}>
          <ArgoWorkflowDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={3}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
        status={<ArgoStatus status={this.workflowState} />}
      />
    );
  }
}
