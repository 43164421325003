import { useMemo } from "react";
import {
  Dictionary,
  Record,
  String,
  Boolean,
  Null,
  Array,
  Static,
} from "runtypes";

import { useOverridableFlags } from "../../../context/featureFlags/OverridableFlags";
import { getATMResourcesURL } from "../client";
import useAtmRequest, { AtmRequestResult } from "../useAtmRequest";

const DEFAULT_ATM_PAGE_SIZE = 50;

const ResourceListResponse = Record({
  data: Array(Dictionary(String, String)),
  isPaginating: Boolean,
  mostRecentUpdate: String.Or(Null),
  sessionId: String,
  failureMessage: String.Or(Null),
});
export type AtmResourceListResponseType = Static<typeof ResourceListResponse>;

export interface RequestListResourcesBody {
  resourceKind: string;
  agentId: string;
  namespaces: string[];
  clusterName: string;
  outputType?: string;
  mostRecentDataFrom?: string | null;
  pageSize?: number;
  labelSelector?: string;
  fieldSelector?: string;
}
const useAtmListResources = (
  agentId: string,
  cluster: string,
  resource: string,
  outputType: string,
  handleResponse: (responseJSON: AtmResourceListResponseType) => void,
  mostRecentDataFrom: string | null = null,
  namespaces: string[] = [],
  labelSelector?: string,
  fieldSelector?: string
): AtmRequestResult => {
  const { inspectionAtm, atmPagesize } = useOverridableFlags();

  let pageSize = DEFAULT_ATM_PAGE_SIZE;
  if (Number.isInteger(atmPagesize)) {
    pageSize = atmPagesize as number;
  }

  const reqBody = useMemo<RequestListResourcesBody | null>(() => {
    if (!inspectionAtm || !cluster || !agentId || !namespaces) return null;
    return {
      resourceKind: resource,
      agentId,
      namespaces,
      clusterName: cluster,
      outputType: outputType,
      mostRecentDataFrom: mostRecentDataFrom,
      pageSize: pageSize,
      labelSelector,
      fieldSelector,
    };
  }, [
    inspectionAtm,
    cluster,
    agentId,
    namespaces,
    resource,
    outputType,
    mostRecentDataFrom,
    pageSize,
    labelSelector,
    fieldSelector,
  ]);

  const handleValidatedResponse = (response: unknown) => {
    handleResponse(response as AtmResourceListResponseType);
    return true;
  };

  return useAtmRequest(
    getATMResourcesURL(),
    JSON.stringify(reqBody),
    handleValidatedResponse
  );
};

export default useAtmListResources;
