import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { Section, SectionName } from "../../common/styles";
import { useIssueDuration } from "../../AvailabilityTriage/sections/ImpactSection";
import { FlexColumn } from "../../../../../../../InvestigationMode/availability/common/styles";
import { FlexErrorSpan } from "../../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/AvailabilitySummaryUtils";

const InfoSection: React.FC<{
  startTime: Date;
  endTime: Date | null;
  errorMessage?: string;
}> = ({ startTime, endTime, errorMessage }) => {
  const issueDuration = useIssueDuration(startTime, endTime);

  return (
    <Section>
      <SectionName>info</SectionName>
      <FlexColumn>
        <div>
          <Typography
            fontWeight={500}
            color={muiColors.gray[500]}
            variant="overline"
          >
            Issue Duration
          </Typography>
          <Typography variant="h5">{issueDuration}</Typography>
        </div>

        {errorMessage && (
          <div>
            <Typography
              fontWeight={500}
              color={muiColors.gray[500]}
              variant="overline"
            >
              Message
            </Typography>
            <Typography>
              <FlexErrorSpan>{errorMessage}</FlexErrorSpan>
            </Typography>
          </div>
        )}
      </FlexColumn>
    </Section>
  );
};

export default InfoSection;
