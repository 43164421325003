import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { DagView } from "@/components/k8sAddons/addons/workflows/DagView";
import { DagsListPage } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsListPage";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import {
  WORKFLOW_DAG_ID_PARAM,
  WORKFLOW_DAGS,
} from "@/components/routes/routes";

export const Workflows: React.FC = () => {
  return (
    <FiltersContextProvider
      urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
      searchTypeFilterOperator={Operator.Like}
    >
      <Routes>
        <Route path={WORKFLOW_DAGS} element={<DagsListPage />} />
        <Route
          path={`${WORKFLOW_DAGS}/:${WORKFLOW_DAG_ID_PARAM}`}
          element={<DagView />}
        />
        <Route path="*" element={<Navigate to={WORKFLOW_DAGS} replace />} />
      </Routes>
    </FiltersContextProvider>
  );
};
