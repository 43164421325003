import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { mapValues, keys } from "lodash";

import { FILTERS_BAR_FILTERS_PARAM_KEY } from "../../../config/urlSearchParamsKeys";

const asStringSets = (o: { [key: string]: string[] }) => {
  return mapValues(o, (values) => new Set(keys(values)));
};

const useCheckedFilters = (): {
  [x: string]: Set<string>;
} | null => {
  const location = useLocation();

  const checkedFilters = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get(FILTERS_BAR_FILTERS_PARAM_KEY);

    if (!fromUrl) return null;
    try {
      return asStringSets(JSON.parse(fromUrl));
    } catch {
      return null;
    }
  }, [location.search]);
  return checkedFilters;
};

export default useCheckedFilters;
