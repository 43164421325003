/* eslint-disable max-lines */
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import ViewTimelineOutlined from "@mui/icons-material/ViewTimelineOutlined";
import ListAltOutlined from "@mui/icons-material/ListAltOutlined";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  useDrawerUrlState,
  useStringifiedStateInSearchParams,
} from "../../../../../shared/hooks/state";
import { TimelineChart } from "../../../../common/EventsChart/TimelineChart/TimelineChart";
import { ProcessList } from "../../../../common/ProcessList";
import { SetTimeWindow } from "../../../../../shared/types/TimeWindow";
import {
  ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE,
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
  TIMELINE_ZOOM_CHANGED_PARAM_KEY,
  EVENTS_TAB_TIME_WINDOW,
  EVENTS_TAB_TIMEFRAME,
} from "../../../../../shared/config/urlSearchParamsKeys";
import { EmptyState } from "../../../../Inspection/Helm/tabs/components/EmptyData";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { LinesLoader } from "../../../../common/loaders/Line";
import { useDDRumViewAndTimingListener } from "../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";
import useChartCallbacks from "../content/useChartCallbacks";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { WorkflowPod } from "@/generated/addonsApi";
import { useGetWorkflowPodsEvents } from "@/components/ResourceView/tabs/EventsTab/WorkflowEventsTab/useGetWorkflowPodsEvents";
import { useDrawerTimeWindowFromUrl } from "@/components/ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/useDrawerTimeWindowFromUrl";
import { useWorkflowChartSwimlanes } from "@/components/ResourceView/tabs/EventsTab/WorkflowEventsTab/useWorkflowChartSwimlanes";
import EventGroup from "@/components/common/EventGroup";
import { useNodeTerminationsByOwnerKomodorUidsFromAPIQuery } from "@/components/common/EventGroup/nodeEvent/useNodeChangeEvents";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useWorkflowIssuesQuery } from "@/components/common/EventGroup/workflowIssues/useWorkflowIssues";
import { WorkflowConfigType } from "@/components/monitorsView/common/types";
import { buildGeneralWorkflowName } from "@/components/common/ProcessList/details/NodeChangeDetails/ImpactedResourcesSection/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-bottom: 16px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  gap: 16px;
  align-items: center;
`;

const StyledToggleButton = styled(ToggleButton)`
  && {
    display: flex;
    column-gap: 8px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const Panel = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "block" : "none")};
`;

enum ViewToggle {
  Timeline = "timeline",
  List = "list",
}

export const WorkflowEventsTab: React.FC<{ resource: WorkflowRun }> = ({
  resource,
}) => {
  const [highlightedEvents, setHighlightedEvents] = useState<string[]>([]);
  const [showOnlyPodsWithIssues, setShowOnlyPodsWithIssues] = useState(false);
  const [eventId, setEventId] = useDrawerUrlState<string>(
    EVENTS_TAB_PREFIX + EVENT_PARAM_KEY
  );

  const [intentId, setIntentId] = useState<string | null>();

  const [viewToggle, setViewToggle] =
    useStringifiedStateInSearchParams<ViewToggle>(
      ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE
    );

  useEffect(() => {
    if (!viewToggle) {
      setViewToggle(ViewToggle.Timeline, true);
    }
  }, [setViewToggle, viewToggle]);

  const { data, isFetching: isLoadingWorkflowPodsEvents } =
    useGetWorkflowPodsEvents({
      workflowRun: resource,
      showOnlyPodsWithIssues,
    });
  const podsData = data?.data.data as WorkflowPod[] | undefined;

  const ownerKomodorUids = useMemo(() => {
    return [
      buildKomodorUid({
        clusterName: resource.cluster,
        namespace: resource.namespace,
        kind: "GeneralWorkflow",
        resourceName: buildGeneralWorkflowName({
          engine: resource.engine,
          dag: resource.dagId,
          run: resource.name,
        }),
      }),
    ];
  }, [resource]);
  const [nodeTerminations, isLoadingNodeTerminations] =
    useNodeTerminationsByOwnerKomodorUidsFromAPIQuery(
      ownerKomodorUids,
      resource.timeWindow
    );
  const [nodeIssues, isLoadingIssues] = useWorkflowIssuesQuery(
    resource.timeWindow,
    [],
    [WorkflowConfigType.NodeIssue]
  );

  const workflowChartSwimlanes = useWorkflowChartSwimlanes({
    nodeTerminations,
    nodeIssues,
    workflowPods: podsData,
  });
  const eventGroups = useMemo(
    () =>
      workflowChartSwimlanes.reduce<EventGroup[]>(
        (acc, { events }) => [...acc, ...events],
        []
      ),
    [workflowChartSwimlanes]
  );

  const { onChartMouseEnter, onChartMouseLeave, onChartClick } =
    useChartCallbacks(setHighlightedEvents, setEventId);

  const [zoomChanged, setZoomChanged] =
    useStringifiedStateInSearchParams<boolean>(TIMELINE_ZOOM_CHANGED_PARAM_KEY);
  const [timeWindow, setTimeWindow] = useDrawerTimeWindowFromUrl(
    resource.timeWindow,
    EVENTS_TAB_TIME_WINDOW,
    EVENTS_TAB_TIMEFRAME
  );

  useEffect(() => {
    if (
      !zoomChanged &&
      (timeWindow.start.getTime() !== resource.timeWindow.start.getTime() ||
        timeWindow.end.getTime() !== resource.timeWindow.end.getTime())
    ) {
      setTimeWindow(resource.timeWindow, { replace: true });
    }
  }, [setTimeWindow, resource.timeWindow, zoomChanged, timeWindow]);

  const onZoomChange: SetTimeWindow = (tw, options) => {
    setTimeWindow(tw, options);
    setZoomChanged(true, true);
  };
  const onResetZoom = () => {
    setTimeWindow(resource.timeWindow);
    setZoomChanged(false, true);
  };

  const isEmpty = eventGroups.length === 0;

  const isFetching =
    isLoadingWorkflowPodsEvents || isLoadingNodeTerminations || isLoadingIssues;
  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName: dataDogViewNames.workflowDagViewEventsTab,
    addTimingParams: {
      enable: !isFetching,
    },
  });

  return (
    <Container>
      <Header>
        <Typography variant="h4">
          Events {!isFetching ? `(${eventGroups.length})` : null}
        </Typography>
        <div>
          {zoomChanged && <Button onClick={onResetZoom}>Reset zoom</Button>}
        </div>
        <SwitchContainer>
          <Switch
            checked={showOnlyPodsWithIssues}
            disabled={!resource.hasIssues}
            onChange={() => {
              setShowOnlyPodsWithIssues((prevState) => !prevState);
            }}
            aria-label={AriaLabels.Workflows.ShowOnlyPodsWithFailuresToggle}
          />
          <Typography variant="body2">Show only pods with failures</Typography>
        </SwitchContainer>
        <ToggleButtonGroup
          exclusive
          onChange={(_e, value) => value && setViewToggle(value)}
          value={viewToggle}
        >
          <StyledToggleButton value={ViewToggle.Timeline}>
            <ViewTimelineOutlined sx={{ width: 16, height: 16 }} />
            Timeline
          </StyledToggleButton>
          <StyledToggleButton value={ViewToggle.List}>
            <ListAltOutlined sx={{ width: 16, height: 16 }} />
            List
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Header>
      {isFetching && isEmpty ? (
        <LinesLoader />
      ) : isEmpty ? (
        <EmptyState text="No events" />
      ) : (
        <>
          <Panel show={viewToggle === ViewToggle.Timeline}>
            <TimelineChart
              onMouseEnter={onChartMouseEnter}
              onMouseLeave={onChartMouseLeave}
              onClick={onChartClick}
              swimlanes={workflowChartSwimlanes}
              timeWindow={timeWindow}
              setTimeWindow={onZoomChange}
              highlightedId={intentId ?? undefined}
              paginateSwimlanes
              xAxisPosition="top"
            />
          </Panel>
          <Panel show={viewToggle === ViewToggle.List}>
            <ProcessList
              highlightedEvents={highlightedEvents}
              showServiceColumn={false}
              eventGroups={eventGroups}
              showMoreEventsPanel={true}
              eventId={eventId}
              setEventId={setEventId}
              setIntentId={setIntentId}
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
            />
          </Panel>
        </>
      )}
    </Container>
  );
};
