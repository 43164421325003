import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { format } from "date-fns";
import { AccountPlan as AccountPlanType } from "komodor-types";

const TrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanTitle: React.FC<{
  trialEnd?: string;
  accountPlan: AccountPlanType | "";
}> = ({ trialEnd, accountPlan }) => {
  const trialEndDate =
    trialEnd && trialEnd !== "null" ? new Date(trialEnd || "") : null;
  const isTrialPlan = accountPlan === AccountPlanType.trial;

  const capitalizePlan =
    accountPlan.charAt(0).toUpperCase() + accountPlan.slice(1);

  const formattedDate = trialEndDate
    ? format(new Date(trialEndDate), "dd/MM/yyyy")
    : null;

  return (
    <TrialContainer>
      <Typography size={"large"} bold>
        {`${capitalizePlan} Plan`}
      </Typography>
      {isTrialPlan && formattedDate && (
        <Typography
          size={"medium"}
        >{`Trial end date: ${formattedDate}`}</Typography>
      )}
    </TrialContainer>
  );
};

export default PlanTitle;
