import React from "react";

import useFreemiumModals, {
  freemiumModalNames,
} from "../../../shared/hooks/useFreemiumModals";
import { restrictionsNames } from "../Restrictions";

import UpgradePlanMeetingModal from "./UpgradePlanMeetingModal/UpgradePlanMeetingModal";
import { RestrictionAchievedModal } from "./RestrictionAchievedModal/RestrictionAchievedModal";
import { PlansDataProvider } from "./useGetPlansData";
import { TrialEndModals } from "./TrialEndModal/TrialEndModals";

const freemiumModalsMap: Record<freemiumModalNames, JSX.Element | null> = {
  [freemiumModalNames.TRIAL_ENDED]: <TrialEndModals />,
  [freemiumModalNames.UPGRADE_PLAN]: <UpgradePlanMeetingModal />,
  [freemiumModalNames.USERS_RESTRICTION]: (
    <RestrictionAchievedModal freemiumKey={restrictionsNames.USERS} />
  ),
  [freemiumModalNames.CLUSTERS_RESTRICTION]: (
    <RestrictionAchievedModal freemiumKey={restrictionsNames.CLUSTERS} />
  ),
  [freemiumModalNames.POLICIES_RESTRICTION]: (
    <RestrictionAchievedModal freemiumKey={restrictionsNames.POLICIES} />
  ),
  [freemiumModalNames.ROLES_RESTRICTION]: (
    <RestrictionAchievedModal freemiumKey={restrictionsNames.ROLES} />
  ),
  [freemiumModalNames.LOCK_RESTRICTION]: (
    <RestrictionAchievedModal freemiumKey={restrictionsNames.LOCK} />
  ),
  [freemiumModalNames.CHOSE_FREE]: null,
};

const FreemiumModals: React.FC = () => {
  const modalsState = useFreemiumModals();

  return (
    <PlansDataProvider>
      {modalsState ? freemiumModalsMap[modalsState] : null}
    </PlansDataProvider>
  );
};

export default FreemiumModals;
