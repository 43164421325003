import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Refresh16, Check16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { RowFieldContainer } from "../../monitorsView/monitorsConfiguration/common/styles";

type FetchingLabelProps = {
  isPaginating?: boolean;
};

const Container = styled.div`
  padding-inline: 0.75rem;
`;

const LabelContainer = styled(RowFieldContainer)`
  gap: 0.5rem;
`;
const RotatingIcon = styled(Refresh16)`
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
const DoneLabel = styled(LabelContainer)`
  animation: fadeOut ease 6s;
  animation-fill-mode: forwards;
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const FetchingLabel: React.FC<FetchingLabelProps> = ({ isPaginating }) => (
  <Container>
    {isPaginating ? (
      <LabelContainer data-e2e-selector={"loader"}>
        <RotatingIcon color={palette.green[700]} />
        <Typography bold color={palette.green[700]}>
          Fetching live data...
        </Typography>
      </LabelContainer>
    ) : (
      <DoneLabel data-e2e-selector={"atmInspectionDone"}>
        <Check16 color={palette.green[700]} />
        <Typography bold color={palette.green[700]}>
          Done
        </Typography>
      </DoneLabel>
    )}
  </Container>
);

export default FetchingLabel;
