import styled from "styled-components";

const Grid = styled.div<{
  gridTemplateColumns?: string;
  gridColumnGap?: string;
}>`
  display: grid;
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns ? `grid-template-columns: ${gridTemplateColumns};` : ""}
  ${({ gridColumnGap }) =>
    gridColumnGap ? `grid-column-gap: ${gridColumnGap};` : ""}
`;

export default Grid;
