import React from "react";
import {
  Button,
  Typography,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";

import { useRemoveWorkflowConfiguration } from "./MonitorRules/actions";

interface DeleteRuleModalProps {
  ruleToDelete: string | null;
  handleClose: () => void;
  refreshMonitors: () => void;
}

const DeleteRuleModal: React.FC<DeleteRuleModalProps> = ({
  ruleToDelete,
  handleClose,
  refreshMonitors,
}) => {
  const removeRule = useRemoveWorkflowConfiguration();

  return (
    <Modal isOpen={!!ruleToDelete} width="25rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Delete Rule</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Are you sure you want to delete this rule?
          <br />
          (This action can not be undone)
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          size="small"
          onClick={async () => {
            if (ruleToDelete) {
              await removeRule({ id: ruleToDelete });
            }
            refreshMonitors();
            handleClose();
          }}
        >
          Delete
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteRuleModal;
