import { immer } from "zustand/middleware/immer";
import { create } from "zustand";

import { PodLogsStore } from "./types";
import { initialState } from "./initialState";

export const usePodLogsStore = create(
  immer<PodLogsStore>((set) => ({
    ...initialState,
    resetLogs: () => set(() => ({ logs: [] })),
    addLogs: (logs) => set((prev) => ({ logs: [...logs, ...prev.logs] })),
  }))
);
