import { useStateInLocalStorage } from "../../../shared/hooks/state";
import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import { useGetParseState } from "../utils";

type Props<T> = {
  localStorageItem: LocalStorageItem;
  defaultValue: T;
};

export const useLocalStorage = <T>({
  localStorageItem,
  defaultValue,
}: Props<T>): [T, (value: string) => void] => {
  const [item, setItem] = useStateInLocalStorage(
    localStorageItem,
    JSON.stringify(defaultValue)
  );

  const parsedItem = useGetParseState<T>(item, defaultValue);

  return [parsedItem, setItem];
};
