/* eslint-disable max-lines */
import { replace } from "lodash";

import { INSPECTION_ROUTE } from "../../routes/routes";

import {
  KubernetesNodesResource,
  KubernetesPodsResource,
  KubernetesPVCResource,
  KubernetesResource,
  SingleGroupResources,
  KubernetesHPAResource,
  KubernetesServiceResource,
  KubernetesConfigMapResource,
  KubernetesSecretsResource,
} from "./SupportedResourcesTypes";
import { KubernetesIngressResource } from "./supportedResourcesTypes/KubernetesIngressResource";
import { KubernetesHelmResource } from "./supportedResourcesTypes/KubernetesHelmResource";
import { KubernetesReplicaSetResource } from "./supportedResourcesTypes/KubernetesReplicaSetResource";
import { KubernetesDeploymentResource } from "./supportedResourcesTypes/KubernetesDeploymentResource";
import { KubernetesJobResource } from "./supportedResourcesTypes/KubernetesJobResource";
import { KubernetesCronJobResource } from "./supportedResourcesTypes/KubernetesCronJobResource";
import { KubernetesStatefulSetResource } from "./supportedResourcesTypes/KubernetesStatefulSetResource";
import { KubernetesDaemonSetResource } from "./supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesPVResource } from "./supportedResourcesTypes/KubernetesPVResource";
import { KubernetesStorageClassResource } from "./supportedResourcesTypes/KubernetesStorageClassResource";
import { KubernetesResourceQuotaResource } from "./supportedResourcesTypes/KubernetesResourceQuotaResource";
import { KubernetesLimitRangeResource } from "./supportedResourcesTypes/KubernetesLimitRangeResource";
import { KubernetesPDBResource } from "./supportedResourcesTypes/KubernetesPDBResource";
import { KubernetesEndpointResource } from "./supportedResourcesTypes/KubernetesEndpointResource";
import { KubernetesNetworkPolicyResource } from "./supportedResourcesTypes/KubernetesNetworkPolicyResource";
import { KubernetesEndpointSliceResource } from "./supportedResourcesTypes/KubernetesEndpointSliceResource";
import { KubernetesCRDResource } from "./supportedResourcesTypes/KubernetesCRDResource";
import { KubernetesCRResource } from "./supportedResourcesTypes/KubernetesCRResource";
import { KubernetesServiceAccountsResource } from "./supportedResourcesTypes/KubernetesServiceAccountsResource";
import { KubernetesClusterRolesResource } from "./supportedResourcesTypes/KubernetesClusterRolesResource";
import { KubernetesRolesResource } from "./supportedResourcesTypes/KubernetesRolesResource";
import { KubernetesClusterRoleBindingsResource } from "./supportedResourcesTypes/KubernetesClusterRoleBindingsResource";
import { KubernetesRoleBindingsResource } from "./supportedResourcesTypes/KubernetesRoleBindingsResource";

export const SupportedKubernetesResources: KubernetesResource[] = [
  KubernetesNodesResource,
  KubernetesPodsResource,
  KubernetesPVCResource,
  KubernetesConfigMapResource,
  KubernetesSecretsResource,
  KubernetesResourceQuotaResource,
  KubernetesLimitRangeResource,
  KubernetesHPAResource,
  KubernetesPDBResource,
  KubernetesServiceResource,
  KubernetesEndpointResource,
  KubernetesIngressResource,
  KubernetesNetworkPolicyResource,
  KubernetesPVResource,
  KubernetesStorageClassResource,
  KubernetesReplicaSetResource,
  KubernetesEndpointSliceResource,
  KubernetesCRDResource,
  KubernetesDeploymentResource,
  KubernetesJobResource,
  KubernetesCronJobResource,
  KubernetesStatefulSetResource,
  KubernetesDaemonSetResource,
  KubernetesHelmResource,
  KubernetesCRResource,
  KubernetesServiceAccountsResource,
  KubernetesClusterRolesResource,
  KubernetesRolesResource,
  KubernetesRoleBindingsResource,
  KubernetesClusterRoleBindingsResource,
];

export const getRoutePathFromKubernetesResource = (
  kubernetesResource: KubernetesResource,
  clusterName = ":cluster"
): string => {
  const routeName = replace(kubernetesResource.NavBarName, /\s/g, "-")
    .trim()
    .toLowerCase();
  if (kubernetesResource.Kind === KubernetesCRDResource.Kind) {
    return `${INSPECTION_ROUTE}/custom-resources/${clusterName}`;
  }

  return SingleGroupResources.includes(kubernetesResource.Group)
    ? `${INSPECTION_ROUTE}/${routeName}/${clusterName}`
    : `${INSPECTION_ROUTE}/${kubernetesResource.Group}/${routeName}/${clusterName}`;
};
