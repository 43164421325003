import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

export const DrawerMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const TabContentContainer = styled.div`
  padding: 8px 24px;
  background: ${muiColors.gray[50]};
  height: 100%;
  box-sizing: border-box;
`;

export const AccordionContentItemContainer = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
`;
