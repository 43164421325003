import React, { useMemo } from "react";
import { MetadataProps } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";

import { matchAgeWithUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellUtils";
import {
  selectCertificate,
  useAddonFullLiveState,
} from "@/components/k8sAddons/LiveStateDrawer/hooks/useAddonFullLiveState";
import { certificateStatusToColor } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { SeverityColor } from "@/components/k8sAddons/types";

export const useTopSectionProps = () => {
  const { data: addonData } = useAddonFullLiveState(selectCertificate);

  return useMemo(() => {
    if (!addonData?.data)
      return {
        title: "",
        status: "",
        color: "info" as SeverityColor,
      };
    const { name, status } = addonData.data.object.header;
    const color = certificateStatusToColor[status];

    return {
      title: name,
      status,
      color,
    };
  }, [addonData?.data]);
};

export const useMetadataItems = () => {
  const { data: addonData } = useAddonFullLiveState(selectCertificate);

  return useMemo<MetadataProps[]>(() => {
    if (!addonData?.data) return [];
    const { clusterName, namespace, age, generation } =
      addonData.data.object.header;
    return [
      {
        title: "Type",
        value: <Typography variant={"body2"}>Certificate</Typography>,
      },
      {
        title: "Cluster",
        value: <Typography variant={"body2"}>{clusterName}</Typography>,
      },
      {
        title: "Namespace",
        value: <Typography variant={"body2"}>{namespace}</Typography>,
      },
      {
        title: "Age",
        value: (
          <Typography variant={"body2"}>
            {matchAgeWithUnits(["d"], age)}
          </Typography>
        ),
      },
      {
        title: "Generation",
        value: <Typography variant={"body2"}>{generation}</Typography>,
      },
    ];
  }, [addonData?.data]);
};
