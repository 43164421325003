import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import PathNotFound from "../404/PathNotFound";
import ResourceView from "../ResourceView/ResourceView";
import { dispatchEvent } from "../../shared/hooks/analytics";
import useResourceWithInterval from "../ResourceView/useResource";
import {
  parseKomodorUid,
  useBuildKomodorUidForService,
} from "../../shared/hooks/resources-api/resourcesAPIUtils";
import { useActiveAgent } from "../../shared/hooks/useAgents";

type ServiceParams = {
  serviceId?: string;
};
const useServiceParams = (): ServiceParams => {
  return useParams<ServiceParams>();
};

const ServiceView: React.FC = () => {
  const { serviceId } = useServiceParams();
  const komodorUID = useBuildKomodorUidForService(serviceId ?? "");

  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUID ?? "") ?? {};

  const agentId = useActiveAgent(cluster) ?? "";

  const { resource, isFetching } = useResourceWithInterval({
    agentId,
    resourceType: kind,
    resourceName: name,
    cluster,
    namespace,
    executeMutation: !!agentId && !!komodorUID,
  });

  useEffect(() => {
    dispatchEvent(AnalyticEvents.ServiceView.View, { serviceId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !resource ? (
    !isFetching ? (
      <PathNotFound />
    ) : null
  ) : (
    <ResourceView resource={resource} />
  );
};

export default ServiceView;
