import React, { useMemo } from "react";
import styled from "styled-components";

import definitions from "../definitions";
import useInstallationSubscription from "../installed/useInstallationSubscription";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

import AvailableIntegrationTile from "./AvailableIntegrationTile";

const Container = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, 28rem);
`;

const AvailableIntegrations: React.FC = () => {
  const { data: installations = [] } = useInstallationSubscription();
  const featureFlags = useOverridableFlags();

  const content = useMemo(() => {
    const installed = new Set(
      installations.map((i) => i.integration.toString())
    );

    return (
      <>
        {Object.entries(definitions).map(([integrationType, integration]) => {
          const hasRequiredFeatureFlag = integration.featureFlag
            ? !!featureFlags[integration.featureFlag]
            : true;
          const showIntegration =
            !integration.hideAvailable && hasRequiredFeatureFlag;

          return (
            showIntegration && (
              <AvailableIntegrationTile
                key={integration.title}
                integration={integration}
                isInstalled={installed.has(integrationType)}
              />
            )
          );
        })}
      </>
    );
  }, [featureFlags, installations]);

  return <Container>{content}</Container>;
};

export default AvailableIntegrations;
