import React from "react";

import { GenericErrorMessage } from "../../../shared/components/GenericErrorMessage";

import { RelatedResourcesPanel } from "./styles";

const RelatedResourceListError: React.FC = () => {
  return (
    <RelatedResourcesPanel>
      <GenericErrorMessage />
    </RelatedResourcesPanel>
  );
};

export default RelatedResourceListError;
