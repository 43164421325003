import React, { useEffect, useState } from "react";
import styled from "styled-components";

import WorkflowCheck from "../../../../monitorsView/workflowRunView/checks";
import {
  CheckIcon,
  Container,
  Subtitle,
  Title,
} from "../../../../monitorsView/workflowRunView/checks/CheckCard";
import { BoldGrayText } from "../../../../monitorsView/workflowRunView/checks/CheckDetailsDrawer";
import { Divider, NormalText } from "../../../../monitorsView/common/styles";
import Arrow from "../../Arrow";
import { gray10 } from "../../../../../Colors";

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 1.5rem 1rem;
`;

const EventDetailsCheckCard: React.FC<{
  check: WorkflowCheck;
  isStartOpen?: boolean;
}> = ({ check, isStartOpen }) => {
  const [open, setOpen] = useState(isStartOpen);

  useEffect(() => {
    if (isStartOpen) setOpen(true);
  }, [isStartOpen]);

  return (
    <>
      <Container onClick={() => setOpen(!open)}>
        <CheckIcon icon={check.icon} />
        <div>
          <Title>{check.title.toUpperCase()}</Title>
          <Subtitle>{check.shortOutput}</Subtitle>
        </div>
        <Arrow
          width="0.75rem"
          color={gray10}
          direction={open ? "down" : "right"}
        />
      </Container>
      {!open && <Divider />}
      <>
        {open && (
          <>
            <div />
            <Details>
              {check.renderCheckEventDetails(() => null) ||
                check.renderCheckDetails(() => null)}
              {check.action && (
                <>
                  <br />
                  <BoldGrayText>What did we check?</BoldGrayText>
                  <NormalText>{check.action}</NormalText>
                </>
              )}
              {check.description && (
                <>
                  <br />
                  <BoldGrayText>Why did we check this?</BoldGrayText>
                  <NormalText>{check.description}</NormalText>
                </>
              )}
            </Details>
            <Divider />
          </>
        )}
      </>
    </>
  );
};

export default EventDetailsCheckCard;
