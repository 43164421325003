import React from "react";

import { JobsPodsOutput } from "../availability/types";
import { Divider } from "../../../common/styles";
import { PodsSummary } from "../availability/detailsComponents/AvailabilitySummaryUtils";
import { Details } from "../../styles";
import { useOpinionatedSummary } from "../availability/detailsComponents/hooks";

const JobsPodsSummary: React.FC<{
  podsCheckOutput: JobsPodsOutput;
  clusterName: string;
  namespace: string;
  endTime: Date;
}> = ({ podsCheckOutput, clusterName, namespace, endTime }) => {
  const opinionatedSummary = useOpinionatedSummary(podsCheckOutput);
  return opinionatedSummary?.length > 0 ? (
    <>
      <Details>
        <PodsSummary
          opinionatedSummary={opinionatedSummary}
          endTime={endTime}
          clusterName={clusterName}
          namespace={namespace}
        />
      </Details>
      <Divider />
    </>
  ) : null;
};

export default JobsPodsSummary;
