import styled from "styled-components";

import { Container } from "../common/styles";

import { InvestigationContent } from "@/components/ResourceView/AiInvestigation/common/InvestigationContent";
import { InvestigationTitle } from "@/components/ResourceView/AiInvestigation/common/InvestigationTitle";
import { useResourceAiInvestigation } from "@/components/ResourceView/AiInvestigation/common/useResourceAiInvestigation";
import Resource from "@/components/ResourceView/resources";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

const StyledContainer = styled(Container)`
  align-items: unset;
`;

export const AIInvestigationCard: React.FC<{
  className?: string;
  requestId: string;
  resource: Resource;
}> = ({ resource, requestId, className }) => {
  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: resource.cluster,
      namespace: resource.namespace,
      resourceType: resource.kind,
      resourceName: resource.name,
    },
    requestId,
    resource,
    isHealthy: false,
    flowType: "Unhealthy pod - Investigation Mode",
  });

  if (!enabled) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      <InvestigationTitle
        richSession={richSession}
        onShareClicked={onShareClicked}
        titleProps={{ fontWeight: 500 }}
      />
      <InvestigationContent
        richSession={richSession}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
        showBorder={false}
      />
    </StyledContainer>
  );
};
