import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";
import { TextWithResourcesLinks } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TextWithResourcesLinks";
import { StyledOrderedList } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureStyles";

const Container = styled.div`
  background-color: ${muiColors.indigo[25]};
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  border: 1px solid ${muiColors.gray[200]};
`;

export const TimelineDetails: React.FC = () => {
  const { detailed_incident_timeline, affected_services = [] } =
    useGetCascadingFailureSupportingData() ?? {};

  const content = useMemo(() => {
    if (!detailed_incident_timeline?.length) {
      return null;
    }
    return detailed_incident_timeline.map((eventStr, index) => {
      return (
        <li key={index}>
          <TextWithResourcesLinks
            text={eventStr}
            resourceKomodorUids={affected_services}
          />
        </li>
      );
    });
  }, [affected_services, detailed_incident_timeline]);

  if (!detailed_incident_timeline?.length) {
    return null;
  }

  return (
    <Container>
      <StyledOrderedList>{content}</StyledOrderedList>
    </Container>
  );
};
