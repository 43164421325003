import React from "react";

import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { Details } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/Details";

export const CascadingFailure: React.FC = () => {
  const { data } = useViolation();

  const { supportingData, headerProps, summaryProps } = useGetCommonDrawerProps(
    data?.data.violation
  );

  if (!supportingData?.cascadingFailure) {
    return null;
  }

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={<Details />}
    />
  );
};
