import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import { gray13 } from "../../../Colors";
import { Divider } from "../../common/ProcessList/details/common";
import Steps from "../../common/Steps";
import TabButtons from "../../common/TabButtons";
import {
  CheckCategory,
  CheckRawReultWithSpecLevel,
  StaticPreventionResult,
} from "../StaticPreventionUtils";
import { DeployEventsResponse } from "../../../generated/resourcesApi";

import StaticPreventionYaml from "./StaticPreventionYaml";
import StaticPrevntionCards from "./StaticPrevntionCards";
import { useCategories } from "./hooks/useCategories";
import { filterResults, tabs } from "./utils";

const Container = styled.div`
  display: flex;
  overflow: hidden;
  gap: 1.5rem;
  height: 92%;
`;

const StyledSteps = styled(Steps)`
  height: 92.5%;
  text-align: left;
`;

const TasbAndStepsContainer = styled.div`
  text-align: center;
  border: ${gray13} 0.1rem solid;
  border-radius: 0.5rem;
  flex: 1 1 0;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
`;

const Label = styled(Typography).attrs({ variant: "body3" })`
  && {
    margin-left: 8px;
  }
`;

interface StaticPreventionModalContentProps {
  parsedResults: StaticPreventionResult;
  ignoreCallback?: (
    check: CheckRawReultWithSpecLevel,
    expirationDate: string
  ) => void;
  unignoreCallback?: (check: CheckRawReultWithSpecLevel) => void;
  lastDeploy: DeployEventsResponse | undefined;
  defaultFilter?: CheckCategory;
}

const StaticPreventionModalContent: React.FC<
  StaticPreventionModalContentProps
> = ({
  parsedResults,
  ignoreCallback,
  unignoreCallback,
  lastDeploy,
  defaultFilter,
}) => {
  const [curTab, setCurTab] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    defaultFilter ? [defaultFilter] : []
  );
  const [shouldShowYaml, setShouldShowYaml] = useState(false);
  const [shouldShowPassedChecks, setShouldShowPassedChecks] = useState(false);

  const categories = useCategories(parsedResults);

  const resetFilters = useCallback(
    () => setSelectedOptions(categories),
    [categories]
  );

  const isChecksFiltered = useMemo(
    () => selectedOptions.length < categories.length,
    [categories.length, selectedOptions.length]
  );

  const onNoChecksToShowClick = useCallback(() => {
    if (isChecksFiltered) {
      resetFilters();
    } else {
      setShouldShowPassedChecks(true);
    }
  }, [isChecksFiltered, resetFilters, setShouldShowPassedChecks]);

  const MultiSelectOptions = useMemo<MuiSelectionOption<string>[]>(
    () => categories.map((category) => ({ value: category, label: category })),
    [categories]
  );

  const checkedResults = useMemo(
    () =>
      filterResults(
        parsedResults.checks,
        selectedOptions,
        shouldShowPassedChecks
      ),
    [parsedResults.checks, selectedOptions, shouldShowPassedChecks]
  );

  const ignoredResults = useMemo(
    () =>
      filterResults(
        parsedResults.ignoredChecks,
        selectedOptions,
        shouldShowPassedChecks
      ),
    [parsedResults.ignoredChecks, selectedOptions, shouldShowPassedChecks]
  );

  useEffect(() => {
    if (!!categories.length && !selectedOptions.length) resetFilters();
  }, [categories, resetFilters, selectedOptions.length]);

  const onChange = (options: MuiSelectionOption<string>[]) => {
    if (!options.length) return resetFilters();
    const values = options.map((x) => {
      return x.value;
    });
    setSelectedOptions(values);
  };

  return (
    <Container>
      <TasbAndStepsContainer>
        <HeaderContainer>
          <TabButtons
            tabs={tabs}
            currentTabIndex={curTab}
            onTabClick={(selectedTab) => {
              setCurTab(selectedTab);
            }}
          />
          <FormControlLabel
            label={<Label>Show YAML</Label>}
            control={
              <Switch
                aria-label="Show YAML switch"
                checked={shouldShowYaml}
                onChange={(event) => setShouldShowYaml(event.target.checked)}
              />
            }
          />
        </HeaderContainer>
        <Divider />
        <FilterContainer>
          <AdvancedMultiSelect
            options={MultiSelectOptions}
            placeholder="Filter by check type"
            width="340px"
            onChange={onChange}
            value={selectedOptions.map((x) => ({ value: x, label: x }))}
          />
          <FormControlLabel
            label={<Typography variant="body3">Show passed checks</Typography>}
            control={
              <Checkbox
                size="small"
                checked={shouldShowPassedChecks}
                onChange={(event) =>
                  setShouldShowPassedChecks(event.target.checked)
                }
              />
            }
          />
        </FilterContainer>
        <Divider />
        <StyledSteps curStep={curTab}>
          <StaticPrevntionCards
            checks={checkedResults}
            ignoreCallback={ignoreCallback}
            isChecksFiltered={isChecksFiltered}
            emptyStateCb={onNoChecksToShowClick}
          />
          <StaticPrevntionCards
            checks={ignoredResults}
            unignoreCallback={unignoreCallback}
            isChecksFiltered={isChecksFiltered}
            emptyStateCb={onNoChecksToShowClick}
          />
        </StyledSteps>
      </TasbAndStepsContainer>
      {shouldShowYaml && <StaticPreventionYaml lastDeploy={lastDeploy} />}
    </Container>
  );
};

export default StaticPreventionModalContent;
