import React from "react";
import styled from "styled-components";

import { TimelineEvents } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/TimelineEvents";
import { RelatedIssues } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedIssues";
import { sectionDivider } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureStyles";
import { Summary } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/Summary/Summary";
import { RelatedEvidence } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/RelatedEvidence";
import { SuggestedRemediation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SuggestedRemediation";
import { GroupingReasons } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/GroupingReasons";
import { KlaudiaAiSection } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/KlaudiaAiSection";

const Container = styled.div`
  margin-top: 16px;
  ${sectionDivider};
`;

export const Details: React.FC = () => {
  return (
    <div>
      <KlaudiaAiSection />
      <Container>
        <Summary />
        <TimelineEvents />
        <RelatedEvidence />
        <SuggestedRemediation />
        <GroupingReasons />
        <RelatedIssues />
      </Container>
    </div>
  );
};
