import React from "react";
import styled from "styled-components";

const FormContainer = styled.div`
  margin: 2.5rem 1rem 0 1rem;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #6e7a96;
  text-align: center;
`;

const BigTitle = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  color: #3d4048;
  text-align: center;
`;

const CustomIntegrationInstallation: React.FC<{
  name: string;
  isForm?: boolean;
  children?: React.ReactNode;
}> = ({ name, isForm = true, children }) => {
  return (
    <div>
      <Title>Install {name}</Title>
      <BigTitle>
        {isForm
          ? "Enter the following details"
          : "Follow the instructions below"}
      </BigTitle>
      <FormContainer>{children}</FormContainer>
    </div>
  );
};

export default CustomIntegrationInstallation;
