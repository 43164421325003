import React from "react";
import { capitalize } from "lodash";

import { Audit } from "../../../generated/graphql";
import { AuditData } from "../types";
import { DefaultAuditDetails } from "../AuditDetailsCommon";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

export default class AccountAudit implements AuditObject {
  readonly data: Audit;
  readonly auditData: AuditData;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as AuditData;
  }

  renderDetails() {
    return <DefaultAuditDetails data={this.auditData} />;
  }

  renderAffectedResources() {
    return "Account settings";
  }

  renderAction() {
    return "update account settings";
  }

  renderEventType() {
    return "update";
  }

  renderJson(): Record<string, unknown> {
    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": "Update account settings",
      Resource: "Account",
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource": "Account settings",
      Change: JSON.stringify(this.auditData.details),
    };
  }
}
