import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { groupBy } from "lodash";
import { Button } from "@komodorio/design-system/deprecated";

import Arrow from "../../common/ProcessList/Arrow";
import k8sIcon from "../../integrations/management/logos/kubernetes.svg";
import { blackGreyText } from "../../../Colors";
import { WorkflowConfigType, WorkflowConfiguration } from "../common/types";
import { LightText } from "../common/fonts";
import { getDefaultRule } from "../monitorsConfiguration/common/RuleTypeSection";
import { MONITORS_RULE_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";

import MonitorRules from "./MonitorRules/MonitorRules";

const Container = styled.div<{ open: boolean }>`
  ${({ open }) => (open ? `background-color: white;` : "")}
  padding-right: 2rem;
  border-radius: 8px;
`;

const ClusterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClusterLeftItems = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ClusterButton = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 0.5rem;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    ${({ open }) => (!open ? `background-color: #eceff2;` : "")}
  }
`;

const K8sIcon = styled.img.attrs({ src: k8sIcon, alt: "k8s-cluster" })`
  width: 1.5rem;
`;

interface ClusterMonitorsProps {
  clusterName: string;
  monitors: WorkflowConfiguration[];
  handleAddRule: (
    rule: WorkflowConfiguration | undefined,
    isTypeStatic?: boolean
  ) => void;
  handleEditRule: (ruleId: string) => void;
  handleDeleteRule: (ruleId: string) => void;
}

const ClusterMonitors: React.FC<ClusterMonitorsProps> = ({
  clusterName,
  monitors,
  handleAddRule,
  handleEditRule,
  handleDeleteRule,
}) => {
  const [showMonitors, setShowMonitors] = useState(false);

  const rulesPerType = useMemo(() => {
    return Object.entries(groupBy(monitors, ({ type }) => type));
  }, [monitors]);

  useEffect(() => {
    const ruleId = new URLSearchParams(location.search).get(
      MONITORS_RULE_PARAM_KEY
    );
    if (monitors.some(({ id }) => id === ruleId)) {
      setShowMonitors(true);
    }
  }, [monitors]);

  return (
    <Container open={showMonitors} data-e2e-selector="cluster-monitors">
      <ClusterRow>
        <ClusterLeftItems>
          <ClusterButton
            open={showMonitors}
            onClick={() => setShowMonitors((prev) => !prev)}
          >
            <Arrow
              direction={showMonitors ? "down" : "right"}
              width="0.75rem"
              color={blackGreyText}
            />
            <K8sIcon />
            <div>
              Cluster: <b>{clusterName}</b>
            </div>
          </ClusterButton>
          <LightText>{rulesPerType.length} configured monitors</LightText>
        </ClusterLeftItems>
        <Button
          variant="secondary"
          size="small"
          onClick={() => handleAddRule(getDefaultRule(clusterName))}
        >
          + Add Rule
        </Button>
      </ClusterRow>
      {showMonitors && (
        <div>
          {rulesPerType.map(([type, rules]) => (
            <MonitorRules
              key={type}
              clusterName={clusterName}
              type={type as WorkflowConfigType}
              rules={rules}
              handleAddRule={handleAddRule}
              handleEditRule={handleEditRule}
              handleDeleteRule={handleDeleteRule}
            />
          ))}
        </div>
      )}
    </Container>
  );
};

export default ClusterMonitors;
