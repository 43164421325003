import React from "react";
import { Job as JobType } from "kubernetes-types/batch/v1.d";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Job as JobIcon } from "@komodorio/design-system/icons";
import { parseISO } from "date-fns";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodsTab from "../tabs/PodsTab/PodsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import EventsTab from "../tabs/EventsTab/EventsTab";
import { extractLastModifyFromService } from "../../../shared/utils/postgresMigrationUtils";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import InfoTab from "../tabs/InfoTab/InfoTab";
import NodesTab from "../tabs/NodesTab/NodesTab";
import { extractJobsStatusandLastRunTime } from "../../../shared/utils/extractJobsStatusandLastRunTime";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { JobHeader } from "../headers/JobHeader";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ServiceLogsTab from "../tabs/ServiceLogsTab/ServiceLogsTab";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { isCurrentlyDeploying } from "./utils";

import Resource, { KomodorServiceType, ResourceTab, ResourceTabName } from ".";

export default class Job implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly icon = JobIcon;
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Logs },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Nodes },
    { label: ResourceTabName.Info },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [
    AvailableActions.JobRerun,
    AvailableActions.Describe,
    AvailableActions.Compare,
    AvailableActions.Edit,
    AvailableActions.Delete,
  ];

  readonly selector;
  readonly k8sUid;
  readonly currentlyDeploying;
  readonly lastModified;
  readonly lastDeployStartTime;
  readonly lastDeployEndDate;
  readonly datadogData;
  readonly sentryData;
  readonly controlledBy;
  readonly ownerReferences;
  readonly jobState;
  readonly jobStatusReason;
  readonly jobStatusMessage;
  readonly jobLastRunTime;
  readonly parallelism;
  readonly completions;
  readonly isDeleted;
  readonly inactive;
  readonly fullObj;
  readonly containers;
  readonly defaultTab;

  constructor(
    cluster?: string,
    job?: JobType,
    agentId?: string,
    komodorService?: KomodorServiceType
  ) {
    if (komodorService) {
      this.agentId = komodorService?.agentId ?? "";
      this.id = komodorService.id;
      this.cluster = komodorService.k8sClusterName;
      this.namespace = komodorService.namespace;
      this.name = komodorService.displayName;
      this.kind = komodorService.kind ?? "";
      this.labels = (komodorService.k8s_metadata?.labels ?? {}) as {
        [key: string]: string;
      };
      this.annotations = (komodorService.k8s_metadata?.annotations ?? {}) as {
        [key: string]: string;
      };
      this.k8sUid = komodorService?.k8s_metadata?.k8sUid;
      this.selector = komodorService.k8s_metadata?.selector as
        | LabelSelector
        | undefined;
      this.datadogData = komodorService.datadog_data;
      this.sentryData = komodorService.sentry_data;

      const lastDeployEndTime = komodorService.deploy_state?.lastDeployEndTime;
      const lastDeployStartTime =
        komodorService.deploy_state?.lastDeployStartTime;
      const currentlyDeploying = isCurrentlyDeploying(
        lastDeployStartTime,
        lastDeployEndTime
      );
      this.currentlyDeploying = currentlyDeploying;
      this.lastModified = extractLastModifyFromService(komodorService);
      this.lastDeployStartTime = lastDeployStartTime;
      this.lastDeployEndDate = lastDeployEndTime
        ? parseISO(lastDeployEndTime)
        : undefined;

      const { jobLastRunTime, jobState, jobStatusReason, jobStatusMessage } =
        extractJobsStatusandLastRunTime(
          komodorService.k8s_metadata?.status ?? ""
        );
      this.jobState = jobState;
      this.jobLastRunTime = jobLastRunTime;
      this.jobStatusReason = jobStatusReason;
      this.jobStatusMessage = jobStatusMessage;

      this.isDeleted = komodorService.isDeleted;
      this.inactive = komodorService.inactive;
      this.drawerTabs = [
        { label: ResourceTabName.Events },
        {
          label: ResourceTabName.Logs,
          disabled: this.isDeleted || this.inactive,
        },
        {
          label: ResourceTabName.Pods,
          disabled: this.isDeleted || this.inactive,
        },
        {
          label: ResourceTabName.Nodes,
          disabled: this.isDeleted || this.inactive,
        },
        { label: ResourceTabName.Info },
        { label: ResourceTabName.Yaml },
      ];
    } else {
      this.fullObj = job;
      this.agentId = agentId ?? "";
      this.id = job?.metadata?.uid ?? "";
      this.cluster = cluster ?? "";
      this.namespace = job?.metadata?.namespace ?? "";
      this.name = job?.metadata?.name ?? "";
      this.kind = job?.kind ?? "";
      this.labels = job?.metadata?.labels ?? {};
      this.annotations = job?.metadata?.annotations ?? {};
      this.selector = job?.spec?.selector;
      this.controlledBy = job?.metadata?.ownerReferences?.[0].name;
      this.ownerReferences = job?.metadata?.ownerReferences || [];
      this.containers = job?.spec?.template?.spec?.containers;

      const { jobLastRunTime, jobState, jobStatusReason, jobStatusMessage } =
        extractJobsStatusandLastRunTime(undefined, job?.status);
      this.jobState = jobState;
      this.jobLastRunTime = jobLastRunTime;
      this.jobStatusReason = jobStatusReason;
      this.jobStatusMessage = jobStatusMessage;

      this.parallelism = job?.spec?.parallelism;
      this.completions = job?.spec?.completions;
    }
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <EventsTab resource={this} />
        </TabPanel>
        <TabPanel
          aria-label={AriaLabels.ResourceView.LogsTab}
          value={selectedTab}
          index={1}
        >
          <ServiceLogsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={2}>
          <PodsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={3}>
          <NodesTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={4}>
          <InfoTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={5}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <JobHeader
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
          {
            name: "controlled by",
            value: this.controlledBy,
          },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
