import React, { useState, MouseEvent, useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Delete from "@mui/icons-material/DeleteOutline";
import { muiColors } from "@komodorio/design-system";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { getCheckLabel } from "../../../../../utils/getCheckLabel";
import { AnimatedKeyboardArrowDown } from "../../../../../ReliabilityStyles";
import { useGetImpactGroupByCheckType } from "../hooks/useGetImpactGroupByCheckType";
import {
  DynamicChecksConfigurationsTableColumnsStyle,
  StaticChecksConfigurationsTableColumnsStyle,
} from "../PolicyDrawerStyles";
import { checkTypeToConfigurationDescription } from "../policyDrawerConstants";
import { impactGroupIdentifierToText } from "../../../../../constants/impactGroupIdentifierToText";
import { useDeletePolicyConfiguration } from "../hooks/useDeletePolicyConfiguration";
import { useIsDynamicChecksTab } from "../hooks/useIsDynamicChecksTab";

import { ConfigurationControls } from "./ConfigurationControls";

import { CheckType, ImpactGroupIdentifier } from "@/generated/reliabilityApi";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  policies: {
    policiesDrawer: {
      configurationItem: {
        container: configurationItemContainerAriaLabel,
        deleteButton: deleteButtonAriaLabel,
      },
    },
  },
} = reliabilityArialLabels;

const TopPartContainer = styled.div<{ $isDynamic: boolean }>`
  && {
    align-items: center;
    cursor: pointer;
    width: 100%;
    ${({ $isDynamic }) =>
      $isDynamic
        ? DynamicChecksConfigurationsTableColumnsStyle
        : StaticChecksConfigurationsTableColumnsStyle}
  }
`;

const StyledDeleteIcon = styled(Delete)`
  && {
    color: ${muiColors.gray[500]};
  }
`;

const AnimatedArrow = styled(AnimatedKeyboardArrowDown)`
  && {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
`;

const StyledAccordion = styled(Accordion)<{ $disableMousePointer: boolean }>`
  &&& {
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    &:before {
      display: none;
    }
    .MuiAccordionSummary-root {
      cursor: ${({ $disableMousePointer }) =>
        $disableMousePointer ? "default" : "pointer"};
    }
  }
`;

const ContentContainer = styled.div`
  padding: 8px 0;
  margin-left: 40px;
`;

type ConfigurationItemProps = {
  checkType: CheckType;
};

export const ConfigurationItem: React.FC<ConfigurationItemProps> = ({
  checkType,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const itemLabel = getCheckLabel(checkType);
  const getImpactGroup = useGetImpactGroupByCheckType();
  const deletePolicyConfiguration = useDeletePolicyConfiguration();
  const { isSubmittingForm, isReadOnlyMode } = usePolicyDrawerContext();
  const isDynamicChecksTab = useIsDynamicChecksTab();

  const impactGroupTitle = useMemo(() => {
    const impactGroup = getImpactGroup(checkType);
    return (
      impactGroupIdentifierToText[impactGroup as ImpactGroupIdentifier]
        ?.title ?? ""
    );
  }, [checkType, getImpactGroup]);

  const { title, subTitle } = checkTypeToConfigurationDescription[checkType];

  const onRowClick = (e: MouseEvent) => {
    setIsExpanded((prev) => !prev);
  };

  const onDeleteClick = (e: MouseEvent) => {
    e.stopPropagation();
    deletePolicyConfiguration(checkType);
  };

  const enableDelete = !isSubmittingForm && !isReadOnlyMode;

  return (
    <StyledAccordion
      id={`configuration-item-${checkType}`}
      aria-label={`${configurationItemContainerAriaLabel}-${checkType}`}
      expanded={isDynamicChecksTab ? isExpanded : false}
      $disableMousePointer={!isDynamicChecksTab}
    >
      <AccordionSummary>
        <TopPartContainer $isDynamic={isDynamicChecksTab} onClick={onRowClick}>
          {isDynamicChecksTab && (
            <AnimatedArrow open={isExpanded} color="action" />
          )}
          <Typography variant={"body2"}>{itemLabel}</Typography>
          <Typography variant={"body2"}>{impactGroupTitle}</Typography>
          <IconButton
            onClick={onDeleteClick}
            disabled={!enableDelete}
            sx={{ opacity: enableDelete ? 1 : 0.3 }}
            aria-label={deleteButtonAriaLabel}
          >
            <StyledDeleteIcon fontSize={"medium"} />
          </IconButton>
        </TopPartContainer>
      </AccordionSummary>
      <AccordionDetails>
        <ContentContainer>
          <Typography variant={"body2"}>{title}</Typography>
          <Typography variant={"body3"} color={"text.secondary"}>
            {subTitle}
          </Typography>
          <ConfigurationControls checkType={checkType} />
        </ContentContainer>
      </AccordionDetails>
    </StyledAccordion>
  );
};
