import React, { useEffect, useMemo } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  Node,
  Handle,
  Position,
  EdgeProps,
  getSmoothStepPath,
  ControlButton,
  ReactFlowProvider,
  useReactFlow,
  NodeProps,
  Viewport,
} from "react-flow-renderer";
import styled from "styled-components";

import success from "../assets/success.svg";
import fail from "../assets/flag.svg";
import warn from "../assets/warn.svg";
import info from "../assets/info.svg";
import bell from "../assets/bell.svg";
import question from "../assets/question.svg";
import { NormalText } from "../common/styles";
import plusIcon from "../assets/zoom-plus.svg";
import minusIcon from "../assets/zoom-minus.svg";

import { CheckIconType } from "./checks/CheckCard";

export type NodeIconType = CheckIconType | "question" | "trigger";
export type NodeDataType = {
  label: string | JSX.Element;
  icon: NodeIconType;
  onClick?: () => void;
};

export const NODE_WIDTH = 200;
const NodeContainer = styled.div<{ pointer: boolean }>`
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  border-radius: 4px;
  column-gap: 1rem;
  width: ${NODE_WIDTH}px;
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
`;

const SourceHandle = styled(Handle).attrs({
  type: "source",
  position: Position.Right,
  style: { pointerEvents: "none" },
})`
  background: white;
  border: 2px solid #9195a1;
  border-radius: 1rem;
`;

const TargetHandle = styled(Handle).attrs({
  type: "target",
  position: Position.Left,
  style: { pointerEvents: "none" },
})`
  background: #9195a1;
  width: 3px;
  height: 8px;
  border-radius: unset;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const nodeDefaultProperties: Pick<
  Node<NodeDataType>,
  "draggable" | "style"
> = {
  draggable: false,
  style: { pointerEvents: "none" },
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const edgeDefaultProperties: Pick<Edge, "type" | "style"> = {
  type: "customSmoothStep",
};

const nodeIcons: { [key in NodeIconType]: string } = {
  success: success,
  fail: fail,
  warn: warn,
  info: info,
  trigger: bell,
  question: question,
};

const CustomDefaultNode: React.FC<NodeProps<NodeDataType>> = ({ data }) => (
  <NodeContainer pointer={!!data?.onClick} onClick={data?.onClick}>
    {data && (
      <>
        <img src={nodeIcons[data.icon]} alt="node icon" />
        <NormalText>{data.label}</NormalText>
      </>
    )}
    <TargetHandle />
    <SourceHandle />
  </NodeContainer>
);
const CustomInputNode: React.FC<NodeProps<NodeDataType>> = ({ data }) => (
  <NodeContainer pointer={!!data?.onClick} onClick={data?.onClick}>
    {data && (
      <>
        <img src={nodeIcons[data.icon]} alt="node icon" />
        <NormalText>{data.label}</NormalText>
      </>
    )}
    <SourceHandle />
  </NodeContainer>
);
const CustomOutputNode: React.FC<NodeProps<NodeDataType>> = ({ data }) => (
  <NodeContainer pointer={!!data?.onClick} onClick={data?.onClick}>
    {data && (
      <>
        <img src={nodeIcons[data.icon]} alt="node icon" />
        <NormalText>{data.label}</NormalText>
      </>
    )}
    <TargetHandle />
  </NodeContainer>
);

const CustomSmoothStepEdge: React.FC<EdgeProps> = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  style = {},
}) => {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerX: (sourceX + targetX) / 2,
    centerY: (sourceY + targetY) / 2,
    borderRadius: 20,
  });

  return (
    <g>
      <path
        fill="none"
        stroke="#9195a1"
        strokeWidth={2}
        style={style}
        d={edgePath}
      />
    </g>
  );
};

const Controls = styled.div`
  top: 1.25rem;
  right: 1.25rem;
  position: absolute;
  z-index: 4;
  border-radius: 4px;
`;

const StyledControlButton = styled(ControlButton)`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.85rem 1rem;
  border-bottom: none;
  font-size: 12px;

  :hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  :first-child {
    border-radius: 4px 4px 0 0;
  }
  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const CustomControls: React.FC<{ initViewport: Viewport }> = ({
  initViewport,
}) => {
  const { zoomIn, zoomOut, setViewport } = useReactFlow();

  return (
    <Controls>
      <StyledControlButton onClick={() => zoomIn()}>
        <img src={plusIcon} alt="plus" />
      </StyledControlButton>
      <StyledControlButton onClick={() => zoomOut()}>
        <img src={minusIcon} alt="minus" />
      </StyledControlButton>
      <StyledControlButton onClick={() => setViewport(initViewport)}>
        Reset
      </StyledControlButton>
    </Controls>
  );
};

const Flow: React.FC<{
  nodes: Node<NodeDataType>[];
  edges: Edge[];
  initX: number;
  initY: number;
}> = ({ nodes, edges, initX, initY }) => {
  const { setViewport } = useReactFlow();
  const initViewport = { x: initX, y: initY, zoom: 1 };

  const nodeTypes = useMemo(
    () => ({
      customDefault: CustomDefaultNode,
      customInput: CustomInputNode,
      customOutput: CustomOutputNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      customSmoothStep: CustomSmoothStepEdge,
    }),
    []
  );

  useEffect(() => {
    Array.from(
      document.getElementsByClassName(
        "react-flow__attribution"
      ) as HTMLCollectionOf<HTMLElement>
    )[0].style.display = "none";
  }, []);

  return (
    <ReactFlowProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        panOnScroll
        onInit={() => setViewport(initViewport)}
      >
        <CustomControls initViewport={initViewport} />
        <Background
          variant={BackgroundVariant.Dots}
          color="rgba(211, 211, 211, 0.5)"
          gap={12}
          size={1}
        />
      </ReactFlow>
    </ReactFlowProvider>
  );
};

const Diagram: React.FC<{
  nodes: Node<NodeDataType>[];
  edges: Edge[];
  initX?: number;
  initY?: number;
}> = ({ nodes, edges, initX = 0, initY = 0 }) => {
  return (
    <ReactFlowProvider>
      <Flow nodes={nodes} edges={edges} initX={initX} initY={initY} />
    </ReactFlowProvider>
  );
};

export default Diagram;
