import { useCallback } from "react";
import { ServiceIdentifierType } from "komodor-types";

import { AppErrors } from "../types/creationPageTypes";
import { useCreationPageContext } from "../context/CreationPageContext";

export const useVerifyCreationForm = (): (() => boolean) => {
  const {
    selectedServiceIds,
    selectedServiceIdentifiers,
    appViewType,
    appName,
    setErrorState,
  } = useCreationPageContext();
  const verifyAppName = useVerifyAppName();

  return useCallback(() => {
    if (!verifyAppName(appName)) return false;

    switch (appViewType) {
      case ServiceIdentifierType.SERVICE_ID:
        if (!Object.keys(selectedServiceIds).length) {
          setErrorState({
            servicesSelectedError: AppErrors.NoServicesSelected,
          });
          return false;
        }
        break;

      default:
        if (!appViewType) {
          setErrorState({
            servicesSelectedError: AppErrors.NoServiceIdentifierTypeSelected,
          });
          return false;
        }

        if (!selectedServiceIdentifiers?.length) {
          setErrorState({
            servicesSelectedError: AppErrors.NoServiceIdentifiersSelected,
          });
          return false;
        }
        break;
    }

    return true;
  }, [
    appName,
    appViewType,
    selectedServiceIdentifiers?.length,
    selectedServiceIds,
    setErrorState,
    verifyAppName,
  ]);
};

export const useSetNameError = (): ((error?: AppErrors) => void) => {
  const { setErrorState } = useCreationPageContext();
  return useCallback(
    (error?: AppErrors) => {
      setErrorState({ appNameError: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [setErrorState]
  );
};

export const useVerifyAppName = (): ((appName: string) => boolean) => {
  const setNameError = useSetNameError();
  return useCallback(
    (appName: string) => {
      if (!appName) {
        setNameError(AppErrors.NameValueEmpty);
        return false;
      } else if (appName.length > 63) {
        setNameError(AppErrors.NameValueTooLong);
        return false;
      }
      return true;
    },
    [setNameError]
  );
};
