import React from "react";
import { parseISO } from "date-fns";
import { get } from "lodash";
import { palette } from "@komodorio/design-system";
import { EventManual24 } from "@komodorio/design-system/icons";
import { ActionTypes, IgnoreActionTypes } from "komodor-types";

import { ActionFragment } from "../../../../generated/graphql";
import EventGroup, { EventBase } from "..";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupEventsByKey } from "../groupEvents";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../shared/constants/systemWideServiceId";
import { isKubectlErrorStatus } from "../../ProcessList/Status";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ScaleEventDetails } from "./ScaleEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DeleteResourceEventDetails } from "./DeleteResourceEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { JobRerunEventDetails } from "./JobRerunEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { RestartServiceEventDetails } from "./RestartServiceEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { EditResourceEventDetails } from "./EditResourceEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CordonUncordonEventDetails } from "./CordonUncordonEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { RollbackServiceEventDetails } from "./RollbackEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { UpdateContainersResourcesEventDetails } from "./UpdateResourcesEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { HelmEventsDetails } from "./HelmEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PodShellEventDetails } from "./PodShellEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PortForwardEventDetails } from "./PortForwardEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { SourceControlRevertDetails } from "./SourceControlRevertDetails";

export const actionType = "Manual action";

export interface ActionEvent extends EventBase {
  id: string;
  uid: string;
  name: string;
  eventTime: Date;
  status: string;
  reason: string;
  message: string;
  action: string;
  taskId: string;
  result: string;
  metadata: unknown;
  email: string;
}

const getActionEventStatus = (status: string, result: string) => {
  if (isKubectlErrorStatus(status)) return "Failed";
  if (result) return "Completed";
  return "In progress";
};
export default class ActionEventGroup implements EventGroup {
  readonly backgroundColor;
  readonly fillColor;
  readonly icon = EventManual24;
  readonly events: ActionEvent[];
  readonly id;
  readonly name;
  readonly type = actionType;
  readonly startTime;
  readonly endTime;
  readonly message;
  readonly status;
  readonly email;
  readonly namespace;
  readonly cluster;
  readonly metadata;
  readonly actionType: string;
  readonly resourceType;
  readonly resourceName;
  readonly controlledBy;
  readonly actionResult;

  serviceId;

  constructor(events: ActionEvent[]) {
    this.events = events;

    const firstEvent = this.events[0];
    const lastEvent = this.events[this.events.length - 1];
    this.endTime = lastEvent.eventTime;
    this.startTime = firstEvent.eventTime;
    this.message = "";
    this.status = getActionEventStatus(lastEvent.status, lastEvent.result);
    this.actionResult = lastEvent.result;
    this.name = "";
    this.serviceId = firstEvent.serviceId;
    this.email = firstEvent.email;
    this.metadata = firstEvent.metadata;
    this.actionType = get(this.metadata, "type");
    this.cluster = get(this.metadata, "cluster");
    this.namespace = get(this.metadata, "namespace");
    this.resourceType = get(this.metadata, "resourceType");
    this.resourceName = get(this.metadata, "resourceName");
    this.controlledBy = get(this.metadata, "controlledBy");

    this.id = firstEvent.id;

    this.backgroundColor = palette.gray["200"];
    this.fillColor = palette.gray["600"];
  }

  get summary(): string {
    return this.buildSummary(this.events[0].metadata);
  }

  get details(): string {
    return this.email;
  }

  buildSummary(metadata: unknown): string {
    const summary: Record<string, string> = {
      [ActionTypes.Scale]: `Scale ${
        get(metadata, "data.currValue") < get(metadata, "data.newValue")
          ? "up"
          : "down"
      }: ${get(metadata, "data.currValue")} to ${get(
        metadata,
        "data.newValue"
      )}`,
      [ActionTypes.DeleteResource]: `Delete ${get(metadata, "resourceType")}`,
      [ActionTypes.RestartService]: `Restart service`,
      [ActionTypes.EditResource]: `Edit ${get(metadata, "resourceType")}`,
      [ActionTypes.JobRerun]: `Re-run job`,
      [ActionTypes.JobRunNow]: `Trigger job`,
      [ActionTypes.Cordon]: `Cordon node`,
      [ActionTypes.Uncordon]: `Uncordon node`,
      [ActionTypes.UpdateContainersResources]: `Update resources`,
      [ActionTypes.RevertHelmChart]: `Helm rollback`,
      [ActionTypes.InstallHelmChart]: `Helm upgrade`,
      [ActionTypes.UninstallHelmChart]: `Helm uninstall`,
      [ActionTypes.AddHelmRepo]: `Helm repo add`,
      [ActionTypes.RemoveHelmRepo]: `Helm repo remove`,
      [ActionTypes.PodShell]: `Pod shell`,
      [ActionTypes.PortForward]: `Port forwarding`,
      [ActionTypes.Drain]: `Drain node`,
      [ActionTypes.RollbackService]: `Rollback service`,
      [ActionTypes.RevertSourceControl]: `Revert source control`,
    };
    return summary[this.actionType] ?? "";
  }

  renderEventDetails(): JSX.Element {
    const details: Record<string, JSX.Element> = {
      [ActionTypes.Scale]: <ScaleEventDetails eventGroup={this} />,
      [ActionTypes.DeleteResource]: (
        <DeleteResourceEventDetails eventGroup={this} />
      ),
      [ActionTypes.EditResource]: (
        <EditResourceEventDetails eventGroup={this} />
      ),
      [ActionTypes.RestartService]: (
        <RestartServiceEventDetails eventGroup={this} />
      ),
      [ActionTypes.JobRerun]: <JobRerunEventDetails eventGroup={this} />,
      [ActionTypes.JobRunNow]: <JobRerunEventDetails eventGroup={this} />,
      [ActionTypes.Cordon]: <CordonUncordonEventDetails eventGroup={this} />,
      [ActionTypes.Uncordon]: <CordonUncordonEventDetails eventGroup={this} />,
      [ActionTypes.Drain]: <CordonUncordonEventDetails eventGroup={this} />,
      [ActionTypes.RollbackService]: (
        <RollbackServiceEventDetails eventGroup={this} />
      ),
      [ActionTypes.UpdateContainersResources]: (
        <UpdateContainersResourcesEventDetails eventGroup={this} />
      ),
      [ActionTypes.RevertHelmChart]: <HelmEventsDetails eventGroup={this} />,
      [ActionTypes.InstallHelmChart]: <HelmEventsDetails eventGroup={this} />,
      [ActionTypes.UninstallHelmChart]: <HelmEventsDetails eventGroup={this} />,
      [ActionTypes.AddHelmRepo]: <HelmEventsDetails eventGroup={this} />,
      [ActionTypes.RemoveHelmRepo]: <HelmEventsDetails eventGroup={this} />,
      [ActionTypes.PodShell]: <PodShellEventDetails eventGroup={this} />,
      [ActionTypes.PortForward]: <PortForwardEventDetails eventGroup={this} />,
      [ActionTypes.RevertSourceControl]: (
        <SourceControlRevertDetails eventGroup={this} />
      ),
    };
    return details[this.actionType] ?? <></>;
  }
}

const toActionEvent = (e: ActionFragment): ActionEvent => {
  return {
    message: "",
    name: "",
    reason: "",
    uid: "",
    serviceId: get(e.metadata, "serviceId") || SYSTEM_WIDE_SERVICE_ID,
    type: actionType,
    id: e.id,
    eventTime: parseISO(e.eventTime),
    action: e.action as string,
    taskId: e.taskId,
    result: e.result as string,
    email: isElectronContext() ? "" : (e.user?.email as string),
    metadata: e.metadata,
    status: (e.result as string)?.toLowerCase()?.startsWith("error")
      ? (e.result as string)
      : (e.status as string),
  };
};

export const groupActionEvents = (
  events: ActionFragment[] | undefined
): ActionEventGroup[] => {
  if (!events) return [];

  return groupEventsByKey(
    events
      .map((e) => toActionEvent(e))
      .filter((e) => !IgnoreActionTypes.includes(get(e.metadata, "type"))),
    (e) => e.taskId
  ).map((e) => new ActionEventGroup(e));
};
