import { IntegrationType } from "komodor-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";
import { muiTheme } from "@komodorio/design-system";

import {
  FormContainer,
  FormInputGroup,
  FormTextInput,
} from "../CustomFormComponents";
import Checkbox from "../../../common/controls/Checkbox";
import CustomIntegrationInstallation from "../CustomIntegrationInstallation";
import { useUpsertInstallation } from "../useUpsertInstallation";

import DatadogInstallationInstructions from "./DatadogInstallationInstructions";

const StyledCheckBox = styled(Checkbox)`
  width: 20rem;
  align-items: unset;
`;

const ErrorMessage = styled.div`
  color: ${muiTheme.palette.error.main};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  height: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
`;

type Configuration = {
  ddApiKey: string;
  ddAppKey: string;
  shouldAutoAddMonitors: boolean;
};
const DatadogInstallation: React.FC<{ closeModalCallback: () => void }> = ({
  closeModalCallback,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Configuration>();
  const createDatadogInstallation = useUpsertInstallation<Configuration>(
    IntegrationType.DATADOG
  );
  const [acceptedAutoAddMonitors, setAcceptedAutoAddMonitors] = useState(true);
  return (
    <CustomIntegrationInstallation name="Datadog">
      <DatadogInstallationInstructions />
      <form>
        <FormContainer>
          <FormInputGroup>
            <label htmlFor="ddApiKey">API Key</label>
            <FormTextInput
              id="ddApiKey"
              {...register("ddApiKey", {
                required: { value: true, message: "API key is required" },
                validate: {
                  isValidRegex: (value) => {
                    const regex = /^[a-f0-9]{32}$/;
                    return (
                      regex.test(value) || "Api key must be 32 characters long"
                    );
                  },
                },
              })}
            />
            {errors.ddApiKey?.message && (
              <ErrorMessage>{errors.ddApiKey?.message}</ErrorMessage>
            )}
          </FormInputGroup>
          <FormInputGroup>
            <label htmlFor="ddAppKey">Application Key</label>
            <FormTextInput
              id="ddAppKey"
              {...register("ddAppKey", {
                required: {
                  value: true,
                  message: "Application key is required",
                },
                validate: {
                  isValidRegex: (value) => {
                    const regex = /^[a-f0-9]{40}$/;
                    return (
                      regex.test(value) ||
                      "Application key must be 40 characters long"
                    );
                  },
                },
              })}
            />
            {errors.ddAppKey?.message && (
              <ErrorMessage>{errors.ddAppKey?.message}</ErrorMessage>
            )}
          </FormInputGroup>
          <FormInputGroup>
            <StyledCheckBox
              id="ddInstallCreateWebhook"
              fieldName="Create komodor webhook and add it to all monitors"
              description="Unchecking this will require manual add to each monitor"
              checked={acceptedAutoAddMonitors}
              handleCheckboxChange={() =>
                setAcceptedAutoAddMonitors(!acceptedAutoAddMonitors)
              }
            />
          </FormInputGroup>
          <Button
            variant="primary"
            onClick={handleSubmit(async ({ ddApiKey, ddAppKey }) => {
              await createDatadogInstallation({
                ddApiKey,
                ddAppKey,
                shouldAutoAddMonitors: acceptedAutoAddMonitors,
              });
              closeModalCallback();
            })}
          >
            Install Datadog integration
          </Button>
        </FormContainer>
      </form>
    </CustomIntegrationInstallation>
  );
};

export default DatadogInstallation;
