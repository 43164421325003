import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import {
  AlertTriangle16,
  QuestionCircle16,
} from "@komodorio/design-system/icons";

import { dispatchEvent } from "../../shared/hooks/analytics";
import { useStateInSearchParams } from "../../shared/hooks/state";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { DarkenOverlay } from "../common/Overlay";
import Resource from "../ResourceView/resources";
import { STATIC_PREVENTION_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { STATIC_PREVENTION_DOCS_LINK } from "../../shared/constants/docsLinks";

import { useStaticPrevention } from "./useStaticPrevention";
import {
  StaticPreventionResult,
  StaticPreventionSeverity,
} from "./StaticPreventionUtils";
import { ScoreBlockBySeverity } from "./ScoreBlock";
import StaticPreventionModal from "./Modal";

const ScoreBlockContainer = styled.div`
  display: grid;
  cursor: pointer;
  gap: 0.4rem;
  width: 10rem;
`;

const TriangleWarning = styled.div`
  display: flex;
  column-gap: 0.4rem;
  align-items: center;
  color: ${theme.foreground.fgSubtle};
  margin-block-end: 0.25rem;
`;

const Title = styled(Typography).attrs({ variant: "title", size: "large" })`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-block-end: 1rem;
`;

const BestPracticesChecks: React.FC<{ resource: Resource }> = ({
  resource,
}) => {
  const staticPreventionData = useStaticPrevention(resource.id);
  const [modalOpen, setModalOpen] = useState(false);
  const [serviceId, setServiceId] = useStateInSearchParams(
    STATIC_PREVENTION_PARAM_KEY
  );
  const [parsedResults, setParsedResults] =
    useState<StaticPreventionResult | null>(null);
  const workflowResults = staticPreventionData.workflowResults;
  const [workflowConfiguration, refreshConfiguration, configurationName] =
    staticPreventionData.configurationResults;
  const lastDeploy = staticPreventionData.lastDeploy;

  useEffect(() => {
    if (staticPreventionData.parsedPolarisResults === null) return;
    setParsedResults(staticPreventionData.parsedPolarisResults);
  }, [staticPreventionData.parsedPolarisResults]);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
    document.body.style.overflow = "";
    setServiceId(null, true);
    dispatchEvent(AnalyticEvents.ServiceView.StaticPrevention.Modal_Close, {
      serviceId: resource.id,
      namespace: resource.namespace,
      workload: resource.kind,
    });
  }, [resource.id, resource.kind, resource.namespace, setServiceId]);

  const onModalOpen = useCallback(() => {
    setModalOpen(true);
    document.body.style.overflow = "hidden";
    setServiceId(resource.id, true);
    dispatchEvent(AnalyticEvents.ServiceView.StaticPrevention.Modal_Open, {
      serviceId: resource.id,
      namespace: resource.namespace,
      workload: resource.kind,
    });
  }, [resource.id, resource.kind, resource.namespace, setServiceId]);
  useEffect(() => {
    if (!serviceId) return;
    onModalOpen();
  }, [onModalOpen, serviceId]);
  if (!workflowResults) return null;
  return (
    <>
      <Title>
        Best Practices
        <QuestionCircle16
          fill={theme.foreground.fgSubtle}
          onClick={() => window.open(STATIC_PREVENTION_DOCS_LINK)}
        />
      </Title>
      {parsedResults && !parsedResults.failedToParse ? (
        <>
          <ScoreBlockContainer onClick={onModalOpen}>
            <ScoreBlockBySeverity
              severity={StaticPreventionSeverity.danger}
              parsedResults={parsedResults}
              displayOnZero={false}
              e2eSelectorPrefix={"best-practices"}
            />
            <ScoreBlockBySeverity
              severity={StaticPreventionSeverity.warning}
              parsedResults={parsedResults}
              displayOnZero={false}
              e2eSelectorPrefix={"best-practices"}
            />
            {!parsedResults.checks.some((c) => !c.Success) ? (
              parsedResults.checks.length ? (
                <ScoreBlockBySeverity
                  severity={StaticPreventionSeverity.passed}
                  parsedResults={parsedResults}
                  e2eSelectorPrefix={"best-practices"}
                />
              ) : (
                <ScoreBlockBySeverity
                  severity={StaticPreventionSeverity.ignored}
                  parsedResults={parsedResults}
                  e2eSelectorPrefix={"best-practices"}
                />
              )
            ) : null}
          </ScoreBlockContainer>
          {modalOpen && lastDeploy && (
            <>
              <DarkenOverlay open={modalOpen} />
              <StaticPreventionModal
                cluster={resource.cluster}
                namespace={resource.namespace}
                name={resource.name}
                serviceName={resource.id}
                isOpen={modalOpen}
                modalCloseCallback={onModalClose}
                parsedResults={parsedResults}
                workflowConfiguration={workflowConfiguration}
                configurationName={configurationName}
                refreshConfiguration={refreshConfiguration}
                lastDeploy={lastDeploy}
              />
            </>
          )}
        </>
      ) : (
        <>
          <TriangleWarning>
            <AlertTriangle16 />
            <Typography color="inherit" bold>
              Data unavailable
            </Typography>
          </TriangleWarning>
          <Typography color={theme.foreground.fgSubtle}>
            The check results will be shown following the next deployment.
          </Typography>
        </>
      )}
    </>
  );
};

export default BestPracticesChecks;
