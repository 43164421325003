import React, { useState, useMemo, useEffect } from "react";
import { partition, isEmpty } from "lodash";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";

import { dispatchEvent } from "../../../hooks/analytics";
import { AnalyticEvents } from "../../../config/analyticsEvents";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { FilterBarCategory } from "../FilterBar";
import { H3 } from "../../../../components/common/typography";
import { Input } from "../../../../components/common/Input";
import searchIcon from "../../../../components/common/SVGs/search.svg";
import { grayAppBackground } from "../../../../Colors";
import { useStringifiedStateInSearchParams } from "../../../hooks/state";
import Selected from "../Interfaces/Selected";
import useBatches from "../../../hooks/useBatches";
import IntersectionDetector from "../../../../components/common/IntersectionDetector";
import { FILTERS_BAR_FILTERS_PARAM_KEY } from "../../../config/urlSearchParamsKeys";

import spinner from "./assets/spinner.svg";
import collapsedArrow from "./assets/collapsed-arrow.svg";
import expandedArrow from "./assets/expanded-arrow.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { categoryUtils, filterByTerm } from "./categoryUtils";

export type Value = FilterBarCategory["values"][0];

const MAX_GROUP_SIZE = 5;
export const debounceMilliseconds = 600;
const BATCH_SIZE = 15;

const Spinner = styled.div`
  height: 1.3rem;
  width: 1.3rem;
  background-image: url(${spinner});
  background-repeat: no-repeat;
`;

const CollapsedArrow = styled.div`
  height: 0.5rem;
  width: 1.3rem;
  background-image: url(${collapsedArrow});
  background-repeat: no-repeat;
`;

const ExpandedArrow = styled.div`
  height: 0.5rem;
  width: 1.3rem;
  background-image: url(${expandedArrow});
  background-repeat: no-repeat;
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-right: 3%;
`;

const CategoryTitleAndExpandArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding-right: 3%;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LongCategoryListContainer = styled.div`
  display: grid;
  grid-template: "cell" 7.2rem / auto;
  &:after {
    content: "";
    height: 2rem;
    background: linear-gradient(rgba(244, 247, 250, 0), rgb(244, 247, 250));
    pointer-events: none;
    grid-area: cell;
    align-self: end;
    margin-right: 20px;
  }
`;

const ListOfFilters = styled.div`
  overflow: hidden;
  grid-area: cell;
  ${LongCategoryListContainer} > & {
    padding-bottom: 1.5em;
    :hover {
      overflow: auto;
    }
  }
`;

const ShortCategoryListContainer = styled.div`
  overflow: hidden;
`;

export interface CategoryProps {
  category: FilterBarCategory;
  renderCheckboxFieldName?: (v: Value, filterTerm: string) => JSX.Element;
}

const Category = ({
  category,
  renderCheckboxFieldName,
}: CategoryProps): JSX.Element => {
  const [filterTerm, setFilterTerm] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [selected, setSelected] = useStringifiedStateInSearchParams<Selected>(
    FILTERS_BAR_FILTERS_PARAM_KEY
  );

  const [debouncedFilterTerm] = useDebounce(filterTerm, debounceMilliseconds);

  const invertFilter = useMemo(() => {
    return (name: string) => {
      const newParamsObject = { ...selected };
      const invertedCatagories = category.values.filter(
        (v) => v.value !== name
      );
      if (!invertedCatagories.length) return;
      const invertedFilters = Object.assign(
        {},
        ...invertedCatagories.map((v) => ({ [v.value]: true }))
      );
      newParamsObject[category.name] = invertedFilters;
      setSelected(newParamsObject);
    };
  }, [category.name, category.values, selected, setSelected]);

  const filteredCategoryItems = useMemo(() => {
    const [checked, unchecked] = partition(
      category.values,
      (v) => v.checked || selected?.[category.name]?.[v.value]
    );
    if (debouncedFilterTerm) {
      dispatchEvent(AnalyticEvents.FilterBar.Category_Search);
    }
    const filtered = filterByTerm(unchecked, debouncedFilterTerm);
    const displayed = [...checked, ...filtered];
    return displayed.map(
      categoryUtils({
        category: category.name,
        invertFilter,
        selected,
        setSelected,
        filterTerm: debouncedFilterTerm,
        renderCheckboxFieldName,
      })
    );
  }, [
    category.name,
    category.values,
    debouncedFilterTerm,
    invertFilter,
    renderCheckboxFieldName,
    selected,
    setSelected,
  ]);

  const {
    limited: limitedCategoryValues,
    resetBatches,
    loadMore,
  } = useBatches(BATCH_SIZE, filteredCategoryItems);

  const clearFilters = () => {
    const newParamsObject = { ...selected };
    delete newParamsObject[category.name];
    dispatchEvent(AnalyticEvents.FilterBar.Clear_Category_Filters, {
      category: category.name,
    });
    setSelected(newParamsObject);
  };

  useEffect(() => {
    if (isScrolled) {
      dispatchEvent(AnalyticEvents.FilterBar.Scroll_In_Category, {
        categoryName: category.name,
      });
    }
  }, [isScrolled, category.name]);

  const CategoryItemsContainer =
    filteredCategoryItems.length > MAX_GROUP_SIZE
      ? LongCategoryListContainer
      : ShortCategoryListContainer;

  const { isFinishedLoadingValues, isCategoryCollapsed } = category;

  const numOfCategoryValues = category?.values?.length;
  const shouldRenderNumValues = numOfCategoryValues > 0 && !isCategoryCollapsed;

  const shouldShowSpinner =
    !isCategoryCollapsed &&
    (!numOfCategoryValues || isFinishedLoadingValues === false);

  const onCategoryTitleClick = () => {
    const { expandCategory, collapseCategory } = category;

    if (!expandCategory || !collapseCategory) return;

    isCategoryCollapsed ? expandCategory() : collapseCategory();
  };

  const renderCollapseExpandArrow = () => {
    if (isCategoryCollapsed === undefined) return null;

    return isCategoryCollapsed ? (
      <CollapsedArrow onClick={onCategoryTitleClick} />
    ) : (
      <ExpandedArrow onClick={onCategoryTitleClick} />
    );
  };

  return (
    <CategoryContainer data-e2e-selector="filtersBar-list">
      <CategoryTitle>
        <CategoryTitleAndExpandArrowContainer>
          {renderCollapseExpandArrow()}
          <H3>
            {category.name}{" "}
            {shouldRenderNumValues && `(${numOfCategoryValues})`}
          </H3>
        </CategoryTitleAndExpandArrowContainer>
        {shouldShowSpinner && <Spinner />}
        {!isEmpty({ ...selected }[category.name]) && (
          <Link
            sx={{ cursor: "pointer" }}
            variant="body3"
            onClick={clearFilters}
          >
            Clear
          </Link>
        )}
      </CategoryTitle>
      {!isCategoryCollapsed && numOfCategoryValues > MAX_GROUP_SIZE && (
        <Input
          icon={searchIcon}
          onChange={setFilterTerm}
          value={filterTerm}
          backgroundColor={grayAppBackground}
          placeholder={`Filter ${category.name.toLowerCase()}`}
        />
      )}
      {!isCategoryCollapsed && (
        <CategoryItemsContainer>
          <ListOfFilters
            onScroll={() => {
              if (!isScrolled) setIsScrolled(true);
            }}
            aria-label={`Filters for ${category.name}`}
          >
            <IntersectionDetector onShow={resetBatches} />
            <FormGroup>{limitedCategoryValues}</FormGroup>
            <IntersectionDetector onShow={loadMore} />
          </ListOfFilters>
        </CategoryItemsContainer>
      )}
    </CategoryContainer>
  );
};

export default Category;
