import React from "react";

import { SnapshotInfoDetail } from "../../../common/Alert/SnapshotInfo";
import useDateFormatter from "../../../../shared/hooks/useDateFormatter";

export const SnapshotAlert: React.FC<{ deletionTimestamp: unknown }> = ({
  deletionTimestamp,
}) => {
  const { format } = useDateFormatter();

  if (!deletionTimestamp) return null;
  return (
    <SnapshotInfoDetail variant="warning">
      All information presented here is a snapshot from{" "}
      <b>{format(new Date(deletionTimestamp as string))}</b> when this pod was
      terminated
    </SnapshotInfoDetail>
  );
};
