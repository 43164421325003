import React, { useMemo } from "react";
import styled from "styled-components";

import Pod from "../../../../ResourceView/resources/pod";
import EventGroup from "../../../EventGroup";
import { IssueBanner } from "../../../../IssueBanner/IssueBanner";

import { useGetCorrelatedNodeTerminations } from "./useGetCorrelatedNodeTerminations";
import { useGetCorrelatedNodeIssues } from "./useGetCorrelatedNodeIssues";
import { getNodeIssueDetails } from "./utils";

const MINUTES_BEFORE_PHASE = 15;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export interface NodeIssueBannerProps {
  resource: Pod | undefined;
  eventGroup: EventGroup;
  onResourceClick: (eg: EventGroup) => void;
}

const NodeIssueBanner: React.FC<{
  group: EventGroup;
  onResourceClick: (eg: EventGroup) => void;
}> = ({ group, onResourceClick }) => {
  const props = getNodeIssueDetails(group);

  return (
    <IssueBanner
      {...props}
      borderTop={false}
      onIssueClicked={() => {
        onResourceClick(group);
      }}
    />
  );
};

const correlatedNodeIssuesStartTime = (start: Date) => {
  const startTime = new Date(start);
  startTime.setMinutes(startTime.getMinutes() - MINUTES_BEFORE_PHASE);
  return startTime;
};

export const NodeIssuesSection: React.FC<NodeIssueBannerProps> = ({
  eventGroup,
  resource,
  onResourceClick,
}) => {
  const timeWindow = useMemo(() => {
    return {
      start: correlatedNodeIssuesStartTime(eventGroup.startTime),
      end: eventGroup.isCompleted ? eventGroup.endTime : new Date(),
    };
  }, [eventGroup.endTime, eventGroup.isCompleted, eventGroup.startTime]);

  const { nodeTerminationsGroups } = useGetCorrelatedNodeTerminations(
    resource,
    timeWindow
  );

  const { nodeIssues } = useGetCorrelatedNodeIssues(resource, timeWindow);

  const relevantNodeEvents = useMemo(() => {
    return [
      ...nodeTerminationsGroups.filter(
        (group) =>
          group.nodeName === resource?.fullObj?.spec?.nodeName &&
          group.clusterName === resource?.cluster
      ),
      ...nodeIssues.filter(
        (group) => group.resourceName === resource?.fullObj?.spec?.nodeName
      ),
    ];
  }, [
    nodeTerminationsGroups,
    nodeIssues,
    resource?.fullObj?.spec?.nodeName,
    resource?.cluster,
  ]);

  if (!relevantNodeEvents?.length) {
    return null;
  }

  return (
    <Section>
      {relevantNodeEvents
        .sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
        .map((group) => (
          <NodeIssueBanner group={group} onResourceClick={onResourceClick} />
        ))}
    </Section>
  );
};
