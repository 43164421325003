import styled from "styled-components";
import React from "react";

import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import InfoIcon from "../../../monitorsView/assets/infoOutlined.svg?react";

import { FlexStyledContainer } from ".";

const SnapshotInfo = styled(FlexStyledContainer)`
  gap: 0.6rem;
  margin: 0.8rem 0 0.6rem;
  background: #e9f7ff;
  padding: 0.2rem 0.5rem;
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-right: 0;
  height: 0.9rem;
  object-fit: contain;
`;
const SnapshotInfoDetail: React.FC<{
  eventTime: Date;
}> = ({ eventTime }) => {
  const { format } = useDateFormatter();

  return (
    <SnapshotInfo>
      <StyledInfoIcon />
      <div>
        The information presented is relevant for <b>{format(eventTime)}</b>
      </div>
    </SnapshotInfo>
  );
};
export default SnapshotInfoDetail;
