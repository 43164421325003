import React from "react";
import styled from "styled-components";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useLiveStateDrawerContext } from "@/components/k8sAddons/LiveStateDrawer/context/useLiveStateDrawerContext";
import { liveStateDrawerAriaLabels } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerAriaLabels";

const Container = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

type DrawerTopSectionProps = {
  title: string;
  className?: string;
  children?: React.ReactNode;
};

export const DrawerTopSection: React.FC<DrawerTopSectionProps> = ({
  title,
  children,
  className,
}) => {
  const { onClose } = useLiveStateDrawerContext();
  return (
    <Container
      className={className}
      aria-label={liveStateDrawerAriaLabels.topSection}
    >
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
      <Typography variant="h3">{title}</Typography>
      {children}
    </Container>
  );
};
