import { noop } from "lodash";
import React, { createContext, useContext, useMemo } from "react";

import { RELATED_RESOURCES_PODS_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useStringifiedStateInSearchParams } from "../../../shared/hooks/state";

interface SelectedPodsContext {
  selectedPodUidsForEvents: string[] | null;
  setSelectedPodUidsForEvents: (
    newValue: string[] | null,
    replace?: boolean | undefined
  ) => void;
}

const defaultValue = {
  selectedPodUidsForEvents: null,
  setSelectedPodUidsForEvents: noop,
};

const selectedPodsContext =
  createContext<Required<SelectedPodsContext>>(defaultValue);

export const SelectedPodsProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [selectedPodUidsForEvents, setSelectedPodUidsForEvents] =
    useStringifiedStateInSearchParams<string[]>(
      RELATED_RESOURCES_PODS_PARAM_KEY
    );

  const value = useMemo(
    () => ({
      selectedPodUidsForEvents,
      setSelectedPodUidsForEvents,
    }),
    [selectedPodUidsForEvents, setSelectedPodUidsForEvents]
  );

  return (
    <selectedPodsContext.Provider value={value}>
      {children}
    </selectedPodsContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useSelectedPodsForEvents = (): SelectedPodsContext =>
  useContext(selectedPodsContext);
