import React from "react";
import styled from "styled-components";

const DIRECTIONS = {
  down: undefined,
  left: "rotate(90deg)",
  up: "rotate(180deg)",
  right: "rotate(270deg)",
};

type Direction = keyof typeof DIRECTIONS;

const Container = styled.div<{ width: string; direction: Direction }>`
  line-height: 0;
  width: ${({ width }) => width};
  transform: ${({ direction }) => DIRECTIONS[direction]};
`;

type Props = {
  className?: string;
  width: string;
  color: string;
  direction?: Direction;
};
const Arrow: React.FC<Props> = ({
  className,
  width,
  color,
  direction = "down",
}) => (
  <Container className={className} direction={direction} width={width}>
    <svg viewBox="0 0 120 60" fill="none" stroke={color} strokeWidth="20">
      <polyline points="10,10 60,50 110,10" />
    </svg>
  </Container>
);

export default Arrow;
