import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

const Container = styled.div`
  padding: 4px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
  gap: 16px;
  flex: 1;
`;

type DataBoxProps = {
  title: string;
  value: string;
};

export const DataBox: React.FC<DataBoxProps> = ({ value, title }) => {
  return (
    <Container>
      <Typography variant={"body2"}>{title}</Typography>
      <Typography variant={"h3"}>{value}</Typography>
    </Container>
  );
};
