import { useEffect, useMemo, useState } from "react";

import { useKeepSocketSessionAlive } from "../../../sockets/useKeepSocketSessionAlive";
import { useInitiateSocketSession } from "../../../sockets/useInitiateSocketSession";
import { connectWithRetry as handleReconnect } from "../../../sockets/connectWithRetry";
import {
  PodLogLine,
  PodLogsInfoRequestProps,
  PodLogsInfoResponseProps,
  PodLogsMode,
} from "../types";
import { SocketMessageType } from "../../../sockets/types";
import { selectIsLiveTailEnabled } from "../../../../components/common/LogsViewer/store/logsViewerStoreSelectors";
import {
  addPodLogs,
  selectPodLogs,
  resetPodLogs,
} from "../store/podLogsStoreSelectors";
import { usePodLogsStore } from "../store/podLogsStore";
import { useLogsViewerStore } from "../../../../components/common/LogsViewer/store/logsViewerStore";

import { useGetLivePodLogsInitData } from "./useGetInitData";
import { useHandleIncomingSocketMessage } from "./useHandleIncomingSocketMessage";
import { buildIdentifier } from "./buildIdentifier";
import { buildEventLog } from "./handleUnknownTerminationReason";

export const useGetLivePodLogs = ({
  podName,
  selectedContainer,
  namespace,
  agentId,
  clusterName: cluster,
  shouldExecuteFetchLogs,
  isPreviousLogs,
}: PodLogsInfoRequestProps): PodLogsInfoResponseProps => {
  const logs = usePodLogsStore(selectPodLogs);
  const resetLogs = usePodLogsStore(resetPodLogs);
  const addLogs = usePodLogsStore(addPodLogs);
  const [sinceTime, setSinceTime] = useState<string>("");
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [
    lastSeenIdxWhileLiveTailWasActive,
    setLastSeenIdxWhileLiveTailWasActive,
  ] = useState<number>(0);
  const [failureMessage, setFailureMessage] = useState<string>("");
  const containerName = selectedContainer?.value;
  const initData = useGetLivePodLogsInitData({
    containerName,
    namespace,
    podName,
    sinceTime: isRetrying ? sinceTime : undefined,
    isPreviousLogs,
  });

  const isLiveTailEnabled = useLogsViewerStore(selectIsLiveTailEnabled);

  const identifier = shouldExecuteFetchLogs
    ? buildIdentifier({
        agentId,
        namespace,
        podName,
        containerName,
      })
    : "";

  const [refreshKeepAliveSession, closeKeepAliveSession] =
    useKeepSocketSessionAlive(identifier);

  useEffect(() => {
    if (isLiveTailEnabled) {
      setLastSeenIdxWhileLiveTailWasActive(logs.length);
    }
  }, [isLiveTailEnabled, logs.length]);

  useEffect(() => {
    setIsRetrying(false);
  }, [isPreviousLogs]);

  const handleIncomingMessage = useHandleIncomingSocketMessage({
    closeKeepAliveSession,
    identifier,
    setLogs: (newLogs: PodLogLine[], isSystemMessageLogs: boolean) => {
      addLogs(newLogs);
      if (!isSystemMessageLogs) {
        setIsRetrying(false);
        setFailureMessage("");
      }
    },
    onError: (msg: string) => {
      if (logs.length > 0) {
        addLogs([buildEventLog(msg)]);
      } else {
        setFailureMessage(msg);
      }
    },
    askForRetry: () => {
      setIsRetrying(true);
      const lastLogTime = new Date();

      lastLogTime.setSeconds(lastLogTime.getSeconds() + 1);
      setSinceTime(lastLogTime.toISOString());
    },
  });

  useInitiateSocketSession({
    identifier,
    agentId,
    cluster,
    namespace,
    podName,
    handleIncomingMessage,
    refreshKeepAliveSession,
    containerName,
    initData,
    handleReconnect,
    initMessageType: SocketMessageType.PodLogsInit,
    shouldReconnect: false,
  });

  // clear the GLOBAL logs when the component is unmounted
  useEffect(() => {
    return () => {
      resetLogs();
      setIsRetrying(false);
    };
  }, [resetLogs]);

  const logsToView = useMemo(() => {
    const userStopOnIdx = logs.length - lastSeenIdxWhileLiveTailWasActive;
    const logsWhereLiveTailWasActive = logs.slice(userStopOnIdx, logs.length);

    return isLiveTailEnabled ? logs : logsWhereLiveTailWasActive;
  }, [isLiveTailEnabled, logs, lastSeenIdxWhileLiveTailWasActive]);

  return {
    failureMessage,
    logs: logsToView,
    fetching: false,
    mode: PodLogsMode.Live,
    tail: logsToView.length,
  };
};
