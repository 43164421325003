import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { DropDownTriggerField } from "../shared/components/DropDownTriggerField";
import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";
import { selectAriaLabels } from "../shared/ariaLabels";
import { SelectContainer } from "../shared/styledComponents";
import { Label } from "../shared/components/Label";
import { defaultSelectSize } from "../../../../constants";

import { DropdownFieldContent } from "./DropdownFieldContent";
import { SingleSelectProps } from "components/komodor-ui/Select/SingleSelect/singleSelectTypes";
import { SingleSelectPopover } from "components/komodor-ui/Select/SingleSelectPopover/SingleSelectPopover";
import { placeholderOptionsValue } from "components/komodor-ui/Select/SingleSelect/singleSelectConstants";
import { DEFAULT_ANCHOR_ORIGIN } from "components/komodor-ui/Select/shared/constants";

export const SingleSelect = <T extends MuiSelectionOptionValue>({
  options,
  value,
  defaultValue,
  onChange,
  onClose,
  onOpen,
  placeholder,
  label,
  size = defaultSelectSize,
  width,
  minWidth,
  ariaLabel = selectAriaLabels.singleSelect,
  classNames,
  disabled,
  customValueElement,
  selectPopoverProps,
  showTooltip = false,
  tooltipPlacement,
  clearable,
  ...rest
}: SingleSelectProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [localValue, setLocalValue] = useState(defaultValue || value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const onClosePopover = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const onChangeLocalValue = useCallback(
    (selectedOption: MuiSelectionOption<T>) => {
      setLocalValue(selectedOption);
      onChange?.(selectedOption);
    },
    [onChange]
  );

  const tooltipValue = useMemo(() => {
    if (!showTooltip) return "";
    return !showTooltip ? "" : localValue?.label;
  }, [showTooltip, localValue]);

  const blankOption: MuiSelectionOption<T> = {
    label: placeholder ?? "",
    value: placeholderOptionsValue as T,
  };
  const optionsToUse =
    placeholder && !clearable ? [blankOption, ...options] : options;

  const isClearable = clearable && !!localValue;

  const onClear = useCallback(() => {
    onChangeLocalValue(null);
  }, [onChangeLocalValue]);

  return (
    <SelectContainer
      aria-label={ariaLabel}
      width={width}
      minWidth={minWidth}
      disabled={disabled}
      ref={containerRef}
      aria-disabled={disabled}
    >
      <Label value={label} className={classNames?.label} />
      <DropDownTriggerField
        onClick={onOpenPopover}
        dropDownOpen={Boolean(anchorEl)}
        size={size}
        className={classNames?.dropDownTriggerField}
        disabled={disabled}
        tooltip={tooltipValue}
        tooltipPlacement={tooltipPlacement}
        clearable={isClearable}
        onClear={onClear}
      >
        <DropdownFieldContent
          selectedOption={localValue}
          placeholder={placeholder}
          customValueElement={customValueElement}
        />
      </DropDownTriggerField>
      <SingleSelectPopover
        anchorEl={anchorEl}
        onClose={onClosePopover}
        value={localValue}
        onChange={onChangeLocalValue}
        options={optionsToUse}
        selectPopoverProps={{
          container: containerRef.current,
          anchorOrigin: DEFAULT_ANCHOR_ORIGIN,
          ...selectPopoverProps,
        }}
        {...rest}
      />
    </SelectContainer>
  );
};
