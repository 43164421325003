import React, { useMemo, useRef } from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { ContainerStatus } from "kubernetes-types/core/v1";

import Pod from "../../resources/pod";
import { LogsViewer } from "../../../common/LogsViewer/LogsViewer";
import PreviousLogs from "../../../common/LogsViewer/PodLogsBar/PreviousLogs";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useReportLogsDDRum } from "../LogsTab/useReportLogsDDRum";
import { usePodContainerLogs } from "../LogsTab/usePodContainerLogs";
import { responsiveLayoutHorizontalMarginPx } from "../../../common/ResponsiveLayout";
import { useIsInsideDrawer } from "../../../../shared/context/drawersStack/helpers";
import { isBailout } from "../../../../shared/hooks/podLogs/podLogsUtils";

const Container = styled.div<{ selectContainerHeight: number }>`
  position: absolute;
  box-sizing: border-box;
  width: calc(100% - ${responsiveLayoutHorizontalMarginPx * 2}px);
  height: ${({ selectContainerHeight = 0 }) =>
    `calc(100% - ${selectContainerHeight}px)`};
  left: -50%;
  margin-left: ${responsiveLayoutHorizontalMarginPx * 2}px;
  transform: translateX(50%);
`;

type SelectedContainer = { value: string; label: string };

export interface PodLogsProps {
  id: string;
  cluster: string;
  namespace: string;
  name: string;
  containerStatuses?: ContainerStatus[];
  fullObj?: Pod["fullObj"];
  selectedContainer: SelectedContainer | undefined;
  selectContainerRef?: React.RefObject<HTMLDivElement>;
}

const PodLogs: React.FC<PodLogsProps> = (pod: PodLogsProps) => {
  const {
    id,
    cluster,
    namespace,
    name,
    containerStatuses = [],
    selectedContainer,
    selectContainerRef,
  } = pod;
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    fetching,
    logsToShow,
    isPreviousLogs,
    setIsPreviousLogs,
    failureMessage,
    requestMoreLogs,
    tail,
    retries,
  } = usePodContainerLogs(pod, selectedContainer);

  const isInsideDrawer = useIsInsideDrawer();

  useReportLogsDDRum({
    divRef: containerRef,
    fetching: isBailout(retries) ? false : fetching,
  });

  const podIdentifier = useMemo(
    () => ({ id, cluster, namespace, name }),
    [id, cluster, namespace, name]
  );
  const previousLogsToggle = containerStatuses.some(
    (c) => c.restartCount > 0
  ) ? (
    <PreviousLogs
      pod={podIdentifier}
      setIsPreviousLogs={setIsPreviousLogs}
      isPreviousLogs={isPreviousLogs}
      containerName={selectedContainer?.value}
    />
  ) : null;

  return (
    <Container
      aria-label={AriaLabels.Inspection.Pod.LogsTab}
      ref={containerRef}
      selectContainerHeight={selectContainerRef?.current?.clientHeight ?? 0}
    >
      <LogsViewer
        podLogsRes={logsToShow}
        failureMessage={failureMessage}
        loading={!isBailout(retries)}
        podName={pod.name}
        containerName={selectedContainer?.value ?? ""}
        requestedCount={tail}
        onLoadMoreClicked={requestMoreLogs}
        previousLogsToggleElement={previousLogsToggle}
        toolbarBgColor={muiColors.common.white}
        clusterName={cluster}
        namespace={namespace}
        showEnlargeButton={isInsideDrawer}
      />
    </Container>
  );
};

export default PodLogs;
