import React, { useCallback, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { StyledDialog } from "../../styles";
import {
  ClusterForRbacSync,
  agentToClusterForRbacSync,
  isClusterValid,
  RbacClusterSyncFeatureFlag,
} from "../utils/rbacClusterStatusSync";

import {
  DownloadKubeconfigButton,
  errorNotificationMessage,
  OpenKubeconfigDownloadClusterSelectionButton,
} from "./DownloadKubeconfigButton";
import { SelectClustersList } from "./SelectClustersList";

import { useGetRbacClusterStatuses } from "@/shared/hooks/auth-service/client/rbacClusterStatus/useGetRbacClusterStatuses";
import { useActiveAgents } from "@/shared/hooks/useAgents";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export interface RenderButtonProps {
  onClick: () => void;
}

export const OpenDownloadKubeconfigModalButton: React.FC<{
  renderButton?: (props: RenderButtonProps) => React.ReactNode;
}> = ({ renderButton }) => {
  const { canGetKubeconfig } = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const { rbacClusterSync } = useOverridableFlags();

  const closeResetTimeoutMs = 250; // animation duration for the Modal Dialog is 225ms, being close to it will prevent flickering
  const [renderKey, setRenderKey] = useState(0);
  const [open, setOpen] = useState(false);

  if (
    !canGetKubeconfig ||
    !accountFeatures?.rbacClusterSync ||
    !(rbacClusterSync as RbacClusterSyncFeatureFlag)?.allowUsersDownload
  ) {
    return null;
  }

  const GetKubeconfigButton = () =>
    renderButton ? (
      renderButton({ onClick: () => setOpen(true) })
    ) : (
      <OpenKubeconfigDownloadClusterSelectionButton
        onClick={() => setOpen(true)}
      >
        Get Kubeconfig...
      </OpenKubeconfigDownloadClusterSelectionButton>
    );

  return (
    <React.Fragment>
      <GetKubeconfigButton />
      <SelectClustersModal
        key={renderKey}
        isOpen={open}
        closeModal={() => {
          setOpen(false);
          setTimeout(() => {
            setRenderKey(renderKey + 1);
          }, closeResetTimeoutMs);
        }}
      />
    </React.Fragment>
  );
};

export const SelectClustersModal: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
}> = ({ isOpen, closeModal }) => {
  const [errorDownloading, setErrorDownloading] = useState(false);
  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);

  const { data: rbacClusterSyncStatuses } = useGetRbacClusterStatuses();
  const activeAgents = useActiveAgents();

  const activeSelectableClusters: ClusterForRbacSync[] = useMemo(() => {
    if (!activeAgents || !rbacClusterSyncStatuses) {
      return [];
    }

    return activeAgents.map((agent) =>
      agentToClusterForRbacSync({
        agent,
        rbacClusterStatuses: rbacClusterSyncStatuses,
      })
    );
  }, [activeAgents, rbacClusterSyncStatuses]);

  const onSelectCluster = useCallback(
    (cluster: string) => {
      if (selectedClusters.includes(cluster)) {
        return;
      }
      setSelectedClusters([...selectedClusters, cluster]);
    },
    [selectedClusters]
  );
  const onUnselectCluster = useCallback(
    (cluster: string) =>
      setSelectedClusters(selectedClusters.filter((c) => c !== cluster)),
    [selectedClusters]
  );

  const selectAndReplaceMultiple = useCallback(
    (clusters: ClusterForRbacSync[]) => {
      const clusterNames = clusters
        .filter(isClusterValid)
        .map((cluster) => cluster.name);
      setSelectedClusters(clusterNames);
    },
    [setSelectedClusters]
  );

  const unselectAll = useCallback(() => {
    setSelectedClusters([]);
  }, [setSelectedClusters]);

  const onDownloaded = useCallback(
    (args: { success: boolean }) => {
      setErrorDownloading(!args.success);
      if (args.success) {
        closeModal();
      }
    },
    [closeModal]
  );

  return (
    <StyledDialog open={isOpen} onClose={closeModal}>
      <DialogTitle sx={{ fontSize: "20px" }}>
        Download Kubeconfig file
      </DialogTitle>
      <DialogContent sx={{ height: "50vh" }}>
        <SelectClustersList
          availableClusters={activeSelectableClusters}
          selectedClusters={selectedClusters}
          onSelectCluster={onSelectCluster}
          onUnselectCluster={onUnselectCluster}
          selectAndReplaceMultiple={selectAndReplaceMultiple}
          unselectAll={unselectAll}
        />
      </DialogContent>
      {errorDownloading && (
        <Typography
          variant="subtitle2"
          color="error"
          sx={{ textAlign: "center" }}
        >
          {errorNotificationMessage}
        </Typography>
      )}
      <DialogActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <DownloadKubeconfigButton
          clusters={selectedClusters}
          onDownloaded={onDownloaded}
        />
      </DialogActions>
    </StyledDialog>
  );
};
