import React, { useState } from "react";
import styled from "styled-components";
import ModeEdit from "@mui/icons-material/ModeEdit";
import Delete from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useDeletePolicyConfirmationDialog } from "../hooks/useDeletePolicyHooks";

import { PolicyListItem } from "@/generated/reliabilityApi";
import { useOpenPolicyDrawer } from "@/components/reliability/components/pages/policies/policiesHooks";
import { useHasWritePermission } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasWritePermission";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
  & > button {
    color: ${muiColors.gray[500]};
  }
`;

const {
  policies: {
    policiesTable: {
      deletePolicy: deletePriorityAriaLabel,
      editPolicy: editPriorityAriaLabel,
      viewPolicy: viewPriorityAriaLabel,
    },
  },
} = reliabilityArialLabels;

export const TableActions: React.FC<PolicyListItem> = (policyListItem) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const hasWritePermissions = useHasWritePermission(policyListItem.id);
  const deletePolicyConfirmationDialog = useDeletePolicyConfirmationDialog({
    isOpen: openDeleteDialog,
    onClose: () => setOpenDeleteDialog(false),
    policyListItem,
  });

  const openPolicyDrawer = useOpenPolicyDrawer();

  const openPolicyData = {
    title: hasWritePermissions ? "Edit" : "View",
    icon: hasWritePermissions ? (
      <ModeEdit fontSize={"small"} />
    ) : (
      <VisibilityIcon fontSize={"small"} />
    ),
  };

  const openPolicyAriaLabel = hasWritePermissions
    ? editPriorityAriaLabel
    : viewPriorityAriaLabel;

  return (
    <Container>
      <Tooltip title={openPolicyData.title} placement={"top"}>
        <IconButton
          onClick={() => openPolicyDrawer(policyListItem.id)}
          aria-label={openPolicyAriaLabel}
        >
          {openPolicyData.icon}
        </IconButton>
      </Tooltip>
      {hasWritePermissions && (
        <>
          <Tooltip title={"Delete"} placement={"top"}>
            <IconButton
              onClick={() => setOpenDeleteDialog(true)}
              aria-label={deletePriorityAriaLabel}
            >
              <Delete fontSize={"medium"} />
            </IconButton>
          </Tooltip>

          {deletePolicyConfirmationDialog}
        </>
      )}
    </Container>
  );
};
