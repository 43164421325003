import { useCallback } from "react";
import { without } from "lodash";

const useChartCallbacks = (
  setHighlightedEvents: (fn: (eventIds: string[]) => string[]) => void,
  setEventId: (eventId: string) => void
): {
  onChartMouseEnter: (eventId: string) => void;
  onChartMouseLeave: (eventId: string) => void;
  onChartClick: (eventId: string) => void;
} => ({
  onChartMouseEnter: useCallback(
    (eventId) => setHighlightedEvents((eventIds) => [...eventIds, eventId]),
    [setHighlightedEvents]
  ),
  onChartMouseLeave: useCallback(
    (eventId) => setHighlightedEvents((eventIds) => without(eventIds, eventId)),
    [setHighlightedEvents]
  ),
  onChartClick: useCallback(
    (eventId: string) => setEventId(eventId),
    [setEventId]
  ),
});

export default useChartCallbacks;
