import { css } from "styled-components";

import arrow from "./arrow.svg";

const dropdownArrow = css`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.75em;
  padding-right: 1.5rem;
`;

export default dropdownArrow;
