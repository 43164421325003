import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PVCCreationDetails from "./detailsComponents/PVCCreationDetails";

interface EventOutput {
  type: string;
  reason: string;
  message: string;
  age: string;
}

interface PVCCreationOutput {
  events?: EventOutput[];
}

export default class PVCCreationCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "PVC Creation Process";
    this.description =
      "We are checking if there are any problems with the PVC creation. We are looking for native kubernetes events that indicate there is an issue with the pvc.";
    this.action = (
      <>
        We run <GrayHighlight>kubectl get event</GrayHighlight> to find all the
        errors that were raised during the pvc creation.
      </>
    );
    this.question = "Are there any problems with the PVC creation?";
    this.output = check.output as PVCCreationOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <PVCCreationDetails check={this} />;
  }
}
