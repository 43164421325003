import { theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { CustomResourceColumnDefinition } from "kubernetes-types/apiextensions/v1.d";
import React from "react";
import styled from "styled-components";

import { SELECTED_TAB_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import { useDrawerUrlState } from "../../../../shared/hooks/state";
import DataTable, { Column } from "../../../common/table/DataTable";
import { extractNameAndGroupFromFQDN } from "../../../Inspection/CRDs/utils/extractNameAndGroupFromFQDN";
import { ResourceTabName } from "../../resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import CRD from "../../resources/crd";
import {
  DescribeDateItem,
  DescribeItem,
  DescribeTextItem,
} from "../DescribeTab/common/DescribeItem";
import { DescribePanel } from "../DescribeTab/common/DescribeSection";
import { DescribeSectionDivider } from "../DescribeTab/common/DescribeSectionDivider";
import { DescribeKeyValueItem } from "../DescribeTab/common/DescribeTagListItem";
import { StatusTag } from "../DescribeTab/common/StatusTag";
import { DescribeTabContentWrapper } from "../DescribeTab/common/DescribeTabContentWrapper";

const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const PanelTitle = styled(Typography).attrs({
  "aria-label": "Printer Columns",
})`
  margin: 1rem 0;
`;

const TableContainer = styled.div`
  .data-table {
    border: 1px solid ${theme.border.borderGray};
    border-radius: 4px;
    box-shadow: unset;
  }
  margin-bottom: 10px;
`;

const printerColumns: Column<CustomResourceColumnDefinition>[] = [
  { name: "Name", selector: (item) => item.name },
  { name: "Type", selector: (item) => item.type },
  { name: "JSON Path", selector: (item) => item.jsonPath },
];

interface CRDDetailsTabProps {
  resource: CRD;
}

const getVersion = (versionFullText?: string) => {
  return (versionFullText || "").split("/").pop();
};

export const CRDDetailsTab: React.FC<CRDDetailsTabProps> = ({ resource }) => {
  const [, setSelectedTab] = useDrawerUrlState<string>(SELECTED_TAB_PARAM_KEY);

  const crd = resource.fullObj;
  const { creationTimestamp, name, labels, annotations } = crd.metadata ?? {};

  const { scope, group, names, versions } = crd.spec ?? {};
  const { conditions } = crd.status ?? {};

  const currentVersionName = getVersion(crd.apiVersion);
  const currentVersion = versions?.at(0);

  return (
    <DescribeTabContentWrapper resource={resource}>
      <DescribePanel>
        <DescribeTextItem name="Name" value={name} />
        <DescribeDateItem name="Created" value={creationTimestamp} />

        <DescribeSectionDivider />

        {annotations ? (
          <DescribeKeyValueItem name="Annotations" value={annotations} />
        ) : (
          <DescribeTextItem name="Annotations" />
        )}

        {labels ? (
          <DescribeKeyValueItem name="Labels" value={labels} />
        ) : (
          <DescribeTextItem name="Labels" />
        )}

        <DescribeSectionDivider title={"RESOURCE INFORMATION"} />

        <DescribeTextItem name="Version" value={currentVersionName} />
        <DescribeTextItem name="Scope" value={scope} />
        <DescribeTextItem name="Group" value={group} />

        <DescribeTextItem
          name="Resource"
          value={extractNameAndGroupFromFQDN(name as string).name}
          onValueClick={() => setSelectedTab(ResourceTabName.Resources)}
        />

        <DescribeSectionDivider title={"CONDITIONS"} />

        <DescribeItem name="Conditions">
          <ConditionsContainer>
            {Array.isArray(conditions) &&
              conditions.length &&
              conditions.map((c) => <StatusTag value={c.type} key={c.type} />)}
          </ConditionsContainer>
        </DescribeItem>

        <DescribeSectionDivider title={"NAMES"} />

        <DescribeTextItem name="Plural" value={names?.plural} />
        <DescribeTextItem name="Singular" value={names?.singular} />
        <DescribeTextItem name="Kind" value={names?.kind} />
        <DescribeTextItem name="ListKind" value={names?.listKind} />
      </DescribePanel>

      {currentVersion?.additionalPrinterColumns && (
        <DescribePanel>
          <PanelTitle variant="title">Printer Columns</PanelTitle>

          <TableContainer>
            <DataTable
              data={currentVersion.additionalPrinterColumns.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              columns={printerColumns}
              getRowId={(u) => u.name}
              highlightedRows={false}
              className="data-table"
            />
          </TableContainer>
        </DescribePanel>
      )}
    </DescribeTabContentWrapper>
  );
};
