import React from "react";
import styled from "styled-components";

import { gray10 } from "../../../../../Colors";
import checkmarkIcon from "../../../assets/checkmark.svg";

const Container = styled.div<{ disabled: boolean; selected: boolean }>`
  display: grid;
  grid-template-columns: 2.6rem auto;
  width: 100%;
  position: relative;
  padding: 5px 10px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: 1px solid ${({ selected }) => (selected ? "#007aff" : "#dcdddf")};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Logo = styled.div<{ icon: string }>`
  width: 2.2rem;
  height: 2.2rem;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 80%;
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  align-content: center;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #3b3d45;
`;

const GrayLabel = styled.div`
  font-size: 11px;
  font-style: italic;
  color: ${gray10};
`;

const Checkmark = styled.div<{ icon: string }>`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 4px;
  right: 4px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
`;

const SinkTile: React.FC<{
  icon: string;
  name: string;
  disabled?: boolean;
  selected?: boolean;
  configured?: boolean;
  onClick?: () => void;
}> = ({
  icon,
  name,
  disabled = false,
  selected = false,
  configured = false,
  onClick,
}) => {
  return (
    <Container
      disabled={disabled}
      selected={selected}
      onClick={!disabled ? onClick : undefined}
    >
      <Logo icon={icon} />
      <TextContainer>
        <Label>{name}</Label>
        {disabled && <GrayLabel>Coming Soon...</GrayLabel>}
      </TextContainer>
      {configured && <Checkmark icon={checkmarkIcon} />}
    </Container>
  );
};

export default SinkTile;
