import {
  Divider,
  Typography,
  Tabs,
  TabPanel,
} from "@komodorio/design-system/deprecated";
import { HelmChartSummary, ResourceTableModelRow } from "komodor-types";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import semver from "semver";
import { parseISO } from "date-fns";

import { SELECTED_CHART_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "../../../shared/hooks/state";
import { LinesLoader } from "../../common/loaders/Line";
import ResourceListTable from "../../common/ResourceListTable/ResourceListTable";
import useInspectionFilters from "../filters/useInspectionFilters";
import { KubernetesResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesResource";
import { useInspectionParams } from "../InspectionViews/common";
import ResourceListErrorState from "../ResourceListErrorState";
import { useFilteredClusters } from "../filters/useFilteredClusters";
import { useActiveAgents } from "../../../shared/hooks/useAgents";
import { REQUIRED_AGENT_VERSION_HELM } from "../../../shared/utils/agent/agent";
import { ConfigurationType } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { AgentInfoToDefaultClusterName } from "../utils/useDefaultClusterForInspection";
import UpgradeRequired from "../../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../../common/upgradeAgentCta/UpgradeAgentCTA";
import { Direction } from "../../common/ProcessList/SortTitle";
import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";
import { useInterval } from "../../common/useInterval";
import { InspectionFilters } from "../filters/InspectionFilters";
import { AgentNotSupported } from "../AgentNotSupported";
import { useDDRumViewAndTimingListener } from "../../../shared/hooks/datadog-rum/datadogRumHooks";
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";
import { dataDogViewNames } from "../../../shared/constants/datadog";

import HelmReposTab from "./tabs/HelmReposTab";
import { useParseHelmChartsResponse } from "./hooks/useHelmCharts";
import ChartReleaseDrawer from "./drawers/ChartReleaseDrawer";
import { invalidateChartRevisionsCache } from "./utils";
import { useHelmChartsFromAgent } from "./hooks/useHelmChartsFromAgent";

export const Container = styled.div`
  margin: 1.5rem;
  margin-top: 1rem;
`;

enum HelmTabs {
  Releases = "Releases",
  Repos = "Repositories",
}

enum HelmTabsIndex {
  Releases = 0,
  Repos = 1,
}

const REFRESH_REVISIONS_CACHE_INTERVAL_IN_SECONDS = 600;

const HelmCharts: React.FC<{
  kubernetesResource: KubernetesResource;
}> = ({ kubernetesResource }) => {
  const [currentTab, setCurrentTab] = useState(HelmTabsIndex.Releases);
  const [showUpdateMessage, setUpdateMessage] = useState(false);
  const { cluster, onClusterChange, agentId } =
    useInspectionParams(kubernetesResource);
  const { filteredClusterSuggestions } = useFilteredClusters(cluster ?? "");
  const { formatToParts } = useDateFormatter({
    month: "2-digit",
    day: "2-digit",
  });

  const { canManageHelm } = useHasPermissions();

  const { execute, isFetching, data, errorMessage } = useHelmChartsFromAgent(
    cluster ?? "",
    agentId ?? ""
  );
  const resourceList = useParseHelmChartsResponse(
    errorMessage,
    isFetching,
    data
  );

  const activeAgents = useActiveAgents() as AgentInfoToDefaultClusterName[];

  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName: dataDogViewNames.getInspectionViewPage(
      kubernetesResource.NameInK8S
    ),
    addTimingParams: {
      enable: !isFetching,
    },
  });

  useEffect(() => {
    if (!resourceList.emptyResult) {
      execute();
    }
  }, [execute, resourceList.emptyResult]);

  useEffect(() => {
    if (data?.[0]?.cluster) {
      onClusterChange(data[0].cluster);
    }
  }, [data, onClusterChange]);
  const [showReleaseDrawer, setShowReleaseDrawer] = useState(false);
  const [selectedChart, setSelectedChart] = useState<HelmChartSummary>();

  const namespaces = useMemo(() => {
    return (
      uniqBy(
        data?.filter((d) => d.cluster === cluster),
        "namespace"
      ).map((r) => r.namespace) ?? []
    );
  }, [cluster, data]);

  const clusters = useMemo(() => {
    return uniqBy(data, "cluster").map((r) => ({
      label: r.cluster,
      value: r.cluster,
    }));
  }, [data]);

  useInterval(
    invalidateChartRevisionsCache,
    REFRESH_REVISIONS_CACHE_INTERVAL_IN_SECONDS * 1_000
  );

  const onRowClick = useCallback(
    (row: ResourceTableModelRow) => {
      const chart = data?.find((r) => r.secretId === row.secretId);
      if (chart) {
        setSelectedChart(chart);
        setShowReleaseDrawer(true);
      }
    },
    [data]
  );
  const [, setChartId] = useStateInSearchParams(SELECTED_CHART_PARAM_KEY);
  const closeDrawer = useCallback(() => {
    setSelectedChart(undefined);
    setShowReleaseDrawer(false);
    setChartId(null);
  }, [setChartId]);

  const filteredTableResults = useInspectionFilters(
    resourceList,
    kubernetesResource
  );
  const chartsByCluster = useMemo(() => {
    return filteredTableResults
      .filter((row) => row?.cluster === cluster)
      .map((row) => {
        const dateParts = Object.fromEntries(
          formatToParts(parseISO(row?.lastUpdated ?? "")).map(
            ({ type, value }) => [type, value]
          )
        );
        const { day, month, year, hour, minute, second, dayPeriod } = dateParts;
        return {
          ...row,
          lastUpdated: `${year}-${month}-${day}, ${hour}:${minute}:${second} ${dayPeriod}`,
        };
      });
  }, [cluster, filteredTableResults, formatToParts]);

  const isAgentSupported = useMemo(() => {
    const currentAgent = activeAgents?.find((a) => a.clusterName === cluster);
    return ConfigurationType.guard(currentAgent?.configuration)
      ? semver.gte(
          currentAgent?.configuration?.appmetadata?.version ?? "0.0.0",
          REQUIRED_AGENT_VERSION_HELM
        ) &&
          currentAgent?.configuration?.enablehelm &&
          currentAgent?.configuration?.resources?.secret
      : false;
  }, [activeAgents, cluster]);

  const tabs = useMemo(() => {
    const presentTabs = [
      {
        label: HelmTabs.Releases,
      },
    ];
    if (canManageHelm) {
      presentTabs.push({
        label: HelmTabs.Repos,
      });
    }
    return presentTabs;
  }, [canManageHelm]);

  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_ENABLE_HELM
  );

  return (
    <Container>
      <Tabs
        value={currentTab}
        onChange={(index) => {
          setCurrentTab(index);
        }}
        tabs={tabs}
      />
      <Divider />
      <TabPanel index={HelmTabsIndex.Releases} value={currentTab}>
        <br />
        {cluster && (
          <InspectionFilters
            kubernetesResource={kubernetesResource}
            resourceList={resourceList}
            selectedCluster={cluster}
            onClusterChange={onClusterChange}
            namespaces={namespaces}
            clustersOptions={filteredClusterSuggestions}
          />
        )}
        <Divider />
        <br />
        {resourceList.errorMessage ? (
          <ResourceListErrorState
            resourceName={kubernetesResource.NameInK8S}
            errorMessage={resourceList.errorMessage}
            refreshCallback={execute}
          />
        ) : isAgentSupported ? (
          <ResourceListTable
            rows={chartsByCluster}
            resourceType={kubernetesResource}
            cluster={cluster ?? ""}
            refreshResults={() => null}
            overrideRowClick={onRowClick}
            initialSortOrder={{ column: "lastUpdated", order: Direction.down }}
            loadingProps={{
              isLoading: resourceList.fetching,
              loaderElement: <LinesLoader marginTop="100px" />,
            }}
          />
        ) : (
          <AgentNotSupported
            onUpgradeClicked={() => setUpdateMessage(true)}
            title="Komodor agent on this cluster does not support Helm."
          />
        )}
        {selectedChart && (
          <ChartReleaseDrawer
            open={showReleaseDrawer}
            chartSummary={selectedChart}
            onClose={closeDrawer}
            agentId={agentId ?? ""}
          />
        )}
        {showUpdateMessage && (
          <UpgradeRequired
            handleClose={() => setUpdateMessage(false)}
            updateMessage={UpdateMessage}
            upgradeCommand={commandStr}
            desiredFeature="helm"
          />
        )}
      </TabPanel>
      <TabPanel index={HelmTabsIndex.Repos} value={currentTab}>
        {canManageHelm && (
          <HelmReposTab clusters={clusters.map((el) => el.value)} />
        )}
      </TabPanel>
    </Container>
  );
};

const UpdateMessage = (
  <Typography size="medium">
    An update to the Komodor agent is required to enable Helm on this cluster.
    <br />
  </Typography>
);

export default HelmCharts;
