import React, { useMemo } from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

type ChipsFlexContainerProps = {
  values: string[];
};

export const ChipsFlexContainer: React.FC<ChipsFlexContainerProps> = ({
  values,
}) => {
  const content = useMemo(() => {
    return values.map((value) => <Chip key={value} label={value} />);
  }, [values]);
  return <Container>{content}</Container>;
};
