import { UserRole } from "komodor-types";
import { useMemo } from "react";

import useAuthorization from "./useAuthorization";

export type Options = {
  context: {
    fetchOptions: RequestInit;
  };
};

export default (): Options | undefined => {
  const Authorization = useAuthorization();
  return useMemo(() => {
    if (!Authorization) return;
    const headers = { Authorization, "x-hasura-role": UserRole.User };
    const res = { context: { fetchOptions: { headers } } };
    return res;
  }, [Authorization]);
};
