const saveDesktopUserCredentials = (
  mail: string,
  id: string,
  password: string,
  installationId: string
): void => {
  window.electronAPI?.saveCredentials(mail, id, password, installationId);
};

export default saveDesktopUserCredentials;
