import { IntegrationType } from "komodor-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";

import {
  FormContainer,
  FormInputGroup,
  FormTextInput,
} from "../CustomFormComponents";
import Checkbox from "../../../common/controls/Checkbox";
import CustomIntegrationInstallation from "../CustomIntegrationInstallation";
import { useUpsertInstallation } from "../useUpsertInstallation";

import NewrelicInstallationInstructions from "./NewrelicAlertInstallationInstructions";

const StyledCheckBox = styled(Checkbox)`
  width: 20rem;
  align-items: unset;
`;

type Configuration = {
  newrelicApiKey: string;
  shouldAddNotificationChannel: boolean;
};
const NewrelicAlertsInstallation: React.FC<{
  closeModalCallback: () => void;
}> = ({ closeModalCallback }) => {
  const { register, handleSubmit } = useForm<Configuration>();
  const createNewrelicInstallation = useUpsertInstallation<Configuration>(
    IntegrationType.NEWRELIC_ALERTS
  );
  const [
    acceptAutoAddNotificationChannel,
    setAcceptedAutoAddNotificationChannel,
  ] = useState(true);
  return (
    <CustomIntegrationInstallation name="Newrelic">
      <NewrelicInstallationInstructions />
      <form>
        <FormContainer>
          <FormInputGroup>
            <label htmlFor="newrelicApiKey">API key</label>
            <FormTextInput
              id="newrelicApiKey"
              {...register("newrelicApiKey", { required: true })}
            />
          </FormInputGroup>
          <FormInputGroup>
            <StyledCheckBox
              id="newrelicCreateNotificationChannel"
              fieldName="Create Komodor notification channel and add it to all newrelic alerts"
              description="Unchecking this will require manual add to each monitor"
              checked={acceptAutoAddNotificationChannel}
              handleCheckboxChange={() =>
                setAcceptedAutoAddNotificationChannel(
                  !acceptAutoAddNotificationChannel
                )
              }
            />
          </FormInputGroup>
          <Button
            variant="primary"
            onClick={handleSubmit(async ({ newrelicApiKey }) => {
              await createNewrelicInstallation({
                newrelicApiKey,
                shouldAddNotificationChannel: acceptAutoAddNotificationChannel,
              });
              closeModalCallback();
            })}
          >
            Install Newrelic Alerts integration
          </Button>
        </FormContainer>
      </form>
    </CustomIntegrationInstallation>
  );
};

export default NewrelicAlertsInstallation;
