import { useEffect, useMemo } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";

import { useActiveAgent } from "../../shared/hooks/useAgents";
import { useFilteredClusters } from "../Inspection/filters/useFilteredClusters";
import { useAgentInfoById } from "../../shared/hooks/useAgentInfo/useAgentInfo";
import { useInspectionData } from "../Inspection/utils/useGetInspectionData";
import { KubernetesCRResource } from "../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRResource";
import { useStateInSearchParams } from "../../shared/hooks/state";
import { ARGO_WORKFLOW_CLUSTER_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { useArgoWorkflowsStore } from "../../shared/store/argoWorkflowsStore/argoWorkflowsStore";
import { setArgoWorkflowsGroupsSelector } from "../../shared/store/argoWorkflowsStore/argoWorkflowsSelectors";
import { useInterval } from "../common/useInterval";
import { useArgoWorkflowClusters } from "../../shared/hooks/resources-api/client/events/useArgoWorkflowClusters";

import { useArgoWorkflowsGroupedByOwner } from "./groupWorkflowsByOwner";
import { ArgoWorkflowRawData } from "./types";
import { useAgentSupportArgoWorkflows } from "./hooks";

import { useClusterSuggestions } from "@/shared/hooks/workspaces-api/clusters/useClusterSuggestions";

export const useArgoWorkflowsFromAtm = (defaultCluster?: string) => {
  const clusterSuggestions = useClusterSuggestions();
  const clusters = useMemo(() => {
    return clusterSuggestions?.map((suggestion) => suggestion.value);
  }, [clusterSuggestions]);
  const [cluster, setCluster] = useStateInSearchParams(
    ARGO_WORKFLOW_CLUSTER_PARAM_KEY
  );

  useEffect(() => {
    // if the default cluster has changed
    if (defaultCluster && defaultCluster !== cluster && clusters?.length > 0) {
      setCluster(defaultCluster);
      return;
    }

    // if the cluster is not set in the url
    if (!cluster && clusters?.length > 0) {
      setCluster(defaultCluster ?? clusters[0], true);
      return;
    }
  }, [clusters, cluster, setCluster, defaultCluster]);

  const { filteredClusterSuggestions, clusterToUse: selectedCluster } =
    useFilteredClusters(defaultCluster ?? cluster ?? "");
  const {
    data: clustersWithArgoEvents,
    isLoading: isLoadingClustersWithArgoEvents,
  } = useArgoWorkflowClusters();

  const agentId = useActiveAgent(selectedCluster) ?? "";
  const { agentProperties: agentInfo, fetching: fetchingAgentInfo } =
    useAgentInfoById(agentId, selectedCluster);

  const isAgentSupported = useAgentSupportArgoWorkflows(agentInfo);

  const { resourceList } = useInspectionData({
    agentId,
    agentInfo,
    cluster: cluster ?? "",
    kubernetesResource: {
      ...KubernetesCRResource,
      Kind: "workflows.argoproj.io",
    },
    shouldStop: !agentId || !agentInfo || !cluster || !isAgentSupported,
  });

  const { rows, fetching, errorMessage } = resourceList;

  const argoWorkflowsGroups = useArgoWorkflowsGroupedByOwner(
    rows as unknown as ArgoWorkflowRawData[],
    cluster ?? ""
  );

  const setGroupsInStore = useArgoWorkflowsStore(
    setArgoWorkflowsGroupsSelector
  );
  useInterval(() => {
    setGroupsInStore(argoWorkflowsGroups);
  }, 1000 * 5);

  const clustersSuggestionsOptions: OptionType<string>[] = useMemo(() => {
    const enabledClusters = new Set(
      clustersWithArgoEvents?.data.filter((clusterWithArgo) =>
        filteredClusterSuggestions.some((c) => clusterWithArgo === c.value)
      ) ?? []
    );
    const disabledClusters = new Set<string>();
    filteredClusterSuggestions.forEach((cluster) => {
      if (!enabledClusters.has(cluster.value)) {
        disabledClusters.add(cluster.value);
      }
    });
    return [
      ...Array.from(enabledClusters).map((cluster) => ({
        label: cluster,
        value: cluster,
      })),
      ...Array.from(disabledClusters).map((cluster) => ({
        label: cluster,
        value: cluster,
        disabled: true,
      })),
    ];
  }, [filteredClusterSuggestions, clustersWithArgoEvents]);

  return {
    argoWorkflowsGroups,
    cluster: cluster ?? "",
    setCluster,
    selectedCluster,
    filteredClusterSuggestions: clustersSuggestionsOptions,
    fetching: fetching || fetchingAgentInfo || isLoadingClustersWithArgoEvents,
    isAgentSupported,
    errorMessage,
  };
};
