import { useMemo } from "react";

import { GenericViolationFilterCategory } from "@/generated/reliabilityApi";
import { useGetImpactGroupType } from "@/components/reliability/hooks/useSelectedTab";

export const useAddImpactGroupTypeFilter =
  (): GenericViolationFilterCategory => {
    const impactGroupType = useGetImpactGroupType();

    return useMemo(
      () => ({
        category: "impactGroupType",
        operator: "include",
        value: [impactGroupType],
      }),
      [impactGroupType]
    );
  };
