import { parseISO } from "date-fns";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@komodorio/design-system/deprecated";

import CustomLinkComponent from "../../../../../common/ProcessList/details/components/LinkSection/CustomLink";
import DiffEntry from "../../../../../common/ProcessList/details/deployEvent/DiffEntry";
import K8sDiffLink from "../../../../../common/ProcessList/details/deployEvent/K8sDiffLink";
import { PREVIEW_LIMIT } from "../../../../../common/ProcessList/details/deployEvent/K8sSection";
import useK8sData from "../../../../../common/ProcessList/details/deployEvent/useK8sData";
import { BoldGrayText } from "../../CheckDetailsDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkloadInfoCheck from "../WorkloadInfoCheck";
import { useDeployRelatedLinks } from "../../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import { AddLink } from "../../../../../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/AddLink/AddLink";
import { External_Link_Context_Enum } from "../../../../../../generated/graphql";
import EditableExternalLink, {
  isEditableLink,
} from "../../../../../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/EditableExternalLink";
import {
  dispatchLinkMetric,
  showLink,
} from "../../../../../ResourceView/tabs/InfoTab/AdditionalLinks/ManageExternalLinks/utils";
import { useHasPermissions } from "../../../../../../shared/hooks/useUserMetadata/rbac";
import useDateFormatter from "../../../../../../shared/hooks/useDateFormatter";
import { getAnnotations } from "../../../../../common/ProcessList/details/deployEvent/utils";

const Container = styled.div`
  margin-block-start: 1.5rem;
  font-size: 14px;
  line-height: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.25rem;
  margin-top: 0.25rem;
`;
const DeployTimes = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  gap: 1rem 1rem;
`;

const WorkloadInfoDetails: React.FC<{
  check: WorkloadInfoCheck;
}> = ({ check }) => {
  const { specsForDiff, k8sDiff } = useK8sData(check.output?.latestDeploy.id);
  const { canManageIntegrations } = useHasPermissions();
  const { format } = useDateFormatter();

  const deploySpec = specsForDiff?.newSpec;
  const serviceInfo = {
    cluster: check.output?.latestDeploy.clusterName,
    serviceId: check.output?.latestDeploy.services[0],
    namespace: check.output?.latestDeploy.namespace,
  };
  const deployRelatedLinks = useDeployRelatedLinks(
    getAnnotations(deploySpec),
    serviceInfo
  );
  const navigate = useNavigate();

  if (!check.output) {
    return null;
  }

  const { latestDeploy, failedPod } = check.output;
  const startTime = parseISO(latestDeploy.eventTime);
  const endTime = latestDeploy.endEventTime
    ? parseISO(latestDeploy.endEventTime)
    : new Date();
  const workflowEndTime = check.closedAt ?? new Date();

  return (
    <Container>
      <DeployTimes>
        <BoldGrayText>Start</BoldGrayText>{" "}
        <div>{format(parseISO(check.output.latestDeploy.eventTime))}</div>
        <BoldGrayText>End</BoldGrayText>{" "}
        <div>{format(parseISO(check.output.latestDeploy.endEventTime))}</div>
      </DeployTimes>
      <br />
      <BoldGrayText>Deploy spec diff</BoldGrayText>
      <br />
      {k8sDiff?.changes.length
        ? k8sDiff.changes
            .slice(0, PREVIEW_LIMIT)
            .map((c) => <DiffEntry key={c.name} change={c} />)
        : null}
      <br />
      <Buttons>
        <K8sDiffLink
          k8sDiff={k8sDiff}
          specsForDiff={specsForDiff}
          versionFrom={latestDeploy.versionFrom}
          versionTo={latestDeploy.versionTo}
          serviceName={latestDeploy.deploymentName}
        />
        <Button
          onClick={() =>
            navigate(
              `/main/deep-dive/${latestDeploy.services[0]}?eventId=${
                latestDeploy.id
              }&timeWindow=${startTime.getTime()}-${endTime.getTime()}&timeframe=custom`
            )
          }
        >
          View deploy
        </Button>
      </Buttons>
      <br />
      <br />
      <BoldGrayText>Links</BoldGrayText>
      <Links>
        {deployRelatedLinks.map((link, i) => {
          return (
            showLink(link, External_Link_Context_Enum.Drawer) && (
              <EditableExternalLink
                link={link}
                isEditable={isEditableLink(link, canManageIntegrations)}
                context={External_Link_Context_Enum.Drawer}
                key={i}
              >
                <CustomLinkComponent
                  key={link.text + link.url + i.toString()}
                  linkTemplate={link}
                  displayName={latestDeploy.deploymentName}
                  k8sClusterName={latestDeploy.clusterName}
                  namespace={latestDeploy.namespace}
                  deploySpec={deploySpec}
                  startTime={check.eventTime}
                  endTime={workflowEndTime}
                  failedPod={failedPod}
                  dispatchLinkMetric={dispatchLinkMetric(
                    External_Link_Context_Enum.Drawer,
                    link
                  )}
                />
              </EditableExternalLink>
            )
          );
        })}
        <AddLink
          context={External_Link_Context_Enum.Drawer}
          k8sClusterName={latestDeploy.clusterName}
          namespace={latestDeploy.namespace}
          serviceId={latestDeploy.services[0]}
          serviceDisplayName={latestDeploy.deploymentName}
        />
      </Links>
      <br />
    </Container>
  );
};

export default WorkloadInfoDetails;
