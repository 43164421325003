import { Node, Edge } from "react-flow-renderer";

import WorkflowCheck from "./checks";
import { NodeDataType } from "./Diagram";

const useDiagramElements = (
  checks: WorkflowCheck[],
  trigger: string,
  selectedCheckType: string | null,
  setSelectedCheckType: (checkType: string | null) => void
): { nodes: Node<NodeDataType>[]; edges: Edge[] } => {
  let allNodes: Node<NodeDataType>[] = [
    {
      id: "trigger-node-id",
      type: "customInput",
      draggable: false,
      style: { pointerEvents: "none" },
      data: { label: trigger, icon: "trigger" },
      position: { x: 100, y: 100 },
    },
  ];
  let allEdges: Edge[] = [];

  let lastYPosition = 100;
  checks.forEach((c, i) => {
    let yPosition;
    if (i === 0) {
      yPosition = lastYPosition;
    } else {
      yPosition = lastYPosition + 150;
    }
    lastYPosition = yPosition;
    const [nodes, edges] = c.getDiagramElements(yPosition, () =>
      selectedCheckType === c.type
        ? setSelectedCheckType(null)
        : setSelectedCheckType(c.type)
    );
    allNodes = [...allNodes, ...nodes];
    allEdges = [...allEdges, ...edges];
  });

  return { nodes: allNodes, edges: allEdges };
};

export default useDiagramElements;
