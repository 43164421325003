import { useCallback, useEffect } from "react";
import { UseQueryState } from "urql/dist/types/hooks/useQuery.d";
import { useNavigate } from "react-router-dom";

import {
  appViewsByIdSelector,
  currentAppViewSelector,
  selectedAppViewIdSelector,
  setAppViewsByIdSelector,
  setDataStateSelector,
  setFetchAppViewServicesStateSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import {
  initialState,
  useAppViewsStore,
} from "../../../../../shared/store/appViewsStore/appViewsStore";
import { toAppView, toAppViewDictionary } from "../utils/appViewsUtils";
import { Dictionary } from "../../../../../shared/types/Dictionary";
import { AppView } from "../types/appViewsTypes";
import { useIsAnonymousUser } from "../../../../../shared/hooks/useIsAnonymousUser";
import { useServicesPageFiltersLocalStorageContext } from "../../../../../shared/context/ServicesPageFiltersLocalStorageProvider";
import { useJobsPageFiltersLocalStorageContext } from "../../../../../shared/context/JobsPageFiltersLocalStorageProvider";
import { useAppViewsResourcesStore } from "../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import { selectResetResources } from "../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStoreSelectors";
import { useWorkspaces } from "../../../../workspaces/WorkspacesTopBar/hooks";

import {
  useRemoveAppViewIdFromLocalStorage,
  useSetAppViewIdInLocalStorage,
} from "./appViewPersistentState";
import {
  useExecuteAppViewsQuery,
  useIsInAppViewsPath,
  useOnAppViewChange,
  usePollServiceIdsInAppView,
  useSetIsFetchingData,
  useSetServiceIdsAndScope,
} from "./appViewsHooks";
import {
  usePopulateResourceClusters,
  usePopulateResourceTags,
  usePopulateServiceKinds,
} from "./resourceInventoryHooks";
import { usePopulateResources } from "./resourcesApiPopulator";

const useSetAppViewIdEffect = (response: UseQueryState): void => {
  const setDataState = useAppViewsStore(setDataStateSelector);
  const appViewsById = useAppViewsStore(appViewsByIdSelector);
  const setAppViewsById = useAppViewsStore(setAppViewsByIdSelector);

  useEffect(() => {
    if (!setAppViewsById || appViewsById) return;
    const { error, fetching, data } = response;
    if (error || fetching) {
      setDataState({ error: !!error, loading: fetching });
      return;
    }

    const fetchedAppViews = (data?.app_view ?? [])
      .map(toAppView)
      .reduce(toAppViewDictionary, {} as Dictionary<AppView>);

    setAppViewsById(fetchedAppViews);
  }, [appViewsById, response, setAppViewsById, setDataState]);
};

const useRemoveLocalStorageForAnonymousUser = (): void => {
  const isAnonymousUser = useIsAnonymousUser();
  const removeAppViewIdFromLocalStorage = useRemoveAppViewIdFromLocalStorage();
  useEffect(() => {
    isAnonymousUser && removeAppViewIdFromLocalStorage();
  }, [isAnonymousUser, removeAppViewIdFromLocalStorage]);
};

const useClearAppViews = () => {
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const navigate = useNavigate();
  const isInAppViewsPath = useIsInAppViewsPath();

  return useCallback(() => {
    isInAppViewsPath && navigate("/");
    setSelectedAppViewId(undefined);
  }, [isInAppViewsPath, navigate, setSelectedAppViewId]);
};

const useAppViewIdNotFoundEffect = (appViewIdNotFound: boolean): void => {
  const setAppViewIdInLocalStorage = useSetAppViewIdInLocalStorage();
  const clearAppViews = useClearAppViews();

  useEffect(() => {
    // selectedAppViewId was not found within appViewsById, clear AppView state
    if (appViewIdNotFound) {
      clearAppViews();
      setAppViewIdInLocalStorage(undefined);
    }
  }, [appViewIdNotFound, clearAppViews, setAppViewIdInLocalStorage]);
};

const useHandleFetchAppViewError = (error: boolean): void => {
  const clearAppViews = useClearAppViews();
  useEffect(() => {
    if (error) clearAppViews();
  }, [clearAppViews, error]);
};

const useHandleAppViewsInLocalStorage = (): void => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const appViewsById = useAppViewsStore(appViewsByIdSelector);
  const setAppViewIdInLocalStorage = useSetAppViewIdInLocalStorage();
  const storedServicesViewQS = useServicesPageFiltersLocalStorageContext();
  const storedJobsViewQS = useJobsPageFiltersLocalStorageContext();

  useEffect(() => {
    if (!appViewsById) return;
    storedServicesViewQS.remove();
    storedJobsViewQS.remove();
  }, [
    appViewsById,
    selectedAppViewId,
    setAppViewIdInLocalStorage,
    storedJobsViewQS,
    storedServicesViewQS,
  ]);
};

const useResetStoreOnAppViewChange = (): void => {
  const { isLoading, isWorkspaceKindBackendFiltered } = useWorkspaces();
  const resetResources = useAppViewsResourcesStore(selectResetResources);
  const setFetchAppViewServicesState = useAppViewsStore(
    setFetchAppViewServicesStateSelector
  );
  const setServiceIdsAndScope = useSetServiceIdsAndScope();

  const onAppViewChange = useCallback(() => {
    resetResources(
      !isLoading && !isWorkspaceKindBackendFiltered
        ? undefined
        : { isLoadingResources: false }
    );

    if (!isLoading && isWorkspaceKindBackendFiltered) {
      setServiceIdsAndScope();
    }
    setFetchAppViewServicesState(initialState.fetchAppViewServicesState);
  }, [
    resetResources,
    isLoading,
    isWorkspaceKindBackendFiltered,
    setFetchAppViewServicesState,
    setServiceIdsAndScope,
  ]);

  useOnAppViewChange(onAppViewChange);
};

export const useAppViews = (): void => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const appViewsById = useAppViewsStore(appViewsByIdSelector);
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const [response] = useExecuteAppViewsQuery();
  const { currentWorkspace, isLoading, isWorkspaceKindBackendFiltered } =
    useWorkspaces();

  usePollServiceIdsInAppView(currentAppView.id);

  const legacyAppViewsNotFound = Boolean(
    appViewsById && selectedAppViewId && !appViewsById[selectedAppViewId]
  );

  // TODO: remove isWorkspaceKindBackendFiltered when the backend is ready for all workspaces kinds
  const workspaceNotFound =
    !isLoading && (!currentWorkspace || !isWorkspaceKindBackendFiltered);
  const appViewIdNotFound = legacyAppViewsNotFound && workspaceNotFound;

  useSetIsFetchingData({
    response,
    appViewIdNotFound,
  });

  useSetAppViewIdEffect(response);

  useHandleAppViewsInLocalStorage();

  useRemoveLocalStorageForAnonymousUser();

  useAppViewIdNotFoundEffect(appViewIdNotFound);
  useHandleFetchAppViewError(!!response.error);

  useResetStoreOnAppViewChange();

  usePopulateResourceTags();
  usePopulateResourceClusters();
  usePopulateServiceKinds();
  usePopulateResources();
};
