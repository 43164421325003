import React from "react";
import { parseISO } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../../groupEvents";
import { Maybe } from "../../../../../generated/graphql";
import EventGroup, { EventBase } from "../..";
import { blueForUI, blueForUIText } from "../../../../../Colors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PdbDetails } from "../../../ProcessList/details/PdbDetailes";
import { pdbType } from "../types";
import { PdbEventsResponseDataInner } from "../../../../../generated/resourcesApi";

import ConfigChangeScatterShape from "./../../shapes/config.svg?react";

export interface PdbEvent extends EventBase {
  readonly name: string;
  readonly namespace?: string;
  readonly clusterName: string;
  readonly action?: Maybe<string>;
}

const toPdbEvent = (
  e: PdbEventsResponseDataInner,
  serviceId: string
): PdbEvent => ({
  id: e.id,
  serviceId,
  eventTime: parseISO(e.eventTime ?? ""),
  type: pdbType,
  name: e.name ?? "",
  namespace: e.namespace,
  clusterName: e.clusterName ?? "",
  action: e.action,
});

export default class PdbEventGroup implements EventGroup {
  readonly backgroundColor = blueForUI;
  readonly fillColor = blueForUIText;
  readonly icon = ConfigChangeScatterShape;

  readonly events: PdbEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly status;
  readonly serviceId;
  readonly sendEventWhenClicked = true;

  constructor(e: PdbEvent) {
    this.events = [e];
    this.id = `${e.serviceId}:${e.id}`;
    this.type = pdbType;
    this.summary =
      (e.action ? `${e.action}` : "") +
      ` - ${e.clusterName}/${e.namespace}/${e.name}`;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.details = "";
    this.status = "Completed";
    this.serviceId = e.serviceId;
  }

  renderEventDetails(): JSX.Element {
    return <PdbDetails eventGroup={this} />;
  }
}

export const groupPdbEvents = (
  events: PdbEventsResponseDataInner[],
  serviceId: string
): PdbEventGroup[] => {
  return mapAndSort(withServices(events, serviceId), toPdbEvent).map(
    (e) => new PdbEventGroup(e)
  );
};
