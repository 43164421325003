import styled from "styled-components";

import Checkbox from "../../../../../../common/controls/Checkbox";

const Option = styled(Checkbox)`
  margin: 0;
  input {
    margin: 0 0.5rem 0 0;
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export default Option;
