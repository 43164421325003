import { useMemo, useState } from "react";

import { Timeframe, TimeWindow } from "@/shared/types/TimeWindow";
import { getServiceIdFromKomodorUid } from "@/shared/utils/getServiceIdFromKomodorUid";
import { useAccountName } from "@/shared/hooks/useAccountName";
import useKomodorServices from "@/shared/hooks/useKomodorServices";

type SharedProcessListParams = {
  fromEpoch: number;
  toEpoch: number;
  komodorUids: string[];
};

export const useSharedProcessListProps = ({
  komodorUids,
  fromEpoch,
  toEpoch,
}: SharedProcessListParams) => {
  const [eventId, setEventId] = useState<string | null>(null);
  const [, setIntentId] = useState<string | null>();
  const [timeWindow, setTimeWindow] = useState<TimeWindow>({
    start: new Date(fromEpoch * 1000),
    end: new Date(toEpoch * 1000),
    timeframe: Timeframe.Custom,
  });
  const accountName = useAccountName();
  const servicesDic = useKomodorServices().servicesAsDictionary.all;

  const serviceIds = useMemo(() => {
    return komodorUids.map((komodorUid) => {
      return (
        getServiceIdFromKomodorUid({
          komodorUid,
          accountName,
          servicesDic: servicesDic,
          serviceShouldExist: false,
        })?.serviceId ?? ""
      );
    });
  }, [accountName, komodorUids, servicesDic]);

  return {
    eventId,
    setEventId,
    setIntentId,
    timeWindow,
    setTimeWindow,
    serviceIds,
  };
};
