import React from "react";
import { Kubernetes16 } from "@komodorio/design-system/icons";

import { DESKTOP_CLUSTERS_ROUTE } from "../../DesktopClustersView/DesktopClustersView";

import { AppBarLink } from "./AppBarLink";

const DISPLAY_NAME = "Cluster management";

export const DesktopClusterManagementLink: React.FC = () => (
  <AppBarLink
    text={DISPLAY_NAME}
    route={DESKTOP_CLUSTERS_ROUTE}
    icon={<Kubernetes16 />}
  />
);
