import React from "react";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";

import { useStateInLocalStorage } from "../../../shared/hooks/state";
import useAgentInfo from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import ModalComponent from "../Modal";
import { DarkenOverlay } from "../Overlay";
import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import { doesAgentVersionSupportLiveDataView } from "../../../shared/utils/agent/agent";
import { UPGRADE_DOCS_LINK } from "../../../shared/constants/docsLinks";

import UpgradeEmoji from "./upgrade.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import UpgradeAgentInstruction from "./UpgradeAgentInstruction";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum UpgradeChartName {
  komodorAgent = "komodorAgent",
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum UpgradeCommands {
  INSTALL_COMMAND = "INSTALL_COMMAND",
  UPGRADE_DOCS = "UPGRADE_DOCS",
  UPGRADE_COMMAND = "UPGRADE_COMMAND",
  NEW_UPGRADE_COMMAND = "NEW_UPGRADE_COMMAND",
  UPGRADE_COMMAND_ENABLE_ACTIONS = "UPGRADE_COMMAND_ENABLE_ACTIONS",
  UPGRADE_COMMAND_ENABLE_HELM = "UPGRADE_COMMAND_ENABLE_HELM",
  UPGRADE_COMMAND_ENABLE_CUSTOM_RESOURCES = "UPGRADE_COMMAND_ENABLE_CUSTOM_RESOURCES",
  UPGRADE_COMMAND_JOB_EVENTS = "UPGRADE_COMMAND_JOB_EVENTS",
  UPGRADE_COMMAND_POD_EVENTS = "UPGRADE_COMMAND_POD_EVENTS",
  UPGRADE_COMMAND_METRICS = "UPGRADE_COMMAND_METRICS",
  UPGRADE_COMMAND_PORT_FORWARDING = "UPGRADE_COMMAND_PORT_FORWARDING",
  UPGRADE_COMMAND_NETWORK_MAPPER = "UPGRADE_COMMAND_NETWORK_MAPPER",
  UPGRADE_COMMAND_ARGO_WORKFLOWS = "UPGRADE_COMMAND_ARGO_WORKFLOWS",
  UPGRADE_COMMAND_KUBECONFIG_RBAC_SYNC = "UPGRADE_COMMAND_KUBECONFIG_RBAC_SYNC",
}

type UpgradeCommandInfo = {
  [key in UpgradeCommands]?: string;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const UPGRADE_COMMANDS: UpgradeCommandInfo = {
  [UpgradeCommands.INSTALL_COMMAND]:
    // eslint-disable-next-line no-template-curly-in-string
    "helm repo add komodorio https://helm-charts.komodor.io ; helm repo update; helm install komodor-agent komodorio/komodor-agent --set apiKey=${apiKey} --set clusterName=${clusterName} ${watcherServerHost} --timeout=90s && ${openBrowserCommand} ${komodorUrl}",
  [UpgradeCommands.UPGRADE_DOCS]: UPGRADE_DOCS_LINK,
  [UpgradeCommands.UPGRADE_COMMAND]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f -",
  [UpgradeCommands.NEW_UPGRADE_COMMAND]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f -",
  [UpgradeCommands.UPGRADE_COMMAND_ENABLE_ACTIONS]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f -",
  [UpgradeCommands.UPGRADE_COMMAND_ENABLE_HELM]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set allowedResources.secret=true --set capabilities.enableHelm=true",
  [UpgradeCommands.UPGRADE_COMMAND_ENABLE_CUSTOM_RESOURCES]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set allowedResources.allowReadAll=true",
  [UpgradeCommands.UPGRADE_COMMAND_JOB_EVENTS]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set allowedResources.job=true --set allowedResources.cronjob=true",
  [UpgradeCommands.UPGRADE_COMMAND_POD_EVENTS]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set allowedResources.event=true --set capabilities.logs.enabled=true",
  [UpgradeCommands.UPGRADE_COMMAND_METRICS]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set capabilities.metrics=true",
  [UpgradeCommands.UPGRADE_COMMAND_PORT_FORWARDING]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f -",
  [UpgradeCommands.UPGRADE_COMMAND_NETWORK_MAPPER]:
    "helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set capabilities.networkMapper=true",
  [UpgradeCommands.UPGRADE_COMMAND_ARGO_WORKFLOWS]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set allowedResources.argoWorkflows.workflows=true --set allowedResources.argoWorkflows.workflowTemplates=true --set allowedResources.argoWorkflows.clusterWorkflowTemplates=true --set allowedResources.argoWorkflows.cronWorkflows=true",
  [UpgradeCommands.UPGRADE_COMMAND_KUBECONFIG_RBAC_SYNC]:
    "helm repo update; helm get values komodor-agent | helm upgrade komodor-agent komodorio/komodor-agent -f - --set capabilities.rbac=true",
};

export const UPGRADE_AGENT_SUGGESTION =
  "Upgrade the agent to enjoy our new live pod view";
const storedAgentCta = new LocalStorageItem("showUpgradeAgentCTA");

const UpgradeModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 46rem;
  height: 28.8rem;
  background-color: #fafbfc;
`;

const UpgradeAgentHeadline = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 140.3%;
  text-align: center;
  color: #6e7a96;
  width: 26rem;
`;

const UpgradeAgentCTA: React.FC = () => {
  const agentInfo = useAgentInfo();
  const defaultVal = String(doesAgentVersionSupportLiveDataView(agentInfo));
  const [isShouldUpgradeAgent, setIsShouldUpgradeAgent] =
    useStateInLocalStorage(storedAgentCta, defaultVal);

  const handleClose = () => {
    setIsShouldUpgradeAgent("false");
  };

  return (
    <>
      <DarkenOverlay open={isShouldUpgradeAgent === "true"} />
      <ModalComponent
        isOpen={isShouldUpgradeAgent === "true"}
        title={""}
        handleCloseModal={handleClose}
        customStyle={{ background: "#fafbfc" }}
      >
        <UpgradeModal>
          <UpgradeEmoji />
          <UpgradeAgentHeadline>
            {UPGRADE_AGENT_SUGGESTION}
          </UpgradeAgentHeadline>
          <UpgradeAgentInstruction />
          <Button variant="secondary" onClick={handleClose}>
            Done!
          </Button>
        </UpgradeModal>
      </ModalComponent>
    </>
  );
};

export default UpgradeAgentCTA;
