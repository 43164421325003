import React, { useMemo, useState } from "react";
import { v4 as uuid4 } from "uuid";

import Steps from "../../../common/Steps";

import SentrySetup from "./steps/sentrySetup";
import SentryConfigTokens from "./steps/sentryConfigTokens";
import FinishSetup from "./steps/FinishSetup";

const webhookPath = "https://app.komodor.com/sentry/issue";

const SentryInstallation: React.FC<{ closeModalCallback: () => void }> = ({
  closeModalCallback,
}) => {
  const [numOfSteps, setNumOfSteps] = useState(0);
  const [curStep, setCurStep] = useState(0);
  const [finishInstallation, setFinishInstallation] = useState(false);
  const installationId = useMemo(() => uuid4(), []);
  return (
    <>
      {!finishInstallation ? (
        <Steps curStep={curStep} setNumberOfSteps={setNumOfSteps}>
          <SentrySetup
            closeModalCallback={closeModalCallback}
            setCurStep={setCurStep}
            curStep={curStep}
            numOfSteps={numOfSteps}
            webhookUrl={`${webhookPath}/${installationId}`}
          />
          <SentryConfigTokens
            closeModalCallback={closeModalCallback}
            setCurStep={setCurStep}
            curStep={curStep}
            numOfSteps={numOfSteps}
            setFinishInstallation={setFinishInstallation}
            installationId={installationId}
          />
        </Steps>
      ) : (
        <FinishSetup closeModalCallback={closeModalCallback} />
      )}
    </>
  );
};

export default SentryInstallation;
