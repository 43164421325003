import pkceGenerator, {
  PKCE,
  saveCodeVerifier,
} from "../../../shared/utils/pkceGenerator";
import { PAGER_DUTY_CLIENT_ID } from "../../../constants";
import { getAppConfig } from "../../../shared/config/appConfig";

const createUrl = ({ codeChallenge, challengeMethod }: PKCE) =>
  `https://app.pagerduty.com/oauth/authorize?client_id=${PAGER_DUTY_CLIENT_ID}&redirect_uri=${
    getAppConfig().pagerDutyRedirectUrl
  }&response_type=code&code_challenge_method=${challengeMethod}&code_challenge=${codeChallenge}`;

export default async function getPDInstallationUrl(): Promise<string> {
  const pkce = await pkceGenerator();
  saveCodeVerifier(pkce.codeVerifier);
  return createUrl(pkce);
}
