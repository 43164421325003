import { useDrawerUrlState } from "../../../../../../../shared/hooks/state";
import { RELIABILITY_PARAM_KEY } from "../../../../../../../shared/config/urlSearchParamsKeys";
import { DEFAULT_DRAWER_TAB } from "../../../../../constants/reliabilityConstants";
import { ViolationDrawerProps } from "../violationDrawerTypes";

export const useGetCurrentTab = () => {
  const [drawerStateInUrl] = useDrawerUrlState<ViolationDrawerProps>(
    RELIABILITY_PARAM_KEY
  );
  return drawerStateInUrl?.currentTab ?? DEFAULT_DRAWER_TAB;
};
