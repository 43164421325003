import React, { useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Stack from "@mui/material/Stack";
import { Pencil16, Reload16, Trash16 } from "@komodorio/design-system/icons";

import { User } from "@/generated/auth";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { usersTableActions } from "@/components/Settings/Users/types";

type ActionsCellProps = {
  params: GridRenderCellParams<User>;
} & usersTableActions;

export const ActionsCell: React.FC<ActionsCellProps> = ({
  params,
  onEdit,
  onRestore,
  onDelete,
}) => {
  const { canManageUsers } = useHasPermissions();
  const { userId } = useUserMetadata();
  const [hovered, setHovered] = React.useState(false);

  const onMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setHovered(false);
  }, []);

  const actions = React.useMemo(() => {
    const disabled = params.id === userId;
    if (params.row.deletedAt) {
      return [
        <IconButton
          key="restore-user"
          aria-label="Restore user"
          disabled={disabled}
          onClick={() => onRestore(params.row as User)}
        >
          <Reload16 />
        </IconButton>,
      ];
    }

    return [
      <IconButton
        key="delete-user"
        aria-label="Delete user"
        color="error"
        onClick={() => onDelete(params.row as User)}
        disabled={disabled}
      >
        <Trash16 />
      </IconButton>,
      <IconButton
        key="edit-user"
        aria-label="Edit user"
        onClick={() => onEdit(params.row as User)}
      >
        <Pencil16 />
      </IconButton>,
    ];
  }, [onDelete, onEdit, onRestore, params, userId]);

  if (!canManageUsers) {
    return null;
  }

  return (
    <Stack
      width="100%"
      height="100%"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      alignItems="center"
      justifyContent="center"
    >
      {hovered && <Stack direction="row">{actions}</Stack>}
    </Stack>
  );
};
