import { has, get, isEqual, isObjectLike } from "lodash";

type Change = { path: string[]; from?: unknown; to?: unknown };

export default function deepDiff(
  fromObject: Record<string, unknown>,
  toObject: Record<string, unknown>
): Change[] {
  const changes: Change[] = [];

  const walk = (
    fromObject: Record<string, unknown>,
    toObject: Record<string, unknown>,
    path: string[] = []
  ) => {
    for (const [key, from] of Object.entries(fromObject)) {
      const currentPath = [...path, key];
      if (!has(toObject, key)) {
        changes.push({ path: currentPath, from });
      }
    }

    for (const [key, to] of Object.entries(toObject)) {
      const currentPath = [...path, key];
      if (!has(fromObject, key)) {
        changes.push({ path: currentPath, to });
      } else {
        const from = get(fromObject, key);
        if (!isEqual(from, to)) {
          if (isObjectLike(to) && isObjectLike(from)) {
            walk(
              from as Record<string, unknown>,
              to as Record<string, unknown>,
              currentPath
            );
          } else {
            changes.push({ path: currentPath, from, to });
          }
        }
      }
    }
  };

  walk(fromObject, toObject);

  return changes;
}
