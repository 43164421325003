import React from "react";
import styled from "styled-components";

import { blackGreyText } from "../../../Colors";
import { H4, Text } from "../../common/typography";
import { PolarisSupportedChecks } from "../PolarisWrapper";

const Container = styled.div`
  padding-left: 1.8rem;
`;

const SmallBr = styled.span`
  display: block;
  margin: 8px 0;
`;

const StyledText = styled(Text)`
  color: ${blackGreyText};
`;

const StyledH4 = styled(H4)`
  color: ${blackGreyText};
`;

const StaticPreventionCheckContent: React.FC<{ checkId: string }> = ({
  checkId,
}) => (
  <Container>
    <SmallBr />
    <StyledH4>What is checked?</StyledH4>
    <StyledText>{PolarisSupportedChecks[checkId].whatChecked}</StyledText>
    <br />
    <br />
    <StyledH4>Why should this be checked?</StyledH4>
    <StyledText>{PolarisSupportedChecks[checkId].whyCheckd}</StyledText>
    <br />
    <br />
  </Container>
);
export default StaticPreventionCheckContent;
