import { sortBy, sortedUniqBy } from "lodash";

import deepDiff from "../../../../shared/utils/deepDiff";

import toPathString from "./toPathString";
import getNormalizedK8s from "./getNormalizedK8s";

const prepareValue = (name: string | undefined, value: unknown) => {
  if (name == null || value == null) {
    return null;
  }
  if (name === "image" && typeof value === "string") {
    return value.substring(value.lastIndexOf("/") + 1);
  }
  if (value && typeof value === "object") {
    const keys = Object.keys(value);
    if (keys.length === 1 && keys[0] === "value") {
      return String((value as { value: unknown }).value);
    }
    return JSON.stringify(value, null, 2);
  }
  return String(value);
};

export interface Change {
  name: string;
  previous: string | null;
  current: string | null;
}

export interface K8sDiff {
  changes: Change[];
  hiddenCount: number;
}

const getK8sDiff = (
  oldSpec: Record<string, unknown>,
  newSpec: Record<string, unknown>
): K8sDiff => {
  const normalizedOldSpec = getNormalizedK8s(oldSpec);
  const normalizedNewSpec = getNormalizedK8s(newSpec);
  const diff = deepDiff(normalizedOldSpec, normalizedNewSpec);
  const allChanges = diff.map(({ path, from, to }) => {
    const name = toPathString(path) ?? "";
    return {
      name,
      previous: prepareValue(name, from),
      current: prepareValue(name, to),
    };
  });
  const changes = sortedUniqBy(
    sortBy(
      allChanges.filter(({ name }) => name),
      ({ name }) => (name?.includes(": ") ? 1 : 0),
      ({ name }) => name
    ),
    (c) => c.name
  );
  return { changes, hiddenCount: allChanges.length - changes.length };
};

export default getK8sDiff;
