import React, { SVGProps, ReactElement, memo } from "react";

const Dot = memo((props: SVGProps<unknown> & { value?: number }) => {
  if (props.value === 0) return null;
  const { cx, cy, r, stroke, strokeWidth, fill } = props;
  return (
    <circle
      cx={cx}
      cy={cy}
      r={r}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  );
});

const DotElement = (<Dot />) as unknown as ReactElement<SVGElement>;

export default DotElement;
