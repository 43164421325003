import { MonitorData } from "../../generated/monitorsApi/api";

import { oomKilled } from "./oomKilled";

export const issues: MonitorData[] = [
  {
    closeReason: "",
    closedAt: null,
    cluster: "development",
    eventTime: "2023-11-06T03:50:00.858Z",
    id: "4f7b1460-65ba-4098-b94b-c1296f09d7d3",
    namespace: "",
    reasons: [],
    resultsSummary: {
      "node-evicted-pods": true,
      "node-network-available": true,
      "node-other-condition-failure": true,
      "node-over-committed": false,
      "node-ready": false,
      "node-resource-consumption": true,
      "node-schedulable": false,
      "node-system-pods-healthy": false,
      "node-under-pressure": true,
      "node-user-pods-healthy": false,
    },
    services: [
      "demo.production-datadog.datadog",
      "demo.production-default.prometheus-prometheus-node-exporter",
      "demo.production-demo.enrichment-processor",
      "demo.production-demo.k8s-watcher-daemon",
      "demo.production-demo.transaction-api",
    ],
    shortResourceName: "mock-node",
    sourceEventId:
      "demo-production-global-ip-10-0-2-88.us-east-2.compute.internal-317821486-857b98ce-a59d-4001-8001-230d66cb633f",
    type: "node",
    workflowConfiguration: {
      accountId: "fe52cf0b-42da-4ae1-a8db-776e0f1335a0",
      active: true,
      id: "76ae174c-bb9a-49e0-a972-47f11913d8db",
      isDeleted: false,
      name: "default-node-monitor",
      sensors: [
        {
          cluster: "production",
        },
      ],
      sinks: null,
      sinksOptions: null,
      type: "node",
      variables: {
        duration: 60,
      },
    },
  },
  {
    cluster: "development",
    eventTime: "2023-11-08T06:18:00.708Z",
    id: "11111111-5706-4bde-817a-dcdcac8dfae5",
    namespace: "default",
    reasons: ["OOMKilled - Exit code: 137"],
    services: ["sandbox.development-default.mock-deployment"],
    shortResourceName: "mock-deployment",
    type: "availability",
  },
  {
    cluster: "development",
    eventTime: "2023-11-08T06:18:00.708Z",
    id: "11111111-5706-4bde-817a-dcdcac8dfae5",
    namespace: "default",
    reasons: ["OOMKilled - Exit code: 137"],
    services: ["sandbox.development-default.mock-deployment"],
    shortResourceName: "mock-deployment-test-7m9bv",
    type: "pod",
    results: {
      "pod-healthy": {
        cta: "OK",
        type: "pod-healthy",
        output: {
          pod: {
            qos: "Guaranteed",
            name: "asset-health-train-predict-9970-5h20xz39",
            phase: "Pending",
            nodeName: "ip-10-213-85-110.us-west-2.compute.internal",
            priority: null,
            isEvicted: false,
            isHealthy: false,
            podEvents: [
              {
                type: "Warning",
                count: 2,
                reason: "FailedScheduling",
                message:
                  "0/4 nodes are available: 1 Insufficient memory, 1 node(s) had untolerated taint {node.kubernetes.io/not-ready: }, 2 node(s) didn't match Pod's node affinity/selector. preemption: 0/4 nodes are available: 1 No preemption victims found for incoming pod, 3 Preemption is not helpful for scheduling..",
                lastTimestamp: "2023-09-13T00:31:14Z",
                firstTimestamp: "2023-09-13T00:31:10Z",
              },
              {
                type: "Warning",
                count: 2,
                reason: "FailedScheduling",
                message:
                  "0/3 nodes are available: 1 Insufficient memory, 2 node(s) didn't match Pod's node affinity/selector. preemption: 0/3 nodes are available: 1 No preemption victims found for incoming pod, 2 Preemption is not helpful for scheduling..",
                lastTimestamp: "2023-09-13T00:30:40Z",
                firstTimestamp: "2023-09-13T00:30:38Z",
              },
            ],
            containers: [
              {
                name: "base",
                ready: false,
                started: false,
                isHealthy: false,
                lastState: null,
                logTaskId: "",
                resources: {},
                isLastState: false,
                currentState: {
                  state: "Waiting",
                  reason: "ContainerCreating",
                  message: "",
                  exitCode: "",
                },
                restartCount: 0,
                failureMessage:
                  "Agent does not support container logs, please upgrade to view full capabilities",
                calculatedReason: "ContainerCreating",
              },
            ],
            failureMessage: null,
            calculatedReason: "FailedScheduling",
            calculatedMessage:
              "0/4 nodes are available: 1 Insufficient memory, 1 node(s) had untolerated taint {node.kubernetes.io/not-ready: }, 2 node(s) didn't match Pod's node affinity/selector. preemption: 0/4 nodes are available: 1 No preemption victims found for incoming pod, 3 Preemption is not helpful for scheduling..",
            deletionTimestamp: "0001-01-01T00:00:00Z",
            podReadyCondition: {
              type: "Ready",
              reason: "ContainersNotReady",
              status: "False",
              message: "containers with unready status: [base]",
              lastProbeTime: null,
              lastTransitionTime: "2023-09-13T00:31:23Z",
            },
          },
          issueSummary: {
            count: 1,
            reason: "FailedScheduling",
            errorMessage:
              "0/4 nodes are available: 1 Insufficient memory, 1 node(s) had untolerated taint {node.kubernetes.io/not-ready: }, 2 node(s) didn't match Pod's node affinity/selector. preemption: 0/4 nodes are available: 1 No preemption victims found for incoming pod, 3 Preemption is not helpful for scheduling..",
            containersLogs: null,
          },
          failureMessage: null,
          isPodLogsAllowed: true,
          isPodEventAllowed: true,
        },
        passed: false,
        shortOutput: "The pod is unhealthy",
      },
    },
  },
  oomKilled,
];
