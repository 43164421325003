import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import IconButton from "@mui/material/IconButton";

import { ActionsCol, CopyText } from "../styles";
import { Column } from "../../common/table/DataTable";

import { isCustomK8sAction, ReadOnlyActionTypes } from "./actionTypes";
import { ActionTypeChip } from "./ActionTypeChip";
import { Action, SetStateFn } from "./common";

type BuildRbacActionsColumnArgs = {
  setElementToDelete: SetStateFn<Action>;
  setElementToEdit: SetStateFn<Action>;
  timezoneName: string;
  formatDate: (date?: Date | number) => string;
};

export const buildRbacActionsColumns: (
  args: BuildRbacActionsColumnArgs
) => Column<Action>[] = ({
  setElementToDelete,
  setElementToEdit,
  timezoneName,
  formatDate,
}) => {
  return [
    {
      name: "Name",
      selector: (a: Action) => <CopyText text={a.action} />,
    },
    {
      name: "Type",
      selector: (a: Action) => <ActionTypeChip label={a.type} />,
    },
    { name: "Description", selector: (a: Action) => a.description },
    {
      name: `Modified At (${timezoneName})`,
      selector: (a: Action) => {
        if (isCustomK8sAction(a)) {
          return formatDate(new Date(a.updatedAt));
        }

        return undefined;
      },
    },
    {
      name: "",
      selector: (a: Action) => {
        if (ReadOnlyActionTypes.includes(a.type)) {
          return (
            <ActionsCol>
              <IconButton
                onClick={() => {
                  setElementToDelete(undefined);
                  setElementToEdit(a);
                }}
              >
                <VisibilityOutlinedIcon color={"action"} fontSize="small" />
              </IconButton>
            </ActionsCol>
          );
        }
        return (
          <ActionsCol>
            <IconButton
              onClick={() => {
                setElementToEdit(undefined);
                setElementToDelete(a);
              }}
            >
              <DeleteOutlineIcon color={"error"} fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => {
                setElementToDelete(undefined);
                setElementToEdit(a);
              }}
            >
              <EditOutlinedIcon color={"action"} fontSize="small" />
            </IconButton>
          </ActionsCol>
        );
      },
    },
  ];
};
