import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useLocation, NavigateOptions } from "react-router-dom";

import {
  TimeWindow,
  SetTimeWindow,
  Timeframe,
} from "../../../../../../shared/types/TimeWindow";
import {
  TIMEFRAME_PARAM_KEY,
  TIME_WINDOW_PARAM_KEY,
} from "../../../../../../shared/config/urlSearchParamsKeys";

import { getTimeframeForLabel } from "./timeframeLabel";
import getUpdatedTimeWindow, {
  parseTimeWindowDataToUrlData,
} from "./getUpdatedTimeWindow";

const timeWindowPattern = /^(\d+)-(\d+)$/;
const useTimeWindowValue = (
  initialTimeWindow: TimeWindow,
  timeWindowKey: string,
  timeFrameKey: string
) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const timeWindowFromSearchParams = searchParams.get(timeWindowKey);
  const timeframeFromSearchParams = searchParams.get(timeFrameKey);

  const timeframe = timeframeFromSearchParams
    ? getTimeframeForLabel(timeframeFromSearchParams, Timeframe.Custom)
    : initialTimeWindow.timeframe;

  return useMemo(() => {
    if (timeWindowFromSearchParams) {
      const match = timeWindowPattern.exec(timeWindowFromSearchParams);
      if (match) {
        const start = new Date(+match[1]);
        const end = new Date(+match[2]);
        return { start, end, timeframe };
      }
    }
    return getUpdatedTimeWindow(initialTimeWindow, { timeframe });
  }, [initialTimeWindow, timeWindowFromSearchParams, timeframe]);
};

const useSetTimeWindow = (
  current: TimeWindow,
  timeWindowKey: string,
  timeFrameKey: string
) => {
  const navigate = useNavigate();
  return useCallback<SetTimeWindow>(
    (updated, options?: NavigateOptions) => {
      const timeWindow = getUpdatedTimeWindow(current, updated);
      const searchParams = new URLSearchParams(window.location.search);
      const { urlTimeWindow, urlTimeframe } =
        parseTimeWindowDataToUrlData(timeWindow);
      searchParams.set(timeWindowKey, urlTimeWindow);
      searchParams.set(timeFrameKey, urlTimeframe);
      navigate(
        {
          pathname: window.location.pathname,
          search: searchParams.toString(),
        },
        options
      );
    },
    [current, navigate, timeFrameKey, timeWindowKey]
  );
};

const isTimeWindowExistOnQueryString = (timeWindowKey: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  return Boolean(searchParams.get(timeWindowKey));
};

const useTimeWindowFromParams = (
  initial: TimeWindow,
  timeWindowKey: string = TIME_WINDOW_PARAM_KEY,
  timeFrameKey: string = TIMEFRAME_PARAM_KEY
): [TimeWindow, SetTimeWindow] => {
  const timeWindow = useTimeWindowValue(initial, timeWindowKey, timeFrameKey);
  const setTimeWindow = useSetTimeWindow(
    timeWindow,
    timeWindowKey,
    timeFrameKey
  );

  useEffect(() => {
    !isTimeWindowExistOnQueryString(timeWindowKey) &&
      setTimeWindow(timeWindow, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTimeWindow]);

  return [timeWindow, setTimeWindow];
};

export default useTimeWindowFromParams;
