import { MouseEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { linkStyle } from "./typography";

const stopPropagation = (e: MouseEvent) => e.stopPropagation();
export default styled(Link).attrs({ onClick: stopPropagation })<{
  disabled?: boolean;
}>`
  ${linkStyle}
  ${({ disabled }) =>
    disabled
      ? `
      pointer-events: none;
      color: #B3B6BC;
    `
      : ""}
`;
