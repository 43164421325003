import React, { useState } from "react";
import styled from "styled-components";
import { Route, Routes, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

import { ClickableArea, RelativeContainer } from "./styles";
import overviewUrl from "./assets/overview.png";
import packageScreenUrl from "./assets/package-screen.png";
import packageDeploymentScreenUrl from "./assets/package-deployment.png";
import analysisDrawerUrl from "./assets/drawer-drift-analysis.png";
import investigateDrawerUrl from "./assets/drawer-failed-deploy.png";
import policyScreenUrl from "./assets/policy-screen.png";
import editDesiredState from "./assets/drawer-edit-desired-state.png";
import enforcePolicyUrl from "./assets/drawer-policy.png";

export const SelectableRow = styled(ClickableArea)<{
  height: string;
  top: string;
}>`
  width: 96.35%;
  height: ${({ height }) => height};
  left: 1.8%;
  top: ${({ top }) => top};
`;

export const DriftAnalysisDemoView: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<DriftAnalysisOverview />} />
      <Route path="/package" element={<PackageScreen />} />
      <Route path="/package/deployment" element={<PackageDeploymentScreen />} />
      <Route path="/policy" element={<PolicyScreen />} />
    </Routes>
  );
};

export const DriftAnalysisOverview: React.FC = () => {
  const navigate = useNavigate();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  return (
    <RelativeContainer>
      <img src={overviewUrl}></img>
      <SelectableRow
        height="5.1%"
        top="35.9%"
        onClick={() => {
          navigate(`./package`);
        }}
      />
      <SelectableRow
        height="5.1%"
        top="77.5%"
        onClick={() => {
          navigate(`./policy`);
        }}
      />
      <EditDesiredStateDrawer
        open={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
      />
      <EditDisiredStateButton
        onClick={() => {
          setIsEditDrawerOpen(true);
        }}
      />
    </RelativeContainer>
  );
};

const BackButton = styled(ClickableArea)<{
  top: string;
}>`
  width: 2.5%;
  height: 3.5%;
  left: 1.8%;
  top: ${({ top }) => top};
`;

const PackageScreen: React.FC = () => {
  const navigate = useNavigate();
  return (
    <RelativeContainer>
      <img src={packageScreenUrl}></img>
      <SelectableRow
        height="6.5%"
        top="52%"
        onClick={() => {
          navigate(`./deployment`);
        }}
      />
      <BackButton noHover onClick={() => navigate("../")} top="8.5%" />
    </RelativeContainer>
  );
};

const AnalyzeButton = styled(ClickableArea)`
  width: 7.4%;
  height: 3.6%;
  left: 74.6%;
  top: 33%;
`;

const PackageDeploymentScreen: React.FC = () => {
  const navigate = useNavigate();
  const [isAnalysisDrawerOpen, setIsAnalysisDrawerOpen] = useState(false);
  const [isInvestigateDrawerOpen, setIsInvestigateDrawerOpen] = useState(false);
  return (
    <RelativeContainer>
      <img src={packageDeploymentScreenUrl}></img>
      <AnalyzeButton noHover onClick={() => setIsAnalysisDrawerOpen(true)} />
      <AnalysisDrawer
        open={isAnalysisDrawerOpen}
        onClose={() => setIsAnalysisDrawerOpen(false)}
        onInvestigate={() => setIsInvestigateDrawerOpen(true)}
      />
      <InvestigateDrawer
        open={isInvestigateDrawerOpen}
        onClose={() => setIsInvestigateDrawerOpen(false)}
      />
      <BackButton noHover onClick={() => navigate("../package")} top="7.5%" />
    </RelativeContainer>
  );
};

type AnalysisDrawerProps = {
  open: boolean;
  onClose: () => void;
  onInvestigate: () => void;
};

const CloseButtonArea = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 18px;
  left: 18.5px;
`;

const InvestigateButton = styled(ClickableArea)`
  width: 85px;
  height: 30px;
  top: 159px;
  left: 88%;
`;

export const AnalysisDrawer: React.FC<AnalysisDrawerProps> = ({
  open,
  onClose,
  onInvestigate,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img src={analysisDrawerUrl} width={907} />
      <InvestigateButton noHover onClick={onInvestigate} />
      <CloseButtonArea onClick={onClose} noHover />
    </Drawer>
  );
};

type InvestigateDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export const InvestigateDrawer: React.FC<InvestigateDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      PaperProps={{ sx: { overflowY: "scroll" } }}
    >
      <img src={investigateDrawerUrl} width={907} />
      <CloseButtonArea onClick={onClose} noHover />
    </Drawer>
  );
};

const PolicyScreen: React.FC = () => {
  const navigate = useNavigate();
  const [isPolicyDrawerOpen, setIsPolicyDrawerOpen] = useState(false);
  return (
    <RelativeContainer>
      <img src={policyScreenUrl}></img>
      <SelectableRow
        height="7.2%"
        top="67.4%"
        onClick={() => {
          setIsPolicyDrawerOpen(true);
        }}
      />
      {isPolicyDrawerOpen && (
        <EnforcePolicyDrawer
          open={isPolicyDrawerOpen}
          onClose={() => setIsPolicyDrawerOpen(false)}
        />
      )}
      <BackButton noHover onClick={() => navigate("../")} top="10.8%" />
    </RelativeContainer>
  );
};

export const EnforcePolicyDrawer: React.FC<InvestigateDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img src={enforcePolicyUrl} width={907} />
      <CloseButtonArea onClick={onClose} noHover />
    </Drawer>
  );
};

const SaveButtonArea = styled(ClickableArea)`
  position: absolute;
  width: 97px;
  height: 30px;
  top: 1001px;
  right: 724px;
`;

const CancelButtonArea = styled(ClickableArea)`
  position: absolute;
  width: 60px;
  height: 30px;
  top: 1001px;
  right: 830px;
`;

const EditDisiredStateButton = styled(ClickableArea)`
  width: 9.5%;
  height: 3.6%;
  top: 9.8%;
  left: 88.6%;
`;

export const EditDesiredStateDrawer: React.FC<InvestigateDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img src={editDesiredState} width={907} />
      <CloseButtonArea onClick={onClose} noHover />
      <SaveButtonArea onClick={onClose} noHover />
      <CancelButtonArea onClick={onClose} noHover />
    </Drawer>
  );
};
