import React, { createContext, useContext } from "react";

import {
  useFetchEventsLimitsQuery,
  FetchEventsLimitsOutput,
} from "../../generated/graphql";
import { useIsAnonymousUser } from "../../shared/hooks/useIsAnonymousUser";

const useFetchEventsLimits = (
  pause?: boolean
): FetchEventsLimitsOutput | null => {
  const isAnonymous = useIsAnonymousUser();
  // don't fetch data for an anonymous user

  const [{ data: eventsLimit }] = useFetchEventsLimitsQuery({
    pause: isAnonymous ?? pause,
  });
  if (!eventsLimit?.fetchEventsLimits) return null;
  return eventsLimit.fetchEventsLimits;
};

const EventsLimitContext = createContext<FetchEventsLimitsOutput | null>(null);

export const EventsLimitProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const eventsLimit = useFetchEventsLimits();
  return (
    <EventsLimitContext.Provider value={eventsLimit}>
      {children}
    </EventsLimitContext.Provider>
  );
};

const useEventsLimit = (): FetchEventsLimitsOutput | null =>
  useContext(EventsLimitContext);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export default useEventsLimit;
