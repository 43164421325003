import React, { useState } from "react";
import styled, { css } from "styled-components";

import plusIcon from "../assets/grayPlus.svg";
import minusIcon from "../assets/grayMinus.svg";
import { gray5, grayAppBackground } from "../../../Colors";
const iconStyle = css`
  cursor: pointer;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: contain;
  height: 0.75rem;
  width: 0.75rem;
`;
const ExpandIcon = styled.div`
  background-image: url(${plusIcon});
  ${iconStyle}
`;
const CloseIcon = styled.div`
  background-image: url(${minusIcon});
  ${iconStyle}
`;
const Row = styled.tr<{
  isExpanded: boolean;
  isExpandable: boolean;
  isNested: boolean;
}>`
  line-height: 1rem;
  cursor: ${({ isExpandable }) => (isExpandable ? "pointer" : "auto")};
  background-color: ${({ isExpanded, isNested }) =>
    isExpanded && !isNested ? `${grayAppBackground}` : "inherit"};
`;
const ExpandedRow = styled.tr<{
  isNested: boolean;
}>`
  background-color: ${({ isNested }) =>
    isNested ? "inherit" : `${grayAppBackground}`};
`;
const ExpandedTd = styled.td<{ isExpanded: boolean }>`
  border-bottom: ${({ isExpanded }) =>
    isExpanded ? "none" : `1px solid ${gray5}`} !important;
`;
const IconTd = styled(ExpandedTd)`
  width: 1rem;
`;
const ExpandedComponentTd = styled.td`
  padding: 0.5rem 0.5rem 0.75rem !important;
`;
const ExpandedTableRow: React.FC<{
  cells: (string | JSX.Element)[];
  expandComponent?: JSX.Element;
  nested?: boolean;
}> = ({ cells, expandComponent, nested = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleRowClick = () => {
    if (expandComponent !== undefined) {
      setIsExpanded((prev) => !prev);
    }
  };
  return (
    <>
      <Row
        isExpanded={isExpanded}
        isExpandable={expandComponent !== undefined}
        onClick={handleRowClick}
        isNested={nested}
      >
        <IconTd isExpanded={isExpanded}>
          {expandComponent && (isExpanded ? <CloseIcon /> : <ExpandIcon />)}
        </IconTd>
        {cells.map((cell, index) => (
          <ExpandedTd key={index} isExpanded={isExpanded}>
            {cell}
          </ExpandedTd>
        ))}
      </Row>
      {isExpanded && (
        <ExpandedRow isNested={nested}>
          <ExpandedComponentTd colSpan={cells.length + 1}>
            {expandComponent}
          </ExpandedComponentTd>
        </ExpandedRow>
      )}
    </>
  );
};

export default ExpandedTableRow;
