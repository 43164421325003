import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { VerticalTabsMenu } from "@komodorio/design-system/komodor-ui";

import {
  USER_API_KEYS_ROUTE,
  USER_EMAIL_SETTINGS_ROUTE,
  USER_PROFILE_ROUTE,
} from "@/components/routes/routes";
import SettingsViewLayout from "@/components/Settings/SettingsViewLayout";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { WORKSPACES_TOP_BAR_HEIGHT } from "@/components/TopBar/TopBar";

export const UserSettingsWrapper: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const location = useLocation();
  const { appNavigationChanges, showUserEmailSettings } = useOverridableFlags();

  const items = useMemo(
    () =>
      [
        {
          title: "Profile",
          route: USER_PROFILE_ROUTE,
        },
        {
          title: "Email Settings",
          route: USER_EMAIL_SETTINGS_ROUTE,
          hide: !showUserEmailSettings,
        },
        {
          title: "API Keys",
          route: USER_API_KEYS_ROUTE,
        },
      ].filter((item) => !item.hide),
    [showUserEmailSettings]
  );

  if (!appNavigationChanges) {
    return <SettingsViewLayout>{children}</SettingsViewLayout>;
  }

  return (
    <VerticalTabsMenu
      title={title}
      items={items}
      currentTab={location.pathname}
      linkComponent={Link}
      height={`calc(100% - ${WORKSPACES_TOP_BAR_HEIGHT} - 1px)`}
    >
      {children}
    </VerticalTabsMenu>
  );
};
