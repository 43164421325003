import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { chunk, isNumber } from "lodash";

import useKomodorServices from "../../../shared/hooks/useKomodorServices";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import {
  KomodorServiceAttributes,
  useAllServiceAttributesPolling,
} from "./service-attributes-fetch-hook";

const defaultServicesChunkSize = 10_000;
type ServiceAttributesResponses = Record<string, KomodorServiceAttributes>;

const useGetChunkSize = (): number => {
  const { webServiceAttributesChunkSize } = useOverridableFlags();
  return useMemo(() => {
    if (isNumber(webServiceAttributesChunkSize)) {
      return webServiceAttributesChunkSize;
    }
    return defaultServicesChunkSize;
  }, [webServiceAttributesChunkSize]);
};

const useServicesIdsChunks = (): string[][] => {
  const chunkSize = useGetChunkSize();

  const services = useKomodorServices().all;
  return useMemo(() => {
    const servicesIds = services?.map((s) => s.id) ?? [];
    return chunk(servicesIds, chunkSize);
  }, [chunkSize, services]);
};

const ServiceAttributesPerformQuery: React.FC<{
  services: string[];
  index: number;
  setServicesAttributesMap: Dispatch<
    SetStateAction<ServiceAttributesResponses>
  >;
}> = ({ services, index, setServicesAttributesMap }) => {
  const allServicesAttributes = useAllServiceAttributesPolling(services);

  useEffect(() => {
    if (allServicesAttributes) {
      setServicesAttributesMap((prevState) => ({
        ...prevState,
        [index]: allServicesAttributes,
      }));
    }
  }, [allServicesAttributes, index, setServicesAttributesMap]);
  return null;
};

const useAggregateServiceAttributesResponses = (
  servicesAttributesResponses: Record<string, KomodorServiceAttributes>,
  responsesLength: number
): KomodorServiceAttributes | null => {
  return useMemo(() => {
    if (Object.keys(servicesAttributesResponses).length === responsesLength) {
      return Object.values(servicesAttributesResponses).flat();
    }
    return null;
  }, [servicesAttributesResponses, responsesLength]);
};

const ServiceAttributesPerformQueries: React.FC<{
  setKomodorServiceAttributes: React.Dispatch<
    React.SetStateAction<KomodorServiceAttributes | undefined>
  >;
}> = ({ setKomodorServiceAttributes }) => {
  const [servicesAttributesResponses, setServicesAttributesResponses] =
    useState<ServiceAttributesResponses>({});

  const servicesChunks = useServicesIdsChunks();

  const allServicesAttributes = useAggregateServiceAttributesResponses(
    servicesAttributesResponses,
    servicesChunks.length
  );

  useEffect(() => {
    if (allServicesAttributes) {
      setKomodorServiceAttributes(allServicesAttributes);
    }
  }, [allServicesAttributes, setKomodorServiceAttributes]);

  return (
    <>
      {servicesChunks.map((services, index) => (
        <ServiceAttributesPerformQuery
          services={services}
          index={index}
          setServicesAttributesMap={setServicesAttributesResponses}
          key={index}
        />
      ))}
    </>
  );
};

export default ServiceAttributesPerformQueries;
