import React, { useEffect } from "react";
import {
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { ArrowLeft24 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import { useIsTrialEnded } from "../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { useFreemiumModalsUpdate } from "../../../../shared/hooks/useFreemiumModals";
import { useUserLoginInfo } from "../../../../shared/hooks/useUserMetadata/useUserLoginInfo";
import { openPlansPage } from "../../../../shared/utils/freemium";

const StyledModalContent = styled(ModalContent)`
  height: 43rem;
  width: 55rem;
  padding-block: 0;
  padding-inline: 1rem;
`;
const StyledIconButton = styled(IconButton)`
  position: absolute;
  margin-inline: 1rem;
  left: 0;
`;
const ModalTitle = styled(Typography)`
  margin-left: 1.2rem;
`;

const UpgradePlanMeetingModal: React.FC = () => {
  const updateOpenFreemiumModal = useFreemiumModalsUpdate();

  const user = useUserLoginInfo();
  const email = user?.email;

  const chiliPiperDomain = "komodor";
  const chiliPiperRouter = "product_router";
  const chiliPiperSrc = `https://${chiliPiperDomain}.chilipiper.com/router/${chiliPiperRouter}?id=${email}`;

  useEffect(() => {
    dispatchEvent(AnalyticEvents.UserManagement.TrialEndedModalScheduleMeeting);
  }, []);

  const closeModal = () => {
    updateOpenFreemiumModal && updateOpenFreemiumModal(null);
  };

  const hideExitButton = useIsTrialEnded();

  return (
    <Modal isOpen={true} hideClose={hideExitButton} onRequestClose={closeModal}>
      <ModalHeader>
        <StyledIconButton onClick={openPlansPage} noBorder icon={ArrowLeft24} />
        <ModalTitle variant="headline" size="small">
          Upgrade your plan
        </ModalTitle>
      </ModalHeader>

      <StyledModalContent>
        <iframe
          className="meetings-iframe-container"
          src={chiliPiperSrc}
          title="chili-piper"
          width="100%"
          height="100%"
          frameBorder="0"
        />
      </StyledModalContent>
    </Modal>
  );
};

export default UpgradePlanMeetingModal;
