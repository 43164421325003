import React from "react";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Status } from "@komodorio/design-system/komodor-ui";
import { Pod as PodIcon } from "@komodorio/design-system/icons";
import { compact } from "lodash";

import { Header } from "../common/Header/Header";
import { Dictionary } from "../../../shared/types/Dictionary";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";
import { DeletedPodEventsTab } from "../tabs/EventsTab/DeletedPodEventsTab";
import PodDescribeTab from "../tabs/DescribeTab/pod/PodDescribeTab";
import { MetricsTab } from "../tabs/MetricsTab/MetricsTab";
import { SnapshotAlert } from "../common/SnapshotAlert/SnapshotAlert";
import { PodEventsTab } from "../tabs/EventsTab/PodEventsTab/PodEventsTab";
import { StatusTag } from "../tabs/DescribeTab/common/StatusTag";
import { podStatusMapper } from "../tabs/DescribeTab/common/PodStatusMapper";

import Pod from "./pod";

import Resource, { ResourceTabName } from ".";

export const DELETED_POD_KIND = "DeletedPod";

export interface DeletedPodType {
  age: string;
  containerNames?: string;
  controlledBy?: string;
  controlledByRoot?: string;
  deletedPodStatus?: Dictionary<unknown>;
  id: string;
  metadata: Dictionary<unknown>;
  name: string;
  namespace?: string;
  node?: string;
  status?: string;
  restarts?: string;
  ready?: string;
  spec: Dictionary<unknown>;
  containerStatuses?: Array<unknown>;
}

export default class DeletedPod implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly icon;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly resourceVersion;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Metrics },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [];
  readonly containerStatuses;
  readonly controlledBy;
  readonly fullObj;
  readonly calculatedStatus;

  readonly featureFlags: Record<string, unknown>;
  readonly defaultTab;

  constructor(
    cluster: string,
    kind: string,
    deletedPod: DeletedPodType,
    agentId: string,
    featureFlags: Record<string, unknown>,
    calculatedStatus: string
  ) {
    this.featureFlags = featureFlags;
    this.agentId = agentId;
    this.id = (deletedPod.metadata.uid as string) ?? "";
    this.cluster = cluster;
    this.namespace = deletedPod.namespace ?? "";
    this.name = deletedPod?.name ?? "";
    this.kind = kind ?? "";
    this.icon = PodIcon;
    this.labels =
      (deletedPod?.metadata?.labels as { [name: string]: string }) ?? {};
    this.annotations =
      (deletedPod.metadata?.annotations as { [name: string]: string }) ?? {};
    this.containerStatuses = deletedPod?.containerStatuses;
    this.controlledBy = deletedPod?.controlledBy;
    this.fullObj = {
      metadata: deletedPod.metadata,
      spec: deletedPod.spec,
      status: deletedPod.deletedPodStatus,
    };
    this.calculatedStatus = calculatedStatus;
    this.resourceVersion = deletedPod.metadata?.resourceVersion ?? "";
    this.defaultTab = 1;

    this.drawerTabs = compact([
      { label: ResourceTabName.Details },
      { label: ResourceTabName.Events },
      { label: ResourceTabName.Metrics },
      { label: ResourceTabName.Yaml },
    ]);
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    let i = 0;
    const getNextIndex = () => i++;

    return (
      <>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <PodDescribeTab
            resource={this as unknown as Pod}
            calculatedStatus={this.calculatedStatus}
            isDeletedPod={true}
          />
        </TabPanel>
        {this.featureFlags["groupPodsByPhases"] ? (
          <TabPanel value={selectedTab} index={getNextIndex()}>
            <PodEventsTab resource={this as unknown as Pod} />
          </TabPanel>
        ) : (
          <TabPanel eager value={selectedTab} index={getNextIndex()}>
            <DeletedPodEventsTab
              namespace={this.namespace}
              clusterName={this.cluster}
              podName={this.name}
            />
          </TabPanel>
        )}
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <MetricsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={getNextIndex()}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        status={<Status label={"DELETED"} color="neutral" />}
        resource={this}
        labels={[
          { name: "type", value: "Pod" },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
          {
            name: "controlled by",
            value: this.controlledBy,
          },
        ]}
        leftActions={leftActions}
      />
    );
  }

  renderDrawerMessage(): JSX.Element {
    return (
      <SnapshotAlert
        deletionTimestamp={this.fullObj.metadata?.deletionTimestamp}
      />
    );
  }

  renderStatus(): JSX.Element {
    return (
      <StatusTag
        value={this.calculatedStatus}
        mapOverride={podStatusMapper()}
      />
    );
  }
}
