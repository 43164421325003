import { ReliabilityStore } from "../ReliabilityTypes";

import { convertLocalDateToUtc } from "@/shared/utils/dateUtils";

export const initialState: ReliabilityStore = {
  initialTime: convertLocalDateToUtc(new Date()),
  hasSubscribedToAppViewChanges: false,
  violationsState: {
    groups: { Impact: {}, Cluster: {}, None: {}, CheckType: {} },
    ungroupedTotalViolationsCount: undefined,
  },
  addEditIgnoreRuleDialogState: {
    isOpen: false,
    checkType: undefined,
    existingIgnoreRule: undefined,
  },
  reliabilityNotifications: [],
  setTopSectionIsShown: () => undefined,
  setGroupExpandedState: () => undefined,
  setGroupState: () => undefined,
  setUngroupedTotalViolationsCount: () => undefined,
  setAddEditIgnoreRuleDialogState: () => undefined,
  setReliabilityNotification: () => undefined,
  removeReliabilityNotification: () => undefined,
  setHasSubscribedToAppViewChanges: () => undefined,
};
