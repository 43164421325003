import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { ViolationsPage } from "./components/pages/violations/ViolationsPage";
import { PoliciesPage } from "./components/pages/policies/PoliciesPage";
import { IgnoreChecksPage } from "./components/pages/ignoreChecks/IgnoreChecksPage";
import { TopSection } from "./components/TopSection/TopSection";
import { ReliabilityRoutes } from "./constants/reliabilityConstants";

import { ReliabilityNotificationsHandler } from "@/components/reliability/components/shared/ReliabilityNotificationsHandler/ReliabilityNotificationsHandler";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const MainContainer = styled.div`
  width: 100%;
  background-color: ${muiColors.gray[50]};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MaxWidthContainer = styled.div`
  min-width: 1000px;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  & > div:nth-child(2) {
    flex-grow: 1;
  }
`;

const PageContainer = styled.div`
  margin-top: 24px;
`;

export const ReliabilityView: React.FC = () => {
  const { reliabilityBestPracticesTab } = useOverridableFlags();
  return (
    <MainContainer>
      <TopSection />
      <MaxWidthContainer>
        <PageContainer>
          <Routes>
            <Route
              path={ReliabilityRoutes.violations}
              element={<ViolationsPage />}
            />
            {!!reliabilityBestPracticesTab && (
              <Route
                path={ReliabilityRoutes.bestPractices}
                element={<ViolationsPage />}
              />
            )}
            <Route
              path={ReliabilityRoutes.checks}
              element={<IgnoreChecksPage />}
            />
            <Route
              path={ReliabilityRoutes.policies}
              element={<PoliciesPage />}
            />
            <Route
              path="*"
              element={<Navigate to={ReliabilityRoutes.violations} replace />}
            />
          </Routes>
        </PageContainer>
      </MaxWidthContainer>
      <ReliabilityNotificationsHandler />
    </MainContainer>
  );
};
