import React, { useCallback, useMemo, useState } from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { getColorByStatus } from "../../../ResourceView/headers/common";
import { getResourceStatusByChartStatus } from "../helmChart";
import { HelmChartSummaryWithAge } from "../hooks/types";

import { Description } from "./Description";
import { HistoryItem } from "./HistoryItem";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-x: auto;
`;

const RevisionHistory: React.FC<{ revisions: HelmChartSummaryWithAge[] }> = ({
  revisions,
}) => {
  const [selectedRevision, setSelectedRevision] = useState(
    revisions[revisions.length - 1]
  );

  const getColor = useCallback((status: string) => {
    return getColorByStatus(getResourceStatusByChartStatus(status)).tile;
  }, []);
  const revisionsContent = useMemo(() => {
    const revisionsDesc = [...revisions].reverse();
    return revisionsDesc.map((revision, index) => (
      <HistoryItem
        key={revision.secretId}
        revision={revision}
        prevChartRevision={revisionsDesc?.[index + 1]}
        color={getColor(revision.status)}
        setSelectedRevision={setSelectedRevision}
      />
    ));
  }, [getColor, revisions]);

  return (
    <Container>
      <div>
        <Typography variant="text" size="medium">
          Revision history
        </Typography>

        <TilesContainer>{revisionsContent}</TilesContainer>
      </div>
      {selectedRevision && (
        <Description
          description={selectedRevision.description}
          status={getResourceStatusByChartStatus(selectedRevision.status)}
        />
      )}
    </Container>
  );
};

export default RevisionHistory;
