import React from "react";

import { useStateInLocalStorage } from "../../../shared/hooks/state";
import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { PermanentHideAlert } from "./PermanentHideAlert";

const INITIAL_HIDE_VAL = "false";

export const usePermanentHideAlert = (item: LocalStorageItem) => {
  const [hideAlertVal, setHideAlertVal] = useStateInLocalStorage(
    item,
    INITIAL_HIDE_VAL
  );

  const hideAlertComponent = (
    <PermanentHideAlert
      setHide={() => setHideAlertVal("true")}
      ariaLabel={
        AriaLabels.Alerts.UpgradeAgent.PermanentHideButton.AbsoulteHide
      }
    />
  );

  return {
    hideAlertComponent,
    hideAlertVal,
  };
};
