import React from "react";
import Alert from "@mui/material/Alert";

import ResponsiveLayout from "../../common/ResponsiveLayout";
import { useGetClusterByName } from "../../../shared/hooks/workspaces-api/clusters/useGetClusterByName";
import { PageLoader } from "../../../shared/components/PageLoader";
import PathNotFound from "../../404/PathNotFound";

import { ClusterViewHeader } from "./components/ClusterViewHeader";
import { ReliabilitySection } from "./components/ReliabilitySection/ReliabilitySection";
import { CostSection } from "./components/CostSection/CostSection";
import { UnhealthyServicesSection } from "./components/UnhealthyServicesSection/UnhealthyServicesSection";
import { InventorySection } from "./components/InventorySection/InventorySection";
import { INACTIVE_CLUSTER_MESSAGE } from "./constants";
import {
  useStoreIsFetchingForReport,
  useReportClusterOverviewLoadingTime,
} from "./useReportClusterOverviewLoadingTime";
import { OverviewContainer } from "./styles";

import { useCanViewCluster } from "@/components/ClustersView/ClusterOverview/permissionsHooks";

export const ClusterOverview: React.FC<{ clusterName: string }> = ({
  clusterName,
}) => {
  const { data: canViewCluster, isLoading: isLoadingCanViewClusters } =
    useCanViewCluster(clusterName);

  const { data: cluster, isLoading } = useGetClusterByName(
    { name: clusterName },
    { enabled: !!clusterName, staleTime: 0 }
  );
  useStoreIsFetchingForReport(
    "isFetchingCluster",
    isLoadingCanViewClusters || isLoading
  );
  useReportClusterOverviewLoadingTime();

  if (isLoadingCanViewClusters || !cluster) {
    return <PageLoader />;
  }

  if (!canViewCluster) {
    return <PathNotFound />;
  }

  return (
    <>
      <ClusterViewHeader cluster={cluster} />
      <ResponsiveLayout>
        <OverviewContainer>
          {cluster.inactive && (
            <Alert variant="outlined" severity="info">
              {INACTIVE_CLUSTER_MESSAGE}
            </Alert>
          )}
          <UnhealthyServicesSection clusters={[cluster.clusterName]} />
          <ReliabilitySection clusters={[cluster.clusterName]} />
          <CostSection clusters={[cluster.clusterName]} />
          <InventorySection
            clusterName={cluster.clusterName}
            isClusterInactive={cluster.inactive}
            pods={cluster.pods}
            nodes={cluster.nodes}
            services={cluster.services}
            jobs={cluster.jobs}
            isPodsEnabled={cluster.additionalInfo.isPodsEnabled}
          />
        </OverviewContainer>
      </ResponsiveLayout>
    </>
  );
};
