import React from "react";
import { Divider } from "@komodorio/design-system/deprecated";

import { FlexRows } from "../../../../../common/FlexContainers";
import { Label, Details, ActionsContainer } from "../../../styles";
import { DrainButton } from "../../../../../Actions/buttons/DrainButton";
import Resource from "../../../../../ResourceView/resources";
import { getButton } from "../../../../../Actions/common/getActionButtonStyle";
import { AvailableActions } from "../../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { useActiveAgent } from "../../../../../../shared/hooks/useAgents";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";

const NodeSummary: React.FC<{
  clusterName: string;
  nodeName: string;
}> = ({ clusterName, nodeName }) => {
  const agentId = useActiveAgent(clusterName);
  const { hideSuggestedActionsNode } = useOverridableFlags();

  return hideSuggestedActionsNode ? (
    <></>
  ) : (
    <>
      <Details>
        <>
          <br />
          <FlexRows>
            <Label>Suggested actions:</Label>
            <ActionsContainer>
              <DrainButton
                resource={
                  {
                    agentId: agentId ?? "",
                    cluster: clusterName,
                    name: nodeName,
                  } as Resource
                }
                Button={getButton(AvailableActions.Drain)}
              />
            </ActionsContainer>
          </FlexRows>
        </>
      </Details>
      <Divider />
    </>
  );
};

export default NodeSummary;
