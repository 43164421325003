import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

export const toOptions = (values: string[]): MuiSelectionOption<string>[] =>
  values.map((value) => ({
    label: value,
    value: value,
  }));

export const fromOptions = (options: MuiSelectionOption<string>[]): string[] =>
  options.map((option) => option.value);
