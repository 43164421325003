import { useCallback, useMemo } from "react";
import { get } from "lodash";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { useGetPolicyData } from "@/shared/hooks/reliability-api/policies/usePolicies";
import { configurationFieldsDefaultValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { DefaultPolicyID } from "@/generated/reliabilityApi";

export const useOpenPolicyDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    (policyId?: string) => {
      pushDrawer({
        drawerType: DrawerType.ReliabilityPolicyDrawer,
        policyId,
      });
    },
    [pushDrawer]
  );
};

export const useFetchDefaultPolicyData = () => {
  return useGetPolicyData(
    { id: DefaultPolicyID.KomodorDefault },
    { staleTime: Infinity }
  );
};

export const useDefaultPolicyConfigurations = () => {
  const { data } = useFetchDefaultPolicyData();

  return useMemo(() => {
    return data?.data?.policy ?? configurationFieldsDefaultValues;
  }, [data?.data?.policy]);
};

export const useGetDefaultConfigurationsPerFieldName = () => {
  const defaultPolicyConfigurations = useDefaultPolicyConfigurations();

  return useCallback(
    (fieldName: string) => {
      return (
        get(defaultPolicyConfigurations, fieldName) ??
        get(defaultPolicyConfigurations, fieldName)
      );
    },
    [defaultPolicyConfigurations]
  );
};
