import { useMemo } from "react";

import { dispatchEvent } from "../../../hooks/analytics";
import { FilterBarCategory } from "../FilterBar";
import { updateSelected } from "../utils/utils";
import { ServiceInfo } from "../../../types/ServiceInfo";
import Selected from "../Interfaces/Selected";
import { AnalyticEvents } from "../../../config/analyticsEvents";
import { intersection } from "../../../utils/collections/collections";

import { FiltersList } from "./useFiltersList";

interface Option {
  value: string;
  count: number;
  checked: boolean;
  onChange: () => void;
}

const useGenerateCategories = (
  checkedServices: ServiceInfo[] | undefined,
  filtersList: FiltersList | null,
  selected: Selected,
  setSelected: (selected: Selected) => void,
  viewType?: string
): FilterBarCategory[] | undefined => {
  const generateCategories = useMemo(() => {
    if (!filtersList) return;
    const categoriesArray = [];
    const shownServiceId = new Set(
      checkedServices?.map((shownService) => shownService.id)
    );
    for (const [key, value] of Object.entries(filtersList)) {
      const values = [];

      for (const [optionKey, optionValue] of Object.entries(value)) {
        const selectedFilters = Object.keys({ ...selected });
        const isOneFilterSelected =
          selectedFilters.length === 1 && selectedFilters[0] === key;
        if (
          optionValue.some((option) => shownServiceId.has(option)) ||
          isOneFilterSelected
        ) {
          const isCurrentChoiceChecked = selected?.[key]?.[optionKey];
          const option: Option = {
            value: optionKey,
            count:
              !isOneFilterSelected || isCurrentChoiceChecked
                ? intersection([new Set(optionValue), shownServiceId]).size
                : optionValue.length,
            checked: isCurrentChoiceChecked ?? false,
            onChange: () => {
              setSelected(updateSelected(selected, key, optionKey));
              const eventName = isCurrentChoiceChecked
                ? AnalyticEvents.ExploreServicesView.Filter_Uncheck
                : AnalyticEvents.ExploreServicesView.Filter_Check;
              dispatchEvent(eventName, {
                key,
                optionKey,
                viewType,
              });
            },
          };
          values.push(option);
        }
      }
      if (values.length > 0) {
        const filterEmptyCategories = values.filter(
          (category) => category.count > 0
        );
        categoriesArray.push({ name: key, values: [...filterEmptyCategories] });
      }
    }

    return categoriesArray;
  }, [filtersList, selected, setSelected, checkedServices, viewType]);
  return generateCategories;
};

export default useGenerateCategories;
