import { useCallback, useMemo } from "react";

import { useGetViolations } from "../../../shared/hooks/reliability-api/violations/useGetViolations";
import { useGetGroups } from "../../../shared/hooks/reliability-api/groups/useGetGroups";
import { useViolationsRequestParams } from "../components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/useViolationsRequestParams";
import { useReliabilityStore } from "../store/reliabilityStore";
import { selectSetAddEditIgnoreRuleDialogState } from "../store/reliabilityStoreSelectors";
import {
  AddEditIgnoreRuleDialogAction,
  GroupByOptions,
} from "../ReliabilityTypes";
import {
  GroupViolationsByOptions,
  ViolationCountBy,
  ViolationStatus,
} from "../../../generated/reliabilityApi";

import { useScopeFilters } from "./useScopeFilters";
import { useTimeWindowToEpoch } from "./useTimeWindowToEpoch";
import { useIsFiltersStateSet } from "./useIsFiltersStateSet";
import { useGetGroupBy } from "./useGetGroupBy";
import { useGetImpactGroupType, useIsBestPracticesTab } from "./useSelectedTab";

export const useViolations = () => {
  const violationsRequestParams = useViolationsRequestParams();
  const enable = useIsFiltersStateSet();
  return useGetViolations(violationsRequestParams, enable);
};

const groupByMapper: Record<GroupByOptions, GroupViolationsByOptions> = {
  Impact: "impact-group",
  Cluster: "cluster",
  CheckType: "checkType",
};

export const useGroups = () => {
  const groupBy = useGetGroupBy();
  const {
    namespace,
    clusterName,
    resourceType,
    shortResourceName,
    createdAt,
    status,
  } = useScopeFilters();
  const timeWindowToEpoch = useTimeWindowToEpoch();
  const isBestPracticesTab = useIsBestPracticesTab();

  const impactGroupType = useGetImpactGroupType();

  const epochs = useMemo(
    () => timeWindowToEpoch(createdAt?.[0]),
    [createdAt, timeWindowToEpoch]
  );

  const enable = useIsFiltersStateSet();

  const breakdownFactors: ViolationCountBy[] = isBestPracticesTab
    ? ["hasDependentViolations"]
    : ["severity"];

  return useGetGroups(
    {
      groupViolationsBy: groupByMapper[groupBy as GroupByOptions],
      breakdownFactors: breakdownFactors,
      impactGroupType: [impactGroupType],
      ...(namespace?.length && { namespace }),
      ...(clusterName?.length && { clusterName }),
      ...(resourceType?.length && { resourceType }),
      ...(shortResourceName?.length && { shortResourceName }),
      ...(!!epochs && { ...epochs }),
      ...(status?.length && { status: status as ViolationStatus[] }),
    },
    { enabled: enable }
  );
};

export const useSetAddEditIgnoreRuleDialogState = () => {
  const setAddEditIgnoreRuleDialogState = useReliabilityStore(
    selectSetAddEditIgnoreRuleDialogState
  );
  return useCallback(
    (newState: AddEditIgnoreRuleDialogAction) => {
      setAddEditIgnoreRuleDialogState(newState);
    },
    [setAddEditIgnoreRuleDialogState]
  );
};
