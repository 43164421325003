import React from "react";

import CircleLoader from "./CircleLoader";

interface LoadingStateCircleProps {
  numOfActualPaginations: number;
  numOfExpectedPaginations: number;
}

const LoadingStateCircle: React.FC<LoadingStateCircleProps> = ({
  numOfActualPaginations,
  numOfExpectedPaginations,
}) => {
  let percentage = Math.ceil(
    (numOfActualPaginations / numOfExpectedPaginations) * 100
  );
  if (percentage <= 5) {
    percentage = 5;
  }

  return <CircleLoader percentage={percentage} />;
};

export default LoadingStateCircle;
