import React, { useState } from "react";

import useDefaultClusterForInspection from "../../Inspection/utils/useDefaultClusterForInspection";

type AppBarState = {
  defaultCluster: string | null;
  minimized: boolean;
  setMinimized: (minimized: boolean) => void;
  expanded: string;
  setExpanded: (expanded: string) => void;
};

const initialState = {
  defaultCluster: null,
  minimized: false,
  setMinimized: () => undefined,
  expanded: "",
  setExpanded: () => undefined,
};

const AppBarStateContext = React.createContext<AppBarState>(initialState);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useAppBarStateContext = (): AppBarState =>
  React.useContext(AppBarStateContext);

export const AppBarStateProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const defaultCluster = useDefaultClusterForInspection();
  const [minimized, setMinimized] = useState(initialState.minimized);
  const [expanded, setExpanded] = useState(initialState.expanded);

  const state = {
    defaultCluster,
    minimized,
    setMinimized,
    expanded,
    setExpanded,
  };

  return (
    <AppBarStateContext.Provider value={state}>
      {children}
    </AppBarStateContext.Provider>
  );
};
