import useDataForFlag from "../../../../../shared/hooks/useDataForFlag";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";
import { HpaEventsResponseDataInner } from "../../../../../generated/resourcesApi";
import { useSearchHpaEvents } from "../../../../../shared/hooks/resources-api/client/events/hpaEvents/useHpaEvents";

export const useHpaEvents = (
  showHpaEvent: unknown,
  timeWindow: Pick<TimeWindow, "start" | "end">
): HpaEventsResponseDataInner[] | null => {
  const { data } = useSearchHpaEvents({
    fromEpoch: timeWindow.start.getTime().toString(),
    toEpoch: timeWindow.end.getTime().toString(),
    fields: [
      "id",
      "name",
      "namespace",
      "clusterName",
      "eventTime",
      "services",
      "action",
      "oldDesiredReplicas",
      "newDesiredReplicas",
      "minReplicas",
      "maxReplicas",
    ],
    enabled: showHpaEvent === true,
  });

  return useDataForFlag(showHpaEvent, data?.data);
};
