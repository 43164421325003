import React from "react";
import styled from "styled-components";

import SettingsViewLayout from "../SettingsViewLayout";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";

import AccountPlan from "./AccountPlan/AccountPlan";
import { Features } from "./BetaFeatures/Features";
import { Profile } from "./Profile/Profile";
import { NodeCount } from "./NodesCount/NodeCount";

const WithPadding = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const General: React.FC = () => (
  <SettingsViewLayout>
    <ValidationsProvider>
      <WithPadding>
        <Profile />
        <Features />
        <AccountPlan />
        <NodeCount />
      </WithPadding>
    </ValidationsProvider>
  </SettingsViewLayout>
);

export default General;
