import React, { useMemo } from "react";
import { get } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import KomodorAlertEventGroup, {
  KomodorAlertEvent,
} from "../../../EventGroup/KomodorAlertEventGroup";
import eventsIcon from "../../assets/events.svg";
import detailsIcon from "../../assets/details.svg";
import tagsIcon from "../../assets/tag.svg";
import datadogIcon from "../../../../integrations/management/logos/datadog.svg";
import grafanaIcon from "../../../../integrations/management/logos/grafana.svg";
import newrelicIcon from "../../../../integrations/management/logos/newrelic.svg";
import opsgenieIcon from "../../../../integrations/management/logos/opsgenie.svg";
import prometheusIcon from "../../../../integrations/management/logos/prometheus.svg";
import { EventDetailsContainer } from "../common";
import EventDetailsTitle from "../components/EventDetailsTitle";
import EventDetailsSection from "../components/EventDetailsSection";
import EventsGrid from "../components/EventsGrid";
import DetailsList from "../components/DetailsList";
import LabeledTag, { TagsContainer } from "../components/LabeledTag";
import IntegrationLink from "../components/LinkSection/IntegrationLink";

interface KomodorAlertEventDetailsProps {
  eventGroup: KomodorAlertEventGroup;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const alertTypes = {
  DATADOG_MONITOR: "datadog_monitor",
  GRAFANA_ALERTS: "grafana-alerts",
  NEW_RELIC_ALERTS: "newrelic_alerts",
  OPSGENIE: "opsgenie",
  PROMETHEUS_ALERT_MANAGER: "prometheus-alert-manager",
};

const linkDetails = {
  [alertTypes.DATADOG_MONITOR]: {
    icon: datadogIcon,
    label: "View incident on Datadog",
  },
  [alertTypes.GRAFANA_ALERTS]: {
    icon: grafanaIcon,
    label: "View incident on Grafana",
  },
  [alertTypes.NEW_RELIC_ALERTS]: {
    icon: newrelicIcon,
    label: "View incident on NewRelic",
  },
  [alertTypes.OPSGENIE]: {
    icon: opsgenieIcon,
    label: "View incident on Opsgenie",
  },
  [alertTypes.PROMETHEUS_ALERT_MANAGER]: {
    icon: prometheusIcon,
    label: "View incident on Alert Manager",
  },
};

const usePreparedEvents = (events: KomodorAlertEvent[]) =>
  useMemo(() => {
    return events.map((e) => ({
      eventTime: e.eventTime,
      status: e.status || "N/A",
      details: "",
    }));
  }, [events]);

const useDetails = (details: KomodorAlertEvent) =>
  useMemo(() => {
    return [
      { label: "Priority", value: details.alertPriority || "N/A" },
      { label: "Severity", value: details.alertSeverity || "N/A" },
      {
        label: "Organization",
        value: get(details.properties, "org.name") || "N/A",
      },
    ];
  }, [details]);

const KomodorAlertEventDetails: React.FC<KomodorAlertEventDetailsProps> = ({
  eventGroup,
}) => {
  const details = eventGroup.events[eventGroup.events.length - 1];
  const preparedEvents = usePreparedEvents(eventGroup.events);
  const preparedDetails = useDetails(details);
  const preparedTags = useMemo(() => {
    for (const event of eventGroup.events) {
      if (event.alertTags) {
        return Object.entries(event.alertTags as Record<string, string>);
      }
    }
    return [];
  }, [eventGroup.events]);

  const { icon, label } = linkDetails[details.alertSource];
  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={eventGroup.summary || "Incident"} />
      <EventDetailsSection
        icon={eventsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Events"
      >
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        icon={detailsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Details"
      >
        <DetailsList details={preparedDetails} />
      </EventDetailsSection>
      {preparedTags.length > 0 && (
        <EventDetailsSection icon={tagsIcon} title="Tags">
          <TagsContainer>
            {preparedTags.map(([k, v]) => (
              <LabeledTag key={k} label={k} value={v} />
            ))}
          </TagsContainer>
        </EventDetailsSection>
      )}
      {details.url && (
        <IntegrationLink icon={icon} href={details.url} title={label} single />
      )}
    </EventDetailsContainer>
  );
};

export default KomodorAlertEventDetails;
