import {
  ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND,
  ARGO_CLUSTER_WORKFLOW_TEMPLATE_KOMODOR_KIND,
} from "../ResourceView/resources/argoClusterWorkflowTemplate";
import {
  ARGO_CRON_WORKFLOW_KIND,
  ARGO_CRON_WORKFLOW_KOMODOR_KIND,
} from "../ResourceView/resources/argoCronWorkflow";
import {
  ARGO_WORKFLOW_KIND,
  ARGO_WORKFLOW_KOMODOR_KIND,
} from "../ResourceView/resources/argoWorkflow";
import {
  ARGO_WORKFLOW_TEMPLATE_KIND,
  ARGO_WORKFLOW_TEMPLATE_KOMODOR_KIND,
} from "../ResourceView/resources/argoWorkflowTemplate";

export const argoKomodorKindToK8sKind: Record<string, string> = {
  [ARGO_WORKFLOW_KOMODOR_KIND]: ARGO_WORKFLOW_KIND,
  [ARGO_CRON_WORKFLOW_KOMODOR_KIND]: ARGO_CRON_WORKFLOW_KIND,
  [ARGO_WORKFLOW_TEMPLATE_KOMODOR_KIND]: ARGO_WORKFLOW_TEMPLATE_KIND,
  [ARGO_CLUSTER_WORKFLOW_TEMPLATE_KOMODOR_KIND]:
    ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND,
};

export const argoK8sKindToKomodorKind: Record<string, string> = {
  [ARGO_WORKFLOW_KIND]: ARGO_WORKFLOW_KOMODOR_KIND,
  [ARGO_CRON_WORKFLOW_KIND]: ARGO_CRON_WORKFLOW_KOMODOR_KIND,
  [ARGO_WORKFLOW_TEMPLATE_KIND]: ARGO_WORKFLOW_TEMPLATE_KOMODOR_KIND,
  [ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND]:
    ARGO_CLUSTER_WORKFLOW_TEMPLATE_KOMODOR_KIND,
};
