import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { formatDistanceToNow } from "date-fns";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import Skeleton from "@mui/material/Skeleton";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { muiColors } from "@komodorio/design-system";

import {
  useStateInSearchParams,
  useStringifiedStateInSearchParams,
} from "../../../../../shared/hooks/state";
import {
  ARGO_WORKFLOW_RUN_PARAM_KEY,
  TIMELINE_ZOOM_CHANGED_PARAM_KEY,
} from "../../../../../shared/config/urlSearchParamsKeys";

import type WorkflowDag from "@/components/ResourceView/resources/workflowDag";
import { WorkflowStatus } from "@/components/k8sAddons/addons/workflows/WorkflowStatus";
import WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { useGetAddonLiveStateList } from "@/shared/hooks/k8s-add-ons/useGetAddonLiveStateList";
import {
  Addon,
  Entity,
  Operator,
  WorkflowRun as WorkflowRunType,
} from "@/generated/addonsApi";
import { useFormattedDuration } from "@/components/k8sAddons/components/table/DurationCell/useFormattedDuration";

const SELECTOR_WIDTH = "400px";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: start;
  padding-block: 16px;

  .drop-down-trigger {
    height: fit-content;
  }
`;

export const WorkflowRunSwitcher: React.FC<{
  resource: WorkflowDag;
}> = ({ resource }) => {
  const [selectedRunId, setSelectedRunId] = useStateInSearchParams(
    ARGO_WORKFLOW_RUN_PARAM_KEY
  );

  const { data, isLoading } = useGetAddonLiveStateList({
    addon: Addon.Workflow,
    entity: Entity.WorkflowRun,
    getEntityListRequest: {
      filter: [
        {
          name: "komodorWorkflowId",
          operator: Operator.Eq,
          value: [resource.id],
        },
      ],
    },
  });

  const workflowRuns =
    data?.data.data.map((run) => new WorkflowRun(run as WorkflowRunType)) ?? [];
  const workflowRunsOptions = workflowRuns.map((wfRun) => ({
    label: wfRun.name,
    value: wfRun.name,
  }));

  useEffect(() => {
    if (
      workflowRunsOptions.length &&
      (!selectedRunId ||
        !workflowRunsOptions.find((o) => o.value === selectedRunId))
    ) {
      setSelectedRunId(workflowRunsOptions[0].value, true);
    }
  }, [selectedRunId, setSelectedRunId, workflowRunsOptions]);

  const selectedRunOption = useMemo(
    () =>
      workflowRunsOptions.find((o) => o.value === selectedRunId) ??
      workflowRunsOptions[0],
    [selectedRunId, workflowRunsOptions]
  );

  const [, setZoomChanged] = useStringifiedStateInSearchParams<boolean>(
    TIMELINE_ZOOM_CHANGED_PARAM_KEY
  );
  const handleSelectorChange = (
    selectedOption: MuiSelectionOption<string> | undefined
  ) => {
    setSelectedRunId(selectedOption?.value ?? null);
    setZoomChanged(false, true);
  };

  const renderOption = (option: MuiSelectionOption<string>) => {
    const wfRun = workflowRuns.find((run) => run.name === option.value);
    return wfRun ? <WorkflowRunValue value={wfRun} /> : null;
  };

  return (
    <Container>
      <Typography variant={"overline2"} color="text.secondary">
        Showing run:
      </Typography>
      {isLoading ? (
        <Skeleton
          width={SELECTOR_WIDTH}
          height="63px"
          sx={{ transform: "none" }}
        />
      ) : !workflowRunsOptions.length || !selectedRunOption ? null : (
        <SingleSelect
          options={workflowRunsOptions}
          minWidth={SELECTOR_WIDTH}
          onChange={handleSelectorChange}
          value={selectedRunOption}
          enableSearch
          texts={{ searchPlaceholder: "Search" }}
          customValueElement={renderOption}
          customOptionElement={renderOption}
          classNames={{ dropDownTriggerField: "drop-down-trigger" }}
          selectPopoverProps={{
            maxHeight: "400px",
          }}
        />
      )}
    </Container>
  );
};

const ArgoWorkflowRunMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 4px 8px;
`;
const ArgoWorkflowRunStatusRow = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 8px;
  align-items: center;
  float: left;
`;
const EllipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WorkflowRunValue: React.FC<{
  value: WorkflowRun;
}> = ({ value }) => {
  const duration = useFormattedDuration(value.durationSec);
  return (
    <ArgoWorkflowRunMenuItem>
      <ArgoWorkflowRunStatusRow>
        {value.hasIssues ? (
          <WarningAmberOutlined
            fontSize={"small"}
            sx={{ fill: muiColors.pink[700] }}
          />
        ) : null}
        <EllipsisTypography variant={"body2"}>{value.name}</EllipsisTypography>
        <WorkflowStatus status={value.status} size="small" />
      </ArgoWorkflowRunStatusRow>
      <Typography variant={"caption"} color="text.secondary">
        <ArgoWorkflowRunStatusRow>
          <span>Duration: {duration}</span>
          {value.startedAt ? (
            <>
              <span>•</span>
              <span>
                Started:{" "}
                {formatDistanceToNow(value.startedAt, {
                  addSuffix: true,
                })}
              </span>
            </>
          ) : null}
        </ArgoWorkflowRunStatusRow>
      </Typography>
    </ArgoWorkflowRunMenuItem>
  );
};
