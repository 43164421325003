import { muiColors } from "@komodorio/design-system";

import { LogLineColor } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/relatedEvidenceTypes";

export const errorCodingWords = ["error", "err"];
export const warningCodingWords = ["warning", "warn"];

export const errorCodeToColor: Record<LogLineColor, string> = {
  error: muiColors.pink[600] ?? "",
  warning: muiColors.orange[400] ?? "",
  info: muiColors.common.white ?? "",
};
