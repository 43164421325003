import { useEffect } from "react";

import {
  AccountByIdQuery,
  useAccountByIdQuery,
} from "../../../generated/graphql";
import { notifyDDError } from "../exceptionManagement";

export default (accountId: string): AccountByIdQuery["account_by_pk"] => {
  const [{ data, error }] = useAccountByIdQuery({
    variables: {
      accountId: accountId.trim(),
    },
    pause: !accountId,
  });

  useEffect(() => {
    if (error) {
      notifyDDError(error);
    }
  }, [error]);

  return data?.account_by_pk;
};
