import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Tabs, Typography } from "@komodorio/design-system/deprecated";
import Stack from "@mui/material/Stack";
import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { intersection } from "lodash";

import ResponsiveLayout from "../common/ResponsiveLayout";
import { LocalStorageItem } from "../../shared/utils/localStorageSettings";
import { useHasPermissions } from "../../shared/hooks/useUserMetadata/rbac";

import Divider from "./common/Divider";
import { Tab } from "./common/types";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const storedMonitorsLastTab = new LocalStorageItem("monitorsLastTab");

const Container = styled.div`
  color: #3d4048;
`;

const Title = styled(Typography).attrs({ variant: "headline", size: "medium" })`
  padding: 1.5rem 0 1rem 0;
`;

const tabs: Tab[] = [
  { label: "Manage Monitors", route: "/main/monitors", accessLevel: ["admin"] },
  {
    label: "Triggered Monitors",
    route: "/main/monitors/triggered",
    accessLevel: ["user", "admin"],
  },
];

const getTabByRoute = (route: string) => {
  const tab = tabs.find((t) => t.route === route);
  if (tab) {
    return tab;
  }

  return tabs.find((t) => route.includes(t.route)) || { route: false };
};

const MonitorsViewContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { canManageMonitors } = useHasPermissions();
  const { appNavigationChanges } = useOverridableFlags();

  const userPermissions = useMemo(() => {
    return canManageMonitors ? ["user", "admin"] : ["user"];
  }, [canManageMonitors]);

  const allowedTabs = useMemo(() => {
    return tabs.filter(
      (t) => intersection(t.accessLevel, userPermissions).length
    );
  }, [userPermissions]);

  const [curTab, curTubIndex] = useMemo(() => {
    let index = allowedTabs.findIndex(
      (t) => t.route === window.location.pathname
    );
    index = index >= 0 ? index : 0;
    return [allowedTabs[index], index];
  }, [allowedTabs]);

  useEffect(() => {
    storedMonitorsLastTab.set(curTab.route);
  }, [curTab]);

  useEffect(() => {
    if (location.pathname !== curTab.route) {
      navigate(curTab.route);
    }
  }, [curTab.route, navigate]);

  const currentTab = React.useMemo(() => {
    return getTabByRoute(location.pathname).route;
  }, []);

  const onChange = React.useCallback(
    (_: React.SyntheticEvent, selectedTab: string) => {
      storedMonitorsLastTab.set(selectedTab);
      navigate(selectedTab);
    },
    [navigate]
  );

  if (appNavigationChanges) {
    return (
      <SettingsViewVerticalLayout title="Monitors">
        <Stack spacing={3}>
          <Stack spacing={0}>
            <MuiTabs
              value={currentTab}
              onChange={onChange}
              data-e2e-selector="monitors-tabs"
            >
              {allowedTabs.map(({ label, route }) => (
                <MuiTab label={label} value={route} />
              ))}
            </MuiTabs>
            <Divider />
          </Stack>
          {children}
        </Stack>
      </SettingsViewVerticalLayout>
    );
  }

  return (
    <Container>
      <ResponsiveLayout>
        <Title>Monitors</Title>
        <Tabs
          tabs={allowedTabs.map(({ label }) => ({ label }))}
          value={curTubIndex}
          onChange={(selectedTab) => {
            navigate(allowedTabs[selectedTab].route);
          }}
          data-e2e-selector="monitors-tabs"
        />
      </ResponsiveLayout>
      <Divider />
      <br />
      {children}
    </Container>
  );
};

export default MonitorsViewContainer;
