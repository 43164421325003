/* eslint-disable max-lines */

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import { default as MuiTypograhpy } from "@mui/material/Typography";
import { PodsAndLogs16 } from "@komodorio/design-system/icons";
import { get } from "lodash";
import differenceInSeconds from "date-fns/differenceInSeconds";

import { dispatchEvent } from "../../../../../shared/hooks/analytics";
import { Divider, EventDetailsContainer } from "../common";
import EventDetailsTitle from "../components/EventDetailsTitle";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowIssueEventGroup from "../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import {
  LinkButton,
  NoUnderlinedLink,
} from "../../../../monitorsView/common/styles";
import { typeTriggers } from "../../../../monitorsView/workflowRunView/IssueDetailsDrawer";
import { grayAppBackground } from "../../../../../Colors";
import useWorkflowsChecks from "../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import {
  CheckDetails,
  WorkflowConfigType,
} from "../../../../monitorsView/common/types";
import { FlexRows } from "../../../FlexContainers";
import { AnalyticEvents } from "../../../../../shared/config/analyticsEvents";
import { useHasPermissions } from "../../../../../shared/hooks/useUserMetadata/rbac";
import NodeSummary from "../../../../monitorsView/workflowRunView/checks/node/detailsComponents/NodeSummary";
import { ResourceTabName } from "../../../../ResourceView/resources";
import { useKomodorServiceAsResourceWithInterval } from "../../../../ResourceView/useKomodorServiceAsResource";
import { getResourceViewPodsTabUrlKey } from "../../../../ResourceView/resourceViewNavigate";
import { MONITORS_RULE_PARAM_KEY } from "../../../../../shared/config/urlSearchParamsKeys";
import { useWorkflowRunById } from "../../../EventGroup/workflowIssues/useWorkflowRunById";
import { useDDRumStartViewAndAddTiming } from "../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import WorkflowCheck from "../../../../monitorsView/workflowRunView/checks";
import { WorkflowRunWithResults } from "../../../../monitorsView/useWorkflowsRuns";
import { useIssueLiveLoading } from "../../../../InvestigationMode/IssueSummary/ReplicasTile";
import { AvailabilityResource } from "../../../../../shared/store/investigationModeStore/investigationModeStore";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";

import EventDetailsCheckCard from "./EventDetailsCheckCard";
import { ShouldOpenPodsHealthProvider } from "./useShouldOpenPodsHealth";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  AvailabilityTriage,
  TRIAGE_RELEASE_DATE_UTC,
} from "./triage/AvailabilityTriage/AvailabilityTriage";
import { IssueSkeleton } from "./IssueDrawerSkeleton";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AvailabilityDrawerData } from "./AvailabilityDrawerData";
import { PodIssueDrawer } from "./triage/pod/PodDrawerData";
import { ResourceLink } from "./ResourceLink";

export const SimpleSection = styled.div`
  margin: 1rem;
  font-size: 14px;
`;

export const ChecksTitle = styled(Typography).attrs({
  variant: "title",
  size: "medium",
})`
  background-color: ${grayAppBackground};
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e7e9f0;
`;

export const SimpleButtonsContainer = styled(FlexRows)`
  gap: 1.5rem;
`;
interface WorkflowIssueEventDetailsProps {
  eventGroup: WorkflowIssueEventGroup;
  onClose?: () => void;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getMonitorLink = (event_id: string): string => {
  return `/main/monitors/run/${event_id}`;
};
const WorkflowIssueEventDetails: React.FC<WorkflowIssueEventDetailsProps> = ({
  eventGroup,
  onClose,
}) => {
  const navigate = useNavigate();
  const [memoizedEventGroup, setMemoizedEventGroup] = useState(eventGroup);
  useEffect(() => {
    if (JSON.stringify(memoizedEventGroup) === JSON.stringify(eventGroup))
      return;
    setMemoizedEventGroup(eventGroup);
  }, [eventGroup, memoizedEventGroup]);
  const { canManageMonitors } = useHasPermissions();

  const [resource, isFetching] = useKomodorServiceAsResourceWithInterval(
    memoizedEventGroup.serviceId
  );

  const { data: wfRun } = useWorkflowRunById(
    memoizedEventGroup.id,
    memoizedEventGroup.workflowType
  );

  const isLoading = useIssueLiveLoading(isFetching, wfRun?.closedAt);

  const checkDetails: CheckDetails = useMemo(
    () => ({
      workflowType: memoizedEventGroup.workflowType,
      results: wfRun?.results ?? [],
      eventTime: wfRun?.eventTime,
      closedAt: wfRun?.closedAt,
      services: wfRun?.services,
      clusterName: wfRun?.cluster,
      namespace: wfRun?.namespace,
    }),
    [
      memoizedEventGroup.workflowType,
      wfRun?.closedAt,
      wfRun?.cluster,
      wfRun?.eventTime,
      wfRun?.namespace,
      wfRun?.results,
      wfRun?.services,
    ]
  );
  const allChecks = useWorkflowsChecks(checkDetails);
  const isDeletedResource =
    get(resource, "isDeleted") || get(resource, "inactive") || false;
  const queryParams = queryString.parse(useLocation().search);
  useEffect(() => {
    dispatchEvent(AnalyticEvents.MonitorsView.Event_Drawer_Opened, {
      type: memoizedEventGroup.workflowType,
      referer: queryParams?.referer,
      reasons: memoizedEventGroup.reasons,
    });

    dispatchEvent(AnalyticEvents.MonitorsView.Any_Result_Opened, {
      type: memoizedEventGroup.workflowType,
      referer: queryParams?.referer,
      eventTime: memoizedEventGroup.startTime,
      status: memoizedEventGroup.status,
      secondsTillOpen: differenceInSeconds(
        new Date(),
        memoizedEventGroup.startTime
      ),
    });
  }, [
    memoizedEventGroup.startTime,
    memoizedEventGroup.reasons,
    memoizedEventGroup.workflowType,
    queryParams?.referer,
    memoizedEventGroup.status,
  ]);

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.getEventDrawerByType("availability_issue"),
    addTimingParams: {
      enable: !isLoading && wfRun !== null,
    },
  });

  if (wfRun === null) return <IssueSkeleton />;

  if (
    wfRun?.type === WorkflowConfigType.Availability &&
    wfRun.eventTime > new Date(TRIAGE_RELEASE_DATE_UTC)
  )
    return (
      <AvailabilityTriage
        allChecks={allChecks}
        wfRun={wfRun}
        eventGroup={memoizedEventGroup}
        resource={resource as AvailabilityResource}
        onClose={onClose}
        isFetchingResource={isLoading}
      />
    );

  if (
    wfRun?.type === WorkflowConfigType.Pod ||
    wfRun?.type === WorkflowConfigType.Workflow
  )
    return (
      <PodIssueDrawer allChecks={allChecks} wfRun={wfRun} onClose={onClose} />
    );

  return (
    <EventDetailsContainer data-testid={"event-details-container"}>
      <EventDetailsTitle title={memoizedEventGroup.summary} />
      <MuiTypograhpy variant={"body1"} fontSize={"14px"} margin={"1rem"}>
        {canManageMonitors ? (
          <LinkButton
            fontSize="14px"
            onClick={() =>
              navigate(
                `/main/monitors?${MONITORS_RULE_PARAM_KEY}=${memoizedEventGroup.workflowConfig?.id}`
              )
            }
          >
            {"Monitor"}
          </LinkButton>
        ) : (
          "Monitor"
        )}{" "}
        has detected that{" "}
        <ResourceLink
          namespace={memoizedEventGroup.namespace ?? ""}
          clusterName={memoizedEventGroup.clusterName ?? ""}
          resourceName={memoizedEventGroup.resourceName}
          type={memoizedEventGroup.workflowType}
        />{" "}
        {typeTriggers[memoizedEventGroup.workflowType]}
      </MuiTypograhpy>
      <br />
      <SimpleButtonsContainer>
        {memoizedEventGroup.serviceId &&
          memoizedEventGroup.workflowType ===
            WorkflowConfigType.Availability && (
            <NoUnderlinedLink
              to={`/main/deep-dive/${
                memoizedEventGroup.serviceId
              }?podId=notselected&${getResourceViewPodsTabUrlKey(
                resource?.kind || ""
              )}=${ResourceTabName.Pods}`}
            >
              <Button
                variant="secondary"
                icon={PodsAndLogs16}
                disabled={isDeletedResource}
              >
                Live Pods & Logs
              </Button>
            </NoUnderlinedLink>
          )}
      </SimpleButtonsContainer>
      {wfRun ? (
        <WorkflowIssueEventDetailsContent
          wfRun={wfRun}
          allChecks={allChecks}
          eventGroup={memoizedEventGroup}
        />
      ) : (
        <IssueSkeleton />
      )}
    </EventDetailsContainer>
  );
};

const WorkflowIssueEventDetailsContent: React.FC<{
  wfRun: WorkflowRunWithResults;
  allChecks: WorkflowCheck[];
  eventGroup: WorkflowIssueEventGroup;
}> = ({ wfRun, allChecks, eventGroup }) => {
  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.getEventDrawerByType(eventGroup.type),
    addTimingParams: {
      enable: true,
    },
  });

  return wfRun.type === WorkflowConfigType.Availability ? (
    <AvailabilityDrawerData
      allChecks={allChecks}
      wfRun={wfRun}
      serviceId={eventGroup.serviceId}
    />
  ) : (
    <>
      <Divider />
      <ChecksTitle>Checks & Findings</ChecksTitle>
      <div>
        {wfRun.type === WorkflowConfigType.NodeIssue &&
          wfRun.closedAt === undefined && (
            <NodeSummary
              clusterName={eventGroup.clusterName ?? ""}
              nodeName={eventGroup.resourceName}
            />
          )}
        {allChecks.map((c, i) => (
          <EventDetailsCheckCard check={c} key={i} />
        ))}
      </div>
    </>
  );
};

const WorkflowIssueEventDetailsProvider: React.FC<
  WorkflowIssueEventDetailsProps
> = ({ eventGroup, onClose }) => {
  return (
    <ShouldOpenPodsHealthProvider>
      <WorkflowIssueEventDetails eventGroup={eventGroup} onClose={onClose} />
    </ShouldOpenPodsHealthProvider>
  );
};

export default WorkflowIssueEventDetailsProvider;
