import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { last, sum } from "lodash";
import { muiColors } from "@komodorio/design-system";

import { LinesLoader } from "../../loaders/Line";
import {
  highlightCodedWords,
  highlightTerm,
  HighlightTypes,
} from "../../HighlightedText";
import { greenBrand } from "../../../../Colors";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LogEvent } from "../LogsViewer";
import {
  PodLogLine,
  PodLogsMode,
} from "../../../../shared/hooks/podLogs/types";

import PodMessage from "./PodMessage";
import getFormattedErrorMessage from "./getFormattedErrorMessage";
import { useMapEventToLogs } from "./LogEvents";
import { MessageContainer } from "./MessageContainer";
import { LogsList } from "./LogsList";
import { useOnContainerResizeHeight } from "./useContainerResizeHeight";
import { useOnContainerResizeWidth } from "./useContainerResizeWidth";

export const LogsContainer = styled.div<{
  disableTextWrapping: boolean;
  maxHeight?: string;
}>`
  padding: 0.5rem 0.7rem;
  background-color: ${muiColors.gray[800]};
  color: white;
  overflow: hidden;
  scroll-behavior: smooth;
  border-radius: 4px;
  flex-grow: 1;
  position: relative;

  ${({ disableTextWrapping }) =>
    disableTextWrapping ? "white-space: pre;" : ""}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : "")}
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum LogsType {
  Current = "current",
  Issue = "issue",
}

interface BlackTextProps {
  logs: PodLogLine[];
  failureMessage: string | null;
  showTimestamp: boolean;
  showLineNumber: boolean;
  enableTextWrapping: boolean;
  loading?: boolean;
  searchTerm?: string;
  setSearchResults?: React.Dispatch<React.SetStateAction<number>>;
  scrollIndex?: number;
  onLoadMoreClicked?: () => void;
  containerName?: string;
  logsType?: LogsType;
  maxHeight?: string;
  events?: LogEvent[];
  mode: PodLogsMode;
}

const LogsBlackText: React.FC<BlackTextProps> = ({
  logs,
  failureMessage,
  showTimestamp,
  showLineNumber,
  enableTextWrapping,
  loading = false,
  searchTerm,
  setSearchResults,
  scrollIndex,
  onLoadMoreClicked,
  containerName,
  logsType = LogsType.Current,
  maxHeight,
  events,
  mode,
}) => {
  const [moreLogsLoading, setMoreLogsLoading] = useState(false);
  const [totalProcessedLogs, setTotalProcessedLogs] = useState(0);
  const [newLogsIndicator, setNewLogsIndicator] = useState(-1);
  const highlightedLines = useMemo(() => {
    return logs.map(({ logTime, message, isEvent }) => {
      const highlightedTerm = highlightTerm(
        message ?? "",
        searchTerm ?? "",
        HighlightTypes.Search
      );
      const parts = highlightCodedWords(highlightedTerm);

      return {
        logTime,
        parts,
        matchesInLine: parts.filter((p) => {
          return p.type === HighlightTypes.Search;
        }).length,
        isEvent,
      };
    });
  }, [logs, searchTerm]);
  useEffect(() => {
    if (logs.length > totalProcessedLogs) {
      setTotalProcessedLogs(logs.length);
    }
    setMoreLogsLoading(false);
  }, [logs, totalProcessedLogs]);

  const messageMatches = useMemo(() => {
    return sum(highlightedLines.map((line) => line.matchesInLine));
  }, [highlightedLines]);

  useEffect(() => {
    if (setSearchResults) {
      setSearchResults(messageMatches);
    }
  }, [messageMatches, setSearchResults]);

  const cumulativeMatchCount = useMemo(() => {
    const cmc: number[] = [];
    highlightedLines.forEach((line) => {
      cmc.push((last(cmc) ?? 0) + line.matchesInLine);
    });
    return cmc;
  }, [highlightedLines]);

  const fullyHighlightedLines = useMemo(() => {
    const realScrollIndex = (scrollIndex ?? 1) - 1;
    return highlightedLines.map((line, i) => {
      if (
        realScrollIndex >= (cumulativeMatchCount[i - 1] ?? 0) &&
        realScrollIndex < cumulativeMatchCount[i]
      ) {
        return {
          ...line,
          parts: line.parts.map((part) => {
            if (
              typeof part.matchIndex === "number" &&
              realScrollIndex ===
                (cumulativeMatchCount[i - 1] ?? 0) + part.matchIndex
            ) {
              return { ...part, type: HighlightTypes.SearchedWord };
            }
            return part;
          }),
        };
      }
      return line;
    });
  }, [scrollIndex, highlightedLines, cumulativeMatchCount]);

  const eventDictionary = useMapEventToLogs(logs, events);
  let content = (
    <MessageContainer>
      <LinesLoader />
    </MessageContainer>
  );

  const ref = useRef<HTMLDivElement>(null);
  const containerHeight = useOnContainerResizeHeight(ref);
  const containerWidth = useOnContainerResizeWidth(ref);

  if (logs.length > 0) {
    content = (
      <LogsList
        logs={fullyHighlightedLines}
        newLogsIndicator={newLogsIndicator}
        eventDictionary={eventDictionary}
        showTimestamp={showTimestamp}
        showLineNumber={showLineNumber}
        enableTextWrapping={enableTextWrapping}
        hasFailureMessage={!!failureMessage}
        mode={mode}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        moreLogsLoading={moreLogsLoading}
        onLoadMoreClicked={
          onLoadMoreClicked
            ? () => {
                setMoreLogsLoading(true);
                onLoadMoreClicked();
                setNewLogsIndicator(logs.length);
              }
            : undefined
        }
      />
    );
  }

  if (!logs.length && (failureMessage || !loading)) {
    const formattedErrorMessage = getFormattedErrorMessage(
      failureMessage,
      containerName
    );
    let icon, title, message;
    if (formattedErrorMessage) {
      [icon, title, message] = [
        null,
        "Logs are not available",
        formattedErrorMessage,
      ];
    } else if (failureMessage) {
      [icon, title, message] = [null, "Logs can't be loaded", failureMessage];
    } else {
      [icon, title, message] = [
        null,
        "Log data does not exist",
        logsType === LogsType.Issue
          ? "Logs are saved for up to 72 hours after the issue"
          : "",
      ];
    }
    content = (
      <PodMessage
        icon={icon}
        title={title}
        message={message}
        iconColor={greenBrand}
        data-e2e-selector={"podLogsError"}
      />
    );
  }

  return (
    <LogsContainer
      disableTextWrapping={!enableTextWrapping}
      maxHeight={maxHeight}
      ref={ref}
    >
      {content}
    </LogsContainer>
  );
};

export default LogsBlackText;
