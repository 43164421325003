import styled from "styled-components";

import { gray3, gray5 } from "../../../Colors";
import { textLightStyle, textStyle } from "../typography";

export default styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;
  ${textStyle}
  th {
    ${textLightStyle}
    padding: 0.75rem 0.75rem;
    text-align: start;
    color: #8993a9;
    white-space: nowrap;
    border-bottom: 2px solid #d2d3d6;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  td {
    padding: 0.75rem;
    border-bottom: 1px solid ${gray5};
  }
`;

export const Row = styled.tr<{ selected: boolean; disabled?: boolean }>`
  ${({ selected, disabled }) =>
    disabled
      ? `color: ${gray3};`
      : `
       background-color: ${selected ? "rgba(0, 122, 255, 0.3)" : "white"};
       cursor: pointer;
       &:hover {
         ${!selected && "background-color: #ebf3fc;"}
       }
`}
`;
