import { format } from "date-fns";
import { get } from "lodash";

import { convertLocalDateToUtc } from "../../../../../../../../shared/utils/dateUtils";

export enum PlaceHolderErrorType {
  HrefNotFound = "HrefNotFound",
  ContainerNotFound = "ContainerNotFound",
  YamlPathNotFound = "YamlPathNotFound",
  TimeInvalidFormat = "TimeInvalidFormat",
  FailedPodNotRetrieved = "FailedPodNotRetrieved",
  EventWithoutFailedPod = "EventWithoutFailedPod",
}

class HrefNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = PlaceHolderErrorType.HrefNotFound;
  }
}
class ContainerNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = PlaceHolderErrorType.ContainerNotFound;
  }
}
class YamlPathNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = PlaceHolderErrorType.YamlPathNotFound;
  }
}

class TimeInvalidFormatError extends Error {
  constructor() {
    super();
    this.name = PlaceHolderErrorType.TimeInvalidFormat;
  }
}
class FailedPodNotRetrievedError extends Error {
  constructor() {
    super();
    this.name = PlaceHolderErrorType.FailedPodNotRetrieved;
  }
}

class EventWithoutFailedPodError extends Error {
  constructor() {
    super();
    this.name = PlaceHolderErrorType.EventWithoutFailedPod;
  }
}

export const PlaceHolderErrorMessages = new Map([
  [
    PlaceHolderErrorType.ContainerNotFound,
    "We couldn’t find a container named:",
  ],
  [
    PlaceHolderErrorType.HrefNotFound,
    "The annotation doesn't include the right values.",
  ],
  [PlaceHolderErrorType.YamlPathNotFound, "We couldn’t find this path:"],
  [PlaceHolderErrorType.TimeInvalidFormat, "Time format is incorrect."],
  [PlaceHolderErrorType.FailedPodNotRetrieved, "Couldn't find failed pod"],
  [
    PlaceHolderErrorType.EventWithoutFailedPod,
    "There are no failed pods for this successful event",
  ],
]);

export interface PlaceHolderError {
  placeHolderError: PlaceHolderErrorType;
  errorValue: string | null;
}

const setReplacements = (
  serviceName: string,
  cluster: string,
  namespace: string,
  startTime?: Date,
  endTime?: Date
): { [placeholder: string]: string } => {
  return {
    HrefNotFound: "!ThisIsTestPlaceholder!",
    epochStart: startTime ? startTime.getTime().toString() : "No_StartTime",
    epochEnd: endTime ? endTime.getTime().toString() : "No_EndTime",
    service: serviceName,
    cluster: cluster ?? "unknown",
    namespace: namespace ?? "No_Namespace",
  };
};

const utcDate = (date: Date): Date => {
  return new Date(convertLocalDateToUtc(date));
};

const checkReplaceTimestamp = (
  placeholder: string,
  startTime?: Date,
  endTime?: Date
): string | null => {
  if (!startTime && !endTime) {
    return null;
  }
  const startPlaceHolder = "timestampStart=";
  const legacyStartPlaceHolder = "timestempStart=";
  const endPlaceholder = "timestampEnd=";
  const legacyEndPlaceholder = "timestempEnd=";
  if (
    (placeholder.startsWith(startPlaceHolder) ||
      placeholder.startsWith(legacyStartPlaceHolder)) &&
    startTime
  ) {
    try {
      return format(startTime, placeholder.substring(startPlaceHolder.length));
    } catch (error) {
      if (error instanceof RangeError) {
        throw new TimeInvalidFormatError();
      }
    }
  } else if (
    (placeholder.startsWith(endPlaceholder) ||
      placeholder.startsWith(legacyEndPlaceholder)) &&
    endTime
  ) {
    try {
      return format(endTime, placeholder.substring(endPlaceholder.length));
    } catch (error) {
      if (error instanceof RangeError) {
        throw new TimeInvalidFormatError();
      }
    }
  }
  return null;
};

const getPlaceholderValue = (
  placeholder: string,
  placeholderRegex: RegExp
): string => placeholder.match(placeholderRegex)?.[1] ?? "";

const getContainerByName = (
  containerName: string,
  newSpec: Record<string, unknown>
): unknown | undefined => {
  const specContainers = get(newSpec, "spec.template.spec.containers");
  return Array.isArray(specContainers)
    ? specContainers.find((element) => element.name === containerName)
    : null;
};

const getContainerInfo = (
  placeholder: string,
  newSpec: Record<string, unknown>
): string | null => {
  const containerName = getPlaceholderValue(
    placeholder,
    /container\[(.+)\].image/
  );

  if (containerName) {
    const container = getContainerByName(containerName, newSpec);
    if (container) {
      return get(container, "image");
    }
    throw new ContainerNotFoundError(containerName);
  }

  return null;
};

const getYamlInfo = (
  placeholder: string,
  newSpec: Record<string, unknown>
): string | null => {
  const yamlPath = getPlaceholderValue(placeholder, /yaml\[(.+)\]/);

  if (yamlPath) {
    const yamlValue = get(newSpec, yamlPath);
    if (
      yamlValue &&
      (typeof yamlValue === "string" || typeof yamlValue === "number")
    ) {
      return yamlValue.toString();
    }
    throw new YamlPathNotFoundError(yamlPath);
  }

  return null;
};

const getFailedPod = (
  placeholder: string,
  eventWithFailedPod: boolean,
  failedPodName: string | undefined
): string | null => {
  if (placeholder === "failedPod") {
    if (!eventWithFailedPod) {
      throw new EventWithoutFailedPodError();
    }
    if (failedPodName) {
      return failedPodName;
    }
    throw new FailedPodNotRetrievedError();
  }
  return null;
};

const generateCustomLinkWithParameters = (
  url: string,
  serviceName: string,
  cluster: string,
  namespace: string,
  eventWithFailedPod: boolean,
  newSpec?: Record<string, unknown>,
  startTime?: Date,
  endTime?: Date,
  failedPodName?: string
): { href: string; errors: PlaceHolderError[] } => {
  const replacements = setReplacements(
    serviceName,
    cluster,
    namespace,
    startTime,
    endTime
  );
  const errors: PlaceHolderError[] = [];
  const href = url.replace(/(\${)(.*?)(\s*\})/gi, (placeholder) => {
    const placeholderWithoutBracket = placeholder.substring(
      2,
      placeholder.length - 1
    );
    try {
      const currentHref =
        replacements[placeholderWithoutBracket] ||
        checkReplaceTimestamp(
          placeholderWithoutBracket,
          startTime && utcDate(startTime),
          endTime && utcDate(endTime)
        ) ||
        (newSpec && getContainerInfo(placeholderWithoutBracket, newSpec)) ||
        (newSpec && getYamlInfo(placeholderWithoutBracket, newSpec)) ||
        getFailedPod(
          placeholderWithoutBracket,
          eventWithFailedPod,
          failedPodName
        );
      if (currentHref) {
        return currentHref;
      } else {
        throw new HrefNotFoundError("");
      }
    } catch (error) {
      if (error instanceof Error) {
        errors.push({
          placeHolderError:
            PlaceHolderErrorType[
              error.name as keyof typeof PlaceHolderErrorType
            ],
          errorValue: error.message,
        });
      }
      return placeholder;
    }
  });
  return { href, errors };
};

export default generateCustomLinkWithParameters;
