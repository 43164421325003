import styled from "styled-components";

import { gray11, gray5, offWhite } from "../../../Colors";

const Container = styled.div`
  display: block;
  appearance: none;
  color: ${gray5};
  border: 1px solid ${gray11};
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  background-color: ${offWhite};
`;

export default Container;
