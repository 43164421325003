import { muiColors, palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import {
  AlertCircleOutlined16,
  AlertTriangle16,
} from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";
import Delete from "@mui/icons-material/Delete";

const variants: {
  [key: string]: {
    background: string | undefined;
    icon: React.ReactNode;
    color: string | undefined;
    border?: string;
  };
} = {
  info: {
    background: palette.blue[50],
    icon: <AlertCircleOutlined16 />,
    color: palette.gray[600],
  },
  warning: {
    background: palette.orange[50],
    icon: <AlertTriangle16 />,
    color: palette.orange[700],
  },
  deleted: {
    background: muiColors.orange[50],
    icon: <Delete />,
    color: palette.orange[700],
  },
  infoGray: {
    background: palette.gray[50],
    icon: <AlertCircleOutlined16 />,
    color: palette.gray[600],
    border: `1px solid ${muiColors.gray[200]}`,
  },
};

type SnapshotVariant = keyof typeof variants;

const SnapshotInfo = styled.div<{ variant: SnapshotVariant }>`
  display: flex;
  gap: 0.6rem;
  background: ${({ variant }) => variants[variant].background};
  padding: ${({ variant }) =>
      (variant === "info" || variant === "infoGray") && "0.5rem"}
    1rem;
  align-items: center;
  color: ${({ variant }) => variants[variant].color};
  border-radius: 0.25rem;
  border: ${({ variant }) => variants[variant].border};
`;

export const SnapshotInfoDetail: React.FC<{
  children: React.ReactNode;
  variant?: SnapshotVariant;
}> = ({ children, variant = "info" }) => {
  return (
    <SnapshotInfo variant={variant}>
      {variants[variant].icon}
      <Typography variant={"body2"} color={palette.black["0"]}>
        {children}
      </Typography>
    </SnapshotInfo>
  );
};
