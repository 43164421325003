interface DockerImageNameParts {
  domain: string;
  path: string;
  tag: string;
  digest: string;
}

const regexp = /(?:([^/]+:\d+|[^/]+\.[^/]+)\/)?([^:@]+)(?::([^@]+))?(?:@(.+))?/;
const parseDockerImageName = (fullImageName: string): DockerImageNameParts => {
  const result = regexp.exec(fullImageName);
  const [, domain = "", path = "", tag = "", digest = ""] = result ?? [];
  return { domain, path, tag, digest };
};

export default parseDockerImageName;
