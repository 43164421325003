import { useMemo } from "react";
import { clone } from "lodash";

import { IArgoWorkflowTableData } from "../types";
import {
  useStateInSearchParams,
  useStringifiedStateInSearchParams,
} from "../../../shared/hooks/state";
import {
  ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY,
  ARGO_WORKFLOW_STATUS_PARAM_KEY,
  ARGO_WORKFLOW_TYPE_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";

export const useArgoFilters = (
  argoWorkflowRows: IArgoWorkflowTableData[]
): IArgoWorkflowTableData[] => {
  const [filterStatus] = useStringifiedStateInSearchParams<string[]>(
    ARGO_WORKFLOW_STATUS_PARAM_KEY
  );
  const [filterTypes] = useStringifiedStateInSearchParams<string[]>(
    ARGO_WORKFLOW_TYPE_PARAM_KEY
  );
  const [filterSearchTerm] = useStateInSearchParams(
    ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY
  );

  return useMemo(
    () =>
      filterArgoWorkflowsBySearchParams(
        argoWorkflowRows,
        filterSearchTerm,
        filterStatus,
        filterTypes
      ),
    [argoWorkflowRows, filterSearchTerm, filterStatus, filterTypes]
  );
};

const filterArgoWorkflowsBySearchParams = (
  argoWorkflowRows: IArgoWorkflowTableData[],
  filterSearchTerm?: string | null,
  filterStatus?: string[] | null,
  filterTypes?: string[] | null
): IArgoWorkflowTableData[] => {
  let output = clone(argoWorkflowRows);

  if (filterTypes?.length) {
    output = filterArgoTableByColumn(output, filterTypes, "type");
  }
  if (filterStatus?.length) {
    output = filterArgoTableByColumn(output, filterStatus, "status");
  }

  if (filterSearchTerm) {
    output = filterArgoTableByTerm(output, filterSearchTerm);
  }

  return output;
};

export const filterArgoTableByTerm = (
  rows: IArgoWorkflowTableData[],
  termValue: string
): IArgoWorkflowTableData[] => {
  return rows.filter((row) =>
    Object.values(row).some((value) =>
      (value?.toString() ?? "").toLowerCase().includes(termValue.toLowerCase())
    )
  );
};

export const filterArgoTableByColumn = (
  rows: IArgoWorkflowTableData[],
  filterValues: string[],
  keyName: string
): IArgoWorkflowTableData[] => {
  return rows.filter((result) =>
    filterValues.includes(result[keyName.toLowerCase()])
  );
};
