import React, { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PagerDutyEvent } from "../../../EventGroup/PagerDutyEventGroup";
import reasonIcon from "../../assets/reason.svg";
import eventsIcon from "../../assets/events.svg";
import detailsIcon from "../../assets/details.svg";
import { EventDetailsContainer } from "../common";
import EventDetailsTitle from "../components/EventDetailsTitle";
import EventDetailsSection from "../components/EventDetailsSection";
import EventsGrid from "../components/EventsGrid";
import pagerDutyIcon from "../../../../integrations/management/logos/pagerduty.svg";
import IntegrationLink from "../components/LinkSection/IntegrationLink";

import PagerDutyPlayers from "./PagerDutyPlayers";

interface PagerdutyEventDetailsProps {
  events: PagerDutyEvent[];
}

const usePreparedEvents = (events: PagerDutyEvent[]) =>
  useMemo(() => {
    return events.map((e) => ({
      eventTime: e.eventTime,
      status: e.properties?.incidentStatus ?? "N/A",
      details: e.properties?.summary ?? "N/A",
    }));
  }, [events]);

const PagerdutyEventDetails: React.FC<PagerdutyEventDetailsProps> = ({
  events,
}) => {
  const properties = events[events.length - 1].properties;
  const preparedEvents = usePreparedEvents(events);
  return (
    <EventDetailsContainer>
      <EventDetailsTitle
        title={properties?.incidentTitle ?? "PagerDuty Incident"}
      />
      <EventDetailsSection icon={reasonIcon} title="More Info">
        <b>{properties?.summary ?? "N/A"}</b>
      </EventDetailsSection>
      <EventDetailsSection icon={eventsIcon} title="Events">
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection icon={detailsIcon} title="Details">
        <PagerDutyPlayers properties={properties} />
      </EventDetailsSection>
      <IntegrationLink
        single
        icon={pagerDutyIcon}
        href={properties?.incidentUrl}
        title="View alert on PagerDuty"
      />
    </EventDetailsContainer>
  );
};

export default PagerdutyEventDetails;
