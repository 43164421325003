import React, { useMemo } from "react";
import MuiStep from "@mui/material/Step";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";
import { TimelineEvent } from "@/generated/reliabilityApi";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { StepContainerWithInfo } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/StepContainerWithInfo";
import { EllipsisTextContainerWithTooltip } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/EllipsisTextContainerWithTooltip";
import { MAX_TIMELINE_COMPONENTS } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/timelineEventsConstants";

export const useTimelineEventsComponents = () => {
  const { timeline } = useGetCascadingFailureSupportingData() ?? {};

  return useMemo(() => {
    const mapTimelineComponent = (
      timelineComponents: TimelineEvent[],
      isIssueGroup: boolean
    ) => {
      return timelineComponents?.map(
        ({ komodor_uid, event, incident_time }) => {
          const icon = <WarningAmberIcon color={"action"} />;

          const { namespace, name } = parseKomodorUid(komodor_uid) ?? {};
          const resource = namespace ? `${namespace}/${name}` : name ?? "";
          // temp logic, root cause items are not yet supported in API
          // const timeStamp = format(new Date(time), "yyyy-MM-dd, hh:mm:ss a");
          const variant = "info";

          return (
            <MuiStep>
              <StepContainerWithInfo
                icon={icon}
                isGrouped={isIssueGroup}
                variant={variant}
                topTitle={
                  <EllipsisTextContainerWithTooltip
                    textContent={event}
                    ellipsisAtLine={1}
                  />
                }
                bottomTitle={
                  <>
                    <EllipsisTextContainerWithTooltip
                      textContent={resource}
                      ellipsisAtLine={1}
                    />
                    <EllipsisTextContainerWithTooltip
                      textContent={incident_time}
                      ellipsisAtLine={1}
                    />
                  </>
                }
              />
            </MuiStep>
          );
        }
      );
    };

    // root causes are not yet supported in API
    return mapTimelineComponent(timeline ?? [], true).slice(
      0,
      MAX_TIMELINE_COMPONENTS
    );
  }, [timeline]);
};
