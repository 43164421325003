import React, { useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import {
  CheckType,
  ImpactGroupIdentifier,
  Severity,
  ViolationCountBy,
  ViolationsAggregationGroup,
} from "../../../../../generated/reliabilityApi";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { impactGroupIdentifierToText } from "../../../../reliability/constants/impactGroupIdentifierToText";
import { SeverityChip } from "../../../styles";
import { lightMuiTooltipStyle } from "../../../../../shared/styles/tooltip";

import { ImpactItem } from "./ImpactItem";
import { useOpenReliabilityDrawer } from "./reliabilitySectionHooks";
import { StyledLinkWithPointer } from "./reliabilitySectionStyles";

const { Container, ViewAllViolationsButton } =
  AriaLabels.ClusterOverview.ReliabilitySection.Violations.ImpactGroup;

const GroupContainer = styled.div.attrs({ "aria-label": Container })`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-height: 168px;
  height: fill-available;
  padding: 16px;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;

  .item:not(:last-of-type) {
    border-bottom: 1px solid ${muiColors.gray[200]};
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ImpactGroupLoader = () => (
  <GroupContainer>
    <Center>
      <LinesLoader />
    </Center>
  </GroupContainer>
);

interface ImpactGroupProps {
  group: ViolationsAggregationGroup;
  clusters: string[];
}
export const ImpactGroup: React.FC<ImpactGroupProps> = ({
  group,
  clusters,
}) => {
  const openReliabilityDrawer = useOpenReliabilityDrawer();
  const groupTotalCount = group.numOfTotalViolations ?? 0;
  const { title, explanation } =
    impactGroupIdentifierToText[group.id as ImpactGroupIdentifier];
  const violationsCounts = useMemo(
    () =>
      (Object.entries(
        group.breakdowns?.[ViolationCountBy.CheckType] ?? {}
      ).slice(0, 4) ?? []) as [CheckType, number][],
    [group.breakdowns]
  );

  const onViewAllClick = () => {
    openReliabilityDrawer({ clusters, impactGroupId: group.id });
  };

  const onImpactItemClick = useCallback(
    (checkType: CheckType) => {
      openReliabilityDrawer({
        clusters,
        impactGroupId: group.id,
        checkTypes: [checkType],
      });
    },
    [clusters, group.id, openReliabilityDrawer]
  );

  const impactItems = useMemo(
    () =>
      violationsCounts.map((violationCount) => (
        <ImpactItem
          key={violationCount[0]}
          violationCount={violationCount}
          onItemClick={onImpactItemClick}
        />
      )),
    [violationsCounts, onImpactItemClick]
  );

  const infoTooltip = (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={<Typography variant="body2">{explanation}</Typography>}
      placement="top"
    >
      <InfoOutlined fontSize="small" sx={{ color: muiColors.gray[500] }} />
    </Tooltip>
  );

  if (violationsCounts.length === 0) {
    return (
      <GroupContainer>
        <Title>
          <Typography variant="h4">{title}</Typography>
          {infoTooltip}
          <CheckCircle
            sx={{ color: muiColors.green[500], marginLeft: "4px" }}
          />
        </Title>
        <Center>
          <Typography variant="body1">No Violations</Typography>
        </Center>
      </GroupContainer>
    );
  }

  return (
    <GroupContainer>
      <Title>
        <Typography variant="h4">
          {title} ({groupTotalCount})
        </Typography>
        {infoTooltip}
      </Title>
      <Box sx={{ display: "flex", gap: "8px" }}>
        {Object.entries(
          group.breakdowns?.[ViolationCountBy.Severity] ?? {}
        ).map(([severity, count]) => (
          <Box key={severity} sx={{ display: "flex", gap: "4px" }}>
            <SeverityChip
              label={severity}
              severity={severity as Severity}
              size="small"
            />
            <Typography variant="body2">{count}</Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
          marginTop: "16px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" color="text.secondary">
            Top violations
          </Typography>
          <StyledLinkWithPointer
            variant="body2"
            underline="hover"
            aria-label={ViewAllViolationsButton}
            onClick={onViewAllClick}
          >
            View all →
          </StyledLinkWithPointer>
        </Box>
        <Items>{impactItems}</Items>
      </Box>
    </GroupContainer>
  );
};
