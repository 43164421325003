import React, { useMemo } from "react";
import styled from "styled-components";
import { replace } from "lodash";
import { TooltipWrapper } from "react-tooltip";

import { HighlightedText } from "../../common/HighlightedText";
import { BlackLabel } from "../../common/Label";
import { Tag } from "../../../shared/types/ServiceInfo";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import { useGetTagValueToDisplay } from "./useGetTagValueToDisplay";

const MAX_LENGTH = 35;

const StyledLabel = styled(BlackLabel)`
  border-radius: 0.25rem;
`;
const LightFont = styled.span`
  font-weight: 500;
`;

export interface ServiceTileTagProps {
  tag: Tag;
  filterTerm?: string;
  maxLength?: number;
}

const ServiceTileTag: React.FC<ServiceTileTagProps> = ({
  tag,
  filterTerm = "",
  maxLength = MAX_LENGTH,
}) => {
  const label = `${tag.label}: `;
  const fullText = `${label}${tag.value}`;
  const disabled = fullText.length < maxLength;
  const getTagValueToDisplay = useGetTagValueToDisplay();

  const highlightString = useMemo(() => {
    if (
      filterTerm.length <= maxLength - label.length ||
      !tag.value.includes(filterTerm)
    )
      return filterTerm;
    let userHighlightString = replace(
      getTagValueToDisplay(tag.value, filterTerm, label.length, maxLength),
      new RegExp("\\.", "g"),
      ""
    );

    if (!filterTerm.endsWith(userHighlightString))
      userHighlightString = `${userHighlightString}...`;
    return userHighlightString;
  }, [filterTerm, maxLength, label.length, tag.value, getTagValueToDisplay]);

  return (
    <TooltipWrapper
      tooltipId={DEFAULT_TOOLTIP}
      content={disabled ? undefined : fullText}
      delayShow={500}
    >
      <StyledLabel>
        <LightFont>{label}</LightFont>
        <HighlightedText
          text={getTagValueToDisplay(
            tag.value,
            filterTerm,
            label.length,
            maxLength
          )}
          term={highlightString}
        />
      </StyledLabel>
    </TooltipWrapper>
  );
};

export default ServiceTileTag;
