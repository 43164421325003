import React, { useMemo } from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { InsightDetailsContentProps } from "../../insightUtils";
import { InsightContentTopContainer } from "../common";
import { DeprecatedApisRecord } from "../../models/DeprecatedApis";
import CopyComponent from "../../../common/Copy";

const Container = styled.div`
  display: flex;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  gap: 1rem;

  .copy {
    font-weight: normal;
  }
`;

const ColumnContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const TooltipAndTextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const DeprecatedApisContent: React.FC<InsightDetailsContentProps> = ({
  insightData,
}) => {
  const data = useMemo(() => {
    if (!insightData || !DeprecatedApisRecord.guard(insightData)) {
      return undefined;
    }
    return insightData;
  }, [insightData]);
  return (
    <InsightContentTopContainer>
      <Container>
        <ColumnContainer>
          <Typography variant="h5">Kind</Typography>
          <Typography variant="h5">Deprecated in</Typography>
          <Typography variant="h5">Removed in</Typography>
          <Typography variant="h5">Removed API</Typography>
          <Typography variant="h5">Replacement API</Typography>
        </ColumnContainer>
        <ColumnContainer>
          <Typography variant="body2">{data?.kind}</Typography>
          <TooltipAndTextContainer>
            <Typography variant="body2">{data?.["deprecated-in"]} </Typography>
            <Tooltip
              arrow={true}
              title={`This kind was deprecated in Kubernetes version ${data?.["deprecated-in"]}`}
            >
              <InfoOutlined color={"action"} fontSize="small" />
            </Tooltip>
          </TooltipAndTextContainer>
          <TooltipAndTextContainer>
            <Typography variant="body2">{data?.["removed-in"]} </Typography>
            <Tooltip
              arrow={true}
              title={`This kind will be removed in Kubernetes version ${data?.["removed-in"]}`}
            >
              <InfoOutlined color={"action"} fontSize="small" />
            </Tooltip>
          </TooltipAndTextContainer>
          <Typography variant="body2">{data?.version}</Typography>
          <Typography variant="body2">
            <CopyComponent
              value={data?.["replacement-api"] ?? ""}
              valueClassName={"copy"}
            />
          </Typography>
        </ColumnContainer>
      </Container>
    </InsightContentTopContainer>
  );
};

export default DeprecatedApisContent;
