import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type InspectionState = {
  selectedNamespaces: string[];
};

export type InspectionActions = {
  setSelectedNamespaces: (selectedNamespaces: string[]) => void;
};

export type InspectionStore = InspectionState & InspectionActions;

export const initialState: InspectionState = {
  selectedNamespaces: [],
};

export const useInspectionStore = create(
  immer<InspectionStore>((set) => ({
    ...initialState,
    setSelectedNamespaces: (namespaces: string[]) => {
      set((state) => {
        state.selectedNamespaces = namespaces;
      });
    },
  }))
);
