import { useMemo } from "react";

import useUserMetadata from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { Plan, useGetPlanDataQuery } from "../../generated/graphql";
import useQueryWithVariables from "../../shared/hooks/useQueryWithVariables";

const useGetAccountPlanData = (): Plan | undefined => {
  const { accountPlan } = useUserMetadata();
  const fetchPlanVars = useMemo(() => ({ accountPlan }), [accountPlan]);
  const data = useQueryWithVariables(useGetPlanDataQuery, fetchPlanVars);
  return data?.plan[0];
};

export default useGetAccountPlanData;
