import {
  Button,
  Modal,
  ModalActions,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import React from "react";

const MarkCloseConfirmation: React.FC<{
  isOpen: boolean;
  handleCancellation?: () => void;
  handleConfirmation?: () => void;
}> = ({ isOpen, handleCancellation, handleConfirmation }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={handleCancellation} width="33%">
      <ModalHeader>
        <Typography variant="headline">
          Are you sure you want to close this issue?
        </Typography>
      </ModalHeader>
      <ModalActions>
        <Button variant="secondary" onClick={handleCancellation}>
          No, keep this open
        </Button>
        <Button variant="primary" onClick={handleConfirmation}>
          Yes, mark as closed
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default MarkCloseConfirmation;
