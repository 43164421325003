import React from "react";
import { NodeCondition } from "kubernetes-types/core/v1.d";
import styled from "styled-components";
import { palette, theme } from "@komodorio/design-system";
import { Tooltip, Typography } from "@komodorio/design-system/deprecated";

import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";

const DescribeTooltipItemContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  padding-block: 0.1rem;
  column-gap: 1rem;
`;

const ConditionTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  color: ${theme.foreground.fgPrimary};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-block: 0.25rem;
`;

const getConditionTooltipFields = (condition: NodeCondition) => {
  const {
    type,
    status,
    reason,
    message,
    lastHeartbeatTime,
    lastTransitionTime,
  } = condition;
  return [
    {
      name: "Type",
      value: type,
    },
    {
      name: "Status",
      value: status,
    },
    {
      name: "Reason",
      value: reason ?? null,
    },
    {
      name: "Message",
      value: message ?? null,
    },
    {
      name: "Last Heartbeat",
      value: lastHeartbeatTime ?? null,
      type: "date",
    },
    {
      name: "Last Transition",
      value: lastTransitionTime ?? null,
      type: "date",
    },
  ];
};

export const NodeConditionTooltip: React.FC<{ condition: NodeCondition }> = ({
  condition,
}) => {
  const { format } = useDateFormatter();
  return (
    <Tooltip id={"condition-tooltip" + condition.type} variant="light">
      <ConditionTooltipContainer>
        {getConditionTooltipFields(condition).map((field) =>
          field.value ? (
            <DescribeTooltipItem
              key={field.name}
              name={field.name + ":"}
              value={
                field.type === "date"
                  ? format(new Date(field.value)) ?? field.value
                  : field.value
              }
            />
          ) : null
        )}
      </ConditionTooltipContainer>
    </Tooltip>
  );
};

const DescribeTooltipItem: React.FC<{ name: string; value?: string }> = ({
  name,
  value,
}) => {
  return (
    <DescribeTooltipItemContainer>
      <Typography variant="title" size="small" color={palette.gray[500]}>
        {name}
      </Typography>
      <Typography size="small">{value}</Typography>
    </DescribeTooltipItemContainer>
  );
};
