/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from "react";
import { palette } from "@komodorio/design-system";
import {
  Button,
  Skeleton,
  Typography,
} from "@komodorio/design-system/deprecated";
import {
  AlertTriangle16,
  InfoCircleOutlined24,
} from "@komodorio/design-system/icons";
import styled from "styled-components";
import { parseISO } from "date-fns";
import { get } from "lodash";

import { ActionsContainer, Label, SummaryCard } from "../../../styles";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import { ContainerLog } from "../types";
import { gray10 } from "../../../../../../Colors";
import { padFollowingZerosToLogTime } from "../../../../../common/LogsViewer/PodLogsBar/PodLogsActions";
import { StyledBlackContainer } from "../../../../common/styles";
import { SpacedRowFieldContainer } from "../../../../monitorsConfiguration/common/styles";
import ExternalIcon from "../../../../../common/ProcessList/assets/external_icon.svg?react";
import { FlexRows } from "../../../../../common/FlexContainers";
import Arrow from "../../../../../common/ProcessList/Arrow";
import useDateFormatter from "../../../../../../shared/hooks/useDateFormatter";
import { useShouldOpenPodsHealthUpdate } from "../../../../../common/ProcessList/details/WorkflowIssueEventDetails/useShouldOpenPodsHealth";
import LogsViewerModal from "../../../../../common/LogsViewer/LogsViewerModal";
import Resource from "../../../../../ResourceView/resources";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";
import { Metrics } from "../../../../../Metrics/Metrics";
import { DescribeExpandedItem } from "../../../../../ResourceView/tabs/DescribeTab/common/DescribeExpandedItem";
import { DescribePanel } from "../../../../../ResourceView/tabs/DescribeTab/common/DescribeSection";
import { AvailableActions } from "../../../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { getButton } from "../../../../../Actions/common/getActionButtonStyle";
import { ConfigureButton } from "../../../../../Actions/buttons/ConfigureButton";
import { usePodMetrics } from "../../../../../Metrics/usePodMetrics";
import { LogsType } from "../../../../../common/LogsViewer/LogsBlackText";
import { useIsMetricsDrawerSupported } from "../../../../../Metrics/metricsHooks";
import { MetricsGraphType } from "../../../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../../../../InvestigationMode/metricsHooks";
import { ReasonTag } from "../../../../../InvestigationMode/availability/common/ReasonTag";
import { ComponentCircle } from "../../../../../common/ProcessList/details/components/EventDetailsSection";
import { RollbackButton } from "../../../../../Actions/buttons/RollbackButton";
import { storedTextWrappingEnabled } from "../../../../../common/LogsViewer/LogsViewer";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { usePodsSummary } from "./hooks";

export type SingleOpinionatedSummary = {
  reason: string;
  errorMessage: string | undefined;
  count: number;
  customMessage: string;
  containersLogs: ContainerLog[];
};

export type OpinionatedSummary = SingleOpinionatedSummary[];
export const FlexMessageSpan = styled.span`
  max-inline-size: max-content;
  word-break: break-word;
  max-height: 5.5rem;
  overflow: auto;
  white-space: break-spaces;
`;
export const FlexErrorSpan = styled(FlexMessageSpan)`
  color: ${palette.pink[700]};
`;

const FixedHeightBlackContainer = styled(StyledBlackContainer)`
  max-height: 9rem;
  overflow: auto;
`;
const ReasonsContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
`;
const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const SummaryContainer = styled.div`
  display: grid;
  flex-direction: column;
  grid-gap: 0.7rem;
  padding: 0.5rem 0;
  margin-left: 3rem;
`;
const ContainersDataContainer = styled.div`
  overflow: hidden;
`;
const MetricsTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const SummaryCardLeftSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.9rem;
`;

const WarnInfo = styled.div`
  display: flex;
  gap: 1rem;
  border: 1px solid ${palette.gray[300]};
  border-radius: 4px;
  width: inherit;
  padding: 0.5rem 0.7rem;
`;
export const PodsSummary: React.FC<{
  opinionatedSummary: OpinionatedSummary;
  infoTime?: Date;
  endTime: Date;
  failureMessage?: string;
  desiredReplicas?: number;
  readyReplicas?: number;
  resource?: Resource;
  clusterName: string;
  namespace: string;
  shouldShowAction?: boolean;
  shouldShowRollback?: boolean;
  shouldShowChangeResources?: boolean;
}> = ({
  opinionatedSummary,
  infoTime,
  endTime,
  failureMessage,
  desiredReplicas,
  readyReplicas,
  shouldShowAction,
  shouldShowRollback,
  shouldShowChangeResources,
  resource,
  clusterName,
  namespace,
}) => {
  const { metricsAvailabilityDrawer } = useOverridableFlags();
  const { format } = useDateFormatter();
  const [showLogsExtendWindow, setShowLogsExtendWindow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [container, setContainer] = useState<ContainerLog | null>(null);
  const [containerIndex, setContainerIndex] = useState(0);
  const setShouldOpenPodsHealth = useShouldOpenPodsHealthUpdate();
  const metricsAggType = useMetricsDefaultAggregationType();

  const { errorMessages, customMessages, logsSummary, data, fetching } =
    usePodsSummary(opinionatedSummary, container);
  const logs = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pod_logs;
  }, [data]);

  useEffect(() => {
    setContainer(null);
  }, [opinionatedSummary]);

  useEffect(() => {
    if (!logs.length) setShouldOpenPodsHealth && setShouldOpenPodsHealth(true);
  }, [logs, setShouldOpenPodsHealth]);

  useEffect(() => {
    if (
      (!container || !logs.length) &&
      containerIndex < logsSummary?.length &&
      !fetching
    ) {
      if (container !== null) {
        setContainerIndex(containerIndex + 1);
      }

      setContainer(logsSummary?.[containerIndex]);
    }
  }, [container, containerIndex, fetching, logs.length, logsSummary]);

  const isDeletedResource =
    get(resource, "isDeleted") || get(resource, "inactive");

  const pauseMetrics = useMemo(() => {
    return (
      !metricsAvailabilityDrawer ||
      !clusterName ||
      !namespace ||
      !container?.podName ||
      !container?.name
    );
  }, [
    container?.name,
    container?.podName,
    clusterName,
    namespace,
    metricsAvailabilityDrawer,
  ]);

  const isOOMKilled = useMemo(() => {
    return opinionatedSummary.some((summary) =>
      summary.reason.startsWith("OOMKilled")
    );
  }, [opinionatedSummary]);

  const endTimestamp = useMemo(() => {
    return endTime?.getTime() ?? new Date().getTime();
  }, [endTime]);

  const isMetricsSupported = useIsMetricsDrawerSupported(clusterName);

  const podMetrics = usePodMetrics(
    clusterName,
    namespace,
    container?.podName ?? "",
    container?.name ?? "",
    endTimestamp,
    !!isMetricsSupported
  );

  const icon = InfoCircleOutlined24;

  return (
    <>
      <SummaryCard onClick={() => setIsOpen((prev) => !prev)}>
        <SummaryCardLeftSide>
          <ComponentCircle
            fill={palette.darkBlue[500]}
            iconDimensions={{ height: "50%", width: "50%" }}
          >
            {icon({ className: "icon" })}
          </ComponentCircle>
          <BoldGrayText>Event summary</BoldGrayText>
          <Arrow
            width="0.75rem"
            color={gray10}
            direction={isOpen ? "down" : "right"}
          />
        </SummaryCardLeftSide>
      </SummaryCard>
      <SummaryContainer>
        {opinionatedSummary?.length > 0 ? (
          <>
            {!!infoTime && (
              <FlexRows>
                <Label>Start</Label>{" "}
                <MessagesContainer>
                  {format(parseISO(infoTime?.toISOString()))}
                </MessagesContainer>
              </FlexRows>
            )}
            {isOpen && (
              <FlexRows>
                <Label>End</Label>{" "}
                <MessagesContainer>
                  {endTime ? format(parseISO(endTime?.toISOString())) : "--"}
                </MessagesContainer>
              </FlexRows>
            )}
            <FlexRows>
              <Label>Reason</Label>
              <ReasonsContainer>
                {opinionatedSummary?.map((summary, index) => (
                  <ReasonTag
                    key={index}
                    reason={summary.reason}
                    count={summary.count}
                  />
                ))}
              </ReasonsContainer>
            </FlexRows>
            {isOpen && errorMessages.length > 0 && (
              <>
                <FlexRows>
                  <Label>Message</Label>{" "}
                  <MessagesContainer>
                    {errorMessages.map((errorMessage, index) => (
                      <FlexErrorSpan key={index}>
                        {errorMessages.length > 1
                          ? `${index + 1}. ${errorMessage}`
                          : errorMessage}
                      </FlexErrorSpan>
                    ))}
                  </MessagesContainer>
                </FlexRows>
              </>
            )}
            {isOpen && customMessages.length > 0 && (
              <>
                <br />
                <FlexRows>
                  <Label>Explanation</Label>{" "}
                  <MessagesContainer>
                    {customMessages.map((customMessage, index) => (
                      <FlexMessageSpan key={index}>
                        {customMessages.length > 1
                          ? `${index + 1}. ${customMessage}`
                          : customMessage}
                      </FlexMessageSpan>
                    ))}
                  </MessagesContainer>
                </FlexRows>
              </>
            )}
            {shouldShowAction && (
              <>
                <br />
                <FlexRows>
                  <Label>Suggested actions</Label>
                  <ActionsContainer>
                    {shouldShowRollback && resource && (
                      <RollbackButton
                        resource={resource}
                        Button={getButton(
                          AvailableActions.Rollback,
                          isDeletedResource
                        )}
                      />
                    )}
                    {shouldShowChangeResources && resource && (
                      <ConfigureButton
                        resource={resource}
                        Button={getButton(
                          AvailableActions.Configure,
                          isDeletedResource
                        )}
                      />
                    )}
                  </ActionsContainer>
                </FlexRows>
              </>
            )}
            {fetching && logs.length === 0 ? (
              <div>
                <Skeleton height={"1rem"} width={"100%"} />
                <br />
                <Skeleton height={"1rem"} width={"100%"} />
                <br />
                <Skeleton height={"1rem"} width={"100%"} />
                <br />
                <Skeleton height={"1rem"} width={"100%"} />
              </div>
            ) : (
              <>
                {logs.length > 0 && (
                  <ContainersDataContainer>
                    <SpacedRowFieldContainer>
                      {container?.name && (
                        <FlexRows>
                          <Label>Failed Container</Label> {container.name}
                        </FlexRows>
                      )}
                      <Button
                        variant="secondary"
                        size="small"
                        icon={ExternalIcon}
                        onClick={() => {
                          storedTextWrappingEnabled.set("false");
                          setShowLogsExtendWindow(true);
                        }}
                      >
                        Pop Up View
                      </Button>
                    </SpacedRowFieldContainer>
                    {data && (
                      <LogsViewerModal
                        isOpen={showLogsExtendWindow}
                        handleCloseModal={() => setShowLogsExtendWindow(false)}
                        podLogsRes={data.pod_logs}
                        containerName={container?.name ?? ""}
                        logsType={LogsType.Issue}
                        clusterName={clusterName}
                        namespace={namespace}
                        podName={container?.podName ?? ""}
                      />
                    )}
                    <FixedHeightBlackContainer disableTextWrapping>
                      {logs.map(({ logTime, message }) => (
                        <>
                          {padFollowingZerosToLogTime(logTime)}
                          {"  "}
                          <span>{message}</span>
                          <br />
                        </>
                      ))}
                    </FixedHeightBlackContainer>
                  </ContainersDataContainer>
                )}
              </>
            )}
            {isOpen && !pauseMetrics ? (
              <DescribePanel>
                <DescribeExpandedItem
                  title={
                    <div>
                      <MetricsTitle>
                        <Typography
                          variant="uppercase"
                          color={palette.gray[700]}
                        >
                          container metrics
                        </Typography>
                      </MetricsTitle>
                      <Typography variant="text" size={"small"}>
                        View Memory and CPU usage
                      </Typography>
                    </div>
                  }
                  startExpanded={isOOMKilled && !pauseMetrics}
                >
                  <Metrics
                    isMetricsSupported={!!isMetricsSupported}
                    endTimestamp={endTimestamp}
                    metrics={podMetrics}
                    graphType={MetricsGraphType.CONTAINER}
                    aggregationType={metricsAggType}
                  />
                </DescribeExpandedItem>
              </DescribePanel>
            ) : null}
          </>
        ) : failureMessage ? (
          <>
            <WarnInfo>
              <AlertTriangle16 color={palette.pink[500]} />
              <Typography variant="title">
                {failureMessage}.{" "}
                {readyReplicas !== undefined &&
                  `Ready replicas: ${readyReplicas}, `}
                {desiredReplicas !== undefined &&
                  `desired replicas: ${desiredReplicas}`}
              </Typography>
            </WarnInfo>
          </>
        ) : null}
      </SummaryContainer>
    </>
  );
};
