export enum NodeStatuses {
  ready = "Ready",
  notReady = "NotReady",
  diskPressure = "DiskPressure",
  memoryPressure = "MemoryPressure",
}

class NodeStatus {
  private ready: boolean;
  private diskPressure: boolean;
  private memoryPressure: boolean;

  constructor(
    ready: boolean | string,
    diskPressure: boolean | string,
    memoryPressure: boolean | string
  ) {
    this.ready = typeof ready === "boolean" ? ready : ready === "True";
    this.diskPressure =
      typeof diskPressure === "boolean"
        ? diskPressure
        : diskPressure === "True";
    this.memoryPressure =
      typeof memoryPressure === "boolean"
        ? memoryPressure
        : memoryPressure === "True";
  }

  get(): NodeStatuses {
    if (!this.ready) {
      return NodeStatuses.notReady;
    } else if (this.diskPressure) {
      return NodeStatuses.diskPressure;
    } else if (this.memoryPressure) {
      return NodeStatuses.memoryPressure;
    } else {
      return NodeStatuses.ready;
    }
  }
}

export default NodeStatus;
