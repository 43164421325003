import React, { useEffect } from "react";
import styled from "styled-components";

import warning from "../../installation/kubernetes/assets/warning.svg";
import { blackGreyText, gray2, greenForUIText } from "../../../../Colors";
import { textLightStyle, Text } from "../../../common/typography";

import FilledCheck, { iconMargin } from "./assets/filledCheck";

const restrictionsmessage =
  "The cluster name should be up to 40 characters with the following restrictions:";
const validations = [
  { regex: /^[a-z]/, message: "Start with a lowercase letter." },
  {
    regex: /^[a-z0-9.-]+$/,
    message: "Contains lowercase letters, numbers, dots and hyphens only.",
  },
  {
    regex: /^([a-z]([a-z0-9-]*[a-z0-9])?\.)*([a-z]([a-z0-9-]*[a-z0-9])?)$/,
    message: "Must be a valid FQDN (without trailing period).",
  },
  {
    regex: /[a-z0-9]$/,
    message: "End with a number or a lowercase letter.",
  },
];
const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${textLightStyle}
  color: ${gray2};
  gap: 0.5rem;
`;
const MessageContainer = styled.span`
  display: flex;
  align-items: start;
`;
const messageIconStyle = `
  height: 1rem;
  margin: ${iconMargin};
`;
const ValidationMessage = styled.span`
  align-self: center;
`;
export const Warning = styled.img.attrs({ src: warning, alt: "warning" })`
  ${messageIconStyle}
`;

const StyledText = styled(Text)`
  color: ${blackGreyText};
`;

export const maxClusterLength = 40;

const ClusterNameValidation: React.FC<{
  clusterName: string;
  setIsNameValid: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ clusterName, setIsNameValid }) => {
  const validationResults = validations.map((validation) =>
    validation.regex.test(clusterName)
  );
  useEffect(() => {
    setIsNameValid(
      validationResults.every(Boolean) && clusterName.length <= maxClusterLength
    );
  }, [clusterName.length, setIsNameValid, validationResults]);

  return (
    <MessagesContainer>
      <StyledText>{restrictionsmessage}</StyledText>
      {validations.map((validation, index) => (
        <MessageContainer key={index}>
          {clusterName.length === 0 ? (
            <FilledCheck />
          ) : validationResults[index] ? (
            <FilledCheck fillColor={greenForUIText} />
          ) : (
            <Warning />
          )}
          <ValidationMessage>{validation.message}</ValidationMessage>
        </MessageContainer>
      ))}
    </MessagesContainer>
  );
};

export default ClusterNameValidation;
