import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import useHandlePDInstallationResponse, {
  ErrorNames,
} from "./useHandlePDInstallationResponse";

const Container = styled.div``;

const errorNameToMessage = {
  [ErrorNames.AuthorizationError]:
    "Looks like you did not authorize Komodor in Pager Duty. Please install the integration again and grant the required permissions.",
  [ErrorNames.FetchTokenError]:
    "Failed fetching installation information from Pager Duty. Please try again at a later time.",
  [ErrorNames.CombinedError]:
    "Failed saving installation information. Please try again later.",
  [ErrorNames.InstallationIdUnavailableError]:
    "Installation succeeded but failed to fetch past incidents information. New incidents will be logged.",
};

interface ErrorMessageProps {
  message: string;
}
const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <div>
    <div>{message}</div>
    <a href="/main/integration">Back to integrations page</a>
  </div>
);

const PagerDutyInstaller: React.FC = () => {
  const { data, error } = useHandlePDInstallationResponse();
  const errorMessage = useMemo(
    () => ({
      message: error
        ? errorNameToMessage[error.name] ?? "Unknown error has occured."
        : undefined,
    }),
    [error]
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.success) {
      return navigate("/main/integration", { replace: true });
    }
  }, [data, navigate]);

  return (
    <Container>
      {errorMessage.message ? (
        <ErrorMessage message={errorMessage.message} />
      ) : (
        <div>Installing pager duty integration on your account...</div>
      )}
    </Container>
  );
};

export default PagerDutyInstaller;
