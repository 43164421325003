import { Filters, FilterType } from "komodor-types";
import { useCallback, useMemo, useState } from "react";

import { useFetchJobsFiltersMutation } from "../../../generated/graphql";
import { FilterBarCategory } from "../../../shared/components/FilterBar/FilterBar";
import {
  calcServicesLength,
  CheckedFilters,
  createFilterBarCategories,
} from "../../servicesView/pagination/utils";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { usePostKomodorServiceFetchJobsFilters } from "../../../shared/hooks/resources-api/client/komodor-services/usePostKomodorServiceFetchJobsFilters";

const useJobsFilters = (): {
  categories: FilterBarCategory[] | undefined;
  jobsLength: number;
  fetching: boolean;
  updateStaticFilters: (
    checkedFilters: CheckedFilters,
    textFilter: string | null
  ) => Promise<void>;
  updateDynamicFilters: (
    checkedFilters: CheckedFilters,
    textFilter: string | null
  ) => Promise<void>;
} => {
  const [{ fetching }, fetchJobsFilters] = useFetchJobsFiltersMutation();
  const [jobsLength, setJobsLength] = useState(0);
  const [staticCategories, setStaticCategories] =
    useState<FilterBarCategory[]>();
  const [dynamicCategories, setDynamicCategories] =
    useState<FilterBarCategory[]>();

  const { fetchJobsFilterFromResourcesApi } = useOverridableFlags();
  const { mutateAsync: fetchJobFiltersResourcesAPI } =
    usePostKomodorServiceFetchJobsFilters();

  const updateStaticFilters = useCallback(
    async (checkedFilters: CheckedFilters, textFilter: string | null) => {
      const { data: resourcesApiData } = fetchJobsFilterFromResourcesApi
        ? await fetchJobFiltersResourcesAPI({
            komodorServiceFetchJobsFiltersParams: {
              filterType: FilterType.Static,
              checkedFilters,
              textFilter: textFilter ?? "",
            },
          })
        : { data: undefined };

      const { data } = await fetchJobsFilters({
        filterType: FilterType.Static,
        checkedFilters,
        textFilter: textFilter ?? "",
      });
      if (fetchJobsFilterFromResourcesApi) {
        const resourcesApiFilters = resourcesApiData?.Filters as Filters;
        setStaticCategories(
          createFilterBarCategories(resourcesApiFilters, checkedFilters)
        );
        setJobsLength(calcServicesLength(resourcesApiFilters, checkedFilters));
      } else {
        const filters = data?.fetchJobsFilters?.filters as Filters;
        setStaticCategories(createFilterBarCategories(filters, checkedFilters));
        setJobsLength(calcServicesLength(filters, checkedFilters));
      }
    },
    [
      fetchJobsFilters,
      fetchJobFiltersResourcesAPI,
      fetchJobsFilterFromResourcesApi,
    ]
  );

  const updateDynamicFilters = useCallback(
    async (checkedFilters: CheckedFilters, textFilter: string | null) => {
      const { data } = await fetchJobsFilters({
        filterType: FilterType.Dynamic,
        checkedFilters,
        textFilter: textFilter ?? "",
      });
      const filters = data?.fetchJobsFilters?.filters as Filters;
      setDynamicCategories(createFilterBarCategories(filters, checkedFilters));
    },
    [fetchJobsFilters]
  );

  const categories = useMemo(
    () => staticCategories?.concat(dynamicCategories ?? []),
    [dynamicCategories, staticCategories]
  );

  return {
    categories,
    jobsLength,
    fetching,
    updateStaticFilters,
    updateDynamicFilters,
  };
};

export default useJobsFilters;
