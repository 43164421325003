import { OptionType } from "@komodorio/design-system/deprecated";
import { ServiceIdentifierType } from "komodor-types";
import { startCase } from "lodash";

export const toSelectableOption = (string: string): OptionType<string> => ({
  label: string,
  value: string,
});

export const toSelectableServiceIdentifierTypeOption = (
  serviceIdentifierType: ServiceIdentifierType
): OptionType<ServiceIdentifierType> => ({
  label: startCase(serviceIdentifierType),
  value: serviceIdentifierType,
});
