import React from "react";
import { ServiceIdentifierType } from "komodor-types";

import type { ScopingOptions, Predicate } from "../ScopingPredicateConstructor";

import { NamespaceSelector } from "./NamespaceSelector";
import { LabelSelector } from "./LabelSelector";

interface ComparandSelectorProps {
  comparandType: ServiceIdentifierType | undefined;
  scopingOptions: ScopingOptions;
  selectedPredicate: Predicate | undefined;
  onPredicateUpdate: (predicate: Predicate) => void;
}

export const ComparandSelector: React.FC<ComparandSelectorProps> = ({
  comparandType,
  scopingOptions,
  selectedPredicate,
  onPredicateUpdate,
}) => {
  switch (comparandType) {
    case ServiceIdentifierType.NAMESPACE:
      return (
        <NamespaceSelector
          namespaces={scopingOptions.namespaces ?? new Set()}
          selectedNamespace={selectedPredicate?.comparand?.value}
          onNamespaceSelection={(namespace) => {
            const newConfigSelection = {
              ...selectedPredicate,
              comparand: {
                value: namespace,
              },
            };

            onPredicateUpdate(newConfigSelection);
          }}
        />
      );

    case ServiceIdentifierType.LABEL:
      return (
        <LabelSelector
          labels={scopingOptions?.labels ?? {}}
          selectedLabel={selectedPredicate?.comparand ?? {}}
          onLabelSelection={({ key, value }) => {
            const newConfigSelection = {
              ...selectedPredicate,
              comparand: {
                key,
                value,
              },
            };

            onPredicateUpdate(newConfigSelection);
          }}
        />
      );

    default:
      return null;
  }
};
