import styled from "styled-components";
import { filter } from "lodash";
import { parseISO } from "date-fns";
import MuiButton from "@mui/material/Button";
import React from "react";

import { textLightStyle, textStyle } from "../common/typography";
import { gray3, gray5 } from "../../Colors";
import ModalComponent from "../common/Modal";
import useDateFormatter from "../../shared/hooks/useDateFormatter";
import ResponsiveLayout from "../common/ResponsiveLayout";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";

import { KubernetesAgentInfo } from "./types";
import { RenderRestartModal } from "./RenderRestartModal";
import { useAgentsView } from "./hooks";

const Table = styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;
  ${textStyle}
  th {
    ${textLightStyle};
    padding: 0.75rem 0.75rem;
    text-align: start;
    color: #8993a9;
    white-space: nowrap;
    border-bottom: 0.5px solid ${gray5};
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  td {
    padding: 0.75rem;
    border-bottom: 0.5px solid ${gray5};
  }
`;

const StyledRow = styled.tr<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled
      ? `
        color: ${gray3};
        cursor: pointer;
      `
      : `
         background-color: white;
         cursor: pointer;
         &:hover {
           background-color: #ebf3fc;
         }
  `}
`;

const RenderAgentConfiguration: React.FC<{ agent?: KubernetesAgentInfo }> = ({
  agent,
}) => {
  if (!agent) {
    return <b>No configuration available</b>;
  }

  const ignoredResources = filter(
    agent.configuration.resources ?? {},
    (value) => !value
  );

  return (
    <Table>
      <thead>
        <tr>
          <th>Collect History</th>
          <th>Namespaces Watched</th>
          <th>Pod & Logs Enabled</th>
          <th>Agent Task Execution Enabled</th>
          <th>Agent Task Execution V2 Enabled</th>
          <th>Ignored Resources</th>
          <th>Ignored Resource Names</th>
          <th>Ignored Namespaces</th>
          <th>Data Redaction Rules</th>
        </tr>
      </thead>
      <tbody>
        <td>{agent.configuration.collecthistory ? "Yes" : "No"}</td>
        <td>{agent.configuration.watchnamespace}</td>
        <td>{agent.configuration.allowreadingpodlogs ? "Yes" : "No"}</td>
        <td>{agent.configuration.enableagenttaskexecution ? "Yes" : "No"}</td>
        <td>{agent.configuration.enableagenttaskexecutionv2 ? "Yes" : "No"}</td>
        <td>{ignoredResources}</td>
        <td>{agent.configuration.namespacesblacklist}</td>
        <td>{agent.configuration.nameblacklist}</td>
        <td>{agent.configuration.redact}</td>
      </tbody>
    </Table>
  );
};

const AgentRow: React.FC<{
  agent: KubernetesAgentInfo;
  onClick: () => void;
  onRestartAgentClick: () => void;
}> = ({ agent, onClick, onRestartAgentClick }) => {
  const { format } = useDateFormatter();
  const { showRestartAgentButton } = useOverridableFlags();
  return (
    <StyledRow onClick={onClick} disabled={agent.inactive}>
      <td>{agent.inactive ? "No" : "Yes"}</td>
      <td>{agent.clusterName}</td>
      <td>{agent.configuration.appmetadata?.version}</td>
      <td>{agent.configuration.appmetadata?.kubernetesVersion?.gitVersion}</td>
      <td>{agent.id}</td>
      <td>{format(parseISO(agent.createdAt))}</td>
      <td>{format(parseISO(agent.lastSeenAlive))}</td>
      {!!showRestartAgentButton && (
        <td>
          <MuiButton
            onClick={(e) => {
              e.stopPropagation();
              onRestartAgentClick();
            }}
            disabled={agent.inactive}
            variant="text"
          >
            Restart
          </MuiButton>
        </td>
      )}
    </StyledRow>
  );
};

const AgentsView: React.FC = () => {
  const { showRestartAgentButton } = useOverridableFlags();
  const {
    isModalOpen,
    isRestartAgentModalOpen,
    isRestartFailed,
    isExecuting,
    orderedActiveAgents,
    orderedInactiveAgents,
    handleModalClose,
    handleRestartAgentModalClose,
    handleFailure,
    handleExecuting,
    onRestartAgentClick,
    onRowClick,
    selectedAgent,
  } = useAgentsView();
  if (orderedActiveAgents.length === 0) {
    return <b>No agents currently connected</b>;
  }

  return (
    <>
      <ResponsiveLayout>
        <br />
        <br />
        <Table>
          <thead>
            <tr>
              <th>Active</th>
              <th>Cluster Name</th>
              <th>Agent Version</th>
              <th>Kubernetes Version</th>
              <th>Agent ID</th>
              <th>Created At</th>
              <th>Last Heartbeat Received</th>
              {!!showRestartAgentButton && <th>Restart Agent</th>}
            </tr>
          </thead>
          <tbody>
            {orderedActiveAgents.map((agent) => (
              <AgentRow
                agent={agent as KubernetesAgentInfo}
                onClick={() => onRowClick(agent as KubernetesAgentInfo)}
                onRestartAgentClick={() =>
                  onRestartAgentClick(agent as KubernetesAgentInfo)
                }
                key={agent.id}
              />
            ))}

            {orderedInactiveAgents.map((agent) => (
              <AgentRow
                agent={agent as KubernetesAgentInfo}
                onClick={() => onRowClick(agent as KubernetesAgentInfo)}
                onRestartAgentClick={() =>
                  onRestartAgentClick(agent as KubernetesAgentInfo)
                }
                key={agent.id}
              />
            ))}
          </tbody>
          <ModalComponent
            isOpen={isModalOpen}
            title={
              selectedAgent !== undefined
                ? `Agent Configuration: ${selectedAgent.clusterName} (${selectedAgent.id})`
                : ""
            }
            handleCloseModal={handleModalClose}
          >
            <RenderAgentConfiguration agent={selectedAgent} />
          </ModalComponent>
        </Table>
      </ResponsiveLayout>

      <ModalComponent
        isOpen={isRestartAgentModalOpen}
        title={
          selectedAgent !== undefined
            ? `Are you sure you want to restart agent: ${selectedAgent.clusterName} (${selectedAgent.id})`
            : ""
        }
        handleCloseModal={handleRestartAgentModalClose}
      >
        {isRestartFailed && <b>Failed to restart agent</b>}
        {isExecuting && <b>Wait, executing restart</b>}
        {!!selectedAgent && (
          <RenderRestartModal
            agent={selectedAgent}
            handleClose={handleRestartAgentModalClose}
            handleFailure={handleFailure}
            handleExecuting={handleExecuting}
          />
        )}
      </ModalComponent>
    </>
  );
};

export default AgentsView;
