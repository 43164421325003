import React, { useMemo } from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import {
  isMetricsSupportedSelector,
  issueSelector,
  noisyNeighborsNodesMetricsSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import HiddenPodsInfo from "../../../monitorsView/common/HiddenPodsInfo";
import { Metrics } from "../../../Metrics/Metrics";
import {
  useIssueMetricsEndTime,
  useIssueMetricsEvents,
} from "../useCorrelatedMetrics";
import { MetricsGraphType } from "../../../Metrics/types";
import { updateAgentToEnableMetrics } from "../../../common/UpgradeAgentVersionAlert/texts";
import { UpgradeButtonAndModalForUnsupportedClusters } from "../../../../shared/components/UpgradeButtonAndModalForUnsupportedClusters";
import { UpgradeCommands } from "../../../common/upgradeAgentCta/UpgradeAgentCTA";
import { EmptyState } from "../../../common/UpgradeAgentVersionAlert/EmptyState";
import { useScrollTracking } from "../common/useScrollTracking";
import { useDDRumStartViewAndAddTiming } from "../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { MetricsContainer } from "../common/styles";
import { MetricsTitle } from "../common/MetricsTitle";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";
import useResourceWithInterval from "../../../ResourceView/useResource";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import { ExpandableSection } from "./ExpandableSection";

const NodeMemoryCapacityExplanation =
  "The total amount of memory available for a Node.";
const NodeMemoryAllocatableExplanation =
  "The amount of memory that the Node can allocate for Pods (after reserving some for system daemons).";

const AlertContainer = styled.div`
  padding: 1rem;
`;

const ExpandableSectionContainer = styled.div`
  gap: 16px;
`;

const NoisyNeighbors: React.FC = () => {
  const issue = useInvestigationModeStore(issueSelector);
  const output = issue?.results?.noisyNeighbors?.output;
  const podsOutput = output?.noisyPods;
  const nodesInfo = output?.nodesInfo;
  const agentId = useActiveAgent(issue?.cluster ?? "") ?? "";

  const firstNode = nodesInfo?.[0];

  const nodeParams = useMemo(() => {
    return {
      resourceType: "Node",
      resourceName: firstNode?.nodeName ?? "",
      cluster: issue?.cluster ?? "",
      agentId,
    };
  }, [firstNode?.nodeName, issue?.cluster, agentId]);
  const { resource: nodeResource } = useResourceWithInterval(nodeParams);

  const noisyNeighborsNodesMetrics = useInvestigationModeStore(
    noisyNeighborsNodesMetricsSelector
  );
  const victimPods = useMemo(
    () =>
      (podsOutput ?? []).filter(
        (pod) => pod.isVictim && pod.nodeName === firstNode?.nodeName
      ),
    [firstNode?.nodeName, podsOutput]
  );
  const suspectPods = useMemo(
    () =>
      (podsOutput ?? []).filter(
        (pod) => !pod.isVictim && pod.nodeName === firstNode?.nodeName
      ),
    [firstNode?.nodeName, podsOutput]
  );
  const { nextStep, goToNextStep } = useNextStep();
  const isMetricsSupported = useInvestigationModeStore(
    isMetricsSupportedSelector
  );

  const metricsEndTime = useIssueMetricsEndTime(issue);
  const nodeMetrics = noisyNeighborsNodesMetrics?.get(
    firstNode?.nodeName ?? ""
  );
  const issueMetricsEvents = useIssueMetricsEvents(issue?.eventTime);

  const showUpgradeAgentVersionAlert = useMemo(() => {
    return (
      !nodeMetrics &&
      issue?.results?.noisyNeighbors?.output?.isMetricsAllowed === false &&
      isMetricsSupported === false
    );
  }, [
    nodeMetrics,
    issue?.results?.noisyNeighbors?.output?.isMetricsAllowed,
    isMetricsSupported,
  ]);

  const showMetricsWereNotSupportedAlert = useMemo(() => {
    return (
      !nodeMetrics &&
      issue?.results?.noisyNeighbors?.output?.isMetricsAllowed === false &&
      isMetricsSupported
    );
  }, [
    nodeMetrics,
    issue?.results?.noisyNeighbors?.output?.isMetricsAllowed,
    isMetricsSupported,
  ]);

  const showStepContent = useMemo(() => {
    return !showUpgradeAgentVersionAlert && !showMetricsWereNotSupportedAlert;
  }, [showUpgradeAgentVersionAlert, showMetricsWereNotSupportedAlert]);
  const elementRef = useScrollTracking<HTMLDivElement>();

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.investigationModeNoisyNeighbors,
    addTimingParams: {
      enable: !!issue && !!noisyNeighborsNodesMetrics,
    },
  });

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  if (!issue) {
    return null;
  }
  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Check if you had noisy neighbors
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          These are workloads that were running on the same Node and could have
          caused the issue by consuming more resources than intended
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {showUpgradeAgentVersionAlert && (
          <AlertContainer>
            <EmptyState
              title={"Update your agent to access metrics"}
              subtitle={updateAgentToEnableMetrics}
            >
              <UpgradeButtonAndModalForUnsupportedClusters
                unsupportedClusters={[issue?.cluster ?? ""]}
                upgradeCommand={UpgradeCommands.UPGRADE_COMMAND_METRICS}
                desiredFeature="metrics"
              />
            </EmptyState>
          </AlertContainer>
        )}
        {showMetricsWereNotSupportedAlert && (
          <AlertContainer>
            <EmptyState
              title={"No metrics available"}
              subtitle={"Metrics were not supported at the time of the issue"}
            />
          </AlertContainer>
        )}
        {showStepContent && firstNode && (
          <ExpandableSectionContainer>
            <ExpandableSection
              items={victimPods}
              header={
                <Typography variant="h4">
                  Victims ({victimPods.length})
                </Typography>
              }
            />
            <ExpandableSection
              items={suspectPods}
              header={
                <Typography variant="h4">
                  Possible noisy neighbors ({suspectPods.length})
                </Typography>
              }
              startExpanded={true}
            />
          </ExpandableSectionContainer>
        )}
        {firstNode && showStepContent && nodeMetrics && (
          <MetricsContainer>
            <MetricsTitle
              kind="node"
              metadata={[
                {
                  title: "node",
                  value: firstNode.nodeName,
                  onValueClick: nodeResource
                    ? () => {
                        pushDrawer({
                          drawerType: DrawerType.ResourceDrawerByData,
                          ...nodeParams,
                        });
                      }
                    : undefined,
                },
                { title: "Cluster", value: issue.cluster ?? "" },
                {
                  title: "memory capacity",
                  value: `${firstNode.memoryCapacityGiB} GiB`,
                  tooltipTitle: NodeMemoryCapacityExplanation,
                },
                {
                  title: "allocatable memory",
                  value: `${firstNode.memoryAllocatableGiB} GiB`,
                  tooltipTitle: NodeMemoryAllocatableExplanation,
                },
              ]}
            />
            <Metrics
              isMetricsSupported={isMetricsSupported}
              endTimestamp={metricsEndTime}
              events={issueMetricsEvents}
              metrics={nodeMetrics}
              graphType={MetricsGraphType.NODE}
            />
          </MetricsContainer>
        )}
        <HiddenPodsInfo hiddenPodsCount={output?.hiddenPods} />
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={
              AriaLabels.InvestigationMode.NodeEventsStep.SkipStepButton
            }
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};

export default NoisyNeighbors;
