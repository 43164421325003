import React, { useMemo } from "react";
import { Divider, Tabs } from "@komodorio/design-system/deprecated";

import ResponsiveLayout from "../common/ResponsiveLayout";
import { useDrawerUrlState } from "../../shared/hooks/state";
import { SELECTED_TAB_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";

import { ResourceProvider } from "./ResourceProvider";
import { Container, WhiteContainer, Content } from "./common/resourceUtils";
import Resource from "./resources";

interface ResourceViewProps {
  resource: Resource;
  leftHeaderActions?: JSX.Element;
}
const ResourceView: React.FC<ResourceViewProps> = (props) => {
  return (
    <ResourceProvider resource={props.resource}>
      <ResourceViewContent {...props} />
    </ResourceProvider>
  );
};
const ResourceViewContent: React.FC<ResourceViewProps> = ({
  resource,
  leftHeaderActions,
}) => {
  const { drawerTabs, defaultTab } = resource;

  const [selectedTab, setSelectedTab] = useDrawerUrlState<string>(
    SELECTED_TAB_PARAM_KEY
  );

  const selectedTabIndex = useMemo(() => {
    const index = drawerTabs.findIndex((tab) => tab.label === selectedTab) || 0;

    return index < 0 ? defaultTab : index;
  }, [defaultTab, drawerTabs, selectedTab]);

  return (
    <ResourceProvider resource={resource}>
      <Container>
        <WhiteContainer>
          <ResponsiveLayout>
            {resource.renderDrawerHeader(leftHeaderActions)}
          </ResponsiveLayout>
          {resource.renderDrawerMessage?.()}
          <ResponsiveLayout>
            <Tabs
              tabs={drawerTabs}
              value={selectedTabIndex}
              onChange={(selectedTabIndex) => {
                setSelectedTab(drawerTabs[selectedTabIndex].label);
              }}
            />
          </ResponsiveLayout>
        </WhiteContainer>
        <Divider />
        <Content>
          <br />
          <ResponsiveLayout>
            {resource.renderDrawerContent(selectedTabIndex)}
          </ResponsiveLayout>
        </Content>
      </Container>
    </ResourceProvider>
  );
};

export default ResourceView;
