import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import Autocomplete from "../../common/controls/Autocomplete";
import {
  InspectionFilterContainer,
  InspectionFilterDiv,
} from "../../Inspection/filters/InspectionResourceListSearch";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";

const allRuleTypes = [
  { label: "Availability", value: "availability" },
  { label: "OOMKilled", value: "OOMKilled" },
  { label: "PVC", value: "PVC" },
  { label: "Service", value: "service" },
  { label: "Node", value: "node" },
  { label: "Job", value: "job" },
  { label: "CronJob", value: "cronJob" },
  { label: "Deploy", value: "deploy" },
  { label: "Pod", value: "pod" },
  { label: "Workflow", value: "workflow" },
];

const MonitorsListRuleTypeFilter: React.FC<{
  ruleTypes: string[];
  ruleType: string | undefined;
  setRuleType: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ ruleTypes, ruleType, setRuleType }) => {
  const { control, watch } = useForm();
  const watchRuleType = watch("ruleType", ruleType);
  const suggestions = useMemo(() => {
    return [
      { label: "ALL", value: "" },
      ...ruleTypes.map((c) => ({
        label: allRuleTypes.filter((a) => a.value === c)[0].label,
        value: c,
      })),
    ];
  }, [ruleTypes]);

  return (
    <InspectionFilterContainer>
      <InspectionFilterDiv>Rule Type</InspectionFilterDiv>
      <Controller
        name="ruleType"
        control={control}
        defaultValue={watchRuleType}
        render={() => (
          <Autocomplete
            fieldname="ruleType"
            placeholder="choose rule type"
            defaultSelected={suggestions[0]}
            suggestions={suggestions}
            onSelectedChange={(value) => {
              if (value !== ruleType) {
                setRuleType(value);
                dispatchEvent(AnalyticEvents.MonitorsView.Filter_Selected, {
                  filterCategory: "ruleType",
                  filterOption: value,
                });
              }
            }}
          />
        )}
      />
    </InspectionFilterContainer>
  );
};

export default MonitorsListRuleTypeFilter;
