import { useEffect, useState } from "react";

const useFollowingState = <T>(original: T): T => {
  const [following, setFollowing] = useState(original);
  useEffect(() => {
    setFollowing(original);
  }, [original]);
  return following;
};

export default useFollowingState;
