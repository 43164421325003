import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { ResourceTableModelRow } from "komodor-types";
import { palette } from "@komodorio/design-system";

import { blueForUI } from "../../../Colors";
import { CurrentService } from "../../../shared/hooks/useCurrentService";
import { getStatusGroup, StatusGroup, StatusGroups } from "../statusMapper";
import Checkbox from "../controls/Checkbox";
import { KubernetesResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import EventGroup from "../EventGroup";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { findColumnProperties, Properties } from "./TableProperties";
import ResourceListTableDataCell from "./ResourceListTableDataCell";
import { useSelectedRowsContext } from "./SelectedRowsProvider/SelectedRowsProvider";
import { LastCell } from "./ResourceListTableDataCell/LastCell";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { IsDeletedPodField } from "./ResourceListTableBody";

const StyledTr = styled.tr<{ clickable?: boolean }>`
  td {
    background-color: white;
  }
  cursor: ${({ clickable = true }) => (clickable ? "pointer" : "not-allowed")};
  &.deleted td {
    background-color: ${palette.gray[50]};
  }
  &:hover td {
    background: ${blueForUI};
  }
`;

// TODO: change result type to boolean
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const checkIsDeletedPod = (
  resourceNameInK8S: string,
  statusGroup: StatusGroup
): string =>
  resourceNameInK8S === "pods" && statusGroup === StatusGroups.neutral
    ? "deleted"
    : "";

interface ResourceListTableRowProps {
  headers: string[];
  resourceType: KubernetesResource;
  row: ResourceTableModelRow;
  id: string;
  properties: Properties;
  clusterName: string;
  onRowClick: (r: { [key: string]: string }) => void;
  agentId: string | undefined;
  currentService: CurrentService;
  refreshResults: () => void;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  includeActionsCell?: boolean;
}

export const ResourceListTableRow: React.FC<ResourceListTableRowProps> = ({
  headers,
  resourceType,
  row,
  id,
  properties,
  clusterName,
  onRowClick,
  agentId,
  refreshResults,
  setCorrelatedEvent,
  includeActionsCell = true,
}) => {
  const isDeletedPod = useMemo(() => {
    return checkIsDeletedPod(
      resourceType.NameInK8S,
      getStatusGroup(row?.status, "pods")
    );
  }, [resourceType.NameInK8S, row?.status]);

  useEffect(() => {
    if (isDeletedPod) {
      row[IsDeletedPodField] = "true";
    }
  }, [isDeletedPod, row]);

  const rowSelection = useSelectedRowsContext();
  const rowSelected = useMemo(
    () => rowSelection?.selectedRowNames.includes(row.uid),
    [row.uid, rowSelection?.selectedRowNames]
  );
  const onRowSelection = rowSelection?.onRowSelection;

  const rowSelectionDisabled = useMemo(
    () => rowSelection?.unselectableRowNames?.includes(row.uid) ?? false,
    [row.uid, rowSelection?.unselectableRowNames]
  );
  return (
    <StyledTr
      aria-label={AriaLabels.Inspection.ResourceListTable.ResourceListRow}
      data-e2e-selector={"table-inspection-resource-row"}
      key={id}
      onClick={() => {
        if (!rowSelectionDisabled) onRowClick(row);
      }}
      className={isDeletedPod}
      clickable={!rowSelectionDisabled}
    >
      {headers.map((h, i) =>
        h === "checkbox" ? (
          <td key={`checkbox:${id}`} style={{ width: 0 }}>
            <Checkbox
              aria-label="table-resource-row-checkbox"
              id={`${row.uid}-checkbox`}
              fieldName={""}
              checked={rowSelected ?? false}
              handleCheckboxChange={() => onRowSelection?.(row.uid)}
              disabled={rowSelectionDisabled}
              rightMargin="2px"
            />
          </td>
        ) : (
          <ResourceListTableDataCell
            cellName={h}
            resourceType={resourceType}
            resourceName={row?.name ?? ""}
            cellData={row[h] ?? ""}
            key={`${id}:${i}`}
            rowId={id}
            properties={findColumnProperties(properties.columns, h)}
            clusterName={clusterName}
            namespace={row.namespace}
            setCorrelatedEvent={setCorrelatedEvent}
            controlledByRoot={row.controlledByRoot}
            row={row}
          />
        )
      )}
      <LastCell
        agentId={agentId}
        clusterName={clusterName}
        includeActionsCell={includeActionsCell}
        isDeletedPod={isDeletedPod}
        refreshResults={refreshResults}
        resourceType={resourceType}
        row={row}
      />
    </StyledTr>
  );
};
