import { ActionTypes, HelmChartSummary, TaskType } from "komodor-types";
import { useMemo, useState } from "react";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";

import { HelmChartsResponse } from "./types";
import { convertHelmChartsAgentDataToHelmChartsSummary } from "./utils";

export const useHelmChartsFromAgent = (
  cluster: string,
  agentId: string
): HelmChartsResponse<HelmChartSummary> => {
  const [errorMessage, setErrorMessage] = useState("");

  const metadata = useMemo(() => {
    return {
      cluster: cluster,
      type: ActionTypes.HelmGetCharts,
    };
  }, [cluster]);

  const {
    execute: getHelmCharts,
    failureMessage: helmAgentTaskFailureMessage,
    result,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  const data = useMemo(() => {
    if (helmAgentTaskFailureMessage) {
      setErrorMessage(helmAgentTaskFailureMessage);
      return;
    }
    if (!result) return;
    return convertHelmChartsAgentDataToHelmChartsSummary(
      result,
      cluster,
      setErrorMessage
    );
  }, [cluster, helmAgentTaskFailureMessage, result]);

  return {
    execute: getHelmCharts,
    errorMessage: errorMessage,
    isFetching: isFetching,
    data,
  };
};
