import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { AppViewUrlParam } from "../../../types/urlParams";
import { CurrentAppView } from "../../../types/CurrentAppView";
import { useCurrentAppViewContext } from "../../../context/currentAppViewContext";
import WorkflowIssueEventGroup, {
  WorkflowIssueStatus,
} from "../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WorkflowConfigType } from "../../../../monitorsView/common/types";
import {
  useImpactfulWorkflowIssuesByTypeQuery,
  useOpenImpactfulWorkflowIssuesByTypesQuery,
} from "../../../../../generated/graphql";
import Selected from "../../../../../shared/components/FilterBar/Interfaces/Selected";
import { IndependentFilter } from "../../../../eventsView/FilterBarUtils/eventsViewFiltersSelectors";
import useDefaultClusterForInspection from "../../../../Inspection/utils/useDefaultClusterForInspection";
import { getRoutePathFromKubernetesResource } from "../../../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import { ResourceType } from "../types/appViewsResourcesTypes";
import { JOBS, SERVICES } from "../../../../routes/routes";
import {
  getServiceFiltersSearchParams,
  getUnhealthySearchParamsForResource,
  isJobType,
  isServiceType,
} from "../utils/resourceHealthStatus";
import { getSortedGroupWorkflowIssueEvents } from "../utils/groupWorkflowIssueEvents";
import { getK8sResourceFromName } from "../utils/getK8sResource";
import { WORKFLOW_CONFIG_TYPES } from "../../../../common/EventGroup/workflowIssues/constants";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  errorStateSelector,
  loadingStateSelector,
  serviceIdsSelector,
  setDataStateSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { generatePathFromFilters } from "../utils/generatePathFromFilters";

export const isAppViewDataComplete = (appView?: CurrentAppView): boolean =>
  !!(appView && appView.id && appView.serviceIdentifiers && appView.name);

export const useIsCurrentAppViewDataComplete = (id?: string): boolean => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  if (!id || !currentAppView) return false;
  return currentAppView.id === id && isAppViewDataComplete(currentAppView);
};

export const useGetAppViewData = (): (() => void) => {
  const setDataState = useAppViewsStore(setDataStateSelector);
  const loadingState = useAppViewsStore(loadingStateSelector);
  const errorState = useAppViewsStore(errorStateSelector);

  const { id: currentAppViewId } = useParams<AppViewUrlParam>();
  const isDataComplete = useIsCurrentAppViewDataComplete(currentAppViewId);

  return useCallback(() => {
    if (isDataComplete) return;
    if (errorState || !currentAppViewId) {
      setDataState({ error: true });
    } else if (loadingState) {
      setDataState({ loading: true });
    }
  }, [
    isDataComplete,
    errorState,
    currentAppViewId,
    loadingState,
    setDataState,
  ]);
};

export const useResetStateOnUnmountEffect = (): void => {
  const setDataState = useAppViewsStore(setDataStateSelector);

  useEffect(() => {
    return () => {
      setDataState({
        error: false,
        loading: false,
      });
    };
  }, [setDataState]);
};

export const useWorkflowIssuesEvents = (): {
  eventGroups?: WorkflowIssueEventGroup[];
  fetching: boolean;
} => {
  const serviceIds = useAppViewsStore(serviceIdsSelector);
  const {
    timeWindow: { start, end },
  } = useCurrentAppViewContext();

  const variables = useMemo(() => {
    return {
      from: start.toISOString(),
      to: end.toISOString(),
      type: WorkflowConfigType.Availability.toString(),
      services: serviceIds,
    };
  }, [start, end, serviceIds]);

  const [{ data, fetching }] = useImpactfulWorkflowIssuesByTypeQuery({
    variables,
    pause: !serviceIds.length,
  });

  const eventGroups = useMemo<WorkflowIssueEventGroup[]>(() => {
    if (!data?.workflow_results.length) return [];
    return getSortedGroupWorkflowIssueEvents(data).filter(
      (event) => event.endTime.getTime() >= start.getTime()
    );
  }, [data, start]);

  return { eventGroups, fetching };
};

export const useOpenWorkflowIssues = (): {
  fetching: boolean;
  eventGroups: WorkflowIssueEventGroup[];
} => {
  const serviceIds = useAppViewsStore(serviceIdsSelector);
  const variables = useMemo(() => {
    return {
      type: WorkflowConfigType.Availability.toString(),
      services: serviceIds,
    };
  }, [serviceIds]);
  const [{ fetching, data }] = useOpenImpactfulWorkflowIssuesByTypesQuery({
    variables,
    pause: !serviceIds.length,
  });

  const eventGroups = useMemo<WorkflowIssueEventGroup[]>(() => {
    if (!data?.workflow_results.length) return [];
    return getSortedGroupWorkflowIssueEvents(data).filter(
      (event) => event.status === WorkflowIssueStatus.open
    );
  }, [data]);

  return {
    eventGroups,
    fetching,
  };
};

const useGeneratePathFromFilters = (): ((filters: Selected) => string) => {
  const { timeWindow } = useCurrentAppViewContext();
  return useCallback(
    (filters: Selected) => generatePathFromFilters({ filters, timeWindow }),
    [timeWindow]
  );
};

export const availabilityEventsFilters: Selected = {
  [IndependentFilter.EventTypes]: Object.fromEntries(
    [WorkflowConfigType.Availability].map((issueType) => [
      WORKFLOW_CONFIG_TYPES[issueType],
      true,
    ])
  ),
};

export const useOpenIssueEventsPath = (): string => {
  const generatePathFromFilters = useGeneratePathFromFilters();
  return useMemo(
    () => generatePathFromFilters(availabilityEventsFilters),
    [generatePathFromFilters]
  );
};

export const useDeployEventsPath = (): string => {
  const generatePathFromFilters = useGeneratePathFromFilters();
  return useMemo(
    () =>
      generatePathFromFilters({
        [IndependentFilter.EventTypes]: { Deploy: true },
      }),
    [generatePathFromFilters]
  );
};

export const useGetRouteForResourceType = (): ((
  type: ResourceType,
  hasUnhealthyResources?: boolean
) => string | undefined) => {
  const cluster = useDefaultClusterForInspection();

  return useCallback(
    (type: ResourceType, hasUnhealthyResources?: boolean) => {
      const kubernetesResource = getK8sResourceFromName(type);
      const isService = isServiceType(type);
      const isJob = isJobType(type);
      if (isService || isJob) {
        const mainPath = isService ? SERVICES : JOBS;
        return `/${mainPath}?${getServiceFiltersSearchParams({
          type,
          kubernetesResource,
          isUnhealthy: hasUnhealthyResources,
        })}`;
      }

      if (!cluster) return undefined;

      if (!kubernetesResource) return undefined;
      const resourcePath = getRoutePathFromKubernetesResource(
        kubernetesResource,
        cluster
      );

      return !hasUnhealthyResources
        ? resourcePath
        : `${resourcePath}?${getUnhealthySearchParamsForResource(type)}`;
    },
    [cluster]
  );
};
