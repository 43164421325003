import React, { ComponentProps, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import YAML from "yaml";
import { useQuery } from "@tanstack/react-query";

import { gray10 } from "../../Colors";
import { H4 } from "../common/typography";
import Autocomplete from "../common/controls/Autocomplete";
import { EventsApiApiV1DeploysEventsSearchGetRequest } from "../../generated/resourcesApi";
import { buildKomodorUid } from "../../shared/hooks/resources-api/resourcesAPIUtils";
import { fetchLatestDeployUntil } from "../../shared/hooks/resources-api/client/events/useSearchDeployEvents";
import { DEPLOY_EVENTS_SEARCH } from "../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesApiClient } from "../../shared/hooks/resources-api/client/apiClient";

import { Container, Flexbox as _Flexbox } from "./common";
import type { YamlFile } from "./common/types";

export const Label = styled(H4)`
  color: ${gray10};
`;

const Flexbox = styled(_Flexbox)`
  width: inherit;
  height: 100%;
`;

interface ResourceSelectorProps {
  clusters: string[] | undefined;
  namespaces: string[] | undefined;
  selectedCluster: string | undefined;
  selectedNamespace: string | undefined;
  selectedServiceName: string | undefined;
  selectedKind: string | undefined;
  expectDefaultValue: boolean;
  onClusterSelection: (cluster: string) => void;
  onNamespaceSelection: (namespace: string) => void;
  onServiceConfigFetch: (yaml: YamlFile) => void;
  autoselect?: boolean;
}

const ResourceSelector: React.FC<ResourceSelectorProps> = ({
  clusters,
  namespaces,
  selectedCluster,
  selectedNamespace,
  selectedServiceName,
  selectedKind,
  expectDefaultValue,
  onClusterSelection,
  onNamespaceSelection,
  onServiceConfigFetch: addYaml,
  autoselect = false,
}) => {
  const clustersAsAutocompleteSuggestions = useMemo(
    () => (clusters ?? []).map((cluster) => new Suggestion(cluster)),
    [clusters]
  );

  const namespacesAsAutocompleteSuggestions = useMemo(
    () => (namespaces ?? []).map((namespace) => new Suggestion(namespace)),
    [namespaces]
  );

  const params: EventsApiApiV1DeploysEventsSearchGetRequest | null =
    useMemo(() => {
      if (
        !selectedCluster ||
        !selectedNamespace ||
        !selectedServiceName ||
        !selectedKind
      )
        return null;
      return {
        komodorUids: [
          buildKomodorUid({
            clusterName: selectedCluster,
            namespace: selectedNamespace,
            resourceName: selectedServiceName,
            kind: selectedKind,
          }),
        ],
        order: "DESC",
        limit: 1,
        fields: ["id", "newSpec", "deploymentName"],
      };
    }, [selectedCluster, selectedKind, selectedNamespace, selectedServiceName]);
  const apiClient = useResourcesApiClient();
  const { data } = useQuery([DEPLOY_EVENTS_SEARCH, params], () =>
    fetchLatestDeployUntil(apiClient, params)
  );

  const latestStoredDeploymentConfig = useRef<string | undefined>();

  // If there is only one cluster, and `autoselect` is true, select the only cluster by default.
  useEffect(() => {
    if (autoselect && clusters?.length === 1) {
      onClusterSelection(clusters[0]);
    }
  }, [autoselect, clusters, onClusterSelection]);

  // If there is already a selected cluster, and there is only one namespace in that cluster, select the namespace by default - provided that `autoselect` is true.
  useEffect(() => {
    if (autoselect && !!selectedCluster && namespaces?.length === 1) {
      onNamespaceSelection(namespaces[0]);
    }
  }, [autoselect, namespaces, selectedCluster, onNamespaceSelection]);

  useEffect(() => {
    const newSpec =
      data?.data?.[0]?.newSpec &&
      JSON.parse(data?.data?.[0]?.newSpec as unknown as string);
    const latestDeploymentConfig = newSpec ? YAML.stringify(newSpec) : "";

    if (latestStoredDeploymentConfig.current === latestDeploymentConfig) return;

    latestStoredDeploymentConfig.current = latestDeploymentConfig;

    const newYaml = {
      name: data?.data?.[0]?.deploymentName ?? "",
      content: latestStoredDeploymentConfig.current,
    };

    if (selectedNamespace?.length) addYaml(newYaml);
  }, [selectedNamespace, addYaml, data?.data]);

  return (
    <Container style={{ height: "4.3rem" }}>
      <Flexbox
        justifyContent="space-between"
        gap="1.3rem"
        style={{ width: "inherit", height: "100%" }}
      >
        <Flexbox column justifyContent="space-around" style={{ flexGrow: 1 }}>
          <Label>{`Cluster (${clusters?.length ?? 0})`}</Label>

          {(!expectDefaultValue || !!selectedCluster) && (
            <Autocomplete
              fieldname="cluster"
              defaultSelected={
                selectedCluster ? new Suggestion(selectedCluster) : undefined
              }
              suggestions={clustersAsAutocompleteSuggestions}
              placeholder="select"
              onSelectedChange={onClusterSelection}
            />
          )}
        </Flexbox>

        <Flexbox column justifyContent="space-around" style={{ flexGrow: 1 }}>
          <Label>{`Namespace (${namespaces?.length ?? 0})`}</Label>

          {(!expectDefaultValue || !!selectedNamespace) && (
            <Autocomplete
              fieldname="namespace"
              defaultSelected={
                selectedNamespace
                  ? new Suggestion(selectedNamespace)
                  : undefined
              }
              suggestions={namespacesAsAutocompleteSuggestions}
              placeholder="select"
              onSelectedChange={onNamespaceSelection}
              disabled={!selectedCluster}
            />
          )}
        </Flexbox>
      </Flexbox>
    </Container>
  );
};

type SuggestionType = NonNullable<
  ComponentProps<typeof Autocomplete<string>>["defaultSelected"]
>;

class Suggestion implements SuggestionType {
  private _label: string;
  private _value: string;

  constructor(string: string) {
    this._label = string;
    this._value = string;
  }

  public get label() {
    return this._label;
  }

  public get value() {
    return this._value;
  }
}

export default ResourceSelector;
