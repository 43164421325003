import React, { useMemo } from "react";
import { ServiceIdentifierType } from "komodor-types";

import { useIsEditMode } from "../../hooks/creationPageHooks";
import { useCreationPageContext } from "../../context/CreationPageContext";
import { currentAppViewSelector } from "../../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsStore } from "../../../../../../../../shared/store/appViewsStore/appViewsStore";

import {
  ScopingPredicateConstructor,
  Predicate,
  constructPredicateFrom,
} from "./ScopingPredicateConstructor/ScopingPredicateConstructor";
import { useGetAggregatedServicesScopes } from "./appViewScopingHooks";

export const AppViewScopeHandler: React.FC = () => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const { setAppViewType, setServiceIdentifiers } = useCreationPageContext();
  const isEditMode = useIsEditMode();
  const aggregatedServicesScopes = useGetAggregatedServicesScopes();

  const existingScopingPredicate = useMemo<Predicate | undefined>(() => {
    if (
      !isEditMode ||
      !currentAppView?.serviceIdentifierType ||
      !currentAppView?.serviceIdentifiers?.length ||
      currentAppView.serviceIdentifierType === ServiceIdentifierType.SERVICE_ID
    )
      return undefined;

    const predicate = constructPredicateFrom(
      //temp solution, could be key/value pair, or multiple entries when we support MultiSelect
      currentAppView.serviceIdentifiers[0],
      currentAppView.serviceIdentifierType
    );

    return predicate;
  }, [
    currentAppView.serviceIdentifiers,
    currentAppView.serviceIdentifierType,
    isEditMode,
  ]);

  const handlePredicateUpdate = (updatedPredicate: Predicate) => {
    if (updatedPredicate.serviceIdentifierType) {
      setAppViewType(updatedPredicate.serviceIdentifierType);
    }

    switch (updatedPredicate.serviceIdentifierType) {
      case ServiceIdentifierType.NAMESPACE:
        setServiceIdentifiers(
          updatedPredicate.comparand.value
            ? [updatedPredicate.comparand.value]
            : []
        );
        break;

      case ServiceIdentifierType.LABEL:
        setServiceIdentifiers(
          updatedPredicate.comparand.key && updatedPredicate.comparand.value
            ? [
                JSON.stringify({
                  [updatedPredicate.comparand.key]:
                    updatedPredicate.comparand.value,
                }),
              ]
            : []
        );
        break;

      default:
        setServiceIdentifiers([]);
        return;
    }
  };

  if (!aggregatedServicesScopes) return null;

  return (
    <ScopingPredicateConstructor
      scopingOptions={aggregatedServicesScopes}
      onPredicateUpdate={handlePredicateUpdate}
      existingPredicate={existingScopingPredicate}
    />
  );
};
