import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomEventDetailsProps } from "../CustomEventDetails";
import { SectionContainer } from "../styles";
import { MAX_COLUMN_WIDTH } from "../constants";

import { Detail } from "./Detail";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: fit-content(${MAX_COLUMN_WIDTH}px) auto;
  gap: 1rem;
`;

export const DetailsSection: React.FC<CustomEventDetailsProps> = ({
  event: { eventDetails },
}) => {
  if (!eventDetails) return null;
  const content = Object.entries(eventDetails).map(([key, value]) => (
    <Detail key={key} keyStr={key} value={value} />
  ));

  return (
    <SectionContainer aria-label={"custom event details"}>
      <Typography color={palette.gray[500]} size={"small"} bold>
        DETAILS
      </Typography>
      <GridContainer>{content}</GridContainer>
    </SectionContainer>
  );
};
