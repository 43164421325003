import useDataForFlag from "../../../../shared/hooks/useDataForFlag";
import { TimeWindow } from "../../../../shared/types/TimeWindow";
import { ConfigMapEventsResponseDataInner } from "../../../../generated/resourcesApi";
import { useConfigMapEvents } from "../../../../shared/hooks/resources-api/client/events/configMapEvents/useConfigMapEvents";

export const useProccessedConfigMapEvents = (
  showConfigMapAndSecrets: unknown,
  timeWindow: Pick<TimeWindow, "start" | "end">
): ConfigMapEventsResponseDataInner[] | null => {
  const { data } = useConfigMapEvents({
    fromEpoch: timeWindow.start.getTime().toString(),
    toEpoch: timeWindow.end.getTime().toString(),
    fields: [
      "id",
      "configMapName",
      "namespace",
      "clusterName",
      "eventTime",
      "k8sDefault",
      "services",
      "action",
    ],
    enabled: showConfigMapAndSecrets === true,
  });

  return useDataForFlag(showConfigMapAndSecrets, data?.data);
};
