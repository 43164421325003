import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";

import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useUpdateAccountFeatures } from "@/shared/hooks/accounts-service/client/features/useUpdateFeatures";
import { AriaLabels } from "@/shared/config/ariaLabels";
import { PlanContainer } from "@/components/Settings/styles";
import { RbacClusterSyncFeatureInfo } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/FeatureInfo";
import { RbacClusterSyncFeatureControls } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/FeatureControls";
import { ConsentFormDialog } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/ConsentFormDialog";
import { DisableFormDialog } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/DisableFormDialog";
import { ConfigureFormDialog } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/ConfigureFormDialog";

const featureName = "rbacClusterSync";
const notificationDuration = 5000;
const formatNotificationMessage = (enabled: boolean, success: boolean) => {
  if (success) {
    return `Successfully ${
      enabled ? "enabled" : "disabled"
    } the RBAC Cluster Sync feature!`;
  }
  return `Failed to ${
    enabled ? "enable" : "disable"
  } the RBAC Cluster Sync feature, please try again or contact our support.`;
};

export const RbacClusterSync: React.FC = () => {
  const closeResetTimeoutMs = 250; // animation duration for the Modal Dialog is 225ms, being close to it will prevent flickering
  const [configureModalRenderKey, setConfigureModalRenderKey] = useState(0);

  const { data: features, refresh: refreshFeatures } = useAccountFeatures();
  const [isEnableModalOpen, setIsEnableModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [isConfigureModalOpen, setIsConfigureModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const featureEnabled = features[featureName];

  const { isLoading, mutateAsync } = useUpdateAccountFeatures({
    features: {
      ...features,
      ...{ [featureName]: !featureEnabled as unknown as object },
    },
  });

  const updateAccountFeaturesAsync = async () => {
    try {
      await mutateAsync();
      setIsEnableModalOpen(false);
      setIsDisableModalOpen(false);

      setSnackbarMessage(formatNotificationMessage(!featureEnabled, true));
      setSnackbarOpen(true);

      refreshFeatures();
    } catch (err) {
      setSnackbarMessage(formatNotificationMessage(!featureEnabled, false));
      setSnackbarOpen(true);
    }
  };

  const handleEnableClose = () => {
    setIsEnableModalOpen(false);
  };

  const handleEnableClick = () => {
    setIsEnableModalOpen(true);
  };

  const handleEnable = () => {
    updateAccountFeaturesAsync();
  };

  const handleDisableClick = () => {
    setIsDisableModalOpen(true);
  };

  const handleDisable = () => {
    updateAccountFeaturesAsync();
  };

  const handleDisableClose = () => {
    setIsDisableModalOpen(false);
  };

  const handleConfigureClick = () => {
    setIsConfigureModalOpen(true);
  };

  const handleConfigureClose = () => {
    setIsConfigureModalOpen(false);
    // This is a workaround to reset the form state when the dialog is closed
    setTimeout(() => {
      setConfigureModalRenderKey(configureModalRenderKey + 1);
    }, closeResetTimeoutMs);
  };

  return (
    <PlanContainer
      aria-label={
        AriaLabels.SettingsPage.AccountProfile.BetaFeatures.RbacClusterSync
      }
    >
      <RbacClusterSyncFeatureInfo />
      <RbacClusterSyncFeatureControls
        featureEnabled={featureEnabled}
        handleDisableClick={handleDisableClick}
        handleEnableClick={handleEnableClick}
        handleConfigureClick={handleConfigureClick}
      />

      <ConsentFormDialog
        open={isEnableModalOpen}
        isLoading={isLoading}
        onClose={handleEnableClose}
        onEnable={handleEnable}
      />
      <DisableFormDialog
        open={isDisableModalOpen}
        isLoading={isLoading}
        onClose={handleDisableClose}
        onDisable={handleDisable}
      />
      <ConfigureFormDialog
        key={configureModalRenderKey}
        open={isConfigureModalOpen}
        onClose={handleConfigureClose}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={notificationDuration}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={snackbarMessage}
      />
    </PlanContainer>
  );
};
