import React from "react";
import styled from "styled-components";

import { blackGreyText, gray9, lightPink, pinkBrand } from "../../../Colors";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum Status {
  Open = "Open",
  Closed = "Closed",
}

const StatusTag = styled.div<{ large?: boolean; status: Status }>`
  display: flex;
  font-weight: bold;
  font-style: normal;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  width: fit-content;
  height: ${({ large }) => (large ? "2rem" : "fit-content")};
  width: ${({ large }) => (large ? "3.375rem" : "fit-content")};
  background: ${({ status }) => (status === Status.Open ? lightPink : gray9)};
  border-radius: 0.25rem;
  font-size: ${({ large }) => (large ? "1rem" : "0.625rem")};
  line-height: 1rem;
  text-transform: uppercase;
  color: ${({ status }) =>
    status === Status.Open ? pinkBrand : blackGreyText};
`;

export const OpenTag: React.FC<{ large?: boolean }> = ({ large }) => (
  <StatusTag large={large} status={Status.Open}>
    Open
  </StatusTag>
);
export const ClosedTag: React.FC<{ large?: boolean }> = ({ large }) => (
  <StatusTag large={large} status={Status.Closed}>
    Closed
  </StatusTag>
);
