import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "../../common/controls/Autocomplete";
import useNamespacesAtm from "../../../shared/hooks/ATM/useNamespacesAtm";

export const NamespaceSelector: React.FC<{
  selectedNamespace: string | undefined;
  cluster: string;
  agentId: string;
  onChange: (namespace: string) => void;
}> = ({ selectedNamespace, cluster, agentId, onChange: selectNamespace }) => {
  const { control, watch } = useForm();
  const watchnamespace = watch("namespace", selectedNamespace ?? null);
  const [suggestions, setSuggestions] = useState<
    { label: string; value: string }[]
  >([]);

  const { result: namespacesData } = useNamespacesAtm(agentId, cluster ?? "");
  const latestSuggestions = useMemo(
    () =>
      namespacesData?.data.map((data) => ({
        value: data.name,
        label: data.name,
      })) ?? [],
    [namespacesData?.data]
  );

  useEffect(() => {
    if (!isEqual(suggestions, latestSuggestions)) {
      setSuggestions(latestSuggestions);
    }
  }, [latestSuggestions, suggestions]);

  return suggestions.length ? (
    <Controller
      name="namespace"
      control={control}
      defaultValue={watchnamespace}
      render={() => (
        <Autocomplete
          fieldname="namespace"
          placeholder="choose namespace"
          defaultSelected={
            watchnamespace
              ? { label: watchnamespace, value: watchnamespace }
              : suggestions.at(0)
          }
          suggestions={suggestions}
          onSelectedChange={selectNamespace}
        />
      )}
    />
  ) : (
    <></>
  );
};
