import styled, { css } from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 8px;
  }
`;

const totalRowStyle = css`
  font-weight: bold;
`;

export const Container = styled.div`
  & {
    height: 400px;
    width: fit-content;

    .total-row {
      ${totalRowStyle}
    }

    .bold-column {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;
