import React, { useMemo } from "react";
import styled from "styled-components";
import { IconButton, Typography } from "@komodorio/design-system/deprecated";
import { Close24 } from "@komodorio/design-system/icons";
import { formatDistanceStrict } from "date-fns";

import { getCustomDateLocale } from "../../../../../shared/utils/dateUtils";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { SeverityTag } from "./SeverityTag";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomEventDetailsProps } from "./CustomEventDetails";
import { SectionContainer } from "./styles";

const TagAndDateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTitle = styled(Typography)`
  margin: 6px 0;
`;

export const HeaderSection: React.FC<CustomEventDetailsProps> = ({
  event,
  onClose,
}) => {
  const timeAgo = useMemo(
    () =>
      formatDistanceStrict(event.startTime, new Date(), {
        addSuffix: true,
        locale: getCustomDateLocale(),
      }),
    [event.startTime]
  );

  const { format } = useDateFormatter({ timeZoneName: undefined });

  return (
    <SectionContainer aria-label={"custom event details header"}>
      <IconButton icon={Close24} noBorder onClick={onClose} />
      <div>
        <TagAndDateContainer>
          <SeverityTag event={event} />
          <Typography size={"medium"}>{format(event.startTime)}</Typography>
          <Typography size={"medium"} color="gray">
            {`(${timeAgo})`}
          </Typography>
        </TagAndDateContainer>
        <StyledTitle variant="headline" bold>
          {event.eventType}
        </StyledTitle>
        <Typography size={"medium"}>{event.summary}</Typography>
      </div>
    </SectionContainer>
  );
};
