import { AccountPlan } from "komodor-types";
import { useEffect, useMemo } from "react";

import { useGetAccount } from "../../../shared/hooks/accounts-service/client/account/useGetAccount";
import { useIsArgoWorkflowsEnabledForAccount } from "../../argoWorkflowsView/hooks";
export const useArgoWorkflows = () => {
  const [cachedIsArgoWFEnabled, setCachedIsArgoWFEnabled] =
    useIsArgoWorkflowsEnabledForAccount();

  const { data: account } = useGetAccount(cachedIsArgoWFEnabled !== "true");

  const showArgoWorkflows = useMemo(
    () =>
      cachedIsArgoWFEnabled === "true" ||
      account?.plan === AccountPlan.free ||
      account?.plan === AccountPlan.trial ||
      account?.inPoc ||
      !!account?.features.argoWF,
    [cachedIsArgoWFEnabled, account]
  );

  useEffect(() => {
    setCachedIsArgoWFEnabled(showArgoWorkflows ? "true" : "false");
  }, [setCachedIsArgoWFEnabled, showArgoWorkflows]);

  return showArgoWorkflows;
};
