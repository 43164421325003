import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { MagicAi24 } from "@komodorio/design-system/icons";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
`;

const StyledMagicAi24 = styled(MagicAi24)`
  && {
    width: 20px;
    height: 20px;
  }
`;

export const KlaudiaAiSection: React.FC = () => {
  return (
    <Container>
      <StyledMagicAi24 />
      <Typography variant={"h5"}>Root cause Analysis </Typography>
      <Typography color={"text.secondary"} variant={"body3"}>
        (Powered by AI)
      </Typography>
    </Container>
  );
};
