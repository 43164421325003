import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import {
  ClickableCard,
  CloseButton,
  RelativeContainer,
  SelectableDrawerRow,
} from "./styles";
import { MockDrawer } from "./MockDrawer";
import clusterAutoscalerImgSrc from "./images/cluster-autoscaler.png";
import nodeTerminationsDrawer from "./images/node-terminations-impact-autoscaling.png";
import underProvisionedDrawer from "./images/under-provisioned-workloads-drawer.png";
import underutilizedDrawer from "./images/underutilized-drawer.png";
import underProvisionedContainerDrawer from "./images/underprovisioned-container-drawer.png";

export const DrawerCloseButton = styled(CloseButton)`
  width: 35px;
  height: 50px;
  top: 20px;
  left: 24px;
  border-radius: 4px;
`;

export const ClusterAutoscalerView: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  const [secondaryDrawerImgSrc, setSecondaryDrawerImgSrc] = useState<
    string | null
  >(null);
  return (
    <RelativeContainer>
      <img src={clusterAutoscalerImgSrc}></img>
      <ClickableCard
        width="32px"
        height="32px"
        left="1.5%"
        top="6%"
        onClick={() => {
          navigate(`../`, { relative: "path" });
        }}
      ></ClickableCard>
      <ClickableCard
        width="18.1%"
        height="6.5%"
        left="3.2%"
        top="21.3%"
        onClick={() => {
          setDrawerImgSrc(nodeTerminationsDrawer);
        }}
      />
      <ClickableCard
        width="18.1%"
        height="6.5%"
        left="22.1%"
        top="21.3%"
        onClick={() => {
          setDrawerImgSrc(underProvisionedDrawer);
        }}
      />
      <ClickableCard
        width="18.1%"
        height="6.5%"
        left="40.9%"
        top="21.3%"
        onClick={() => {
          setDrawerImgSrc(underutilizedDrawer);
        }}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <DrawerCloseButton onClick={() => setDrawerImgSrc(null)} />
        }
      >
        {drawerImgSrc === underProvisionedDrawer && (
          <SelectableDrawerRow
            height="58px"
            top="152px"
            onClick={() =>
              setSecondaryDrawerImgSrc(underProvisionedContainerDrawer)
            }
          />
        )}
      </MockDrawer>
      <MockDrawer
        open={secondaryDrawerImgSrc !== null}
        imgSrc={secondaryDrawerImgSrc}
        onClose={() => setSecondaryDrawerImgSrc(null)}
        closeButton={
          <DrawerCloseButton onClick={() => setSecondaryDrawerImgSrc(null)} />
        }
      />
    </RelativeContainer>
  );
};
