import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";
import { PodsOutput } from "../../CommonTypes";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeHealthySystemPodsDetails from "./detailsComponents/NodeHealthySystemPodsDetails";

interface NodeHealthySystemPodsOutput {
  pods?: PodsOutput[];
  hiddenPods?: number;
}

export default class NodeHealthySystemPodsCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "System pods";
    this.description =
      "We wanted to make sure that all the system pods are healthy. A failure in one of these pods might be connected to the issue the node is facing.";
    this.shortOutput = check.passed
      ? "All system pods are healthy"
      : "There are unhealthy system pods on this node";
    this.action = (
      <>
        We ran
        <GrayHighlight>
          kubectl get pods --all-namespaces -o wide --field-selector
          spec.nodeName={`<NODE>`}, metadata.namespace=kube-system
        </GrayHighlight>
        to see if all system pods are healthy.
      </>
    );
    this.question = "Are all the system pods healthy?";
    this.output = check.output as NodeHealthySystemPodsOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <NodeHealthySystemPodsDetails check={this} />;
  }
}
