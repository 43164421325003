/* eslint-disable max-lines */
import { parseISO } from "date-fns";
import { sortBy } from "lodash";
import { useMemo } from "react";

import {
  FetchWorkflowRunByIdQuery,
  FetchWorkflowsRunsQuery,
  useFetchWorkflowRunByIdQuery,
  useFetchWorkflowRunBySensorIdsQuery,
  useFetchWorkflowsRunsQuery,
} from "../../generated/graphql";
import useQueryWithVariables from "../../shared/hooks/useQueryWithVariables";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowIssueEventGroup, {
  groupWorkflowIssuesEvents,
  WorkflowIssueStatus,
} from "../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { TimeWindow } from "../../shared/types/TimeWindow";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { buildSummaryFromImageTag } from "../common/EventGroup/deployEvent/DeployEventGroup";
import getDeployStatus from "../common/EventGroup/deployEvent/getDeployStatus";
import {
  TriageDataDb,
  TriageDataParsed,
} from "../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import {
  MonitorApiApiV1TypeIdGetRequest,
  MonitorData,
  MonitorType,
} from "../../generated/monitorsApi";
import { AvailabilityInvestigationCheckType } from "../InvestigationMode/availability/types";
import { useGetMonitorRunById } from "../../shared/hooks/monitors-api/client/monitors/useGetMonitorRunById";

import { AvailabilityCheckType } from "./workflowRunView/checks/availability/types";
import { NodeCheckType } from "./workflowRunView/checks/node/types";
import { PVCCheckType } from "./workflowRunView/checks/pvc/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowConfigType, WorkflowConfiguration } from "./common/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  PodCheckType,
  DeployCheckType,
  JobCheckTypeEnum,
} from "./workflowRunView/checks/useWorkflowsChecks";

export const KlaudiaSessionCheckType = "klaudiaSession";

export type AllChecksTypes =
  | PVCCheckType
  | NodeCheckType
  | AvailabilityCheckType
  | JobCheckTypeEnum.JobCheckType
  | DeployCheckType.DeployPodsHealthy
  | PodCheckType.PodHealthy
  | AvailabilityInvestigationCheckType.Introduction
  | AvailabilityInvestigationCheckType.UnhealthyPods
  | AvailabilityInvestigationCheckType.Logs
  | AvailabilityInvestigationCheckType.CorrelatedDeploys
  | AvailabilityInvestigationCheckType.NodeIssues
  | AvailabilityInvestigationCheckType.KlaudiaSession;

export interface BaseWorkflowCheck {
  passed: boolean;
  type: AllChecksTypes;
  shortOutput: string;
  cta: string;
  output: unknown;
}

export const asWorkflowChecks = (checksResults: unknown): BaseWorkflowCheck[] =>
  Object.values(checksResults as Record<AllChecksTypes, BaseWorkflowCheck>);

export type WorkflowRun = {
  id: string;
  sourceEventId?: string;
  type: WorkflowConfigType;
  shortResourceName: string;
  workflowConfiguration: WorkflowConfiguration | null;
  closedAt: Date | null;
  closeReason?: string;
  resultsSummary: { [key: string]: boolean };
  cluster?: string;
  namespace?: string;
  reasons: string[];
  eventTime: Date;
};
export type WorkflowRunWithResults = WorkflowRun & {
  results: BaseWorkflowCheck[];
  services?: string[];
  triageData: TriageDataParsed;
};

type RawWorkflowRun = FetchWorkflowsRunsQuery["workflow_results"][0];
const parseWorkflowRunOld = (wr: RawWorkflowRun): WorkflowRun => {
  return {
    id: wr.id,
    sourceEventId: wr.sourceEventId ?? undefined,
    type: wr.type as WorkflowConfigType,
    shortResourceName: wr.shortResourceName,
    workflowConfiguration:
      wr.workflowConfiguration as WorkflowConfiguration | null,
    closedAt: wr.closedAt ? parseISO(wr.closedAt) : null,
    closeReason: wr.closeReason ?? undefined,
    resultsSummary: wr.resultsSummary as { [key: string]: boolean },
    cluster: wr.cluster ?? undefined,
    namespace: wr.namespace ?? undefined,
    reasons: (wr.reasons as string[]) ?? [],
    eventTime: parseISO(wr?.eventTime ?? wr.createdAt),
  };
};
export const parseWorkflowRun = (wr: MonitorData): WorkflowRun => {
  return {
    id: wr.id,
    sourceEventId: wr.sourceEventId ?? undefined,
    type: wr.type as WorkflowConfigType,
    shortResourceName: wr.shortResourceName ?? "",
    workflowConfiguration:
      wr.workflowConfiguration as WorkflowConfiguration | null,
    closedAt: wr.closedAt ? parseISO(wr.closedAt) : null,
    closeReason: wr.closeReason ?? undefined,
    resultsSummary: wr.resultsSummary as { [key: string]: boolean },
    cluster: wr.cluster ?? undefined,
    namespace: wr.namespace ?? undefined,
    reasons: (wr.reasons as string[]) ?? [],
    eventTime: wr?.eventTime ? parseISO(wr?.eventTime) : new Date(),
  };
};
type RawWorkflowRunWithResults =
  FetchWorkflowRunByIdQuery["workflow_results"][0];
const parseWorkflowRunWithResultsOld = (
  wr: RawWorkflowRunWithResults
): WorkflowRunWithResults => {
  const triageDataDb = wr.triageData as TriageDataDb;
  const parsedTriageData: TriageDataParsed = {
    pastWeekIssues:
      triageDataDb?.pastWeekIssues?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
        endTime: parseISO(issue.endTime),
      })) ?? [],
    latestDeploysInsights:
      triageDataDb?.latestDeploysInsights?.map((issue) => {
        const summary = buildSummaryFromImageTag(
          issue.versionFrom,
          issue.versionTo
        );
        return {
          ...issue,
          status: getDeployStatus(issue.status),
          startTime: parseISO(issue.startTime),
          summary: summary,
        };
      }) ?? [],
    limitDecreaseInsight: triageDataDb?.limitDecreaseInsight
      ? {
          ...triageDataDb.limitDecreaseInsight,
          status: getDeployStatus(triageDataDb?.limitDecreaseInsight.status),
          startTime: parseISO(triageDataDb?.limitDecreaseInsight.startTime),
          summary: buildSummaryFromImageTag(
            triageDataDb?.limitDecreaseInsight.versionFrom,
            triageDataDb?.limitDecreaseInsight.versionTo
          ),
        }
      : null,
    latestNodeIssuesInsights:
      triageDataDb?.latestNodeIssuesInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
        status: issue.isClosed
          ? WorkflowIssueStatus.closed
          : WorkflowIssueStatus.open,
      })) ?? [],
    latestNodeTerminationsInsights:
      triageDataDb?.latestNodeTerminationsInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
      })) ?? [],
    latestManualActionsInsights:
      triageDataDb?.latestManualActionsInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
      })) ?? [],
    sameIssuesInClusterInsights:
      triageDataDb?.sameIssuesInClusterInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
        status: issue.isClosed
          ? WorkflowIssueStatus.closed
          : WorkflowIssueStatus.open,
      })) ?? [],
    occurrences: {
      issuesCountLastWeek: triageDataDb?.occurrences?.issuesCountLastWeek ?? 0,
      issuesCountWeekBeforeLastOne:
        triageDataDb?.occurrences?.issuesCountWeekBeforeLastOne ?? 0,
      issuesCountLastDay: triageDataDb?.occurrences?.issuesCountLastDay ?? 0,
      issuesCountDayBeforeLastOne:
        triageDataDb?.occurrences?.issuesCountDayBeforeLastOne ?? 0,
    },
    isNoisyNeighborsDetected: triageDataDb?.isNoisyNeighborsDetected ?? false,
    issueType: triageDataDb?.issueType ?? "",
    subReason: triageDataDb?.subReason ?? "",
  };
  return {
    ...parseWorkflowRunOld(wr),
    results: sortBy(asWorkflowChecks(wr.results), ["passed"]),
    services: wr.services as string[],
    triageData: parsedTriageData,
  };
};

export const parseWorkflowRunWithResults = (
  wr: MonitorData
): WorkflowRunWithResults => {
  const parsedTriageData = parseTriageData(wr.triageData as TriageDataDb);
  return {
    ...parseWorkflowRun(wr),
    results: sortBy(asWorkflowChecks(wr.results), ["passed"]),
    services: wr.services as string[],
    triageData: parsedTriageData,
  };
};

export const parseTriageData = (
  triageDataDb: TriageDataDb
): TriageDataParsed => {
  return {
    pastWeekIssues:
      triageDataDb?.pastWeekIssues?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
        endTime: parseISO(issue.endTime),
      })) ?? [],
    latestDeploysInsights:
      triageDataDb?.latestDeploysInsights?.map((issue) => {
        const summary = buildSummaryFromImageTag(
          issue.versionFrom,
          issue.versionTo
        );
        return {
          ...issue,
          status: getDeployStatus(issue.status),
          startTime: parseISO(issue.startTime),
          summary: summary,
        };
      }) ?? [],
    limitDecreaseInsight: triageDataDb?.limitDecreaseInsight
      ? {
          ...triageDataDb.limitDecreaseInsight,
          status: getDeployStatus(triageDataDb?.limitDecreaseInsight.status),
          startTime: parseISO(triageDataDb?.limitDecreaseInsight.startTime),
          summary: buildSummaryFromImageTag(
            triageDataDb?.limitDecreaseInsight.versionFrom,
            triageDataDb?.limitDecreaseInsight.versionTo
          ),
        }
      : null,
    latestNodeIssuesInsights:
      triageDataDb?.latestNodeIssuesInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
        status: issue.isClosed
          ? WorkflowIssueStatus.closed
          : WorkflowIssueStatus.open,
      })) ?? [],
    latestNodeTerminationsInsights:
      triageDataDb?.latestNodeTerminationsInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
      })) ?? [],
    latestManualActionsInsights:
      triageDataDb?.latestManualActionsInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
      })) ?? [],
    sameIssuesInClusterInsights:
      triageDataDb?.sameIssuesInClusterInsights?.map((issue) => ({
        ...issue,
        startTime: parseISO(issue.startTime),
        status: issue.isClosed
          ? WorkflowIssueStatus.closed
          : WorkflowIssueStatus.open,
      })) ?? [],
    occurrences: {
      issuesCountLastWeek: triageDataDb?.occurrences?.issuesCountLastWeek ?? 0,
      issuesCountWeekBeforeLastOne:
        triageDataDb?.occurrences?.issuesCountWeekBeforeLastOne ?? 0,
      issuesCountLastDay: triageDataDb?.occurrences?.issuesCountLastDay ?? 0,
      issuesCountDayBeforeLastOne:
        triageDataDb?.occurrences?.issuesCountDayBeforeLastOne ?? 0,
    },
    isNoisyNeighborsDetected: triageDataDb?.isNoisyNeighborsDetected ?? false,
    issueType: triageDataDb?.issueType ?? "",
    subReason: triageDataDb?.subReason ?? "",
  };
};

export const useWorkflowRunBySensorIds = (
  sourceEventIds: string[],
  workflowTypes: WorkflowConfigType[]
): [WorkflowRunWithResults | null, boolean] => {
  const variables = useMemo(() => ({ sourceEventIds }), [sourceEventIds]);
  const data = useQueryWithVariables(
    useFetchWorkflowRunBySensorIdsQuery,
    variables
  );

  const result = data?.workflow_results.find((result) =>
    workflowTypes.includes(result.type as WorkflowConfigType)
  );
  return [result ? parseWorkflowRunWithResultsOld(result) : null, !data];
};

export const useWorkflowRunByIdOld = (
  runId: string
): WorkflowRunWithResults | null => {
  const variables = useMemo(() => ({ runId }), [runId]);
  const data = useQueryWithVariables(useFetchWorkflowRunByIdQuery, variables);
  return useMemo(() => {
    return data?.workflow_results.length
      ? parseWorkflowRunWithResultsOld(data.workflow_results[0])
      : null;
  }, [data?.workflow_results]);
};

const MINIFIED_RUN_FIELDS: MonitorApiApiV1TypeIdGetRequest["fields"] = [
  "id",
  "type",
  "shortResourceName",
  "sourceEventId",
  "createdAt",
  "closedAt",
  "updatedAt",
  "closeReason",
  "services",
  "workflowConfiguration",
  "resultsSummary",
  "cluster",
  "namespace",
  "reasons",
  "eventTime",
];

export const useMinifiedMonitorRunById = (
  id: string,
  type?: MonitorType
): MonitorData | null => {
  const { data } = useGetMonitorRunById(
    { id, type: type as MonitorType, fields: MINIFIED_RUN_FIELDS },
    id !== "" && type != null
  );
  return useMemo(() => data?.data ?? null, [data]);
};

export const useMinifiedMonitorEventGroupById = ({
  serviceId,
  runId,
  type,
}: {
  serviceId: string;
  runId: string;
  type?: MonitorType;
}): WorkflowIssueEventGroup | undefined => {
  const minifiedCorrelatedIssueEvent = useMinifiedMonitorRunById(runId, type);
  const correlatedIssueEvent = useMemo(() => {
    if (!minifiedCorrelatedIssueEvent) return undefined;
    const minifiedIssuesEventGroups = groupWorkflowIssuesEvents(
      [minifiedCorrelatedIssueEvent],
      serviceId
    );
    return minifiedIssuesEventGroups?.[0];
  }, [serviceId, minifiedCorrelatedIssueEvent]);
  return correlatedIssueEvent;
};

const useWorkflowsRuns = (
  timeWindow: Pick<TimeWindow, "start" | "end">
): {
  workflowsRuns: WorkflowRun[] | never;
  isFetching: boolean;
  isEmpty: boolean;
} => {
  const variables = useMemo(
    () => ({
      from: timeWindow.start.toISOString(),
      to: timeWindow.end.toISOString(),
    }),
    [timeWindow.start, timeWindow.end]
  );
  const data = useQueryWithVariables(useFetchWorkflowsRunsQuery, variables);

  const workflow_results = data?.workflow_results;

  const { workflowsRuns, isFetching, isEmpty } = useMemo(() => {
    return workflow_results
      ? workflow_results.length > 0
        ? {
            workflowsRuns: workflow_results.map(parseWorkflowRunOld),
            isFetching: false,
            isEmpty: false,
          }
        : { workflowsRuns: [], isFetching: false, isEmpty: true }
      : { workflowsRuns: [], isFetching: true, isEmpty: false };
  }, [workflow_results]);

  return { workflowsRuns, isFetching, isEmpty };
};

export default useWorkflowsRuns;
