import React from "react";
import { Metadata, MetadataProps } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import {
  Section,
  SectionName,
} from "../WorkflowIssueEventDetails/triage/common/styles";

import { useGetPodsInfo } from "@/shared/hooks/resources-api/client/useGetPodsInfo";
import { useGetDeletedPodsState } from "@/shared/hooks/resources-api/client/useGetDeletedPodsState";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { getWorkflowInfoFromLabels } from "@/components/common/ProcessList/details/PodPhaseDetails/utils";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";

const WhereContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max-content, 150px));
  gap: 8px 24px;
`;

const MetadataWithTooltip = (props: MetadataProps) => (
  <Metadata
    {...props}
    maxWidth={150}
    tooltipProps={{
      componentsProps: lightMuiTooltipStyle,
      title: props.value,
    }}
  />
);

export const PodWhereSection: React.FC<{
  fromEpoch?: string;
  toEpoch?: string;
  clusterName: string;
  namespace: string;
  podName: string;
  onResourceClick?: () => void;
  podNameAriaLabel?: string;
}> = ({
  fromEpoch,
  toEpoch,
  clusterName,
  namespace,
  podName,
  onResourceClick,
  podNameAriaLabel,
}) => {
  const { data: podData } = useGetPodsInfo({
    clusterName,
    namespace,
    podName,
    fields: ["labels", "nodeName", "ownerRef"],
  });
  const { data: deletedPodData } = useGetDeletedPodsState({
    clusterName,
    fromEpoch: fromEpoch,
    toEpoch: toEpoch,
    komodorUids: [
      buildKomodorUid({
        kind: "Pod",
        clusterName,
        namespace,
        resourceName: podName,
      }),
    ],
    fields: ["labels", "nodeName", "resourceOwnerName"],
    limit: 1,
  });

  const pod = podData?.[0];
  const deletedPod = deletedPodData?.data?.[0];

  const nodeName = pod?.nodeName || deletedPod?.nodeName;
  const controlledBy =
    pod?.ownerRef?.[0]?.name || deletedPod?.resourceOwnerName;
  const labels = (pod?.labels || deletedPod?.labels) as
    | Record<string, string>
    | undefined;
  const workflowLabels = labels && getWorkflowInfoFromLabels(labels);
  const { dagId, taskId, runId, tryNumber } = workflowLabels ?? {};

  return (
    <Section grayBg>
      <SectionName>where</SectionName>
      <WhereContainer>
        <MetadataWithTooltip
          title={"pod"}
          value={podName}
          onValueClick={onResourceClick}
          aria-label={podNameAriaLabel}
        />
        <MetadataWithTooltip title="cluster" value={clusterName} />
        <MetadataWithTooltip title="namespace" value={namespace} />
        {controlledBy && controlledBy !== "<none>" && (
          <MetadataWithTooltip title="controlled by" value={controlledBy} />
        )}
        {nodeName && <MetadataWithTooltip title="node" value={nodeName} />}
        {tryNumber && (
          <MetadataWithTooltip title="try number" value={tryNumber} />
        )}
        {dagId && <MetadataWithTooltip title="dag_id" value={dagId} />}
        {runId && <MetadataWithTooltip title="run_id" value={runId} />}
        {taskId && <MetadataWithTooltip title="task_id" value={taskId} />}
      </WhereContainer>
    </Section>
  );
};
