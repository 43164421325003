import React, { useRef } from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { TooltipProps } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";

import { useHasTextEllipsis } from "@/shared/hooks/useHasTextEllipsis";
import { OverflowTextStyle } from "@/shared/styles/styles";

const EllipsisContainer = styled.div<{ ellipsisAtLine: number }>`
  width: 100%;
  & > * {
    ${OverflowTextStyle};
    text-wrap: balance;
    display: ${({ ellipsisAtLine }) =>
      ellipsisAtLine > 1 ? "-webkit-box;" : "block"};
    -webkit-line-clamp: ${({ ellipsisAtLine }) => ellipsisAtLine};
    -webkit-box-orient: vertical;
    white-space: ${({ ellipsisAtLine }) =>
      ellipsisAtLine > 1 ? "normal" : "nowrap"};
  }
`;

type EllipsisTextContainerWithTooltipProps = {
  textContent: string;
  ellipsisAtLine?: number;
  tooltipProps?: TooltipProps;
  typographyProps?: TypographyProps;
};

/** TODO: This could replace shared component EllipsisContainerWithTooltip */
export const EllipsisTextContainerWithTooltip: React.FC<
  EllipsisTextContainerWithTooltipProps
> = ({ textContent, ellipsisAtLine = 2, tooltipProps, typographyProps }) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const showTooltip = useHasTextEllipsis(descriptionRef);

  return (
    <Tooltip
      title={showTooltip ? textContent : ""}
      placement="top-start"
      {...tooltipProps}
    >
      <EllipsisContainer ellipsisAtLine={ellipsisAtLine}>
        <Typography variant={"body3"} ref={descriptionRef} {...typographyProps}>
          {textContent}
        </Typography>
      </EllipsisContainer>
    </Tooltip>
  );
};
