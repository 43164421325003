import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Button from "@mui/material/Button";

import { AllPoliciesTable } from "./AllPoliciesTable/AllPoliciesTable";
import { AllPoliciesTableContextProvider } from "./AllPoliciesTable/context/AllPoliciesTableContext";
import { useOpenPolicyDrawer } from "./policiesHooks";

import { useHasWritePermission } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasWritePermission";
import { policyTableSearchPlaceholder } from "@/components/reliability/components/pages/policies/allPoliciesConstants";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const Container = styled.div``;

const SearchAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  & > :first-child {
    max-width: 710px;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .search-field-container {
    background: white;
  }
`;

const { newReliabilityPolicyButton } = reliabilityArialLabels.policies;

export const AllPolicies: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const onClearSearchTerm = useCallback(() => setSearchTerm(""), []);
  const openPolicyDrawer = useOpenPolicyDrawer();
  const hasWritePermissions = useHasWritePermission();

  return (
    <Container>
      <SearchAreaContainer>
        <Typography variant={"body2"}>
          Create additional, custom policies to override specific checks for
          cluster(s) based on your business requirements. Define the policies
          priority, the highest priority policy will be used when running the
          checks.
        </Typography>
        <FlexContainer>
          <SearchField
            placeholder={policyTableSearchPlaceholder}
            value={searchTerm}
            onSearch={setSearchTerm}
            width={"15rem"}
            classNames={{ container: "search-field-container" }}
            ariaLabel={"search field"}
          />
          {hasWritePermissions && (
            <Button
              variant={"contained"}
              onClick={() => openPolicyDrawer()}
              aria-label={newReliabilityPolicyButton}
            >
              New Reliability Policy
            </Button>
          )}
        </FlexContainer>
      </SearchAreaContainer>
      <AllPoliciesTableContextProvider>
        <AllPoliciesTable
          searchTerm={searchTerm}
          onClearSearchTerm={onClearSearchTerm}
        />
      </AllPoliciesTableContextProvider>
    </Container>
  );
};
