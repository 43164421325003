import { useEffect, useState } from "react";

import { DesktopErrorVariant } from "../../../components/DesktopApp/error/DesktopErrorPage";

import isElectronContext from "./isElectronContext";

const useIsDesktopAppError = (): DesktopErrorVariant | null => {
  const [desktopError, setDesktopError] = useState<DesktopErrorVariant | null>(
    null
  );
  useEffect(() => {
    if (!isElectronContext()) return;
    window.electronAPI?.updateDesktopError(
      (_: unknown, errorVariant: DesktopErrorVariant) => {
        setDesktopError(errorVariant);
      }
    );
  }, []);
  return desktopError;
};

export default useIsDesktopAppError;
