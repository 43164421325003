import React, { useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import styled from "styled-components";

import { useOpenWorkflowIssues } from "../../../hooks/overviewPageHooks";
import { Dictionary } from "../../../../../../../shared/types/Dictionary";
import useKomodorServices from "../../../../../../../shared/hooks/useKomodorServices";

import { IssuesHeader } from "./IssuesHeader";
import { SingleIssueItem } from "./SingleIssueItem/SingleIssueItem";

const MAX_ISSUES = 3;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  && {
    margin-bottom: 10px;
    width: fit-content;
    align-self: center;
  }
`;

const StyledArrowContainer = styled(KeyboardArrowDownOutlined)<{
  direction: "down" | "up";
}>`
  && {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    transition: transform 0.2s ease-in-out;
    transform: ${({ direction }) =>
      direction === "down" ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;

export const IssueItems: React.FC = () => {
  const [expandContent, setExpandContent] = useState(false);
  const services = useKomodorServices().all;

  const { eventGroups } = useOpenWorkflowIssues();

  const totalIssues = useMemo(() => {
    return eventGroups.map((workflowIssue, idx) => {
      const serviceInfo = services?.find(
        (service) => service.id === workflowIssue.serviceId
      );
      return (
        <SingleIssueItem
          key={idx}
          workflowIssue={workflowIssue}
          serviceInfo={serviceInfo}
        />
      );
    });
  }, [eventGroups, services]);

  const shownIssues = useMemo(() => {
    return totalIssues.slice(0, MAX_ISSUES);
  }, [totalIssues]);

  const collapsedIssues = useMemo(() => {
    return totalIssues.slice(MAX_ISSUES, totalIssues.length);
  }, [totalIssues]);

  const unhealthyServicesNum = useMemo(() => {
    const totalUnhealthyServices = eventGroups.reduce<Dictionary<number>>(
      (acc, issue) => {
        if (!acc[issue.serviceId]) {
          acc[issue.serviceId] = 1;
        } else {
          acc[issue.serviceId] += 1;
        }
        return acc;
      },
      {}
    );

    return Object.keys(totalUnhealthyServices).length;
  }, [eventGroups]);

  const shouldShowMore = collapsedIssues.length > 0;

  const onExpandClick = () => {
    setExpandContent(!expandContent);
  };

  const actionText = expandContent
    ? "Show less"
    : `Show ${collapsedIssues.length} more issues`;

  const deploysNum = 0; // TODO: support ongoing deploys

  return (
    <Container>
      <IssuesHeader
        issuesNum={shownIssues.length}
        deploysNum={deploysNum}
        unhealthyServicesNum={unhealthyServicesNum}
      />
      {shownIssues}
      {shouldShowMore && (
        <>
          <Collapse in={expandContent} timeout={300}>
            {collapsedIssues}
          </Collapse>
          <StyledButton variant="text" onClick={onExpandClick}>
            <StyledArrowContainer direction={expandContent ? "up" : "down"} />
            {actionText}
          </StyledButton>
        </>
      )}
    </Container>
  );
};
