import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { ActionMetadataRunJob, ActionTypes, TaskType } from "komodor-types";
import { useLocation } from "react-router-dom";
import { get, set } from "lodash";
import { Job } from "kubernetes-types/batch/v1.d";

import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../../shared/config/analyticsEvents";
import { extractPathFromLocation, omitFields } from "../../common/utils";
import { ErrorMessage } from "../../common/ErrorMessage";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import {
  ActionSentMessage,
  useShouldShowActionSentMessage,
} from "../../common/useActionSent";
import { DisabledRunButton } from "../../common/DisabledRunButton";
import { jsonToYaml } from "../../../../shared/utils/yaml/yaml";

import useCronJobNewSpec from "./useCronJobNewSpec";
import { getJobName } from "./utils";

const TIME_TO_WAIT_BEFORE_CLEARING_RESULT = 5000; // 5 seconds

interface RunNowJobModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  afterEffect?: () => void;
}

const JobRunNowModal: React.FC<RunNowJobModalProps> = ({
  isModalOpen,
  handleClose,
  resourceId,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const location = useLocation();

  const { cronJobObj, fetching: fetchingCronJobConfiguration } =
    useCronJobNewSpec(resourceId);

  const jobObj = get(cronJobObj, "spec.jobTemplate") as Job;

  const spec: string = useMemo(() => {
    let stringifiedJsonValue;
    let o = omitFields(jobObj);

    const newJobName = getJobName(`${get(cronJobObj, "metadata.name")}`);

    const apiVersion = get(cronJobObj, "apiVersion", "batch/v1");
    set(o, "metadata.annotations['komodor.manual-actions.job-rerun']", "true");

    set(o, "kind", "Job");
    set(o, "apiVersion", apiVersion);
    set(o, "metadata.name", `${newJobName}`);
    set(o, "metadata.namespace", get(cronJobObj, "metadata.namespace"));

    const ownerReferences = [
      {
        uid: get(cronJobObj, "metadata.uid"),
        kind: "CronJob",
        name: get(cronJobObj, "metadata.name"),
        apiVersion: apiVersion,
        controller: true,
        blockOwnerDeletion: true,
      },
    ];
    set(o, "metadata.ownerReferences", ownerReferences);

    try {
      o = JSON.parse(JSON.stringify(o));
      stringifiedJsonValue = jsonToYaml(JSON.stringify(o));
    } catch (err) {
      stringifiedJsonValue = "";
    }
    return stringifiedJsonValue;
  }, [cronJobObj, jobObj]);

  const isRequestDataSufficientForAction = jobObj && spec;

  const metadata: ActionMetadataRunJob = {
    namespace,
    cluster,
    serviceId: resourceId,
    type: ActionTypes.JobRunNow,
    resourceType,
    resourceName,
    data: {
      newJson: spec,
    },
  };

  const { execute, result, deniedObject, isFetching, resetAgentTask } =
    useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);
  const analytics = useAnalyticsApi();
  const executeAndReport = () => {
    setShowErrorMessage(false);
    execute();
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: "jobRunNow",
        path: extractPathFromLocation(location),
        resourceType,
      },
      true,
      false,
      [SegmentIntegrations.Hubspot]
    );
  };

  const shouldShowActionSentMessage =
    useShouldShowActionSentMessage(isFetching);

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  useEffect(() => {
    let timeoutID: NodeJS.Timeout;
    if (result && !deniedObject) {
      afterEffect?.();
      handleClose();

      timeoutID = setTimeout(() => {
        setActionSent(false);
        resetAgentTask(); // this will allow to reinvoke the action
      }, TIME_TO_WAIT_BEFORE_CLEARING_RESULT);
    }

    return () => {
      timeoutID && clearTimeout(timeoutID);
    };
  }, [afterEffect, deniedObject, handleClose, result, resetAgentTask]);

  useEffect(() => {
    if (deniedObject) {
      setActionSent(false);
      setShowErrorMessage(true);
    }
  }, [deniedObject]);

  const btnText = `Run ${actionSent ? "..." : ""}`;

  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }

  return (
    <Modal isOpen={isModalOpen} width="25rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Trigger {resourceName}</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          When triggered, this action will instantly run a job with a
          configuration of this cron job.
          <br />
        </Typography>
        {showErrorMessage && deniedObject !== undefined && (
          <ErrorMessage reason={deniedObject} />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        {fetchingCronJobConfiguration ? (
          <DisabledRunButton text={btnText} />
        ) : (
          <Button
            data-testid="run-now-button"
            variant="primary"
            size="small"
            disabled={actionSent || !isRequestDataSufficientForAction}
            onClick={() => {
              executeAndReport();
              setActionSent(true);
            }}
          >
            {btnText}
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export default JobRunNowModal;
