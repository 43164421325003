import React from "react";
import { LabelSelector } from "kubernetes-types/meta/v1.d";
import { parseISO } from "date-fns";

import { extractLastModifyFromService } from "../../../shared/utils/postgresMigrationUtils";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ArgoWorkflowOwnerHeader } from "../headers/ArgoWorkflowOwnerHeader";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ArgoWorkflowOwnerContent } from "../../argoWorkflowsView/ArgoWorkflowOwnerContent";
import { WorkflowTemplate as ArgoWorkflowTemplateType } from "../../argoWorkflowsView/kubernetes-types";

import { isCurrentlyDeploying } from "./utils";

import Resource, { KomodorServiceType, ResourceTab, ResourceTabName } from ".";

export const ARGO_WORKFLOW_TEMPLATE_KIND = "WorkflowTemplate";
export const ARGO_WORKFLOW_TEMPLATE_KOMODOR_KIND =
  "ArgoWorkflows.WorkflowTemplates";

export default class ArgoWorkflowTemplate implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind = ARGO_WORKFLOW_TEMPLATE_KIND;
  readonly komodorKind = ARGO_WORKFLOW_TEMPLATE_KOMODOR_KIND;
  readonly name;
  readonly annotations;
  readonly labels;
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Pods },
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.ArgoWorkflowTemplateDetails];

  readonly selector;
  readonly k8sUid;
  readonly currentlyDeploying;
  readonly lastModified;
  readonly lastDeployStartTime;
  readonly lastDeployEndDate;
  readonly datadogData;
  readonly sentryData;
  readonly isDeleted;
  readonly inactive;
  readonly fullObj;
  readonly defaultTab;

  constructor(
    cluster?: string,
    argoWft?: ArgoWorkflowTemplateType,
    agentId?: string,
    komodorService?: KomodorServiceType
  ) {
    if (komodorService) {
      this.agentId = komodorService?.agentId ?? "";
      this.id = komodorService.id;
      this.cluster = komodorService.k8sClusterName;
      this.namespace = komodorService.namespace;
      this.name = komodorService.displayName;
      this.labels = (komodorService.k8s_metadata?.labels ?? {}) as {
        [key: string]: string;
      };
      this.annotations = (komodorService.k8s_metadata?.annotations ?? {}) as {
        [key: string]: string;
      };
      this.k8sUid = komodorService?.k8s_metadata?.k8sUid;
      this.selector = komodorService.k8s_metadata?.selector as
        | LabelSelector
        | undefined;
      this.datadogData = komodorService.datadog_data;
      this.sentryData = komodorService.sentry_data;

      const lastDeployEndTime = komodorService.deploy_state?.lastDeployEndTime;
      const lastDeployStartTime =
        komodorService.deploy_state?.lastDeployStartTime;
      this.currentlyDeploying = isCurrentlyDeploying(
        lastDeployStartTime,
        lastDeployEndTime
      );
      this.lastModified = extractLastModifyFromService(komodorService);
      this.lastDeployStartTime = lastDeployStartTime;
      this.lastDeployEndDate = lastDeployEndTime
        ? parseISO(lastDeployEndTime)
        : undefined;

      this.isDeleted = komodorService.isDeleted;
      this.inactive = komodorService.inactive;
    } else {
      this.fullObj = argoWft;
      this.agentId = agentId ?? "";
      this.id = argoWft?.metadata?.uid ?? "";
      this.cluster = cluster ?? "";
      this.namespace = argoWft?.metadata?.namespace ?? "";
      this.name = argoWft?.metadata?.name ?? "";
      this.labels = argoWft?.metadata?.labels ?? {};
      this.annotations = argoWft?.metadata?.annotations ?? {};
      this.selector = undefined;

      if (!this.fullObj?.metadata?.uid) {
        this.actions = [];
      }
    }
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <ArgoWorkflowOwnerContent
        cluster={this.cluster}
        selectedTab={selectedTab}
      />
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <ArgoWorkflowOwnerHeader
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
