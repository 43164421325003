import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { capitalize, groupBy } from "lodash";

import { textRegularStyle } from "../../common/typography";
import {
  blackGreyText,
  gray10,
  gray13,
  grayAppBackground,
} from "../../../Colors";
import { Divider } from "../../common/ProcessList/details/common";
import {
  CheckRawReultWithSpecLevel,
  StaticPreventionSeverity,
} from "../StaticPreventionUtils";
import { generateUniqueSpecLevel } from "../PolarisWrapper";

import StaticPreventionCheckCard from "./StaticPreventionCheckCard";
import { NoChecksToShowPrompt } from "./NoChecksToShowPrompt";

const InlineChecksContainer = styled.div`
  ${textRegularStyle}
  width: 100%;
  height: 2.25rem;
  background-color: ${grayAppBackground};
  border-top: ${gray13} 1px solid;
  border-bottom: ${gray13} 1px solid;
  display: flex;
  color: ${gray10};
  align-items: center;
  white-space: pre;

  .boldtext {
    font-weight: 700;
    color: ${blackGreyText};
  }
`;

const DividerAndCardContainer = styled.div`
  padding: 0 0.9rem 0 1.3rem;
`;

const StaticPreventionCardsContainer = styled.div<{
  ignoreTimePickerId: string;
}>`
  overflow: auto;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ ignoreTimePickerId }) =>
    ignoreTimePickerId !== "" ? "overflow: hidden;" : null}
`;

interface StaticPrevntionCardsProps {
  checks: CheckRawReultWithSpecLevel[];
  ignoreCallback?: (
    check: CheckRawReultWithSpecLevel,
    expirationDate: string
  ) => void;
  unignoreCallback?: (check: CheckRawReultWithSpecLevel) => void;
  isChecksFiltered: boolean;
  emptyStateCb: () => void;
}

const StaticPrevntionCards: React.FC<StaticPrevntionCardsProps> = ({
  checks,
  ignoreCallback,
  unignoreCallback,
  isChecksFiltered,
  emptyStateCb,
}) => {
  const [ignoreTimePickerId, setIgnoreTimePickerId] = useState<string>("");
  const [checksBySpecLevel, numOfGroups] = useMemo(() => {
    const checkGroupBySpecLevel = groupBy(checks, (c) =>
      generateUniqueSpecLevel(c.SpecLevel, c.SpecName)
    );
    return [
      Object.entries(checkGroupBySpecLevel).reduce<{
        [_: string]: CheckRawReultWithSpecLevel[];
      }>((allGroups, [specLevel, checks]) => {
        const sortedChecks = checks.sort((check1, check2) =>
          check1.Success === check2.Success &&
          check1.Severity === StaticPreventionSeverity.warning
            ? 0
            : check1.Success && !check2.Success
            ? 1
            : -1
        );
        return { ...allGroups, [specLevel]: sortedChecks };
      }, {}),
      Object.keys(checkGroupBySpecLevel).length,
    ];
  }, [checks]);

  const content = useMemo(
    () =>
      Object.entries(checksBySpecLevel).map(
        ([uniqueSpecLevel, checks], topIndex) => {
          const specLevelIsContainer =
            uniqueSpecLevel !== "pod" && uniqueSpecLevel !== "spec";
          const title = specLevelIsContainer ? "Container:" : uniqueSpecLevel;
          return (
            <React.Fragment key={topIndex}>
              <InlineChecksContainer>
                {`    ${capitalize(title)}`}
                {specLevelIsContainer && (
                  <div className="boldtext">{`    ${uniqueSpecLevel}  `}</div>
                )}
              </InlineChecksContainer>
              {checks.map((c, index) => (
                <DividerAndCardContainer key={index}>
                  <StaticPreventionCheckCard
                    checkResult={c}
                    uniqueSpecLevel={uniqueSpecLevel}
                    ignoreCallback={ignoreCallback}
                    unignoreCallback={unignoreCallback}
                    ignoreTimePickerId={ignoreTimePickerId}
                    setIgnoreTimePickerId={setIgnoreTimePickerId}
                  />
                  {index === checks.length - 1 &&
                  topIndex !== numOfGroups - 1 ? null : (
                    <Divider />
                  )}
                </DividerAndCardContainer>
              ))}
            </React.Fragment>
          );
        }
      ),
    [
      checksBySpecLevel,
      ignoreCallback,
      ignoreTimePickerId,
      numOfGroups,
      unignoreCallback,
    ]
  );

  return (
    <StaticPreventionCardsContainer ignoreTimePickerId={ignoreTimePickerId}>
      {checks.length ? (
        content
      ) : (
        <NoChecksToShowPrompt isFiltered={isChecksFiltered} cb={emptyStateCb} />
      )}
    </StaticPreventionCardsContainer>
  );
};

export default StaticPrevntionCards;
