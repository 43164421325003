import React from "react";
import styled from "styled-components";

import { H1 } from "../../common/typography";
import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import { IntegrationPermissionAlert } from "../../common/IntegrationPermissionAlert";
import { isSandbox } from "../../../shared/utils/sandbox";

import InstalledIntegrations from "./installed/InstalledIntegrations";
import AvailableIntegrations from "./available/AvailableIntegrations";

import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";

const Container = styled.div`
  > section {
    margin-bottom: 2rem;
  }

  ${H1} {
    margin: 2rem 0 1rem;
  }
`;

const IntegrationsManagement: React.FC = () => {
  const { canManageIntegrations } = useHasPermissions();

  return (
    <SettingsViewLayoutWrapper title={"Integrations"}>
      {!canManageIntegrations ? (
        <IntegrationPermissionAlert title="To make modifications to your integrations, please contact your organization admin" />
      ) : (
        <Container>
          {!isSandbox() && (
            <section>
              <H1>Installed Integrations</H1>
              <InstalledIntegrations />
            </section>
          )}
          <section>
            <H1>Available Integrations</H1>
            <AvailableIntegrations />
          </section>
        </Container>
      )}
    </SettingsViewLayoutWrapper>
  );
};

export default IntegrationsManagement;
