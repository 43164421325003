import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  useFormContext,
  FieldErrors,
  FieldErrorsImpl,
  FieldError,
  Merge,
} from "react-hook-form";
import { get } from "lodash";

import { severitiesList } from "../policyDrawerConstants";
import { useValidateConfigurationControlsInput } from "../hooks/useValidateConfigurationControlsInput";
import { HelperTextTypography } from "../HelperTextTypography";

import { SingleConfigurationControl } from "./SingleConfigurationControl";

import { CheckType } from "@/generated/reliabilityApi";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";

const Container = styled.div`
  margin-top: 16px;
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledErrorTextContainer = styled.div`
  min-height: 20px;
`;

const { getConfigurationControlsContainer: getControlContainerAriaLabel } =
  reliabilityArialLabels.policies.policiesDrawer;

type FieldErrorType =
  | FieldErrors
  | FieldError
  | Merge<FieldError, FieldErrorsImpl>;
type ConfigurationControlsProps = { checkType: CheckType };

export const ConfigurationControls: React.FC<ConfigurationControlsProps> = ({
  checkType,
}) => {
  const { isReadOnlyMode, setCheckTypeConfigurationRef } =
    usePolicyDrawerContext();
  const { formState, watch } = useFormContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const content = useMemo(() => {
    return severitiesList.map((severity) => (
      <SingleConfigurationControl
        checkType={checkType}
        severity={severity}
        key={`${checkType}${severity}`}
      />
    ));
  }, [checkType]);

  useEffect(() => {
    if (containerRef.current) {
      setCheckTypeConfigurationRef(checkType, containerRef);
    }

    return () => {
      setCheckTypeConfigurationRef(checkType, null);
    };
  }, [checkType, setCheckTypeConfigurationRef]);

  const watchers = watch(
    severitiesList
      .map((severity) =>
        getConfigurationFieldNamesByCheckTypeAsList(checkType, severity)
      )
      .flatMap((x) => x)
  );

  const error = useMemo(() => {
    const errors = formState.errors;
    if (!watchers.length) return;
    return severitiesList.reduce<FieldErrorType>((acc, severity) => {
      const checkTypeIdentifiers = getConfigurationFieldNamesByCheckTypeAsList(
        checkType,
        severity
      );

      const foundError = checkTypeIdentifiers.reduce<
        FieldErrorType | undefined
      >((acc, configurationName) => {
        return get(errors, configurationName) ?? acc;
      }, undefined);

      return foundError ?? acc;
    }, {});
  }, [checkType, formState.errors, watchers]);

  useValidateConfigurationControlsInput(checkType);

  return (
    <Container
      aria-label={getControlContainerAriaLabel(checkType)}
      ref={containerRef}
    >
      {content}
      {!isReadOnlyMode && (
        <StyledErrorTextContainer>
          <HelperTextTypography errorMessage={error?.message as string} />
        </StyledErrorTextContainer>
      )}
    </Container>
  );
};
