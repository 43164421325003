import { MonitorData } from "@/generated/monitorsApi";
import { issues } from "@/test/responseMocks/issues";
import { oomKilled } from "@/test/responseMocks/oomKilled";
import { livenessProbe } from "@/test/responseMocks/livenessProbe";
import {
  CascadingIncidentReport,
  ViolationSupportingData,
} from "@/generated/reliabilityApi";

export const affectedServicesNamespace = "namespace-cascading-failure";

export const cascadingFailureIssues: MonitorData[] = [
  ...issues,
  oomKilled,
  livenessProbe,
].map((issue, idx) => ({
  ...issue,
  id: `issue-id-${idx}`,
}));

const cascadingIncidentReport: CascadingIncidentReport = {
  id: "e281985d-c157-4a4a-bbea-c84f0d86bd80",
  requestId: "request-123",
  flowName: "auth-service-fail",
  batchId: "batch-123",
  isComplete: false,
  isFailed: false,
  isStuck: false,
  title: "Auth Service Failure Incident Report",
  timeline: [
    {
      real_time: "2023-09-30T15:00:00",
      incident_time: "T+0:00",
      event: "NonZeroExitCode:123456789 here we go its super long",
      komodor_uid: "Deployment|production|namespace|payment-service",
    },
    {
      real_time: "2023-09-30T15:01:00",
      incident_time: "T+0:01",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|notification-service",
    },
    {
      real_time: "2023-09-30T15:02:00",
      incident_time: "T+0:02",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|user-service",
    },
    {
      real_time: "2023-09-30T15:03:00",
      incident_time: "T+0:03",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|user-service",
    },
    {
      real_time: "2023-09-30T15:04:00",
      incident_time: "T+0:04",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|user-service",
    },
    {
      real_time: "2023-09-30T15:05:00",
      incident_time: "T+0:05",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|user-service",
    },
    {
      real_time: "2023-09-30T15:06:00",
      incident_time: "T+0:06",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|user-service",
    },
    {
      real_time: "2023-09-30T15:07:00",
      incident_time: "T+0:07",
      event: "NonZeroExitCode:1",
      komodor_uid: "Deployment|production|namespace|user-service",
    },
  ],
  grouping_reasons: [
    {
      reason: "All affected services have the same helm chart: mono",
      type: "Deterministic",
    },
    {
      reason: "All affected services are in the same namespace: production",
      type: "Deterministic",
    },
    {
      reason:
        "Based on the logs, all of the dependent services rely on auth-service",
      type: "AI Driven",
    },
  ],
  affected_services: [
    `Deployment|production|${affectedServicesNamespace}|user-service`,
    `Deployment|production|${affectedServicesNamespace}|auth-service`,
    `Deployment|production|${affectedServicesNamespace}|payment-service`,
    `Deployment|production|${affectedServicesNamespace}|notification-service`,
  ],
  issues: cascadingFailureIssues.slice(0, 3).map((issue) => issue.id),
  tldr: "The auth-service is failing due to an Out of Memory (OOM) error, causing three dependent services to experience authentication issues and service disruptions.",
  next_steps: [
    "revert auth-service pod",
    "Investigate the OOM of auth-service workload",
    "Review and adjust memory limits for auth-service",
    "Implement better monitoring for memory usage",
  ],
  detailed_incident_timeline: [
    "A new image (auth-service:v2) was deployed to the service: 'auth-service'",
    "The auth-service pod was terminated due to an Out of Memory condition, leading later to crashLoopbackOff",
    "Finally services: user-service, payment-service, and notification-service started failing due to auth-service unavailability",
  ],
  relatedChange: "kom-123456",
  rootCauseExternal: "",
  rootCauseResource: "pod-auth-123",
  rootCauseService: "auth-service",
  evidence: [
    {
      source: "user-service-komodor-uid",
      logsSnippet:
        "\n            [2023-09-30 15:01:30.123] [USER-SERVICE] [ERROR] Failed to authenticate user request: Unable to reach auth-service\n            [2023-09-30 15:01:30.124] [USER-SERVICE] [DEBUG] Auth-service connection attempt failed: Connection refused (111)\n            [2023-09-30 15:01:31.234] [USER-SERVICE] [WARN] Retrying auth-service connection (attempt 1 of 3)\n            [2023-09-30 15:01:31.456] [USER-SERVICE] [ERROR] Auth-service connection retry failed: Connection refused (111)",
      events: [
        {
          firstTimestamp: "2023-09-30T15:00:00",
          lastTimestamp: "2023-09-30T15:00:00",
          type: "Warning",
          reason: "OOMKilled",
          message:
            "Container auth-service was killed due to OOM (Out of Memory)",
        },
        {
          firstTimestamp: "2023-09-30T15:10:00",
          lastTimestamp: "2023-09-30T15:10:00",
          type: "Warning",
          reason: "OOMKilled",
          message: "message for event 2",
        },
        {
          firstTimestamp: "2023-09-30T15:20:00",
          lastTimestamp: "2023-09-30T15:20:00",
          type: "Warning",
          reason: "OOMKilled",
          message: "message for event 3",
        },
        {
          firstTimestamp: "2023-09-30T15:30:00",
          lastTimestamp: "2023-09-30T15:30:00",
          type: "Warning",
          reason: "ImagePullBackOff",
          message: "message for event 4",
        },
        {
          firstTimestamp: "2023-09-30T15:40:00",
          lastTimestamp: "2023-09-30T15:40:00",
          type: "Warning",
          reason: "CrashLoopBackOff",
          message: "message for event 5",
        },
      ],
      textualDescription:
        "The auth-service pod was terminated due to an Out of Memory condition, leading to authentication failures in dependent services.",
    },
    {
      source: "payment-service-komodor-uid",
      logsSnippet:
        "\n    [2023-09-30 15:01:30.123] [USER-SERVICE] [ERROR] Failed to authenticate user request: Unable to reach auth-service\n    [2023-09-30 15:01:30.124] [USER-SERVICE] [DEBUG] Auth-service connection attempt failed: Connection refused (111)\n    [2023-09-30 15:01:31.234] [USER-SERVICE] [WARN] Retrying auth-service connection (attempt 1 of 3)\n    [2023-09-30 15:01:31.456] [USER-SERVICE] [ERROR] Auth-service connection retry failed: Connection refused (111)",
      events: [
        {
          firstTimestamp: "2023-09-30T15:00:00",
          lastTimestamp: "2023-09-30T15:00:00",
          type: "Warning",
          reason: "OOMKilled",
          message:
            "Container auth-service was killed due to OOM (Out of Memory)",
        },
      ],
      textualDescription:
        "the logs of the user-service pod showing the connection attempts to the auth-service pod.",
    },
    {
      source: "notification-service-komodor-uid",
      logsSnippet:
        "\n            [2023-09-30 15:01:30.123] [USER-SERVICE] [ERROR] Failed to authenticate user request: Unable to reach auth-service\n            [2023-09-30 15:01:30.124] [USER-SERVICE] [DEBUG] Auth-service connection attempt failed: Connection refused (111)\n            [2023-09-30 15:01:31.234] [USER-SERVICE] [WARN] Retrying auth-service connection (attempt 1 of 3)\n            [2023-09-30 15:01:31.456] [USER-SERVICE] [ERROR] Auth-service connection retry failed: Connection refused (111)",
      events: [
        {
          firstTimestamp: "2023-09-30T15:00:00",
          lastTimestamp: "2023-09-30T15:00:00",
          type: "Warning",
          reason: "OOMKilled",
          message:
            "Container auth-service was killed due to OOM (Out of Memory)",
        },
      ],
      textualDescription:
        "the logs of the user-service pod showing the connection attempts to the auth-service pod.",
    },
  ],
};

export const getCascadingFailureSupportingData =
  (): ViolationSupportingData => {
    return {
      cascadingFailure: {
        cascadingSessionInfo: cascadingIncidentReport,
      },
    };
  };
