import React, { useEffect, useMemo, useRef, useState } from "react";

import { LazyEditor } from "@/components/common/LazyEditor";
import { TabContentContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { jsonToYaml } from "@/shared/utils/yaml/yaml";
import {
  selectCertificate,
  useAddonFullLiveState,
} from "@/components/k8sAddons/LiveStateDrawer/hooks/useAddonFullLiveState";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";

const EDITOR_HEIGHT_OFFSET = 16;

export const CertificateYAML: React.FC = () => {
  const { data: addonData } = useAddonFullLiveState(selectCertificate);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const stringifiedJsonAsYaml = useMemo(() => {
    const yamlAsJson = addonData?.data?.json;
    if (!yamlAsJson) return "";
    try {
      return jsonToYaml(JSON.stringify(yamlAsJson));
    } catch (error) {
      const uid = addonData?.data?.object.header.uid;
      notifyDDError(error as Error, {
        message: `Failed to parse YAML from JSON ${yamlAsJson} for object with UID ${uid}`,
      });
      return "";
    }
  }, [addonData?.data.json, addonData?.data.object.header.uid]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  }, []);

  return (
    <TabContentContainer ref={containerRef}>
      <LazyEditor
        width="100%"
        height={`${containerHeight - EDITOR_HEIGHT_OFFSET}px`}
        readOnly
        value={stringifiedJsonAsYaml}
        mode="yaml"
      />
    </TabContentContainer>
  );
};
