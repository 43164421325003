import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { InsightDetailsContentProps } from "../../insightUtils";
import { roundToNearestNSeconds } from "../../../Metrics/utils";
import { ThrottledCPURecord } from "../../models/ThrottledCPU";
import { InsightContentTopContainer } from "../common";

import ContainersTableMetrics, { PodDetails } from "./ContainersTableMetrics";

const Container = styled.div`
  background-color: white;
  border: 1px solid ${muiColors.gray[200]};
  border-radius: 4px;
`;

const ThrottledCpuContent: React.FC<InsightDetailsContentProps> = ({
  insightData,
  resource,
}) => {
  const endTimestamp = useMemo(
    () => roundToNearestNSeconds(new Date().getTime(), 60),
    []
  );

  const containersDetails: PodDetails[] = useMemo(() => {
    if (!insightData || !ThrottledCPURecord.guard(insightData)) {
      return [];
    }
    return insightData.containers;
  }, [insightData]);

  return (
    <InsightContentTopContainer>
      <Container>
        <ContainersTableMetrics
          endTimestamp={endTimestamp}
          podsDetails={containersDetails}
          resource={resource}
        />
      </Container>
    </InsightContentTopContainer>
  );
};

export default ThrottledCpuContent;
