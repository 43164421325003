import React, { useCallback, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { muiColors } from "@komodorio/design-system";

import { FormDetailsFields, InputFields } from "../policyDrawerTypes";
import { FormFieldContainer } from "../PolicyDrawerStyles";
import { HelperTextTypography } from "../HelperTextTypography";

import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";

const SelectContainer = styled.div<{ $hasError: boolean }>`
  .dropDownTriggerField {
    ${({ $hasError }) =>
      $hasError ? `border-color: ${muiColors.pink[500]};` : ""}
  }
`;

const TypographyWithMargin = styled(Typography)`
  && {
    margin-top: 8px;
  }
`;

type SelectFieldProps = {
  inputType: FormDetailsFields;
  label: string;
  options: MuiSelectionOption<string>[];
  helperText?: string;
  placeHolder?: string;
};

const optionsToStrings = (options: MuiSelectionOption<string>[]) =>
  options.map((option) => option.value);

const stringsToOptions = (strings: string[] = []) =>
  strings.map((string) => ({
    label: string,
    value: string,
  }));

export const SelectField: React.FC<SelectFieldProps> = ({
  inputType,
  label,
  options,
  helperText,
  placeHolder,
}) => {
  const { setValue, trigger, watch, getFieldState } =
    useFormContext<InputFields>();
  const { isSubmittingForm, isReadOnlyMode } = usePolicyDrawerContext();

  const onChange = useCallback(
    (selectedOptions: MuiSelectionOption<string>[]) => {
      setValue(inputType, optionsToStrings(selectedOptions));
      trigger(inputType);
    },
    [inputType, setValue, trigger]
  );

  const value = watch(inputType);
  const error = getFieldState(inputType)?.error;

  const readOnlyContent = useMemo(() => {
    if (!isReadOnlyMode) return null;
    return (
      <Box display={"flex"} gap={"4px"} flexWrap={"wrap"}>
        {((value as string[]) ?? []).map((option) => {
          return <Chip key={option} label={option} />;
        })}
      </Box>
    );
  }, [isReadOnlyMode, value]);

  const writableContent = useMemo(() => {
    if (isReadOnlyMode) return null;
    return (
      <SelectContainer $hasError={!!error?.message}>
        <AdvancedMultiSelect
          options={options}
          onChange={onChange}
          value={stringsToOptions(value as string[])}
          enableFreeSolo
          disabled={isSubmittingForm}
          ariaLabel={`select ${label}`}
          placeholder={placeHolder}
          classNames={{ dropDownTriggerField: "dropDownTriggerField" }}
        />
        <HelperTextTypography errorMessage={error?.message} />
        {helperText && (
          <TypographyWithMargin variant={"body3"} color={"text.secondary"}>
            {helperText}
          </TypographyWithMargin>
        )}
      </SelectContainer>
    );
  }, [
    error?.message,
    helperText,
    isReadOnlyMode,
    isSubmittingForm,
    label,
    onChange,
    options,
    placeHolder,
    value,
  ]);

  return (
    <FormFieldContainer>
      <Typography variant={"h5"} color={"text.secondary"}>
        {label}
      </Typography>
      <Box width={"100%"} aria-label={`${label} container`}>
        {isReadOnlyMode ? readOnlyContent : writableContent}
      </Box>
    </FormFieldContainer>
  );
};
