import { useCallback, useMemo } from "react";
import { camelCase } from "lodash";
import { ResourceTableModelRow } from "komodor-types";

import { LABELS_COLUMN } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ResourceListTableProps } from "../ResourceListTableProps";
import { useDrawerUrlState } from "../../../../shared/hooks/state";
import { Direction } from "../../ProcessList/SortTitle";
import { INSPECTION_SHOW_DELETED_PODS_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";

type GetRawHeaderProps = Pick<
  ResourceListTableProps,
  "resourceType" | "overridingColumns"
> & {
  headersOrder: string[];
};

export const useGetRawHeaders = ({
  resourceType,
  overridingColumns,
  headersOrder,
}: GetRawHeaderProps): string[] => {
  return useMemo(() => {
    const columnsToExclude = [LABELS_COLUMN]
      .concat(resourceType.UnnecessaryColumns)
      .map(camelCase);

    return (
      overridingColumns?.map(({ name }) => name) ??
      headersOrder.filter((h) => !columnsToExclude.includes(h))
    );
  }, [resourceType.UnnecessaryColumns, overridingColumns, headersOrder]);
};

type TableSortOrderProps = Pick<
  ResourceListTableProps,
  "initialSortOrder" | "resourceType"
>;

export const useTableSortOrder = ({
  initialSortOrder,
  resourceType,
}: TableSortOrderProps): number => {
  const [showDeletedPodsParam] = useDrawerUrlState<boolean>(
    INSPECTION_SHOW_DELETED_PODS_PARAM_KEY
  );
  const adjustInitialDirection = showDeletedPodsParam ? -1 : 1;
  return useMemo(
    () =>
      (initialSortOrder?.order ??
        resourceType.DefaultSort?.Direction ??
        Direction.down) * adjustInitialDirection,
    [
      adjustInitialDirection,
      initialSortOrder?.order,
      resourceType.DefaultSort?.Direction,
    ]
  );
};

type ToggleAllRowsSelectionProps = Pick<
  ResourceListTableProps,
  "rowSelection"
> & {
  allRowsSelected: boolean;
  rowsOnPage: ResourceTableModelRow[];
};

export const useToggleAllRowsSelection = ({
  rowSelection,
  allRowsSelected,
  rowsOnPage,
}: ToggleAllRowsSelectionProps): (() => void) => {
  return useCallback(() => {
    if (!rowSelection) return;

    if (!allRowsSelected) {
      const {
        selectedRowNames: selectedRowIds,
        unselectableRowNames: unselectableRowIds,
        onRowSelection,
        maxSelectableRows,
      } = rowSelection;
      toggleAllUnselectedRows(
        rowsOnPage,
        selectedRowIds,
        unselectableRowIds,
        maxSelectableRows,
        onRowSelection
      );
    } else {
      rowsOnPage
        .filter(
          ({ uid: rowUid }) =>
            !rowSelection.unselectableRowNames?.includes(rowUid)
        )
        .forEach(({ uid: rowUid }) => {
          rowSelection.onRowSelection(rowUid);
        });
    }
  }, [allRowsSelected, rowSelection, rowsOnPage]);
};

export const toggleAllUnselectedRows = (
  rows: ResourceTableModelRow[],
  selectedRowIds: string[],
  unselectableRowIds: string[] | undefined,
  maxSelectableRows: number,
  toggleRowSelection: (rowName: string) => void
): void =>
  rows
    .map(({ uid: rowUid }) => rowUid)
    .filter(
      (rowUid) =>
        !selectedRowIds.includes(rowUid) &&
        !unselectableRowIds?.includes(rowUid)
    )
    .slice(0, maxSelectableRows)
    .forEach((rowName) => {
      toggleRowSelection(rowName);
    });
