import { ServicesSortStrategy } from "komodor-types";
import { useCallback, useState } from "react";

import { useFetchServicesByPageMutation } from "../../../generated/graphql";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { processDataFromHasuraAction } from "../ProcessServiceFetchRequests";

import { CheckedFilters } from "./utils";

const useServicesByPage = (): {
  services: ServiceInfo[] | undefined;
  fetching: boolean;
  updateServices: (
    checkedFilters: CheckedFilters,
    textFilter: string | null,
    sortStrategy: ServicesSortStrategy | null,
    page: number
  ) => Promise<void>;
} => {
  const [{ fetching }, fetchServices] = useFetchServicesByPageMutation();
  const [paginatedServices, setPaginatedServices] = useState<ServiceInfo[]>();

  const updateServices = useCallback(
    async (
      checkedFilters: CheckedFilters,
      textFilter: string | null,
      sortStrategy: ServicesSortStrategy | null,
      page: number
    ) => {
      const { data } = await fetchServices({
        checkedFilters,
        textFilter: textFilter ?? "",
        sortStrategy: sortStrategy ?? ServicesSortStrategy.Health,
        page,
      });
      setPaginatedServices(
        processDataFromHasuraAction(data?.fetchServicesByPage)
      );
    },
    [fetchServices]
  );

  return {
    services: paginatedServices,
    fetching,
    updateServices,
  };
};

export default useServicesByPage;
