import { useMemo } from "react";

import { GitCompare } from "../../../../../shared/types/git";

type GitCompares = GitCompare[] | undefined;
const useFilteredGitCompares = (gitCompares: GitCompares): GitCompares =>
  useMemo(() => {
    return gitCompares?.some((c) => c.group !== "default")
      ? gitCompares.filter((c) => c.group !== "default")
      : gitCompares;
  }, [gitCompares]);

export default useFilteredGitCompares;
