import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { lightBlue, blackGreyText } from "../../../Colors";
import { useBannerUpdate } from "../../../shared/hooks/useBanner";

interface BannerProps {
  height: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Container = styled.div<{ sHeight: string }>`
  position: sticky;
  top: 0;
  z-index: 120;
  display: flex;
  background-color: ${lightBlue};
  height: ${({ sHeight }) => sHeight};
  padding: 1px;
  align-items: center;
  justify-content: center;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const bannerButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap !important;
  height: 1.5em;
  cursor: pointer;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  margin: 0px 10px;
  text-decoration: none;
`;

export const BannerButton = styled.a`
  ${bannerButtonStyle}
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 0;
  margin-right: 2rem;
  height: 1.2em;
  width: 1.2em;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    background-color: ${blackGreyText};
    clip-path: polygon(
      8% 0,
      0 8%,
      42% 50%,
      0 92%,
      8% 100%,
      50% 58%,
      92% 100%,
      100% 92%,
      58% 50%,
      100% 8%,
      92% 0,
      50% 42%
    );
  }
`;

const Banner: React.FC<BannerProps> = ({ height, onClose, children }) => {
  const setBanner = useBannerUpdate();

  useEffect(() => {
    setBanner && setBanner(true);
  }, [setBanner]);

  return (
    <Container sHeight={height}>
      {children}
      {onClose && <CloseIcon onClick={onClose} />}
    </Container>
  );
};

export default Banner;
