import { cloneDeep } from "lodash";
import { useEffect } from "react";

export const useSetWindowsFlags = (flags: Record<string, unknown>): void => {
  useEffect(() => {
    window.komodor = Object.assign(window.komodor || {}, {
      flags: cloneDeep(flags),
    });
  }, [flags]);
};

export default useSetWindowsFlags;
