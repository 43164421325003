import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { IntegrationType } from "komodor-types";
import { v4 as uuid } from "uuid";
import { Button } from "@komodorio/design-system/deprecated";

import { FormContainer } from "../CustomFormComponents";
import { H3 } from "../../../common/typography";
import CopyComponent from "../../../common/Copy";
import { contactMessage } from "../../../../constants";
import { useCreateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";

const Container = styled(FormContainer)`
  align-items: center;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #6e7a96;
`;

const HelpText = styled.p`
  font-style: italic;
  font-size: 0.85rem;
  color: #6e7a96;
`;

const createInstallationError = "Error while creating Opsgenie Integration";

const messages: Record<string, string[]> = {
  query: [createInstallationError, contactMessage],
};

const komodorHost = "https://app.komodor.com";
const webhookPath = "opsgenie/events";

const OpsgenieInstallation: React.FC<{ closeModalCallback: () => void }> = ({
  closeModalCallback,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [installationId] = useState(uuid());
  const { mutateAsync: createInstallation } = useCreateInstallation();

  const onCloseClick = useCallback(() => {
    (async function () {
      try {
        await createInstallation({
          id: installationId,
          integration: IntegrationType.OPSGENIE,
          configuration: {
            webhookPath: `${komodorHost}/${webhookPath}/${installationId}`,
          },
        });
      } catch (e) {
        setError("query");
        return;
      }
      if (closeModalCallback) {
        closeModalCallback();
      }
    })();
  }, [closeModalCallback, createInstallation, installationId]);

  return (
    <Container>
      <Title>Install Opsgenie Webhook Integration</Title>
      {installationId && (
        <>
          <H3>Copy the following URL in Opsgenie webhook configuration</H3>
          <br />
          <br />
          <CopyComponent
            value={`${komodorHost}/${webhookPath}/${installationId}`}
          />
          <br />
          <HelpText>
            The{" "}
            <a
              href="https://docs.opsgenie.com/docs/webhook-integration"
              target="_blank"
              rel="noreferrer"
            >
              Opsgenie webhook documentation
            </a>{" "}
            includes more in-depth configuration options and possibilities.
          </HelpText>
        </>
      )}
      <br />
      <Button variant="primary" onClick={onCloseClick}>
        Install Opsgenie integration
      </Button>
      <br />
      {error && <b>{messages[error] || contactMessage}</b>}
    </Container>
  );
};

export default OpsgenieInstallation;
