import { useEffect, useMemo, useState } from "react";

import {
  FetchKomodorServiceQuery,
  Komodor_Service,
  useFetchKomodorServiceQuery,
} from "../../generated/graphql";
import { useOverridableFlags } from "../context/featureFlags/OverridableFlags";
import { ServiceInfo } from "../types/ServiceInfo";

import useQueryPolling from "./useQueryPolling";
import { useGetKomodorService } from "./resources-api/client/komodor-services/useKomodorServices";

export enum Status {
  Loading,
  NotFound,
  Found,
}

export type KomodorService = FetchKomodorServiceQuery["komodor_service"][0];

export type CurrentService = KomodorService | undefined;
const useCurrentService = (
  serviceId: string
): {
  currentService: CurrentService;
  serviceFetchStatus: Status;
} => {
  const [lastServiceId, setLastServiceId] = useState<string>();

  const { getKomodorServiceFromResourcesApi } = useOverridableFlags();

  useEffect(() => {
    if (!lastServiceId && serviceId) {
      setLastServiceId(serviceId);
    }
  }, [lastServiceId, serviceId]);

  const variables = useMemo(() => {
    return {
      serviceId,
    };
  }, [serviceId]);

  const { data: resourcesApiData, isLoading: isResourcesApiQueryLoading } =
    useGetKomodorService(
      {
        fields: [
          "k8s_metadata",
          "deploy_state",
          "datadog_data",
          "health_state",
          "sentry_data",
        ],
        servicesIds: [variables.serviceId],
        isDeleted: false,
      },
      true,
      undefined,
      7500,
      !!getKomodorServiceFromResourcesApi
    );

  const [data, isFetching] = useQueryPolling(
    useFetchKomodorServiceQuery({ variables: variables, pause: !serviceId }),
    7500,
    !getKomodorServiceFromResourcesApi
  );

  return useMemo(() => {
    const loadingData = !data || (isFetching && lastServiceId !== serviceId);
    const loadingResourcesApiData =
      !resourcesApiData ||
      (isResourcesApiQueryLoading && lastServiceId !== serviceId);
    const isLoading = !getKomodorServiceFromResourcesApi
      ? loadingData
      : loadingResourcesApiData;
    if (isLoading) {
      return {
        currentService: undefined,
        serviceFetchStatus: Status.Loading,
        serviceType: undefined,
      };
    }

    const komodorServiceRes: (Komodor_Service | ServiceInfo)[] | undefined =
      getKomodorServiceFromResourcesApi
        ? resourcesApiData?.servicesWithLastUpdatedAt?.services
        : (data?.komodor_service as Komodor_Service[] | undefined);

    const service = komodorServiceRes?.find(({ id }) => id === serviceId) as
      | KomodorService //BUG: The type should be ServiceInfo instead of KomodorService when getKomodorServiceFromResourcesApi is true, potentially causing bug for useCurrentService callers https://app.clickup.com/t/86c01fbqq
      | undefined;

    let status: Status;
    if (service) {
      status = Status.Found;
      setLastServiceId(serviceId);
    } else {
      status = Status.NotFound;
    }

    return {
      currentService: service,
      serviceFetchStatus: status,
    };
  }, [
    data,
    getKomodorServiceFromResourcesApi,
    isFetching,
    isResourcesApiQueryLoading,
    lastServiceId,
    resourcesApiData,
    serviceId,
  ]);
};

export default useCurrentService;
