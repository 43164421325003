import React from "react";
import styled from "styled-components";
import { sortBy } from "lodash";

import { BoldGrayText } from "../../CheckDetailsDrawer";
import { NormalText, StyledTable } from "../../../../common/styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeSchedulableCheck from "../NodeSchedulableCheck";

const Row = styled.tr`
  line-height: 1rem;
`;

const TaintMessage: Record<string, string> = {
  "node.kubernetes.io/not-ready":
    "Node is not ready, which makes the node unschedulable for pods that are not tolerating this taint, the node controller will trigger an API-initiated eviction for Pods assigned to that node, Default eviction timeout duration is 5 minutes",
  "node.kubernetes.io/unreachable":
    "Node is unreachable, which makes the node unschedulable, the node controller will trigger an API-initiated eviction for Pods assigned to that node, Default eviction timeout duration is 5 minutes",
  "node.kubernetes.io/memory-pressure":
    "Memory pressure exists, which makes the node unschedulable for pods that are not tolerating this taint, eviction of pods might be happening",
  "node.kubernetes.io/disk-pressure":
    "Disk pressure exists, which makes the node unschedulable for pods that are not tolerating this taint, eviction of pods might be happening",
  "node.kubernetes.io/pid-pressure":
    "PID pressure exists, which makes the node unschedulable for pods that are not tolerating this taint, eviction of pods might be happening",
  "node.kubernetes.io/network-unavailable":
    "Network is unavailable, which makes the node unschedulable for pods that are not tolerating this taint",
  "node.kubernetes.io/unschedulable":
    "The node is cordoned, which makes it unschedulable",
  "node.cloudprovider.kubernetes.io/uninitialized":
    "When the kubelet is started with external cloud provider, this taint is set on a node to mark it as unusable",
};

const EffectsColumn = styled.div`
  white-space: pre-line;
`;
const NodeSchedulableDetails: React.FC<{
  check: NodeSchedulableCheck;
}> = ({ check }) => {
  const checkOutput = check.output;

  return !check.passed ? (
    checkOutput?.taints?.length ? (
      <>
        <br />
        <BoldGrayText>Node taints</BoldGrayText>
        <StyledTable>
          <thead>
            <tr>
              <th>Taint</th>
              <th>Effect</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {sortBy(checkOutput.taints, (t) => t.taint).map((taint, index) => (
              <Row key={index}>
                <td>{taint.taint}</td>
                <td>
                  <EffectsColumn>{taint.effects?.join(",\n")}</EffectsColumn>
                </td>
                <td>
                  {TaintMessage[taint.taint] ||
                    (taint.effects?.includes("NoExecute")
                      ? "Node is unschedulable for pods that are not tolerating this taint. Pods not tolerating this taint will be evicted after toleration timeout."
                      : "Node is unschedulable for pods that are not tolerating this taint.")}
                </td>
              </Row>
            ))}
          </tbody>
        </StyledTable>
      </>
    ) : !checkOutput?.isConditionsOk ? (
      <>
        <br />
        <NormalText>
          At least one of the node conditions is not ok, the node is suppose to
          be tainted soon and unschedulable for not tolerating pods .
        </NormalText>
      </>
    ) : null
  ) : null;
};

export default NodeSchedulableDetails;
