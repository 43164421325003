import React from "react";
import { parseISO } from "date-fns";

import EventGroup, { EventBase } from "../index";
import { customEventType } from "../commonEventGroup/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";
import { CustomEventResponseDataInnerSeverityEnum } from "../../../../generated/resourcesApi";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomEventDetails } from "./CustomEventDetails/CustomEventDetails";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { iconPropsBySeverity } from "./utils";
import { CustomEventWithServiceId } from "./types";

export interface CustomEvent extends EventBase {
  readonly severity: CustomEventResponseDataInnerSeverityEnum;
  readonly eventType: string;
  readonly summary: string;
  readonly servicesNames?: string[];
  readonly clusters?: string[];
  readonly namespaces?: string[];
  readonly details?: { [key: string]: string };
}

const toCustomEvent = (e: CustomEventWithServiceId): CustomEvent => ({
  ...e,
  eventTime: parseISO(e.eventTime ?? ""),
  type: customEventType,
  severity: e.severity ?? "information",
  eventType: e.eventType ?? "Custom Event",
  details: e.details,
  summary: e.summary ?? "",
  serviceId: e.serviceId,
});

export default class CustomEventGroup implements EventGroup {
  readonly events: CustomEvent[];
  readonly id;
  readonly type;
  readonly eventType;
  readonly severity;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly status;
  readonly eventDetails;
  readonly servicesNames;
  readonly clusters;
  readonly namespaces;
  readonly serviceId;
  readonly backgroundColor;
  readonly fillColor;
  readonly icon;

  constructor(e: CustomEvent) {
    const { icon, backgroundColor, fillColor } =
      iconPropsBySeverity[e.severity];

    this.events = [e];
    this.id = `${e.serviceId}:${e.id}`;
    this.type = e.eventType;
    this.eventType = e.eventType;
    this.servicesNames = e.servicesNames;
    this.clusters = e.clusters;
    this.namespaces = e.namespaces;
    this.summary = e.summary;
    this.startTime = e.eventTime;
    this.endTime = e.eventTime;
    this.eventDetails = e.details;
    this.details = "details";
    this.status = "";
    this.serviceId = e.serviceId;
    this.severity = e.severity;
    this.backgroundColor = backgroundColor;
    this.fillColor = fillColor;
    this.icon = icon;
  }

  renderEventDetails(onClose?: () => void): JSX.Element {
    return <CustomEventDetails event={this} onClose={onClose} />;
  }
}

const serviceNamesToServiceIds = (
  events: CustomEventWithServiceId[],
  serviceId: string
) => events.map((e) => ({ ...e, services: [serviceId] }));

export const groupCustomEvents = (
  events: CustomEventWithServiceId[],
  serviceId: string
): CustomEventGroup[] => {
  const uniqueEvents = events.filter((event, index, self) => {
    return self.findIndex((e) => e.id === event.id) === index;
  });
  const eventsWithServicesProperty = serviceNamesToServiceIds(
    uniqueEvents,
    serviceId
  );

  return mapAndSort(
    withServices(eventsWithServicesProperty, serviceId),
    toCustomEvent
  ).map((e) => new CustomEventGroup(e));
};
