import { SubmitHandler } from "react-hook-form";
import { UseMutationResponse } from "urql";

import {
  AddExternalLinkMutation,
  AddExternalLinkMutationVariables,
  External_Link_Context_Enum,
} from "../../../../../../../generated/graphql";
import { Options } from "../../../../../../../shared/hooks/roles/usePrivilegedOptions";
import {
  getLinkInfo,
  isValidUrl,
  UpsertLinkErrorType,
  FormValues,
} from "../utils";
import { AnalyticEvents } from "../../../../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../../../../shared/hooks/analytics";

type AddLinkMutation = UseMutationResponse<
  AddExternalLinkMutation,
  AddExternalLinkMutationVariables
>;

const addLinkSubmitHandler: (
  addLinkMutation: AddLinkMutation,
  acceptedCluster: boolean,
  acceptedNamespace: boolean,
  handleClose: () => void,
  setErrorMessage: React.Dispatch<React.SetStateAction<UpsertLinkErrorType>>,
  privilegedOptions: Options | undefined,
  context: External_Link_Context_Enum,
  k8sClusterName?: string,
  namespace?: string,
  serviceId?: string
) => SubmitHandler<FormValues> =
  (
    addLinkMutation,
    acceptedCluster,
    acceptedNamespace,
    handleClose,
    setErrorMessage,
    privilegedOptions,
    context,
    k8sClusterName,
    namespace,
    serviceId
  ) =>
  async (values) => {
    const { link, name } = values;
    if (!isValidUrl(link)) {
      setErrorMessage(UpsertLinkErrorType.LinkNotValid);
      return;
    }
    const [, addLink] = addLinkMutation;
    const { error, data } = await addLink(
      getLinkInfo(
        link,
        name,
        acceptedCluster,
        acceptedNamespace,
        context,
        k8sClusterName,
        namespace,
        serviceId
      ),
      { fetchOptions: privilegedOptions?.context.fetchOptions }
    );
    if (data?.insert_external_link_one?.id) {
      dispatchEvent(AnalyticEvents.Links.Add_External_Link_Submit, {
        linkContext: context,
      });
      handleClose();
    }
    if (error) {
      setErrorMessage(UpsertLinkErrorType.AddLinkError);
    }
  };

export default addLinkSubmitHandler;
