import React, { useMemo } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { recommendationsPadding } from "../styles";
import { useRecommendationsModalContext } from "../context/useRecommendationsModalContext";
import { useExecuteUpdateResources } from "../hooks/useExecuteUpdateResources";
import { CostRightSizingSummary } from "../../../../../../generated/metricsApi";
import { isInvalidResourceValues } from "../utils/validateResourceValues";
import { costOptimizationAriaLabels } from "../../../../constants/costOptimizationAriaLabels";

const Container = styled.div`
  display: flex;
  ${recommendationsPadding}
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const {
  rightSizingPage: {
    recommendationModal: {
      buttons: {
        updateButton: updateButtonAriaLabel,
        cancelButton: cancelButtonAriaLabel,
        resetButton: resetButtonAriaLabel,
      },
    },
  },
} = costOptimizationAriaLabels;

interface Props {
  data: CostRightSizingSummary | undefined;
  onClose: () => void;
  applyRecommendations: (isAppliedSuccessfully: boolean) => void;
  updatedContainersCount: number | undefined;
}

export const Actions: React.FC<Props> = ({
  data,
  onClose,
  applyRecommendations,
  updatedContainersCount,
}) => {
  const { containers, resetContainers } = useRecommendationsModalContext();

  const isDisabled = useMemo(() => {
    if (!containers.length || !updatedContainersCount) return true;
    return containers.some((container) => {
      const isCpuInvalid = isInvalidResourceValues(container.resources, "cpu");
      const isMemoryInvalid = isInvalidResourceValues(
        container.resources,
        "memory"
      );

      return isCpuInvalid || isMemoryInvalid;
    });
  }, [containers, updatedContainersCount]);

  const { execute, deniedObject } = useExecuteUpdateResources(data);
  const onUpdate = () => {
    execute();
    applyRecommendations(!deniedObject);
    onClose();
  };
  return (
    <Container>
      <Button aria-label={cancelButtonAriaLabel} onClick={onClose}>
        Cancel
      </Button>
      <Button
        aria-label={resetButtonAriaLabel}
        variant="outlined"
        color="primary"
        onClick={resetContainers}
      >
        Reset values
      </Button>
      <Button
        aria-label={updateButtonAriaLabel}
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={onUpdate}
      >
        Update {updatedContainersCount} containers
      </Button>
    </Container>
  );
};
