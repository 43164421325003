import React from "react";
import Language from "@mui/icons-material/LanguageOutlined";

import { EXTERNAL_DNS_DEMO_ROUTE } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

const DISPLAY_NAME = "External DNS";

export const ExternalDNSDemo: React.FC = () => {
  return (
    <AppBarLink
      text={DISPLAY_NAME}
      route={EXTERNAL_DNS_DEMO_ROUTE}
      icon={<Language fontSize="small" />}
      tag="beta"
    />
  );
};
