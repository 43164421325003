import styled from "styled-components";

const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  display: ${({ open }) => (open ? undefined : "none")};
  width: 100vw;
  height: 100vh;
  z-index: 17;
`;

export const DarkenOverlay = styled(Overlay)`
  background-color: rgba(61, 64, 72, 0.6);
`;

export default Overlay;
