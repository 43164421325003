import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Record, String } from "runtypes";
import { IntegrationType } from "komodor-types";
import { compact } from "lodash";
import { Select } from "@komodorio/design-system/deprecated";

import AvailableIntegrationTile from "../../../../integrations/management/available/AvailableIntegrationTile";
import definitions from "../../../../integrations/management/definitions";
import useInstallationSubscription from "../../../../integrations/management/installed/useInstallationSubscription";
import { useFetchAllSlackChannelsMutation } from "../../../../../generated/graphql";
import { lightStyle } from "../styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowConfiguration } from "../../../common/types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { Suggestions } from ".";

const IntegrationConfig = Record({ botAccessToken: String });

const Label = styled.div`
  ${lightStyle}
  padding-bottom: 0.5rem;
`;

const StyledAvailableIntegrationTile = styled(AvailableIntegrationTile)`
  width: 20rem;
  min-height: 2rem;
  border: 1px solid #dcdddf;
  box-shadow: unset;
  padding: 0.75rem 1.5rem;
`;

const SlackSinkOutput: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule: configData, setRule: setConfigData }) => {
  const installations = useInstallationSubscription().data;

  const loading = useMemo(() => {
    return installations === undefined;
  }, [installations]);

  const token = useMemo(() => {
    const configurations = installations
      ?.filter((i) => i.integration === IntegrationType.SLACK)
      .find((i) => IntegrationConfig.guard(i.configuration));
    return IntegrationConfig.guard(configurations?.configuration)
      ? configurations?.configuration.botAccessToken
      : null;
  }, [installations]);

  const [channels, setChannels] = useState<string[]>([]);
  const fetchChannels = useFetchAllSlackChannelsMutation()[1];
  useEffect(() => {
    (async () => {
      if (!token) return;
      const { data } = await fetchChannels({ token: token });
      setChannels(compact(data?.fetchAllSlackChannels?.channels).sort());
    })();
  }, [fetchChannels, token]);

  const [suggestions, setSuggestions] = useState<Suggestions>([]);
  useEffect(() => {
    if (channels.length === 0) return;
    setSuggestions(channels?.map((c) => ({ label: c, value: c })));
  }, [channels]);

  const [channel, setChannel] = useState(configData.sinks?.slack?.[0] ?? "");
  useEffect(() => {
    setConfigData({
      ...configData,
      sinks: {
        ...configData.sinks,
        slack: channel === "" ? undefined : [channel],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return loading ? (
    <></>
  ) : token ? (
    <Select
      size="medium"
      width="100%"
      label="Slack Channel"
      listMaxHeight="15rem"
      placeholder="select channel"
      searchable={suggestions.length > 5}
      options={suggestions}
      value={
        suggestions.find((o) => o.value === channel) ?? {
          label: channel,
          value: channel,
        }
      }
      onChange={(selected) => {
        setChannel(selected.value);
      }}
      onClean={() => setChannel("")}
    />
  ) : (
    <>
      <Label>
        Keep your team up-to-date with the current state and health of your
        system by installing Komodor's Slack integration
      </Label>
      <StyledAvailableIntegrationTile
        integration={definitions.slack}
        newTab={true}
      />
    </>
  );
};

export default SlackSinkOutput;
