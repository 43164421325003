import { MAX_TIMELINE_COMPONENTS } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/timelineEventsConstants";

export const getTimelineComponentTextMaxWidth = (totalEvents: number) => {
  switch (totalEvents) {
    case MAX_TIMELINE_COMPONENTS:
      return 104;
    default:
      return 120;
  }
};
