import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";
import { severitiesList } from "../policyDrawerConstants";
import { ConfigurationControlCheckedState } from "../context/PolicyDrawerContext";

import { CheckType, PolicyConfigurations } from "@/generated/reliabilityApi";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";

export const useDeletePolicyConfiguration = () => {
  const {
    deletePolicyConfiguration,
    configurationsControlCheckedState,
    setAllControlCheckedState,
  } = usePolicyDrawerContext();
  const { unregister } = useFormContext();

  return useCallback(
    (checkType: CheckType) => {
      deletePolicyConfiguration(checkType as keyof PolicyConfigurations);
      severitiesList.forEach((severity) => {
        const configurationFields = getConfigurationFieldNamesByCheckTypeAsList(
          checkType,
          severity
        );
        configurationFields.forEach((name) => {
          unregister(name);
        });
      });

      // Remove all checked states for the configurations of the deleted check type
      const newConfigurationsControlCheckedState = Object.entries(
        configurationsControlCheckedState
      ).reduce<ConfigurationControlCheckedState>((acc, [key, value]) => {
        if (key.includes(checkType)) {
          return acc;
        }
        acc[key] = value;
        return acc;
      }, {});

      setAllControlCheckedState(newConfigurationsControlCheckedState);
    },
    [
      configurationsControlCheckedState,
      deletePolicyConfiguration,
      setAllControlCheckedState,
      unregister,
    ]
  );
};
