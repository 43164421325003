import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { ServiceIdentifierType } from "komodor-types";

import { Dictionary } from "../../../../../../../shared/types/Dictionary";
import { AppErrors } from "../types/creationPageTypes";
import { useOverridableFlags } from "../../../../../../../shared/context/featureFlags/OverridableFlags";

const initialCreationPageState: CreationPageState = {
  appName: "",
  description: "",
  selectedServiceIds: {},
  isSavingAppView: false,
  errorState: {},
  appViewType: undefined,
  setAppName: () => undefined,
  setDescription: () => undefined,
  setSelectedServiceIds: () => undefined,
  setErrorState: () => undefined,
  setIsSavingAppView: () => undefined,
  resetState: () => undefined,
  setServiceIdentifiers: () => undefined,
  setAppViewType: () => undefined,
};

type CreationPageErrorState = {
  appNameError?: AppErrors;
  servicesSelectedError?: string;
  savingAppViewError?: boolean;
  agentVersionNeedsUpgradeError?: boolean;
};

type CreationPageState = {
  appName: string;
  description?: string;
  selectedServiceIds: Dictionary<boolean>;
  selectedServiceIdentifiers?: string[];
  errorState: CreationPageErrorState;
  isSavingAppView: boolean;
  setAppName: (val: string) => void;
  setDescription: (val: string) => void;
  setSelectedServiceIds: Dispatch<SetStateAction<Dictionary<boolean>>>;
  setServiceIdentifiers: (serviceIdentifiers: string[]) => void;
  setErrorState: (val: Partial<CreationPageErrorState>) => void;
  setIsSavingAppView: (val: boolean) => void;
  resetState: () => void;
  appViewType: ServiceIdentifierType | undefined;
  setAppViewType: (appViewType: ServiceIdentifierType | undefined) => void;
};

const CreationPageContext = createContext<CreationPageState>(
  initialCreationPageState
);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useCreationPageContext = (): CreationPageState =>
  useContext(CreationPageContext);

const {
  appName: initialAppName,
  selectedServiceIds: initialServiceIds,
  isSavingAppView: initialIsSavingAppView,
  errorState: initialErrorState,
} = initialCreationPageState;
export const CreationPageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { enableAppViewDynamicScoping } = useOverridableFlags();
  const [appName, setAppName] = useState<string>(initialAppName);
  const [description, setDescription] = useState<string>();
  const [selectedServiceIds, setSelectedServiceIds] =
    useState<Dictionary<boolean>>(initialServiceIds);
  const [serviceIdentifiers, setServiceIdentifiers] = useState<string[]>();

  const initialAppViewType = enableAppViewDynamicScoping
    ? undefined
    : ServiceIdentifierType.SERVICE_ID;
  const [appViewType, setAppViewType] = useState<
    ServiceIdentifierType | undefined
  >(initialAppViewType);

  const [errorState, setErrorState] =
    useState<CreationPageErrorState>(initialErrorState);
  const [isSavingAppView, setIsSavingAppView] = useState<boolean>(
    initialIsSavingAppView
  );

  const resetState = useCallback(() => {
    setAppName(initialAppName);
    setDescription(undefined);
    setSelectedServiceIds(initialServiceIds);
    setErrorState(initialErrorState);
    setIsSavingAppView(initialIsSavingAppView);
  }, []);

  const onSetErrorState = useCallback(
    (val: Partial<CreationPageErrorState>) => {
      setErrorState({
        ...errorState,
        ...val,
      });
    },
    [errorState]
  );

  const contextState: CreationPageState = {
    appName,
    setAppName,
    description,
    setDescription,
    selectedServiceIds,
    setSelectedServiceIds,
    errorState,
    setErrorState: onSetErrorState,
    isSavingAppView,
    setIsSavingAppView,
    resetState,
    appViewType,
    setAppViewType,
    selectedServiceIdentifiers: serviceIdentifiers,
    setServiceIdentifiers,
  };

  return (
    <CreationPageContext.Provider value={contextState}>
      {children}
    </CreationPageContext.Provider>
  );
};
