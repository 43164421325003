import React from "react";
import styled from "styled-components";
import Modal from "react-modal";

import { lightStyle, titleStyle } from "../monitorsConfiguration/common/styles";
import CopyCode from "../../integrations/installation/kubernetes/CopyCode";
import { UpgradeCommands } from "../../common/upgradeAgentCta/UpgradeAgentCTA";
import { TextRegular } from "../../common/typography";
import { gray10 } from "../../../Colors";
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";

import { CloseIcon } from "./Header";

const NotUsingHelmContainer = styled.div`
  color: ${gray10};
`;
const customModalStyles = {
  overlay: { zIndex: 10000, background: "rgb(0,0,0,0.6)" },
  content: {
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.10)",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    height: "21rem",
    width: "40rem",
    borderRadius: "1.5rem",
    overflow: "hidden",
  },
};

const UpgradeAgentModalContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% auto;
  width: fit-content;
  align-items: start;
  ${lightStyle}
`;
const CopyCodeWider = styled(CopyCode)`
  max-width: 40rem;
  width: inherit;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  ${titleStyle}
`;
const Container = styled.div`
  padding: 0 1rem;
`;
const UpgradeAgentModal: React.FC<{
  closeModalCallback: () => void;
  upgradeCommand: string;
}> = ({ closeModalCallback, upgradeCommand }) => {
  const docsStr = useGetHelmInstallationCommand(UpgradeCommands.UPGRADE_DOCS);
  return (
    <>
      <Modal
        isOpen
        onRequestClose={closeModalCallback}
        style={customModalStyles}
      >
        <Container>
          <Header>
            <Title>Upgrade Agent</Title>
            <CloseIcon onClick={() => closeModalCallback()} />
          </Header>
          <UpgradeAgentModalContainerLeft>
            <TextRegular>
              The following helm command will upgrade Komodor’s agent on your
              cluster
            </TextRegular>
            <CopyCodeWider value={upgradeCommand} width="34rem" />
            <br />
            <NotUsingHelmContainer>
              <div>Not using helm?</div>
              <div>
                Read about other ways to install our agent in our{" "}
                <a rel="noreferrer" target="_blank" href={docsStr}>
                  Installation Guide
                </a>
              </div>
            </NotUsingHelmContainer>
          </UpgradeAgentModalContainerLeft>
        </Container>
      </Modal>
    </>
  );
};

export default UpgradeAgentModal;
