import React from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Header from "./Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Category, { CategoryProps } from "./Category/Category";
import { FilterBarLoader } from "./FilterBarLoader";

export interface FilterValue {
  value: string;
  count: number;
  checked: boolean;
  onChange: () => void;
}
export interface FilterBarCategory {
  name: string;
  values: FilterValue[];
  isFinishedLoadingValues?: boolean;
  isCategoryCollapsed?: boolean;
  expandCategory?: () => void;
  collapseCategory?: () => void;
}

export type SaveFilter = ((name: string) => Promise<void>) | undefined;

export interface FilterBarHeaderProps {
  title?: string;
  saveFilter?: SaveFilter | undefined;
  showSave?: boolean;
}

export type FilterBarProps = FilterBarHeaderProps & {
  categories: FilterBarCategory[];
  isLoading?: boolean;
  inputFilter?: JSX.Element;
} & Pick<CategoryProps, "renderCheckboxFieldName">;

const Container = styled.div`
  margin-block-start: 1.2rem;
  display: grid;
  gap: 1.1rem;
`;

const FilterBar: React.FC<FilterBarProps> = ({
  categories,
  isLoading = false,
  saveFilter,
  title,
  inputFilter,
  showSave = false,
  renderCheckboxFieldName,
}) => {
  return (
    <Container data-e2e-selector="filtersBar-container">
      <Header saveFilter={saveFilter} title={title} showSave={showSave} />
      {inputFilter}
      {!isLoading ? (
        categories.map((c) => (
          <Category
            key={c.name}
            category={c}
            renderCheckboxFieldName={renderCheckboxFieldName}
          />
        ))
      ) : (
        <FilterBarLoader />
      )}
    </Container>
  );
};

export default FilterBar;
