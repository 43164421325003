import React from "react";
import { useParams } from "react-router-dom";

import WorkflowDag from "@/components/ResourceView/resources/workflowDag";
import ResourceView from "@/components/ResourceView/ResourceView";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { Addon, Entity, WorkflowDagFromJSON } from "@/generated/addonsApi";
import { parseKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { PageLoader } from "@/shared/components/PageLoader";
import PathNotFound from "@/components/404/PathNotFound";

export const DagView: React.FC = () => {
  const { komodorWorkflowId } = useParams<{
    komodorWorkflowId: string;
  }>();

  const { cluster, dagId } = parseKomodorWorkflowId(komodorWorkflowId ?? "");

  const { data, isFetching } = useGetAddonFullLiveState(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      uid: komodorWorkflowId ?? "",
      name: dagId,
      getEntityRequest: {
        clusterName: cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: WorkflowDagFromJSON(json?.data?.data),
    })
  );

  return isFetching ? (
    <PageLoader />
  ) : data?.data ? (
    <ResourceView resource={new WorkflowDag(data.data)} />
  ) : (
    <PathNotFound />
  );
};
