import { compact, isEqual } from "lodash";
import { useEffect, useRef } from "react";

import {
  ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum,
  RelatedResourcesResponse,
} from "../../../../../generated/resourcesApi";
import {
  Resource,
  ResourceType,
  ResponseState,
} from "../types/appViewsResourcesTypes";
import useKomodorServices from "../../../../../shared/hooks/useKomodorServices";
import { parseKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useResourcesAPIPost } from "../../../../../shared/hooks/resources-api/client";
import { KOMODOR_SERVICES_RELATED_RESOURCES } from "../../../../../shared/hooks/resources-api/requestResponseMaps";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";
import {
  selectedAppViewIdSelector,
  servicesUIDsSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAppViewsResourcesStore } from "../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStore";
import { selectUpdateResources } from "../../../../../shared/store/appViewsResourcesStore/appViewsResourcesStoreSelectors";
import { getK8sResourceFromKind } from "../utils/getK8sResource";
import { getResourceIdentification } from "../../../../../shared/utils/appViews/getResourceIdentification";
import { useWorkspaces } from "../../../../workspaces/WorkspacesTopBar/hooks";

const requestFields: ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum[] =
  [
    "HpaUids",
    "K8sServicesUids",
    "PodUids",
    "IngressUids",
    "NodesUids",
    "ConfigMapUids",
    "SecretUids",
    "PvcUids",
  ];

const FieldEnumToDataResponseKey: Partial<
  Record<ResourceType, keyof RelatedResourcesResponse>
> = {
  configmaps: "ConfigMapUids",
  secrets: "SecretUids",
  persistentvolumeclaims: "PvcUids",
  horizontalpodautoscalers: "HpaUids",
  services: "K8sServicesUids",
  ingresses: "IngressUids",
  nodes: "NodesUids",
  pods: "PodUids",
};

export const usePopulateResources = (): void => {
  const { scopeHasBeenLoaded } = useKomodorServices(); // Services are scoped by current App View.
  const komodorServiceUids = useAppViewsStore(servicesUIDsSelector);
  const { isWorkspaceKindBackendFiltered } = useWorkspaces();

  const serviceUids = compact(komodorServiceUids);
  const lastReqServiceUids = useRef(serviceUids);
  const response = useResourcesAPIPost(
    KOMODOR_SERVICES_RELATED_RESOURCES,
    {
      fields: requestFields,
      komodorUids: serviceUids,
    },
    !komodorServiceUids.length || !scopeHasBeenLoaded
  );

  const { refresh } = response;

  useEffect(() => {
    if (isWorkspaceKindBackendFiltered) {
      return;
    }
    if (!isEqual(lastReqServiceUids.current, serviceUids)) {
      lastReqServiceUids.current = serviceUids;
      refresh();
    }
  }, [refresh, serviceUids, isWorkspaceKindBackendFiltered]);

  usePopulateResourcePerType("horizontalpodautoscalers", response);
  usePopulateResourcePerType("services", response);
  usePopulateResourcePerType("nodes", response);
  usePopulateResourcePerType("ingresses", response);
  usePopulateResourcePerType("configmaps", response);
  usePopulateResourcePerType("secrets", response);
  usePopulateResourcePerType("persistentvolumeclaims", response);
  usePopulateResourcePerType("pods", response);
};

const usePopulateResourcePerType = (
  type: ResourceType,
  response: ResponseState
): void => {
  useUpdateResourcesEffect(type, response);
};

const convertToResource = (
  type: ResourceType,
  uids?: string[]
): (Resource | undefined)[] => {
  if (type === "pods") {
    return uids?.map((uid) => convertPodToResource(uid)) ?? [];
  }
  return uids?.map((uid) => convertKomodorUidsToResource(uid as string)) ?? [];
};
const useUpdateResourcesEffect = (
  type: ResourceType,
  response: ResponseState
): void => {
  const { error, loading } = response;
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const updateResources = useAppViewsResourcesStore(selectUpdateResources);
  const uids = getUidsFromType(type, response);
  const { isWorkspaceKindBackendFiltered } = useWorkspaces();

  useEffect(() => {
    if (isWorkspaceKindBackendFiltered || error) return;
    const resources = convertToResource(type, uids);

    updateResources({
      type,
      isLoading: loading,
      resources: resources.filter((r) => r !== undefined) as Resource[],
    });
  }, [
    uids,
    error,
    loading,
    updateResources,
    type,
    selectedAppViewId,
    isWorkspaceKindBackendFiltered,
  ]);
};

const getUidsFromType = (type: ResourceType, response: ResponseState) => {
  const dataProp = FieldEnumToDataResponseKey[type];
  return response.data && dataProp ? response.data[dataProp] : undefined;
};

function convertKomodorUidsToResource(
  komodorUid: string
): Resource | undefined {
  const uidParsed = parseKomodorUid(komodorUid);
  if (!uidParsed) return undefined;
  const nameInK8s = getK8sResourceFromKind(uidParsed.kind)?.NameInK8S;
  if (!nameInK8s) return undefined;
  return {
    id: getResourceIdentification({
      clusterName: uidParsed.cluster,
      namespace: uidParsed.namespace,
      resourceName: uidParsed.name,
      k8sResourceName: nameInK8s,
    }),
    name: uidParsed.name,
  };
}

function convertPodToResource(uid: string): Resource | undefined {
  const uidParsed = parseKomodorUid(uid);
  if (!uidParsed) return undefined;
  const nameInK8s = getK8sResourceFromKind(uidParsed.kind)?.NameInK8S;
  if (!nameInK8s) return undefined;
  return {
    id: getResourceIdentification({
      clusterName: uidParsed.cluster,
      namespace: uidParsed.namespace,
      resourceName: uidParsed.name,
      k8sResourceName: nameInK8s,
    }),
    name: uidParsed.name,
  };
}
