import React, { ChangeEvent } from "react";
import styled, { css } from "styled-components";
import { useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

import { InputFields } from "../policyDrawerTypes";
import { FormFieldContainer } from "../PolicyDrawerStyles";
import { HelperTextTypography } from "../HelperTextTypography";
import { NumberTextField } from "../PolicyDrawerConfigurations/NumberTextField";

import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { convertNumberStringToNumber } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/policyDrawerUtils";

const InputStyle = css<{ $maxWidth?: number }>`
  && {
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "none")};
    border-color: ${muiColors.pink[600]};
    width: 100%;
    & > div {
      height: 32px;
    }
    input {
      font-size: 14px;
      &::placeholder {
        opacity: 1;
        color: ${muiColors.gray[500]};
      }
    }
  }
`;

const StyledInput = styled(TextField)<{ $maxWidth?: number }>`
  ${InputStyle};
`;

const StyledNumberTextField = styled(NumberTextField)<{ $maxWidth?: number }>`
  ${InputStyle};
`;

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  color: ${muiColors.gray[500]};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

type InputFieldProps = {
  inputType: keyof InputFields;
  label: string;
  maxWidth?: number;
  type?: "text" | "number";
  placeholder?: string;
  helperText?: string;
  tooltipContent?: React.ReactNode;
};

export const InputField: React.FC<InputFieldProps> = ({
  inputType,
  label,
  maxWidth,
  placeholder,
  type = "text",
  tooltipContent,
}) => {
  const { setValue, trigger, watch, getFieldState } =
    useFormContext<InputFields>();
  const { isSubmittingForm, isReadOnlyMode } = usePolicyDrawerContext();

  const onInputFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(inputType, e.target.value);
    trigger(inputType);
  };

  const onNumberFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(inputType, convertNumberStringToNumber(e.target.value));
    trigger(inputType);
  };

  const value = watch(inputType);
  const error = getFieldState(inputType)?.error;

  const content = isReadOnlyMode ? (
    <Typography variant={"body2"} sx={{ marginTop: "6px" }}>
      {value as string}
    </Typography>
  ) : (
    <>
      {type === "text" ? (
        <StyledInput
          $maxWidth={maxWidth}
          size={"small"}
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onInputFieldChange}
          error={Boolean(error)}
          disabled={isSubmittingForm}
          inputProps={{
            "aria-label": `${label} input field`,
          }}
        />
      ) : (
        <StyledNumberTextField
          $maxWidth={maxWidth}
          size={"small"}
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onNumberFieldChange}
          error={Boolean(error)}
          allowDecimal={false}
          allowNegative={false}
          max={99999999}
          disabled={isSubmittingForm}
          inputProps={{
            "aria-label": `${label} input field`,
          }}
        />
      )}

      <HelperTextTypography errorMessage={error?.message} />
    </>
  );

  return (
    <FormFieldContainer>
      <FlexContainer>
        <Typography variant={"h5"} color={"text.secondary"}>
          {label}
        </Typography>
        {tooltipContent && (
          <Tooltip title={tooltipContent} placement={"right"}>
            <StyledHelpOutlineIcon />
          </Tooltip>
        )}
      </FlexContainer>
      <Box width={"100%"} aria-label={`${label} container`}>
        {content}
      </Box>
    </FormFieldContainer>
  );
};
