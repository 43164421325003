import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChannelType } from "komodor-types";

import slackIcon from "../../../../integrations/management/logos/slack.svg";
import teamsIcon from "../../../../integrations/management/logos/teams.svg";
import opsGenieIcon from "../../../../integrations/management/logos/opsgenie.svg";
import pagerDutyIcon from "../../../../integrations/management/logos/pagerduty.svg";
import webhookIcon from "../../../../integrations/management/logos/webhook.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AvailableSinks, WorkflowConfiguration } from "../../../common/types";
import AvailableWorkflows from "../../../common/availableWorkflows";
import { useOverridableFlags } from "../../../../../shared/context/featureFlags/OverridableFlags";

import SinkTile from "./SinkTile";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import SlackSinkOutput from "./SlackSinkOutput";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import TeamsSinkOutput from "./TeamsSinkOutput";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import OpsgenieSinkOutput from "./OpsgenieSinkOutput";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PagerDutySinkOutput from "./PagerDutySinkOutput";
import WebhookSinkOutput from "./WebhookSinkOutput";

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const SinkTiles = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 1.5rem;
`;

export type GenericSuggestions<T> = { label: string; value: T }[];
export type Suggestions = GenericSuggestions<string>;

const SinkSection: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule, setRule }) => {
  const [selectedSink, setSelectedSink] = useState<ChannelType>();
  const { showWebhookSink } = useOverridableFlags();

  const availableSinksForWorkflow = AvailableWorkflows[rule.type].sinks;
  const slackConfigured = !!rule.sinks?.slack?.[0];
  const teamsConfigured = !!rule.sinks?.teams?.[0];
  const opsgenieConfigured = !!rule.sinks?.opsgenie?.[0];
  const pagerdutyConfigured = !!rule.sinks?.pagerduty?.[0];
  const webhookConfigured = !!rule.sinks?.genericWebhook?.[0];

  useEffect(() => {
    if (!selectedSink && rule.sinks) {
      let channelType: ChannelType = ChannelType.Slack;
      if (slackConfigured) {
        channelType = ChannelType.Slack;
      }
      if (teamsConfigured) {
        channelType = ChannelType.Teams;
      }
      if (opsgenieConfigured) {
        channelType = ChannelType.Opsgenie;
      }
      if (pagerdutyConfigured) {
        channelType = ChannelType.PagerDuty;
      }
      setSelectedSink(channelType);
    }
  }, [
    opsgenieConfigured,
    pagerdutyConfigured,
    rule.sinks,
    selectedSink,
    slackConfigured,
    teamsConfigured,
    webhookConfigured,
  ]);

  return (
    <Container>
      <SinkTiles>
        {availableSinksForWorkflow.includes(AvailableSinks.Slack) && (
          <SinkTile
            icon={slackIcon}
            name={AvailableSinks.Slack}
            selected={!selectedSink || selectedSink === ChannelType.Slack}
            configured={slackConfigured}
            onClick={() => setSelectedSink(ChannelType.Slack)}
          />
        )}
        {availableSinksForWorkflow.includes(AvailableSinks.Teams) && (
          <SinkTile
            icon={teamsIcon}
            name={AvailableSinks.Teams}
            selected={selectedSink === ChannelType.Teams}
            configured={teamsConfigured}
            onClick={() => setSelectedSink(ChannelType.Teams)}
          />
        )}
        {availableSinksForWorkflow.includes(AvailableSinks.Opsgenie) && (
          <SinkTile
            icon={opsGenieIcon}
            name={AvailableSinks.Opsgenie}
            selected={selectedSink === ChannelType.Opsgenie}
            configured={opsgenieConfigured}
            onClick={() => setSelectedSink(ChannelType.Opsgenie)}
          />
        )}
        {availableSinksForWorkflow.includes(AvailableSinks.PagerDuty) && (
          <SinkTile
            icon={pagerDutyIcon}
            name={AvailableSinks.PagerDuty}
            selected={selectedSink === ChannelType.PagerDuty}
            configured={pagerdutyConfigured}
            onClick={() => setSelectedSink(ChannelType.PagerDuty)}
          />
        )}
        {availableSinksForWorkflow.includes(AvailableSinks.GenericWebhook) && (
          <SinkTile
            icon={webhookIcon}
            name={AvailableSinks.GenericWebhook}
            selected={selectedSink === ChannelType.GenericWebhook}
            configured={webhookConfigured}
            disabled={!showWebhookSink}
            onClick={() => setSelectedSink(ChannelType.GenericWebhook)}
          />
        )}
      </SinkTiles>
      {!selectedSink || selectedSink === ChannelType.Slack ? (
        <SlackSinkOutput rule={rule} setRule={setRule} />
      ) : selectedSink === ChannelType.Teams ? (
        <TeamsSinkOutput rule={rule} setRule={setRule} />
      ) : selectedSink === ChannelType.Opsgenie ? (
        <OpsgenieSinkOutput rule={rule} setRule={setRule} />
      ) : selectedSink === ChannelType.PagerDuty ? (
        <PagerDutySinkOutput rule={rule} setRule={setRule} />
      ) : showWebhookSink && selectedSink === ChannelType.GenericWebhook ? (
        <WebhookSinkOutput rule={rule} setRule={setRule} />
      ) : null}
    </Container>
  );
};

export default SinkSection;
