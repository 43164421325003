import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import { LabeledSwitch } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import { HorizontalContainer } from "@/components/Settings/General/BetaFeatures/componentsStyles";

export const Stack = styled(HorizontalContainer)`
  align-items: center;
  justify-content: space-between;
`;

interface FeatureControlsProps {
  featureEnabled: boolean;
  handleDisableClick: () => void;
  handleEnableClick: () => void;
  handleConfigureClick?: () => void;
}

export const RbacClusterSyncFeatureControls: React.FC<FeatureControlsProps> = ({
  featureEnabled,
  handleDisableClick,
  handleEnableClick,
  handleConfigureClick,
}) => {
  const onEnableChange = useCallback(
    (shouldEnable: boolean) => {
      shouldEnable ? handleEnableClick() : handleDisableClick();
    },
    [handleDisableClick, handleEnableClick]
  );
  return (
    <div>
      <Stack>
        <LabeledSwitch
          checked={featureEnabled}
          onCheck={onEnableChange}
          label={featureEnabled ? "Enabled" : "Disabled"}
        />
        {featureEnabled && (
          <Button
            startIcon={<TuneIcon />}
            variant={"text"}
            size={"medium"}
            sx={{
              width: "5rem",
            }}
            onClick={handleConfigureClick}
          >
            Configure
          </Button>
        )}
      </Stack>
    </div>
  );
};
