import { useCallback } from "react";

import {
  useGetAllPolicies,
  useInsertPolicy,
  useUpdatePolicy,
} from "@/shared/hooks/reliability-api/policies/usePolicies";
import { CreateOrUpdatePolicyRequest } from "@/generated/reliabilityApi";
import { useHandleServerErrorMessages } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHandleServerErrorMessages";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";
import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import { selectSetReliabilityNotification } from "@/components/reliability/store/reliabilityStoreSelectors";
import { reliabilitySuccessMessages } from "@/components/reliability/constants/reliabilityConstants";
import { SubmitPolicyType } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { useClearPolicyDataFromQueryCache } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useClearPolicyDataFromQueryCache";

export const useSubmitPolicy = () => {
  const { mutateAsync: createPolicy } = useInsertPolicy();
  const { mutateAsync: updatePolicy } = useUpdatePolicy();
  const { refetch: refetchAllPolicies } = useGetAllPolicies();
  const handleServerErrorMessages = useHandleServerErrorMessages();
  const { onClose: onCloseDrawer, setIsSubmittingForm } =
    usePolicyDrawerContext();
  const setNotification = useReliabilityStore(selectSetReliabilityNotification);
  const clearPolicyDataFromQueryCache = useClearPolicyDataFromQueryCache();

  return useCallback(
    async (type: SubmitPolicyType, params: CreateOrUpdatePolicyRequest) => {
      setIsSubmittingForm(true);
      try {
        if (type === "create") {
          await createPolicy({ createOrUpdatePolicyRequest: params });
        } else {
          await updatePolicy({
            id: params.policy.id,
            createOrUpdatePolicyRequest: params,
          });
        }

        const successMessage =
          type === "create"
            ? reliabilitySuccessMessages.policyCreated
            : reliabilitySuccessMessages.policyUpdated;

        if (type === "update") {
          // Clear the policy data from the query cache to ensure that the updated policy data is fetched
          clearPolicyDataFromQueryCache({
            id: params.policy.id,
          });
        }
        await refetchAllPolicies();
        onCloseDrawer();
        setNotification({
          message: successMessage,
          severity: "info",
        });
      } catch (error) {
        handleServerErrorMessages(error);
      }
      setIsSubmittingForm(false);
    },
    [
      clearPolicyDataFromQueryCache,
      createPolicy,
      handleServerErrorMessages,
      onCloseDrawer,
      refetchAllPolicies,
      setIsSubmittingForm,
      setNotification,
      updatePolicy,
    ]
  );
};
