import { useCallback, useMemo } from "react";

import {
  FetchWorkflowConfigurationsQuery,
  useFetchWorkflowConfigurationByIdQuery,
  useFetchWorkflowConfigurationsQuery,
} from "../../generated/graphql";
import { useQueryRefreshWithVariables } from "../../shared/hooks/useQueryWithVariables";

import { useWorkflowsFlags } from "./common/availableWorkflows";
import {
  Sensor,
  Sinks,
  SinksOptions,
  Variables,
  WorkflowConfigType,
  WorkflowConfiguration,
} from "./common/types";

type RawWorkflowConfiguration =
  FetchWorkflowConfigurationsQuery["workflow_configuration"][0];
const parseWorkflowConfiguration = (
  wc: RawWorkflowConfiguration
): WorkflowConfiguration => {
  return {
    id: wc.id,
    name: wc.name,
    createdAt: new Date(wc.createdAt),
    updatedAt: new Date(wc.updatedAt) ?? undefined,
    type: wc.type as WorkflowConfigType,
    active: wc.active,
    sensors: wc.sensors as Sensor[],
    variables: (wc.variables as Variables) ?? undefined,
    sinks: (wc.sinks as Sinks) ?? undefined,
    sinksOptions: (wc.sinksOptions as SinksOptions) ?? undefined,
  };
};

export const useWorkflowConfigurationById = (
  configId: string | undefined
): {
  workflowConfiguration: WorkflowConfiguration | null;
  refreshFromNetwork: () => void;
} => {
  const variables = useMemo(() => (configId ? { configId } : null), [configId]);
  const [data, refreshFromNetwork] = useQueryRefreshWithVariables(
    useFetchWorkflowConfigurationByIdQuery,
    variables
  );

  return {
    workflowConfiguration: data?.workflow_configuration.length
      ? parseWorkflowConfiguration(data.workflow_configuration[0])
      : null,
    refreshFromNetwork: refreshFromNetwork,
  };
};

const useWorkflowConfigurations = (): {
  accountConfigurations: WorkflowConfiguration[];
  refreshFromNetwork: () => void;
  isFetchingConfigurations: boolean;
  isEmptyConfigurations: boolean;
} => {
  const [{ data }, refresh] = useFetchWorkflowConfigurationsQuery();
  const wfFlags = useWorkflowsFlags();

  const refreshFromNetwork = useCallback(() => {
    refresh({ requestPolicy: "network-only" });
  }, [refresh]);

  const [
    accountConfigurations,
    isFetchingConfigurations,
    isEmptyConfigurations,
  ] = useMemo(() => {
    return data?.workflow_configuration
      ? data?.workflow_configuration.length > 0
        ? [
            data.workflow_configuration
              .map(parseWorkflowConfiguration)
              .filter(({ type }) =>
                Object.values(WorkflowConfigType).includes(type)
              )
              .filter((config) => wfFlags[config.type]),
            false,
            false,
          ]
        : [[], false, true]
      : [[], true, false];
  }, [data?.workflow_configuration, wfFlags]);

  return {
    accountConfigurations,
    refreshFromNetwork,
    isFetchingConfigurations,
    isEmptyConfigurations,
  };
};

export default useWorkflowConfigurations;
