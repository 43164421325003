import React, { useMemo } from "react";
import styled from "styled-components";

import { useGroups } from "../../../../hooks/reliabilityHooks";
import { LoaderContainer } from "../../../shared/LoaderContainer";
import { ErrorContainer } from "../../../shared/ErrorContainer";
import { useReportDDRumWithReactQuery } from "../../../../../../shared/hooks/datadog-rum/useReportDDRumWithReactQuery";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { GroupByOptions } from "../../../../ReliabilityTypes";
import {
  violationsGroupByCluster,
  violationsGroupByImpactGroup,
  violationsGroupByCheckType,
} from "../../../../constants/dataDogReporting";
import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";

import { ViolationsGroup } from "./ViolationsGroup/ViolationsGroup";
import { NoViolationsEmptyState } from "./NoViolationsEmptyState";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const {
  impactGroups: { mainContainer: impactGroupsMainContainer },
  clusterGroups: { mainContainer: clusterGroupsMainContainer },
  checkTypeGroups: { mainContainer: checkTypeGroupsMainContainer },
} = reliabilityArialLabels;

const groupsMapper: Record<
  GroupByOptions,
  { ariaLabel: string; viewName: string }
> = {
  Impact: {
    ariaLabel: impactGroupsMainContainer,
    viewName: violationsGroupByImpactGroup,
  },
  Cluster: {
    ariaLabel: clusterGroupsMainContainer,
    viewName: violationsGroupByCluster,
  },
  CheckType: {
    ariaLabel: checkTypeGroupsMainContainer,
    viewName: violationsGroupByCheckType,
  },
};

export const GroupedViolations: React.FC = () => {
  const result = useGroups();
  const groupBy = useGetGroupBy();
  const groupByAsGroupByOptions = groupBy as GroupByOptions;
  const { data, isFetching, error } = result;

  const { ariaLabel, viewName } = groupsMapper[groupByAsGroupByOptions];

  const content = useMemo(() => {
    if (isFetching) return <LoaderContainer />;
    if (error) return <ErrorContainer />;
    if (!data?.data) return null;

    if (data.data.groups.length === 0) return <NoViolationsEmptyState />;

    return data.data.groups.map((group, idx) => (
      <ViolationsGroup
        group={group}
        groupBy={groupByAsGroupByOptions}
        key={`${group.id}-${idx}`}
      />
    ));
  }, [data?.data, error, groupByAsGroupByOptions, isFetching]);

  useReportDDRumWithReactQuery({
    result,
    viewName: viewName,
  });

  return <Container aria-label={ariaLabel}>{content}</Container>;
};
