import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { muiColors } from "@komodorio/design-system";

import { Deploy } from "../../../../generated/monitorsApi";

import CorrelatedDeploysCards from "./CorrelatedDeploysCards";
import RelatedServicesDeploysCards from "./RelatedServicesDeploysCards";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  gap: 3px;
`;

interface HeaderProps {
  title: string;
  subtitle: string;
}

interface CorrelatedDeploysContentProps {
  correlatedDeploys: Deploy[];
  relatedServicesDeploys: {
    upstream: Deploy[];
    downstream: Deploy[];
  };
}

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => {
  return (
    <HeaderContainer>
      <Typography variant="h2" color={muiColors.gray[800]}>
        {title}
      </Typography>
      <Typography variant="body1" color={muiColors.gray[500]}>
        {subtitle}
      </Typography>
    </HeaderContainer>
  );
};

export const CorrelatedDeploysContent: React.FC<
  CorrelatedDeploysContentProps
> = ({ correlatedDeploys, relatedServicesDeploys }) => {
  return (
    <div>
      <div>
        <Header
          title="Deploys in this service"
          subtitle={
            correlatedDeploys.length
              ? "We have identified some recent deploys in these services, which are correlated with this issue."
              : "No deploys in this service, which are correlated with this issue were identified."
          }
        />
        <CorrelatedDeploysCards correlatedDeploys={correlatedDeploys} />
      </div>
      <Divider
        sx={{
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      />
      <div>
        <Header
          title="Changes in related services"
          subtitle={
            relatedServicesDeploys.upstream.length ||
            relatedServicesDeploys.downstream.length
              ? "We have identified some recent deploys in related services, which are correlated with this issue."
              : "No deploys in related services (upstream or downstream) that are correlated with this issue were identified."
          }
        />
        <RelatedServicesDeploysCards
          upstream={relatedServicesDeploys.upstream}
          downstream={relatedServicesDeploys.downstream}
        />
      </div>
    </div>
  );
};
