import React from "react";
import { useParams } from "react-router-dom";

import ResourceView from "../ResourceView/ResourceView";
import useResourceWithInterval from "../ResourceView/useResource";
import { useActiveAgent } from "../../shared/hooks/useAgents";
import { LinesLoader } from "../common/loaders/Line";
import { parseKomodorUid } from "../../shared/hooks/resources-api/resourcesAPIUtils";

import { useDefaultArgoWorkflowTemplate } from "./useDefaultArgoWorkflowTemplate";
import { ArgoWorkflowNotFound } from "./ArgoWorkflowNotFound";

type ArgoWorkflowParams = {
  argoWorkflowId?: string;
};
const useArgoWorkflowParams = (): ArgoWorkflowParams => {
  return useParams<ArgoWorkflowParams>();
};

const ArgoWorkflowView: React.FC = () => {
  const { argoWorkflowId } = useArgoWorkflowParams();
  const parsedArgoWorkflowKomodorUid = parseKomodorUid(argoWorkflowId ?? "");

  const agentId = useActiveAgent(parsedArgoWorkflowKomodorUid?.cluster) ?? "";

  const { resource, isFetching } = useResourceWithInterval({
    agentId,
    cluster: parsedArgoWorkflowKomodorUid?.cluster ?? "",
    namespace: parsedArgoWorkflowKomodorUid?.namespace ?? "",
    resourceName: parsedArgoWorkflowKomodorUid?.name ?? "",
    resourceType: parsedArgoWorkflowKomodorUid?.kind ?? "",
    executeMutation: !!parsedArgoWorkflowKomodorUid && agentId !== "",
  });

  const defaultResource = useDefaultArgoWorkflowTemplate(
    parsedArgoWorkflowKomodorUid
  );

  return isFetching ? (
    <LinesLoader />
  ) : resource ? (
    <ResourceView resource={resource} />
  ) : defaultResource ? (
    <ResourceView resource={defaultResource} />
  ) : (
    <ArgoWorkflowNotFound />
  );
};

export default ArgoWorkflowView;
