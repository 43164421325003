import React from "react";
import { formatDistanceStrict, parseISO } from "date-fns";

import {
  greenForUI,
  greenForUIText,
  pinkBrand,
  pinkForUI,
} from "../../../../Colors";
import { JobFragment } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { groupEventsByKey, mapAndSort } from "../groupEvents";
import EventGroup, { EventBase } from "..";
import JobScatterShape from "../shapes/job.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import JobEventDetails from "../../ProcessList/details/JobEventDetails";
import { Selector } from "../../../../shared/types/ServiceVertex";
import {
  JobState,
  JobStatusInterface,
  jobType,
} from "../../../../shared/types/job";

enum ActionType {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export interface SubEvent {
  status: unknown;
  eventTime: string;
  action: string;
}

const getStateReasonMessage = (
  status: unknown,
  action: string
): [JobState, string, string] => {
  const eventStatus = status as JobStatusInterface | undefined;

  if (action === ActionType.Create && !eventStatus?.conditions) {
    return [JobState.started, "", ""];
  }
  if (action === ActionType.Delete) {
    return [JobState.deleted, "", ""];
  }

  const completeCondition = eventStatus?.conditions?.find(
    (c) => c.type === "Complete"
  );
  const failedCondition = eventStatus?.conditions?.find(
    (c) => c.type === "Failed"
  );
  const suspendedCondition = eventStatus?.conditions?.find(
    (c) => c.type === "Suspended"
  );

  if (completeCondition && completeCondition.status === "True") {
    return [
      JobState.completed,
      completeCondition.reason,
      completeCondition.message,
    ];
  } else if (failedCondition && failedCondition.status === "True") {
    return [JobState.failed, failedCondition.reason, failedCondition.message];
  } else if (suspendedCondition && suspendedCondition.status === "True") {
    return [
      JobState.suspended,
      suspendedCondition.reason,
      suspendedCondition.message,
    ];
  } else if (eventStatus?.active) {
    if (suspendedCondition && suspendedCondition.status === "False") {
      return [
        JobState.inprogress,
        suspendedCondition.reason,
        suspendedCondition.message,
      ];
    }
    return [JobState.inprogress, "", ""];
  }

  return [JobState.unknown, "", ""];
};

const getDetails = (events: JobEvent[]) => {
  const completedEvent = events.find(
    (e) => e.state === JobState.completed || e.state === JobState.failed
  );
  if (completedEvent) {
    return (
      "Ran: " +
      formatDistanceStrict(completedEvent.eventTime, events[0].eventTime)
    );
  }
  return events[events.length - 1].message;
};

export interface JobEvent extends EventBase {
  id: string;
  uid: string;
  name: string;
  state: JobState;
  eventTime: Date;
  reason: string;
  message: string;
  podsStatus: {
    active: number;
    failed: number;
    succeeded: number;
  };
  cronJobUid: string;
  action: ActionType;
  selector: Selector | undefined;
  clusterName: string;
  namespace: string;
  subEvents: SubEvent[] | null;
}

const toJobEvent = (e: JobFragment, serviceId: string): JobEvent => {
  const [state, reason, message] = getStateReasonMessage(e.status, e.action);
  const {
    active = 0,
    failed = 0,
    succeeded = 0,
  } = (e.status as JobStatusInterface | undefined) ?? {};
  return {
    serviceId,
    type: jobType,
    id: e.id,
    uid: e.uid,
    name: e.name,
    eventTime: parseISO(e.eventTime),
    state,
    reason,
    message,
    podsStatus: { active, failed, succeeded },
    cronJobUid: e.cronJobUid ?? "",
    action: e.action as ActionType,
    selector: e.selector as Selector,
    namespace: e.namespace,
    clusterName: e.clusterName ?? "",
    subEvents: e.subEvents ? (e.subEvents as SubEvent[]) : null,
  };
};

const mapSubEventsToJobEvents = (event: JobEvent): JobEvent[] => {
  return (event.subEvents ?? []).map((subEvent) => {
    const [state, reason, message] = getStateReasonMessage(
      subEvent.status,
      subEvent.action
    );
    const {
      active = 0,
      failed = 0,
      succeeded = 0,
    } = (subEvent.status as JobStatusInterface | undefined) ?? {};
    return {
      serviceId: event.serviceId,
      type: jobType,
      id: event.id,
      uid: event.uid,
      name: event.name,
      eventTime: parseISO(subEvent.eventTime),
      state,
      reason,
      message,
      podsStatus: { active, failed, succeeded },
      cronJobUid: event.cronJobUid ?? "",
      action: subEvent.action as ActionType,
      selector: event.selector as Selector,
      namespace: event.namespace,
      clusterName: event.clusterName ?? "",
      subEvents: null,
    };
  });
};

export default class JobEventGroup implements EventGroup {
  readonly backgroundColor;
  readonly fillColor;
  readonly icon = JobScatterShape;

  readonly events: JobEvent[];
  readonly id;
  readonly jobUid;
  readonly name;
  readonly type = jobType;
  readonly startTime;
  readonly endTime;
  readonly status;
  readonly reason;
  readonly message;
  readonly podsStatus;
  readonly isStandalone;
  readonly isFinished;
  readonly isFailed;
  readonly labelSelector;

  serviceId;

  static getFillColor = (isFailed: boolean): string => {
    return isFailed ? pinkBrand : greenForUIText;
  };

  constructor(events: JobEvent[]) {
    this.events = events;
    if (events[0].subEvents) {
      this.events = mapSubEventsToJobEvents(events[0]).sort(
        (e1, e2) => e1.eventTime.getTime() - e2.eventTime.getTime()
      );
    }

    const firstEvent = this.events[0];
    this.labelSelector = firstEvent.selector;
    const lastEvent = this.events[this.events.length - 1];

    this.isStandalone = !lastEvent.cronJobUid;

    this.id = firstEvent.id;
    this.jobUid = firstEvent.uid;
    this.name = firstEvent.name;
    this.serviceId = firstEvent.serviceId;
    this.status = lastEvent.state;
    this.startTime = firstEvent.eventTime;
    this.endTime = this.calculateEndTime();
    this.reason = lastEvent.reason;
    this.message = lastEvent.message;
    this.podsStatus = lastEvent.podsStatus;

    this.isFinished = this.events.some(
      (e) => e.state === JobState.completed || e.state === JobState.failed
    );
    this.isFailed = this.events.some((e) => e.state === JobState.failed);

    this.backgroundColor = this.isFailed ? pinkForUI : greenForUI;
    this.fillColor = JobEventGroup.getFillColor(this.isFailed);
  }

  get summary(): string {
    return this.name;
  }

  get details(): string {
    return getDetails(this.events);
  }

  renderEventDetails(): JSX.Element {
    return <JobEventDetails eventGroup={this} />;
  }

  calculateEndTime(): Date {
    for (let i = this.events.length - 1; i > 0; --i) {
      if (this.events[i].state !== JobState.deleted) {
        return this.events[i].eventTime;
      }
    }
    return this.events[this.events.length - 1].eventTime;
  }
}

export const groupJobEvents = (
  events: JobFragment[] | undefined
): JobEventGroup[] => {
  if (!events) return [];

  return groupEventsByKey(mapAndSort(events, toJobEvent), (e) => e.uid)
    .map((g) => new JobEventGroup(g))
    .filter(
      (g) =>
        g.events.length !== 1 ||
        (g.events.length === 1 && g.events[0].action !== "Delete")
    );
};
