import React from "react";
import styled from "styled-components";
import { TooltipWrapper } from "react-tooltip";

import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import { Sensor } from "./types";
import { FilterTag } from "./styles";

const NamespacesContainer = styled.div<{ wrapDiv: boolean }>`
  display: flex;
  align-items: center;
  ${({ wrapDiv }) => (wrapDiv ? "flex-wrap: wrap;" : "")}
`;

const ExtraNamespaces = styled.span`
  cursor: default;
`;

const createNamespacesLabel = (
  namespaces: Array<string> | undefined,
  showAll: boolean
) => {
  const extraNamespaces =
    namespaces && namespaces.length > 1 ? namespaces.slice(1).join(",\n") : "";
  if (namespaces?.length) {
    if (showAll) {
      return (
        <>
          {namespaces?.map((namespace) => (
            <FilterTag>
              Namespace: <b>{namespace}</b>
            </FilterTag>
          ))}
        </>
      );
    }
    return (
      <>
        <FilterTag>
          Namespace: <b>{namespaces[0]}</b>
        </FilterTag>
        <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content={extraNamespaces}>
          <ExtraNamespaces>
            {namespaces.length > 1 && <>+{namespaces.length - 1} more</>}
          </ExtraNamespaces>
        </TooltipWrapper>
      </>
    );
  }
  return (
    <FilterTag>
      Namespace: <b>All</b>
    </FilterTag>
  );
};

const SensorLabels: React.FC<{
  sensor: Sensor;
  showAllNamespaces?: boolean;
}> = ({ sensor, showAllNamespaces = false }) => {
  return (
    <>
      {sensor?.cluster && (
        <FilterTag>
          Cluster: <b>{sensor.cluster}</b>
        </FilterTag>
      )}
      <NamespacesContainer wrapDiv={showAllNamespaces}>
        {createNamespacesLabel(sensor?.namespaces, showAllNamespaces)}
      </NamespacesContainer>
    </>
  );
};

export default SensorLabels;
