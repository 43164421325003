import { useMemo } from "react";

import { useAccountName } from "./useAccountName";
import useKomodorServices from "./useKomodorServices";

import { getServiceIdFromKomodorUid } from "@/shared/utils/getServiceIdFromKomodorUid";

export const useGetServiceIdFromKomodorUid = (
  komodorUid?: string,
  serviceShouldExist = true
) => {
  const accountName = useAccountName();
  const servicesDic = useKomodorServices().servicesAsDictionary.all;

  return useMemo(() => {
    return getServiceIdFromKomodorUid({
      komodorUid,
      accountName,
      servicesDic,
      serviceShouldExist,
    });
  }, [accountName, komodorUid, serviceShouldExist, servicesDic]);
};
