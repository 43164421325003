import { CombinedError } from "urql";

import {
  InstallationsPerAccountSubscription,
  useInstallationsPerAccountSubscription,
} from "../../../../generated/graphql";

export type Installation =
  InstallationsPerAccountSubscription["installation"][0];

export interface InstallationsResponse {
  data: Installation[] | undefined;
  error: CombinedError | undefined;
  fetching: boolean;
}

export default (): InstallationsResponse => {
  const [{ data, error, fetching }] = useInstallationsPerAccountSubscription();
  return { data: data?.installation, error, fetching };
};
