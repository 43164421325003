import React, { useMemo } from "react";
import { SentryIssueEventData } from "komodor-types";
import { Dictionary } from "lodash";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import SentryEventGroup from "../../../EventGroup/SentryEventGroup";
import { EventDetailsContainer } from "../common";
import EventDetailsSection from "../components/EventDetailsSection";
import EventDetailsTitle from "../components/EventDetailsTitle";
import EventsGrid from "../components/EventsGrid";
import eventsIcon from "../../assets/events.svg";
import detailsIcon from "../../assets/details.svg";
import sentryIcon from "../../assets/sentry-purple.svg";
import DetailsList from "../components/DetailsList";
import IntegrationLink from "../components/LinkSection/IntegrationLink";
import useUserMetadata from "../../../../../shared/hooks/useUserMetadata/useUserMetadata";
import useInstallationSubscription from "../../../../integrations/management/installed/useInstallationSubscription";

interface SentryEventDetailsProps {
  eventGroup: SentryEventGroup;
}
export const DEFAULT_SENTRY_ADDRESS = "https://sentry.io";

const INNOVORDER_ACCOUNT_ID = "f83160c7-3b40-4f04-9103-6f3bf670d0f2";
const useSentryUrl = (
  details: SentryIssueEventData | undefined,
  domain: string,
  orgName?: string
) => {
  const { accountId } = useUserMetadata();
  if (accountId === INNOVORDER_ACCOUNT_ID) {
    return `${domain}/organizations/innovorder/issues/${details?.issueId}`;
  }
  if (details?.shareId) {
    return `${domain}/share/issue/${details?.shareId}`;
  }
  if (orgName) {
    return `${domain}/organizations/${orgName}/issues/${details?.issueId}?project=${details?.sentryProjectId}`;
  }
  return `${domain}/organizations/${details?.projectName}/issues/${details?.issueId}`;
};

const SentryEventDetails: React.FC<SentryEventDetailsProps> = ({
  eventGroup,
}) => {
  const events = eventGroup.events;
  const lastEvent = events[events.length - 1];
  const details = lastEvent.additionalData;
  const installations = useInstallationSubscription();

  const { domain, orgName } = useMemo(() => {
    const configuration = installations?.data?.find(
      (i) => i.integration === "sentry"
    )?.configuration;

    const domain = configuration
      ? (configuration as Dictionary<string>)["domain"] ??
        DEFAULT_SENTRY_ADDRESS
      : DEFAULT_SENTRY_ADDRESS;
    const orgName = configuration
      ? (configuration as Dictionary<string>)["orgName"]
      : undefined;
    return { domain, orgName };
  }, [installations]);

  const preparedEvents = useMemo(
    () =>
      events.map((e) => ({
        eventTime: e.eventTime,
        status: e.additionalData?.status ?? "",
        details: "",
      })),
    [events]
  );
  const detailsList = useMemo(
    () => [
      { label: "Kind", value: details?.kind },
      { label: "Level", value: details?.level },
      { label: "Issue ID", value: details?.shortId },
    ],
    [details]
  );
  const sentryUrl = useSentryUrl(details, domain, orgName);
  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={eventGroup.summary} />
      <EventDetailsSection title="Events" icon={eventsIcon}>
        <EventsGrid events={preparedEvents} />
      </EventDetailsSection>
      <EventDetailsSection
        title="Details"
        icon={detailsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
      >
        <DetailsList details={detailsList} />
      </EventDetailsSection>
      <IntegrationLink
        single
        icon={sentryIcon}
        href={sentryUrl}
        title="View issue on Sentry"
      />
    </EventDetailsContainer>
  );
};

export default SentryEventDetails;
