import React from "react";
import styled from "styled-components";

import { H2, H3Light } from "../../typography";

import ErrorIcon from "./error.svg?react";
import { MessageContainer } from "./MessageContainer";
import NotFoundIcon from "./not-found.svg?react";
import UpgradeIcon from "./upgrade.svg?react";

const Icon = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? "#d6effe"};
  padding-bottom: 1rem;
`;

const StyledH3Light = styled(H3Light)`
  padding-top: 1rem;
  word-break: break-word;
  text-align: center;
  width: 23rem;
  white-space: break-spaces;
`;

const StyledH2Bottom = styled(H2)<{ customSecondLineStyle?: string }>`
  ${({ customSecondLineStyle }) => customSecondLineStyle ?? ""}
`;

const StyledH2Top = styled(H2)<{ customFirstLineStyle?: string }>`
  font-family: poppins;
  ${({ customFirstLineStyle }) => customFirstLineStyle ?? ""}
`;

const icons = {
  error: ErrorIcon,
  "not-found": NotFoundIcon,
  upgrade: UpgradeIcon,
};

const PodMessage: React.FC<{
  title: string;
  message?: string;
  titleSecondLine?: string;
  customMargin?: string;
  icon: null | keyof typeof icons;
  iconColor?: string;
  customFirstLineStyle?: string;
  customSecondLineStyle?: string;
  children?: React.ReactNode;
}> = ({
  title,
  message,
  titleSecondLine,
  customMargin,
  icon,
  iconColor,
  customFirstLineStyle,
  customSecondLineStyle,
  children,
}) => {
  const IconComponent = icon && icons[icon];

  return (
    <MessageContainer customMargin={customMargin}>
      {IconComponent ? (
        <Icon color={iconColor}>
          <IconComponent />
        </Icon>
      ) : null}
      <StyledH2Top customFirstLineStyle={customFirstLineStyle}>
        {title}
      </StyledH2Top>
      {titleSecondLine && (
        <StyledH2Bottom customSecondLineStyle={customSecondLineStyle}>
          {titleSecondLine}
        </StyledH2Bottom>
      )}
      {message && <StyledH3Light>{message}</StyledH3Light>}
      {children}
    </MessageContainer>
  );
};

export default PodMessage;
