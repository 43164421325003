import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import { useDebouncedCallback } from "use-debounce";

import { Operator, GenericFilter } from "@/generated/addonsApi";
import { useStateInUrlAsJSON } from "@/shared/hooks/persistentStateAsJSON";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { AddonUrlState } from "@/components/k8sAddons/types";

const SEARCH_DEBOUNCE_DELAY = 250;

type SearchFieldProps = Omit<
  ComponentProps<typeof SearchField>,
  "onSearch" | "value"
>;

type SearchFieldAsGenericFilterProps = {
  onSearch?: (value: string) => void;
  searchDebounce?: number;
  filterName?: string;
  searchFieldProps?: SearchFieldProps;
};

export const useSearchFieldAsGenericFilter = ({
  onSearch,
  searchDebounce = SEARCH_DEBOUNCE_DELAY,
  filterName = "name",
  searchFieldProps,
}: SearchFieldAsGenericFilterProps) => {
  const [dataInUrl, setDataInUrl] = useStateInUrlAsJSON<AddonUrlState>(
    K8S_ADD_ONS_FILTERS_PARAM_KEY
  );

  const [localSearchValue, setLocalSearchValue] = useState<string>(
    dataInUrl?.tableState?.searchTerm ?? ""
  );

  const searchValueFromUrl = dataInUrl?.tableState?.searchTerm ?? "";
  const setSearchValueInUrl = useCallback(
    (value: string) => {
      setDataInUrl({
        tableState: {
          ...dataInUrl?.tableState,
          searchTerm: value,
        },
      });
    },
    [dataInUrl?.tableState, setDataInUrl]
  );

  useEffect(() => {
    if (searchValueFromUrl === "") {
      setLocalSearchValue(searchValueFromUrl);
    }
  }, [searchValueFromUrl]);

  const { callback: debouncedSetSearchValueAsFilter } = useDebouncedCallback(
    setSearchValueInUrl,
    searchDebounce
  );

  const onSearchCb = useCallback(
    (value: string) => {
      setLocalSearchValue(value);
      onSearch && onSearch(value);
      debouncedSetSearchValueAsFilter(value);
    },
    [debouncedSetSearchValueAsFilter, onSearch, setLocalSearchValue]
  );

  const tableSearchAsGenericFilter = useMemo<GenericFilter | undefined>(() => {
    if (searchValueFromUrl === "") {
      return undefined;
    }
    return {
      value: [searchValueFromUrl],
      operator: Operator.Like,
      name: filterName,
    };
  }, [filterName, searchValueFromUrl]);

  const component = (
    <SearchField
      {...searchFieldProps}
      onSearch={onSearchCb}
      value={localSearchValue}
    />
  );

  return {
    searchFieldComponent: component,
    searchFilter: tableSearchAsGenericFilter,
  };
};
