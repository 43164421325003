import React from "react";
import Alert from "@mui/material/Alert";

import ResponsiveLayout from "../../common/ResponsiveLayout";
import { OverviewContainer } from "../ClusterOverview/styles";
import { useGetWorkspaceById } from "../../../shared/hooks/workspaces-api/workspaces/useGetWorkspaceById";
import { PageLoader } from "../../../shared/components/PageLoader";
import { ReliabilitySection } from "../ClusterOverview/components/ReliabilitySection/ReliabilitySection";
import { CostSection } from "../ClusterOverview/components/CostSection/CostSection";

import { ClustersGroupOverviewHeader } from "./components/ClustersGroupOverviewHeader";
import { partialClustersMessage } from "./constants";

import { ClustersGroupInventorySection } from "@/components/ClustersView/ClustersGroupOverview/components/ClustersGroupInventorySection/ClustersGroupInventorySection";
import { ClustersGroupWorkspace } from "@/generated/workspacesApi";
import { UnhealthyServicesSection } from "@/components/ClustersView/ClusterOverview/components/UnhealthyServicesSection/UnhealthyServicesSection";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state";
import { InventorySectionByClusterName } from "@/components/ClustersView/ClustersGroupOverview/components/ClustersGroupInventorySection/InventorySectionByClusterName";
import {
  useReportClusterOverviewLoadingTime,
  useStoreIsFetchingForReport,
} from "@/components/ClustersView/ClusterOverview/useReportClusterOverviewLoadingTime";

export const ClustersGroupOverview: React.FC<{ clustersGroupId: string }> = ({
  clustersGroupId,
}) => {
  const [selectedClusters, setSelectedClusters] =
    useStringifiedStateInSearchParams<string[]>("selectedClusters");

  const clusters = selectedClusters ?? [];

  const { data: workspace, isLoading } = useGetWorkspaceById({
    id: clustersGroupId,
  });
  useStoreIsFetchingForReport("isFetchingWorkspace", isLoading);
  useReportClusterOverviewLoadingTime();

  if (!workspace) {
    return <PageLoader />;
  }

  const { totalClusters, authorizedClusters } =
    workspace.value as ClustersGroupWorkspace;

  return (
    <>
      <ClustersGroupOverviewHeader
        workspace={workspace}
        selectedClusters={selectedClusters}
        setSelectedClusters={setSelectedClusters}
      />
      <ResponsiveLayout>
        <OverviewContainer>
          {totalClusters !== authorizedClusters && (
            <Alert variant="outlined" severity="info" color="running">
              {partialClustersMessage(totalClusters, authorizedClusters)}
            </Alert>
          )}
          <UnhealthyServicesSection clusters={clusters} />
          <ReliabilitySection clusters={clusters} />
          <CostSection clusters={clusters} />
          {clusters.length === 1 ? (
            <InventorySectionByClusterName clusterName={clusters[0]} />
          ) : (
            <ClustersGroupInventorySection clusters={clusters} />
          )}
        </OverviewContainer>
      </ResponsiveLayout>
    </>
  );
};
