/* eslint-disable max-lines */
import { palette } from "@komodorio/design-system";
import { Divider, TabPanel } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";

import { ONE_DAY_IN_MS } from "../../shared/constants/time";
import { AriaLabels } from "../../shared/config/ariaLabels";
import { useStateInSearchParams } from "../../shared/hooks/state";
import { METRICS_AGGREGATION_TYPE_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { Timeframe } from "../../shared/types/TimeWindow";

import {
  MetricName,
  MetricsAggregationType,
  MetricsGraphsProps,
  MetricsGraphType,
  MetricsKind,
  zeroDate,
} from "./types";
import { ResourceGraph } from "./graphs/ResourceGraph";
import {
  GraphContainerBorderColor,
  GraphContainerHeight,
  GraphMinimizedContainerHeight,
} from "./styles";
import {
  prependMetricsWithPadding,
  formatTooltipCPUTick,
  formatTooltipMemoryTick,
  getEmptyGraphStatus,
  isTimestampOverRetention,
  transformData,
} from "./utils";
import EmptyStateGraph from "./EmptyStateGraph";
import Legend from "./Legend";
import { MetricsHeader, MetricsMinimizedHeader } from "./MetricsHeaders";
import { Summary } from "./Summary";

const Container = styled.div<{ minimized: boolean }>`
  border: ${GraphContainerBorderColor} 1px solid;
  border-radius: 4px;
  margin-block-end: 1rem;
  background-color: ${palette.white[0]};

  ${({ minimized }) =>
    minimized &&
    `
  :hover {
    border: 1px solid ${palette.darkBlue[500]};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  `};
`;

const Padding = styled.div<{ minimized: boolean }>`
  padding: ${(p) =>
    p.minimized ? "0rem 1rem 1.5rem 1rem" : "0 1.5rem 0.5rem 0"};
`;

const GraphContainer = styled.div<{ minimized: boolean }>`
  grid-template-rows: auto auto auto;
  box-sizing: border-box;
  height: ${(p) =>
    p.minimized ? GraphMinimizedContainerHeight : GraphContainerHeight};
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5rem 0.5rem 4rem;
`;

const mapMetricsSelectorToMetricName = (
  metricsSelector: string
): MetricName => {
  switch (metricsSelector) {
    case MetricsAggregationType.Avg:
      return "usageBytes";
    case MetricsAggregationType.P90:
      return "p90";
    case MetricsAggregationType.P95:
      return "p95";
    case MetricsAggregationType.P99:
      return "p99";
    case MetricsAggregationType.Max:
      return "max";
    default:
      return "usageBytes";
  }
};

export const Metrics: React.FC<MetricsGraphsProps> = ({
  metrics,
  endTimestamp,
  isMetricsSupported,
  events,
  collapsible = false,
  memoryUnit = "MiB",
  disableZoom = false,
  graphType,
  aggregationType,
  defaultTab,
  paddingInMinutes = 0,
}: MetricsGraphsProps) => {
  const [zoomed, setZoomed] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    defaultTab ?? MetricsKind.Memory
  );
  const [selectedTime, setSelectedTime] = useState(Timeframe.Last24Hours);
  const [minimized, setMinimized] = useState(collapsible);
  const [aggregationValue, setAggregationValue] = useStateInSearchParams(
    METRICS_AGGREGATION_TYPE_PARAM_KEY
  );

  useEffect(() => {
    if (!aggregationValue && graphType === MetricsGraphType.CONTAINER) {
      setAggregationValue(aggregationType ?? MetricsAggregationType.Avg, true);
    }
  }, [aggregationType, aggregationValue, graphType, setAggregationValue]);

  const {
    memoryOneHour,
    memoryFourHours,
    memoryOneDay,
    memoryOneWeek,
    cpuOneHour,
    cpuFourHours,
    cpuOneDay,
    cpuOneWeek,
    cpuCustom,
    memoryCustom,
    timeframe,
    setTimeframe,
  } = metrics;

  const isThereDataBefore24hours = useMemo(() => {
    const data =
      selectedTab === MetricsKind.Memory ? memoryOneWeek : cpuOneWeek;
    const selectedData = transformData(data.metrics);
    const firstDataPoint = selectedData[0];
    const lastDataPoint = selectedData[selectedData.length - 1];
    return lastDataPoint?.time - firstDataPoint?.time > ONE_DAY_IN_MS;
  }, [cpuOneWeek, memoryOneWeek, selectedTab]);

  const tabs = useMemo(() => {
    return [{ label: MetricsKind.Memory }, { label: MetricsKind.CPU }];
  }, []);

  const selectedTabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.label === selectedTab) || 0;
    return index < 0 ? 0 : index;
  }, [tabs, selectedTab]);

  const memory = useMemo(() => {
    switch (selectedTime) {
      case Timeframe.LastHour:
        return memoryOneHour;
      case Timeframe.Last4Hours:
        return memoryFourHours;
      case Timeframe.Last24Hours:
        return memoryOneDay;
      case Timeframe.Last7Days:
        return memoryOneWeek;
      case Timeframe.Custom:
        return memoryCustom;
      default:
        return memoryOneDay;
    }
  }, [
    memoryCustom,
    memoryFourHours,
    memoryOneDay,
    memoryOneHour,
    memoryOneWeek,
    selectedTime,
  ]);

  const cpu = useMemo(() => {
    switch (selectedTime) {
      case Timeframe.LastHour:
        return cpuOneHour;
      case Timeframe.Last4Hours:
        return cpuFourHours;
      case Timeframe.Last24Hours:
        return cpuOneDay;
      case Timeframe.Last7Days:
        return cpuOneWeek;
      case Timeframe.Custom:
        return cpuCustom;
      default:
        return cpuOneDay;
    }
  }, [
    cpuFourHours,
    cpuOneDay,
    cpuOneHour,
    cpuOneWeek,
    cpuCustom,
    selectedTime,
  ]);

  const memoryData = useMemo(() => {
    return transformData(
      prependMetricsWithPadding(memory.metrics, paddingInMinutes)
    );
  }, [memory.metrics, paddingInMinutes]);

  const cpuData = useMemo(() => {
    return transformData(
      prependMetricsWithPadding(cpu.metrics, paddingInMinutes)
    );
  }, [cpu.metrics, paddingInMinutes]);

  const isOverRetention = useMemo(
    () => isTimestampOverRetention(endTimestamp),
    [endTimestamp]
  );

  const onZoom = (value: boolean) => {
    setZoomed(value);
    if (value) {
      setSelectedTime(Timeframe.Custom);
    } else {
      setSelectedTime(Timeframe.Last24Hours);
      setTimeframe(undefined, undefined);
    }
  };

  const showLinesList: MetricName[] = useMemo(
    () => [
      "capacityBytes",
      "limitBytes",
      "requestBytes",
      "recommendationBytes",
      mapMetricsSelectorToMetricName(aggregationValue ?? ""),
    ],
    [aggregationValue]
  );

  return (
    <Container
      minimized={minimized}
      onClick={minimized ? () => setMinimized(false) : undefined}
      style={{ cursor: minimized ? "pointer" : "default" }}
      aria-label={AriaLabels.Metrics.CollapsibleGraph}
    >
      {minimized ? (
        <MetricsMinimizedHeader
          setMinimized={setMinimized}
          setSelectedTab={setSelectedTab}
        />
      ) : (
        <>
          <MetricsHeader
            tabs={tabs}
            selectedTabIndex={selectedTabIndex}
            setSelectedTab={setSelectedTab}
            isThereDataBefore24hours={isThereDataBefore24hours}
            aggregationValue={aggregationValue ?? ""}
            handleAggregationChange={setAggregationValue}
            onMetricsTimeframeChange={(tf: Timeframe | undefined) => {
              if (tf) {
                onZoom(false);
                setSelectedTime(tf);
              }
            }}
            timeWindow={{
              start: timeframe?.start ?? zeroDate,
              end: timeframe?.end ?? zeroDate,
            }}
            metricsTimeframe={selectedTime}
          />
          <Divider />
        </>
      )}
      <Padding minimized={minimized}>
        <TabPanel value={selectedTabIndex} index={0}>
          <GraphContainer minimized={minimized}>
            {isMetricsSupported &&
            !memory.error &&
            !memory.loading &&
            !memory.paused &&
            memory.metrics.usage.length > 0 ? (
              <ResourceGraph
                type={"memory"}
                disableZoom={disableZoom}
                data={memoryData}
                zoomProps={{ zoomed, onZoom }}
                events={events}
                minimized={minimized}
                memoryUnit={memoryUnit}
                showLinesList={showLinesList}
                aggregationType={
                  (aggregationValue as MetricsAggregationType) ?? undefined
                }
                setTimeframe={setTimeframe}
              />
            ) : (
              <EmptyStateGraph
                status={getEmptyGraphStatus({
                  data: memory,
                  isMetricsSupported,
                  isOverRetention,
                })}
              />
            )}
            {!minimized && (
              <Footer>
                <Legend data={memoryData} graphType={graphType} />
                <Summary
                  data={memoryData}
                  formatter={formatTooltipMemoryTick}
                  timeframe={timeframe}
                />
              </Footer>
            )}
          </GraphContainer>
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={1}>
          <GraphContainer minimized={minimized}>
            {isMetricsSupported &&
            !cpu.error &&
            !cpu.loading &&
            !cpu.paused &&
            cpu.metrics.usage.length > 0 ? (
              <ResourceGraph
                type={"cpu"}
                disableZoom={disableZoom}
                data={cpuData}
                zoomProps={{ zoomed: zoomed, onZoom: onZoom }}
                events={events}
                showLinesList={showLinesList}
                aggregationType={
                  (aggregationValue as MetricsAggregationType) ?? undefined
                }
                setTimeframe={setTimeframe}
              />
            ) : (
              <EmptyStateGraph
                status={getEmptyGraphStatus({
                  data: cpu,
                  isMetricsSupported,
                  isOverRetention,
                })}
              />
            )}
            {!minimized && (
              <Footer>
                <Legend data={cpuData} graphType={graphType} />
                <Summary
                  data={cpuData}
                  formatter={formatTooltipCPUTick}
                  timeframe={timeframe}
                />
              </Footer>
            )}
          </GraphContainer>
        </TabPanel>
      </Padding>
    </Container>
  );
};
