import React from "react";
import { groupBy } from "lodash";
import { parseISO } from "date-fns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import KomodorAlertEventDetails, {
  alertTypes,
} from "../ProcessList/details/KomodorAlertEventDetails";
import { Event_Komodor_Alert } from "../../../generated/graphql";
import { pinkBrand, pinkForUI } from "../../../Colors";

import AlertScatterShape from "./shapes/alert.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  groupEventsByKey,
  mapAndSort,
  pushToMap,
  withServices,
} from "./groupEvents";

import EventGroup, { EventBase } from ".";

export type KomodorAlertEvent = EventBase &
  Omit<Event_Komodor_Alert, keyof EventBase>;

export const alertSourceToType = new Map([
  [alertTypes.DATADOG_MONITOR, "Datadog Monitor Alert"],
  [alertTypes.GRAFANA_ALERTS, "Grafana Alert"],
  [alertTypes.NEW_RELIC_ALERTS, "New Relic Alert"],
  [alertTypes.OPSGENIE, "Opsgenie Alert"],
  [alertTypes.PROMETHEUS_ALERT_MANAGER, "AlertManager Alert"],
]);

const toKomodorAlertEvent = (
  e: Event_Komodor_Alert,
  serviceId: string
): KomodorAlertEvent => ({
  ...e,
  serviceId,
  type: alertSourceToType.get(e.alertSource) ?? "Unknown",
  eventTime: parseISO(e.eventTime),
});

export default class KomodorAlertEventGroup implements EventGroup {
  readonly backgroundColor = pinkForUI;
  readonly fillColor = pinkBrand;
  readonly icon = AlertScatterShape;
  readonly events: KomodorAlertEvent[];
  readonly id;
  readonly type;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details = "";
  readonly status;
  readonly serviceId;

  constructor(events: KomodorAlertEvent[]) {
    const lastEvent = events[events.length - 1];
    this.id = `${events[0].serviceId}:${events[0].id}`;
    this.type = events[0].type;
    this.summary = lastEvent.title;
    this.startTime = events[0].eventTime;
    this.endTime = lastEvent.eventTime;
    this.status = lastEvent.status ?? "";
    this.serviceId = events[0].serviceId;

    this.events = events;
  }

  renderEventDetails(): JSX.Element {
    return <KomodorAlertEventDetails eventGroup={this} />;
  }
}

const grafanaGroupFn = (
  events: Event_Komodor_Alert[]
): KomodorAlertEventGroup[] => {
  const sorted = mapAndSort(events, toKomodorAlertEvent);
  const openGroups = new Map<string, KomodorAlertEvent[]>();
  const closedGroups: KomodorAlertEvent[][] = [];
  for (const e of sorted) {
    pushToMap(openGroups, e.serviceId, e);
    if (e.status === "ok") {
      const group = openGroups.get(e.serviceId);
      if (group) closedGroups.push(group);
      openGroups.delete(e.serviceId);
    }
  }
  return [...closedGroups, ...Array.from(openGroups.values())].map(
    (g) => new KomodorAlertEventGroup(g)
  );
};

const genericGroupFn = (
  events: Event_Komodor_Alert[],
  serviceId: string
): KomodorAlertEventGroup[] => {
  return groupEventsByKey(
    mapAndSort(withServices(events, serviceId), toKomodorAlertEvent),
    (e) => `${e.serviceId}:${e.alertCycleId}`
  ).map((es) => new KomodorAlertEventGroup(es));
};

export const groupKomodorAlertEvents = (
  events: Event_Komodor_Alert[],
  serviceId: string
): KomodorAlertEventGroup[] => {
  const typedAlerts = groupBy(events, (e) => e.alertSource);
  if (!Object.keys(typedAlerts).length) {
    return [];
  }
  return [
    ...grafanaGroupFn(typedAlerts[alertTypes.GRAFANA_ALERTS] || []),
    ...genericGroupFn(typedAlerts[alertTypes.DATADOG_MONITOR] || [], serviceId),
    ...genericGroupFn(
      typedAlerts[alertTypes.NEW_RELIC_ALERTS] || [],
      serviceId
    ),
    ...genericGroupFn(typedAlerts[alertTypes.OPSGENIE] || [], serviceId),
    ...genericGroupFn(
      typedAlerts[alertTypes.PROMETHEUS_ALERT_MANAGER] || [],
      serviceId
    ),
  ];
};
