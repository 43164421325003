import { useCallback, useEffect } from "react";

import {
  FetchAgentTaskResultQuery,
  InsertAgentTaskMutationVariables,
  useFetchAgentTaskResultQuery,
} from "../../../generated/graphql";
import { useQueryRefreshWithVariables } from "../../../shared/hooks/useQueryWithVariables";
import useAgentTaskExecution from "../../../shared/hooks/useAgentTaskExecution/useAgentTaskExecution";

interface AgentTaskProps {
  retries: number;
  setRetries: (_: number) => void;
  maxRetries: number;
  executeTaskVars: InsertAgentTaskMutationVariables | null;
  triggerManually: boolean;
  setTriggerManually: React.Dispatch<React.SetStateAction<boolean>>;
}

const useAgentTaskLogic = ({
  retries,
  setRetries,
  maxRetries,
  executeTaskVars,
  triggerManually,
  setTriggerManually,
}: AgentTaskProps): {
  refresh: () => void;
  resultFromQuery: FetchAgentTaskResultQuery | null;
  failureMessage: string;
} => {
  const [
    fetchPayloadVars,
    failureMessage,
    executeTaskCallback,
    setTaskTimeoutCallback,
  ] = useAgentTaskExecution(
    executeTaskVars,
    triggerManually,
    setTriggerManually
  );

  const [resultFromQuery, refreshQuery] = useQueryRefreshWithVariables(
    useFetchAgentTaskResultQuery,
    fetchPayloadVars
  );

  useEffect(() => {
    if (
      failureMessage ||
      !executeTaskVars ||
      resultFromQuery?.agent_task_result.length
    )
      return;

    if (retries > maxRetries) {
      setTaskTimeoutCallback();
      return;
    }
    const timeout = setTimeout(() => {
      if (!resultFromQuery?.agent_task_result.length) {
        refreshQuery();
      }
      setRetries(retries + 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    executeTaskVars,
    failureMessage,
    maxRetries,
    refreshQuery,
    resultFromQuery,
    retries,
    setRetries,
    setTaskTimeoutCallback,
  ]);

  const refresh = useCallback(() => {
    setRetries(0);
    executeTaskCallback();
  }, [executeTaskCallback, setRetries]);

  return { refresh, resultFromQuery, failureMessage };
};

export default useAgentTaskLogic;
