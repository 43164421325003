import { useEffect, useMemo } from "react";

import { useAddEditRuleContext } from "../context/useAddEditRuleContext";
import useKomodorServices from "../../../../../../../../shared/hooks/useKomodorServices";
import { ClustersMap } from "../addEditRuleTypes";

export const useGenerateClustersMap = () => {
  const { clustersMap, setClustersMap } = useAddEditRuleContext();

  const allServices = useKomodorServices().all;

  const newClustersMap = useMemo(() => {
    return (
      clustersMap ||
      allServices?.reduce<ClustersMap>((clustersMap: ClustersMap, service) => {
        const { k8sCluster, env, title } = service;
        if (!clustersMap[k8sCluster]) {
          clustersMap[k8sCluster] = { [env]: [title] };
        } else if (!clustersMap[k8sCluster][env]) {
          clustersMap[k8sCluster][env] = [title];
        } else {
          clustersMap[k8sCluster][env].push(title);
        }
        return clustersMap;
      }, {})
    );
  }, [allServices, clustersMap]);

  useEffect(() => {
    if (!clustersMap && newClustersMap) {
      setClustersMap(newClustersMap);
    }
  }, [clustersMap, newClustersMap, setClustersMap]);
};
