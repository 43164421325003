import { useNavigate } from "react-router-dom";

import { RelativeContainer, SelectableRow } from "./styles";
import { addonsDemoRoutes } from "./constants";
import nodeAutoscalersImgSrc from "./images/node-autoscalers.png";

export const NodeAutoscalersView: React.FC = () => {
  const navigate = useNavigate();
  return (
    <RelativeContainer>
      <img src={nodeAutoscalersImgSrc}></img>
      <SelectableRow
        height="6%"
        top="58%"
        onClick={() => {
          navigate(`./${addonsDemoRoutes.clusterAutoscaler}`);
        }}
      />
    </RelativeContainer>
  );
};
