import React, { useMemo } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import { InsightDetailsContentProps } from "../../insightUtils";
import {
  IssuesAfterDeployRecord,
  IssuesAfterDeployType,
} from "../../models/IssuesAfterDeploy";
import { InsightContentTopContainer } from "../common";

import IssuesAfterDeployGraph from "./IssuesAfterDeployGraph";
import { useDeployCardsById } from "./issuesAfterDeployHooks";

const GRAPH_HEIGHT = 200;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const GraphsContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`;

const IssuesAfterDeployContent: React.FC<InsightDetailsContentProps> = ({
  insightData,
  resource,
}) => {
  const issuesAfterDeploy: IssuesAfterDeployType | null = useMemo(() => {
    if (!IssuesAfterDeployRecord.guard(insightData)) {
      return null;
    }
    return insightData;
  }, [insightData]);

  const deployCards = useDeployCardsById(
    issuesAfterDeploy?.deployment_ids ?? []
  );
  return issuesAfterDeploy ? (
    <InsightContentTopContainer>
      <GraphsContainer
        height={
          issuesAfterDeploy.count_triggered && issuesAfterDeploy.duration_norm
            ? GRAPH_HEIGHT * 2
            : GRAPH_HEIGHT
        }
      >
        <IssuesAfterDeployGraph
          issuesAfterDeploy={issuesAfterDeploy}
          resource={resource}
        />
      </GraphsContainer>
      <br />
      <Typography size="medium" variant="title" color={palette.gray["800"]}>
        {`${deployCards.length} related deploys found`}
      </Typography>
      <Container>{deployCards}</Container>
    </InsightContentTopContainer>
  ) : null;
};

export default IssuesAfterDeployContent;
