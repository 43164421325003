import React from "react";
import { muiColors, palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import Deployment from "../../../resources/deployment";
import DaemonSet from "../../../resources/daemonset";
import StatefulSet from "../../../resources/statefulset";
import Rollout from "../../../resources/rollout";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";
import { lightMuiTooltipStyle } from "../../../../../shared/styles/tooltip";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

const TooltipContainer = styled.div`
  display: grid;
  padding: 0.5rem;
  gap: 0.1rem;
`;

export const InvalidServiceTooltip: React.FC<{
  resource: Deployment | DaemonSet | StatefulSet | Rollout;
}> = ({ resource }) => {
  const { format } = useDateFormatter();
  const tooltipContent = (
    <TooltipContainer>
      {resource?.isDeleted ? (
        <>
          <Typography>This service was deleted on</Typography>
          <Typography bold color={palette.gray[600]}>
            {resource?.deletedAt ? format(resource?.deletedAt) : ""}
          </Typography>
        </>
      ) : (
        <Typography>The komodor agent is not active</Typography>
      )}
    </TooltipContainer>
  );

  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={tooltipContent}
      placement="bottom"
    >
      <InfoOutlined
        sx={{ width: 14, height: 16, color: muiColors.gray[500] }}
        aria-label={
          AriaLabels.ResourceView.Header.ServiceHealth.Availability
            .StatusInfoIcon
        }
      />
    </Tooltip>
  );
};
