import React, { useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import {
  Modal,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { ArrowRight16, Pencil24 } from "@komodorio/design-system/icons";
import { diff, flattenChangeset } from "json-diff-ts";

import DiffEntry from "../deployEvent/DiffEntry";
import { PREVIEW_LIMIT } from "../deployEvent/K8sSection";
import { ObjectDiffAsYaml } from "../../../ObjectDiff";

import EventDetailsSection from "./EventDetailsSection";

const DiffButton = styled(Typography).attrs({
  underline: true,
  bold: true,
})`
  cursor: pointer;
  color: ${palette.blue[500]};
  display: flex;
  gap: 0.25rem;
  width: fit-content;
  align-items: center;
`;

type K8sResourceDiffSectionProps = {
  oldObj: unknown;
  newObj: unknown;
};

const K8sResourceDiffSection: React.FC<K8sResourceDiffSectionProps> = ({
  oldObj,
  newObj,
}) => {
  const [openDiff, setOpenDiff] = useState(false);

  const changes = flattenChangeset(diff(oldObj, newObj));

  if (!changes.length) {
    return null;
  }

  return (
    <EventDetailsSection
      icon={Pencil24}
      iconDimensions={{ height: "50%", width: "50%" }}
      title="Changes"
    >
      {changes.slice(0, PREVIEW_LIMIT).map((c) => (
        <DiffEntry
          key={c.path}
          change={{
            name: c.key,
            previous: JSON.stringify(c.oldValue),
            current: JSON.stringify(c.value),
          }}
        />
      ))}
      <br />
      <DiffButton onClick={() => setOpenDiff(true)}>
        View all {changes.length} changes on diff <ArrowRight16 />
      </DiffButton>
      <Modal isOpen={openDiff} onRequestClose={() => setOpenDiff(false)}>
        <ModalHeader />
        <ModalContent>
          <ObjectDiffAsYaml oldObj={oldObj} newObj={newObj} />
        </ModalContent>
      </Modal>
    </EventDetailsSection>
  );
};

export default K8sResourceDiffSection;
