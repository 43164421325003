import React, { memo } from "react";
import styled from "styled-components";

import { blackGreyText, pinkBrand } from "../../../Colors";
import { Text } from "../typography";

const StyledText = styled(Text)<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? blackGreyText : pinkBrand)};
`;

const MaxLengthContainer: React.FC<{
  length: number;
  maxLength: number;
}> = memo(({ length, maxLength }) => {
  return (
    <StyledText isValid={length < maxLength}>
      {length}/{maxLength}
    </StyledText>
  );
});

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputTitleMaxLength: React.FC<{
  length: number;
  maxLength: number;
  children?: React.ReactNode;
}> = ({ length, maxLength, children }) => (
  <Container>
    {children}
    <MaxLengthContainer length={length} maxLength={maxLength} />
  </Container>
);

export default InputTitleMaxLength;
