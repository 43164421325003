import { useMemo } from "react";
import { get, isEqual } from "lodash";
import { useFormContext } from "react-hook-form";

import { usePolicyDrawerContext } from "../context/usePolicyDrawerContext";

import { InputFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import {
  severitiesList,
  supportedStaticCheckTypes,
} from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerConstants";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";
import {
  BasePolicy,
  CheckType,
  PolicyConfigurations,
} from "@/generated/reliabilityApi";
import { useGetFetchedPolicyData } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetFetchedPolicyData";

const excludedKeys: (keyof BasePolicy)[] = ["createdAt", "updatedAt", "id"];

export const useHasChangedFormValues = () => {
  const { getValues, watch } = useFormContext<InputFields>();
  const { policyConfigurations } = usePolicyDrawerContext();
  const { data } = useGetFetchedPolicyData();

  const watchers = watch();

  return useMemo(() => {
    if (!data?.data.policy || !watchers) {
      return false;
    }

    const currentValues = getValues();

    if (!Object.keys(currentValues).length) return false;

    return Object.entries(data.data.policy).some(([key, policyValue]) => {
      if (excludedKeys.includes(key as keyof BasePolicy)) {
        return false;
      }

      // key not found in currentValues
      if (currentValues[key as keyof InputFields] === undefined) {
        return true;
      }

      if (key !== "configurations") {
        return !isEqual(currentValues[key as keyof InputFields], policyValue);
      }

      const isBestPracticesChanged = !isBestPracticesEqual(
        getCurrentBestPracticesConfigurations(policyConfigurations),
        getFetchedEnabledBestPractices(data.data.policy.configurations)
      );

      if (
        Object.keys(data.data.policy.configurations).length !==
          Object.keys(currentValues.configurations).length ||
        isBestPracticesChanged
      ) {
        return true;
      }

      return severitiesList.some((severity) => {
        return Object.keys(data.data.policy.configurations).some(
          (checkType) => {
            const configNames = getConfigurationFieldNamesByCheckTypeAsList(
              checkType as CheckType,
              severity
            );

            return configNames.some((path) => {
              const currentValue = get(currentValues, path);
              const policyValue = get(data.data.policy, path);
              return !isEqual(currentValue, policyValue);
            });
          }
        );
      });
    });
  }, [data?.data.policy, getValues, policyConfigurations, watchers]);
};

const getCurrentBestPracticesConfigurations = (
  configurations: PolicyConfigurations
) => {
  return Object.keys(configurations).filter((checkType) =>
    supportedStaticCheckTypes.includes(checkType as CheckType)
  ) as CheckType[];
};

const getFetchedEnabledBestPractices = (
  configurations: PolicyConfigurations
) => {
  return Object.entries(configurations)
    .filter(
      ([checkType, configuration]) =>
        configuration.enabled &&
        supportedStaticCheckTypes.includes(checkType as CheckType)
    )
    .map(([checkType]) => checkType as CheckType);
};

const isBestPracticesEqual = (
  currentBestPractices: CheckType[],
  fetchedBestPractices: CheckType[]
) => {
  const sortedCurrent = currentBestPractices.sort();
  const sortedFetched = fetchedBestPractices.sort();

  return (
    sortedCurrent.length === sortedFetched.length &&
    sortedCurrent.every((val, index) => val === sortedFetched[index])
  );
};
