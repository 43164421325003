import React from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import styled from "styled-components";
import { IntegrationType } from "komodor-types";
import { TooltipWrapper } from "react-tooltip";

import definitions from "../definitions";
import IntegrationTile from "../IntegrationTile";
import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import { DEFAULT_TOOLTIP } from "../../../../shared/constants/tooltipIds";
import { useHasPermissions } from "../../../../shared/hooks/useUserMetadata/rbac";

import { Installation } from "./useInstallationSubscription";
import { Info } from "./Info";
import RemoveIntegrationButton from "./RemoveIntegrationButton";

const parse = (ts: string) => parseISO(ts + "Z");
const formatTime = (ts: string) =>
  formatDistanceToNow(parse(ts), { addSuffix: true });

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem 1rem;
  justify-items: start;
  align-items: start;
  justify-content: start;
  color: #3d4048;
  font-size: 0.9rem;
  grid-template:
    "installed" auto
    "installed" auto
    "installed" auto / 9rem;
`;

const InstalledBy = styled(Info)`
  grid-area: installed;
`;

const TopContainer = styled.div`
  display: grid;
  position: relative;
`;

const InstalledIntegrationTile: React.FC<{
  installation: Installation;
}> = ({ installation }) => {
  const { format } = useDateFormatter();
  const { canManageIntegrations } = useHasPermissions();

  const index = Object.values(IntegrationType).find(
    (t) => t === installation.integration
  );

  if (!index) {
    return null;
  }

  const { logo, title, info, hideInstalled, canDelete } = definitions[index];
  if (hideInstalled) {
    return null;
  }

  return (
    <TopContainer>
      {canManageIntegrations && canDelete ? (
        <RemoveIntegrationButton installation={installation} />
      ) : null}
      <IntegrationTile logoWidth="4rem" logo={logo} title={title}>
        <Container>
          <InstalledBy label="Installed by">
            <div style={{ whiteSpace: "pre-line" }}>
              {installation.user?.displayName ?? "N/A"}
            </div>
            <TooltipWrapper
              tooltipId={DEFAULT_TOOLTIP}
              content={format(parse(installation.createdAt))}
              place="bottom"
            >
              <b>{formatTime(installation.createdAt)}</b>
            </TooltipWrapper>
          </InstalledBy>
          {(info ?? []).map((GenericInfo, i) => (
            <GenericInfo installation={installation} key={i} />
          ))}
        </Container>
      </IntegrationTile>
    </TopContainer>
  );
};

export default InstalledIntegrationTile;
