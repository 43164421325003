import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import Komo from "../../../../../../shared/assets/happyKomo.svg?react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 16px;
`;

export const NoViolationsEmptyState: React.FC = () => (
  <Container>
    <Komo />
    <Typography variant="h4">
      There are no open violations in your environment!
    </Typography>
  </Container>
);
