import { groupBy, mapValues, sortBy } from "lodash";
import { useCallback, useMemo } from "react";

import { dispatchEvent } from "../../../../../../shared/hooks/analytics";
import { useDrawerUrlState } from "../../../../../../shared/hooks/state";
import EventGroup from "../../../../../common/EventGroup";
import { intersection } from "../../../../../../shared/utils/collections/collections";
import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";
import {
  EVENTS_TAB_PREFIX,
  FILTERS_PARAM_KEY,
} from "../../../../../../shared/config/urlSearchParamsKeys";

import {
  defaultHiddenTypes,
  eventFilterDefinitions,
  eventFilterSelectors,
} from "./eventFiltersDefinitions";
import { CategoryInfo } from "./EventFiltersCategory";

type FilterState = Record<string, string[]> | null;

export const useFilterState = () =>
  useDrawerUrlState<FilterState>(EVENTS_TAB_PREFIX + FILTERS_PARAM_KEY);

const useEventGroups = (
  allEventGroups: EventGroup[] | undefined,
  state: FilterState
) =>
  useMemo(() => {
    return state
      ? allEventGroups?.filter((g) =>
          Object.entries(eventFilterSelectors).every(
            ([selectorKey, select]) =>
              !state[selectorKey] || state[selectorKey].includes(select(g))
          )
        )
      : allEventGroups?.filter((g) => !defaultHiddenTypes.has(g.type));
  }, [allEventGroups, state]);

const useReset = (setState: (s: FilterState) => void) =>
  useCallback(() => {
    dispatchEvent(AnalyticEvents.ServiceView.Filters_Reset);
    setState(null);
  }, [setState]);

const getUpdatedState = (state: FilterState, key: string, option: string) => {
  dispatchEvent(AnalyticEvents.ServiceView.Filters_Change, {
    eventType: option,
  });
  if (state?.[key]?.includes(option)) {
    const clone = { ...state };
    clone[key] = state[key].filter((o) => o !== option);
    if (clone[key].length === 0) delete clone[key];
    return Object.keys(clone).length ? clone : null;
  }
  return { ...state, [key]: [...(state?.[key] ?? []), option] };
};

const useCategories = (
  allEventGroups: EventGroup[] | undefined,
  state: FilterState,
  setState: (s: FilterState) => void
) => {
  const optionsFromData = useMemo(() => {
    return mapValues(eventFilterSelectors, (select) =>
      mapValues(groupBy(allEventGroups, select), (eventGroups) =>
        eventGroups.map((g) => g.id)
      )
    );
  }, [allEventGroups]);

  const { podEventsInServiceView } = useOverridableFlags();

  // TODO: Complete hack. Needs to be resolved later when a better spec for the Pod Selector comes along.
  if (podEventsInServiceView) {
    optionsFromData["t"]["Pod event"] = optionsFromData["t"]["Pod event"] ?? [];
  }

  const optionsFromDataAndState = useMemo(() => {
    return mapValues(optionsFromData, (eventGroupsPerOption, selectorKey) => ({
      ...eventGroupsPerOption,
      ...Object.fromEntries(
        (state?.[selectorKey] ?? [])
          .filter((option) => !(option in eventGroupsPerOption))
          .map((option) => [option, [] as string[]])
      ),
    }));
  }, [optionsFromData, state]);

  return useMemo(() => {
    if (!allEventGroups?.length) return [];
    const selectedEventGroups = mapValues(
      optionsFromDataAndState,
      (eventGroupsPerOption, selectorKey) =>
        new Set(
          Object.entries(eventGroupsPerOption)
            .filter(([option]) => state?.[selectorKey]?.includes(option))
            .flatMap(([, eventGroups]) => eventGroups)
        )
    );
    return eventFilterDefinitions.map((def) => {
      const otherSelectorsEventGroups = Object.entries(selectedEventGroups)
        .filter(([k, v]) => k !== def.selectorKey && v.size > 0)
        .map(([, v]) => v);
      return {
        title: def.title,
        Icon: def.Icon,
        options:
          sortBy(
            Object.entries(optionsFromDataAndState[def.selectorKey])
              .filter(([option]) => def.filterOption(option))
              .map(([option, eventGroups]) => ({
                label: def.formatLabel(option),
                count: otherSelectorsEventGroups.length
                  ? intersection([
                      new Set(eventGroups),
                      ...otherSelectorsEventGroups,
                    ]).size
                  : eventGroups.length,
                value: option,
                isSelected: state?.[def.selectorKey]?.includes(option) ?? false,
                onChange: () =>
                  setState(getUpdatedState(state, def.selectorKey, option)),
              })),
            (option) => option.label
          ) ?? [],
      };
    });
  }, [allEventGroups?.length, optionsFromDataAndState, state, setState]);
};

const useFilteredEvents = (
  allEventGroups: EventGroup[] | undefined
): {
  eventGroups: EventGroup[] | undefined;
  reset: (() => void) | undefined;
  categories: CategoryInfo[];
} => {
  const [state, setState] = useFilterState();
  const resetState = useReset(setState);
  const reset = state ? resetState : undefined;

  const eventGroups = useEventGroups(allEventGroups, state);
  const categories = useCategories(allEventGroups, state, setState);
  return { eventGroups, reset, categories };
};

export default useFilteredEvents;
