import React from "react";

import { LinesLoader } from "../../../common/loaders/Line";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { Panel } from "../../../Inspection/styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useAtmInServiceTabs } from "../useAtmInServiceTabs";
import { PodsLogsEarlyFetchProvider } from "../../common/usePodsLogsEarlyFetch";
import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";

import PodsContentAtm, { PodsContentOld } from "./PodsData";

interface PodsTabProps {
  resource: KomodorServiceResourceKind;
}

const PodsTabContent: React.FC<PodsTabProps> = ({ resource }) => {
  const agentId = useActiveAgent(resource.cluster);
  const shouldUseAtm = useAtmInServiceTabs(resource);
  if (shouldUseAtm === undefined || !agentId || !resource.selector) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return shouldUseAtm ? (
    <PodsContentAtm agentId={agentId} resource={resource} />
  ) : (
    <PodsContentOld agentId={agentId} resource={resource} />
  );
};

const PodsTab: React.FC<PodsTabProps> = ({ resource }) => (
  <PodsLogsEarlyFetchProvider>
    <PodsTabContent resource={resource} />
  </PodsLogsEarlyFetchProvider>
);

export default PodsTab;
