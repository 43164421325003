import EventGroup from "../../common/EventGroup";
import WorkflowIssueEventGroup from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WorkflowConfigType } from "../../monitorsView/common/types";

export default function* selectAvailaiblityReasons(
  g: EventGroup
): Iterable<string> {
  if (
    !(g instanceof WorkflowIssueEventGroup) ||
    g.workflowType !== WorkflowConfigType.Availability
  ) {
    return;
  }
  for (const reason of (g as WorkflowIssueEventGroup).reasons) {
    yield reason;
  }
}

export function* selectStandalonePodReasons(g: EventGroup): Iterable<string> {
  if (
    !(g instanceof WorkflowIssueEventGroup) ||
    (g.workflowType !== WorkflowConfigType.Pod &&
      g.workflowType !== WorkflowConfigType.Workflow)
  ) {
    return;
  }
  for (const reason of (g as WorkflowIssueEventGroup).reasons) {
    yield reason;
  }
}
