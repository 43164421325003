import React, { useCallback, useMemo, useRef } from "react";
import {
  AdvancedMultiSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { useSetFiltersDataFromOptions } from "@/components/k8sAddons/hooks/filters/useSetFiltersDataFromOptions";
import { useFiltersDataInUrl } from "@/shared/hooks/filtersDataInUrl/useFiltersDataInUrl";

type SelectProps = Omit<
  React.ComponentProps<typeof AdvancedMultiSelect<string>>,
  "options" | "values" | "onChange" | "onClose" | "onOpen"
>;
type FiltersContainerProps = {
  filterCategory: string;
  label: string;
  selectProps?: SelectProps;
  options: MuiSelectionOption<string>[];
  disable: boolean;
};

/** <SelectFilterHandler> must be a child of <FiltersContextProvider>
 *  options are set in URL onClose, not onChange
 * */

export const SelectFilterHandler: React.FC<FiltersContainerProps> = ({
  filterCategory,
  label,
  selectProps,
  options,
  disable,
}) => {
  const filtersDataInUrl = useFiltersDataInUrl();
  const selectedOptions = useRef<MuiSelectionOption<string>[]>();
  const hasChangedOption = useRef(false);
  const isSelectOpen = useRef(false);
  const setFiltersDataFromOptions = useSetFiltersDataFromOptions();

  const currentValues = useMemo<MuiSelectionOption<string>[]>(() => {
    const filtersFromUrl = filtersDataInUrl?.[filterCategory] ?? [];
    if (filtersFromUrl.length === 0) return [];
    const filter = filtersFromUrl[0];
    return filter.values
      .filter((value) => {
        return options?.find((option) => {
          return option.value === value;
        });
      })
      .map((value) => ({
        value,
        label: value,
      }));
  }, [filterCategory, filtersDataInUrl, options]);

  const onChange = useCallback(
    (newOptions: MuiSelectionOption<string>[]) => {
      selectedOptions.current = newOptions;
      hasChangedOption.current = true;
      if (!isSelectOpen.current) {
        setFiltersDataFromOptions(filterCategory, selectedOptions.current);
      }
    },
    [filterCategory, setFiltersDataFromOptions]
  );

  const onClose = useCallback(() => {
    isSelectOpen.current = false;
    setFiltersDataFromOptions(filterCategory, selectedOptions.current);
  }, [filterCategory, setFiltersDataFromOptions]);

  return (
    <AdvancedMultiSelect
      ariaLabel={`select filter for ${label}`}
      options={options}
      label={label}
      placeholder={"All"}
      value={currentValues}
      onChange={onChange}
      onClose={onClose}
      disabled={disable}
      onOpen={() => {
        isSelectOpen.current = true;
      }}
      {...selectProps}
    />
  );
};
