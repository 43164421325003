import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";

import { GroupBySelector } from "./GroupBySelector";
import { TotalViolations } from "./TotalViolations";
import { ViolationTableWithContext } from "./ViolationTableWithContext/ViolationTableWithContext";
import { GroupedViolations } from "./GroupedViolations";
import { TimeWindowSelector } from "./TimeWindowSelector";

const TopSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledViolationTable = styled(ViolationTableWithContext)`
  padding: 16px;
  background: white;
  border-radius: 4px;
`;

const SelectorsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const violationTableUid = uuidv4();

export const ViolationsBreakdown: React.FC = () => {
  const groupBy = useGetGroupBy();

  return (
    <div>
      <TopSectionContainer>
        <TotalViolations />
        <SelectorsContainer>
          <GroupBySelector />
          <TimeWindowSelector />
        </SelectorsContainer>
      </TopSectionContainer>
      {groupBy === "None" ? (
        <StyledViolationTable
          violationsGroupBy="None"
          uid={violationTableUid}
        />
      ) : (
        <GroupedViolations />
      )}
    </div>
  );
};
