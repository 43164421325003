import React, { useEffect } from "react";

import useKomodorServices from "../../../shared/hooks/useKomodorServices";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";

import {
  KomodorServiceAttributes,
  useServiceAttributesConfiguredKeys,
} from "./service-attributes-fetch-hook";

const attributeKeyToServiceInfoKeyMap: Record<string, string> = {
  cluster: "k8sCluster",
  namespace: "env",
};

type serviceInfoAttrKeyType = keyof Pick<ServiceInfo, "k8sCluster" | "env">;

// ServiceAttributesPerformLocalAggregation is a component that is responsible for aggregating all the services attributes responses into one object.
// This component is only to be used when the required filterable attributes are ONLY ["cluster", "namespace"], because
// this is what we already have in the state of the app. If the required filterable attributes are different, we should use ServiceAttributesPerformQueries instead to actually fetch from resources-api.
const ServiceAttributesPerformLocalAggregationForClusterNamespace: React.FC<{
  setKomodorServiceAttributes: React.Dispatch<
    React.SetStateAction<KomodorServiceAttributes | undefined>
  >;
}> = ({ setKomodorServiceAttributes }) => {
  const serviceAttributesKeys = useServiceAttributesConfiguredKeys();
  const allServices = useKomodorServices().all;

  useEffect(() => {
    if (!allServices || !serviceAttributesKeys) {
      return;
    }

    const aggregatedServiceAttributesMap: KomodorServiceAttributes = [];

    allServices.forEach(
      (service) => {
        serviceAttributesKeys.forEach((attributeKey) => {
          const serviceInfoAttrKey = attributeKeyToServiceInfoKeyMap[
            attributeKey
          ] as serviceInfoAttrKeyType;
          if (service[serviceInfoAttrKey]) {
            aggregatedServiceAttributesMap.push({
              serviceId: service.id,
              key: attributeKey, // "cluster", "namespace"
              value: service[serviceInfoAttrKey], // value of: service.k8sCluster, service.env
            });
          }
        });
      },
      [allServices, serviceAttributesKeys]
    );

    setKomodorServiceAttributes(aggregatedServiceAttributesMap);
  }, [allServices, serviceAttributesKeys, setKomodorServiceAttributes]);

  return null;
};

export default ServiceAttributesPerformLocalAggregationForClusterNamespace;
