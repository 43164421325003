import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

import { OptionType } from "../../../../common/controls/Select/SingleSmartSelect";
import { PodOutputWithLogs } from "../../CommonTypes";
import {
  ContainerSectionFull,
  PodsEvents,
} from "../../../common/availabilityComponents";
import { BoldGrayText } from "../CheckDetailsDrawer";
import { podSelectEnrichment } from "../availability/detailsComponents/workloadHealthyPodsDetails/WorkloadHealthyPodsDetailsEvents";
import { GraySmallLightShortText } from "../../../monitorsConfiguration/common/styles";
import { SmallPadding } from "../../../common/styles";
import SnapshotInfoDetail from "../../../common/availabilityComponents/SnapshotInfoDetail";
import AvailabilityUpgradeAgent from "../../../common/availabilityComponents/AvailabilityUpgradeAgent";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import HealthyPodCheck from "./HealthyPodCheck";

const Margin = styled.div`
  margin: 0 0.2rem 0 0.7rem;
`;

const Container = styled.div`
  background-color: ${palette.gray[50]};
  margin: 0.25rem 0rem;
  padding: 0.25rem 0.5rem;
`;
export type PodOption = OptionType<PodOutputWithLogs>;

const PodHealthDetailsEvent: React.FC<{
  check: HealthyPodCheck;
}> = ({ check }) => {
  const checkOutput = check.output;
  const pod = checkOutput.pod;

  return (
    <Margin>
      {checkOutput?.eventTime && (
        <SnapshotInfoDetail eventTime={checkOutput?.eventTime} />
      )}
      {(!checkOutput?.isPodEventAllowed ||
        checkOutput?.isPodLogsAllowed === false) && (
        <>
          <AvailabilityUpgradeAgent />
          <br />
        </>
      )}
      {pod && (
        <>
          <BoldGrayText>Unhealthy pod</BoldGrayText>
          <Container>
            <div>
              <GraySmallLightShortText>Name:</GraySmallLightShortText>
              <SmallPadding> {pod.name}</SmallPadding>
            </div>
            {podSelectEnrichment({ value: pod, label: pod.name })}
          </Container>
          <br />
          <PodsEvents pod={pod} />
          <br />
          <ContainerSectionFull
            containers={pod.containers}
            podName={pod.name}
            clusterName={checkOutput.clusterName ?? ""}
            namespace={checkOutput?.namespace ?? ""}
            eventTime={check.output?.eventTime}
          />
        </>
      )}
    </Margin>
  );
};

export default PodHealthDetailsEvent;
