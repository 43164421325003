import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SectionContainer";
import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";
import { StyledOrderedList } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureStyles";

export const SuggestedRemediation: React.FC = () => {
  const { next_steps } = useGetCascadingFailureSupportingData() ?? {};

  const content = useMemo(() => {
    if (!next_steps?.length) return null;
    return next_steps.map((step, index) => (
      <li key={index}>
        <Typography variant={"body2"}>{step}</Typography>
      </li>
    ));
  }, [next_steps]);

  if (!next_steps?.length) return null;

  return (
    <SectionContainer title={"Suggested Remediation:"}>
      <StyledOrderedList>{content}</StyledOrderedList>
    </SectionContainer>
  );
};
