import { KeyboardEventHandler } from "react";

import { zeroPadStart as padStart } from "../../../shared/utils/zeroPadStart";

const absMod = (n: number, m: number) => ((n % m) + m) % m;

const fields = ["hour", "minute", "ampm"] as const;
const fieldOperations = {
  hour: {
    isKeyValid: (key: string) => key >= "0" && key <= "9",
    transformKey: (key: string) => key,
    isValueValid: (v: string) => {
      const asInt = parseInt(v, 10);
      return asInt >= 1 && asInt <= 12;
    },
    getNextValue: (v: string, step: number) =>
      padStart(absMod(parseInt(v, 10) - 1 + step, 12) + 1),
  },
  minute: {
    isKeyValid: (key: string) => key >= "0" && key <= "9",
    transformKey: (key: string) => key,
    isValueValid: (v: string) => {
      const asInt = parseInt(v, 10);
      return asInt >= 0 && asInt <= 59;
    },
    getNextValue: (v: string, step: number) =>
      padStart(absMod(parseInt(v, 10) + step, 60)),
  },
  ampm: {
    isKeyValid: (key: string) => "ampAMP".includes(key),
    transformKey: (key: string) => key.toUpperCase(),
    isValueValid: (v: string) => {
      return v === "AM" || v === "PM";
    },
    getNextValue: (v: string) => (v === "AM" ? "PM" : "AM"),
  },
};

const moveCursor = (
  input: HTMLInputElement,
  step: number,
  initialPosition?: number
) => {
  const base = initialPosition ?? input.selectionStart ?? 0;
  const nextPosition = Math.max(Math.min(input.value.length, base + step), 0);
  input.setSelectionRange(nextPosition, nextPosition);
};

const replaceInPosition = (s1: string, s2: string, pos: number) => {
  return s1.substring(0, pos) + s2 + s1.substring(pos + s2.length);
};

const getNextFieldIndex = (
  currentIndex: number,
  allSelected: boolean,
  shiftPressed: boolean
) => {
  if (allSelected) {
    return shiftPressed ? 2 : 0;
  }
  return shiftPressed ? currentIndex - 1 : currentIndex + 1;
};

const SINGLE_UNICODE_CHAR = /^.$/u;
const keyShouldBeCaptured = (key: string) =>
  ["Delete", "Backspace", "ArrowUp", "ArrowDown"].includes(key) ||
  SINGLE_UNICODE_CHAR.test(key);

const onTimeInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
  const input = e.currentTarget;
  const cursorPosition = input.selectionStart ?? 0;
  const fieldIndex = Math.floor(cursorPosition / 3);
  if (e.key === "Tab") {
    const allSelected =
      (input.selectionEnd ?? -1) - (input.selectionStart ?? 0) ===
      input.value.length;
    const nextFieldIndex = getNextFieldIndex(
      fieldIndex,
      allSelected,
      e.shiftKey
    );
    if (nextFieldIndex < 0 || nextFieldIndex > 2) {
      return;
    }
    e.preventDefault();
    const nextFieldPosition = nextFieldIndex * 3;
    input.setSelectionRange(nextFieldPosition, nextFieldPosition + 2);
    return;
  }
  if (!keyShouldBeCaptured(e.key)) {
    return;
  }
  e.preventDefault();
  if (e.key === " " || e.key === "Backspace") {
    moveCursor(input, e.key === " " ? 1 : -1);
    return;
  }
  const positionInField = cursorPosition % 3;
  const field = fields[fieldIndex];
  const fullValue = input.value;
  const fieldStartIndex = cursorPosition - positionInField;
  const fieldValue = fullValue.substring(fieldStartIndex, fieldStartIndex + 2);
  const op = fieldOperations[field];
  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
    const newValue = op.getNextValue(fieldValue, e.key === "ArrowUp" ? 1 : -1);
    input.value = replaceInPosition(fullValue, newValue, fieldStartIndex);
    input.setSelectionRange(fieldStartIndex, fieldStartIndex + 2);
    return;
  }
  if (!op.isKeyValid(e.key) || positionInField > 1) {
    return;
  }
  const newValue = replaceInPosition(
    fieldValue,
    op.transformKey(e.key),
    positionInField
  );
  if (!op.isValueValid(newValue)) {
    return;
  }
  input.value = replaceInPosition(fullValue, newValue, fieldStartIndex);
  const step = positionInField === 0 ? 1 : 2;
  moveCursor(input, step, cursorPosition);
};

export default onTimeInputKeyDown;
