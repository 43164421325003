import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { muiColors } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";

interface SectionAccordionSummaryProps {
  title: string;
  icon: React.ReactNode;
  isExpanded: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyMessage?: string;
  isDisabled?: boolean;
  disabledMessage?: string;
  children?: React.ReactNode;
}
export const SectionAccordionSummary: React.FC<
  SectionAccordionSummaryProps
> = ({
  title,
  icon,
  isExpanded,
  isLoading,
  emptyMessage,
  isEmpty,
  isDisabled,
  disabledMessage,
  children,
}) => {
  return (
    <AccordionSummary disabled={!isLoading && (isDisabled || isEmpty)}>
      <Box
        sx={{
          display: "flex",
          columnGap: "8px",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!isLoading && (isDisabled || isEmpty) ? (
          <Box width={24} />
        ) : (
          <ChevronRight
            sx={{
              rotate: isExpanded ? "90deg" : "none",
              transition: "rotate 0.2s",
              color: muiColors.gray[500],
            }}
          />
        )}
        {icon}
        <Typography variant="h4" sx={{ whiteSpace: "nowrap" }}>
          {title}
        </Typography>
        {isLoading && !isExpanded ? (
          <>
            <Divider orientation="vertical" sx={{ marginInline: "8px" }} />
            <CircularProgress size={16} />
            <Typography variant="body2" color="text.primary">
              Loading...
            </Typography>
          </>
        ) : isEmpty ? (
          <>
            <Divider orientation="vertical" sx={{ marginInline: "8px" }} />
            <CheckCircle sx={{ color: muiColors.green[500] }} />
            <Typography variant="body2" color="text.primary">
              {emptyMessage}
            </Typography>
          </>
        ) : isDisabled ? (
          <>
            <Divider orientation="vertical" sx={{ marginInline: "8px" }} />
            <Typography variant="body2" color="text.primary">
              {disabledMessage}
            </Typography>
          </>
        ) : (
          children
        )}
      </Box>
    </AccordionSummary>
  );
};
