import { capitalize } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import dropdownArrow from "../../../../common/controls/Select/dropdownArrow";
import { textBolderStyle } from "../../../../common/typography";
import { FormTextInput, Label } from "../styles";
import trashIcon from "../../../assets/trash.svg";
import { useFormValidations } from "../../../../../shared/context/ValidationsProvider";
import { ConditionVariables } from "../../../common/types";
import useKomodorServices from "../../../../../shared/hooks/useKomodorServices";
import StyledMultiSelect from "../../../common/StyledMultiSelect";

const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: end;
  justify-content: space-between;
`;

const Select = styled.select<{ width?: string }>`
  display: block;
  appearance: none;
  color: #3d4048;
  border: 1px solid #bcc0c8;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: #fff;
  width: ${({ width }) => width ?? "8rem"};
  margin-right: 0.3rem;
  height: 2.2rem;

  ${textBolderStyle}
  ${dropdownArrow}

:focus {
    outline: none;
  }
`;

const TrashButton = styled.img.attrs({ src: trashIcon, alt: "Delete" })`
  cursor: pointer;
`;

export const SelectorWrapper = styled.div`
  font-size: 12px;
  margin-inline: 0.4rem;
`;

const validateInput = (option: string, input: string): string | undefined => {
  if (["labels", "annotations"].includes(option)) {
    let validEntries = 0;
    const splitInput: string[] = input.toString().split(",");
    splitInput.forEach((entry) => {
      const [key, ...values] = entry.trim().split(":");
      const value = values.join(":");

      if (key?.length && value?.length) {
        const keyPattern =
          /^(([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\/)?([a-z0-9A-Z])+([-_.a-z0-9])*([a-z0-9A-Z])+$/;
        const valuePatterns = [/^"([^"])+"$/, /^[^'"]*$/, /^'([^'])+'$/];
        if (
          keyPattern.test(key) &&
          valuePatterns.map((p) => p.test(value)).some((element) => element)
        ) {
          ++validEntries;
        }
      }
    });
    if (splitInput.length !== 0 && validEntries === splitInput.length) {
      return;
    }
  } else if (option === "namespaces") {
    if (input.toString().length) {
      let validEntries = 0;
      let splitInput: string[] = input.toString().split(",");
      splitInput = splitInput.map((entry) => entry.trim());
      const pattern = /^(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])?$/;
      splitInput.forEach((entry) => {
        if (pattern.test(entry)) {
          ++validEntries;
        }
      });
      if (splitInput.length !== 0 && validEntries === splitInput.length) {
        return;
      }
    }
  }
  return "";
};

const Selector: React.FC<{
  id: string;
  options: string[];
  selectorKey: string;
  value: string;
  handleChange: (key: string, value: unknown, oldKey?: string) => void;
  handleConditionChange: (condition: string) => void;
  condition: string;
  handleClose: (key: string) => void;
  enableDeletion: boolean;
  index: number;
  cluster?: string;
}> = ({
  id,
  options,
  handleChange,
  condition,
  handleConditionChange,
  value,
  handleClose,
  selectorKey,
  enableDeletion,
  index,
  cluster,
}) => {
  const [selectedOption, setSelectedOption] = useState(selectorKey);
  const selectedValues =
    value.toString() === "" ? [] : value.toString().split(",");

  const conditionOptions = Object.values(ConditionVariables);
  const { errors, setError, deleteError } = useFormValidations();
  const isValid =
    errors[id] === undefined ||
    errors[id] === validateInput(selectedOption, value);
  const isDisabled = index !== 1;
  const allServices = useKomodorServices().all;

  const serviceNamespaces = useMemo(() => {
    return (
      [
        ...new Set(
          allServices
            ?.filter((service) => service.k8sCluster === cluster)
            .map((service) => service.env)
        ),
      ] || []
    );
  }, [allServices, cluster]);
  const serviceNames = useMemo(() => {
    return (
      allServices
        ?.filter((service) => service.k8sCluster === cluster)
        .map((service) => `${service.env}/${service.title}`) || []
    );
  }, [allServices, cluster]);

  const selectFilterChanged = useCallback(
    (fields: string[]) => {
      handleChange(selectedOption, fields);
    },
    [handleChange, selectedOption]
  );

  return (
    <Container>
      {index >= 1 && (
        <Select
          onChange={(e) => {
            handleConditionChange(e.target.value);
          }}
          value={condition}
          disabled={isDisabled}
          width={"4rem"}
        >
          {conditionOptions.map((value) => (
            <option key={value} value={value}>
              {capitalize(value)}
            </option>
          ))}
        </Select>
      )}
      <Select
        onChange={(e) => {
          const prevSelected = selectedOption;
          setSelectedOption(e.target.value);
          handleChange(e.target.value, "", prevSelected);
        }}
        value={selectorKey}
      >
        {options.concat(selectedOption).map((type) => (
          <option key={type} value={type}>
            {capitalize(type)}
          </option>
        ))}
      </Select>
      <Label>:</Label>

      {((selectedOption === "namespaces" || selectedOption === "services") && (
        <SelectorWrapper>
          <StyledMultiSelect
            placeholder={`Select ${selectedOption}`}
            width={"15.8rem"}
            options={
              selectedOption === "namespaces" ? serviceNamespaces : serviceNames
            }
            values={selectedValues}
            onChange={selectFilterChanged}
            isEnabled={true}
            isValid={selectedValues.length > 0}
          />
        </SelectorWrapper>
      )) || (
        <FormTextInput
          autoFocus
          isValid={isValid}
          width={"14.7"}
          height={"1.1"}
          placeholder={
            selectedOption === "labels"
              ? 'e.g. label1:"val1", label2:"val2"'
              : selectedOption === "annotations"
              ? 'e.g. annotation1:"val1", annotation2:"val2"'
              : ""
          }
          onChange={(e) => {
            setError(id, validateInput(selectedOption, e.target.value));
            handleChange(selectorKey, e.target.value);
          }}
          value={value}
        />
      )}
      {enableDeletion && (
        <TrashButton
          onClick={() => {
            handleClose(selectorKey);
            deleteError(id);
          }}
        />
      )}
    </Container>
  );
};
export default Selector;
