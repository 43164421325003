import React, { useMemo } from "react";
import { Divider } from "@komodorio/design-system/deprecated";

import WorkflowCheck from "../..";
import { AvailabilityCheckType } from "../../useWorkflowsChecks";
import { Details } from "../../../styles";
import { WorkloadHealthyPodsOutput, WorkloadInfoOutput } from "../types";
import { useKomodorServiceAsResourceWithInterval } from "../../../../../ResourceView/useKomodorServiceAsResource";
import { useAvailabilityActions } from "../../../../../Actions/common/monitorsActions";

import { PodsSummary } from "./AvailabilitySummaryUtils";
import { useOpinionatedSummary } from "./hooks";

const AvailabilitySummary: React.FC<{
  checks: WorkflowCheck[];
  serviceId: string;
  reasons: string[];
  wfEventTime?: Date;
  wfClosedAt?: Date | null;
  showPrevious: boolean;
}> = ({
  reasons,
  checks,
  wfEventTime,
  wfClosedAt,
  serviceId,
  showPrevious,
}) => {
  const podsCheckOutputToShow = useMemo(() => {
    const previousPodsHealthyCheck = checks.find(
      (c) => c.type === AvailabilityCheckType.WorkloadPodsHealthyPrevious
    );
    if (showPrevious && previousPodsHealthyCheck) {
      return previousPodsHealthyCheck?.output
        ? (previousPodsHealthyCheck?.output as WorkloadHealthyPodsOutput)
        : undefined;
    }
    const currentPodsHealthyCheck = checks.find(
      (c) => c.type === AvailabilityCheckType.WorkloadPodsHealthy
    );
    return currentPodsHealthyCheck?.output
      ? (currentPodsHealthyCheck?.output as WorkloadHealthyPodsOutput)
      : undefined;
  }, [checks, showPrevious]);

  const opinionatedSummary = useOpinionatedSummary(podsCheckOutputToShow);
  const [resource] = useKomodorServiceAsResourceWithInterval(serviceId);

  const infoCheck = checks.find((c) =>
    showPrevious
      ? c.type === AvailabilityCheckType.WorkloadInfoPrevious
      : c.type === AvailabilityCheckType.WorkloadInfo
  );

  const { shouldShowRollback, shouldShowChangeResources } =
    useAvailabilityActions(reasons, checks, wfEventTime);
  const shouldShowAction = shouldShowRollback || shouldShowChangeResources;

  const issueStartTime = useMemo(
    () =>
      new Date(
        (infoCheck?.output as WorkloadInfoOutput)?.infoTime ?? wfEventTime ?? ""
      ),
    [infoCheck?.output, wfEventTime]
  );
  const issueEndTime = useMemo(() => {
    const prevResultClosedAt = (infoCheck?.output as WorkloadInfoOutput)
      ?.closedAt;
    const endTime = showPrevious
      ? prevResultClosedAt
        ? new Date(prevResultClosedAt)
        : undefined
      : wfClosedAt;
    return endTime ?? new Date();
  }, [infoCheck?.output, showPrevious, wfClosedAt]);

  return opinionatedSummary?.length > 0 ||
    podsCheckOutputToShow?.failureMessage ? (
    <>
      <Details>
        <PodsSummary
          opinionatedSummary={opinionatedSummary}
          infoTime={issueStartTime}
          endTime={issueEndTime}
          failureMessage={podsCheckOutputToShow?.failureMessage}
          desiredReplicas={podsCheckOutputToShow?.desiredReplicas ?? 0}
          readyReplicas={podsCheckOutputToShow?.readyReplicas ?? 0}
          resource={resource}
          clusterName={resource?.cluster ?? ""}
          namespace={resource?.namespace ?? ""}
          shouldShowAction={shouldShowAction}
          shouldShowRollback={shouldShowRollback}
          shouldShowChangeResources={shouldShowChangeResources}
        />
      </Details>
      <Divider />
    </>
  ) : null;
};

export default AvailabilitySummary;
