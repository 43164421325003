import React from "react";
import styled from "styled-components";

import { AriaLabels } from "../../../shared/config/ariaLabels";
import { PageName, usePages } from "../links/pages";

import { ButtonLinkStyle } from "./styles";

const ChatSupportButton = styled.div`
  font-family: Roboto, serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  ${ButtonLinkStyle}
`;

const name = PageName.ChatSupport;

export const ChatSupportLink: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <ChatSupportButton
      onClick={() => {
        window.zE?.("messenger", "open");
      }}
      aria-label={AriaLabels.AppBar.ChatSupport}
    >
      {page.icon && <page.icon />}
      {name}
    </ChatSupportButton>
  ) : null;
};
