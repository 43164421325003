/* tslint:disable */
/* eslint-disable */
/**
 * Global Search API
 * This is an API that exposes search functionality for the Komodor platform with elastcisearch
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof SearchResponse
     */
    'results': Array<SearchResult>;
}
        /**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'kind': string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResult
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'deletedPodId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'deletedAt'?: string;
}
    
        /**
* Get global search results
* @param params SearchApiApiV1SearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1SearchGetUrl = (params: SearchApiApiV1SearchGetRequest, baseUrl: string): string => {
    // verify required parameter 'query' is not null or undefined
    assertParamExists('apiV1SearchGet', 'query', params.query)
    const localVarPath = `/api/v1/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.query !== undefined) {
            localVarQueryParameter['query'] = params.query;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1SearchGet operation in SearchApi.
* @export
* @interface SearchApiApiV1SearchGetRequest
*/
export interface SearchApiApiV1SearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof SearchApiApiV1SearchGet
    */
    readonly query: string
}


    
