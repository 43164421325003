import { useCallback, useMemo } from "react";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { ViolationsFilterGroupByGeneric } from "@/generated/reliabilityApi";
import { ViolationStatus } from "@/generated/workspacesApi";

type OpenReliabilityDrawerProps = {
  impactGroupId: string;
  clusters: string[];
  checkTypes?: string[];
};

export const useOpenReliabilityDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    ({ impactGroupId, clusters, checkTypes }: OpenReliabilityDrawerProps) => {
      pushDrawer({
        drawerType: DrawerType.ViolationsDrawerByScope,
        scope: {
          impactGroupId: [impactGroupId],
          clusterName: clusters,
          checkType: checkTypes,
        },
        additionalConfig: {
          paginationModel: {
            page: 0,
            pageSize: 10,
          },
          showImpactAggregations: true,
          showGroupSeverities: true,
        },
      });
    },
    [pushDrawer]
  );
};

export const useParseViolationsCounts = (
  data: ViolationsFilterGroupByGeneric | undefined
): ViolationStatus => {
  return useMemo(
    () => ({
      count: data?.items.reduce((sum, item) => sum + item.value, 0) ?? 0,
      low: {
        count: data?.items.find((item) => item.key === "low")?.value ?? 0,
        reasons: [],
      },
      medium: {
        count: data?.items.find((item) => item.key === "medium")?.value ?? 0,
        reasons: [],
      },
      high: {
        count: data?.items.find((item) => item.key === "high")?.value ?? 0,
        reasons: [],
      },
    }),
    [data?.items]
  );
};
