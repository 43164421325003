import React from "react";
import styled from "styled-components";

import { extractServiceName } from "../../../../../../shared/utils/serviceHelpers";
import { lightBlue } from "../../../../../../Colors";
import { FilterTag, StyledTable } from "../../../../common/styles";
import { BoldGrayText } from "../../CheckDetailsDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ImpactedServicesCheck from "../ImpactedServicesCheck";
import { dispatchEvent } from "../../../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";
import { StyledLink } from "../../CheckCard";
import {
  healthyTextColor,
  unhealthyColor,
} from "../../../../../servicesView/styles";

import { useGetKomodorService } from "@/shared/hooks/resources-api/client/komodor-services/useKomodorServices";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { SERVICES_ROUTE } from "@/components/routes/routes";

const Label = styled(FilterTag)`
  background-color: ${lightBlue};
  line-height: 1rem;
`;

const HealthStatus = styled.div<{ healthy: boolean }>`
  color: ${({ healthy }) => (healthy ? healthyTextColor : unhealthyColor)};
  font-weight: bold;

  ::after {
    content: ${({ healthy }) => (healthy ? '"HEALTHY"' : '"UNHEALTHY"')};
  }
`;

const ServiceDetailsRow: React.FC<{
  serviceId: string;
  checkType: string;
}> = ({ serviceId, checkType }) => {
  const { fetchAllServicesFromResourcesApi } = useOverridableFlags();
  const { data: serviceResponse } = useGetKomodorService(
    {
      fields: ["deploy_state", "health_state"],
      servicesIds: [serviceId],
    },
    true,
    true,
    undefined,
    !!fetchAllServicesFromResourcesApi
  );

  const service = serviceResponse?.servicesWithLastUpdatedAt?.services?.[0];

  const serviceName = extractServiceName(serviceId);
  if (!service) return null;
  return (
    <tr>
      <td>
        {service.isDeleted ? (
          serviceName
        ) : (
          <StyledLink
            to={`${SERVICES_ROUTE}/${serviceId}`}
            onClick={(e) => {
              dispatchEvent(
                AnalyticEvents.WorkflowsView.Impacted_Services_Clicked,
                {
                  service: serviceId,
                  checkType: checkType,
                }
              );
            }}
          >
            {serviceName}
          </StyledLink>
        )}
      </td>
      <td>
        {service.replicas && !service.isDeleted
          ? `${service.replicas?.ready}/${service.replicas?.desired}`
          : null}
      </td>
      <td>
        {service.isDeleted
          ? "Deleted"
          : service.healthy !== undefined && (
              <HealthStatus healthy={service.healthy} />
            )}
      </td>
      <td>
        <Label>
          cluster: <b>{service.k8sCluster}</b>
        </Label>
        <Label>
          namespace: <b>{service.env}</b>
        </Label>
      </td>
    </tr>
  );
};

const ImpactedServicesDetails: React.FC<{
  check: ImpactedServicesCheck;
}> = ({ check }) => {
  const checkOutput = check.output;
  const checkType = check.type;

  return checkOutput?.services?.length ? (
    <>
      <br />
      <BoldGrayText>Impacted Services</BoldGrayText>
      <StyledTable>
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Replicas</th>
            <th>State</th>
            <th>Cluster and Namespace</th>
          </tr>
        </thead>
        <tbody>
          {checkOutput.services.flat().map((service, index) => (
            <ServiceDetailsRow
              key={index}
              serviceId={service}
              checkType={checkType}
            />
          ))}
        </tbody>
      </StyledTable>
    </>
  ) : null;
};

export default ImpactedServicesDetails;
