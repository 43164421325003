import React, { useMemo } from "react";
import { subHours } from "date-fns";

import { Timeframe } from "../../shared/types/TimeWindow";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { doesAgentVersionSupportResourceListFromATM } from "../../shared/utils/agent/agent";
import FetchDataLoading from "../common/loaders/LoadingState";
import {
  AgentInfo,
  agentInfoToProperties,
} from "../../shared/hooks/useAgentInfo/useAgentInfo";

import { KubernetesResource } from "./inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useInspectionParams } from "./InspectionViews/common";
import InspectionViewAgentTask from "./InspectionViews/InspectionViewAgentTask";
import InspectionViewAtm from "./InspectionViews/InspectionViewAtm";
import { Panel } from "./styles";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialTimeWindow = {
  start: subHours(new Date(), 1),
  end: new Date(),
  timeframe: Timeframe.LastHour,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum InspectionType {
  DB = "db",
  OldAgentTask = "oldAgentTask",
  ATM = "atm",
}

const InspectionView: React.FC<{
  kubernetesResource: KubernetesResource;
}> = ({ kubernetesResource }) => {
  const {
    cluster,
    onClusterChange,
    agentInfo: agentType,
    agentId,
  } = useInspectionParams(kubernetesResource);

  const { inspectionAtm } = useOverridableFlags();

  const agentInfo = useMemo(() => {
    if (!agentType) return null;
    return agentInfoToProperties(agentType as AgentInfo);
  }, [agentType]);

  const inspectionType = useMemo(() => {
    if (!cluster || !agentInfo) return undefined;
    if (
      inspectionAtm !== false &&
      doesAgentVersionSupportResourceListFromATM(agentInfo)
    ) {
      return InspectionType.ATM;
    }

    return InspectionType.OldAgentTask;
  }, [agentInfo, cluster, inspectionAtm]);
  if (!inspectionType || !cluster || !agentId) {
    return (
      <Panel>
        <FetchDataLoading
          dataName={
            kubernetesResource.PresentName ?? kubernetesResource.NameInK8S
          }
        />
      </Panel>
    );
  }
  if (inspectionType === InspectionType.OldAgentTask) {
    return (
      <InspectionViewAgentTask
        kubernetesResource={kubernetesResource}
        onClusterChange={onClusterChange}
        cluster={cluster}
        agentInfo={agentInfo}
        agentId={agentId}
      />
    );
  }
  return (
    <InspectionViewAtm
      kubernetesResource={kubernetesResource}
      onClusterChange={onClusterChange}
      cluster={cluster}
      agentInfo={agentInfo}
      agentId={agentId}
    />
  );
};
export default InspectionView;
