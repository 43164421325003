import React from "react";
import Stack from "@mui/material/Stack";
import {
  AdvancedMultiSelect,
  TextInput,
} from "@komodorio/design-system/komodor-ui";

import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";
import isElectronContext from "@/shared/context/electron/isElectronContext";
import TimezoneSelect from "@/components/Settings/General/TimezoneSelect";
import { useProfilePage } from "@/pages/user-settings/useProfilePage";
import { ValidationsProvider } from "@/shared/context/ValidationsProvider";
import { useGetRbacRoles } from "@/shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";
import { mapAvailableRoles } from "@/components/Settings/Users/hooks";
import { ariaLabels } from "@/pages/user-settings/constants";
import { useUserLoginInfo } from "@/shared/hooks/useUserMetadata/useUserLoginInfo";

const WIDTH = "360px";

const NameInput: React.FC = () => {
  const { handleNameChange, name, errors } = useProfilePage();

  return (
    <TextInput
      size="medium"
      label="Name"
      value={name}
      onChange={handleNameChange}
      error={!!errors["name"]}
      helperText={errors["name"]}
      fullWidth
      aria-label={ariaLabels.name}
    />
  );
};

export const ProfilePage: React.FC = () => {
  const { userRoles, user } = useProfilePage();

  // We get the email from the Auth0 too, in case we are in switch-account mode
  const { email: fallbackEmail } = useUserLoginInfo() || {};
  const { data: allRoles = [] } = useGetRbacRoles();
  const roleOptions = mapAvailableRoles({
    allRoles,
    currentRoles: userRoles,
    isOnlyOneAccountAdmin: false,
  });

  return (
    <UserSettingsWrapper title="Profile">
      <Stack direction="column" spacing={2} sx={{ width: WIDTH }}>
        <ValidationsProvider>
          <NameInput />
        </ValidationsProvider>
        {!isElectronContext() && (
          <TextInput
            size="medium"
            label="Email"
            disabled
            defaultValue={user?.email ?? fallbackEmail}
            fullWidth
            aria-label={ariaLabels.email}
          />
        )}
        <TimezoneSelect
          width={WIDTH}
          selectPopoverProps={{
            height: "200px",
            width: WIDTH,
          }}
        />
        {!!userRoles && (
          <AdvancedMultiSelect
            size="medium"
            width={WIDTH}
            label="Roles"
            value={userRoles}
            disabled
            options={roleOptions}
            showTooltip
            tooltipPlacement="right"
            ariaLabel={ariaLabels.roles}
          />
        )}
      </Stack>
    </UserSettingsWrapper>
  );
};
