import React from "react";
import styled from "styled-components";

import { blackGreyText } from "../../../../../../Colors";
import { TextRegular } from "../../../../../common/typography";
import errorImg from "../../../../installation/kubernetes/assets/error.svg";

import {
  IconStyle,
  StyledStepContent,
  H2MessageTitle,
  StyledButton,
} from "./styles";

const ErrorIcon = styled.img.attrs({ src: errorImg, alt: "Error" })`
  ${IconStyle}
`;
const StyledTextRegular = styled(TextRegular)`
  white-space: pre-line;
  color: ${blackGreyText};
`;
const HelpButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const TryAgainButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 21rem;
  align-self: flex-end;
`;

const TryAgainButton = styled(StyledButton)`
  background-color: white;
  color: ${blackGreyText};
  border: 1px solid #dcdddf;
`;

const InstallationError: React.FC<{
  installError: string;
  accountOrigin: string;
  showHelpMessage: (prePopulatedContent?: string | undefined) => void;
  setInstallError: React.Dispatch<React.SetStateAction<string>>;
}> = ({ installError, accountOrigin, showHelpMessage, setInstallError }) => {
  const handleRetry = () => {
    setInstallError("");
  };
  return (
    <StyledStepContent>
      <ErrorIcon />
      <H2MessageTitle>Something is missing....</H2MessageTitle>
      <StyledTextRegular>{installError}</StyledTextRegular>
      {accountOrigin === "self-serve" ? (
        <TryAgainButtonContainer>
          <TryAgainButton onClick={handleRetry}>Try Again</TryAgainButton>
        </TryAgainButtonContainer>
      ) : (
        <HelpButtonsContainer>
          <StyledButton
            onClick={() => {
              showHelpMessage();
            }}
          >
            Contact Us
          </StyledButton>
          <TryAgainButton onClick={handleRetry}>Try Again</TryAgainButton>
        </HelpButtonsContainer>
      )}
    </StyledStepContent>
  );
};

export default InstallationError;
