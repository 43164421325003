import React, { useMemo } from "react";
import { InfoCircleOutlined16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import containersIcon from "../../assets/containers.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NativePodEventGroup from "../../../EventGroup/NativePodEventGroup";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";
import EventDetailsSection from "../components/EventDetailsSection";
import { pinkBrand } from "../../../../../Colors";
import {
  buildContainerStatuses,
  ContainerState,
  ContainerStatus,
  StatusCondition,
} from "../../../EventGroup/nativePodEvent/utils";
import { useActiveAgent } from "../../../../../shared/hooks/useAgents";
import { buildKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetPodsState } from "../../../../../shared/hooks/resources-api/client/useGetPodsState";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const iconDimensions50 = { height: "50%", width: "50%" };
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const iconDimensions65 = { height: "65%", width: "65%" };

export const Grid = styled.div<{ colCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ colCount }) => colCount}, auto);
  justify-content: start;
  gap: 0.75rem;
  .header {
    font-weight: bold;
  }
  .red-text {
    color: ${pinkBrand};
  }
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useNativePodDetails = (
  eventGroup: NativePodEventGroup
): { label: string; value: string }[] => {
  const { format } = useDateFormatter();
  return useMemo(() => {
    return [
      {
        label: "First occurrence",
        value: format(eventGroup.startTime),
      },
      {
        label: "Last occurrence",
        value: format(eventGroup.endTime),
      },
      {
        label: "Count",
        value: eventGroup.count.toString(),
      },

      {
        label: "Message",
        value: eventGroup.message,
      },
    ];
  }, [
    eventGroup.count,
    eventGroup.endTime,
    eventGroup.startTime,
    eventGroup.message,
    format,
  ]);
};

export const ContainerStatuses: React.FC<{
  containerStatuses: ContainerStatus[];
}> = ({ containerStatuses }) => {
  const preparedContainerStatuses = useMemo(() => {
    const containersGrid = containerStatuses.map((c, index) => {
      return (
        <React.Fragment key={index}>
          <div>{c.name}</div>
          <div>
            {c.state} {c.stateReason ? `: ${c.stateReason}` : ""}
            {c.exitCode ? ` (exitCode: ${c.exitCode})` : ""}
          </div>
        </React.Fragment>
      );
    });
    return containersGrid.length
      ? [
          <React.Fragment key="header">
            <div className="header">Name</div>
            <div className="header">Status</div>
          </React.Fragment>,
        ].concat(containersGrid)
      : null;
  }, [containerStatuses]);
  return (
    <EventDetailsSection
      icon={containersIcon}
      iconDimensions={iconDimensions65}
      title={`Containers (${containerStatuses.length})`}
    >
      <Grid colCount={2}>{preparedContainerStatuses}</Grid>
    </EventDetailsSection>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const joinAvailableContainerNames = (
  containers: ContainerStatus[]
): string | null => {
  return (
    containers
      .filter((container) => container.state !== ContainerState.Terminated)
      .map((container) => container.name)
      .join(",") || null
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useContainerNames = (
  containers: ContainerStatus[],
  podName: string,
  namespace: string,
  clusterName: string
): string | null => {
  const agentId = useActiveAgent(clusterName);

  const uid = buildKomodorUid({
    kind: "Pod",
    clusterName,
    namespace,
    resourceName: podName,
  });
  const { data } = useGetPodsState(
    {
      komodorUid: uid,
      fields: ["json"],
    },
    !!agentId
  );

  const pods = data?.data?.json;
  const containerStatuses = buildContainerStatuses(
    pods?.[0]?.status?.containerStatuses
  );
  return joinAvailableContainerNames(containerStatuses);
};

export const StatusConditions: React.FC<{
  statusConditions: StatusCondition[];
}> = ({ statusConditions }): JSX.Element => {
  const statuses = useMemo(() => {
    const statuses = statusConditions.map((s, index) => (
      <React.Fragment key={index}>
        <div>{s.type}</div>
        <div className={!s.status ? "red-text" : ""}>{`${s.status}`}</div>
        <div>{s.message}</div>
      </React.Fragment>
    ));
    return statuses.length
      ? [
          <React.Fragment key="header">
            <div className="header">Type</div>
            <div className="header">Status</div>
            <div className="header">Message</div>
          </React.Fragment>,
        ].concat(statuses)
      : null;
  }, [statusConditions]);
  return (
    <EventDetailsSection
      icon={InfoCircleOutlined16}
      iconDimensions={iconDimensions65}
      title="Conditions"
    >
      <Grid colCount={3} data-e2e-selector="native-pod-status-conditions">
        {statuses}
      </Grid>
    </EventDetailsSection>
  );
};
