import React from "react";
import { ResourceQuota as ResourceQuotaType } from "kubernetes-types/core/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { Quota } from "@komodorio/design-system/icons";

import { Header } from "../common/Header/Header";
import { AvailableActions } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

export default class ResourceQuota implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [{ label: ResourceTabName.Yaml }];
  readonly actions = [AvailableActions.Describe];
  readonly fullObj;
  readonly defaultTab;

  constructor(
    cluster: string,
    resourceQuota: ResourceQuotaType,
    agentId: string
  ) {
    this.agentId = agentId;
    this.id = resourceQuota.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = resourceQuota.metadata?.namespace ?? "";
    this.name = resourceQuota.metadata?.name ?? "";
    this.kind = resourceQuota.kind ?? "";
    this.labels = resourceQuota.metadata?.labels ?? {};
    this.annotations = resourceQuota.metadata?.annotations ?? {};
    this.icon = Quota;
    this.fullObj = resourceQuota;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
