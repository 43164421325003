import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

const Container = styled.div<{ width?: string; height?: string }>`
  grid-row: body / buttons;

  display: flex;
  ${({ width, height }) => `
    width: ${width ?? "100%"};
    height: ${height ?? "100%"};
  `}
  justify-content: center;
  flex-direction: column;
`;

export const Step = styled.div`
  height: 90%;
`;
interface StepsProps {
  width?: string;
  height?: string;
  curStep: number;
  className?: string;
  children?: React.ReactNode;
  setNumberOfSteps?: (numberOfSteps: number) => void;
}

const Steps: React.FC<StepsProps> = ({
  width,
  height,
  curStep,
  children,
  className,
  setNumberOfSteps,
}) => {
  const steps = useMemo(() => React.Children.toArray(children), [children]);

  useEffect(() => {
    if (setNumberOfSteps) {
      setNumberOfSteps(steps.length);
    }
  }, [setNumberOfSteps, steps]);

  return (
    <Container className={className} width={width} height={height}>
      {steps[curStep] ?? null}
    </Container>
  );
};

export default Steps;
