import React from "react";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { CRD as crdIcon } from "@komodorio/design-system/icons";

import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CustomResourceDetailsTab } from "../tabs/CustomResourceTabs/CustomResourceDetailsTab";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

export interface CustomResourceBasic {
  apiVersion: string;
  kind: string;

  metadata: {
    creationTimestamp: string;
    name: string;
    namespace: string;
    resourceVersion: string;
    uid: string;
    annotations: Record<string, string>;
    managedFields?: {
      apiVersion: string;
      fieldsType: string;
      manager: string;
      operation: string;
      time: string;
    }[];
    labels?: Record<string, string>;
  };

  spec: {
    image: string;
  };

  status?: Record<string, unknown>;
}

export default class CustomResource implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [];
  readonly fullObj;
  readonly crdName: string | undefined;
  readonly displayKind: string | undefined;
  readonly defaultTab;

  constructor(
    cluster: string,
    cr: CustomResourceBasic,
    agentId: string,
    crdName: string,
    displayKind?: string
  ) {
    this.agentId = agentId;
    this.id = cr.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = cr.metadata?.namespace ?? "";
    this.name = cr.metadata?.name ?? "";
    this.kind = cr.kind ?? "";
    this.labels = cr.metadata?.labels ?? {};
    this.annotations = cr.metadata?.annotations ?? {};
    this.icon = crdIcon;
    this.fullObj = cr;
    this.crdName = crdName;
    this.displayKind = displayKind;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <CustomResourceDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab
            resource={this}
            options={{ dropManagedFields: true }}
          />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.displayKind ?? this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
