import { LogLineColor } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/relatedEvidenceTypes";
import {
  errorCodingWords,
  warningCodingWords,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/relatedEvidenceConstants";

export const getLogLineColor = (logLevel: string): LogLineColor => {
  if (
    errorCodingWords.find((word) =>
      logLevel.toLowerCase().includes(`[${word}]`)
    )
  ) {
    return "error";
  }
  if (
    warningCodingWords.find((word) =>
      logLevel.toLowerCase().includes(`[${word}]`)
    )
  ) {
    return "warning";
  }
  return "info";
};
