import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";

import { pinkBrand } from "../../../../../Colors";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";
import { isKubectlErrorStatus, redStatuses } from "../../Status";

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content auto auto;
  justify-content: start;
  gap: 0.75rem;
  > :nth-child(3n + 2) {
    font-weight: bold;
    text-transform: capitalize;
  }
  .red-text {
    color: ${pinkBrand};
  }
`;

const getEventStatusClass = (status: ReactNode) =>
  typeof status === "string" &&
  (redStatuses.has(status.toLowerCase()) || isKubectlErrorStatus(status))
    ? "red-text"
    : "";

export interface EventsGridPropItem {
  eventTime: Date;
  status: ReactNode;
  details: string;
}
interface EventsGridProps {
  events: EventsGridPropItem[];
}
const EventsGrid: React.FC<EventsGridProps> = ({ events }) => {
  const { format } = useDateFormatter();
  const reversed = useMemo(() => {
    return Array.from(events).sort(
      (e1, e2) => e2.eventTime.getTime() - e1.eventTime.getTime()
    );
  }, [events]);
  return (
    <Container>
      {reversed.map((e, i) => (
        <React.Fragment key={i}>
          <div>{format(e.eventTime)}</div>
          <div className={getEventStatusClass(e.status)}>{e.status}</div>
          <div>{e.details}</div>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default EventsGrid;
