import { useEffect } from "react";

import useAgents from "../../hooks/useAgents";

import isElectronContext from "./isElectronContext";

const useUpdateAgentConnectionStatus = (): void => {
  const agents = useAgents();
  const activeAgentsName = agents?.activeAgents.map(
    (agent) => agent.clusterName
  );

  useEffect(() => {
    if (!isElectronContext()) return;
    window.electronAPI?.updateAgentsConnectionStatus?.(activeAgentsName);
  }, [activeAgentsName]);
};

export default useUpdateAgentConnectionStatus;
