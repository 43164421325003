import React, { useMemo } from "react";
import { Divider } from "@komodorio/design-system/deprecated";
import Typography from "@mui/material/Typography";

import { SectionColumn, SectionName } from "../../common/styles";
import { ContainerLog } from "../../../../../../../monitorsView/workflowRunView/checks/availability/types";
import { useQueryRefreshWithVariables } from "../../../../../../../../shared/hooks/useQueryWithVariables";
import { useFetchPodLogsQuery } from "../../../../../../../../generated/graphql";
import { LogsType } from "../../../../../../LogsViewer/LogsBlackText";
import { LogsViewer } from "../../../../../../LogsViewer/LogsViewer";

const FailedContainerLogsSection: React.FC<{
  failedContainer: ContainerLog;
  clusterName: string;
  namespace: string;
}> = ({ failedContainer, clusterName, namespace }) => {
  const taskVariables = useMemo(() => {
    return failedContainer?.logTaskId
      ? { taskId: failedContainer.logTaskId }
      : undefined;
  }, [failedContainer?.logTaskId]);

  const [data] = useQueryRefreshWithVariables(
    useFetchPodLogsQuery,
    taskVariables ?? null
  );

  const logs = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pod_logs;
  }, [data]);

  if (logs.length === 0) {
    return null;
  }

  return (
    <>
      <SectionColumn>
        <SectionName>failed container logs</SectionName>
        <LogsViewer
          podLogsRes={logs}
          loading={false}
          containerPickerElement={
            <Typography>Container: {failedContainer.name}</Typography>
          }
          podName={failedContainer.podName}
          containerName={failedContainer.name}
          logsType={LogsType.Issue}
          maxHeight="15rem"
          showSearchBar={false}
          clusterName={clusterName}
          namespace={namespace}
        />
      </SectionColumn>
      <Divider />
    </>
  );
};

export default FailedContainerLogsSection;
