import { theme } from "@komodorio/design-system";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import OpenInFull from "@mui/icons-material/OpenInFull";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import { Drawer as DeprecatedDrawer } from "@komodorio/design-system/deprecated";

import { LinesLoader } from "../common/loaders/Line";
import { AriaLabels } from "../../shared/config/ariaLabels";
import { IsOldDrawerStateMechanismProvider } from "../../shared/context/drawersStack/IsOldDrawerStateMechanismProvider";
import { RESOURCE_PREFIX } from "../../shared/config/urlSearchParamsKeys";
import { useIsOldDrawerStateMechanism } from "../../shared/context/drawersStack/useIsOldDrawerStateMechanism";
import {
  parseDrawerUrlStatesToSearchParams,
  useCurrentDrawerState,
} from "../../shared/context/drawersStack/helpers";
import { useActiveAgent } from "../../shared/hooks/useAgents";
import { DrawerProps } from "../../shared/store/drawersStackStore/types";

import { useKomodorServiceAsResourceWithInterval } from "./useKomodorServiceAsResource";
import Resource from "./resources";
import ResourceView from "./ResourceView";
import useResourceWithInterval from "./useResource";
import { IResourceDrawerByData, IResourceDrawerByServiceId } from "./types";
import { ResourceNotFound } from "./ResourceNotFound";

const OpenInFullTooltip = "Open in full page";

export const StyledDrawer = styled(DeprecatedDrawer)`
  background-color: ${theme.background.bgGrayLight};
`;

type ResourceDrawerByDataProps = DrawerProps & IResourceDrawerByData;
export const ResourceDrawerByData: React.FC<ResourceDrawerByDataProps> = ({
  cluster,
  namespace,
  resourceType,
  resourceName,
  additionalData,
  buildPreloadResource,
  ...rest
}) => {
  const existData = useMemo(
    () =>
      buildPreloadResource && additionalData
        ? additionalData.isCustomResource
          ? additionalData
          : {
              kind: resourceType,
              cluster,
              metadata: {
                id: additionalData.id,
                name: resourceName,
                namespace,
                ownerReferences: [{ name: additionalData.controlledBy }],
              },
              calculatedStatus: additionalData.calculatedStatus,
            }
        : undefined,
    [
      additionalData,
      buildPreloadResource,
      cluster,
      namespace,
      resourceName,
      resourceType,
    ]
  );
  const agentId = useActiveAgent(cluster) ?? "";

  const { resource, isFetching } = useResourceWithInterval({
    agentId,
    cluster,
    namespace,
    resourceName,
    resourceType,
    existData,
  });

  return (
    <ResourceDrawer {...rest} resource={resource} isFetching={isFetching} />
  );
};

export type ResourceDrawerByServiceIdProps = DrawerProps &
  IResourceDrawerByServiceId;

export const ResourceDrawerByServiceId: React.FC<
  ResourceDrawerByServiceIdProps
> = ({ serviceId, ...rest }) => {
  const [resource, isFetching] =
    useKomodorServiceAsResourceWithInterval(serviceId);

  return (
    <ResourceDrawer {...rest} resource={resource} isFetching={isFetching} />
  );
};

interface ResourceDrawerProps extends DrawerProps {
  resource: Resource | undefined;
  isFetching?: boolean;
}
export const ResourceDrawer: React.FC<ResourceDrawerProps> = (props) => {
  return (
    <IsOldDrawerStateMechanismProvider>
      <ResourceDrawerContent {...props} />
    </IsOldDrawerStateMechanismProvider>
  );
};
const ResourceDrawerContent: React.FC<ResourceDrawerProps> = ({
  open,
  onClose,
  resource,
  isFetching = false,
}) => {
  const currentDrawerState = useCurrentDrawerState();

  const isOldDrawerStateMechanism = useIsOldDrawerStateMechanism();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const closeDrawer = useCallback(() => {
    if (isOldDrawerStateMechanism) {
      const cleanSearchParams = [];
      for (const [key, value] of searchParams.entries()) {
        if (!key.startsWith(`${RESOURCE_PREFIX}${resource?.kind}`)) {
          cleanSearchParams.push([key, value]);
        }
      }
      setSearchParams(new URLSearchParams(cleanSearchParams));
    }
    onClose();
  }, [
    isOldDrawerStateMechanism,
    onClose,
    setSearchParams,
    searchParams,
    resource?.kind,
  ]);

  const fullScreenUrl = useMemo(() => {
    const screenUrl = resource?.getFullScreenUrl?.();
    if (!screenUrl) {
      return;
    }
    const drawerSearchParams = parseDrawerUrlStatesToSearchParams(
      currentDrawerState?.urlStates
    );
    return {
      pathname: screenUrl,
      search: drawerSearchParams.toString(),
    };
  }, [currentDrawerState?.urlStates, resource]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      elevation={5}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          width: "60%",
          backgroundColor: theme.background.bgGrayLight,
        },
      }}
      data-e2e-selector="drawer"
      aria-label={AriaLabels.ResourceView.ResourceDrawer}
    >
      {!resource && !isFetching ? (
        <ResourceNotFound onClose={onClose} />
      ) : !resource ? (
        <LinesLoader marginTop="100px" />
      ) : (
        <ResourceView
          resource={resource}
          leftHeaderActions={
            <>
              <IconButton
                aria-label={AriaLabels.ResourceView.DrawerCloseButton}
                onClick={closeDrawer}
                sx={{ padding: 0 }}
              >
                <Close />
              </IconButton>
              {fullScreenUrl && (
                <Tooltip arrow={false} title={OpenInFullTooltip}>
                  <IconButton
                    aria-label={AriaLabels.ResourceView.DrawerOpenInFullButton}
                    onClick={() => navigate(fullScreenUrl)}
                    sx={{ padding: 0 }}
                  >
                    <OpenInFull sx={{ fontSize: "1.167rem" }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
      )}
    </Drawer>
  );
};
