import { omit } from "lodash";

import resolveStringPath from "./resolveStringPath";

export interface Specs {
  oldSpec: Record<string, unknown>;
  newSpec: Record<string, unknown>;
}

const excludedMetadataFields = ["generation", "resourceVersion"];
export const excludedDiffKeys = new Set(
  excludedMetadataFields.map((f) => `metadata.${f}`)
);

const isObject = (v: unknown) => v && typeof v === "object";

const relevantFields = ({
  status,
  metadata,
  ...o
}: Record<string, unknown>): Record<string, unknown> => ({
  ...o,
  metadata: omit(
    metadata as Record<string, unknown> | undefined,
    excludedMetadataFields
  ),
});

export const filterDiffKeys = (diffKeys: string[]): (string | null)[] => {
  return diffKeys
    ?.filter((k) => k && !k.startsWith("status.") && !excludedDiffKeys.has(k))
    .map((k) => resolveStringPath(k));
};

const getSpecsForDiff = (oldSpec: unknown, newSpec: unknown): Specs | null =>
  isObject(oldSpec) && isObject(newSpec)
    ? {
        oldSpec: relevantFields(oldSpec as Record<string, unknown>),
        newSpec: relevantFields(newSpec as Record<string, unknown>),
      }
    : null;

export default getSpecsForDiff;
