import React from "react";
import { palette } from "@komodorio/design-system";
import Tab from "@mui/material/Tab";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

const StyledMuiTab = styled(Tab)`
  && {
    padding: 0 2rem 1rem 0;
  }
`;
const SnapshotLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const SnapshotLabel = styled.span`
  margin-bottom: 0.4rem;
`;

type TabLabel = "First Snapshot" | "Latest Snapshot";
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const enum SnapshotTab {
  firstSnapshot = "firstSnapshot",
  latestSnapshot = "latestSnapshot",
}

const tabLabels: Record<string, { label: TabLabel; value: SnapshotTab }> = {
  firstSnapshot: { label: "First Snapshot", value: SnapshotTab.firstSnapshot },
  latestSnapshot: {
    label: "Latest Snapshot",
    value: SnapshotTab.latestSnapshot,
  },
};
const SnapshotTabs: React.FC<{
  selectedSnapshot: string;
  firstTimestamp?: string;
  lastTimestamp?: string | null;
  setSelectedSnapshot: (
    value: string | null,
    replace?: boolean | undefined
  ) => void;
}> = ({
  setSelectedSnapshot,
  selectedSnapshot,
  firstTimestamp,
  lastTimestamp,
}) => {
  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedSnapshot(newValue);
  };
  const { firstSnapshot, latestSnapshot } = tabLabels;
  const { format } = useDateFormatter();

  return (
    <TabContext value={selectedSnapshot}>
      <TabList onChange={onTabChange}>
        <StyledMuiTab
          label={
            <SnapshotLabelContainer>
              <SnapshotLabel>{firstSnapshot.label}</SnapshotLabel>
              <Typography color={palette.gray[700]}>
                {firstTimestamp ? format(new Date(firstTimestamp)) : ""}
              </Typography>
            </SnapshotLabelContainer>
          }
          disabled={!firstTimestamp}
          value={firstSnapshot.value}
          aria-label={AriaLabels.InvestigationMode.FirstSnapshotTab}
        />
        <StyledMuiTab
          label={
            <SnapshotLabelContainer>
              <SnapshotLabel>{latestSnapshot.label}</SnapshotLabel>
              <Typography color={palette.gray[700]}>
                {lastTimestamp ? format(new Date(lastTimestamp)) : ""}
              </Typography>
            </SnapshotLabelContainer>
          }
          value={latestSnapshot.value}
          disabled={!lastTimestamp}
          aria-label={AriaLabels.InvestigationMode.LatestSnapshotTab}
        />
      </TabList>
    </TabContext>
  );
};

export default SnapshotTabs;
