import React, { useEffect, useRef, useState } from "react";
import { TooltipWrapper } from "react-tooltip";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { CollapsableContainer } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import { DEFAULT_TOOLTIP } from "../../../../../../shared/constants/tooltipIds";
import { hasTextEllipsis } from "../../../../../../shared/utils/hasTextEllipsis";
import { MAX_COLUMN_WIDTH } from "../constants";

const TypographyWithEllipsis = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TextContainer = styled.div`
  max-width: ${MAX_COLUMN_WIDTH}px;
`;

type DetailProps = {
  keyStr: string;
  value: string;
};

export const Detail: React.FC<DetailProps> = ({ keyStr, value }) => {
  const keyContainerRef = useRef<HTMLDivElement>(null);
  const [showTextEllipsis, setShowTextEllipsis] = useState(false);
  useEffect(() => {
    setShowTextEllipsis(
      hasTextEllipsis(keyContainerRef.current?.children[0] as HTMLElement)
    );
  }, []);

  return (
    <>
      <TooltipWrapper
        tooltipId={DEFAULT_TOOLTIP}
        content={showTextEllipsis ? keyStr : undefined}
      >
        <TextContainer ref={keyContainerRef}>
          <TypographyWithEllipsis color={palette.gray[500]} variant={"title"}>
            {`${keyStr}:`}
          </TypographyWithEllipsis>
        </TextContainer>
      </TooltipWrapper>
      <CollapsableContainer>
        <Typography variant={"text"} size={"small"}>
          {value}
        </Typography>
      </CollapsableContainer>
    </>
  );
};
