import React, { useMemo } from "react";
import styled from "styled-components";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";

import { KubernetesEvent } from "@/generated/reliabilityApi";
import { ColoredTypography } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureStyles";
import {
  getTextColor,
  isWarning,
} from "@/components/common/table/PodEventsTable/podEventsTableUtils";
import { useFormatPodEventTimestamp } from "@/components/common/table/PodEventsTable/podEventsTableHooks";

const Container = styled.div`
  .MuiDataGrid-cell .MuiDataGrid-cellContent {
    white-space: normal;
    font-size: 13px;
  }
`;

const useRelatedEvidenceEventsTableColumns = () => {
  const formatTimestamp = useFormatPodEventTimestamp();

  const getColoredTypography = (event: KubernetesEvent, text: string) => {
    const color = getTextColor(isWarning(event.type, event.reason));
    return (
      <ColoredTypography $color={color} style={{ whiteSpace: "normal" }}>
        {text}
      </ColoredTypography>
    );
  };

  return useMemo<GridColDef<KubernetesEvent>[]>(
    () => [
      {
        field: "reason",
        headerName: "Reason",
        width: 80,
        renderCell: (params) => {
          return getColoredTypography(params.row, params.value);
        },
      },
      {
        field: "time",
        headerName: "Last Timestamp",
        width: 200,
        renderCell: (params) => {
          return (
            <ColoredTypography $color={""}>
              {formatTimestamp({
                firstTimestamp: params.row.firstTimestamp ?? "",
                lastTimestamp: params.row.lastTimestamp ?? "",
              })}
            </ColoredTypography>
          );
        },
      },
      {
        field: "message",
        headerName: "Message",
        flex: 1,
        renderCell: (params) => {
          return getColoredTypography(params.row, params.value);
        },
      },
    ],
    [formatTimestamp]
  );
};

type RelatedEvidenceTableProps = {
  events: KubernetesEvent[];
};

export const RelatedEvidenceTable: React.FC<RelatedEvidenceTableProps> = ({
  events,
}) => {
  const tableColumns = useRelatedEvidenceEventsTableColumns();
  const hasSufficientEvents = events.length > 0;

  if (!hasSufficientEvents) {
    return null;
  }

  return (
    <Container>
      <DataGridPro
        columns={tableColumns}
        rows={events}
        getRowId={(e) => `${e.firstTimestamp}-${e.lastTimestamp}`}
        getRowHeight={() => 72}
        disableColumnResize
        disableVirtualization
        disableColumnMenu
        disableColumnReorder
        hideFooter
        autoHeight
      />
    </Container>
  );
};
