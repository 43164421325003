import { useMemo } from "react";

import { TimelineChartConfig } from "@/components/common/EventsChart/TimelineChart/useTimelineChartConfig";

const X_AXIS_HEIGHT = 48;
const PAGINATION_BAR_HEIGHT = 53;

export const useDynamicChartHeight = (
  numOfSwimlanes: number,
  paginateSwimlanes: boolean,
  hideXAxis: boolean,
  chartConfig: TimelineChartConfig
) => {
  return useMemo(() => {
    let height = numOfSwimlanes * chartConfig.swimlaneHeight;
    if (paginateSwimlanes && numOfSwimlanes > 0) {
      height += PAGINATION_BAR_HEIGHT;
    }
    if (!hideXAxis) {
      height += X_AXIS_HEIGHT;
    }
    return height + "px";
  }, [
    chartConfig.swimlaneHeight,
    hideXAxis,
    numOfSwimlanes,
    paginateSwimlanes,
  ]);
};
