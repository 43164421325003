export const DOCS_LINK = "https://help.komodor.com";

export const KOMODOR_UPDATES_LINK = "https://updates.komodor.com/";

export const ANNOTATIONS_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240380547730-Komodor-Kubernetes-Annotations";
export const CUSTOM_LINKS_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240380547730-Komodor-Kubernetes-Annotations#customing-links";

export const SOURCE_CONTROL_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240780147346-Source-Control-%E2%84%B9";

export const UPGRADE_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16238783128082-Getting-started";

export const CUSTOM_EVENTS_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241379857810-Custom-Events";

export const ACTIONS_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240992013970-Actions";

export const STATIC_PREVENTION_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240933887378-Static-Prevention";

export const ALERT_MANAGER_INTEGRATION_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241723829266-Prometheus-Grafana-Alert-Manager";
export const SENTRY_INTEGRATION_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241281526418-Sentry";
export const PAGER_DUTY_INTEGRATION_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241242237074-PagerDuty";
export const OPSGENIE_INTEGRATION_EVENT_ALERT_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241238124050-Opsgenie-Event-Alert";
export const DATADOG_INTEGRATION_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241138371858-Datadog-Integration";
export const LAUNCH_DARKLY_INTEGRATION_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16241219386898-LaunchDarkly-Integration";

export const MONITORS_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors";
export const AVAILABILITY_MONITOR_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors#availability-monitor";
export const NODE_MONITOR_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors#node-monitor";
export const DEPLOY_MONITOR_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors#deploy-monitor";
export const PVC_MONITOR_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors#pvc-monitor";
export const JOB_MONITOR_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors#job-monitor";
export const CRONJOB_MONITOR_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/16240402516498-Monitors#cronjob-monitor";
export const OPSGENIE_INTEGRATION_SEND_ALERT_DOCS_LINK =
  "https://help.komodor.com/hc/en-us/articles/19031514725650-Integrating-Komodor-to-Send-Alerts-to-OPSGenie";
