import React, { PropsWithChildren } from "react";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import SettingsViewLayout from "@/components/Settings/SettingsViewLayout";

type SettingsViewLayoutWrapperProps = PropsWithChildren<{ title: string }>;

export const SettingsViewLayoutWrapper: React.FC<
  SettingsViewLayoutWrapperProps
> = ({ children, title }) => {
  const { appNavigationChanges } = useOverridableFlags();

  if (appNavigationChanges) {
    return (
      <SettingsViewVerticalLayout title={title}>
        {children}
      </SettingsViewVerticalLayout>
    );
  }

  return <SettingsViewLayout>{children}</SettingsViewLayout>;
};
