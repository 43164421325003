import styled from "styled-components";

const ButtonGroup = styled.div`
  display: flex;
  & > button:not(:first-child) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & > button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export default ButtonGroup;
