import { DEFAULT_PAGINATION_MODEL } from "@/pages/organization-settings/account/AgentsPage/constants";

export function getUniqueValues<T>(array: T[], property: keyof T): T[] {
  return array.filter(
    (value, index, array) =>
      array.findIndex((item) => item[property] === value[property]) === index
  );
}

export function mapToOptions<T>(
  array: T[],
  property: keyof T
): { label: T[keyof T]; value: T[keyof T] }[] {
  return getUniqueValues(array, property).map((agent) => ({
    label: agent[property],
    value: agent[property],
  }));
}

export const getPaginationModel = (
  limit: number | undefined,
  offset: number | undefined
) => {
  if (limit === undefined || offset === undefined) {
    return DEFAULT_PAGINATION_MODEL;
  }
  return {
    pageSize: limit,
    page: Math.floor(offset / limit),
  };
};
