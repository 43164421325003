import styled from "styled-components";

import { blueForUIText, disabledBlue } from "../../../Colors";

export default styled.button`
  padding: 0.5rem 1rem;
  background-color: ${blueForUIText};
  border: 0;
  border-style: solid;
  border-radius: 0.25rem;
  color: white;
  font-weight: 600;

  :focus {
    outline: none;
    border-color: ${blueForUIText};
  }

  :disabled {
    background-color: rgba(0, 122, 255, 0.3);
    color: ${disabledBlue};
  }
`;
