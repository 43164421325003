import styled from "styled-components";

import { ClickableArea } from "../ClustersDemoViewV3/styles";

export const RelativeContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  & > img {
    width: 100%;
  }
`;

export const SelectableDrawerRow = styled(ClickableArea)<{
  height: string;
  top: string;
}>`
  width: 87.4%;
  left: 6.3%;
  height: ${({ height }) => height};
  top: ${({ top }) => top};
`;

export const SelectableRow = styled(ClickableArea)<{
  height: string;
  top: string;
}>`
  width: 96.35%;
  height: ${({ height }) => height};
  left: 1.8%;
  top: ${({ top }) => top};
`;

export const ClickableCard = styled(ClickableArea)<{
  width: string;
  height: string;
  left?: string;
  right?: string;
  top: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
`;

export const CloseButton = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 20px;
  left: 20px;
  border-radius: 4px;
`;

export const AlternateCloseButton = styled(CloseButton)`
  width: 35px;
  height: 35px;
  top: 15px;
  left: 15.5px;
  border-radius: 4px;
`;
