import { useCallback } from "react";

import { defaultSelectedViolationFilters } from "@/components/reliability/constants/violationFiltersConstants";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const useResetAllFilters = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();

  return useCallback(() => {
    setDataInUrl({
      ...dataInUrl,
      filters: defaultSelectedViolationFilters,
    });
  }, [dataInUrl, setDataInUrl]);
};
