import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid4 } from "uuid";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";

import { FormContainer } from "../CustomFormComponents";
import CustomIntegrationInstallation from "../CustomIntegrationInstallation";
import { contactMessage } from "../../../../constants";

import { useGrafanaInstallation } from "./useGrafanaInstallation";
import GrafanaInstallationInstructions from "./GrafanaInstallationInstructions";

const HelpText = styled.div`
  margin-top: 2.5rem;
  color: darkred;
`;

const webhookPath = "https://app.komodor.com/collector/grafana/alerts";

const GrafanaInstallation: React.FC<{ closeModalCallback: () => void }> = ({
  closeModalCallback,
}) => {
  const { handleSubmit } = useForm();
  const installationId = useMemo(() => uuid4(), []);
  const [error, setError] = useState(false);
  const install = useGrafanaInstallation();

  return (
    <CustomIntegrationInstallation name="Grafana">
      <GrafanaInstallationInstructions
        webhookUrl={`${webhookPath}/${installationId}`}
      />
      <FormContainer>
        <Button
          variant="primary"
          onClick={handleSubmit(async () => {
            try {
              await install(installationId, `${webhookPath}/${installationId}`);
            } catch (e) {
              setError(true);
              return;
            }
            closeModalCallback();
          })}
        >
          Install Grafana integration
        </Button>
      </FormContainer>
      {error && (
        <HelpText>
          Error while creating Grafana Integration.
          <br />
          {contactMessage}
        </HelpText>
      )}
    </CustomIntegrationInstallation>
  );
};

export default GrafanaInstallation;
