import React from "react";
import styled from "styled-components";

import { TextBolder } from "../../typography";

import { AriaLabels } from "@/shared/config/ariaLabels";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-inline-end: 0.5rem;
`;

type TypeTagProps = {
  backgroundColor: string;
  fillColor: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  eventType: string;
};

const TypeTag: React.FC<TypeTagProps> = ({
  backgroundColor,
  fillColor,
  icon: Icon,
  eventType,
}) => {
  return (
    <Container
      data-e2e-selector={"typeTag"}
      aria-label={AriaLabels.ProcessList.EventType}
    >
      <IconContainer style={{ backgroundColor }}>
        <Icon width="50%" height="50%" fill={fillColor} />
      </IconContainer>
      <TextBolder>{eventType}</TextBolder>
    </Container>
  );
};

export default TypeTag;
