import React from "react";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import StyledMultiSelect from "../../monitorsView/common/StyledMultiSelect";
import { useStringifiedStateInSearchParams } from "../../../shared/hooks/state";
import { MultiSelectContainerInspection } from "../../Inspection/filters/InspectionResourceListLabelsFilter";

import { ArgoFilterContainer } from "./styles";

export const ArgoTableFilter: React.FC<{
  items: string[];
  filterName: string;
  filterTitle: string;
}> = ({ items, filterName, filterTitle }) => {
  const [selectedFields, setSelectedFields] =
    useStringifiedStateInSearchParams<string[]>(filterName);

  const placeholder = "Select " + filterTitle.toLowerCase();

  return (
    <ArgoFilterContainer>
      <Typography variant={"h6"} color={muiColors.gray[400]}>
        {filterTitle}
      </Typography>
      <MultiSelectContainerInspection>
        <StyledMultiSelect
          placeholder={placeholder}
          width={"100%"}
          options={items}
          values={selectedFields ?? []}
          onChange={(fields) =>
            setSelectedFields(fields.length ? fields : null)
          }
          isEnabled={true}
          ariaLabel={placeholder}
        />
      </MultiSelectContainerInspection>
    </ArgoFilterContainer>
  );
};
