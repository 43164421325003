import React from "react";

import { useStateInSearchParams } from "../../../shared/hooks/state";
import { SNAPSHOT_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { SnapshotTab } from "../availability/common/SnapshotTabs";
import { KubernetesNodesResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";
import { AvailabilityRunWithResults } from "../types";
import Resource from "../../ResourceView/resources";
import { BaseWorkflowCheck } from "../../monitorsView/useWorkflowsRuns";
import { AvailabilityInvestigationCheckType } from "../availability/types";
import { UnhealthyPodsOutput } from "../availability/UnhealthyPods/types";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { getPreferredSnapshot } from "../availability/common/utils/getPreferredSnapshot";

import { EnrichedResource } from "./EnrichedResource";
import { NodeResourceToken } from "./tokens";

export interface EnrichedNodeResourceProps {
  index: number;
  token: NodeResourceToken;
  agentId: string;
  cluster: string;
  onClick?: (resource: Resource) => void;
  issue?: AvailabilityRunWithResults;
  checks?: BaseWorkflowCheck[];
}

export const EnrichedNodeResource: React.FC<EnrichedNodeResourceProps> = ({
  index,
  token,
  agentId,
  cluster,
  onClick,
  issue,
  checks,
}) => {
  const nodeName = useFindNodeName(token, issue, checks);

  return (
    <EnrichedResource
      key={index}
      value={token.getValue()}
      resourceKind={KubernetesNodesResource.Kind}
      resourceName={nodeName ?? ""}
      agentId={agentId}
      cluster={cluster}
      namespace={""}
      onClick={onClick}
    />
  );
};

const useFindNodeName = (
  token: NodeResourceToken,
  issue?: AvailabilityRunWithResults,
  checks?: BaseWorkflowCheck[]
) => {
  const { demoOomKilled } = useOverridableFlags();
  const [selectedSnapshot] = useStateInSearchParams(SNAPSHOT_KEY);

  if (!issue && !checks) {
    return null;
  }

  const preferFirstSnapshot = checks
    ? getPreferredSnapshot(checks, !!demoOomKilled).preferFirstSnapshot
    : true;

  const output = issue
    ? issue?.results.unhealthyPods.output
    : (checks?.find(
        (c) => c.type === AvailabilityInvestigationCheckType.UnhealthyPods
      )?.output as UnhealthyPodsOutput);

  const unhealthyPods =
    selectedSnapshot === SnapshotTab.latestSnapshot || !preferFirstSnapshot
      ? output?.lastSnapshot?.pods
      : output?.firstSnapshot.pods;

  const pod = unhealthyPods?.find(
    (pod) => pod.calculatedMessage === token.getSearchMessage()
  );

  return pod?.nodeName;
};
