import React, { ComponentProps } from "react";
import styled from "styled-components";

import Option from "./Option";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

type OptionProps = ComponentProps<typeof Option>;

type ActionableOptionProps = OptionProps & { actionButton: JSX.Element };

const ActionableOption: React.FC<ActionableOptionProps> = ({
  actionButton,
  ...props
}) => {
  return (
    <Container>
      <Option {...props} />
      {actionButton}
    </Container>
  );
};

export default ActionableOption;
