import React from "react";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeOverCommittedCheck from "../NodeOverCommittedCheck";
import { BoldGrayText } from "../../CheckDetailsDrawer";
import { NormalText } from "../../../../common/styles";
import Recommendations from "../../../../common/Recommendations";

const Content = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 0.8rem 2.5rem;
  justify-content: start;
`;
const OVERCOMMITTED_RECOMMENDATIONS = [
  "Adding more nodes",
  "Replace node with more memory",
  "Review QoS configurations (limits / requests)",
];
const NodeOverCommittedDetails: React.FC<{
  check: NodeOverCommittedCheck;
}> = ({ check }) => {
  const { cpu, memory } = check.output ?? {};

  return check.output && !check.passed ? (
    <>
      <br />
      <Content>
        <BoldGrayText>CPU Limits:</BoldGrayText>
        <BoldGrayText>Memory Limits:</BoldGrayText>
        <NormalText>{cpu?.limits?.text ?? "N/A"}</NormalText>
        <NormalText>{memory?.limits?.text ?? "N/A"}</NormalText>
      </Content>
      <br />
      <Recommendations list={OVERCOMMITTED_RECOMMENDATIONS} />
    </>
  ) : null;
};
export default NodeOverCommittedDetails;
