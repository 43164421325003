import React, { useMemo } from "react";
import styled from "styled-components";

import { useFetchDeployEventQuery } from "../../../../generated/graphql";
import useQueryWithVariables from "../../../../shared/hooks/useQueryWithVariables";
import K8sDiffLink from "../../ProcessList/details/deployEvent/K8sDiffLink";
import getK8sDiff from "../deployEvent/getK8sDiff";
import getSpecsForDiff from "../deployEvent/getSpecsForDiff";
import { PREVIEW_LIMIT } from "../../ProcessList/details/deployEvent/K8sSection";
import DiffEntry from "../../ProcessList/details/deployEvent/DiffEntry";
import { extractServiceName } from "../../../../shared/utils/serviceHelpers";

const Container = styled.div``;

export type DeployDiffProps = {
  eventTimeRangeStart: string;
  clusterName: string;
  namespace: string;
  deploymentName: string;
};

export const DeployDiff: React.FC<DeployDiffProps> = ({
  eventTimeRangeStart,
  clusterName,
  namespace,
  deploymentName,
}) => {
  const variables = useMemo(() => {
    return {
      eventTimeRangeStart: eventTimeRangeStart,
      clusterName: clusterName,
      namespace: namespace,
      deploymentName: deploymentName,
    };
  }, [clusterName, deploymentName, eventTimeRangeStart, namespace]);
  const k8sData = useQueryWithVariables(useFetchDeployEventQuery, variables);
  const eventDeploy = k8sData?.event_deploy[0];
  if (!eventDeploy) {
    return null;
  }

  const oldSpec = eventDeploy.oldSpec;
  const newSpec = eventDeploy.newSpec;
  const specsForDiff =
    oldSpec && newSpec ? getSpecsForDiff(oldSpec, newSpec) : null;
  const k8sDiff = specsForDiff
    ? getK8sDiff(specsForDiff.oldSpec, specsForDiff.newSpec)
    : undefined;

  return (
    <Container>
      {k8sDiff?.changes.length
        ? k8sDiff.changes
            .slice(0, PREVIEW_LIMIT)
            .map((c) => <DiffEntry key={c.name} change={c} />)
        : null}
      <br />
      <K8sDiffLink
        k8sDiff={k8sDiff}
        specsForDiff={specsForDiff}
        versionFrom={eventDeploy.versionFrom}
        versionTo={eventDeploy.versionTo}
        serviceName={extractServiceName(
          (eventDeploy.services as string[])[0] ?? ""
        )}
      />
    </Container>
  );
};
