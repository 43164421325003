import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { Alert } from "@komodorio/design-system/deprecated";

import { LinesLoader } from "../common/loaders/Line";
import LoadingStateCircle from "../common/loaders/LoadingStateCircle";

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  height: 100%;
  min-height: 30rem;
`;

const LoadingMessage = styled(Typography).attrs({ variant: "h3" })`
  font-size: 1.25rem;
  margin-top: 0.75em;
  margin-bottom: 2rem;
`;

const StyledLinesLoader = styled(LinesLoader)`
  margin: 2rem 0 0.5rem;
`;

export const MINIMUM_NUMBER_OF_REQUESTS_TO_SHOW_CIRCLE_LOADING = 3;
export const MINIMUM_NUMBER_OF_REQUESTS_TO_SHOW_CIRCLE_LOADING_SERVICE = 7;

interface EventViewLoadingTypeProps {
  timeWindowsLength: number;
  numTimeWindowsFetched: number;
  showCircleFromNumber: number;
  warning?: string;
}

const EventViewLoadingType: React.FC<EventViewLoadingTypeProps> = ({
  timeWindowsLength,
  numTimeWindowsFetched,
  showCircleFromNumber,
  warning,
}) => {
  const showCircle = timeWindowsLength >= showCircleFromNumber;
  return (
    <Panel data-e2e-selector={"loader"}>
      {showCircle ? (
        <LoadingStateCircle
          numOfActualPaginations={numTimeWindowsFetched}
          numOfExpectedPaginations={timeWindowsLength}
        />
      ) : (
        <StyledLinesLoader />
      )}
      <LoadingMessage>Loading events...</LoadingMessage>
      {warning && <Alert variant={"warning"}>{warning}</Alert>}
    </Panel>
  );
};

export default EventViewLoadingType;
