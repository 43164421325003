import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TooltipWrapper } from "react-tooltip";

import useKomodorServices from "../../../shared/hooks/useKomodorServices";
import Resource from "../../ResourceView/resources";
import {
  COMPARISON_COMPARAND_PARAM_KEY,
  COMPARISON_COMPARATOR_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

const COMPARISON_VIEW_PATH = "/main/comparison";

interface CompareButtonProps {
  resource: Resource;
  Button: React.FC<{ onClick: () => void; disabled?: boolean }>;
  tooltipMessage?: string;
}

export const CompareButton: React.FC<CompareButtonProps> = ({
  resource,
  tooltipMessage,
  Button,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const services = useKomodorServices().all;

  const shouldDisableCompareButton = useMemo(() => {
    const comparableServicesNumber = services
      ?.filter((service) => service.id !== resource?.id)
      .filter((service) => service.title === resource?.name).length;

    return comparableServicesNumber === 0;
  }, [services, resource]);

  const comparatorService = useMemo(
    () => services?.find((service) => service.id === resource?.id),
    [services, resource?.id]
  );
  const comparandService = useMemo(
    () =>
      services
        ?.filter((service) => service.id !== comparatorService?.id)
        .find((service) => service.title === comparatorService?.title),
    [services, comparatorService]
  );

  const searchParams = new URLSearchParams({
    [COMPARISON_COMPARATOR_PARAM_KEY]: resource?.id ?? "",
    [COMPARISON_COMPARAND_PARAM_KEY]: comparandService?.id ?? "",
  });

  return (
    <TooltipWrapper
      tooltipId={DEFAULT_TOOLTIP}
      content={tooltipMessage}
      place="bottom"
    >
      <Button
        disabled={shouldDisableCompareButton}
        onClick={() => {
          navigate(
            {
              pathname: COMPARISON_VIEW_PATH,
              search: searchParams.toString(),
            },
            {
              state: {
                prevPath: location.pathname,
              },
            }
          );
        }}
      />
    </TooltipWrapper>
  );
};
