import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import styled from "styled-components";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

import { EXPERT_OVERVIEW_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "../../shared/hooks/state";

import { ServicesTab } from "./tabs/ServicesTab";
import { ApplicationsTab } from "./tabs/ApplicationsTab";

export type ExpertOverviewTab = "services" | "applications";

type TabLabel = "Services" | "Applications";

const tabLabels: Record<string, { label: TabLabel; value: ExpertOverviewTab }> =
  {
    services: { label: "Services", value: "services" },
    applications: { label: "Applications", value: "applications" },
  };

const StyledTabPanel = styled(TabPanel)`
  && {
    padding: 16px 0;
  }
`;

const StyledTabList = styled(TabList)`
  && {
    padding: 1rem 1rem 0 0;
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
`;

const defaultTab: ExpertOverviewTab = "services";

export const ExpertOverviewTabs: React.FC = () => {
  const [tab, setTab] = useStateInSearchParams(EXPERT_OVERVIEW_PARAM_KEY);

  const { services, applications } = tabLabels;

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    newValue && setTab(newValue as ExpertOverviewTab);
  };

  useEffect(() => {
    !tab && setTab(tab);
  }, [setTab, tab]);

  return (
    <TabContext value={tab ?? defaultTab}>
      <StyledTabList onChange={onTabChange}>
        <Tab label={`${services.label} (8,847)`} value={services.value} />
        <Tab label={`${applications.label} (182)`} value={applications.value} />
      </StyledTabList>
      <StyledTabPanel value="services">
        <ServicesTab />
      </StyledTabPanel>
      <StyledTabPanel value="applications">
        <ApplicationsTab />
      </StyledTabPanel>
    </TabContext>
  );
};
