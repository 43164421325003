export enum DeployStatusEnum {
  Failed = "failed",
  Completed = "completed",
  InProgress = "in progress",
  Started = "started",
}

type DeployStatus =
  | "started"
  | "in progress"
  | "completed"
  | "failed"
  | "unknown";

const dbStatusToStatus = new Map<string | undefined, DeployStatus>([
  ["Successful", "completed"],
  ["Failure", "failed"],
  ["In Progress", "in progress"],
  ["Started", "started"],
  ["", "started"],
]);

export const getDeployStatus = (
  statusFromDB: string | undefined
): DeployStatus => dbStatusToStatus.get(statusFromDB) ?? "unknown";

export default getDeployStatus;
