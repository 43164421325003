import {
  ViolationCountBy,
  ViolationFilterOperator,
} from "../../../../generated/reliabilityApi";

export const violationFilterCategoryToTitle: Partial<
  Record<ViolationCountBy, string>
> = {
  clusterName: "Cluster",
  namespace: "Namespace",
  resourceType: "Resource Type",
  shortResourceName: "Service",
  status: "Status",
  checkType: "Check Type",
  severity: "Severity",
};

export const MAX_OPTIONS_PER_GROUP = 8;

export const MAX_RESULTS_PER_FILTER = 30;

export const SERVER_REQUEST_DEBOUNCE_DELAY = 250;

export const FILTERS_SECTION_WIDTH = 240;

export const SEARCH_TYPE_FILTER_OPERATOR = ViolationFilterOperator.Like;

export const INCLUDE_TYPE_FILTER_OPERATOR = ViolationFilterOperator.Include;

export const GREATER_THAN_FILTER_OPERATOR = ViolationFilterOperator.GreaterThan;

export const LESS_THAN_FILTER_OPERATOR = ViolationFilterOperator.LessThan;
