import React from "react";
import styled from "styled-components";

import { H4Light } from "./typography";

const H4Lighter = styled(H4Light)`
  color: #6e7a96;
  margin-top: 0.5rem;
`;

const LinkToDocs: React.FC<{ docsUrl: string }> = ({ docsUrl }) => {
  return (
    <H4Lighter>
      Not sure how to do this?{" "}
      <a rel="noreferrer" target="_blank" href={docsUrl}>
        Link to documentation
      </a>
    </H4Lighter>
  );
};

export default LinkToDocs;
