import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import komodorLogo from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/assets/komdorLogo.svg";

const Container = styled.div`
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: ${muiColors.indigo[25]};
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
`;

type SummaryTitleProps = { title: string | undefined };

export const SummaryTitle: React.FC<SummaryTitleProps> = ({ title }) => {
  if (!title) {
    return null;
  }

  return (
    <Container>
      <img src={komodorLogo} alt={""} />
      <Typography variant={"body2"}>{title}</Typography>
    </Container>
  );
};
