import { Node, Edge } from "react-flow-renderer";

import { StateInSearchParamsType } from "../../../../shared/hooks/state";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { AllChecksTypes, BaseWorkflowCheck } from "../../useWorkflowsRuns";
import {
  edgeDefaultProperties,
  NodeDataType,
  nodeDefaultProperties,
} from "../Diagram";

import { CheckIconType } from "./CheckCard";

export default abstract class WorkflowCheck {
  icon: CheckIconType;
  type: AllChecksTypes;
  title = "";
  shortOutput: string | JSX.Element = "";
  description: string | JSX.Element = "";
  action: string | JSX.Element = "";
  question = "";
  passed: boolean;
  isFinding = false;
  output: unknown;

  constructor(check: BaseWorkflowCheck) {
    this.icon = (check.passed ? "success" : "fail") as CheckIconType;
    this.type = check.type;
    this.shortOutput = check.shortOutput;
    this.passed = check.passed;
  }

  getDiagramElements(
    yPosition: number,
    onResultClick: () => void
  ): [Node<NodeDataType>[], Edge[]] {
    const checkNodeId = this.type + "-check-node";
    const resultNodeId = this.type + "-result-node";
    return [
      [
        {
          id: checkNodeId,
          type: "customDefault",
          data: { label: this.question, icon: "question" },
          position: { x: 450, y: yPosition },
          ...nodeDefaultProperties,
        },
        {
          id: resultNodeId,
          type: "customOutput",
          draggable: false,
          data: {
            label: this.shortOutput,
            icon: this.icon,
            onClick: onResultClick,
          },
          position: { x: 750, y: yPosition },
        },
      ],
      [
        {
          id: this.type,
          source: checkNodeId,
          target: resultNodeId,
          ...edgeDefaultProperties,
        },
        {
          id: "trigger-" + this.type,
          source: "trigger-node-id",
          target: checkNodeId,
          ...edgeDefaultProperties,
        },
      ],
    ];
  }

  abstract renderCheckDetails(
    setSelectedCheckType: StateInSearchParamsType[1]
  ): JSX.Element;
  renderCheckEventDetails(
    setSelectedCheckType: StateInSearchParamsType[1]
  ): JSX.Element | null {
    return null;
  }
}
