import styled from "styled-components";

export const RelativeContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  & > img {
    width: 100%;
    user-select: none;
  }
`;

export const ClickableArea = styled.div<{ noHover?: boolean }>`
  position: absolute;
  cursor: pointer;
  ${({ noHover }) =>
    noHover
      ? ""
      : `&:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }`}
`;

export const CloseButtonArea = styled(ClickableArea)`
  width: 24px;
  height: 24px;
  top: 35px;
  left: 24px;
  border-radius: 4px;
`;
