import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { ViolationTableWithContext } from "../ViolationTableWithContext/ViolationTableWithContext";
import { AnimatedKeyboardArrowDown } from "../../../../../ReliabilityStyles";
import { ExpandableContentContainer } from "../../../../shared/ExpandableContentContainer";
import { reliabilityArialLabels } from "../../../../../reliabilityArialLabels";
import {
  ImpactGroupIdentifier,
  ViolationsAggregationGroup,
} from "../../../../../../../generated/reliabilityApi";
import { GroupByOptions } from "../../../../../ReliabilityTypes";
import { Severities } from "../Severities";
import { ImpactAggregations } from "../ImpactAggregations/ImpactAggregations";
import { ViolationsTableInternalState } from "../ViolationTableWithContext/context/ViolationsTableContext";
import { DependentViolations } from "../../DependentViolations";

import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";
import { Dictionary } from "@/shared/types/Dictionary";

const Container = styled(Card)`
  cursor: pointer;
`;

const InteractiveSectionContainer = styled.div`
  cursor: pointer;
  padding: 8px 0 8px 0;
`;

const PaddedDiv = styled.div<{ $paddingBottom: boolean }>`
  padding: 0 16px ${({ $paddingBottom }) => ($paddingBottom ? 24 : 0)}px 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledViolationTable = styled(ViolationTableWithContext)`
  margin-top: 16px;
`;

const AnimatedArrow = styled(AnimatedKeyboardArrowDown)`
  && {
    width: 20px;
    height: 20px;
    padding: 6px;
    margin-right: 8px;
  }
`;

const {
  groups: {
    singleGroup: singleGroupArialLabel,
    Title: groupTitleArialLabel,
    Explanation: groupExplanationArialLabel,
    expandHitArea: expandHitAreaArialLabel,
  },
} = reliabilityArialLabels;

type Props = ViolationsAggregationGroup & {
  isOpen: boolean;
  title: string;
  explanation?: string;
  onExpandClick: () => void;
  groupBy: GroupByOptions;
};

export const GroupCard: React.FC<Props> = ({
  breakdowns,
  impactDataAggregations,
  id,
  title,
  explanation,
  isOpen,
  onExpandClick,
  groupBy,
}) => {
  const isBestPracticeTab = useIsBestPracticesTab();
  const [isHovering, setIsHovering] = useState(false);
  const [countDict, setCountDict] = useState<Dictionary<number> | undefined>(
    isBestPracticeTab
      ? breakdowns?.["hasDependentViolations"]
      : breakdowns?.["severity"]
  );

  const ariaLabel = `${singleGroupArialLabel} ${id} ${isOpen ? "open" : ""}`;

  const onViolationsTableStateChange = useCallback(
    ({ count }: ViolationsTableInternalState) => {
      if (count) {
        setCountDict(count);
      }
    },
    []
  );

  const showImpactAggregations = !!impactDataAggregations && !isBestPracticeTab;

  return (
    <Container elevation={isHovering ? 8 : 1} aria-label={ariaLabel}>
      <InteractiveSectionContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onExpandClick}
        aria-label={expandHitAreaArialLabel}
      >
        <PaddedDiv $paddingBottom={showImpactAggregations}>
          <HeaderContainer>
            <AnimatedArrow open={isOpen} color="action" />
            <Typography variant={"h4"} aria-label={groupTitleArialLabel}>
              {title}
            </Typography>
            {isBestPracticeTab ? (
              <DependentViolations dependecies={countDict} />
            ) : (
              <Severities severities={countDict} />
            )}
          </HeaderContainer>
          <Typography variant={"body2"} aria-label={groupExplanationArialLabel}>
            {explanation}
          </Typography>
          {showImpactAggregations && (
            <ImpactAggregations
              impactDataAggregations={impactDataAggregations}
              groupIdentifier={id}
            />
          )}
        </PaddedDiv>
      </InteractiveSectionContainer>
      <ExpandableContentContainer
        isExpanded={isOpen}
        autoDetectContentHeight={false}
      >
        <PaddedDiv $paddingBottom>
          <StyledViolationTable
            uid={id}
            violationsGroupBy={groupBy}
            impactGroupIdentifier={id as ImpactGroupIdentifier}
            onInternalStateChange={onViolationsTableStateChange}
          />
        </PaddedDiv>
      </ExpandableContentContainer>
    </Container>
  );
};
