import React from "react";
import { Nodes16 } from "@komodorio/design-system/icons";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

import { StatusCondition } from "../../../EventGroup/groupedPodEvent/types";
import { WORKFLOW_CONFIG_TYPES } from "../../../EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../../../monitorsView/common/types";
import EventGroup from "../../../EventGroup";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

export const getMessageFromConditions = (
  conditions: StatusCondition[] | undefined
) => {
  if (!conditions || conditions.length === 0) {
    return "";
  }
  const errorCondition = conditions.find(
    (condition) => condition.status === "False"
  );

  const goodCondition = conditions.find(
    (condition) => condition.status === "True"
  );

  return errorCondition
    ? errorCondition.message
    : goodCondition
    ? goodCondition.message
    : "";
};

export const getNodeIssueDetails = (
  group: EventGroup
): {
  text: React.ReactNode;
  icon: React.ReactNode;
  bgColor: string | undefined;
  ariaLabel: string;
} => {
  const isNodeIssue =
    group.type === WORKFLOW_CONFIG_TYPES[WorkflowConfigType.NodeIssue];

  if (isNodeIssue) {
    return {
      text: group.isCompleted ? (
        <Typography variant={"body2"}>
          This pod may have been affected by a <strong>node issue</strong>
        </Typography>
      ) : (
        <Typography variant={"body2"}>
          There is an ongoing <strong>node issue</strong> that may be affecting
          this pod
        </Typography>
      ),
      icon: <Nodes16 fill={muiColors.orange[700]} />,
      bgColor: group.isCompleted ? muiColors.orange[50] : muiColors.pink[50],
      ariaLabel: AriaLabels.PodPhases.NodeIssueBanner,
    };
  }
  return {
    text: (
      <Typography variant={"body2"}>
        This pod was affected by a <strong>node termination</strong> event
      </Typography>
    ),
    icon: (
      <WarningAmberOutlined
        sx={{
          color: muiColors.pink[700],
          fontSize: "16px",
        }}
      />
    ),
    bgColor: muiColors.pink[50],
    ariaLabel: AriaLabels.PodPhases.NodeTerminationBanner,
  };
};

const getLabelValue = (labels: Record<string, string>, key: string) => {
  const isKeyExist = Object.keys(labels).find((k) => k === key);
  return isKeyExist ? labels[isKeyExist] : undefined;
};
interface WorkflowInfo {
  dagId: string | undefined;
  taskId: string | undefined;
  runId: string | undefined;
  tryNumber: string | undefined;
}
export const getWorkflowInfoFromLabels = (
  labels: Record<string, string>
): WorkflowInfo => {
  return {
    dagId:
      getLabelValue(labels, "dag_id") ||
      getLabelValue(labels, "app.komodor.com/WorkflowDagId"),
    runId:
      getLabelValue(labels, "run_id") ||
      getLabelValue(labels, "app.komodor.com/WorkflowRunId"),
    taskId:
      getLabelValue(labels, "task_id") ||
      getLabelValue(labels, "app.komodor.com/WorkflowTaskId"),
    tryNumber: getLabelValue(labels, "try_number"),
  };
};
