import { notifyDDError } from "../../../../shared/hooks/exceptionManagement";
import { retry } from "../../../../shared/utils/retry";
import { getTerminalInstance } from "../../terminalInstance";

export const handleReconnect = async (
  connect: () => Promise<void>,
  id: string
): Promise<void> => {
  const terminal = getTerminalInstance(id);

  try {
    await retry<void>({
      func: connect,
      retries: 10,
      exponentialBackoff: true,
    });

    if (terminal) {
      terminal.options.disableStdin = false;
      terminal.options.cursorBlink = true;
    }
  } catch (error) {
    terminal?.writeln("Reconnection attempts limit reached.");
    notifyDDError({
      name: "pod exec - failed to reconnect",
      message: (error as Error).message,
    });
  }
};
