import { useCallback, useEffect, useMemo, useState } from "react";

import Selected from "../../../../shared/components/FilterBar/Interfaces/Selected";
import {
  useDeleteFilterMutation,
  useInsertFilterMutation,
  useSavedFiltersQuery,
} from "../../../../generated/graphql";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { notifyDDError } from "../../../../shared/hooks/exceptionManagement";

import SavedFilter from "./SavedFilter";

const useSavedFilters = (): {
  savedFilters: SavedFilter[];
  addSavedFilter: (name: string, filter: Selected) => Promise<void>;
  deleteSavedFilter: (id: string) => void;
} => {
  const [deleted, setDeleted] = useState([] as string[]);

  const [, insertFilter] = useInsertFilterMutation();
  const addSavedFilter = useCallback(
    async (filterName: string, filterData: Selected) => {
      await insertFilter({
        filterName,
        filterData,
        filterType: "account",
      });
      dispatchEvent(AnalyticEvents.EventsView.SavedFilter_Add, {
        filterName,
        filterData,
      });
    },
    [insertFilter]
  );

  const [, deleteFilter] = useDeleteFilterMutation();
  const deleteSavedFilter = useCallback(
    (filterId: string) => {
      deleteFilter({ filterId });
      setDeleted((prev) => [...prev, filterId]);
      dispatchEvent(AnalyticEvents.EventsView.SavedFilter_Delete, { filterId });
    },
    [deleteFilter]
  );

  const [{ data, error }] = useSavedFiltersQuery();
  useEffect(() => {
    if (error) {
      notifyDDError(error as Error);
    }
  }, [error]);

  const savedFilters = useMemo(() => {
    const fetched = (data?.komodor_filter_settings ?? []).map((filter) => ({
      id: filter.id,
      name: filter.filterName,
      selected: filter.filterData as Selected,
      filterType: filter.filterSettingType,
    }));
    return fetched.filter((f) => !deleted.includes(f.id));
  }, [data?.komodor_filter_settings, deleted]);

  return { savedFilters, addSavedFilter, deleteSavedFilter };
};

export default useSavedFilters;
