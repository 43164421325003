import { ColorScheme } from "@/components/k8sAddons/constants/colorScheme";
import { ONE_DAY_IN_MS } from "@/shared/constants/time";
import { INVALID_TIME } from "@/components/k8sAddons/constants/time";

export const getTypographySchemeByTimeElapsed = (timeElapsedInMs: number) => {
  const daysElapsed = timeElapsedInMs / ONE_DAY_IN_MS;
  if (timeElapsedInMs === INVALID_TIME) {
    return { color: ColorScheme["info"], fontWeight: 400 };
  }
  if (daysElapsed > 14) {
    return { color: ColorScheme["info"] };
  }
  if (daysElapsed > 10) {
    return { color: ColorScheme["warning"], fontWeight: 700 };
  }
  return { color: ColorScheme["error"], fontWeight: 700 };
};
