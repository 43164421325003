import { DEMO_ACCOUNT_ID } from "@/constants";

export const clustersDemoRoutes = {
  clusters: "clusters",
  workflows: "workflows",
  clusterOverview: "production",
  wfLaneExtractorRegression: "lane-extractor-regression",
  wfIcpMapGenerator: "icp-map-generator",
  policies: "policies",
  eventsPullerService: `/services/demo.production-demo.events-puller-service?accountId=${DEMO_ACCOUNT_ID}&workspaceId=null`,
  atm: `/main/resources/workloads/pods/production?accountId=${DEMO_ACCOUNT_ID}&workspaceId=null`,
  cost: `/cost-optimization?accountId=${DEMO_ACCOUNT_ID}&costOptimizationQS=%7B%22tab%22%3A%22rightSizing%22%7D`,
};
