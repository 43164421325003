import { useMemo } from "react";

import { useFindInstalledAgentSubscription } from "../../../../../generated/graphql";
import useSubscriptionWithVariables from "../../../../../shared/hooks/useSubscriptionWithVariables";

const useIsAgentInstalled = (apiKey: string | null): boolean => {
  const variables = useMemo(() => (apiKey ? { apiKey } : null), [apiKey]);
  const data = useSubscriptionWithVariables(
    useFindInstalledAgentSubscription,
    variables
  );

  if (!data) return false;
  if (data.kubernetes_agent_info.length === 1) return true;

  return false;
};

export default useIsAgentInstalled;
