import React from "react";
import { capitalize, get } from "lodash";

import { Audit } from "../../../generated/graphql";
import { AuditData } from "../types";
import { DetailsGrid } from "../../common/EventGroup/actionsEvent/common";
import { ManualActionCommon } from "../AuditDetailsCommon";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AuditObject from "./AuditObject";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const ManualActionCompleted: React.FC<{ data: AuditData }> = ({ data }) => {
  const resourceDetails = [];
  if (data.details.data?.new?.metadata?.type) {
    resourceDetails.push({
      title: "action",
      data: data.details.data.new.metadata.type,
    });
  }
  if (data.details.data?.new?.metadata?.cluster) {
    resourceDetails.push({
      title: "cluster",
      data: data.details.data.new.metadata.cluster,
    });
  }
  if (data.details.data?.new?.metadata?.namespace) {
    resourceDetails.push({
      title: "namespace",
      data: data.details.data.new.metadata.namespace,
    });
  }
  if (data.details.data?.new?.metadata?.resourceType) {
    resourceDetails.push({
      title: "resource type",
      data: data.details.data.new.metadata.resourceType,
    });
  }
  if (data.details.data?.new?.metadata?.resourceName) {
    resourceDetails.push({
      title: "resource name",
      data: data.details.data.new.metadata.resourceName,
    });
  }
  if (data.details?.data?.new?.result) {
    resourceDetails.push({
      title: "result",
      data: data.details.data.new.result,
    });
  }
  return (
    <DetailsGrid>
      {resourceDetails.map(
        (e, i) =>
          e && (
            <React.Fragment key={i}>
              <div>{e.title}</div>
              <div>{e.data}</div>
            </React.Fragment>
          )
      )}
    </DetailsGrid>
  );
};

export default class ManualActionAudit implements AuditObject {
  readonly data: Audit;
  readonly auditData: AuditData;

  constructor(data: Audit) {
    this.data = data;
    this.auditData = data as AuditData;
  }

  renderDetails() {
    if (this.auditData?.details?.resource) {
      return <ManualActionCommon data={this.auditData} />;
    }
    return <ManualActionCompleted data={this.auditData} />;
  }

  getAffectedResource(): string {
    if (this.auditData?.details?.data?.new?.metadata) {
      const metadata = this.auditData.details.data.new.metadata as Record<
        string,
        unknown
      >;
      if (metadata?.serviceId) {
        return metadata?.serviceId as string;
      }
      return metadata.cluster
        ? metadata?.namespace
          ? `${metadata.cluster}/${metadata.namespace}/${
              metadata?.resourceName || metadata?.chartName
            }`
          : `${metadata.cluster}/${
              metadata?.resourceName || metadata?.chartName
            }`
        : `${metadata?.resourceName || metadata?.repoName}`;
    }
    if (this.auditData?.details?.resource) {
      const metadata = this.auditData?.details?.resource;
      return metadata?.serviceId
        ? metadata.serviceId
        : `${metadata?.cluster}/${metadata?.resourceName}`;
    }
    return "";
  }

  renderAffectedResources() {
    return this.getAffectedResource();
  }

  renderAction() {
    const type = (
      this.auditData?.details?.data?.new?.metadata as Record<string, string>
    )?.type;
    if (type) {
      return type;
    }
    if (this.auditData?.details?.action) {
      const [actionPrefix, actionName] = this.auditData?.details?.action.split(
        ":"
      ) || [""];
      if (!!actionPrefix && !!actionName) {
        return `${actionPrefix}${capitalize(actionName)}`;
      }
    }
    return "";
  }

  renderEventType() {
    return "Update";
  }

  renderJson(): Record<string, unknown> {
    const change = this.auditData.details?.data?.new
      ? {
          command: get(this.auditData.details.data.new, "action.command"),
          args: get(this.auditData.details.data.new, "action.args"),
          result: get(this.auditData.details.data.new, "result"),
        }
      : this.auditData.details;

    return {
      Timestamp: this.data.eventTimestamp,
      "Event type": capitalize(this.data.crudType.toLowerCase()),
      Resource: "Manual Action",
      User: this.data.user?.email,
      Status: capitalize(this.data.status),
      "Affected Resource": this.getAffectedResource(),
      Change: JSON.stringify([change]),
    };
  }
}
