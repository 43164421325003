import React from "react";
import Box from "@mui/material/Box";

import { Addon, Entity } from "@/generated/addonsApi";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { PageContainer } from "@/components/k8sAddons/styles";
import { DagsTable } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsTable";
import { Filters } from "@/components/k8sAddons/addons/workflows/DagsListPage/Filters";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import {
  WORKFLOW_DAGS_DEFAULT_SORT_BY_DIRECTION,
  WORKFLOW_DAGS_DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/workflows/DagsListPage/constants";

export const DagsListPage: React.FC = () => {
  return (
    <AddonContextProvider
      addonType={Addon.Workflow}
      addonEntity={Entity.WorkflowDag}
      defaultSortModel={{
        field: WORKFLOW_DAGS_DEFAULT_SORT_BY_FIELD,
        sort: WORKFLOW_DAGS_DEFAULT_SORT_BY_DIRECTION,
      }}
    >
      <ResponsiveLayout>
        <Box sx={{ paddingBlock: "24px" }}>
          <PageContainer>
            <Filters />
            <DagsTable />
          </PageContainer>
        </Box>
      </ResponsiveLayout>
    </AddonContextProvider>
  );
};
