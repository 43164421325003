import React, { useMemo } from "react";

import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { CommonEventGroup } from "../../../EventGroup/commonEventGroup/commonEvents";
import { TextBolder, Text } from "../../../typography";
import { EventDetailsContainer } from "../common";
import EventDetailsSection from "../components/EventDetailsSection";
import detailsIcon from "../../assets/details.svg";
import EventDetailsTitle from "../components/EventDetailsTitle";
import {
  ingressType,
  limitRangeType,
  networkPolicyType,
  resourcesQuotaType,
} from "../../../EventGroup/commonEventGroup/types";
import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import {
  CONFIGURATION_LIMIT_RANGES_EVENT_ID,
  CONFIGURATION_RESOURCE_QUOTAS_EVENT_ID,
  NETWORK_INGRESS_EVENT_ID,
  NETWORK_NETWORK_POLICIES_EVENT_ID,
} from "../../../../../shared/hooks/resources-api/requestResponseMaps";

import DiffSection from "./DiffSection";

const CommonEventDetails: React.FC<{ eventGroup: CommonEventGroup }> = ({
  eventGroup,
}) => {
  const { format } = useDateFormatter();
  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={eventGroup.summary} />
      <EventDetailsSection
        icon={detailsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Details"
      >
        <TextBolder>Change time</TextBolder>
        <br />
        <Text>{format(eventGroup.endTime)}</Text>
      </EventDetailsSection>
      <ProperResourceDiff eventGroup={eventGroup} />
    </EventDetailsContainer>
  );
};

const ProperResourceDiff: React.FC<{ eventGroup: CommonEventGroup }> = ({
  eventGroup,
}) => {
  const variables = useMemo(
    () => ({
      id: eventGroup.events[0].id,
    }),
    [eventGroup.events]
  );
  switch (eventGroup.type) {
    case ingressType:
      return <IngressDiff variables={variables} summary={eventGroup.summary} />;
    case limitRangeType:
      return (
        <LimitRangeDiff variables={variables} summary={eventGroup.summary} />
      );
    case networkPolicyType:
      return (
        <NetworkPolicyDiff variables={variables} summary={eventGroup.summary} />
      );
    case resourcesQuotaType:
      return (
        <ResourceQuotaDiff variables={variables} summary={eventGroup.summary} />
      );
    default:
      return null;
  }
};

interface ResourceDiffProps {
  variables: { id: string };
  summary: string;
}
const IngressDiff: React.FC<ResourceDiffProps> = ({ variables, summary }) => {
  const { data } = useResourcesAPIGet(NETWORK_INGRESS_EVENT_ID, {
    id: variables.id,
  });
  if (!data?.data) return null;

  const oldSpec = data.data?.[0].oldSpec as Record<string, string>;
  const newSpec = data.data?.[0].newSpec as Record<string, string>;

  return <DiffSection oldSpec={oldSpec} newSpec={newSpec} summary={summary} />;
};
const LimitRangeDiff: React.FC<ResourceDiffProps> = ({
  variables,
  summary,
}) => {
  const { data } = useResourcesAPIGet(CONFIGURATION_LIMIT_RANGES_EVENT_ID, {
    id: variables.id,
  });
  if (!data?.data) return null;

  const oldSpec = data.data?.[0].oldSpec as Record<string, string>;
  const newSpec = data.data?.[0].newSpec as Record<string, string>;

  return <DiffSection oldSpec={oldSpec} newSpec={newSpec} summary={summary} />;
};
const NetworkPolicyDiff: React.FC<ResourceDiffProps> = ({
  variables,
  summary,
}) => {
  const { data } = useResourcesAPIGet(NETWORK_NETWORK_POLICIES_EVENT_ID, {
    id: variables.id,
  });
  if (!data?.data) return null;

  const oldSpec = data.data?.[0] as Record<string, string>;
  const newSpec = data.data?.[0] as Record<string, string>;

  return <DiffSection oldSpec={oldSpec} newSpec={newSpec} summary={summary} />;
};
const ResourceQuotaDiff: React.FC<ResourceDiffProps> = ({
  variables,
  summary,
}) => {
  const { data } = useResourcesAPIGet(CONFIGURATION_RESOURCE_QUOTAS_EVENT_ID, {
    id: variables.id,
    fields: ["id", "newSpec", "oldSpec"],
  });
  if (!data?.data) return null;
  const oldSpec = data?.data[0].oldSpec as Record<string, string>;
  const newSpec = data?.data[0].newSpec as Record<string, string>;

  return <DiffSection oldSpec={oldSpec} newSpec={newSpec} summary={summary} />;
};

export default CommonEventDetails;
