import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { greenForUIText, pinkBrand } from "../../../../../Colors";

const KeyValueGrid = styled.div<{ rows: number }>`
  display: grid;
  gap: 1rem 4rem;
  grid-template-rows: repeat(${({ rows }) => rows}, auto);
  grid-auto-flow: column;
  justify-content: start;
`;

const Value = styled.div`
  &.positive {
    text-transform: capitalize;
    color: ${greenForUIText};
  }
  &.negative {
    text-transform: capitalize;
    color: ${pinkBrand};
  }
  &.positive::before,
  &.negative::before {
    line-height: 0;
    content: "•";
    font-size: 2.25em;
    vertical-align: middle;
    margin-inline-end: 0.1rem;
  }
`;

const positiveValues = new Set([
  "normal",
  "success",
  "sufficient",
  "running",
  "completed",
]);
const negativeValues = new Set(["error", "low", "unhealthy"]);
const getClassName = (value: string) => {
  const lowerCaseValue = value.toLowerCase();
  if (positiveValues.has(lowerCaseValue)) {
    return "positive";
  }
  if (negativeValues.has(lowerCaseValue)) {
    return "negative";
  }
  return "";
};

const KeyValue = ({ label, value }: { label: string; value: string }) => {
  const className = useMemo(() => getClassName(value), [value]);
  return (
    <div>
      <Typography variant="body3" color={muiColors.gray[600]}>
        {label}
      </Typography>
      <Typography variant="h6">
        <Value className={className}>{value}</Value>
      </Typography>
    </div>
  );
};

type Details = { label: string; value: string | undefined }[];
const DetailsList: React.FC<{ details: Details; rows?: number }> = ({
  details,
  rows = 2,
}) => (
  <KeyValueGrid rows={rows}>
    {details
      .filter((d) => d.value)
      .map((d) => (
        <KeyValue key={d.label} label={d.label} value={d.value ?? ""} />
      ))}
  </KeyValueGrid>
);

export default DetailsList;
