import React from "react";
import { palette } from "@komodorio/design-system";
import styled from "styled-components";

import TypeTag from "../../common/ProcessList/TypeTag";
import ConfigChangeScatterShape from "../../common/EventGroup/shapes/config.svg?react";
import EventWorkloadShape from "../../monitorsView/assets/eventWorkload.svg?react";
import TextButton from "../../common/TextButton";
import Status from "../../common/ProcessList/Status";
import {
  TextCell,
  MaxWidthText,
  TimeCell,
} from "../../common/ProcessList/ListItem";

const variants = [
  {
    backgroundColor: palette.pink[100],
    fillColor: palette.pink[500],
    icon: ConfigChangeScatterShape,
    eventType: "HPA change",
    status: "Closed",
    summary:
      "validate.agent.test/k8s-watcher-installation-test/k8s-watcher-installation-test is unhealthy",
  },
  {
    backgroundColor: palette.blue[100],
    fillColor: palette.blue[500],
    icon: EventWorkloadShape,
    eventType: "Availability issue",
    status: "Open",
    summary:
      "validate.agent.test/k8s-watcher-installation-test/k8s-watcher-installation-test is unhealthy",
  },
];

const StyledBlurredDataRow = styled.tr`
  background-color: white;
  filter: blur(3px);
  > td {
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: transparent;
    padding: 0.5rem;
  }
  > td:first-child {
    border-left-width: 1px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  > td:last-child {
    border-right-width: 1px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

const FakeEventRow: React.FC<{ variant: number }> = ({ variant }) => {
  return (
    variants[variant] && (
      <>
        <StyledBlurredDataRow>
          <td>
            <TypeTag
              backgroundColor={variants[variant]?.backgroundColor}
              fillColor={variants[variant]?.fillColor}
              icon={variants[variant]?.icon}
              eventType={variants[variant]?.eventType}
            />
          </td>
          <TextCell>
            <MaxWidthText maxWidth="16rem">
              {variants[variant]?.summary}
            </MaxWidthText>
          </TextCell>
          <TimeCell>{"Jan 2, 2023, 6:27:37 PM"}</TimeCell>
          <TimeCell>{"Jan 3, 2023, 6:27:37 PM"}</TimeCell>
          <TextCell>
            <MaxWidthText maxWidth="20rem">{"Some Fake details"}</MaxWidthText>
          </TextCell>
          <td>
            <TextButton>View Pods</TextButton>
          </td>
          <td>
            <Status status={variants[variant]?.status} />
          </td>
          <td></td>
        </StyledBlurredDataRow>
      </>
    )
  );
};

export default FakeEventRow;
