import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@komodorio/design-system/deprecated";
import { TooltipWrapper } from "react-tooltip";

import usePrivilegedOptions from "../../../../../../../shared/hooks/roles/usePrivilegedOptions";
import {
  External_Link_Context_Enum,
  useAddExternalLinkMutation,
} from "../../../../../../../generated/graphql";
import { blueBrand } from "../../../../../../../Colors";
import {
  FormContainer,
  SmallExternalLink,
  ButtonContainer,
} from "../LinkModal.styles";
import {
  UpsertLinkErrorType,
  FormValues,
  ModalErrorMessage,
  LinkModal,
} from "../utils";
import { NameInput, LinkInput } from "../inputs";
import { isSandbox } from "../../../../../../../shared/utils/sandbox";
import { DEFAULT_TOOLTIP } from "../../../../../../../shared/constants/tooltipIds";
import { ANNOTATIONS_DOCS_LINK } from "../../../../../../../shared/constants/docsLinks";

import ClusterNamespaceCheckbox from "./ClusterNamespaceCheckbox";
import addLinkSubmitHandler from "./addLinkSubmitHandler";

const AddLinkForm: React.FC<{
  handleClose: () => void;
  context: External_Link_Context_Enum;
  k8sClusterName?: string;
  namespace?: string;
  serviceId?: string;
}> = ({ handleClose, context, k8sClusterName, namespace, serviceId }) => {
  const [errorMessage, setErrorMessage] = useState<UpsertLinkErrorType>(
    UpsertLinkErrorType.NoError
  );
  const [acceptedCluster, setAcceptedCluster] = useState(false);
  const [acceptedNamespace, setAcceptedNamespace] = useState(false);

  const { register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      name: "",
      link: "",
    },
  });

  const isValidLink = errorMessage?.length === 0;
  const name = watch("name");
  const link = watch("link");

  const addLinkMutation = useAddExternalLinkMutation();

  const privilegedOptions = usePrivilegedOptions();

  return (
    <form>
      <FormContainer>
        <NameInput
          nameLength={name.length}
          {...register("name", { required: true })}
        />
        <LinkInput
          errorMessage={errorMessage}
          {...register("link")}
          isValidInput={isValidLink}
          setErrorMessage={setErrorMessage}
        />
        <ClusterNamespaceCheckbox
          acceptedCluster={acceptedCluster}
          setAcceptedCluster={setAcceptedCluster}
          acceptedNamespace={acceptedNamespace}
          setAcceptedNamespace={setAcceptedNamespace}
          serviceCluster={k8sClusterName}
          serviceNamespace={namespace}
        />
        <SmallExternalLink color={blueBrand} href={ANNOTATIONS_DOCS_LINK}>
          Learn how to create dynamic links
        </SmallExternalLink>
        <ModalErrorMessage
          errorMessage={errorMessage}
          expectedType={UpsertLinkErrorType.AddLinkError}
        />
      </FormContainer>
      <ButtonContainer>
        <TooltipWrapper
          tooltipId={DEFAULT_TOOLTIP}
          content={
            isSandbox()
              ? "This option is disabled in sandbox version"
              : undefined
          }
          place="left"
        >
          <Button
            variant="primary"
            onClick={handleSubmit(
              addLinkSubmitHandler(
                addLinkMutation,
                acceptedCluster,
                acceptedNamespace,
                handleClose,
                setErrorMessage,
                privilegedOptions,
                context,
                k8sClusterName,
                namespace,
                serviceId
              )
            )}
            disabled={name.length === 0 || link.length === 0 || isSandbox()}
          >
            Add Link
          </Button>
        </TooltipWrapper>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </ButtonContainer>
    </form>
  );
};

const AddLinkModal: React.FC<{
  show: boolean;
  close: () => void;
  context: External_Link_Context_Enum;
  k8sClusterName?: string;
  namespace?: string;
  serviceId?: string;
}> = ({ show, close, context, k8sClusterName, namespace, serviceId }) => (
  <LinkModal title="Add Link" show={show} handleClose={close}>
    <AddLinkForm
      handleClose={close}
      context={context}
      k8sClusterName={k8sClusterName}
      namespace={namespace}
      serviceId={serviceId}
    />
  </LinkModal>
);

export default AddLinkModal;
