import retryElectronSetupEnv from "./retryElectronSetupEnv";

const useRetryDesktopSetup = (): {
  retrySetup: () => void;
} => {
  const retrySetup = async () => {
    (async () => {
      retryElectronSetupEnv();
    })();
  };

  return { retrySetup };
};

export default useRetryDesktopSetup;
