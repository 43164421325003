import { useEffect } from "react";

import { Account } from "../../../generated/accounts";
import { ACCOUNT_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "../../../shared/hooks/state";
import { setStoredAccountId } from "../../../shared/utils/auth0/localEnv/storedAccountId";

export const useAccountCorrector = (
  accountIdFromMetadata: string,
  accounts: Account[] | undefined
) => {
  const [accountIdInUrl, setAccountIdInUrl] =
    useStateInSearchParams(ACCOUNT_PARAM_KEY);

  useEffect(() => {
    if (!accounts?.length) return;
    const isValid = (id: string) => accounts.some((a) => a.id === id);
    if (accountIdInUrl && isValid(accountIdInUrl)) {
      if (accountIdInUrl === accountIdFromMetadata) return;
      setStoredAccountId(accountIdInUrl);
      window.location.reload();
      return;
    }
    if (isValid(accountIdFromMetadata)) {
      setAccountIdInUrl(accountIdFromMetadata, true);
      return;
    }
    setStoredAccountId(accounts[0].id);
    window.location.reload();
  }, [accountIdFromMetadata, accountIdInUrl, accounts, setAccountIdInUrl]);
};
