import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { theme } from "@komodorio/design-system";
import {
  ActionMetadataRollbackService,
  ActionTypes,
  TaskType,
} from "komodor-types";
import { useLocation } from "react-router-dom";
import pluralize from "pluralize";

import {
  extractPathFromLocation,
  isFaultyResultMessage,
} from "../../common/utils";
import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../../shared/config/analyticsEvents";
import { ErrorMessage } from "../../common/ErrorMessage";
import { Result } from "../../common/styles";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import {
  ActionSentMessage,
  useShouldShowActionSentMessage,
} from "../../common/useActionSent";

interface RollbackModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  serviceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  currentRevision?: number;
}

const RollbackModal: React.FC<RollbackModalProps> = ({
  isModalOpen,
  handleClose,
  serviceId,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  currentRevision,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const metadata: ActionMetadataRollbackService = {
    cluster: cluster,
    namespace: namespace,
    serviceId: serviceId ?? "",
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    type: ActionTypes.RollbackService,
  };

  const {
    execute,
    result: rollbackResult,
    deniedObject: rollbackDeniedObject,
    failureMessage: rollbackFailureMessage,
    isFetching,
  } = useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);
  const analytics = useAnalyticsApi();
  const executeAndReport = () => {
    setErrorMessage("");
    execute();
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: "rollbackService",
        path: extractPathFromLocation(location),
        resourceType: resourceType,
      },
      true,
      false,
      [SegmentIntegrations.Hubspot]
    );
  };

  const shouldShowActionSentMessage =
    useShouldShowActionSentMessage(isFetching);

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  useEffect(() => {
    if (rollbackDeniedObject) {
      setActionSent(false);
    }
  }, [rollbackDeniedObject]);

  const errorOutput = errorMessage || rollbackFailureMessage;

  useEffect(() => {
    if (rollbackResult) {
      if (isFaultyResultMessage(rollbackResult as string)) {
        setErrorMessage(rollbackResult as string);
        setActionSent(false);
      } else {
        handleClose();
      }
    }
  }, [handleClose, rollbackResult]);

  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }

  return (
    <>
      <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
        <ModalHeader>
          <Typography variant="headline">
            Rollback Service: {resourceName}
          </Typography>
        </ModalHeader>
        <ModalContent>
          <Typography size="medium">
            This action will <b>rollback</b> the service to the previous
            revision.{" "}
            {currentRevision !== undefined && (
              <>
                Current revision is: <b>{currentRevision}</b>
              </>
            )}
            <br />
            <br />
          </Typography>
          {errorOutput ? (
            <Result>
              <Typography color={theme.foreground.fgPink}>
                {errorOutput}
              </Typography>
            </Result>
          ) : null}
          {rollbackDeniedObject !== undefined && (
            <ErrorMessage reason={rollbackDeniedObject} />
          )}
        </ModalContent>
        <ModalActions>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="small"
            disabled={actionSent}
            onClick={() => {
              executeAndReport();
              setActionSent(true);
            }}
          >
            {actionSent ? "reverting..." : "Rollback Service"}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default RollbackModal;
