/* tslint:disable */
/* eslint-disable */
/**
 * Monitors API
 * This is an API that serve Komodor users Monitors data
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        
/**
 * 
 * @export
 */
export const AggregateBy = {
    Service: 'service',
    Day: 'day',
    Cluster: 'cluster'
} as const;
export type AggregateBy = typeof AggregateBy[keyof typeof AggregateBy];


export function instanceOfAggregateBy(value: any): boolean {
    for (const key in AggregateBy) {
        if (Object.prototype.hasOwnProperty.call(AggregateBy, key)) {
            if (AggregateBy[key as keyof typeof AggregateBy] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AggregateByFromJSON(json: any): AggregateBy {
    return AggregateByFromJSONTyped(json, false);
}

export function AggregateByFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregateBy {
    return json as AggregateBy;
}

export function AggregateByToJSON(value?: AggregateBy | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface ApiV1ConfigurationsDefaultPostRequest
 */
export interface ApiV1ConfigurationsDefaultPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1ConfigurationsDefaultPostRequest
     */
    'clusterName': string;
}


/**
 * Check if a given object implements the ApiV1ConfigurationsDefaultPostRequest interface.
 */
export function instanceOfApiV1ConfigurationsDefaultPostRequest(value: object): value is ApiV1ConfigurationsDefaultPostRequest {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    return true;
}

export function ApiV1ConfigurationsDefaultPostRequestFromJSON(json: any): ApiV1ConfigurationsDefaultPostRequest {
    return ApiV1ConfigurationsDefaultPostRequestFromJSONTyped(json, false);
}

export function ApiV1ConfigurationsDefaultPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1ConfigurationsDefaultPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
    };
}

export function ApiV1ConfigurationsDefaultPostRequestToJSON(value?: ApiV1ConfigurationsDefaultPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
    };
}

        
/**
 * 
 * @export
 */
export const BinType = {
    Hour: 'hour',
    FourHours: 'fourHours',
    Day: 'day',
    Week: 'week'
} as const;
export type BinType = typeof BinType[keyof typeof BinType];


export function instanceOfBinType(value: any): boolean {
    for (const key in BinType) {
        if (Object.prototype.hasOwnProperty.call(BinType, key)) {
            if (BinType[key as keyof typeof BinType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BinTypeFromJSON(json: any): BinType {
    return BinTypeFromJSONTyped(json, false);
}

export function BinTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinType {
    return json as BinType;
}

export function BinTypeToJSON(value?: BinType | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface ConfigurationSensor
 */
export interface ConfigurationSensor {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationSensor
     */
    'cluster': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationSensor
     */
    'namespaces'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationSensor
     */
    'annotations'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationSensor
     */
    'labels'?: Array<string>;
    /**
     * 
     * @type {ConfigurationSensorFilters}
     * @memberof ConfigurationSensor
     */
    'exclude'?: ConfigurationSensorFilters;
}


/**
 * Check if a given object implements the ConfigurationSensor interface.
 */
export function instanceOfConfigurationSensor(value: object): value is ConfigurationSensor {
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    return true;
}

export function ConfigurationSensorFromJSON(json: any): ConfigurationSensor {
    return ConfigurationSensorFromJSONTyped(json, false);
}

export function ConfigurationSensorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationSensor {
    if (json == null) {
        return json;
    }
    return {
        
        'cluster': json['cluster'],
        'namespaces': json['namespaces'] == null ? undefined : json['namespaces'],
        'annotations': json['annotations'] == null ? undefined : json['annotations'],
        'labels': json['labels'] == null ? undefined : json['labels'],
        'exclude': json['exclude'] == null ? undefined : ConfigurationSensorFiltersFromJSON(json['exclude']),
    };
}

export function ConfigurationSensorToJSON(value?: ConfigurationSensor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cluster': value['cluster'],
        'namespaces': value['namespaces'],
        'annotations': value['annotations'],
        'labels': value['labels'],
        'exclude': ConfigurationSensorFiltersToJSON(value['exclude']),
    };
}

        /**
 * 
 * @export
 * @interface ConfigurationSensorFilters
 */
export interface ConfigurationSensorFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationSensorFilters
     */
    'namespaces'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationSensorFilters
     */
    'annotations'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationSensorFilters
     */
    'labels'?: Array<string>;
}


/**
 * Check if a given object implements the ConfigurationSensorFilters interface.
 */
export function instanceOfConfigurationSensorFilters(value: object): value is ConfigurationSensorFilters {
    return true;
}

export function ConfigurationSensorFiltersFromJSON(json: any): ConfigurationSensorFilters {
    return ConfigurationSensorFiltersFromJSONTyped(json, false);
}

export function ConfigurationSensorFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationSensorFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'namespaces': json['namespaces'] == null ? undefined : json['namespaces'],
        'annotations': json['annotations'] == null ? undefined : json['annotations'],
        'labels': json['labels'] == null ? undefined : json['labels'],
    };
}

export function ConfigurationSensorFiltersToJSON(value?: ConfigurationSensorFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'namespaces': value['namespaces'],
        'annotations': value['annotations'],
        'labels': value['labels'],
    };
}

        /**
 * 
 * @export
 * @interface ConfigurationVariables
 */
export interface ConfigurationVariables {
    /**
     * 
     * @type {number}
     * @memberof ConfigurationVariables
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationVariables
     */
    'minAvailable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationVariables
     */
    'cronJobCondition'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationVariables
     */
    'resourceKinds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationVariables
     */
    'reasons'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationVariables
     */
    'nodeCreationThreshold'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationVariables
     */
    'resolveAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationVariables
     */
    'ignoreAfter'?: number;
}


/**
 * Check if a given object implements the ConfigurationVariables interface.
 */
export function instanceOfConfigurationVariables(value: object): value is ConfigurationVariables {
    return true;
}

export function ConfigurationVariablesFromJSON(json: any): ConfigurationVariables {
    return ConfigurationVariablesFromJSONTyped(json, false);
}

export function ConfigurationVariablesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationVariables {
    if (json == null) {
        return json;
    }
    return {
        
        'duration': json['duration'] == null ? undefined : json['duration'],
        'minAvailable': json['minAvailable'] == null ? undefined : json['minAvailable'],
        'cronJobCondition': json['cronJobCondition'] == null ? undefined : json['cronJobCondition'],
        'resourceKinds': json['resourceKinds'] == null ? undefined : json['resourceKinds'],
        'reasons': json['reasons'] == null ? undefined : json['reasons'],
        'nodeCreationThreshold': json['nodeCreationThreshold'] == null ? undefined : json['nodeCreationThreshold'],
        'resolveAfter': json['resolveAfter'] == null ? undefined : json['resolveAfter'],
        'ignoreAfter': json['ignoreAfter'] == null ? undefined : json['ignoreAfter'],
    };
}

export function ConfigurationVariablesToJSON(value?: ConfigurationVariables | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'duration': value['duration'],
        'minAvailable': value['minAvailable'],
        'cronJobCondition': value['cronJobCondition'],
        'resourceKinds': value['resourceKinds'],
        'reasons': value['reasons'],
        'nodeCreationThreshold': value['nodeCreationThreshold'],
        'resolveAfter': value['resolveAfter'],
        'ignoreAfter': value['ignoreAfter'],
    };
}

        /**
 * 
 * @export
 * @interface ConfigurationsResponse
 */
export interface ConfigurationsResponse {
    /**
     * 
     * @type {Array<MonitorConfiguration>}
     * @memberof ConfigurationsResponse
     */
    'data': Array<MonitorConfiguration>;
}


/**
 * Check if a given object implements the ConfigurationsResponse interface.
 */
export function instanceOfConfigurationsResponse(value: object): value is ConfigurationsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ConfigurationsResponseFromJSON(json: any): ConfigurationsResponse {
    return ConfigurationsResponseFromJSONTyped(json, false);
}

export function ConfigurationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MonitorConfigurationFromJSON)),
    };
}

export function ConfigurationsResponseToJSON(value?: ConfigurationsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(MonitorConfigurationToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface Deploy
 */
export interface Deploy {
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'endEventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'deploymentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'versionFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'versionTo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Deploy
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'newSpec'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'oldSpec'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Deploy
     */
    'kubernetesConfigChanges'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Deploy
     */
    'deploymentDiffKeys'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Deploy
     */
    'gitCompare'?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof Deploy
     */
    'isEmptyDeployment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'statusMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'statusReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'changeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Deploy
     */
    'generation'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Deploy
     */
    'trackedFiles'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'failed_container'?: string;
    /**
     * 
     * @type {object}
     * @memberof Deploy
     */
    'strategy'?: object;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'calculatedSummary'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'calculatedDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'appLink'?: string;
}


/**
 * Check if a given object implements the Deploy interface.
 */
export function instanceOfDeploy(value: object): value is Deploy {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function DeployFromJSON(json: any): Deploy {
    return DeployFromJSONTyped(json, false);
}

export function DeployFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deploy {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventTime': json['eventTime'] == null ? undefined : json['eventTime'],
        'endEventTime': json['endEventTime'] == null ? undefined : json['endEventTime'],
        'deploymentName': json['deploymentName'] == null ? undefined : json['deploymentName'],
        'versionFrom': json['versionFrom'] == null ? undefined : json['versionFrom'],
        'versionTo': json['versionTo'] == null ? undefined : json['versionTo'],
        'services': json['services'] == null ? undefined : json['services'],
        'newSpec': json['newSpec'] == null ? undefined : json['newSpec'],
        'oldSpec': json['oldSpec'] == null ? undefined : json['oldSpec'],
        'kubernetesConfigChanges': json['kubernetesConfigChanges'] == null ? undefined : json['kubernetesConfigChanges'],
        'deploymentDiffKeys': json['deploymentDiffKeys'] == null ? undefined : json['deploymentDiffKeys'],
        'updatedAt': json['updatedAt'] == null ? undefined : json['updatedAt'],
        'gitCompare': json['gitCompare'] == null ? undefined : json['gitCompare'],
        'isEmptyDeployment': json['isEmptyDeployment'] == null ? undefined : json['isEmptyDeployment'],
        'status': json['status'] == null ? undefined : json['status'],
        'statusMessage': json['statusMessage'] == null ? undefined : json['statusMessage'],
        'statusReason': json['statusReason'] == null ? undefined : json['statusReason'],
        'changeType': json['changeType'] == null ? undefined : json['changeType'],
        'generation': json['generation'] == null ? undefined : json['generation'],
        'trackedFiles': json['trackedFiles'] == null ? undefined : json['trackedFiles'],
        'failed_container': json['failed_container'] == null ? undefined : json['failed_container'],
        'strategy': json['strategy'] == null ? undefined : json['strategy'],
        'clusterName': json['clusterName'] == null ? undefined : json['clusterName'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'calculatedSummary': json['calculatedSummary'] == null ? undefined : json['calculatedSummary'],
        'calculatedDetails': json['calculatedDetails'] == null ? undefined : json['calculatedDetails'],
        'appLink': json['appLink'] == null ? undefined : json['appLink'],
    };
}

export function DeployToJSON(value?: Deploy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'eventTime': value['eventTime'],
        'endEventTime': value['endEventTime'],
        'deploymentName': value['deploymentName'],
        'versionFrom': value['versionFrom'],
        'versionTo': value['versionTo'],
        'services': value['services'],
        'newSpec': value['newSpec'],
        'oldSpec': value['oldSpec'],
        'kubernetesConfigChanges': value['kubernetesConfigChanges'],
        'deploymentDiffKeys': value['deploymentDiffKeys'],
        'updatedAt': value['updatedAt'],
        'gitCompare': value['gitCompare'],
        'isEmptyDeployment': value['isEmptyDeployment'],
        'status': value['status'],
        'statusMessage': value['statusMessage'],
        'statusReason': value['statusReason'],
        'changeType': value['changeType'],
        'generation': value['generation'],
        'trackedFiles': value['trackedFiles'],
        'failed_container': value['failed_container'],
        'strategy': value['strategy'],
        'clusterName': value['clusterName'],
        'namespace': value['namespace'],
        'calculatedSummary': value['calculatedSummary'],
        'calculatedDetails': value['calculatedDetails'],
        'appLink': value['appLink'],
    };
}

        /**
 * 
 * @export
 * @interface DeploysResponse
 */
export interface DeploysResponse {
    /**
     * 
     * @type {Array<Deploy>}
     * @memberof DeploysResponse
     */
    'data': Array<Deploy>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof DeploysResponse
     */
    'meta'?: ResponseMeta;
}


/**
 * Check if a given object implements the DeploysResponse interface.
 */
export function instanceOfDeploysResponse(value: object): value is DeploysResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function DeploysResponseFromJSON(json: any): DeploysResponse {
    return DeploysResponseFromJSONTyped(json, false);
}

export function DeploysResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploysResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DeployFromJSON)),
        'meta': json['meta'] == null ? undefined : ResponseMetaFromJSON(json['meta']),
    };
}

export function DeploysResponseToJSON(value?: DeploysResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(DeployToJSON)),
        'meta': ResponseMetaToJSON(value['meta']),
    };
}

        /**
 * 
 * @export
 * @interface IssuesCountRequest
 */
export interface IssuesCountRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IssuesCountRequest
     */
    'services': Array<string>;
}


/**
 * Check if a given object implements the IssuesCountRequest interface.
 */
export function instanceOfIssuesCountRequest(value: object): value is IssuesCountRequest {
    if (!('services' in value) || value['services'] === undefined) return false;
    return true;
}

export function IssuesCountRequestFromJSON(json: any): IssuesCountRequest {
    return IssuesCountRequestFromJSONTyped(json, false);
}

export function IssuesCountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuesCountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'services': json['services'],
    };
}

export function IssuesCountRequestToJSON(value?: IssuesCountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'services': value['services'],
    };
}

        /**
 * 
 * @export
 * @interface IssuesCountResponse
 */
export interface IssuesCountResponse {
    /**
     * 
     * @type {Array<IssuesCountResponseData>}
     * @memberof IssuesCountResponse
     */
    'data': Array<IssuesCountResponseData>;
}


/**
 * Check if a given object implements the IssuesCountResponse interface.
 */
export function instanceOfIssuesCountResponse(value: object): value is IssuesCountResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function IssuesCountResponseFromJSON(json: any): IssuesCountResponse {
    return IssuesCountResponseFromJSONTyped(json, false);
}

export function IssuesCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuesCountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(IssuesCountResponseDataFromJSON)),
    };
}

export function IssuesCountResponseToJSON(value?: IssuesCountResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(IssuesCountResponseDataToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface IssuesCountResponseData
 */
export interface IssuesCountResponseData {
    /**
     * 
     * @type {number}
     * @memberof IssuesCountResponseData
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof IssuesCountResponseData
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuesCountResponseData
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuesCountResponseData
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof IssuesCountResponseData
     */
    'namespace': string;
}


/**
 * Check if a given object implements the IssuesCountResponseData interface.
 */
export function instanceOfIssuesCountResponseData(value: object): value is IssuesCountResponseData {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    return true;
}

export function IssuesCountResponseDataFromJSON(json: any): IssuesCountResponseData {
    return IssuesCountResponseDataFromJSONTyped(json, false);
}

export function IssuesCountResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuesCountResponseData {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'service': json['service'] == null ? undefined : json['service'],
        'day': json['day'] == null ? undefined : json['day'],
        'cluster': json['cluster'],
        'namespace': json['namespace'],
    };
}

export function IssuesCountResponseDataToJSON(value?: IssuesCountResponseData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'service': value['service'],
        'day': value['day'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
    };
}

        /**
 * 
 * @export
 * @interface MonitorConfiguration
 */
export interface MonitorConfiguration {
    /**
     * 
     * @type {string}
     * @memberof MonitorConfiguration
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MonitorConfiguration
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ConfigurationSensor>}
     * @memberof MonitorConfiguration
     */
    'sensors'?: Array<ConfigurationSensor>;
    /**
     * 
     * @type {{ [key: string]: Array<object>; }}
     * @memberof MonitorConfiguration
     */
    'sinks'?: { [key: string]: Array<object>; };
    /**
     * 
     * @type {boolean}
     * @memberof MonitorConfiguration
     */
    'active'?: boolean;
    /**
     * 
     * @type {MonitorType}
     * @memberof MonitorConfiguration
     */
    'type'?: MonitorType;
    /**
     * 
     * @type {ConfigurationVariables}
     * @memberof MonitorConfiguration
     */
    'variables'?: ConfigurationVariables;
    /**
     * 
     * @type {SinksOptions}
     * @memberof MonitorConfiguration
     */
    'sinksOptions'?: SinksOptions;
    /**
     * 
     * @type {boolean}
     * @memberof MonitorConfiguration
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitorConfiguration
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorConfiguration
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorConfiguration
     */
    'updatedAt'?: string;
}




/**
 * Check if a given object implements the MonitorConfiguration interface.
 */
export function instanceOfMonitorConfiguration(value: object): value is MonitorConfiguration {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function MonitorConfigurationFromJSON(json: any): MonitorConfiguration {
    return MonitorConfigurationFromJSONTyped(json, false);
}

export function MonitorConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitorConfiguration {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'sensors': json['sensors'] == null ? undefined : ((json['sensors'] as Array<any>).map(ConfigurationSensorFromJSON)),
        'sinks': json['sinks'] == null ? undefined : json['sinks'],
        'active': json['active'] == null ? undefined : json['active'],
        'type': json['type'] == null ? undefined : MonitorTypeFromJSON(json['type']),
        'variables': json['variables'] == null ? undefined : ConfigurationVariablesFromJSON(json['variables']),
        'sinksOptions': json['sinksOptions'] == null ? undefined : SinksOptionsFromJSON(json['sinksOptions']),
        'isDeleted': json['isDeleted'] == null ? undefined : json['isDeleted'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'updatedAt': json['updatedAt'] == null ? undefined : json['updatedAt'],
    };
}

export function MonitorConfigurationToJSON(value?: MonitorConfiguration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'sensors': value['sensors'] == null ? undefined : ((value['sensors'] as Array<any>).map(ConfigurationSensorToJSON)),
        'sinks': value['sinks'],
        'active': value['active'],
        'type': MonitorTypeToJSON(value['type']),
        'variables': ConfigurationVariablesToJSON(value['variables']),
        'sinksOptions': SinksOptionsToJSON(value['sinksOptions']),
        'isDeleted': value['isDeleted'],
        'accountId': value['accountId'],
        'createdAt': value['createdAt'],
        'updatedAt': value['updatedAt'],
    };
}

        /**
 * 
 * @export
 * @interface MonitorData
 */
export interface MonitorData {
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'closeReason'?: string | null;
    /**
     * 
     * @type {MonitorType}
     * @memberof MonitorData
     */
    'type'?: MonitorType;
    /**
     * 
     * @type {object}
     * @memberof MonitorData
     */
    'results'?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitorData
     */
    'triageData'?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitorData
     */
    'resultsSummary'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitorData
     */
    'reasons'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitorData
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'sourceEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'sourceEventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'resourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'cluster'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'shortResourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'workflowConfigurationId'?: string;
    /**
     * 
     * @type {object}
     * @memberof MonitorData
     */
    'workflowConfiguration'?: object;
    /**
     * 
     * @type {object}
     * @memberof MonitorData
     */
    'openMonitorAlerts'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitorData
     */
    'rbacResources'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'appLink'?: string;
}




/**
 * Check if a given object implements the MonitorData interface.
 */
export function instanceOfMonitorData(value: object): value is MonitorData {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function MonitorDataFromJSON(json: any): MonitorData {
    return MonitorDataFromJSONTyped(json, false);
}

export function MonitorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitorData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'eventTime': json['eventTime'] == null ? undefined : json['eventTime'],
        'closedAt': json['closedAt'] == null ? undefined : json['closedAt'],
        'updatedAt': json['updatedAt'] == null ? undefined : json['updatedAt'],
        'closeReason': json['closeReason'] == null ? undefined : json['closeReason'],
        'type': json['type'] == null ? undefined : MonitorTypeFromJSON(json['type']),
        'results': json['results'] == null ? undefined : json['results'],
        'triageData': json['triageData'] == null ? undefined : json['triageData'],
        'resultsSummary': json['resultsSummary'] == null ? undefined : json['resultsSummary'],
        'reasons': json['reasons'] == null ? undefined : json['reasons'],
        'services': json['services'] == null ? undefined : json['services'],
        'sourceEventId': json['sourceEventId'] == null ? undefined : json['sourceEventId'],
        'sourceEventType': json['sourceEventType'] == null ? undefined : json['sourceEventType'],
        'resourceId': json['resourceId'] == null ? undefined : json['resourceId'],
        'cluster': json['cluster'] == null ? undefined : json['cluster'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'shortResourceName': json['shortResourceName'] == null ? undefined : json['shortResourceName'],
        'workflowConfigurationId': json['workflowConfigurationId'] == null ? undefined : json['workflowConfigurationId'],
        'workflowConfiguration': json['workflowConfiguration'] == null ? undefined : json['workflowConfiguration'],
        'openMonitorAlerts': json['openMonitorAlerts'] == null ? undefined : json['openMonitorAlerts'],
        'rbacResources': json['rbacResources'] == null ? undefined : json['rbacResources'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
        'appLink': json['appLink'] == null ? undefined : json['appLink'],
    };
}

export function MonitorDataToJSON(value?: MonitorData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdAt': value['createdAt'],
        'eventTime': value['eventTime'],
        'closedAt': value['closedAt'],
        'updatedAt': value['updatedAt'],
        'closeReason': value['closeReason'],
        'type': MonitorTypeToJSON(value['type']),
        'results': value['results'],
        'triageData': value['triageData'],
        'resultsSummary': value['resultsSummary'],
        'reasons': value['reasons'],
        'services': value['services'],
        'sourceEventId': value['sourceEventId'],
        'sourceEventType': value['sourceEventType'],
        'resourceId': value['resourceId'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'shortResourceName': value['shortResourceName'],
        'workflowConfigurationId': value['workflowConfigurationId'],
        'workflowConfiguration': value['workflowConfiguration'],
        'openMonitorAlerts': value['openMonitorAlerts'],
        'rbacResources': value['rbacResources'],
        'accountId': value['accountId'],
        'appLink': value['appLink'],
    };
}

        /**
 * 
 * @export
 * @interface MonitorResponse
 */
export interface MonitorResponse {
    /**
     * 
     * @type {MonitorData}
     * @memberof MonitorResponse
     */
    'data': MonitorData | null;
}


/**
 * Check if a given object implements the MonitorResponse interface.
 */
export function instanceOfMonitorResponse(value: object): value is MonitorResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function MonitorResponseFromJSON(json: any): MonitorResponse {
    return MonitorResponseFromJSONTyped(json, false);
}

export function MonitorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': MonitorDataFromJSON(json['data']),
    };
}

export function MonitorResponseToJSON(value?: MonitorResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': MonitorDataToJSON(value['data']),
    };
}

        
/**
 * 
 * @export
 */
export const MonitorType = {
    Availability: 'availability',
    Node: 'node',
    Pvc: 'PVC',
    Job: 'job',
    CronJob: 'cronJob',
    Deploy: 'deploy',
    Pod: 'pod',
    Workflow: 'workflow'
} as const;
export type MonitorType = typeof MonitorType[keyof typeof MonitorType];


export function instanceOfMonitorType(value: any): boolean {
    for (const key in MonitorType) {
        if (Object.prototype.hasOwnProperty.call(MonitorType, key)) {
            if (MonitorType[key as keyof typeof MonitorType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MonitorTypeFromJSON(json: any): MonitorType {
    return MonitorTypeFromJSONTyped(json, false);
}

export function MonitorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitorType {
    return json as MonitorType;
}

export function MonitorTypeToJSON(value?: MonitorType | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface MonitorsResponse
 */
export interface MonitorsResponse {
    /**
     * 
     * @type {Array<MonitorData>}
     * @memberof MonitorsResponse
     */
    'data': Array<MonitorData>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof MonitorsResponse
     */
    'meta'?: ResponseMeta;
}


/**
 * Check if a given object implements the MonitorsResponse interface.
 */
export function instanceOfMonitorsResponse(value: object): value is MonitorsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function MonitorsResponseFromJSON(json: any): MonitorsResponse {
    return MonitorsResponseFromJSONTyped(json, false);
}

export function MonitorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitorsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MonitorDataFromJSON)),
        'meta': json['meta'] == null ? undefined : ResponseMetaFromJSON(json['meta']),
    };
}

export function MonitorsResponseToJSON(value?: MonitorsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(MonitorDataToJSON)),
        'meta': ResponseMetaToJSON(value['meta']),
    };
}

        /**
 * 
 * @export
 * @interface OpenIssuesResponse
 */
export interface OpenIssuesResponse {
    /**
     * 
     * @type {Array<OpenIssuesResponseDataByCluster>}
     * @memberof OpenIssuesResponse
     */
    'data': Array<OpenIssuesResponseDataByCluster>;
}


/**
 * Check if a given object implements the OpenIssuesResponse interface.
 */
export function instanceOfOpenIssuesResponse(value: object): value is OpenIssuesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function OpenIssuesResponseFromJSON(json: any): OpenIssuesResponse {
    return OpenIssuesResponseFromJSONTyped(json, false);
}

export function OpenIssuesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenIssuesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OpenIssuesResponseDataByClusterFromJSON)),
    };
}

export function OpenIssuesResponseToJSON(value?: OpenIssuesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(OpenIssuesResponseDataByClusterToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface OpenIssuesResponseDataByCluster
 */
export interface OpenIssuesResponseDataByCluster {
    /**
     * 
     * @type {string}
     * @memberof OpenIssuesResponseDataByCluster
     */
    'clusterName': string;
    /**
     * 
     * @type {Array<OpenIssuesResponseDataPoint>}
     * @memberof OpenIssuesResponseDataByCluster
     */
    'datapoints': Array<OpenIssuesResponseDataPoint>;
}


/**
 * Check if a given object implements the OpenIssuesResponseDataByCluster interface.
 */
export function instanceOfOpenIssuesResponseDataByCluster(value: object): value is OpenIssuesResponseDataByCluster {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('datapoints' in value) || value['datapoints'] === undefined) return false;
    return true;
}

export function OpenIssuesResponseDataByClusterFromJSON(json: any): OpenIssuesResponseDataByCluster {
    return OpenIssuesResponseDataByClusterFromJSONTyped(json, false);
}

export function OpenIssuesResponseDataByClusterFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenIssuesResponseDataByCluster {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
        'datapoints': ((json['datapoints'] as Array<any>).map(OpenIssuesResponseDataPointFromJSON)),
    };
}

export function OpenIssuesResponseDataByClusterToJSON(value?: OpenIssuesResponseDataByCluster | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
        'datapoints': ((value['datapoints'] as Array<any>).map(OpenIssuesResponseDataPointToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface OpenIssuesResponseDataPoint
 */
export interface OpenIssuesResponseDataPoint {
    /**
     * 
     * @type {string}
     * @memberof OpenIssuesResponseDataPoint
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof OpenIssuesResponseDataPoint
     */
    'count': number;
}


/**
 * Check if a given object implements the OpenIssuesResponseDataPoint interface.
 */
export function instanceOfOpenIssuesResponseDataPoint(value: object): value is OpenIssuesResponseDataPoint {
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function OpenIssuesResponseDataPointFromJSON(json: any): OpenIssuesResponseDataPoint {
    return OpenIssuesResponseDataPointFromJSONTyped(json, false);
}

export function OpenIssuesResponseDataPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenIssuesResponseDataPoint {
    if (json == null) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'count': json['count'],
    };
}

export function OpenIssuesResponseDataPointToJSON(value?: OpenIssuesResponseDataPoint | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timestamp': value['timestamp'],
        'count': value['count'],
    };
}

        /**
 * 
 * @export
 * @interface PagerDutySink
 */
export interface PagerDutySink {
    /**
     * 
     * @type {string}
     * @memberof PagerDutySink
     */
    'channel': string;
    /**
     * 
     * @type {string}
     * @memberof PagerDutySink
     */
    'integrationKey': string;
    /**
     * 
     * @type {string}
     * @memberof PagerDutySink
     */
    'pagerDutyAccountName': string;
}


/**
 * Check if a given object implements the PagerDutySink interface.
 */
export function instanceOfPagerDutySink(value: object): value is PagerDutySink {
    if (!('channel' in value) || value['channel'] === undefined) return false;
    if (!('integrationKey' in value) || value['integrationKey'] === undefined) return false;
    if (!('pagerDutyAccountName' in value) || value['pagerDutyAccountName'] === undefined) return false;
    return true;
}

export function PagerDutySinkFromJSON(json: any): PagerDutySink {
    return PagerDutySinkFromJSONTyped(json, false);
}

export function PagerDutySinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagerDutySink {
    if (json == null) {
        return json;
    }
    return {
        
        'channel': json['channel'],
        'integrationKey': json['integrationKey'],
        'pagerDutyAccountName': json['pagerDutyAccountName'],
    };
}

export function PagerDutySinkToJSON(value?: PagerDutySink | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'channel': value['channel'],
        'integrationKey': value['integrationKey'],
        'pagerDutyAccountName': value['pagerDutyAccountName'],
    };
}

        /**
 * 
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'nextPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'prevPage'?: number;
}


/**
 * Check if a given object implements the ResponseMeta interface.
 */
export function instanceOfResponseMeta(value: object): value is ResponseMeta {
    if (!('pageSize' in value) || value['pageSize'] === undefined) return false;
    if (!('page' in value) || value['page'] === undefined) return false;
    return true;
}

export function ResponseMetaFromJSON(json: any): ResponseMeta {
    return ResponseMetaFromJSONTyped(json, false);
}

export function ResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'nextPage': json['nextPage'] == null ? undefined : json['nextPage'],
        'prevPage': json['prevPage'] == null ? undefined : json['prevPage'],
    };
}

export function ResponseMetaToJSON(value?: ResponseMeta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pageSize': value['pageSize'],
        'page': value['page'],
        'nextPage': value['nextPage'],
        'prevPage': value['prevPage'],
    };
}

        /**
 * 
 * @export
 * @interface Sinks
 */
export interface Sinks {
    /**
     * 
     * @type {Array<string>}
     * @memberof Sinks
     */
    'slack'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Sinks
     */
    'teams'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Sinks
     */
    'opsgenie'?: Array<string>;
    /**
     * 
     * @type {Array<PagerDutySink>}
     * @memberof Sinks
     */
    'pagerduty'?: Array<PagerDutySink>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Sinks
     */
    'genericWebhook'?: Array<string>;
}


/**
 * Check if a given object implements the Sinks interface.
 */
export function instanceOfSinks(value: object): value is Sinks {
    return true;
}

export function SinksFromJSON(json: any): Sinks {
    return SinksFromJSONTyped(json, false);
}

export function SinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sinks {
    if (json == null) {
        return json;
    }
    return {
        
        'slack': json['slack'] == null ? undefined : json['slack'],
        'teams': json['teams'] == null ? undefined : json['teams'],
        'opsgenie': json['opsgenie'] == null ? undefined : json['opsgenie'],
        'pagerduty': json['pagerduty'] == null ? undefined : ((json['pagerduty'] as Array<any>).map(PagerDutySinkFromJSON)),
        'genericWebhook': json['genericWebhook'] == null ? undefined : json['genericWebhook'],
    };
}

export function SinksToJSON(value?: Sinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'slack': value['slack'],
        'teams': value['teams'],
        'opsgenie': value['opsgenie'],
        'pagerduty': value['pagerduty'] == null ? undefined : ((value['pagerduty'] as Array<any>).map(PagerDutySinkToJSON)),
        'genericWebhook': value['genericWebhook'],
    };
}

        /**
 * 
 * @export
 * @interface SinksOptions
 */
export interface SinksOptions {
    /**
     * 
     * @type {Array<string>}
     * @memberof SinksOptions
     */
    'notifyOn'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SinksOptions
     */
    'shouldSend'?: boolean;
}


/**
 * Check if a given object implements the SinksOptions interface.
 */
export function instanceOfSinksOptions(value: object): value is SinksOptions {
    return true;
}

export function SinksOptionsFromJSON(json: any): SinksOptions {
    return SinksOptionsFromJSONTyped(json, false);
}

export function SinksOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SinksOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'notifyOn': json['notifyOn'] == null ? undefined : json['notifyOn'],
        'shouldSend': json['shouldSend'] == null ? undefined : json['shouldSend'],
    };
}

export function SinksOptionsToJSON(value?: SinksOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notifyOn': value['notifyOn'],
        'shouldSend': value['shouldSend'],
    };
}

    
        /**
* 
* @param params ConfigurationsApiApiV1ConfigurationsDefaultPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsDefaultPostUrl = (params: ConfigurationsApiApiV1ConfigurationsDefaultPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/default`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params ConfigurationsApiApiV1ConfigurationsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsGetUrl = (params: ConfigurationsApiApiV1ConfigurationsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ConfigurationsDefaultPost operation in ConfigurationsApi.
* @export
* @interface ConfigurationsApiApiV1ConfigurationsDefaultPostRequest
*/
export interface ConfigurationsApiApiV1ConfigurationsDefaultPostRequest {
    /**
    * 
    * @type {ApiV1ConfigurationsDefaultPostRequest}
    * @memberof ConfigurationsApiApiV1ConfigurationsDefaultPost
    */
    readonly apiV1ConfigurationsDefaultPostRequest?: ApiV1ConfigurationsDefaultPostRequest
}

/**
* Request parameters for apiV1ConfigurationsGet operation in ConfigurationsApi.
* @export
* @interface ConfigurationsApiApiV1ConfigurationsGetRequest
*/
export interface ConfigurationsApiApiV1ConfigurationsGetRequest {
    /**
    * Fetch deleted or not deleted configurations
    * @type {boolean}
    * @memberof ConfigurationsApiApiV1ConfigurationsGet
    */
    readonly isDeleted?: boolean

    /**
    * 
    * @type {Array<'id' | 'name' | 'sensors' | 'sinks' | 'active' | 'type' | 'variables' | 'sinksOptions' | 'createdAt' | 'updatedAt'>}
    * @memberof ConfigurationsApiApiV1ConfigurationsGet
    */
    readonly fields?: Array<'id' | 'name' | 'sensors' | 'sinks' | 'active' | 'type' | 'variables' | 'sinksOptions' | 'createdAt' | 'updatedAt'>
}


    
        /**
* 
* @param params DeploysApiApiV1DeploysSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1DeploysSearchGetUrl = (params: DeploysApiApiV1DeploysSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/deploys/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.serviceIds) {
        localVarQueryParameter['serviceIds'] = params.serviceIds;
    }
    if (params.ids) {
        localVarQueryParameter['ids'] = params.ids;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.page !== undefined) {
            localVarQueryParameter['page'] = params.page;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespaces) {
        localVarQueryParameter['namespaces'] = params.namespaces;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1DeploysSearchGet operation in DeploysApi.
* @export
* @interface DeploysApiApiV1DeploysSearchGetRequest
*/
export interface DeploysApiApiV1DeploysSearchGetRequest {
    /**
    * Start time of the event in epoch format
    * @type {string}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly fromEpoch?: string

    /**
    * End time of the event in epoch format
    * @type {string}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly serviceIds?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly ids?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>

    /**
    * The maximum number of results to return
    * @type {number}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly pageSize?: number

    /**
    * 
    * @type {number}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly page?: number

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly accountId?: string

    /**
    * Cluster name to filter by
    * @type {string}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly clusterName?: string

    /**
    * Namespaces to filter by
    * @type {Array<string>}
    * @memberof DeploysApiApiV1DeploysSearchGet
    */
    readonly namespaces?: Array<string>
}


    
        /**
* 
* @param params MonitorApiApiV1TypeIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1TypeIdGetUrl = (params: MonitorApiApiV1TypeIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1TypeIdGet', 'type', params.type)
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1TypeIdGet', 'id', params.id)
    const localVarPath = `/api/v1/{type}/{id}`
        .replace(`{${"type"}}`, encodeURIComponent(String(params.type)))
        .replace(`{${"id"}}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params MonitorApiApiV1TypeIssuesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1TypeIssuesGetUrl = (params: MonitorApiApiV1TypeIssuesGetRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1TypeIssuesGet', 'type', params.type)
    const localVarPath = `/api/v1/{type}/issues`
        .replace(`{${"type"}}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.ids) {
        localVarQueryParameter['ids'] = params.ids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1TypeIdGet operation in MonitorApi.
* @export
* @interface MonitorApiApiV1TypeIdGetRequest
*/
export interface MonitorApiApiV1TypeIdGetRequest {
    /**
    * Type of monitor to fetch
    * @type {MonitorType}
    * @memberof MonitorApiApiV1TypeIdGet
    */
    readonly type: MonitorType

    /**
    * Monitor run id to fetch
    * @type {string}
    * @memberof MonitorApiApiV1TypeIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>}
    * @memberof MonitorApiApiV1TypeIdGet
    */
    readonly fields?: Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>
}

/**
* Request parameters for apiV1TypeIssuesGet operation in MonitorApi.
* @export
* @interface MonitorApiApiV1TypeIssuesGetRequest
*/
export interface MonitorApiApiV1TypeIssuesGetRequest {
    /**
    * Type of monitor to fetch
    * @type {MonitorType}
    * @memberof MonitorApiApiV1TypeIssuesGet
    */
    readonly type: MonitorType

    /**
    * 
    * @type {Array<string>}
    * @memberof MonitorApiApiV1TypeIssuesGet
    */
    readonly ids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>}
    * @memberof MonitorApiApiV1TypeIssuesGet
    */
    readonly fields?: Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>
}


    
        /**
* 
* @param params MonitorsApiApiV1IssuesOverTimeGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1IssuesOverTimeGetUrl = (params: MonitorsApiApiV1IssuesOverTimeGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/issues-over-time`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.types) {
        localVarQueryParameter['types'] = params.types;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.timeBin !== undefined) {
            localVarQueryParameter['timeBin'] = params.timeBin;
    }
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params MonitorsApiApiV1SearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1SearchGetUrl = (params: MonitorsApiApiV1SearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.resourceId !== undefined) {
            localVarQueryParameter['resourceId'] = params.resourceId;
    }
    if (params.serviceIds) {
        localVarQueryParameter['serviceIds'] = params.serviceIds;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.types) {
        localVarQueryParameter['types'] = params.types;
    }
    if (params.includeOpen !== undefined) {
            localVarQueryParameter['includeOpen'] = params.includeOpen;
    }
    if (params.includeClose !== undefined) {
            localVarQueryParameter['includeClose'] = params.includeClose;
    }
    if (params.pageSize !== undefined) {
            localVarQueryParameter['pageSize'] = params.pageSize;
    }
    if (params.page !== undefined) {
            localVarQueryParameter['page'] = params.page;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespaces) {
        localVarQueryParameter['namespaces'] = params.namespaces;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params MonitorsApiApiV1TopAvailabilityIssuesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1TopAvailabilityIssuesGetUrl = (params: MonitorsApiApiV1TopAvailabilityIssuesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/top-availability-issues`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.count !== undefined) {
            localVarQueryParameter['count'] = params.count;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params MonitorsApiApiV1TopFailedDeployEventsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1TopFailedDeployEventsGetUrl = (params: MonitorsApiApiV1TopFailedDeployEventsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/top-failed-deploy-events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.count !== undefined) {
            localVarQueryParameter['count'] = params.count;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params MonitorsApiApiV1TypeCountPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1TypeCountPostUrl = (params: MonitorsApiApiV1TypeCountPostRequest, baseUrl: string): string => {
    // verify required parameter 'type' is not null or undefined
    assertParamExists('apiV1TypeCountPost', 'type', params.type)
    // verify required parameter 'aggregateBy' is not null or undefined
    assertParamExists('apiV1TypeCountPost', 'aggregateBy', params.aggregateBy)
    const localVarPath = `/api/v1/{type}/count`
        .replace(`{${"type"}}`, encodeURIComponent(String(params.type)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.aggregateBy) {
        localVarQueryParameter['aggregateBy'] = params.aggregateBy;
    }
    if (params.includeOpen !== undefined) {
            localVarQueryParameter['includeOpen'] = params.includeOpen;
    }
    if (params.includeClose !== undefined) {
            localVarQueryParameter['includeClose'] = params.includeClose;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.offset !== undefined) {
            localVarQueryParameter['offset'] = params.offset;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.userId !== undefined) {
            localVarQueryParameter['userId'] = params.userId;
    }
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1IssuesOverTimeGet operation in MonitorsApi.
* @export
* @interface MonitorsApiApiV1IssuesOverTimeGetRequest
*/
export interface MonitorsApiApiV1IssuesOverTimeGetRequest {
    /**
    * List of issue types to filter by
    * @type {Array<'availability' | 'node' | 'PVC' | 'job' | 'cronJob' | 'deploy' | 'pod' | 'job-pod' | 'workflow'>}
    * @memberof MonitorsApiApiV1IssuesOverTimeGet
    */
    readonly types?: Array<'availability' | 'node' | 'PVC' | 'job' | 'cronJob' | 'deploy' | 'pod' | 'job-pod' | 'workflow'>

    /**
    * Start time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1IssuesOverTimeGet
    */
    readonly fromEpoch?: string

    /**
    * End time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1IssuesOverTimeGet
    */
    readonly toEpoch?: string

    /**
    * The bin size for the time series.
    * @type {BinType}
    * @memberof MonitorsApiApiV1IssuesOverTimeGet
    */
    readonly timeBin?: BinType

    /**
    * Clusters to filter by
    * @type {Array<string>}
    * @memberof MonitorsApiApiV1IssuesOverTimeGet
    */
    readonly clusterNames?: Array<string>
}

/**
* Request parameters for apiV1SearchGet operation in MonitorsApi.
* @export
* @interface MonitorsApiApiV1SearchGetRequest
*/
export interface MonitorsApiApiV1SearchGetRequest {
    /**
    * Start time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly fromEpoch?: string

    /**
    * End time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly toEpoch?: string

    /**
    * Monitor resource id (workflow Id) to fetch
    * @type {string}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly resourceId?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly serviceIds?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * List of issue types to filter by
    * @type {Array<'availability' | 'node' | 'PVC' | 'job' | 'cronJob' | 'deploy' | 'pod' | 'job-pod' | 'workflow'>}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly types?: Array<'availability' | 'node' | 'PVC' | 'job' | 'cronJob' | 'deploy' | 'pod' | 'job-pod' | 'workflow'>

    /**
    * Whether to include open issues
    * @type {boolean}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly includeOpen?: boolean

    /**
    * Whether to include closed issues
    * @type {boolean}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly includeClose?: boolean

    /**
    * The maximum number of results to return
    * @type {number}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly pageSize?: number

    /**
    * 
    * @type {number}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly page?: number

    /**
    * 
    * @type {Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly fields?: Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly accountId?: string

    /**
    * Cluster name to filter by
    * @type {string}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly clusterName?: string

    /**
    * Namespaces to filter by
    * @type {Array<string>}
    * @memberof MonitorsApiApiV1SearchGet
    */
    readonly namespaces?: Array<string>
}

/**
* Request parameters for apiV1TopAvailabilityIssuesGet operation in MonitorsApi.
* @export
* @interface MonitorsApiApiV1TopAvailabilityIssuesGetRequest
*/
export interface MonitorsApiApiV1TopAvailabilityIssuesGetRequest {
    /**
    * Clusters to filter by
    * @type {Array<string>}
    * @memberof MonitorsApiApiV1TopAvailabilityIssuesGet
    */
    readonly clusterNames?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>}
    * @memberof MonitorsApiApiV1TopAvailabilityIssuesGet
    */
    readonly fields?: Array<'id' | 'createdAt' | 'eventTime' | 'closedAt' | 'updatedAt' | 'closeReason' | 'type' | 'results' | 'triageData' | 'resultsSummary' | 'reasons' | 'services' | 'sourceEventId' | 'sourceEventType' | 'resourceId' | 'cluster' | 'namespace' | 'shortResourceName' | 'workflowConfigurationId' | 'workflowConfiguration' | 'openMonitorAlerts' | 'rbacResources' | 'accountId'>

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1TopAvailabilityIssuesGet
    */
    readonly accountId?: string

    /**
    * User ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1TopAvailabilityIssuesGet
    */
    readonly userId?: string

    /**
    * The number of top issues to return
    * @type {number}
    * @memberof MonitorsApiApiV1TopAvailabilityIssuesGet
    */
    readonly count?: number

    /**
    * Start time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1TopAvailabilityIssuesGet
    */
    readonly fromEpoch?: string
}

/**
* Request parameters for apiV1TopFailedDeployEventsGet operation in MonitorsApi.
* @export
* @interface MonitorsApiApiV1TopFailedDeployEventsGetRequest
*/
export interface MonitorsApiApiV1TopFailedDeployEventsGetRequest {
    /**
    * Clusters to filter by
    * @type {Array<string>}
    * @memberof MonitorsApiApiV1TopFailedDeployEventsGet
    */
    readonly clusterNames?: Array<string>

    /**
    * Start time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1TopFailedDeployEventsGet
    */
    readonly fromEpoch?: string

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1TopFailedDeployEventsGet
    */
    readonly accountId?: string

    /**
    * User ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1TopFailedDeployEventsGet
    */
    readonly userId?: string

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>}
    * @memberof MonitorsApiApiV1TopFailedDeployEventsGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>

    /**
    * The number of top events to return
    * @type {number}
    * @memberof MonitorsApiApiV1TopFailedDeployEventsGet
    */
    readonly count?: number
}

/**
* Request parameters for apiV1TypeCountPost operation in MonitorsApi.
* @export
* @interface MonitorsApiApiV1TypeCountPostRequest
*/
export interface MonitorsApiApiV1TypeCountPostRequest {
    /**
    * Type of monitor to fetch
    * @type {MonitorType}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly type: MonitorType

    /**
    * The field to aggregate by
    * @type {Array<AggregateBy>}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly aggregateBy: Array<AggregateBy>

    /**
    * Start time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly fromEpoch?: string

    /**
    * End time of the event in epoch format
    * @type {string}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly toEpoch?: string

    /**
    * Whether to include open issues
    * @type {boolean}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly includeOpen?: boolean

    /**
    * Whether to include closed issues
    * @type {boolean}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly includeClose?: boolean

    /**
    * The maximum number of results to return
    * @type {number}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly limit?: number

    /**
    * 
    * @type {number}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly offset?: number

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly accountId?: string

    /**
    * User ID to override in case the requester is an admin
    * @type {string}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly userId?: string

    /**
    * Clusters to filter by
    * @type {Array<string>}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly clusterNames?: Array<string>

    /**
    * 
    * @type {IssuesCountRequest}
    * @memberof MonitorsApiApiV1TypeCountPost
    */
    readonly issuesCountRequest?: IssuesCountRequest
}


    
