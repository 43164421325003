import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import {
  DELETED_POD_KIND,
  DeletedPodType,
} from "@/components/ResourceView/resources/deletedPod";
import { Dictionary } from "@/shared/types/Dictionary";
import useParsedResource from "@/shared/hooks/resources/useParsedResource";
import PodPhaseGroup from "@/components/common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { useGetDeletedPodsState } from "@/shared/hooks/resources-api/client/useGetDeletedPodsState";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import Resource from "@/components/ResourceView/resources";

export const useGetDeletedPod = (
  eventGroup: PodPhaseGroup
): Resource | undefined => {
  const agentId = useActiveAgent(eventGroup.clusterName);

  const { data: deletedPodData } = useGetDeletedPodsState({
    clusterName: eventGroup.clusterName,
    fromEpoch: eventGroup.startTime.getTime().toString(),
    komodorUids: [
      buildKomodorUid({
        kind: "Pod",
        clusterName: eventGroup.clusterName,
        namespace: eventGroup.namespace,
        resourceName: eventGroup.podName,
      }),
    ],
    limit: 1,
  });
  const deletedPod: DeletedPodType = {
    age: "",
    id: "",
    namespace: eventGroup.namespace,
    name: deletedPodData?.data?.[0]?.podName ?? "",
    metadata: (deletedPodData?.data?.[0]?.metadata ??
      {}) as Dictionary<unknown>,
    status: (deletedPodData?.data?.[0]?.status as unknown as string) ?? "",
    spec: (deletedPodData?.data?.[0]?.spec ?? {}) as Dictionary<unknown>,
    node: (deletedPodData?.data?.[0]?.nodeName ?? "") as string,
    containerStatuses: (deletedPodData?.data?.[0]?.containerStatuses ??
      []) as Array<unknown>,
  };

  return useParsedResource(
    deletedPod,
    DELETED_POD_KIND,
    eventGroup.clusterName,
    agentId ?? "",
    eventGroup.status
  );
};
