import React, { useMemo } from "react";
import { get } from "lodash";
import { Pod } from "kubernetes-types/core/v1";
import { LabelSelector } from "kubernetes-types/meta/v1";

import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import EventGroup from "..";
import {
  jobType as JOB_RUN_EVENT_GROUP_TYPE,
  JobState,
} from "../../../../shared/types/job";
import { ServiceInfo } from "../../../../shared/types/ServiceInfo";
import { SELECTED_TAB_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import TextButton from "../../TextButton";
import { useResourceView } from "../../../ResourceView/ResourceProvider";
import { DrawerType } from "../../../../shared/store/drawersStackStore/types";
import { useGetPodsState } from "../../../../shared/hooks/resources-api/client/useGetPodsState";
import { useDrawersStackStore } from "../../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../../shared/store/drawersStackStore/drawersStackSelectors";
import { ResourceTabName } from "../../../ResourceView/resources";
import { KubernetesJobResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";
import { useFindServiceById } from "../../../../shared/hooks/useFindService";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { getJobControllerUid } from "../../../../shared/utils/jobUtils";
import { useLabelSelector } from "../../../../shared/utils/podUtils";

const useFetchJobPodsFromAPI = (
  cluster: string,
  ownerId: string | undefined,
  labelSelector: string
): string | null => {
  const { data } = useGetPodsState(
    {
      clusterName: cluster,
      resourceOwnerUid: ownerId,
      labelSelector,
      fields: ["json"],
    },
    !!cluster && !!ownerId && !!labelSelector
  );

  const pods = data?.data?.json as Pod[] | undefined;
  return pods?.[0]?.metadata?.name ?? null;
};

const useIsJobPodVisible = (
  cluster: string,
  eventGroup: EventGroup
): boolean => {
  const labelSelector = get(eventGroup, "labelSelector") as
    | LabelSelector
    | undefined;
  const controllerUid = getJobControllerUid(labelSelector);
  const labelSelectorExpression = useLabelSelector(labelSelector);

  const podsDataFromAPI = useFetchJobPodsFromAPI(
    cluster,
    controllerUid,
    labelSelectorExpression
  );
  const hasPodState = useMemo(() => !!podsDataFromAPI, [podsDataFromAPI]);

  return (
    eventGroup.type === JOB_RUN_EVENT_GROUP_TYPE &&
    eventGroup.status !== JobState.deleted &&
    hasPodState
  );
};

const ViewJobPodsButton: React.FC<{
  eventGroup: EventGroup;
  service?: ServiceInfo;
}> = ({ eventGroup, service: givenService }) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const resource = useResourceView();

  const service = useFindServiceById(eventGroup.serviceId) ?? givenService;

  const cluster = service?.k8sCluster ?? "";
  const namespace = service?.env ?? "";

  const shouldShowButton =
    useIsJobPodVisible(cluster, eventGroup) &&
    service &&
    resource?.kind !== KubernetesJobResource.Kind;

  if (!shouldShowButton) {
    return null;
  }

  return (
    <TextButton
      onClick={(e) => {
        e.stopPropagation();
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster,
          namespace,
          resourceType: KubernetesJobResource.Kind,
          resourceName: eventGroup.summary,
          urlStates: {
            [SELECTED_TAB_PARAM_KEY]: ResourceTabName.Pods,
          },
        });
        dispatchEvent(AnalyticEvents.JobsView.CronJobs_PodsLogsClicked);
      }}
      aria-label={AriaLabels.Events.JobRun.ViewJobPods}
    >
      View pods
    </TextButton>
  );
};

export default ViewJobPodsButton;
