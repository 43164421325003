import { useRef } from "react";
import semver from "semver";

import { ARGO_WF_ENABLED } from "../../shared/constants/localStorageKeys";
import { useStateInLocalStorage } from "../../shared/hooks/state";
import {
  AgentProperties,
  ConfigurationType,
} from "../../shared/hooks/useAgentInfo/useAgentInfo";
import useUserMetadata from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { REQUIRED_AGENT_VERSION_ARGO_WORKFLOWS } from "../../shared/utils/agent/agent";
import { LocalStorageItem } from "../../shared/utils/localStorageSettings";

export const useAgentSupportArgoWorkflows = (
  agentInfo: AgentProperties | null
): boolean => {
  return ConfigurationType.guard(agentInfo)
    ? semver.gte(
        agentInfo?.version ?? "0.0.0",
        REQUIRED_AGENT_VERSION_ARGO_WORKFLOWS
      ) && !!agentInfo?.resources?.argoWorkflows
    : false;
};

export const useIsArgoWorkflowsEnabledForAccount = () => {
  const { accountId } = useUserMetadata();
  const localStorageItem = useRef(
    new LocalStorageItem(`${ARGO_WF_ENABLED}-${accountId}`)
  );
  return useStateInLocalStorage(localStorageItem.current, "false");
};
