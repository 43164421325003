import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { groupBy, sortBy } from "lodash";

import ResponsiveLayout from "../../common/ResponsiveLayout";
import useWorkflowConfigurations from "../useWorkflowConfigurations";
import MonitorsViewContainer from "..";
import MonitorRuleDrawer from "../monitorsConfiguration/MonitorRuleDrawer";
import MonitorFilters from "../MonitorsFilters";
import { useStateInSearchParams } from "../../../shared/hooks/state";
import Divider from "../common/Divider";
import { Spacer } from "../common/styles";
import FetchDataLoading from "../../common/loaders/LoadingState";
import { WorkflowConfiguration } from "../common/types";
import { MONITORS_RULE_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { useActiveAgents } from "../../../shared/hooks/useAgents";
import { useDDRumViewAndTimingListener } from "../../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../../shared/constants/datadog";

import ClusterMonitors from "./ClusterMonitors";
import DeleteRuleModal from "./DeleteRuleModal";

const Container = styled.div`
  display: flex;
  margin-block-end: 10rem;
`;

const Clusters = styled.div`
  width: 100%;
`;

const ManageMonitors: React.FC = () => {
  const [cluster, setCluster] = useState<string | undefined>();
  const [ruleType, setRuleType] = useState<string | undefined>();

  const {
    accountConfigurations,
    refreshFromNetwork,
    isFetchingConfigurations,
  } = useWorkflowConfigurations();
  const activeAgents = useActiveAgents();

  const configurationsOfActiveClusters = useMemo(
    () =>
      accountConfigurations?.filter((configuration) =>
        activeAgents?.some(
          (agent) => agent.clusterName === configuration.sensors?.[0]?.cluster
        )
      ),
    [accountConfigurations, activeAgents]
  );

  const filteredMonitors = useMemo(
    () =>
      configurationsOfActiveClusters
        ?.filter(
          (monitor) => cluster === "" || monitor.sensors[0].cluster === cluster
        )
        .filter((monitor) => ruleType === "" || monitor.type === ruleType),
    [configurationsOfActiveClusters, cluster, ruleType]
  );

  const monitorsByCluster = sortBy(
    Object.entries(
      groupBy(filteredMonitors, ({ sensors }) => sensors[0].cluster)
    ),
    ([cluster]) => cluster
  );

  const [isTypeStatic, setIsTypeStatic] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useStateInSearchParams(
    MONITORS_RULE_PARAM_KEY
  );
  const [selectedRule, setSelectedRule] = useState<WorkflowConfiguration>();
  useEffect(() => {
    if (selectedRuleId && !selectedRule) {
      setSelectedRule(
        accountConfigurations?.find((m) => m.id === selectedRuleId)
      );
    }
  }, [accountConfigurations, selectedRule, selectedRuleId]);

  const handleEditRule = useCallback(
    (ruleId: string) => {
      setSelectedRuleId(ruleId);
    },
    [setSelectedRuleId]
  );

  const handleAddRule = useCallback(
    (rule: WorkflowConfiguration | undefined, isTypeStatic?: boolean) => {
      setSelectedRule(rule);
      setIsTypeStatic(isTypeStatic ?? false);
    },
    []
  );

  const [ruleToDelete, setRuleToDelete] = useState<string | null>(null);
  const handleDeleteRule = useCallback((ruleId: string) => {
    setRuleToDelete(ruleId);
  }, []);

  useDDRumViewAndTimingListener({
    isResourceFetching: isFetchingConfigurations,
    viewName: dataDogViewNames.monitorsViewManageMonitors,
    addTimingParams: {
      enable: !isFetchingConfigurations,
    },
  });

  return (
    <MonitorsViewContainer>
      <ResponsiveLayout>
        <MonitorFilters
          clusters={
            activeAgents?.map((agent) => agent.clusterName).sort() ?? []
          }
          cluster={cluster}
          setCluster={setCluster}
          ruleTypes={
            Array.from(new Set(filteredMonitors?.map((m) => m.type))).sort(
              (t1, t2) => t1.toLowerCase().localeCompare(t2.toLowerCase())
            ) ?? []
          }
          ruleType={ruleType}
          setRuleType={setRuleType}
        />
        <Divider />
        <Spacer />
        <Container>
          {isFetchingConfigurations ? (
            <FetchDataLoading dataName="monitor rules" />
          ) : (
            <Clusters>
              {monitorsByCluster.map(([cluster, monitors]) => (
                <ClusterMonitors
                  key={cluster}
                  clusterName={cluster}
                  monitors={monitors}
                  handleAddRule={handleAddRule}
                  handleEditRule={handleEditRule}
                  handleDeleteRule={handleDeleteRule}
                />
              ))}
            </Clusters>
          )}
        </Container>
      </ResponsiveLayout>
      <MonitorRuleDrawer
        isOpen={!!selectedRule}
        initRule={selectedRule}
        handleClose={() => {
          setSelectedRuleId(null);
          setSelectedRule(undefined);
        }}
        refreshMonitors={refreshFromNetwork}
        isTypeStatic={isTypeStatic || !!selectedRuleId}
      />
      <DeleteRuleModal
        ruleToDelete={ruleToDelete}
        handleClose={() => setRuleToDelete(null)}
        refreshMonitors={refreshFromNetwork}
      />
    </MonitorsViewContainer>
  );
};

export default ManageMonitors;
