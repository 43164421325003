export const GENERAL_ERROR_MESSAGE =
  "Encountered an issue while saving the user. Please retry or contact support for assistance.";

export const emailRegex =
  /^(([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+$/;

export type timeTokens = "xMinutes" | "xHours" | "xDays" | "xMonths" | "xYears";

export const formatDistanceLocale = {
  xYears: "{{count}} yr",
  xMonths: "{{count}} mo",
  xMinutes: "{{count}} min",
  xHours: "{{count}} h",
  xDays: "{{count}} d",
};

export const PAST_DATE_PICKET_ERROR = "disablePast";
export const INVALID_DATE_PICKET_ERROR = "invalidDate";
export const MAX_DATE_PICKET_ERROR = "maxDate";
