import { ResourceTableModelRow } from "komodor-types";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useStringifiedStateInSearchParams } from "../../../shared/hooks/state";
import useAtmResourcesListLiveResult from "../../../shared/hooks/ATM/useAtmResourcesListLiveResult";
import { useNamespacesFilter } from "../../../shared/hooks/ATM/useNamespacesAtm";
import { useParseAtmResult } from "../../../shared/hooks/ATM/useParseInspectionResult";
import { useResourceListOfDeletedPods } from "../../Inspection/historicalFetch/useResourceListFromDatabase";
import { KubernetesPodsResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { generateUid } from "../../../shared/utils/generateUid";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import { PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";

import CommonTableContent from "./CommonTableContent";

const PodsDataAtm: React.FC<{
  timeWindow: TimeWindow;
  setPods: Dispatch<SetStateAction<ResourceTableModelRow[]>>;
  cluster: string;
  namespace: string;
  selectedRowsData: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  maxSelectableRows: number;
  showSelectedRowsOnly: boolean;
  agentId: string;
}> = ({
  setPods,
  timeWindow,
  cluster,
  namespace,
  selectedRowsData,
  maxSelectableRows,
  showSelectedRowsOnly,
  agentId,
}) => {
  const [showDeletedPods] = useStringifiedStateInSearchParams<boolean>(
    PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY
  );

  const namespacesFilter = useNamespacesFilter(
    KubernetesPodsResource.Namespaced
  );
  const {
    result,
    status,
    refresh: refreshFromATM,
    errorMessage,
  } = useAtmResourcesListLiveResult({
    resourceKind: KubernetesPodsResource.Kind,
    agentId,
    cluster,
    namespaces: namespacesFilter,
  });
  const { data, fetching, isPaginating } = useParseAtmResult(
    status,
    result?.data,
    result?.isPaginating
  );

  const params = useMemo(
    () => (cluster && namespace ? { clusterName: cluster, namespace } : null),
    [cluster, namespace]
  );
  const {
    data: deletedPodsData,
    fetching: isDeletedPodsFetching,
    refresh: refreshDeletedPods,
    errorMessage: deletedPodsErrorMessage,
  } = useResourceListOfDeletedPods(timeWindow, params, !showDeletedPods);
  const finalResult = useMemo(() => {
    return {
      data: deletedPodsData.concat(data).map((p) => ({
        ...p,
        clusterName: cluster,
        uid: generateUid({
          name: p.name,
          namespace: p.namespace,
          cluster,
        }),
      })),
      fetching: fetching || isDeletedPodsFetching,
      isPaginating,
      errorMessage: errorMessage || deletedPodsErrorMessage,
    };
  }, [
    cluster,
    data,
    deletedPodsData,
    deletedPodsErrorMessage,
    errorMessage,
    fetching,
    isDeletedPodsFetching,
    isPaginating,
  ]);
  const refresh = useCallback(() => {
    refreshFromATM();
    refreshDeletedPods();
  }, [refreshDeletedPods, refreshFromATM]);

  useEffect(() => {
    setPods(finalResult.data);
  }, [finalResult.data, setPods]);

  return (
    <CommonTableContent
      podsResult={{
        pods: finalResult.data,
        fetching: finalResult.fetching,
        refresh,
        errorMessage: finalResult.errorMessage,
        isPaginating: finalResult.isPaginating,
      }}
      selectedRowsData={selectedRowsData}
      cluster={cluster}
      maxSelectableRows={maxSelectableRows}
      showSelectedRowsOnly={showSelectedRowsOnly}
    />
  );
};
export default PodsDataAtm;
