import React from "react";
import styled from "styled-components";

import { blueBrand } from "../../../../../../Colors";
import { H2, TextRegular } from "../../../../../common/typography";
import logo from "../../../../installation/kubernetes/assets/logo.gif";

import { StyledStepContent } from "./styles";

const HelpText = styled.a.attrs({ rel: "noreferrer", target: "_blank" })`
  cursor: pointer;
  color: ${blueBrand};
  text-decoration: none;
`;
const Logo = styled.img.attrs({ src: logo, alt: "logo" })`
  height: 3.5rem;
  width: 5rem;
  object-fit: contain;
`;
const H2BlueBrand = styled(H2)`
  color: ${blueBrand};
  font-size: 24px;
  line-height: 32px;
`;

const WaitingForConnection: React.FC<{
  showHelpMessage: boolean;
  showHelpMessages: (prePopulatedContent?: string | undefined) => void;
}> = ({ showHelpMessage, showHelpMessages }) => (
  <StyledStepContent data-e2e-selector="k8s-integration-waiting-connection">
    <Logo />
    <H2BlueBrand>Waiting for connection</H2BlueBrand>
    {showHelpMessage && (
      <TextRegular>
        Having trouble?{" "}
        <HelpText
          onClick={() => {
            showHelpMessages();
          }}
        >
          Contact Us
        </HelpText>
      </TextRegular>
    )}
  </StyledStepContent>
);

export default WaitingForConnection;
