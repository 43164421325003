import { Operator } from "@/generated/addonsApi";
import { FilterCategory } from "@/shared/hooks/filtersDataInUrl/filtersStateHandlerTypes";

export const filterCategoryToGenericFilter = (
  filtersList: FilterCategory[]
) => {
  return filtersList.map((filter) => ({
    value: filter.values,
    operator: filter.operator as Operator,
    name: filter.category,
  }));
};
