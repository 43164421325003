import React from "react";
import styled from "styled-components";
import { capitalize } from "lodash";

import { textBolderStyle } from "../common/typography";

import {
  SeverityColorMap,
  StaticPreventionResult,
  StaticPreventionSeverity,
} from "./StaticPreventionUtils";

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 0.075rem solid ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.2rem;
  width: min-content;
  height: 1.3rem;
`;

const ScoreAndTextContainer = styled.div`
  ${textBolderStyle}
  font-weight: 700;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`;

const ScoreContainer = styled(ScoreAndTextContainer)`
  padding: 0 0.45rem;
  border-radius: 0.2rem 0 0 0.2rem;
  background-color: white;
`;

const TextContainer = styled(ScoreAndTextContainer)`
  padding: 0 0.5rem 0 0.3rem;
`;

const ScoreBlock: React.FC<{
  backgroundColor: string;
  text: string;
  score: number;
  displayOnZero: boolean;
  e2eSelector: string;
}> = ({ backgroundColor, text, score, displayOnZero, e2eSelector }) => {
  if (!displayOnZero && score === 0) return null;
  return (
    <Container
      data-e2e-selector={e2eSelector}
      backgroundColor={backgroundColor}
    >
      <ScoreContainer>
        <div>{score}</div>
      </ScoreContainer>
      <TextContainer>
        <div>{text}</div>
      </TextContainer>
    </Container>
  );
};

export const ScoreBlockBySeverity: React.FC<{
  severity: StaticPreventionSeverity;
  parsedResults: StaticPreventionResult;
  displayOnZero?: boolean;
  e2eSelectorPrefix: string;
}> = ({ severity, parsedResults, displayOnZero = true, e2eSelectorPrefix }) => {
  let score = 0;
  switch (severity) {
    case StaticPreventionSeverity.danger:
      score = parsedResults.checks.filter(
        (c) => !c.Success && c.Severity === StaticPreventionSeverity.danger
      ).length;
      break;
    case StaticPreventionSeverity.warning:
      score = parsedResults.checks.filter(
        (c) => !c.Success && c.Severity === StaticPreventionSeverity.warning
      ).length;
      break;
    case StaticPreventionSeverity.passed:
      score = parsedResults.checks.filter((c) => c.Success).length;
      break;
    case StaticPreventionSeverity.ignored:
      score = parsedResults.ignoredChecks.length;
      break;
  }
  let text = capitalize(severity);
  if (severity === StaticPreventionSeverity.danger) {
    text = capitalize(StaticPreventionSeverity.critical);
  }

  return (
    <ScoreBlock
      e2eSelector={`${e2eSelectorPrefix}-static-prevention-scores-severity-${severity}`}
      backgroundColor={SeverityColorMap[severity]}
      text={text}
      score={score}
      displayOnZero={displayOnZero}
    />
  );
};

export default ScoreBlock;
