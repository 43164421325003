import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

import { dispatchEvent } from "../../shared/hooks/analytics";
import { blackGreyText, gray10 } from "../../Colors";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import {
  AgentProperties,
  useAgentInfoById,
} from "../../shared/hooks/useAgentInfo/useAgentInfo";
import { H2, TextRegular } from "../common/typography";
import { UpgradeCommands } from "../common/upgradeAgentCta/UpgradeAgentCTA";
import CopyCode from "../integrations/installation/kubernetes/CopyCode";
import { doesAgentVersionSupportResourceList } from "../../shared/utils/agent/agent";
import { useGetHelmInstallationCommand } from "../../shared/hooks/useGetHelmInstallationCommand";

const StyledH2 = styled(H2)`
  font-weight: 500;
`;

const Container = styled.div`
  color: ${blackGreyText};
  font-size: 14px;
`;

const NotUsingHelmContainer = styled.div`
  color: ${gray10};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useInspectionNeedUpgradeAgentWithByInfo = (
  agentInfo: AgentProperties | null
): boolean => {
  const upgradeRequired = useMemo(() => {
    if (!agentInfo) {
      return false;
    }
    return !doesAgentVersionSupportResourceList(agentInfo);
  }, [agentInfo]);
  return upgradeRequired;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useInspectionNeedUpgradeAgent = (
  agentId: string | undefined,
  cluster: string | undefined
): [boolean, AgentProperties | null] => {
  const { agentProperties: agentInfo } = useAgentInfoById(agentId, cluster);
  const upgradeRequired = useInspectionNeedUpgradeAgentWithByInfo(agentInfo);
  return [upgradeRequired, agentInfo];
};

const InspectionUpgradeRequired = (): JSX.Element => {
  useEffect(() => {
    dispatchEvent(
      AnalyticEvents.Inspection.Inspection_resource_list_upgrade_agent
    );
  }, []);
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_ENABLE_ACTIONS
  );
  const docsStr = useGetHelmInstallationCommand(UpgradeCommands.UPGRADE_DOCS);
  return (
    <Container>
      <StyledH2>Agent Update Required</StyledH2>
      <br />
      <TextRegular>
        The following command will update your Komodor agent
      </TextRegular>
      <CopyCode value={commandStr} width="34rem" />
      <br />
      <NotUsingHelmContainer>
        <div>Not using helm?</div>
        <div>
          Read about other ways to install our agent in our{" "}
          <a rel="noreferrer" target="_blank" href={docsStr}>
            Installation Guide
          </a>
        </div>
      </NotUsingHelmContainer>
    </Container>
  );
};

export default InspectionUpgradeRequired;
