import { parseISO } from "date-fns";

import { Limit_RangesFragment } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort, withServices } from "../groupEvents";

import { CommonEventBase, CommonEventGroup } from "./commonEvents";
import { limitRangeType } from "./types";

const toLimitRangeEvent = (
  e: Limit_RangesFragment,
  serviceId: string
): CommonEventBase => ({
  id: e.id,
  serviceId,
  action: "",
  eventTime: parseISO(e.eventTime),
  type: limitRangeType,
  name: e.name,
  namespace: e.namespace,
  clusterName: e.clusterName,
});

export default class LimitRangeEventGroup extends CommonEventGroup {
  constructor(e: CommonEventBase) {
    super(e, "Limit Range");
  }
}

export const groupLimitRangeEvents = (
  events: Limit_RangesFragment[],
  serviceId: string
): LimitRangeEventGroup[] =>
  mapAndSort(withServices(events, serviceId), toLimitRangeEvent).map(
    (e) => new LimitRangeEventGroup(e)
  );
