import { AccountPlan } from "komodor-types";

import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import useUserMetadata from "../../shared/hooks/useUserMetadata/useUserMetadata";

const useIsFreeTier = (): boolean => {
  const { accountPlan } = useUserMetadata();
  const { freemiumFlow } = useOverridableFlags();
  if (!freemiumFlow) return false;
  return accountPlan === AccountPlan.free;
};

export default useIsFreeTier;
