import { useEffect } from "react";

import { defaultSelectedViolationFilters } from "../../../constants/violationFiltersConstants";

import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const useSetDefaultFiltersInUrl = () => {
  const [dataInUrl, setDataInUrl] = useStateInUrl();

  useEffect(() => {
    if (dataInUrl.filters === undefined) {
      setDataInUrl({ filters: defaultSelectedViolationFilters }, true);
    }
  }, [dataInUrl.filters, setDataInUrl]);
};
