import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  Tag,
  Typography,
} from "@komodorio/design-system/deprecated";

import { useQueryRefreshWithVariables } from "../../../../shared/hooks/useQueryWithVariables";
import { useFetchServiceDescribeQuery } from "../../../../generated/graphql";
import Divider from "../../../monitorsView/common/Divider";
import { LinesLoader } from "../../../common/loaders/Line";
import { useAgentInfoById } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import UpgradeAgentInstruction from "../../../common/upgradeAgentCta/UpgradeAgentInstruction";
import upgradeEmojiUrl from "../../../common/upgradeAgentCta/upgrade.svg";
import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { doesAgentVersionSupportServiceDescribe } from "../../../../shared/utils/agent/agent";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import useAgentTaskExecution from "../../../../shared/hooks/useAgentTaskExecution/useAgentTaskExecution";
import { useGetContainerImages } from "../../../ResourceView/headers/utils/hooks/useGetContainerImages";
import { useGetImageLabel } from "../../../ResourceView/headers/utils/hooks/useGetImageLabel";

import {
  ServiceDescribeFailed,
  ServiceDescribeSuccess,
} from "./ServiceDescribeFailedAndSuccess";
import { RefreshButton } from "./RefreshButton";

const TASK_TYPE = "service-describe";
const MAX_RETRIES = 5;
const UPGRADE_AGENT_SUGGESTION = `Upgrade the agent to enjoy the new describe view`;

const Tags = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-block-start: 1rem;
`;

const StyledModalContent = styled(ModalContent)`
  overflow-y: auto;
  height: inherit;
`;

const CustomUpgradeModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin: 1rem auto auto auto;
  width: auto;
  padding: 1rem;
  gap: 1rem;
  background-color: white;
`;

const CustomHeadline = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 140.3%;
  text-align: center;
  color: #6e7a96;
  width: 24rem;
`;

interface DescribeModalProps {
  isOpen: boolean;
  handleClose: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  startMeasureLatency: number;
  setStartMeasureLatency: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  lastDeployEndDate: Date | undefined;
}

const DescribeModal: React.FC<DescribeModalProps> = ({
  isOpen,
  handleClose,
  resourceId,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  startMeasureLatency,
  setStartMeasureLatency,
  lastDeployEndDate,
}) => {
  const { servicedescribeRetries } = useOverridableFlags();
  const maxRetries = useMemo(
    () => (servicedescribeRetries as number) || MAX_RETRIES,
    [servicedescribeRetries]
  );

  const { format } = useDateFormatter();

  const { agentProperties: agentInfo } = useAgentInfoById(agentId);
  const images = useGetContainerImages({
    serviceId: resourceId,
  });
  const imageLabel = useGetImageLabel(images);
  const [retries, setRetries] = useState(0);
  const isAgentAbleToSendTasks =
    doesAgentVersionSupportServiceDescribe(agentInfo);

  const executeTaskVars = useMemo(() => {
    if (!agentId || !resourceType || !isAgentAbleToSendTasks) {
      return null;
    }
    return {
      agentId: agentId,
      type: TASK_TYPE,
      data: {
        namespace: namespace,
        serviceName: resourceName,
        serviceType: resourceType.toLowerCase(),
      },
    };
  }, [agentId, isAgentAbleToSendTasks, namespace, resourceName, resourceType]);
  const [
    fetchPayloadVars,
    failureMessage,
    executeTaskCallback,
    setTaskTimeoutCallback,
  ] = useAgentTaskExecution(executeTaskVars);

  const closeModalCallback = useCallback(() => {
    handleClose();
    setRetries(0);
  }, [handleClose]);

  const [serviceDescribeRes, refresh] = useQueryRefreshWithVariables(
    useFetchServiceDescribeQuery,
    fetchPayloadVars
  );

  const refreshCallback = useCallback(() => {
    dispatchEvent(AnalyticEvents.ServiceView.ServiceDescribe_Refresh, {
      serviceId: resourceId,
    });
    executeTaskCallback();
    setRetries(0);
    setStartMeasureLatency(performance.now());
  }, [executeTaskCallback, resourceId, setStartMeasureLatency]);

  useEffect(() => {
    if (
      failureMessage ||
      serviceDescribeRes?.service_description?.length ||
      !isOpen
    )
      return;
    if (retries > maxRetries) {
      setTaskTimeoutCallback();
      return;
    }
    const timeout = setTimeout(() => {
      if (!serviceDescribeRes) {
        refresh();
      }
      setRetries(retries + 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    failureMessage,
    isOpen,
    maxRetries,
    refresh,
    retries,
    serviceDescribeRes,
    setTaskTimeoutCallback,
  ]);

  const title = `${resourceType} Describe: ${cluster}/${namespace}/${resourceName}`;
  const lastUpdated = useMemo(() => {
    if (!lastDeployEndDate) {
      return null;
    }
    return format(lastDeployEndDate);
  }, [format, lastDeployEndDate]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalCallback}
      width="50%"
      height="80%"
    >
      <ModalHeader>
        <RefreshButton onClick={refreshCallback} />
        <Typography variant="headline">{title}</Typography>
        <Tags>
          {imageLabel !== undefined && <Tag>{imageLabel.value}</Tag>}
          {lastUpdated && (
            <Tag>
              <Typography>
                Last updated: <b>{lastUpdated}</b>{" "}
              </Typography>
            </Tag>
          )}
        </Tags>
      </ModalHeader>
      <Divider />
      <StyledModalContent>
        {!isAgentAbleToSendTasks ? (
          <CustomUpgradeModal>
            <img src={upgradeEmojiUrl} />
            <CustomHeadline>{UPGRADE_AGENT_SUGGESTION}</CustomHeadline>
            <UpgradeAgentInstruction />
            <Button variant="secondary" onClick={closeModalCallback}>
              Done!
            </Button>
          </CustomUpgradeModal>
        ) : !serviceDescribeRes && retries < maxRetries ? (
          <LinesLoader />
        ) : failureMessage || retries >= maxRetries ? (
          <ServiceDescribeFailed
            failureMessage={failureMessage}
            serviceId={resourceId}
            serviceType={resourceType}
          />
        ) : !serviceDescribeRes?.service_description?.length ? (
          <LinesLoader />
        ) : (
          <ServiceDescribeSuccess
            serviceDescribeRes={serviceDescribeRes}
            serviceId={resourceId}
            serviceType={resourceType}
            startMeasureLatency={startMeasureLatency}
          />
        )}
      </StyledModalContent>
    </Modal>
  );
};

export default DescribeModal;
