import React, { useId, useMemo } from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import { palette } from "@komodorio/design-system";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import {
  ClusterForRbacSync,
  filterClustersOutOfSync,
  filterClustersRequiringUpdate,
  filterValidClusters,
} from "../utils/rbacClusterStatusSync";

import {
  BorderedBox,
  HalfWidthBox,
  LTRBoxSpaced,
  VerticallyDividedBox,
} from "./containers";
import {
  tooltipMessageTemplate_ClusterEncounteredErrorWhileSyncing,
  TooltipMessage_ClusterVersionOutOfDate,
  TooltipMessage_ClusterOutOfSync,
  TooltipMessage_InfoWhatIsDisplayed,
} from "./tooltips";
import { UpdateAgentForKubeconficRbacClusterSyncAlert } from "./AgentRequiresUpgradeAlertForKubeconfig";
import {
  AvailableClusterItem,
  ClusterListItemProps,
  SelectedClusterItem,
} from "./ClusterListItem";

const Spacer = styled.div`
  height: 0.5rem;
`;

type SelectClustersListProps = {
  availableClusters: ClusterForRbacSync[];
  selectedClusters: string[];
  onSelectCluster: (cluster: string) => void;
  onUnselectCluster: (cluster: string) => void;
  selectAndReplaceMultiple: (clusters: ClusterForRbacSync[]) => void;
  unselectAll: () => void;
};

export const SelectClustersList: React.FC<SelectClustersListProps> = ({
  availableClusters,
  selectedClusters,
  onSelectCluster,
  onUnselectCluster,
  selectAndReplaceMultiple,
  unselectAll,
}) => {
  const [searchPrompt, setSearchPrompt] = React.useState("");

  const clustersAvailableToSelect = useMemo(() => {
    return availableClusters
      .filter((cluster) => !cluster.hasNoPoliciesMatchingScope) // We don't want to show clusters that have no policies matching the scope
      .filter((cluster) => !selectedClusters.includes(cluster.name))
      .filter((cluster) => cluster.name.includes(searchPrompt))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [availableClusters, selectedClusters, searchPrompt]);

  const filteredClustersSelected = useMemo(() => {
    return availableClusters
      .filter((cluster) => selectedClusters.includes(cluster.name))
      .filter((cluster) => cluster.name.includes(searchPrompt));
  }, [availableClusters, selectedClusters, searchPrompt]);

  const validClusters = useMemo(() => {
    return filterValidClusters(clustersAvailableToSelect);
  }, [clustersAvailableToSelect]);

  const clustersThatRequireVersionUpdate = useMemo(() => {
    return availableClusters.filter((cluster) => cluster.requiresAgentUpgrade);
  }, [availableClusters]);

  return (
    <Box
      sx={{
        height: clustersThatRequireVersionUpdate.length > 0 ? "60%" : "70%",
      }}
    >
      <UpdateAgentForKubeconficRbacClusterSyncAlert
        clusters={clustersThatRequireVersionUpdate}
      />
      <Typography variant="body2">
        Select the clusters you want to include in the Kubeconfig file
      </Typography>
      <Spacer />
      <SearchField
        onSearch={setSearchPrompt}
        placeholder={"Search clusters"}
        value={searchPrompt}
      />
      <Spacer />
      <VerticallyDividedBox>
        <HalfWidthBox>
          <LTRBoxSpaced>
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              Available clusters ({validClusters.length})
              <Tooltip
                title={TooltipMessage_InfoWhatIsDisplayed}
                placement={"top"}
              >
                <InfoOutlined sx={{ marginLeft: "6px" }} color={"action"} />
              </Tooltip>
            </Typography>
            <Button
              variant="text"
              size="medium"
              onClick={() => selectAndReplaceMultiple(validClusters)}
            >
              Select all
            </Button>
          </LTRBoxSpaced>
          <Spacer />
          <SelectableClusterList
            clusters={clustersAvailableToSelect}
            onSelectCluster={onSelectCluster}
            ItemComponent={AvailableClusterItem}
          />
        </HalfWidthBox>
        <HalfWidthBox>
          <LTRBoxSpaced>
            <Typography variant="h5">
              Selected clusters ({filteredClustersSelected.length})
            </Typography>
            <Button variant="text" size="medium" onClick={unselectAll}>
              Remove all
            </Button>
          </LTRBoxSpaced>
          <Spacer />
          <SelectableClusterList
            clusters={filteredClustersSelected}
            onSelectCluster={onUnselectCluster}
            emptyStateText={"No clusters selected"}
            ItemComponent={SelectedClusterItem}
          />
        </HalfWidthBox>
      </VerticallyDividedBox>
    </Box>
  );
};

type SelectableClusterListProps = {
  clusters: ClusterForRbacSync[];
  estimatedCount?: number;
  onSelectCluster: (cluster: string) => void;
  emptyStateText?: string;

  ItemComponent: React.FC<ClusterListItemProps>;
};
const SelectableClusterList: React.FC<SelectableClusterListProps> = ({
  clusters,
  onSelectCluster,
  emptyStateText,
  ItemComponent,
}) => {
  const listId = useId();

  const categorizedClusters = useMemo((): {
    validClusters: ClusterForRbacSync[];
    clustersRequiringUpdate: ClusterForRbacSync[];
    clustersOutOfSync: ClusterForRbacSync[];
    restOfClusters: ClusterForRbacSync[];
    hasDisabledClusters: boolean;
  } => {
    const validClusters = filterValidClusters(clusters);
    const clustersRequiringUpdate = filterClustersRequiringUpdate(clusters);
    const clustersOutOfSync = filterClustersOutOfSync(clusters);

    const restOfClusters = clusters.filter(
      // just in case we didn't cover everything
      (cluster) =>
        !validClusters.includes(cluster) &&
        !clustersRequiringUpdate.includes(cluster) &&
        !clustersOutOfSync.includes(cluster)
    );

    return {
      validClusters,
      clustersRequiringUpdate,
      clustersOutOfSync,
      restOfClusters,

      hasDisabledClusters:
        clustersRequiringUpdate.length > 0 ||
        clustersOutOfSync.length > 0 ||
        restOfClusters.length > 0,
    };
  }, [clusters]);

  return (
    <BorderedBox sx={{ overflow: "auto" }}>
      {!categorizedClusters.validClusters.length && (
        <Typography color={palette.gray[500]}>
          {emptyStateText || "No more available clusters"}
        </Typography>
      )}
      {categorizedClusters.validClusters.map((cluster, index) => (
        <ItemComponent
          cluster={cluster}
          onClick={onSelectCluster}
          key={`${listId}-valid-${index}`}
        />
      ))}
      {categorizedClusters.hasDisabledClusters && (
        <>
          <Divider />
          {categorizedClusters.clustersOutOfSync.map((cluster, index) => (
            <ItemComponent
              cluster={cluster}
              onClick={onSelectCluster}
              key={`${listId}-outofsync-${index}`}
              disabled
              errorMessage={
                cluster.syncError
                  ? tooltipMessageTemplate_ClusterEncounteredErrorWhileSyncing(
                      cluster.syncError
                    )
                  : TooltipMessage_ClusterOutOfSync
              }
            />
          ))}
          {categorizedClusters.clustersRequiringUpdate.map((cluster, index) => (
            <ItemComponent
              cluster={cluster}
              onClick={onSelectCluster}
              key={`${listId}-requireupdate-${index}`}
              disabled
              errorMessage={TooltipMessage_ClusterVersionOutOfDate}
            />
          ))}
          {categorizedClusters.restOfClusters.map((cluster, index) => (
            <ItemComponent
              cluster={cluster}
              onClick={onSelectCluster}
              key={`${listId}-other-${index}`}
              disabled
              errorMessage={undefined}
            />
          ))}
        </>
      )}
    </BorderedBox>
  );
};
