import styled from "styled-components";
import React from "react";
import { palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import { PodEvent } from "../../../monitorsView/workflowRunView/CommonTypes";
import { StyledFontTableMin } from "../styles";

import {
  getTextColor,
  isWarning,
} from "@/components/common/table/PodEventsTable/podEventsTableUtils";
import { useFormatPodEventTimestamp } from "@/components/common/table/PodEventsTable/podEventsTableHooks";

const BorderedWrapper = styled.div<{ isHeightRestricted: boolean }>`
  border: 1px solid #c9cbcf;
  border-radius: 4px;
  margin: 1rem 0 0;
  overflow-y: auto;
  min-height: 8rem;
  ${({ isHeightRestricted }) => isHeightRestricted && `max-height: 17.5rem;`}
`;

const EventTd = styled.td<{ isWarning?: boolean }>`
  ${({ isWarning }) =>
    `border-left: 3px solid ${
      isWarning ? palette.pink[400] : palette.gray[300]
    };`}
`;

const EventTr = styled.tr`
  background-color: ${theme.foreground.fgWhite};
`;

const PodEventsTable: React.FC<{
  podEvents: PodEvent[];
  isHeightRestricted?: boolean;
}> = ({ podEvents, isHeightRestricted = true }) => {
  const formatTimestamp = useFormatPodEventTimestamp();

  return (
    <BorderedWrapper isHeightRestricted={isHeightRestricted}>
      <StyledFontTableMin aria-label="pod-event-table">
        <thead>
          <tr>
            <th>
              <Typography bold color={theme.foreground.fgSubtle}>
                Reason
              </Typography>
            </th>
            <th>
              <Typography bold color={theme.foreground.fgSubtle}>
                Last Timestamp
              </Typography>
            </th>
            <th>
              <Typography bold color={theme.foreground.fgSubtle}>
                Message
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {podEvents.map((podEvent, i) => {
            const textColor = getTextColor(
              isWarning(podEvent.type, podEvent.reason)
            );

            return (
              <EventTr key={i}>
                <EventTd isWarning={isWarning(podEvent.type, podEvent.reason)}>
                  <Typography color={textColor}>{podEvent.reason}</Typography>
                </EventTd>
                <td>
                  <Typography>{formatTimestamp(podEvent)}</Typography>
                </td>
                <td>
                  <Typography color={textColor}>{podEvent.message}</Typography>
                </td>
              </EventTr>
            );
          })}
        </tbody>
      </StyledFontTableMin>
    </BorderedWrapper>
  );
};
export default PodEventsTable;
