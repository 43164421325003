import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  ServicesObject,
  useFetchAllServices,
} from "../../components/servicesView/serviceFetchHooks/serviceFetchHook";
import { ServiceInfo } from "../types/ServiceInfo";
import { FetchModeState } from "../../components/servicesView/serviceFetchHooks/types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  initialStateAsDict,
  ServicesDictionary,
  useScopeServicesObject,
} from "./useKomodorServicesAsDictionary";

import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";

type ExpandedServiceState = {
  unscopedServices: ServiceInfo[] | undefined;
  setServicesScope: (serviceIds?: string[]) => void;
  servicesAsDictionary: ServicesDictionary;
  isFetchingServicesData: boolean;
  setIsFetchingServicesData: (val: boolean) => void;
  scopeHasBeenLoaded: boolean;
  fetchState: FetchModeState;
};

type ServicesState = ServicesObject & ExpandedServiceState;

const initialState: ServicesState = {
  services: undefined,
  jobs: undefined,
  all: undefined,
  servicesAsDictionary: initialStateAsDict,
  unscopedServices: undefined,
  setServicesScope: () => undefined,
  isFetchingServicesData: false,
  setIsFetchingServicesData: () => undefined,
  scopeHasBeenLoaded: false,
  fetchState: {
    services: "full",
    jobs: "full",
  },
};
const ServicesContext = createContext<ServicesState>(initialState);

export const ServicesProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const isAnonymousUser = useIsAnonymousUser();
  if (isAnonymousUser) {
    return <AnonymousServicesProvider>{children}</AnonymousServicesProvider>;
  }
  return (
    <AuthenticatedServicesProvider>{children}</AuthenticatedServicesProvider>
  );
};

const AuthenticatedServicesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { servicesLists, servicesAsDictionary, fetchState } =
    useFetchAllServices();
  const [servicesScope, setServicesScope] = useState<string[]>();
  const [isFetchingServicesData, setIsFetchingServicesData] =
    useState<boolean>(true);

  const scopeServicesObject = useScopeServicesObject();

  const getFullServicesObj = useCallback(() => {
    if (servicesScope?.length) {
      return scopeServicesObject(servicesScope, servicesAsDictionary);
    }
    return {
      ...servicesLists,
      servicesAsDictionary,
    };
  }, [scopeServicesObject, servicesAsDictionary, servicesLists, servicesScope]);

  const allFilteredServices = useMemo(() => {
    return getFullServicesObj();
  }, [getFullServicesObj]);

  const state: ServicesState = useMemo(
    () => ({
      ...allFilteredServices,
      servicesAsDictionary: {
        ...allFilteredServices.servicesAsDictionary,
        unscopedServices: servicesAsDictionary.all,
      },
      unscopedServices: servicesLists.all,
      setServicesScope,
      isFetchingServicesData,
      setIsFetchingServicesData,
      scopeHasBeenLoaded: (servicesScope?.length ?? 0) > 0,
      fetchState,
    }),
    [
      allFilteredServices,
      fetchState,
      isFetchingServicesData,
      servicesAsDictionary.all,
      servicesLists.all,
      servicesScope?.length,
    ]
  );

  return (
    <ServicesContext.Provider value={state}>
      {children}
    </ServicesContext.Provider>
  );
};

const AnonymousServicesProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ServicesContext.Provider value={initialState}>
      {children}
    </ServicesContext.Provider>
  );
};

const useKomodorServices = (): ServicesState => useContext(ServicesContext);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export default useKomodorServices;
