import React, { useState } from "react";
import { palette } from "@komodorio/design-system";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { AlertTriangleOutlined24 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useDeleteAppViewByIdMutation } from "../../../../../../../../../generated/graphql";
import { useAppViewsStore } from "../../../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  onAppViewDeletionSelector,
  selectedAppViewIdSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { useAppViewActionsStateContext } from "./AppViewActionsStateProvider";

const FlexRow = styled.div`
  display: flex;
  gap: 1rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PinkCircle = styled.div`
  background: ${palette.pink[100]};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppViewDeletionDialog: React.FC = () => {
  const navigate = useNavigate();
  const { deletionDialogOpen, setDeletionDialogOpen } =
    useAppViewActionsStateContext();
  const [, deleteAppViewById] = useDeleteAppViewByIdMutation();
  const [deletionFailed, setDeletionFailed] = useState(false);
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const onAppViewDeletion = useAppViewsStore(onAppViewDeletionSelector);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);

  const close = () => {
    setDeletionFailed(false);
    setDeletionDialogOpen(false);
  };

  const deleteCurrentlySelectedAppView = async () => {
    if (!selectedAppViewId) {
      return;
    }

    const deletionResult = await deleteAppViewById({ id: selectedAppViewId });

    if (deletionResult.error) {
      // TODO: Notify the user if the deletion didn't go down as planned.
      //  https://app.clickup.com/t/860pfuvme
      setDeletionFailed(true);
      return;
    }

    onAppViewDeletion(selectedAppViewId);
    setSelectedAppViewId(undefined);

    navigate("/");
  };

  return (
    <Modal
      isOpen={deletionDialogOpen}
      onRequestClose={close}
      width="400px"
      aria={{
        labelledby: "app-view-deletion-dialog-header",
      }}
    >
      <ModalHeader>
        <Typography
          variant="headline"
          size="small"
          id="app-view-deletion-dialog-header"
        >
          Delete workspace
        </Typography>
      </ModalHeader>
      <ModalContent>
        <FlexRow>
          <PinkCircle>
            <AlertTriangleOutlined24 color={palette.pink[500]} />
          </PinkCircle>
          <FlexColumn>
            <Typography size="medium">
              Are you sure you want to delete the workspace?
            </Typography>
            {deletionFailed && (
              <Typography size="medium" color={palette.pink[500]}>
                App deletion failed, please try again
              </Typography>
            )}
          </FlexColumn>
        </FlexRow>
      </ModalContent>
      <ModalActions>
        <Button size="small" variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="danger"
          onClick={deleteCurrentlySelectedAppView}
        >
          Delete
        </Button>
      </ModalActions>
    </Modal>
  );
};
