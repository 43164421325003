import React from "react";
import { muiColors, muiPalette } from "@komodorio/design-system";
import { Status } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { FlexRow } from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/styles";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";

const { gray } = muiColors;

const ServiceNameContainer = styled.div<{ isDeleted: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 8rem;
  cursor: pointer;
  ${({ isDeleted }) =>
    isDeleted
      ? `
    text-decoration: line-through;
    color: ${muiPalette?.text?.secondary};
    `
      : `
    text-decoration: underline;
    color: ${muiPalette?.text?.primary};
    `}
`;
const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 0.2rem;
`;
const tooltipStyle = {
  tooltip: {
    sx: {
      boxShadow:
        "0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.10)",
      display: "inline-flex",
      padding: "8px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
      backgroundColor: "white",
      color: "black",
    },
  },
};
const ResourceNameTile: React.FC<{
  resourceKind: string;
  resourceName: string;
  onResourceClick?: () => void;
  ariaLabel?: string;
  deletedAt?: Date;
}> = ({
  deletedAt,
  resourceKind,
  resourceName,
  onResourceClick,
  ariaLabel,
}) => {
  const { format } = useDateFormatter();

  const tooltipContent = deletedAt ? (
    <>
      <Typography variant="body3">
        <b>{resourceName}</b> was deleted on
      </Typography>
      <Typography fontWeight={700} fontSize="12px">
        {format(deletedAt)}
      </Typography>
    </>
  ) : (
    resourceName
  );
  return (
    <CardColumn>
      <Typography variant={"overline2"} color={gray[600]}>
        {resourceKind}
      </Typography>
      <FlexRow>
        <Tooltip
          arrow={false}
          componentsProps={tooltipStyle}
          title={tooltipContent}
          placement="bottom-end"
        >
          <Typography variant={"body2"} fontWeight={500}>
            <ServiceNameContainer
              isDeleted={!!deletedAt}
              onClick={onResourceClick}
              aria-label={ariaLabel}
            >
              {resourceName}
            </ServiceNameContainer>
          </Typography>
        </Tooltip>
        {deletedAt && (
          <Status size="small" uppercase label="deleted" color="neutral" />
        )}
      </FlexRow>
    </CardColumn>
  );
};

export default ResourceNameTile;
