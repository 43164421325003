import { IntegrationType } from "komodor-types";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import {
  LaunchDarkly,
  Sentry,
  Slack,
  Opsgenie,
  PagerDuty,
  GitHub,
  KubernetesTriple,
} from "@komodorio/design-system/icons";

import OpsgenieInstallation from "../installation/opsgenie/OpsgenieInstallation";
import DatadogInstallation from "../installation/datadog/DatadogInstallation";
import SentryInstallation from "../installation/sentry/SentryInstallation";
import NewrelicAlertsInstallation from "../installation/newrelic/NewrelicAlertsInstallation";
import GrafanaInstallation from "../installation/grafana/GrafanaInstallation";
import LaunchDarklyInstallation from "../installation/launchdarkly/LaunchDarklyInstallation";
import GitlabInstallation from "../installation/gitlab/GitlabInstallation";
import PrometheusAlertManagerInstallation from "../installation/prometheus-alert-manager/PrometheusAlertManagerInstallation";
import KubernetesInstallation from "../installation/kubernetes/KubernetesInstallation";
import { CustomEventsInstallation } from "../installation/custom-events/CustomEventsInstallation";
import ArgocdInstallation from "../installation/argocd/ArgocdInstallation";
import { IconProps } from "../../appView/sections/AppView/components/OverviewPage/types/overviewPageTypes";
import {
  ALERT_MANAGER_INTEGRATION_DOCS_LINK,
  DATADOG_INTEGRATION_DOCS_LINK,
  LAUNCH_DARKLY_INTEGRATION_DOCS_LINK,
  OPSGENIE_INTEGRATION_EVENT_ALERT_DOCS_LINK,
  PAGER_DUTY_INTEGRATION_DOCS_LINK,
  SENTRY_INTEGRATION_DOCS_LINK,
} from "../../../shared/constants/docsLinks";
import { setCookie } from "../../../shared/utils/cookie/cookie";
import { SLACK_STATE_KEY } from "../installation/slack/types";

import GitlabLogo from "./logos/gitlab.svg?react";
import OpsgenieLogo from "./logos/opsgenie.svg?react";
import DatadogLogo from "./logos/datadog.svg?react";
import NewrelicLogo from "./logos/newrelic.svg?react";
import GrafanaLogo from "./logos/grafana.svg?react";
import PrometheusGrafanaLogo from "./logos/prometheusGrafana.svg?react";
import CustomEventsLogoLogo from "./logos/custom_events.svg?react";
import ArgoCDLogo from "./logos/ArgoCD.svg?react";
import JiraLogo from "./logos/jira.svg?react";
import getPDInstallationUrl from "./getPDInstallationUrl";
import {
  APIKeyFromId,
  Datadog,
  GenericInfo,
  renderDocs,
  WebhookUrl,
} from "./installed/Info";
import { ModalType } from "./available/ModalFactory";
import { KubernetesIntegrationClusters } from "./installed/KubernetesIntegrationClusters";

import { Installation } from "@/components/integrations/management/installed/useInstallationSubscription";
import {
  datadogConfigGetter,
  getWebhookRecord,
} from "@/components/integrations/management/installed/helpers";

export type Integration = {
  logo: React.FC<IconProps>;
  title: string;
  description: string;
  buttonText?: string;
  onInstallClick?: (newTab?: boolean) => void;
  InstallationComponent?: React.FC<{
    closeModalCallback: () => void;
  }>;
  allowMultiple: boolean;
  hideAvailable?: boolean;
  hideInstalled?: boolean;
  info?: GenericInfo[];
  modalStyle?: React.CSSProperties;
  modalType?: ModalType;
  canDelete?: boolean;
  featureFlag?: string;
  docsLink?: string;
  infoGetter?: (installation: Installation) => Record<string, string> | null;
};

const definitions: Record<IntegrationType, Integration> = {
  [IntegrationType.OPSGENIE_API]: {
    logo: OpsgenieLogo,
    title: "Opsgenie-api",
    description:
      "Allow to fetch opsgenie responders to be able to send triggered monitors. Managed in monitors configuration",
    allowMultiple: false,
    hideInstalled: true,
    hideAvailable: true,
  },
  [IntegrationType.GITLAB]: {
    logo: GitlabLogo,
    title: "GitLab",
    description:
      "View merge requests and commits included in each deploy event",
    InstallationComponent: GitlabInstallation,
    allowMultiple: false,
    modalStyle: {
      background: "#fafbfc",
    },
  },
  [IntegrationType.GITHUB]: {
    logo: GitHub,
    title: "Github",
    description: "View pull requests and commits included in each deploy event",
    onInstallClick(): void {
      window.location.assign(
        "https://github.com/apps/komodor-collector/installations/new"
      );
    },
    allowMultiple: false,
  },
  [IntegrationType.DESKTOP]: {
    logo: KubernetesTriple,
    title: "Desktop",
    description: "Control your kubernetes via desktop",
    allowMultiple: false,
    hideAvailable: true,
    hideInstalled: true,
  },
  [IntegrationType.KUBERNETES]: {
    logo: KubernetesTriple,
    title: "Kubernetes Cluster",
    description:
      "Track your Kubernetes resources and deployments.\nSee new, updated and removed resources on your cluster.",
    buttonText: "Add Cluster",
    InstallationComponent: KubernetesInstallation,
    info: [
      APIKeyFromId,
      KubernetesIntegrationClusters,
      renderDocs(
        "https://github.com/komodorio/helm-charts/tree/master/charts/komodor-agent"
      ),
    ],
    docsLink: "https://docs.komodor.com/docs/integrations/kubernetes",
    allowMultiple: true,
    modalType: ModalType.Empty,
    canDelete: true,
  },
  [IntegrationType.PAGERDUTY]: {
    logo: PagerDuty,
    title: "PagerDuty",
    description: "Track incidents as they are triggered on Pager Duty.",
    onInstallClick(): void {
      getPDInstallationUrl().then((url) => window.location.assign(url));
    },
    allowMultiple: false,
    info: [renderDocs(PAGER_DUTY_INTEGRATION_DOCS_LINK)],
    docsLink: PAGER_DUTY_INTEGRATION_DOCS_LINK,
  },
  [IntegrationType.SLACK]: {
    logo: Slack,
    title: "Slack",
    description: "Track conversations on selected services.",
    onInstallClick(newTab = false): void {
      const state = uuidv4();
      setCookie(SLACK_STATE_KEY, state, 5);
      const url = `https://slack.com/oauth/v2/authorize?${new URLSearchParams({
        client_id: "1183152337187.3049965402915",
        // https://api.slack.com/apps/A031FUDBUSX/oauth
        scope: "channels:join,channels:read,chat:write,chat:write.customize",
        user_scope: "",
        redirect_uri: `${window.location.origin}/installation/slack`,
        state: state,
      })}`;
      if (newTab) {
        window.open(url);
      } else {
        window.location.assign(url);
      }
    },
    allowMultiple: false,
    hideAvailable: false,
  },
  [IntegrationType.SENTRY]: {
    logo: Sentry,
    title: "Sentry",
    description: "Track errors and issues captured by Sentry.",
    InstallationComponent: SentryInstallation,
    allowMultiple: false,
    info: [renderDocs(SENTRY_INTEGRATION_DOCS_LINK)],
    modalType: ModalType.Steps,
    docsLink: SENTRY_INTEGRATION_DOCS_LINK,
  },
  [IntegrationType.OPSGENIE]: {
    logo: Opsgenie,
    title: "Opsgenie",
    description: "Track incidents as they are triggered on Opsgenie.",
    InstallationComponent: OpsgenieInstallation,
    info: [WebhookUrl, renderDocs(OPSGENIE_INTEGRATION_EVENT_ALERT_DOCS_LINK)],
    allowMultiple: false,
    docsLink: OPSGENIE_INTEGRATION_EVENT_ALERT_DOCS_LINK,
    infoGetter: getWebhookRecord,
  },
  [IntegrationType.DATADOG]: {
    logo: DatadogLogo,
    title: "Datadog",
    description: "Enrich services dependencies using Datadog APM.",
    InstallationComponent: DatadogInstallation,
    allowMultiple: false,
    info: [Datadog, renderDocs(DATADOG_INTEGRATION_DOCS_LINK)],
    canDelete: true,
    docsLink: DATADOG_INTEGRATION_DOCS_LINK,
    infoGetter: datadogConfigGetter,
  },
  [IntegrationType.NEWRELIC_ALERTS]: {
    hideAvailable: false,
    hideInstalled: false,
    logo: NewrelicLogo,
    InstallationComponent: NewrelicAlertsInstallation,
    title: "New Relic Alerts",
    description: "Get alerts from New Relic",
    allowMultiple: false,
  },
  [IntegrationType.GRAFANA_ALERTS]: {
    logo: GrafanaLogo,
    title: "Grafana",
    description: "Get alerts from Grafana.",
    InstallationComponent: GrafanaInstallation,
    allowMultiple: true,
    info: [WebhookUrl, renderDocs(ALERT_MANAGER_INTEGRATION_DOCS_LINK)],
    hideAvailable: true,
    docsLink: ALERT_MANAGER_INTEGRATION_DOCS_LINK,
    infoGetter: getWebhookRecord,
  },
  [IntegrationType.JIRA]: {
    hideAvailable: true,
    hideInstalled: true,
    logo: JiraLogo,
    title: "JIRA",
    description: "JIRA issue events",
    allowMultiple: true,
  },
  [IntegrationType.LAUNCHDARKLY]: {
    hideAvailable: false,
    hideInstalled: false,
    logo: LaunchDarkly,
    title: "LaunchDarkly",
    description: "Track the system’s feature flags creation and updates",
    InstallationComponent: LaunchDarklyInstallation,
    allowMultiple: true,
    info: [WebhookUrl, renderDocs(LAUNCH_DARKLY_INTEGRATION_DOCS_LINK)],
    docsLink: LAUNCH_DARKLY_INTEGRATION_DOCS_LINK,
    infoGetter: getWebhookRecord,
  },
  [IntegrationType.PROMETHEUS_ALERT_MANAGER]: {
    hideAvailable: false,
    hideInstalled: false,
    logo: PrometheusGrafanaLogo,
    title: "Prometheus / Grafana Alert Manager",
    description: "Get alerts from alert manager",
    InstallationComponent: PrometheusAlertManagerInstallation,
    allowMultiple: true,
    info: [WebhookUrl, renderDocs(ALERT_MANAGER_INTEGRATION_DOCS_LINK)],
    canDelete: true,
    docsLink: ALERT_MANAGER_INTEGRATION_DOCS_LINK,
    infoGetter: getWebhookRecord,
  },
  [IntegrationType.CUSTOM_EVENTS]: {
    logo: CustomEventsLogoLogo,
    title: "Custom Events",
    hideAvailable: false,
    hideInstalled: false,
    description: "Enrich the Komodor timeline with your own custom events",
    InstallationComponent: CustomEventsInstallation,
    allowMultiple: false,
    modalType: ModalType.Empty,
    featureFlag: "showCustomEvents",
  },
  [IntegrationType.ARGOCD]: {
    logo: ArgoCDLogo,
    title: "Argo CD - External Cluster Support",
    hideAvailable: false,
    hideInstalled: false,
    description: "Track Argo deployments from external clusters",
    InstallationComponent: ArgocdInstallation,
    allowMultiple: false,
    modalType: ModalType.Empty,
    featureFlag: "argocdIntegration",
  },
};

export default definitions;
