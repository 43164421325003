import React, { createContext, useCallback, useContext, useState } from "react";

import {
  clearMarketplaceActivationState,
  getMarketplaceActivationState,
} from "./localState";
import { useActivateMarketplaceAccount } from "./useActivateMarketplace";

export interface MarketplaceActivator {
  isCandidateForMarketplaceActivation: boolean;
  hasSentCallback: boolean;
  sendMarketplaceAccountActivationCallback: () => Promise<void>;
}

const defaultContext: MarketplaceActivator = {
  isCandidateForMarketplaceActivation: false,
  hasSentCallback: false,
  sendMarketplaceAccountActivationCallback: async () => undefined,
};

const context = createContext(defaultContext);

export const MarketplaceActivatorProvider: React.FC<{
  accessToken: string | null;
  children?: React.ReactNode;
}> = ({ accessToken, children }) => {
  const [hasSentCallback, setHasSentCallback] = useState(false);
  const [
    isCandidateForMarketplaceActivation,
    setIsCandidateForMarketplaceActivation,
  ] = useState(true);
  const activateMarketplaceAccount = useActivateMarketplaceAccount();

  const sendMarketplaceAccountActivationCallback =
    useCallback(async (): Promise<void> => {
      if (
        hasSentCallback ||
        !isCandidateForMarketplaceActivation ||
        !accessToken
      )
        return;
      const activationState = getMarketplaceActivationState();
      if (!activationState) {
        setIsCandidateForMarketplaceActivation(false);
        return;
      }

      try {
        const resp = await activateMarketplaceAccount(
          activationState,
          accessToken
        );
        setHasSentCallback(true);
        setIsCandidateForMarketplaceActivation(false);

        if (resp.ok) {
          // On success - clear the activation state so it doesn't trigger again
          // On failure - do nothing, it will retry on next hard-refresh when this provider resets
          // This is so we are not sending too many requests at once
          clearMarketplaceActivationState();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }, [
      accessToken,
      activateMarketplaceAccount,
      hasSentCallback,
      isCandidateForMarketplaceActivation,
    ]);

  const marketplaceContext: MarketplaceActivator = {
    isCandidateForMarketplaceActivation,
    hasSentCallback,
    sendMarketplaceAccountActivationCallback,
  };

  const value = !accessToken ? defaultContext : marketplaceContext;
  return <context.Provider value={value}>{children}</context.Provider>;
};

const useMarketplaceActivator = (): MarketplaceActivator => useContext(context);
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export default useMarketplaceActivator;
