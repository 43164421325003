/* eslint-disable max-lines */
import {
  AccessControl16,
  Applications16,
  Workflows16,
  Boxes16,
  Clock16,
  Cog16,
  Gauge16,
  Helm16,
  Integration16,
  Kubernetes16,
  List16,
  Network16,
  Nodes16,
  QuestionCircle16,
  Radar16,
  Storage16,
  Workloads16,
  Wrench16,
} from "@komodorio/design-system/icons";
import Newspaper from "@mui/icons-material/Newspaper";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import Language from "@mui/icons-material/LanguageOutlined";
import React, { useMemo } from "react";
import { AccountPlan } from "komodor-types";

import CostIcon from "../assets/costIcon.svg?react";
import driftAnalysisIconUrl from "../assets/driftAnalysisIcon.svg";
import k8sAddonsCertManagerIcon from "../assets/k8sAddonCertManager.svg";
import {
  COST_OPTIMIZATION,
  EVENTS_ROUTE,
  INTEGRATIONS_ROUTE,
  JOBS,
  SERVICES,
  MONITORS_ROUTE as MONITORS_DEFAULT_ROUTE,
  AGENTS_ROUTE,
  RELIABILITY,
  ARGO_WORKFLOWS,
  AUDIT_ROUTE,
  CLUSTERS_ROUTE,
  DRIFT_ANALYSIS_DEMO_ROUTE,
  K8S_ADDONS_CERT_MANAGER_ROUTE,
  WORKFLOWS_ROUTE,
  EXPERT_OVERVIEW,
  SETTINGS_ROUTE,
  USER_PROFILE_ROUTE,
  EXTERNAL_DNS_DEMO_ROUTE,
} from "../../routes/routes";
import { useAppBarStateContext } from "../internal/appBarStateContext";
import { getRoutePathFromKubernetesResource } from "../../Inspection/inspectionConfiguration/SupportedKubernetesResources";
import {
  KubernetesConfigMapResource,
  KubernetesHPAResource,
  KubernetesIngressResource,
  KubernetesNodesResource,
  KubernetesPVCResource,
  KubernetesPodsResource,
  KubernetesSecretsResource,
  KubernetesServiceResource,
} from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { KubernetesReplicaSetResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesReplicaSetResource";
import { KubernetesDeploymentResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";
import { KubernetesCronJobResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";
import { KubernetesStatefulSetResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesStatefulSetResource";
import { KubernetesDaemonSetResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesPVResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPVResource";
import { KubernetesStorageClassResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesStorageClassResource";
import { KubernetesResourceQuotaResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesResourceQuotaResource";
import { KubernetesLimitRangeResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesLimitRangeResource";
import { KubernetesPDBResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesPDBResource";
import { KubernetesEndpointResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesEndpointResource";
import { KubernetesNetworkPolicyResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNetworkPolicyResource";
import { KubernetesEndpointSliceResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesEndpointSliceResource";
import { KubernetesServiceAccountsResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesServiceAccountsResource";
import { KubernetesClusterRolesResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRolesResource";
import { KubernetesRolesResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRolesResource";
import { KubernetesClusterRoleBindingsResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRoleBindingsResource";
import { KubernetesRoleBindingsResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRoleBindingsResource";
import { KubernetesHelmResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesHelmResource";
import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import {
  DOCS_LINK,
  KOMODOR_UPDATES_LINK,
} from "../../../shared/constants/docsLinks";
import { KubernetesJobResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";
import { useAppViewsStore } from "../../../shared/store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../../../shared/store/appViewsStore/appViewStoreSelectors";
import { getOverviewPageRoute } from "../../appView/sections/AppView/utils/getRoutes";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import ReliabilityIcon from "../assets/reliabilityIcon.svg?react";
import { KubernetesCRDResource } from "../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";
import { useCanViewClustersList } from "../../ClustersView/ClusterOverview/permissionsHooks";

import { useArgoWorkflows } from "./argoWorkflowHooks";

import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";

export enum PageName {
  Overview = "Overview",
  Reliability = "Reliability",
  ArgoWorkflows = "Argo Workflows",
  Workflows = "Workflows",
  CostOptimization = "Cost Optimization",
  DriftAnalysisDemo = "Drift Analysis",
  Services = "Services",
  Jobs = "Jobs",
  Events = "Events",
  Nodes = "Nodes",
  Pods = "Pods",
  ReplicaSets = "ReplicaSets",
  Deployments = "Deployments",
  InspectionJobs = "Inspection Jobs",
  CronJobs = "CronJobs",
  StatefulSets = "StatefulSets",
  DaemonSets = "DaemonSets",
  PVCs = "PVCs",
  PVs = "PVs",
  StorageClasses = "Storage Classes",
  ConfigMaps = "ConfigMaps",
  Secrets = "Secrets",
  ResourceQuotas = "Resource Quotas",
  LimitRanges = "Limit Ranges",
  HPAs = "HPAs",
  PDBs = "PDBs",
  KubernetesServices = "Kubernetes Services",
  Endpoints = "Endpoints",
  Ingresses = "Ingresses",
  NetworkPolicies = "Network Policies",
  EndpointSlices = "Endpoint Slices",
  ServiceAccounts = "Service Accounts",
  ClusterRoles = "Cluster Roles",
  Roles = "Roles",
  ClusterRoleBinding = "Cluster Role Bindings",
  RoleBinding = "Role Bindings",
  CustomResources = "Custom Resources",
  Helm = "Helm",
  Integrations = "Integrations",
  Clusters = "Clusters",
  Agents = "Agents",
  Monitors = "Monitors",
  k8sAddonsCertManager = "Cert Manager",
  DocsAndSupport = "Docs and Support",
  WhatsNew = "What's New?",
  ChatSupport = "Chat Support",
  Audit = "Audit",
  Settings = "Settings",
  Topology = "Topology",
  ExternalDNS = "External DNS",
}
export type Page = {
  url: string;
  urlWithCluster?: (clusterName: string) => string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  shouldShow: boolean;
  locked?: boolean;
};
const storedMonitorsLastTab = new LocalStorageItem("monitorsLastTab");
const MONITORS_ROUTE = storedMonitorsLastTab.get() || MONITORS_DEFAULT_ROUTE;

// eslint-disable-next-line react-refresh/only-export-components
export const usePages: () => Record<PageName, Page> = () => {
  const appBarStateContext = useAppBarStateContext();
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const defaultCluster = appBarStateContext.defaultCluster ?? "";
  const {
    showReliability,
    showCostOptimization,
    demoDriftAnalysis,
    showCertManagerAddon,
    argoWorkflows,
    showServiceAccountInspectionPage,
    showClusterRoleInspectionPage,
    showRolesInspectionPage,
    showClusterRoleBindingInspectionPage,
    showRoleBindingInspectionPage,
    showAgentsTab,
    showClustersPage,
    showHelm,
    forceShowZendeskChatbot,
    forceShowIntercom,
    showWorkflows,
    showExpertOverview,
    appNavigationChanges,
  } = useOverridableFlags();
  const showArgoWorkflows = useArgoWorkflows();

  const { data: canViewClustersList } = useCanViewClustersList();

  const { accountPlan } = useUserMetadata();

  const shouldShowChatSupport =
    !forceShowIntercom &&
    (!!forceShowZendeskChatbot ||
      (accountPlan != AccountPlan.trial && accountPlan != AccountPlan.free));

  return useMemo(
    () => ({
      [PageName.Overview]: {
        url: selectedAppViewId ? getOverviewPageRoute(selectedAppViewId) : "",
        icon: () => <Gauge16 />,
        shouldShow: !!selectedAppViewId,
      },
      [PageName.Reliability]: {
        url: showReliability ? `/${RELIABILITY}` : "",
        icon: () => <ReliabilityIcon />,
        shouldShow: Boolean(showReliability),
      },
      [PageName.CostOptimization]: {
        url: showCostOptimization ? `/${COST_OPTIMIZATION}` : "",
        icon: CostIcon,
        shouldShow: Boolean(showCostOptimization),
      },
      [PageName.DriftAnalysisDemo]: {
        url: demoDriftAnalysis ? DRIFT_ANALYSIS_DEMO_ROUTE : "",
        icon: () => <img src={driftAnalysisIconUrl} />,
        shouldShow: Boolean(demoDriftAnalysis),
      },
      [PageName.k8sAddonsCertManager]: {
        url: showCertManagerAddon ? K8S_ADDONS_CERT_MANAGER_ROUTE : "",
        icon: () => <img src={k8sAddonsCertManagerIcon} />,
        shouldShow: Boolean(showCertManagerAddon),
      },
      [PageName.ArgoWorkflows]: {
        url:
          showArgoWorkflows && Boolean(argoWorkflows)
            ? `/${ARGO_WORKFLOWS}`
            : "",
        icon: () => <Workflows16 />,
        shouldShow: showArgoWorkflows && Boolean(argoWorkflows),
      },
      [PageName.Workflows]: {
        url: showWorkflows ? WORKFLOWS_ROUTE : "",
        icon: () => <Workflows16 />,
        shouldShow: Boolean(showWorkflows),
      },
      [PageName.Services]: {
        url: `/${SERVICES}`,
        icon: () => <Applications16 />,
        shouldShow: true,
      },
      [PageName.Jobs]: {
        url: `/${JOBS}`,
        icon: () => <Boxes16 />,
        shouldShow: true,
      },
      [PageName.Events]: {
        url: EVENTS_ROUTE,
        icon: () => <Clock16 />,
        shouldShow: true,
      },
      [PageName.Nodes]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesNodesResource,
          defaultCluster
        ),
        urlWithCluster: (clusterName: string) =>
          getRoutePathFromKubernetesResource(
            KubernetesNodesResource,
            clusterName
          ),
        icon: () => <Nodes16 />,
        shouldShow: true,
      },
      [PageName.Pods]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPodsResource,
          defaultCluster
        ),
        urlWithCluster: (clusterName: string) =>
          getRoutePathFromKubernetesResource(
            KubernetesPodsResource,
            clusterName
          ),
        shouldShow: true,
      },
      [PageName.ReplicaSets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesReplicaSetResource,
          defaultCluster
        ),
        icon: () => <Applications16 width={16} height={14} />,
        shouldShow: true,
      },
      [PageName.Deployments]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesDeploymentResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.InspectionJobs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesJobResource,
          defaultCluster
        ),
        shouldShow: true,
      },
      [PageName.CronJobs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesCronJobResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.StatefulSets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesStatefulSetResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.DaemonSets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesDaemonSetResource,
          defaultCluster
        ),
        icon: () => <Workloads16 />,
        shouldShow: true,
      },
      [PageName.PVCs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPVCResource,
          defaultCluster
        ),
        icon: () => <Storage16 />,
        shouldShow: true,
      },
      [PageName.PVs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPVResource,
          defaultCluster
        ),
        icon: () => <Storage16 />,
        shouldShow: true,
      },
      [PageName.StorageClasses]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesStorageClassResource,
          defaultCluster
        ),
        icon: () => <Storage16 />,
        shouldShow: true,
      },
      [PageName.ConfigMaps]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesConfigMapResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.Secrets]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesSecretsResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.ResourceQuotas]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesResourceQuotaResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.LimitRanges]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesLimitRangeResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.HPAs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesHPAResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.PDBs]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesPDBResource,
          defaultCluster
        ),
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.KubernetesServices]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesServiceResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.Endpoints]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesEndpointResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.Ingresses]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesIngressResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.NetworkPolicies]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesNetworkPolicyResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.EndpointSlices]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesEndpointSliceResource,
          defaultCluster
        ),
        icon: () => <Network16 />,
        shouldShow: true,
      },
      [PageName.ServiceAccounts]: {
        url: showServiceAccountInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesServiceAccountsResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showServiceAccountInspectionPage),
      },
      [PageName.ClusterRoles]: {
        url: showClusterRoleInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesClusterRolesResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showClusterRoleInspectionPage),
      },
      [PageName.Roles]: {
        url: showRolesInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesRolesResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showRolesInspectionPage),
      },
      [PageName.ClusterRoleBinding]: {
        url: showClusterRoleBindingInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesClusterRoleBindingsResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showClusterRoleBindingInspectionPage),
      },
      [PageName.RoleBinding]: {
        url: showRoleBindingInspectionPage
          ? getRoutePathFromKubernetesResource(
              KubernetesRoleBindingsResource,
              defaultCluster
            )
          : "",
        icon: () => <AccessControl16 />,
        shouldShow: Boolean(showRoleBindingInspectionPage),
      },
      [PageName.CustomResources]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesCRDResource,
          defaultCluster
        ),
        icon: () => <Wrench16 />,
        shouldShow: true,
      },
      [PageName.Helm]: {
        url: getRoutePathFromKubernetesResource(
          KubernetesHelmResource,
          defaultCluster
        ),
        icon: () => <Helm16 />,
        shouldShow: Boolean(showHelm),
      },
      [PageName.Clusters]: {
        url: showClustersPage ? CLUSTERS_ROUTE : "",
        icon: () => <Kubernetes16 />,
        shouldShow: !!showClustersPage,
        locked:
          canViewClustersList == undefined ? undefined : !canViewClustersList,
      },
      [PageName.Agents]: {
        url: showAgentsTab ? AGENTS_ROUTE : "",
        icon: () => <Kubernetes16 />,
        shouldShow: !!showAgentsTab,
      },
      [PageName.Integrations]: {
        url: INTEGRATIONS_ROUTE,
        icon: () => <Integration16 />,
        shouldShow: true,
      },
      [PageName.Monitors]: {
        url: MONITORS_ROUTE,
        icon: () => <Radar16 />,
        shouldShow: true,
      },
      [PageName.DocsAndSupport]: {
        url: DOCS_LINK,
        icon: () => <QuestionCircle16 />,
        shouldShow: true,
      },
      [PageName.WhatsNew]: {
        url: KOMODOR_UPDATES_LINK,
        icon: () => (
          <Newspaper
            sx={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          />
        ),
        shouldShow: true,
      },
      [PageName.ChatSupport]: {
        url: "",
        icon: () => (
          <ChatBubbleOutline
            sx={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          />
        ),
        shouldShow: shouldShowChatSupport,
      },
      [PageName.Audit]: {
        url: AUDIT_ROUTE,
        icon: () => <List16 />,
        shouldShow: true,
      },
      [PageName.Settings]: {
        url: appNavigationChanges ? USER_PROFILE_ROUTE : SETTINGS_ROUTE,
        icon: () => <Cog16 />,
        shouldShow: true,
      },
      [PageName.Topology]: {
        url: `/${EXPERT_OVERVIEW}`,
        icon: () => <Gauge16 />,
        shouldShow: !!showExpertOverview && !selectedAppViewId,
      },
      [PageName.ExternalDNS]: {
        url: EXTERNAL_DNS_DEMO_ROUTE,
        icon: () => <Language />,
        shouldShow: true,
      },
    }),
    [
      selectedAppViewId,
      showReliability,
      showCostOptimization,
      demoDriftAnalysis,
      showCertManagerAddon,
      showArgoWorkflows,
      argoWorkflows,
      showWorkflows,
      defaultCluster,
      showServiceAccountInspectionPage,
      showClusterRoleInspectionPage,
      showRolesInspectionPage,
      showClusterRoleBindingInspectionPage,
      showRoleBindingInspectionPage,
      showHelm,
      showClustersPage,
      canViewClustersList,
      showAgentsTab,
      shouldShowChatSupport,
      showExpertOverview,
      appNavigationChanges,
    ]
  );
};
