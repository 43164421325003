/* eslint-disable max-lines */

const explanations: Record<string, string> = {
  Terminated:
    'The "Terminated" error in Kubernetes indicates that a container within a Pod has been terminated unexpectedly. This termination can occur due to various reasons.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Application Failure: Check the container logs for any error messages or exceptions that indicate a failure within the application. \n" +
    "\n" +
    "2. Resource Constraints: Insufficient resources such as CPU, memory, or storage can cause the container to be terminated by the system. Review the resource allocations for the Pod and adjust the resource requests and limits to ensure they meet the application's requirements.\n" +
    "\n" +
    "3. Health Check Failure: If the container fails to respond successfully to health checks defined in the Kubernetes configuration, it may be terminated. Review the health check configurations and ensure the application is correctly implemented to pass the checks.\n" +
    "\n" +
    "4. SIGTERM Signal: The container may have received a termination signal (SIGTERM) from an external source. Investigate if there are any actions or events triggering the termination signal and address them accordingly.\n" +
    "\n" +
    "",
  Shutdown: "",
  Evicted:
    "The Pod was forcefully removed from the Node. This is typically caused by the Node running out of resources such as CPU or memory, or when it needs to make space for higher-priority workloads.\n" +
    "\n" +
    "To address this error, you can start by checking the resource allocation of the Pod and the Node. Ensure that the Pod's resource requests and limits are appropriate and that the Node has enough resources available to accommodate the Pod.\n" +
    "\n" +
    "In some cases, it might be necessary to prioritize critical workloads to reduce the likelihood of eviction for those workloads. This can be accomplished by using a 'Guaranteed' Quality of Service and/or applying a higher level PriorityClass.\"",
  ContainerCreating:
    'The error "ContainerCreating" in Kubernetes means that a container is in the process of being created or started within a pod. \n' +
    "It indicates that the container you're trying to create is currently being set up and initialized. \n" +
    "This can happen when deploying a new pod or when scaling up the number of containers within a pod. \n" +
    "The container creation process involves tasks like pulling the container image, setting up networking, and allocating resources. \n" +
    'The duration of the "ContainerCreating" state depends on factors like image size, network speed, and resource availability. \n' +
    "You don't need to take any action unless the creating process takes an unusually long time.\"",
  PodInitializing:
    'The error "PodInitializing" in Kubernetes means that a pod is in the process of initializing or starting up. \n' +
    "When a pod is created, it takes some time for all the containers inside the pod to start and become fully operational. During this initialization phase, Kubernetes is setting up the necessary resources and configurations for the containers. \n" +
    "\n" +
    "It indicates that the containers within the pod are still going through their initialization process and have not yet completed their setup. \n" +
    "This is a normal part of the pod's lifecycle, unless the initialization process takes an unusually long time. \n" +
    'Once the initialization is complete, the pod will transition to a "Running" state, and you can start using it for your application."',
  CreateContainerConfigError:
    'The "CreateContainerConfigError" in Kubernetes indicates an error in creating the container configuration, preventing the container from being created and started.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Misconfigured Container Configuration: Verify the container's configuration, including the image, command, arguments, environment variables, and volume mounts. Ensure that all configuration elements are properly specified and match the requirements of the application.\n" +
    "\n" +
    "2. Image Unavailability or Pull Errors: Check if the specified container image is accessible and available in the specified image repository. Verify network connectivity and credentials required to pull the image. \n" +
    "\n" +
    "3. Incompatible Container Runtime: Ensure that the container runtime specified in the cluster configuration is compatible with the container image and configuration. Check for compatibility issues between the runtime and the container's operating system or dependencies.\n" +
    "\n" +
    "4. Resource Constraints: Review the resource requirements and limits specified for the container. Verify that the cluster has sufficient resources (CPU, memory, storage) to accommodate the container's needs.\"",
  CreateContainerError: "",
  ContainerCannotRun: "",
  CrashLoopBackOff:
    'The error "CrashLoopBackOff" in Kubernetes means that a container within a pod is repeatedly crashing immediately after it starts. \n' +
    "Instead of running successfully, the container enters a loop of crashing and restarting. \n" +
    "This can occur due to coding errors, missing dependencies, incorrect configurations, or other issues that prevent the container from running properly.",
  ImagePullBackOff:
    'The error "ImagePullBackOff" in Kubernetes occurs when a pod is unable to pull or retrieve the container image specified in its configuration. \n' +
    "This error suggests that there is a problem fetching the container image from the specified container registry or repository. \n" +
    "\n" +
    "It can happen due to various reasons such as: \n" +
    "1. Incorrect image name or tag\n" +
    "2. Missing permissions to access the repository\n" +
    "3. Rate limit exceeded\n" +
    "4. Node network issues\n" +
    "5. problems with the image registry itself\n" +
    "\n" +
    "\n" +
    "You should ensure that the image name and tag are accurate, check your network connectivity, verify authentication credentials if required, and ensure that the image registry is accessible.",
  OOMKilled:
    "Out of Memory errors in Kubernetes occur when a container or a process inside a container runs out of available memory. When the memory limit is exceeded, the system terminates the container, and the exit code associated with this error is typically non-zero unless the container shuts down gracefully.",
  Completed: "",
  Error: "",
  ErrImagePull:
    'The "ErrImagePull" error in Kubernetes indicates a failure to pull the container image specified in the pod\'s configuration from the container registry.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Image Not Found: Verify if the specified container image is available in the container registry. Check the image name, tag, and repository URL for correctness. \n" +
    "\n" +
    "2. Authentication Issues: If the container registry requires authentication, ensure that the correct credentials are provided in the pod's configuration or secret. \n" +
    "\n" +
    "3. Network Connectivity: Check if the cluster has proper network connectivity to reach the container registry. Verify firewall rules, network policies, or proxy configurations that might be blocking the network traffic. \n" +
    "\n" +
    "4. Registry Access Restrictions: Some container registries enforce access restrictions based on IP addresses or require additional configurations.",
  ImageInspectError: "",
  ErrImageNeverPull: "",
  RegistryUnavailable: "",
  InvalidImageName:
    "Unable to pull image, image/tag does not exist, verify you are using a valid image name.",
  FailedScheduling:
    'The error "FailedScheduling" in Kubernetes means that the scheduler was unable to assign a pod to a suitable node in the cluster. \n' +
    "When you create a pod, Kubernetes needs to find an appropriate node with enough available resources to run the pod, but it failed to do so in this case.\n" +
    "\n" +
    "It typically occurs when there are not enough resources, such as CPU or memory, available on any of the nodes to accommodate the pod's requirements.\n" +
    "\n" +
    "To address this error, you can start by checking the resource requirements specified for the pod. \n" +
    "Make sure the requested resources, such as CPU and memory, are not set too high or exceed the capacity of the available nodes.\n" +
    "\n" +
    "If the cluster is running out of resources, you might need to consider scaling up the cluster by adding more nodes or optimizing the resource allocation across existing nodes.\n" +
    "\n" +
    'Additionally, you can review the resource utilization of the nodes in the cluster. Check if any nodes are under heavy load or experiencing issues that prevent them from accepting new pods. If this is not the case, you can allow the Pod to run on other nodes by updating restrictions (nodeSelectors, Affinity rules, Tolerations)."',
  FailedMount:
    'The "FailedMount" error in Kubernetes means that a container within a pod was unable to access or mount a specified volume. \n' +
    "This could be due to configuration issues, volume unavailability, or permission problems.",
  FailedAttachVolume:
    'The "FailedAttachVolume" error in Kubernetes indicates a failure to attach a persistent volume to the pod, preventing the pod from accessing the requested storage volume.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Storage Provisioner Issues: Verify if the storage provisioner responsible for managing the persistent volumes is functioning correctly. Check the provisioner's logs for any error messages or failures. \n" +
    "\n" +
    "2. Insufficient Storage Resources: Ensure that the cluster has sufficient available storage resources to fulfill the persistent volume claim (PVC) request. Verify storage class configurations and available storage capacity.\n" +
    "\n" +
    "3. Incompatible Volume and Node: Check if the selected persistent volume is compatible with the node where the pod is scheduled. Verify that the node supports the required storage technology or access modes. \n" +
    "\n" +
    "4. Access Control Issues: Review the access control configurations for the persistent volume and associated storage provider. Ensure that the necessary credentials, secrets, or access permissions are correctly configured.",
  NodeNotReady:
    'The "NodeNotReady" error in Kubernetes means that a node in the cluster is currently unavailable or not ready to run containers. \n' +
    "This can occur due to various reasons such as network connectivity issues, resource constraints, or hardware problems. \n" +
    "When a node is not ready, it cannot accept workloads or perform its assigned tasks.",
  NotTriggerScaleUp:
    'The "NotTriggerScaleUp" error in Kubernetes indicates that an event or condition expected to trigger an automatic scaling operation did not occur, preventing the cluster from scaling up.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Insufficient Resource Utilization: Verify if the cluster's resource utilization, such as CPU or memory, is below the defined threshold for triggering scaling. \n" +
    "\n" +
    "2. Incorrect Metrics or Rules Configuration: Review the metrics and scaling rules configured for the cluster's autoscaler to ensure they accurately represent the desired scaling behavior.\n" +
    "\n" +
    "3. Misconfigured Cluster Autoscaler: Ensure that the cluster autoscaler is correctly installed and configured in the cluster. Verify that it has the necessary permissions and access to perform scaling operations. \n" +
    "\n" +
    "4. External Dependencies or Constraints: Examine if there are any external dependencies or constraints affecting the scaling triggers, such as dependencies on external services or manual approvals.",
  Unhealthy:
    "When a pod or container is marked as 'unhealthy', it means it is not meeting the defined health criteria, such as failing readiness or liveness probes. This can indicate issues with the underlying application or the environment it is running in. \n" +
    "\n" +
    "Start by investigating the logs to identify the cause.",
  "Unhealthy - failed probes":
    "When a pod or container is marked as 'unhealthy', it means it is not meeting the defined health criteria, such as failing readiness or liveness probes. This can indicate issues with the underlying application or the environment it is running in. \n" +
    "\n" +
    "Start by investigating the logs to identify the cause.",
  "Liveness Probe Failed":
    "Kubernetes was configured to perform periodic liveness checks on one or more containers in this service to ensure that they are still functioning correctly. This check has failed enough times for the pod to be restarted as a result. This in turn caused an availability issue. \n" +
    "\n" +
    "There are typically three reasons for this issue:",
  "Readiness Probe Failed":
    "Kubernetes can be configured to perform periodic checks on containers to check containers’ status. Readiness probes monitor whether a service is ready to receive connections. If a readiness probe fails, the pod is taken out of the service and its ready condition is set to false. \n" +
    "\n" +
    "In this instance, a container has failed a readiness probe, causing the pod to be stop receiving connections. \n" +
    "\n" +
    "There are typically three reasons for this issue:",
  DNSConfigForming: "",
  NetworkNotReady:
    'The "NetworkNotReady" error in Kubernetes indicates that the network is not ready for the Pod, preventing it from establishing network connectivity.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Network Plugin or Configuration Issues: Verify the installation and configuration of the network plugin in the Kubernetes cluster. Ensure that the plugin is functioning correctly and properly integrated with the cluster's networking infrastructure. Restart or reconfigure the network plugin if needed.\n" +
    "\n" +
    "2. Network Service Unavailability: Check if the network services required for Pod networking, such as DNS resolution or network overlay, are available and operational. \n" +
    "\n" +
    "3. Pod Network Policy Restrictions: Review the network policies applied to the Pod or Namespace. Ensure that the network policies are not overly restrictive, blocking necessary network traffic. \n" +
    "\n" +
    '4. Cluster Network Connectivity: Verify the network connectivity between the Pod and other network endpoints, such as Services, Nodes, or external resources."',
  FailedPreStopHook: "",
  PolicyViolation: "",
  FailedKillPod:
    'The "FailedKillPod" error in Kubernetes indicates that there was a failure in terminating or killing a pod.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Pod Stuck in Terminating State: Check if the pod is stuck in a terminating state due to an unresponsive process or a termination hook that is not completing. Review the pod's logs for any such indication. \n" +
    "\n" +
    "2. Insufficient Permissions: Verify if the user or service account executing the kill command has the necessary permissions to terminate pods. \n" +
    "\n" +
    "3. Infrastructure or API Connectivity Issues: Ensure that there are no connectivity issues between the Kubernetes cluster and the underlying infrastructure or API server. Check network connectivity, DNS resolution, and API server availability. \n" +
    "\n" +
    "4. Resource Constraints: If the cluster is under heavy load or has resource constraints, it may struggle to terminate pods efficiently. Check the cluster's resource utilization and consider scaling up the cluster or optimizing resource allocations to improve the termination process.\"",
  FailedCreatePodContainer: "",
  SyncError: "",
  Preempted: "",
  Terminating:
    'The error "Terminating" in Kubernetes means that a pod or resource is in the process of being shut down and removed.\n' +
    "It is a normal part of the Kubernetes lifecycle when you delete a pod or scale down a deployment. \n" +
    "Kubernetes initiates the termination process to gracefully stop the associated containers and release resources. \n" +
    "You don't need to take any action unless the termination process takes an unusually long time. \n" +
    'Once the termination is complete, the pod or resource will be removed from the cluster."',
  ContainersNotReady:
    'The error "ContainersNotReady" in Kubernetes means that one or more containers within a pod are not yet ready to handle requests or perform their tasks. It indicates that the containers have not completed their initialization process or are experiencing issues preventing them from functioning properly. \n' +
    "\n" +
    "This error can occur due to dependencies not being ready, incorrect configurations, or problems with the containers' code or resources. \n" +
    "To resolve this error, you should investigate the logs and status of the affected containers, ensure proper configuration and resource allocation, and address any underlying issues or dependencies causing the containers to remain not ready.",
  "NonZeroExitCode - Exit code: 1":
    "The error \"NonZeroExitCode - Exit code: 1\" in Kubernetes means that a program or command executed inside a container did not finish successfully. When a program runs succesfully, it usually returns an exit code of '0'.\n" +
    "\n" +
    "To troubleshoot this error, you can start by checking the logs of the container or the pod where the error occurred to understand what led up to the error.",
  "NonZeroExitCode - Exit code: 143":
    `The "NonZeroExitCode - Exit code: 143" error in Kubernetes indicates that a container was terminated with signal 143 (SIGTERM).\n` +
    `SIGTERM is a standard way to request the graceful termination of a container.\n` +
    `\n` +
    `To address the issue:\n` +
    `\n` +
    `\t1. Check the container's logs.\n` +
    `\n` +
    `\t2. Review resource allocations, ensuring they meet the application's needs and available cluster resources.\n` +
    `\n` +
    `\t3. Troubleshoot resource constraints, network issues, and verify health check configurations.\n` +
    `\n` +
    `\t4. Optimize the application code to reduce resource usage and prevent timeouts.`,
  "NonZeroExitCode - Exit code: 139":
    'The "NonZeroExitCode - Exit code: 139" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 2":
    'The "NonZeroExitCode - Exit code: 2" error in Kubernetes indicates a failure or error condition.',
  FailedCreate:
    "Deployment was unsuccessful, pods failed to be created. \nThis could be caused by:\n\t1 - Insufficient quota.\n\t2 - Readiness probe failures.\n\t3 - Image pull errors.\n\t4 - Insufficient permissions.\n\t5 - Limit ranges.\n\t6 - Application runtime misconfiguration.",
  ReadinessGatesNotReady:
    'The "ReadinessGatesNotReady" error in Kubernetes indicates that the readiness gates defined for a pod are not satisfied, meaning that the pod is not considered ready to receive traffic or requests.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Health Check Failure: Verify if the application within the pod is failing the readiness checks defined in the pod configuration. Inspect the application's health check endpoints or probes and ensure they are correctly implemented.\n" +
    "\n" +
    "2. Dependencies or Initialization Delays: Check if there are dependencies or initialization processes that are taking longer than expected to become ready. Identify the dependencies or initialization steps causing the delay and optimize or streamline them. Adjust the readiness gate configuration or introduce delays if necessary.\n" +
    "\n" +
    "3. Resource Constraints: Insufficient resources (CPU, memory) can prevent the pod from becoming ready. \n" +
    "\n" +
    '4. Misconfigured Readiness Gates: Review the readiness gate configurations for the pod. Ensure that the conditions specified in the readiness gates accurately reflect the expected state for the pod to be considered ready."',
  PodNotReady:
    'The "PodNotReady" error in Kubernetes means that the Pod failed to reach a ready state within a certain timeframe or encountered issues during initialization.\n' +
    "\n" +
    "Potential causes are as follows:\n" +
    "\n" +
    "1. Application Startup Issues: The container within the Pod may be encountering errors during startup, such as crashing, failing health checks, or not completing the required initialization steps. Check the Pod logs for these indications.\n" +
    "\n" +
    "2. Resource Constraints: Insufficient resources like CPU, memory, or storage can prevent the Pod from starting or cause it to remain in a non-ready state. Verify that the resource requests and limits are appropriately allocated.\n" +
    "\n" +
    "3. Dependencies or Image Availability: If the Pod's image or dependencies are not available or experiencing issues, it can result in the Pod failing to start or remaining in an unready state. Confirm that the pod's image and any required dependencies are accessible and properly specified in the pod's manifest.\"",
  PodPending:
    'The "PodPending" error in Kubernetes means that a Pod is stuck in the pending state because it cannot be scheduled to a Node. This occurs when the Kubernetes scheduler is unable to allocate the necessary resources or meet the requirements specified in the pod\'s configuration.\n' +
    "\n" +
    "Potential causes are as follows:\n" +
    "\n" +
    "1. Insufficient Resources: The cluster may not have enough available resources (CPU, memory, or storage) to accommodate the Pod's resource requests. Check resource utilization and availability for the available Node pool. Ensure the cluster has sufficient resources to meet the pod's requests. Adjust resource quotas, scale the cluster, or optimize resource utilization if needed.\n" +
    "\n" +
    "2. Node Affinity and Selector Constraints: If the Pod's Affinity or Anti-Affinity rules, Node selectors, or Taints and Tolerations conflict with the available Nodes. Review the Pod's Node Affinity, Selectors, Taints and Tolerations and ensure they are compatible with the available Nodes.\n" +
    "\n" +
    "3. Persistent Volume (PV) Provisioning: If the Pod requires a PersistentVolumeClaim (PVC), but the Cluster does not have enough available storage or appropriate Storage Classes, the Pod will remain pending until a suitable PV can be provisioned. Verify that the cluster has appropriate storage classes and available PVs. Adjust storage configurations, provision new PVs if necessary, or modify PVC requirements.\n" +
    "\n" +
    "4. Network or DNS Configuration: Network-related issues, such as misconfigured network policies or DNS resolution problems can prevent Pod scheduling. Check the network configuration, including network policies and DNS resolution. Resolve any misconfigurations or connectivity issues.\n" +
    "\n" +
    '5. Cluster Conditions: Use the kubectl describe nodes command to check if there are any cluster-level issues or conditions affecting node availability."',
  "Completed - Exit code: 0":
    'In Kubernetes "Completed - Exit code: 0" means that a container has finished executing successfully without any errors. \n' +
    "When a container completes its task or job, it exits with an exit code of 0, which indicates a successful execution. \n" +
    "This is a normal and expected state when a container completes its intended operation without encountering any issues. \n" +
    "\n" +
    "However, it still indicates an application-related issue, that is not Kubernetes related.\n" +
    "\n" +
    'To troubleshoot this error, you can start by checking the logs of the container or the pod where the error occurred to understand what led up to the shutdown."',
  "NonZeroExitCode - Exit code: 255":
    'The "NonZeroExitCode - Exit code: 255" error in Kubernetes indicates a failure or error condition.',
  MinimumReplicasUnavailable:
    'The "MinimumReplicasUnavailable" error in Kubernetes indicates that the desired minimum number of replicas specified for a deployment or replica set is not available or running.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Insufficient Resources: Verify if the cluster has enough resources (CPU, memory) to accommodate the desired minimum replicas.\n" +
    "\n" +
    "2. Pod Scheduling Issues: Ensure that there are enough available nodes in the cluster to schedule the desired replicas. Verify if any node selector, affinity, or taints and tolerations configurations are preventing the pods from being scheduled. \n" +
    "\n" +
    "3. Failed or Pending Pods: Check if any of the pods are failing or stuck in a pending state due to errors or resource constraints.\n" +
    "\n" +
    '4. Container Image Pull Errors: Verify if the specified container images are accessible and can be pulled from the container registry."',
  "NonZeroExitCode - Exit code: 3":
    'The "NonZeroExitCode - Exit code: 3" error in Kubernetes indicates a failure or error condition.',
  NonZeroExitCode:
    'The "NonZeroExitCode" error in Kubernetes indicates that a container within a pod exited with a non-zero exit code, signaling a failure or error condition.',
  "NonZeroExitCode - Exit code: 127":
    'The "NonZeroExitCode - Exit code: 127" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 134":
    'The "NonZeroExitCode - Exit code: 134" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 254":
    'The "NonZeroExitCode - Exit code: 254" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 137":
    `The container received a 'SIGKILL' command, and will be terminated immediately.\n` +
    "\n" +
    `This could be caused by:\n` +
    `\n` +
    `\t1. Node overcommitted, Pods are being evicted to release resources.\n` +
    `\n` +
    `\t2. Node terminated (Preempted/Scaled-down).\n` +
    `\n` +
    `\t3. Preemption due to low priority.\n` +
    `\n` +
    `\t4. Pod tries to consume more Memory than requested (OOMKilled).`,
  NodeAffinity:
    'The "NodeAffinity" error in Kubernetes indicates that a pod\'s scheduling requirements defined by node affinity rules cannot be satisfied, preventing the pod from being scheduled on any available node.\n' +
    "\n" +
    "Node affinity is a feature in Kubernetes that allows pods to be scheduled onto specific nodes based on node labels and selectors, ensuring pod placement based on node characteristics.\n" +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Insufficient Nodes with Matching Labels: Verify if there are enough nodes in the cluster that satisfy the node affinity rules specified for the pod. \n" +
    "\n" +
    "2. Conflicting Node Affinity Rules: Check if there are conflicting node affinity rules specified for the pod or other pods in the cluster. \n" +
    "\n" +
    "3. Node Selector Mismatch: Ensure that the node selector defined in the pod's configuration matches the labels assigned to the nodes. \n" +
    "\n" +
    "4. Taints and Tolerations: If the nodes have taints applied and you want to schedule Pods to this node, add tolerations to the pod's configuration to tolerate the taints.\"",
  RunContainerError:
    'The "RunContainerError" error in Kubernetes indicates a failure in running or starting a container within a pod.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Insufficient Resources: Check if the container requires more resources (CPU, memory) than what is available on the node. Review the resource requests and limits specified for the container in the pod's configuration. Increase the resource allocations if the container needs more resources to run.\n" +
    "\n" +
    "2. Image Pull Failure: Verify if the container image specified in the pod's configuration is inaccessible or unavailable. Check the image repository, credentials, and network connectivity. Ensure the image is accessible and properly configured for the pod.\n" +
    "\n" +
    "3. Misconfigured Command or Entrypoint: Examine if there is an issue with the command or entrypoint specified for the container. Check the container's configuration and ensure the command or entrypoint is correctly defined. Adjust the configuration to specify the correct command or entrypoint.\n" +
    "\n" +
    '4. Volume Mount Error: If the container requires volumes to be mounted, check if there are any issues with the volume configuration or availability. Review the volume mounts and ensure they are correctly defined and accessible. Resolve any issues with volume mounts."',
  "NonZeroExitCode - Exit code: 12":
    'The "NonZeroExitCode - Exit code: 12" error in Kubernetes indicates a failure or error condition.',
  "ContainerCannotRun - Exit code: 128":
    'The "ContainerCannotRun - Exit code: 128" error in Kubernetes indicates that the container within the pod cannot be started or run successfully, resulting in an exit code of 128.\n' +
    "\n" +
    "Potential causes are:\n" +
    "\n" +
    "1. Missing or Misconfigured Command: Verify if the command specified for the container is missing or misconfigured in the pod's configuration. Check the command section in the container's definition and ensure it is accurately defined. \n" +
    "\n" +
    "2. Insufficient Privileges or Access: Check if the container requires specific privileges or access rights that are not provided. Review the security context or pod security policies associated with the container. \n" +
    "\n" +
    "3. Resource Limitations: Insufficient resources (CPU, memory) allocated to the container can cause it to fail to run. Check the resource requests and limits specified for the container in the pod's configuration.\n" +
    "\n" +
    "4. Dependencies or Environmental Issues: Examine if the container has dependencies or environmental requirements that are not met. Verify if all necessary libraries, files, or environmental variables are available and properly configured within the container's environment. \"",
  "NonZeroExitCode - Exit code: 4":
    'The "NonZeroExitCode - Exit code: 4" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 239":
    'The "NonZeroExitCode - Exit code: 239" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 14":
    'The "NonZeroExitCode - Exit code: 14" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 126":
    'The "NonZeroExitCode - Exit code: 126" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 135":
    'The "NonZeroExitCode - Exit code: 135" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 5":
    'The "NonZeroExitCode - Exit code: 5" error in Kubernetes indicates a failure or error condition.',
  "NonZeroExitCode - Exit code: 125":
    'The "NonZeroExitCode - Exit code: 125" error in Kubernetes indicates that a container within a pod exited with a non-zero exit code of 125, signaling a docker run command did not execute successfully.',
};
export default explanations;
