import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import React from "react";
import styled from "styled-components";

import { AriaLabels } from "../../../../shared/config/ariaLabels";

export const SummaryLoader = () => (
  <Skeleton
    aria-label={AriaLabels.ClusterOverview.Summary.Loader}
    width="360px"
    height="23px"
    sx={{ transform: "none" }}
  />
);

const Bold = styled.b<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
`;

interface SummaryProps {
  stats: { label: string; value: string | number; color?: string }[];
  isLoading: boolean;
  ariaLabel?: string;
}
export const Summary: React.FC<SummaryProps> = ({
  stats,
  isLoading,
  ariaLabel,
}) => {
  if (isLoading) {
    return <SummaryLoader />;
  }

  return (
    <Box sx={{ display: "flex", columnGap: "4px" }} aria-label={ariaLabel}>
      {stats.map(({ label, value, color }) => (
        <Chip
          label={
            <>
              {label}{" "}
              <Bold color={color}>
                {typeof value === "number" ? value.toLocaleString() : value}
              </Bold>
            </>
          }
        />
      ))}
    </Box>
  );
};
