import React from "react";
import { muiColors, palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { AriaLabels } from "../../shared/config/ariaLabels";

import { LegendLabelTextColor } from "./styles";
import { GraphDataPoint, MetricsGraphType } from "./types";
import { NoLimitLabel, NoLimitPerOneContainerLabel } from "./NoLimitLabel";

export const UsageIcon: React.FC<{ fillColor?: string }> = ({ fillColor }) => {
  return (
    <svg
      width="18"
      height="5"
      viewBox="0 0 18 5"
      fill="none"
      aria-label={AriaLabels.MetricsAvailability.Legend.UsageIcon}
    >
      <rect
        y="0.816406"
        width="18"
        height="4"
        rx="2"
        fill={fillColor || theme.foreground.fgDarkBlue}
      />
    </svg>
  );
};

export const CapacityIcon: React.FC = () => {
  return (
    <svg
      width="18"
      height="5"
      viewBox="0 0 18 5"
      fill="none"
      aria-label={AriaLabels.MetricsAvailability.Legend.UsageIcon}
    >
      <rect
        y="0.816406"
        width="18"
        height="4"
        rx="2"
        fill={muiColors.orange[600]}
      />
    </svg>
  );
};

export const RequestIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="18"
    height="5"
    viewBox="0 0 18 5"
    fill="none"
    aria-label={AriaLabels.MetricsAvailability.Legend.RequestIcon}
    className={className}
  >
    <rect
      x="0"
      y="0.816406"
      width="8"
      height="4"
      rx="2"
      fill={palette.gray[400]}
    />
    <rect
      x="10"
      y="0.816406"
      width="8"
      height="4"
      rx="2"
      fill={palette.gray[400]}
    />
  </svg>
);

export const LimitIcon: React.FC = () => (
  <svg
    width="18"
    height="5"
    viewBox="0 0 18 5"
    fill="none"
    aria-label={AriaLabels.MetricsAvailability.Legend.LimitIcon}
  >
    <rect
      x="0"
      y="0.816406"
      width="8"
      height="4"
      rx="2"
      fill={palette.gray[700]}
    />
    <rect
      x="10"
      y="0.816406"
      width="8"
      height="4"
      rx="2"
      fill={palette.gray[700]}
    />
  </svg>
);
export const RecommendationIcon: React.FC = () => (
  <svg
    width="18"
    height="5"
    viewBox="0 0 18 5"
    fill="none"
    aria-label={AriaLabels.MetricsAvailability.Legend.RecommendationIcon}
  >
    <rect
      y="0.816406"
      width="18"
      height="4"
      rx="2"
      fill={theme.foreground.fgGreen}
    />
  </svg>
);

export const AllocatableIcon: React.FC = () => (
  <svg
    width="18"
    height="5"
    viewBox="0 0 18 5"
    fill="none"
    aria-label={AriaLabels.MetricsAvailability.Legend.AllocatableIcon}
  >
    <rect
      y="0.816406"
      width="18"
      height="4"
      rx="2"
      fill={muiColors.deepPurple[400]}
    />
  </svg>
);

export interface LegendProps {
  data: GraphDataPoint[];
  graphType?: MetricsGraphType;
}

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

const Legend: React.FC<LegendProps> = ({ data, graphType }) => {
  const showUsageLabel = data.some((d) => d.usageBytes !== undefined);
  const showRequestLabel = data.some(
    (d) => d.requestBytes && d.requestBytes > 0
  );
  const showLimitLabel = data.some((d) => d.limitBytes && d.limitBytes > 0);

  return (
    <LegendContainer aria-label={AriaLabels.MetricsAvailability.Legend.Legend}>
      {showUsageLabel && (
        <LabelContainer>
          <UsageIcon />
          <Typography variant="uppercase" color={LegendLabelTextColor}>
            usage
          </Typography>
        </LabelContainer>
      )}
      {showRequestLabel && (
        <LabelContainer>
          <RequestIcon />
          <Typography variant="uppercase" color={LegendLabelTextColor}>
            request
          </Typography>
        </LabelContainer>
      )}
      {showLimitLabel ? (
        <LabelContainer>
          <LimitIcon />
          <Typography variant="uppercase" color={LegendLabelTextColor}>
            limit
          </Typography>
        </LabelContainer>
      ) : data?.length > 0 ? (
        graphType === MetricsGraphType.CONTAINER ? (
          <NoLimitPerOneContainerLabel />
        ) : (
          <NoLimitLabel />
        )
      ) : null}
    </LegendContainer>
  );
};

export default Legend;
