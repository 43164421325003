import { CertificateFullStatus } from "@/generated/addonsApi";

export const tooltipStatusTexts: Record<keyof CertificateFullStatus, string> = {
  notBefore:
    "The date and time when the certificate was issued and became active",
  renewalTime:
    "The date and time when the certificate is scheduled for renewal before it expires",
  notAfter:
    "The date and time when the certificate will expire and must be renewed to maintain service availability",
  conditions:
    "The Certificate resource status conditions and their statuses, hover the condition status for more information",
};
