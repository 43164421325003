import { useCallback } from "react";
import { GridRowParams } from "@mui/x-data-grid-pro";

import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useOpenLiveStateDrawer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerHooks";

export const useOpenDrawerOnRowClick = <
  T extends { uid: string; name: string; clusterName: string }
>() => {
  const { addonType, addonEntity } = useAddonContext();
  const openLiveStateDrawer = useOpenLiveStateDrawer();
  return useCallback(
    (params: GridRowParams<T>) => {
      openLiveStateDrawer({
        addon: addonType,
        entity: addonEntity,
        uid: params.row.uid,
        name: params.row.name,
        clusterName: params.row.clusterName,
      });
    },
    [addonEntity, addonType, openLiveStateDrawer]
  );
};
