import React from "react";
import { CRD as crdIcon } from "@komodorio/design-system/icons";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { ObjectMeta } from "kubernetes-types/meta/v1";
import { CustomResourceDefinitionCondition } from "kubernetes-types/apiextensions/v1";

import { Header } from "../common/Header/Header";

import Resource, { ResourceTabName } from "./index";

import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/AvailableActions";
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "@/components/ResourceView/tabs/ResourceYAMLTab";
// eslint-disable-next-line import/no-cycle
import { CertManagerCertificateRequestsDetailsTab } from "@/components/ResourceView/tabs/DescribeTab/CertManagerCertificateRequestsDetailsTab";
import { Dictionary } from "@/shared/types/Dictionary";

type CertManagerCertificateRequestsSpec = {
  extra: Dictionary<string[]>;
  groups: string[];
  issuerRef: {
    group: string;
    kind: string;
    name: string;
  };
  request: string;
  uid: string;
  usages: string[];
  username: string;
};

type CertManagerCertificateRequestsStatus = {
  certificate: string;
  conditions: CustomResourceDefinitionCondition[];
};

export interface CertManagerCertificateRequestsType {
  apiVersion?: string;
  kind?: string;
  metadata?: ObjectMeta;
  spec?: CertManagerCertificateRequestsSpec;
  status?: CertManagerCertificateRequestsStatus;
}

export default class CertManagerCertificateRequests implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [
    { label: ResourceTabName.Details },
    { label: ResourceTabName.Yaml },
  ];
  readonly actions = [AvailableActions.Edit, AvailableActions.Describe];
  readonly fullObj;
  readonly defaultTab;

  constructor(
    cluster: string,
    certificate: CertManagerCertificateRequestsType,
    agentId: string
  ) {
    this.agentId = agentId;
    this.id = certificate.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = certificate.metadata?.namespace ?? "";
    this.name = certificate.metadata?.name ?? "";
    this.kind = certificate.kind ?? "";
    this.labels = certificate.metadata?.labels ?? {};
    this.annotations = certificate.metadata?.annotations ?? {};
    this.icon = crdIcon;

    this.fullObj = certificate;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <CertManagerCertificateRequestsDetailsTab resource={this} />
        </TabPanel>
        <TabPanel eager value={selectedTab} index={1}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
