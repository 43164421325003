// eslint-disable import/no-cycle
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { IntercomProvider } from "react-use-intercom";

import PrivateApp from "./PrivateApp";
import { ScrollToTop } from "./shared/utils/scrollService";
import { getStoredAccountId } from "./shared/utils/auth0/localEnv/storedAccountId";
import { getAppConfig } from "./shared/config/appConfig";
import { getAndClearStoredLocalUserId } from "./shared/utils/auth0/localEnv/storedLocalUserId";
import {
  getStoredDevUserRole,
  getStoredDevAccountPlan,
} from "./shared/utils/auth0/localEnv/storedUserRole";
import { getStoredDevUserManageRbac } from "./shared/utils/auth0/localEnv/storedUserPermissions";
import { fastSocialConnection } from "./shared/utils/auth0/utils";
import { useIntercomEffectsStore } from "./shared/store/intercomEffectsStore/intercomEffectsStore";
import { setUnreadMessagesCountSelector } from "./shared/store/intercomEffectsStore/intercomEffectsSelectors";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const App: React.FC = () => {
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const fastConnection =
    fastSocialConnection[
      urlParams.get("socialConnection") as keyof typeof fastSocialConnection
    ];

  const NotifyingErrorBoundary = ErrorBoundary;
  const storedAccountId = getStoredAccountId();
  const storedLocalUserId = getAndClearStoredLocalUserId();
  const storedDevUserRole = getStoredDevUserRole();
  const storedDevAccountPlan = getStoredDevAccountPlan();
  const storedManageRbac = getStoredDevUserManageRbac();

  const setUnreadIntercomMessagesCount = useIntercomEffectsStore(
    setUnreadMessagesCountSelector
  );

  const appConfig = getAppConfig();

  return (
    <NotifyingErrorBoundary>
      <Auth0Provider
        domain={appConfig.auth0Domain}
        clientId={appConfig.auth0ClientId}
        redirectUri={appConfig.authRedirectUri}
        audience={appConfig.auth0Audience}
        hasuraUrl={appConfig.serverUrl}
        authServiceUrl={appConfig.authServiceAPIServerURL}
        currentAccountId={storedAccountId ?? ""}
        currentUserId={storedLocalUserId ?? ""}
        currentDevUserRole={storedDevUserRole ?? ""}
        currentAccountPlan={storedDevAccountPlan ?? ""}
        currentManageRbac={storedManageRbac ?? ""}
        connection={fastConnection}
        skipRedirectCallback={
          window.location.pathname === "/installation/slack" // Handles collision between "state" param in slack and auth0: https://github.com/auth0/auth0-react/issues/204
        }
      >
        <Router>
          <IntercomProvider
            appId={"l36h9j2e"}
            onUnreadCountChange={(count) =>
              setUnreadIntercomMessagesCount(count)
            }
          >
            {/* Whenever we will use Tabs as navigation
          <ScrollToTop /> will need to move into specific components in case it will interfere with proper navigation */}
            <ScrollToTop />
            <PrivateApp />
          </IntercomProvider>
        </Router>
      </Auth0Provider>
    </NotifyingErrorBoundary>
  );
};

export default App;
