import React, { useMemo } from "react";
import { Line } from "recharts";

import { CPUMetricsChartTooltip } from "../Tooltip/ResourceTooltip";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { formatGraphCPUTick } from "../utils";
import { GraphDataPoint, MultiLinesMetricsGraphProps } from "../types";
import {
  MetricsCapacityColor,
  MetricsLimitColor,
  MetricsRequestColor,
} from "../styles";
import { sharedDashedLineProps, sharedStraightLineProps } from "../constants";

import { MetricsGraphContainer } from "./MetricsGraphContainer";
import {
  buildMetric,
  commonMetrics,
  getColorByIndex,
  getPointsByTime,
  PointsByTime,
} from "./graphUtils";

const MultiLineCpuGraph: React.FC<MultiLinesMetricsGraphProps> = (props) => {
  const newData = useMemo(() => {
    const pointsByTime = props.items.reduce<PointsByTime>(
      (acc, item) => getPointsByTime(acc, item),
      {}
    );

    return Object.entries(pointsByTime).map(([time, data]) => {
      return {
        time: parseInt(time),
        ...data,
      } as GraphDataPoint;
    });
  }, [props.items]);

  const lineNames: string[] = useMemo(() => {
    return (props.showLinesList ?? commonMetrics)
      .map((metric) => {
        return props.items.map((item) => buildMetric(metric, item));
      })
      .flat();
  }, [props.items, props.showLinesList]);

  const content = useMemo(() => {
    return (
      <MetricsGraphContainer
        {...props}
        data={newData}
        tooltipContent={
          <CPUMetricsChartTooltip aggregationType={props.aggregationType} />
        }
        tickFormatter={formatGraphCPUTick}
        ariaLabels={AriaLabels.MetricsAvailability.CpuGraph}
        label="MilliCores"
      >
        {/* items that will apply to first item */}
        {lineNames.includes("capacityBytes") && (
          <Line
            aria-label={AriaLabels.MetricsAvailability.CpuGraph.CapacityLine}
            stroke={MetricsCapacityColor}
            dataKey="capacityBytes"
            {...sharedDashedLineProps}
          />
        )}
        {lineNames.includes("limitBytes") && (
          <Line
            aria-label={AriaLabels.MetricsAvailability.CpuGraph.LimitLine}
            dataKey="limitBytes"
            stroke={MetricsLimitColor}
            {...sharedDashedLineProps}
          />
        )}
        {lineNames.includes("requestBytes") && (
          <Line
            aria-label={AriaLabels.MetricsAvailability.CpuGraph.RequestLine}
            dataKey="requestBytes"
            stroke={MetricsRequestColor}
            {...sharedDashedLineProps}
          />
        )}

        {/* items that will apply to all item */}
        {props.items.map(
          (item, index) =>
            lineNames.includes(buildMetric("usageBytes", item)) && (
              <Line
                key={index}
                aria-label={AriaLabels.MetricsAvailability.CpuGraph.UsageLine}
                stroke={getColorByIndex(item.index)}
                dataKey={buildMetric("usageBytes", item)}
                {...sharedStraightLineProps}
              />
            )
        )}
        {props.items.map(
          (item, index) =>
            lineNames.includes(buildMetric("p90", item)) && (
              <Line
                key={index}
                aria-label={AriaLabels.MetricsAvailability.CpuGraph.P90Line}
                dataKey={buildMetric("p90", item)}
                stroke={getColorByIndex(item.index)}
                {...sharedStraightLineProps}
              />
            )
        )}
        {props.items.map(
          (item, index) =>
            lineNames.includes(buildMetric("p95", item)) && (
              <Line
                key={index}
                aria-label={AriaLabels.MetricsAvailability.CpuGraph.P95Line}
                dataKey={buildMetric("p95", item)}
                stroke={getColorByIndex(item.index)}
                {...sharedStraightLineProps}
              />
            )
        )}
        {props.items.map(
          (item, index) =>
            lineNames.includes(buildMetric("p99", item)) && (
              <Line
                key={index}
                aria-label={AriaLabels.MetricsAvailability.CpuGraph.P99Line}
                dataKey={buildMetric("p99", item)}
                stroke={getColorByIndex(item.index)}
                {...sharedStraightLineProps}
              />
            )
        )}
        {props.items.map(
          (item, index) =>
            lineNames.includes(buildMetric("max", item)) && (
              <Line
                key={index}
                aria-label={AriaLabels.MetricsAvailability.CpuGraph.MaxLine}
                dataKey={buildMetric("max", item)}
                stroke={getColorByIndex(item.index)}
                {...sharedStraightLineProps}
              />
            )
        )}
      </MetricsGraphContainer>
    );
  }, [lineNames, newData, props]);

  return content;
};

export default MultiLineCpuGraph;
