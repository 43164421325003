import React, { useMemo } from "react";
import { Folder16 } from "@komodorio/design-system/icons";

import { AppBarTitle } from "@/components/AppBar/constants";
import { ArgoWorkflowsLink } from "@/components/AppBar/links/ArgoWorkflows";
import { HelmLink } from "@/components/AppBar/links/Helm";
import { K8sAddonCertManager } from "@/components/AppBar/links/K8sAddonCertManager";
import { LinkGroup } from "@/components/AppBar/linkGroups/LinkGroup";
import { WorkflowsClustersDemoV3Link } from "@/components/AppBar/links/ClustersDemoV3";
import { WorkflowsLink } from "@/components/AppBar/links/Workflows";
import { useAppBar } from "@/components/AppBar/useAppBar";
import { useArgoWorkflows } from "@/components/AppBar/links/argoWorkflowHooks";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import {
  AddonsDemoCertManagerLink,
  AddonsDemoExternalDNSLink,
  AddonsDemoNodeAutoscalersLink,
} from "@/components/AppBar/links/AddonsDemo";
import { ExternalDNSDemo } from "@/components/AppBar/links/ExternalDNSDemo";
import { NodeAutoscalersDemo } from "@/components/AppBar/links/NodeAutoscalersDemo";

export const K8sAddonsMenu = () => {
  const {
    appNavigationChanges,
    showWorkflows,
    showClustersPageDemoV3,
    demoAddons,
    showCertManagerAddon,
    comingSoonStaticPageExternalDns,
    comingSoonStaticPageNodeAutoscalers,
  } = useOverridableFlags();
  const showArgoWorkflows = useArgoWorkflows();
  const { shouldShowK8sAddonsMenu } = useAppBar();

  const WorkflowsLinkToRender = useMemo(() => {
    if (showClustersPageDemoV3) {
      return WorkflowsClustersDemoV3Link;
    }
    if (showWorkflows) {
      return WorkflowsLink;
    }

    return () => null;
  }, [showClustersPageDemoV3, showWorkflows]);

  if (!shouldShowK8sAddonsMenu) {
    return null;
  }

  return (
    <LinkGroup
      title={AppBarTitle.K8sAddons}
      icon={<Folder16 />}
      e2eSelector="k8sAddonesExpandableSectionTitle"
      alwaysExpanded
    >
      {Boolean(demoAddons) && (
        <>
          <AddonsDemoNodeAutoscalersLink />
          <AddonsDemoExternalDNSLink />
          <AddonsDemoCertManagerLink />
        </>
      )}
      {!!appNavigationChanges && (
        <>
          <HelmLink />
          {showArgoWorkflows && <ArgoWorkflowsLink />}
        </>
      )}
      {demoAddons === false && !!showCertManagerAddon && (
        <K8sAddonCertManager />
      )}
      <WorkflowsLinkToRender />
      {!!comingSoonStaticPageExternalDns && <ExternalDNSDemo />}
      {!!comingSoonStaticPageNodeAutoscalers && <NodeAutoscalersDemo />}
    </LinkGroup>
  );
};
