/* eslint-disable max-lines */
import queryString from "query-string";
import { capitalize, partition } from "lodash";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "@komodorio/design-system/deprecated";
import differenceInSeconds from "date-fns/differenceInSeconds";

import BellScatterShape from "../../monitorsView/assets/bell.svg?react";
import ResponsiveLayout from "../../common/ResponsiveLayout";
import {
  Divider,
  NormalText,
  paddingAndDivider,
  Title,
  WrapWithIcon,
  WrapWithTag,
} from "../common/styles";
import { useWorkflowRunByIdOld } from "../useWorkflowsRuns";
import SuccessIcon from "../assets/gray-success.svg?react";
import {
  blueBrand,
  gray10,
  gray5,
  grayAppBackground,
  pinkBrand,
  pinkForUI,
} from "../../../Colors";
import { useStateInSearchParams } from "../../../shared/hooks/state";
import InfoIcon from "../assets/infoOutlined.svg?react";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";
import SensorLabels from "../common/SensorLabels";
import BackButton from "../common/BackButton";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { ClosedTag, OpenTag } from "../common/StatusTag";
import { useCloseMonitorMutation } from "../../../generated/graphql";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { CheckDetails, WorkflowConfigType } from "../common/types";
import { Label } from "../monitorsConfiguration/common/styles";
import AvailableWorkflows from "../common/availableWorkflows";
import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import { MONITORS_RUN_CHECK_PARAM_KEY } from "../../../shared/config/urlSearchParamsKeys";
import { WorkflowIssueStatus } from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";

import CheckCard from "./checks/CheckCard";
import IssueDetailsDrawer, { ClockIcon } from "./IssueDetailsDrawer";
import CheckDetailsDrawer from "./checks/CheckDetailsDrawer";
import useWorkflowsChecks from "./checks/useWorkflowsChecks";
import Diagram from "./Diagram";
import useDiagramElements from "./useDiagramElements";
import WorkflowCheck from "./checks";
import MarkCloseConfirmation from "./MarkCloseConfirmation";

const FlexRows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin-left: auto;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 25rem auto;
  color: #3b3d45;
  min-height: calc(100vh - 9.5rem);
  position: relative;
`;

const WhiteBackground = styled.div`
  background-color: white;
`;

export const Header = styled.div`
  display: grid;
  row-gap: 1rem;
  padding-top: 1rem;
  color: #3b3d45;
`;

const TagsList = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.75rem;
`;

const Sensors = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: 1rem;
`;

const IssueIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  border-radius: 1.25rem;
  border-radius: 100%;
  background-color: ${pinkForUI};
  margin-inline-end: 1rem;
`;

const ChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid ${gray5};
  z-index: 6;
`;

const ChecksHeader = styled.div`
  ${paddingAndDivider}
`;

const ChecksSection = styled.div`
  background-color: ${grayAppBackground};
  color: ${gray10};
  font-family: Poppins;
  ${paddingAndDivider};
  padding: 0.4rem 1rem;
`;

const ColoredLabel = styled(Label)`
  color: ${blueBrand};
  margin-left: 1rem;
`;

const WorkflowRunView: React.FC = () => {
  const { canManageMonitors } = useHasPermissions();

  const navigate = useNavigate();
  const [selectedCheckType, setSelectedCheckType] = useStateInSearchParams(
    MONITORS_RUN_CHECK_PARAM_KEY
  );
  const [issueDetailsOpen, setIsIssueDetailsOpen] = useState(false);
  const [isCloseIssueModalOpen, setIsCloseIssueModalOpen] = useState(false);
  const { format } = useDateFormatter();
  const { runId } = useParams();
  const queryParams = queryString.parse(useLocation().search);
  const workflowRun = useWorkflowRunByIdOld(runId || "");

  const {
    type = "",
    results = [],
    shortResourceName = "",
    eventTime,
    services,
    cluster,
    namespace,
  } = workflowRun ?? {};
  const { sensors } = workflowRun?.workflowConfiguration ?? {};

  const checkDetails: CheckDetails = useMemo(
    () => ({
      workflowType: type,
      results,
      eventTime,
      services,
      clusterName: cluster,
      namespace,
    }),
    [cluster, eventTime, namespace, results, services, type]
  );
  const allChecks = useWorkflowsChecks(checkDetails);

  const diagramElements = useDiagramElements(
    allChecks,
    type,
    selectedCheckType,
    setSelectedCheckType
  );

  const selectedCheck = useMemo(
    () => allChecks.find((c) => c.type === selectedCheckType),
    [allChecks, selectedCheckType]
  );

  const [causes, findings] = partition(allChecks, (c) => !c.isFinding);

  useEffect(() => {
    if (workflowRun?.type) {
      dispatchEvent(AnalyticEvents.MonitorsView.Run_Opened, {
        referer: queryParams?.referer,
        type: workflowRun?.type,
      });

      dispatchEvent(AnalyticEvents.MonitorsView.Any_Result_Opened, {
        referer: queryParams?.referer,
        type: workflowRun.type,
        eventTime: workflowRun.eventTime,
        status: workflowRun?.closedAt
          ? WorkflowIssueStatus.closed
          : WorkflowIssueStatus.open,
        secondsTillOpen: differenceInSeconds(new Date(), workflowRun.eventTime),
      });
    }
  }, [
    queryParams?.referer,
    workflowRun?.eventTime,
    workflowRun?.type,
    workflowRun?.closedAt,
  ]);

  const renderChecks = (checks: WorkflowCheck[]) =>
    checks.map((c) => (
      <CheckCard
        key={c.type}
        title={c.title}
        subtitle={c.shortOutput}
        icon={c.icon}
        isSelected={selectedCheckType === c.type}
        onClick={() => {
          selectedCheckType === c.type
            ? setSelectedCheckType(null)
            : setSelectedCheckType(c.type);
          dispatchEvent(
            AnalyticEvents.WorkflowsView.Distinct_Check_More_Info_Clicked,
            {
              type: c.type,
              passed: c.passed,
              shortOutput: c.shortOutput,
              runId: workflowRun?.id,
              shortResourceName: workflowRun?.shortResourceName,
              sourceEventId: workflowRun?.sourceEventId,
              workflowName: workflowRun?.workflowConfiguration?.name,
            }
          );
        }}
      />
    ));

  const [closed, setClosed] = useState(false);
  useEffect(() => {
    setClosed(closed || !!workflowRun?.closedAt);
  }, [closed, workflowRun]);

  const [, closeWorkflow] = useCloseMonitorMutation();
  const markWorkflowRunAsClosed = useCallback(() => {
    (async function () {
      if (workflowRun) {
        await closeWorkflow({ workflowRunId: workflowRun.id });
        setIsCloseIssueModalOpen(false);
        setClosed(true);
      }
    })();
  }, [workflowRun, closeWorkflow]);

  const Icon = workflowRun?.type
    ? AvailableWorkflows[workflowRun.type].icon
    : BellScatterShape;

  return (
    <>
      <WhiteBackground>
        <ResponsiveLayout>
          <Header>
            <BackButton onClick={() => navigate("/main/monitors/triggered")}>
              Back
            </BackButton>
            <WrapWithIcon>
              <IssueIcon>
                <Icon fill={pinkBrand} />
              </IssueIcon>
              <Title fontSize="24px">
                {`${capitalize(type)} Monitor: ` +
                  `${
                    !cluster
                      ? `${shortResourceName}`
                      : !namespace
                      ? `${cluster}/${shortResourceName}`
                      : `${cluster}/${namespace}/${shortResourceName}`
                  }`}
              </Title>
              <WrapWithTag>
                {closed ? <ClosedTag large={true} /> : <OpenTag large={true} />}
              </WrapWithTag>
              <FlexRows>
                {canManageMonitors && (
                  <Button
                    icon={SuccessIcon}
                    disabled={closed}
                    onClick={() => setIsCloseIssueModalOpen(true)}
                  >
                    Mark as Closed
                  </Button>
                )}
                <Button
                  icon={InfoIcon}
                  onClick={() => setIsIssueDetailsOpen((prev) => !prev)}
                >
                  Details
                </Button>
              </FlexRows>
            </WrapWithIcon>
            <TagsList>
              <WrapWithIcon>
                <ClockIcon />
                <NormalText>
                  {eventTime && format(new Date(eventTime))}
                </NormalText>
              </WrapWithIcon>
              {sensors && (
                <Sensors>
                  <SensorLabels sensor={sensors[0]} />
                </Sensors>
              )}
              {services?.[0] && type === WorkflowConfigType.Availability && (
                <Link to={`/main/deep-dive/${services[0]}`}>
                  <ColoredLabel>View workload</ColoredLabel>
                </Link>
              )}
            </TagsList>
          </Header>
        </ResponsiveLayout>
      </WhiteBackground>
      <Divider />
      <Content>
        <ChecksContainer>
          <ChecksHeader>
            <Title>Checks & Findings</Title>
            <NormalText>
              We performed the following checks to better troubleshoot the
              issue:
            </NormalText>
          </ChecksHeader>
          <ChecksSection>
            <NormalText>Possible Causes</NormalText>
          </ChecksSection>
          {renderChecks(causes)}
          <ChecksSection>
            <NormalText>Findings</NormalText>
          </ChecksSection>
          {renderChecks(findings)}
        </ChecksContainer>
        <CheckDetailsDrawer
          check={selectedCheck}
          setSelectedCheckType={setSelectedCheckType}
        />
        <Diagram nodes={diagramElements.nodes} edges={diagramElements.edges} />
        <IssueDetailsDrawer
          workflowRun={workflowRun}
          handleClose={() => setIsIssueDetailsOpen(false)}
          isOpen={issueDetailsOpen}
        />
      </Content>
      <MarkCloseConfirmation
        isOpen={isCloseIssueModalOpen}
        handleCancellation={() => setIsCloseIssueModalOpen(false)}
        handleConfirmation={markWorkflowRunAsClosed}
      />
    </>
  );
};

export default WorkflowRunView;
