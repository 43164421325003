import React, { useEffect, useMemo, useState } from "react";
import { OptionType, Select } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { ServiceIdentifierType } from "komodor-types";
import { isEmpty } from "lodash";

import { getServiceIdentifierTypeBy } from "../appViewScopingHooks";
import { tryParseLabel } from "../../../../../utils/tryParseLabel";
import { notifyDDError } from "../../../../../../../../../shared/hooks/exceptionManagement";
import { useCreationPageContext } from "../../../context/CreationPageContext";
import { AppErrors } from "../../../types/creationPageTypes";
import { appViewAriaLabels } from "../../../../../appViewAriaLabels";

import { ComparandSelector } from "./internal/ComparandSelector";
import { toSelectableServiceIdentifierTypeOption } from "./internal/utils";
import { VALUE_MISSING_ERROR } from "./internal/constants";
import { PaddedTypography } from "./internal/PaddedTypography";

const Container = styled.div`
  display: flex;
  align-items: start;
  gap: 12px;
`;

export type Predicate = {
  serviceIdentifierType?: ServiceIdentifierType;
  comparand: Partial<{ key: string; value: string }>;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const constructPredicateFrom = (
  serviceIdentifier: string,
  serviceIdentifierType: ServiceIdentifierType
): Predicate => ({
  serviceIdentifierType,
  comparand: (() => {
    switch (serviceIdentifierType) {
      case ServiceIdentifierType.NAMESPACE:
        return { value: serviceIdentifier };

      case ServiceIdentifierType.LABEL:
        try {
          const label = tryParseLabel(serviceIdentifier);

          const labelKey = Object.keys(label).at(0);
          const labelValue = Object.values(label).at(0);

          return { key: labelKey, value: labelValue };
        } catch (error) {
          notifyDDError(error as Error);

          return {};
        }

      default:
        return {};
    }
  })(),
});

interface Dictionary<T> {
  [Key: string]: T;
}

export interface ScopingOptions {
  namespaces?: Set<string>;
  labels?: Dictionary<Set<string>>;
}

interface ScopingPredicateConstructorProps {
  scopingOptions: ScopingOptions;
  onPredicateUpdate: (selection: Predicate) => void;
  existingPredicate?: Predicate;
}

export const ScopingPredicateConstructor: React.FC<
  ScopingPredicateConstructorProps
> = ({
  scopingOptions,
  onPredicateUpdate,
  existingPredicate,
}: ScopingPredicateConstructorProps) => {
  const {
    errorState: { servicesSelectedError },
    setErrorState,
  } = useCreationPageContext();
  const [
    selectedServiceIdentifierTypeOption,
    setSelectedServiceIdentifierTypeOption,
  ] = useState<OptionType<ServiceIdentifierType>>();
  const [selectedPredicate, setSelectedPredicate] = useState<Predicate>();

  const serviceIdentifierTypeMissing =
    servicesSelectedError === AppErrors.NoServiceIdentifierTypeSelected;

  const handleServiceIdentifierTypeSelection = (
    selectedOption: OptionType<ServiceIdentifierType>
  ) => {
    setSelectedServiceIdentifierTypeOption(selectedOption);

    const updatedPredicate: Predicate = {
      serviceIdentifierType: selectedOption.value,
      comparand: {},
    };

    setSelectedPredicate(updatedPredicate);
    onPredicateUpdate(updatedPredicate);
    setErrorState({ servicesSelectedError: undefined });
  };

  const serviceIdentifierTypeOptions = useMemo(
    () =>
      Object.entries(scopingOptions)
        .filter(([, values]) => !isEmpty(values))
        .map(([serviceProperty]) => getServiceIdentifierTypeBy(serviceProperty))
        .map(toSelectableServiceIdentifierTypeOption),
    [scopingOptions]
  );

  useEffect(() => {
    if (existingPredicate && existingPredicate.serviceIdentifierType) {
      setSelectedServiceIdentifierTypeOption(
        serviceIdentifierTypeOptions.find(
          (option) => option.value === existingPredicate.serviceIdentifierType
        )
      );
      setSelectedPredicate(existingPredicate);
    }
  }, [scopingOptions, existingPredicate, serviceIdentifierTypeOptions]);

  return (
    <Container aria-label={appViewAriaLabels.dynamicScopeSelector}>
      <PaddedTypography size="medium">where</PaddedTypography>
      <Select
        width="12rem"
        options={serviceIdentifierTypeOptions}
        onChange={handleServiceIdentifierTypeSelection}
        value={selectedServiceIdentifierTypeOption}
        placeholder="Select"
        errorMessage={
          serviceIdentifierTypeMissing ? VALUE_MISSING_ERROR : undefined
        }
      />
      {selectedServiceIdentifierTypeOption?.label && (
        <PaddedTypography size="medium">is</PaddedTypography>
      )}
      <ComparandSelector
        comparandType={selectedServiceIdentifierTypeOption?.value}
        scopingOptions={scopingOptions}
        selectedPredicate={selectedPredicate}
        onPredicateUpdate={(predicate: Predicate) => {
          setSelectedPredicate(predicate);
          onPredicateUpdate(predicate);
        }}
      />
    </Container>
  );
};
