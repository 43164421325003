import React, { useState } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ConfigMapEventGroup from "../../../EventGroup/configMap/ConfigMapEventGroup";
import {
  Action,
  Change,
  ConfigChanges,
  EventDetailsContainer,
  From,
  To,
} from "../common";
import EventDetailsSection from "../components/EventDetailsSection";
import EventDetailsTitle from "../components/EventDetailsTitle";
import detailsIcon from "../../assets/details.svg";
import configChangesIcon from "../../assets/config-changes.svg";
import { TextBolder, Text } from "../../../typography";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";
import Modal from "../../../Modal";
import { ObjectDiff } from "../../../ObjectDiff";
import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import { CONFIGURATION_CONFIGMAPS_EVENT_ID } from "../../../../../shared/hooks/resources-api/requestResponseMaps";

interface ConfigMapDetailsProps {
  eventGroup: ConfigMapEventGroup;
}

function createDiffBetweenConfig(
  oldConfig: Record<string, string>,
  newConfig: Record<string, string>
): ConfigChanges {
  const allKeys = new Set([
    ...Object.keys(oldConfig ?? {}),
    ...Object.keys(newConfig ?? {}),
  ]);
  return Array.from(allKeys).map((key) => ({
    name: key,
    previous: oldConfig?.[key] || "",
    current: newConfig?.[key] || "",
  }));
}

interface ConfigmapData {
  [key: string]: string | Record<string, string>;

  data: Record<string, string>;
}

export const ConfigMapDetails: React.FC<ConfigMapDetailsProps> = ({
  eventGroup,
}) => {
  const { format } = useDateFormatter();
  const [modalOpen, setModalOpen] = useState(false);

  const firstEvent = eventGroup.events[0];
  const eventId = firstEvent.id;

  const {
    data: dataFromAPI,
    loading,
    error,
  } = useResourcesAPIGet(CONFIGURATION_CONFIGMAPS_EVENT_ID, {
    id: eventId,
    fields: ["oldData", "newData"],
  });

  if (loading) {
    return (
      <EventDetailsContainer>
        <EventDetailsTitle title={"Fetching data..."} />
      </EventDetailsContainer>
    );
  }

  if (error) {
    return (
      <EventDetailsContainer>
        <EventDetailsTitle title={"Failed to fetch data."} />
      </EventDetailsContainer>
    );
  }

  const oldConfigmapData = dataFromAPI?.data[0]?.oldData as ConfigmapData;
  const newConfigmapData = dataFromAPI?.data[0]?.newData as ConfigmapData;

  const oldData = oldConfigmapData?.["data"] ?? {};
  const newData = newConfigmapData?.["data"] ?? {};
  const changes = createDiffBetweenConfig(oldData, newData).filter(
    (c) => c.current !== c.previous
  );
  return (
    <EventDetailsContainer>
      <EventDetailsTitle title={eventGroup.summary} />
      <EventDetailsSection
        icon={detailsIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
        title="Details"
      >
        <TextBolder>Change time</TextBolder>
        <br />
        <Text>{format(eventGroup.endTime)}</Text>
      </EventDetailsSection>
      <EventDetailsSection
        title="Changes"
        icon={configChangesIcon}
        iconDimensions={{ height: "50%", width: "50%" }}
      >
        {changes.length
          ? changes.map((c) => (
              <Change key={c.name}>
                <div>{c.name}</div>
                {c.previous && <From title={c.previous}>{c.previous}</From>}
                {c.current && <To title={c.current}>{c.current}</To>}
              </Change>
            ))
          : null}
        <br />
        <Action onClick={() => setModalOpen(true)}>View diff</Action>
      </EventDetailsSection>
      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => setModalOpen(false)}
        title={eventGroup.summary}
      >
        <ObjectDiff oldObj={oldConfigmapData} newObj={newConfigmapData} />
      </Modal>
    </EventDetailsContainer>
  );
};
