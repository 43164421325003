import React, { ChangeEvent } from "react";
import styled from "styled-components";

import { blueForUIText, gray13 } from "../../../Colors";

const SwitchContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  width: max-content;
`;

const Switch = styled.label`
  position: relative;
  width: 40px;
  height: 20px;
`;

const SliderInput = styled.input<{ color?: string }>`
  &:checked + .slider {
    background-color: ${({ color = blueForUIText }) => color};
  }
  &:checked + .slider:before {
    transform: translateX(20px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${gray13};
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }
  &.round {
    border-radius: 34px;
  }
  &.round:before {
    border-radius: 50%;
  }
`;

const SwitchLabel = styled.label`
  font-size: 0.75rem;
`;

const Switcher: React.FC<{
  id?: string;
  fieldName?: string;
  color?: string;
  className?: string;
  checked: boolean;
  handleSwitcherChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ id, fieldName, checked, color, className, handleSwitcherChange }) => (
  <SwitchContainer className={className}>
    {fieldName && <SwitchLabel htmlFor={id}>{fieldName}</SwitchLabel>}
    <Switch>
      <SliderInput
        id={id}
        type="checkbox"
        checked={checked}
        color={color}
        onChange={handleSwitcherChange}
      />
      <Slider className="slider round" />
    </Switch>
  </SwitchContainer>
);

export default Switcher;
