import React, { useEffect, useMemo, useRef } from "react";
import { parse, format as formatDate, parseISO } from "date-fns";

import Input from "../controls/Input";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";
import { useTimezone } from "../../../shared/context/TimezoneProvider";

import onTimeInputKeyDown from "./onTimeInputKeyDown";

const d = (n: number) => String(n).padStart(2, "0");

function getDefaultTimeZoneOffset() {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return `${offset < 0 ? "+" : "-"}${d(Math.floor(o / 60))}:${d(o % 60)}`;
}

const getTimeZoneOffset = (timezone: string) => {
  if (timezone === "default") return getDefaultTimeZoneOffset();
  if (timezone === "UTC") return "+00:00";
  const sign = timezone[7] === "+" ? "-" : "+";
  return `${sign}${timezone.substring(8).padStart(2, "0")}`;
};

export type TimeInputRef = {
  getValue(reference: Date): Date | null;
  setValue(value: Date): void;
};

type TimeInputProps = { id?: string; defaultValue: Date };
const TimeInput = React.forwardRef<TimeInputRef, TimeInputProps>(
  ({ id, defaultValue }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { timezone } = useTimezone();
    const { format } = useDateFormatter({
      hour: "2-digit",
      year: undefined,
      month: undefined,
      day: undefined,
      second: undefined,
      timeZoneName: undefined,
    });
    useEffect(() => {
      if (ref && "current" in ref) {
        ref.current = {
          getValue(reference) {
            if (!inputRef.current) return null;
            const base = formatDate(reference, "yyyy-MM-dd");
            const time = parse(inputRef.current.value, "hh:mm a", Date.now());
            const hours = d(time.getHours());
            const minutes = d(time.getMinutes());
            const offset = getTimeZoneOffset(timezone);
            return parseISO(`${base}T${hours}:${minutes}:00${offset}`);
          },
          setValue(value) {
            if (!inputRef.current) return;
            inputRef.current.value = format(value);
          },
        };
      }
    }, [format, ref, timezone]);

    const formattedValue = useMemo(
      () => format(defaultValue),
      [defaultValue, format]
    );

    return (
      <Input
        id={id}
        ref={inputRef}
        onKeyDown={onTimeInputKeyDown}
        defaultValue={formattedValue}
        size={8}
      />
    );
  }
);

export default TimeInput;
