import React from "react";
import { ReplicaSet as ReplicaSetType } from "kubernetes-types/apps/v1.d";
import { TabPanel } from "@komodorio/design-system/deprecated";
import { RS } from "@komodorio/design-system/icons";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { Header } from "../common/Header/Header";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceYAMLTab } from "../tabs/ResourceYAMLTab";

import Resource, { ResourceTabName } from ".";

export default class ReplicaSet implements Resource {
  readonly agentId;
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly annotations;
  readonly icon;
  readonly labels;
  readonly drawerTabs = [{ label: ResourceTabName.Yaml }];
  readonly actions = [
    AvailableActions.Edit,
    AvailableActions.Delete,
    AvailableActions.Describe,
  ];

  readonly replicas;
  readonly selector;
  readonly image;
  readonly ownerReferences;
  readonly fullObj;
  readonly defaultTab;

  constructor(cluster: string, replicaset: ReplicaSetType, agentId: string) {
    this.agentId = agentId;
    this.id = replicaset.metadata?.uid ?? "";
    this.cluster = cluster;
    this.namespace = replicaset.metadata?.namespace ?? "";
    this.name = replicaset.metadata?.name ?? "";
    this.kind = replicaset.kind ?? "";
    this.labels = replicaset.metadata?.labels ?? {};
    this.annotations = replicaset.metadata?.annotations ?? {};
    this.replicas = {
      ready: replicaset.status?.readyReplicas ?? 0,
      desired: replicaset.status?.replicas ?? 0,
    };
    this.selector = replicaset.spec?.selector;
    this.icon = RS;
    this.image = replicaset?.spec?.template?.spec?.containers[0].image;
    this.ownerReferences = replicaset.metadata?.ownerReferences ?? [];
    this.fullObj = replicaset;
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return (
      <>
        <TabPanel eager value={selectedTab} index={0}>
          <ResourceYAMLTab resource={this} />
        </TabPanel>
      </>
    );
  }

  renderDrawerHeader(leftActions?: JSX.Element): JSX.Element {
    return (
      <Header
        resource={this}
        labels={[
          { name: "type", value: this.kind },
          { name: "cluster", value: this.cluster },
          { name: "namespace", value: this.namespace },
          {
            name: "image",
            value: this.image
              ? this.image.slice(this.image.lastIndexOf("/") + 1)
              : undefined,
          },
        ]}
        leftActions={leftActions}
      />
    );
  }
}
