import styled from "styled-components";

import { blueBrand } from "../../../../Colors";
import { textBolderStyle } from "../../../common/typography";

const FilterLabel = styled.div`
  padding: 0.5rem;
  border-radius: 2px;
  background-color: #dde5fb;
  color: ${blueBrand};
  ${textBolderStyle}
`;

export default FilterLabel;
