import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";

import { WorkflowConfiguration } from "../../common/types";
import Select from "../../../common/controls/Select";
import { RowFieldContainer } from "../common/styles";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum DeployStateSink {
  All = "All",
  Failure = "Failure",
  Successful = "Successful",
}

const sinksOptions: Record<DeployStateSink, string> = {
  [DeployStateSink.All]: "ALL",
  [DeployStateSink.Failure]: "Only failed deploys",
  [DeployStateSink.Successful]: "Only successful deploys",
};

const Container = styled(RowFieldContainer)`
  gap: 0.8rem;
`;
const DeploySinksOptions: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule, setRule }) => {
  const handleOptionChange = (option: string) => {
    setRule((prev) => ({
      ...prev,
      sinksOptions: { notifyOn: [option] },
    }));
  };

  return (
    <Container>
      <Typography variant="text" size="medium">
        For
      </Typography>
      <Select
        onChange={(e) => {
          handleOptionChange(e.target.value);
        }}
        value={rule?.sinksOptions?.notifyOn?.[0]}
      >
        {Object.entries(sinksOptions).map(([sinkKey, sinkLabel]) => (
          <option key={sinkKey} value={sinkKey}>
            {sinkLabel}
          </option>
        ))}
      </Select>
    </Container>
  );
};

export default DeploySinksOptions;
