import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";

import {
  ApiServerUrlClusterErrorResponse,
  RbacClusterStatus,
} from "@/generated/auth";
import {
  getColumns,
  columnsInitialSorting,
  columnsInitialState,
} from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/columns";
import { prepareRbacClusterStatusRowsForDataGrid } from "@/components/Settings/General/BetaFeatures/RbacClusterSync/RbacClusterStatusDataTable/rows";
import { useActiveAgents } from "@/shared/hooks/useAgents";

export const RbacClusterStatusDataTable: React.FC<{
  isLoading: boolean;
  data?: RbacClusterStatus[];
  onApiServerUrlChange: (clusterName: string, newValue: string) => void;
  setHasValidationError(value: boolean): void;
  clustersThatFailedToUpdate: ApiServerUrlClusterErrorResponse[];
}> = ({
  isLoading,
  data,
  onApiServerUrlChange,
  setHasValidationError,
  clustersThatFailedToUpdate,
}) => {
  const activeAgents = useActiveAgents();
  const preparedRows = useMemo(() => {
    return prepareRbacClusterStatusRowsForDataGrid(
      activeAgents,
      data,
      clustersThatFailedToUpdate
    ).filter((cluster) => cluster.clusterName !== "*" && cluster.isConnected);
    // We don't want to refresh everytime the agents are refreshed, it will cause user input to reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, clustersThatFailedToUpdate]);

  return (
    <DataGridPro
      slots={{
        toolbar: CustomToolbar,
      }}
      loading={isLoading}
      initialState={{
        columns: columnsInitialState,
        sorting: columnsInitialSorting,
      }}
      columns={getColumns({ onApiServerUrlChange, setHasValidationError })}
      rows={preparedRows}
      rowReordering={false}
      hideFooter={true}
      disableRowSelectionOnClick={true}
      disableMultipleRowSelection={true}
    />
  );
};

export const CustomToolbar: React.FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};
