import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "@komodorio/design-system/deprecated";

import { Z_INDEX_ABOVE_MODAL } from "../../drawers/styles";

interface RevisionComparer {
  //   revisions: {
  //     revision: string;
  //     id: string;
  //   }[];
  revisions: string[];
  currentRevision: string;
  onRevisionChange: (value?: string) => void;
  initialRevision?: string;
  title?: string;
}
export interface GenericResource {
  kind: string;
  metadata: {
    name: string;
  };
}
const RevisionComparer: React.FC<RevisionComparer> = ({
  revisions,
  currentRevision,
  onRevisionChange,
  initialRevision,
  title,
}) => {
  const [compareToRevision, setCompareToRevision] = useState<
    string | undefined
  >(initialRevision);
  useEffect(() => {
    if (currentRevision === compareToRevision) {
      setCompareToRevision(undefined);
      onRevisionChange(undefined);
    }
  }, [compareToRevision, currentRevision, onRevisionChange]);
  const revisionsOptions = useMemo(() => {
    return [
      {
        label: "Select revision",
        value: undefined,
      },
      ...revisions
        .filter((r) => r !== currentRevision)
        .map((r) => ({
          label: `Revision #${r}`,
          value: r,
        }))
        .reverse(),
    ];
  }, [currentRevision, revisions]);
  const onRevisionChangeCallback = useCallback(
    (value: string | undefined) => {
      onRevisionChange(value);
      setCompareToRevision(value);
    },
    [onRevisionChange]
  );

  return (
    <Select
      label={title ?? "Compare with"}
      size="medium"
      options={revisionsOptions}
      width="10rem"
      value={revisionsOptions.find((r) => compareToRevision === r?.value)}
      onChange={(o) => onRevisionChangeCallback(o.value)}
      listZIndex={Z_INDEX_ABOVE_MODAL}
      listMaxHeight={"20rem"}
    />
  );
};

export default RevisionComparer;
