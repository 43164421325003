import React, { useEffect, useMemo, useState } from "react";

import {
  isMetricsSupportedSelector,
  setNoisyNeighborsNodesMetricsSelector,
} from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { Metrics } from "../../../Metrics/types";
import { AvailabilityRunWithResults } from "../../types";
import { useNodeMetrics } from "../../../Metrics/useNodeMetrics";
import { useIssueMetricsEndTime } from "../useCorrelatedMetrics";

const NoisyNeighborsDataFetch: React.FC<{
  issue: AvailabilityRunWithResults | null;
}> = ({ issue }) => {
  const setNoisyNeighborsNodesMetricsInStore = useInvestigationModeStore(
    setNoisyNeighborsNodesMetricsSelector
  );
  const [nodesMetricsData, setNodesMetricsData] =
    useState<Map<string, Metrics>>();

  const isMetricsSupported = useInvestigationModeStore(
    isMetricsSupportedSelector
  );
  const metricsEndTime = useIssueMetricsEndTime(issue);

  useEffect(() => {
    if (nodesMetricsData) {
      setNoisyNeighborsNodesMetricsInStore(nodesMetricsData);
    }
  }, [
    isMetricsSupported,
    nodesMetricsData,
    setNoisyNeighborsNodesMetricsInStore,
  ]);

  const metricsFetchElement = useMemo(() => {
    const nodesInfo = issue?.results?.noisyNeighbors?.output.nodesInfo ?? [];
    if (!nodesInfo) {
      return null;
    }
    return nodesInfo.map((nodeInfo, index) => (
      <NodeMetricsFetchLoop
        key={index}
        clusterName={issue?.cluster ?? ""}
        nodeName={nodeInfo.nodeName}
        isMetricsSupported={isMetricsSupported}
        metricsEndTime={metricsEndTime}
        setNodesMetricsData={setNodesMetricsData}
      />
    ));
  }, [
    isMetricsSupported,
    issue?.cluster,
    issue?.results?.noisyNeighbors?.output.nodesInfo,
    metricsEndTime,
  ]);

  return <>{metricsFetchElement}</>;
};
export default NoisyNeighborsDataFetch;

export const NodeMetricsFetchLoop: React.FC<{
  clusterName: string;
  nodeName: string;
  isMetricsSupported: boolean | null;
  metricsEndTime: number;
  setNodesMetricsData: React.Dispatch<
    React.SetStateAction<Map<string, Metrics> | undefined>
  >;
}> = ({
  clusterName,
  nodeName,
  isMetricsSupported,
  metricsEndTime,
  setNodesMetricsData,
}) => {
  const nodeMetrics = useNodeMetrics(
    clusterName,
    nodeName,
    metricsEndTime,
    !!isMetricsSupported
  );
  useEffect(() => {
    if (nodeMetrics) {
      setNodesMetricsData((prev) => {
        if (!prev) {
          return new Map([[nodeName, nodeMetrics]]);
        }
        const updatedMap = new Map(prev);
        updatedMap.set(nodeName, nodeMetrics);
        return updatedMap;
      });
    }
  }, [nodeMetrics, nodeName, setNodesMetricsData]);
  return null;
};
