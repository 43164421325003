import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

import clusterViewContent from "../assets/screen-clusterOverview.png";
import drawerEol from "../assets/drawer-eol.png";
import drawerNoisyNeighbor from "../assets/drawer-noisyNeighbor.png";
import drawerLowResiliency from "../assets/drawer-reseliency.png";
import drawerExpiredCerts from "../assets/drawer-certMgr.png";
import drawerManagePolicy from "../assets/drawer-managePolicies.png";
import drawerApi from "../assets/drawer-api.png";
import bestPractices from "../assets/reliability-tableBestPractices.png";
import drawerReviewAndApplyRecs from "../assets/drawer-applyReommendations.png";
import drawerCostAutopilot from "../assets/drawer-autoPilot.png";
import { ClickableArea, RelativeContainer, CloseButtonArea } from "../styles";
import { clustersDemoRoutes } from "../constants";

const SelectableIssueRow = styled(ClickableArea)<{ top: string }>`
  width: 60.1%;
  height: 4%;
  left: 36%;
  top: ${({ top }) => top};
`;

const SelectableViolationRow = styled(ClickableArea)<{ top: string }>`
  width: 61.1%;
  height: 3.1%;
  left: 35.2%;
  top: ${({ top }) => top};
`;

const BackButtonArea = styled(ClickableArea)`
  width: 3%;
  height: 1.7%;
  top: 3.3%;
  left: 1.6%;
  border-radius: 4px;
`;

const RuntimeViolationsButton = styled(ClickableArea)`
  width: 13%;
  height: 2%;
  top: 32.8%;
  left: 35%;
`;

const BestPracticesButton = styled(ClickableArea)`
  width: 15%;
  height: 2%;
  top: 32.8%;
  left: 47.9%;
`;

const ManagePoliciesButton = styled(ClickableArea)`
  width: 12.1%;
  height: 1.6%;
  top: 33%;
  left: 82.7%;
  border-radius: 4px;
`;

const SaveButton = styled(ClickableArea)`
  width: 8.8%;
  height: 1.6%;
  top: 57.9%;
  left: 85%;
  border-radius: 4px;
`;

const AutopilotButton = styled(ClickableArea)`
  width: 12.1%;
  height: 1.6%;
  top: 68.1%;
  left: 67.6%;
  border-radius: 4px;
`;

const ViewSavingsButton = styled(ClickableArea)`
  width: 15.2%;
  height: 1.6%;
  top: 68.1%;
  left: 80.9%;
  border-radius: 4px;
`;

const InventoryPodsButton = styled(ClickableArea)`
  width: 21.5%;
  height: 3.9%;
  top: 76.7%;
  left: 74.6%;
  border-radius: 4px;
`;

const CloseButtonVariant = styled(CloseButtonArea)<{
  top: string;
  left: string;
}>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

const ViolationDrawerRow = styled(ClickableArea)`
  width: 94.7%;
  height: 39px;
  top: 405px;
  left: 2.6%;
`;

const drawerButtonPlacemenet: Record<string, { top: string; left: string }> = {
  [drawerEol]: { top: "33px", left: "22.2px" },
  [drawerNoisyNeighbor]: { top: "33px", left: "22.2px" },
  [drawerLowResiliency]: { top: "18px", left: "15px" },
  [drawerExpiredCerts]: { top: "18px", left: "14px" },
  [drawerManagePolicy]: { top: "18px", left: "18px" },
  [drawerApi]: { top: "32px", left: "22.2px" },
  [drawerReviewAndApplyRecs]: { top: "18px", left: "22px" },
  [drawerCostAutopilot]: { top: "18px", left: "18px" },
};

const BestPracticesImg = styled.img`
  && {
    position: absolute;
    left: calc(35.1%);
    top: calc(32.1222% + 18px);
    width: calc(61% + 2px);

    @media (max-width: 1800px) {
      position: absolute;
      left: calc(35.1% + 1px);
      width: calc(61% + 2px);
      top: calc(32.1222% + 16px);
    }

    @media (max-width: 1600px) {
      position: absolute;
      left: calc(35.1% + 1px);
      width: calc(61% + 2px);
      top: calc(32.1222% + 14px);
    }
  }
`;

export const ClusterOverview: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  const [secondaryDrawerImgSrc, setSecondaryImageSrc] = useState<string | null>(
    null
  );
  const [isShowBestPractices, setIsShowBestPractices] = useState(false);
  return (
    <RelativeContainer>
      <img src={clusterViewContent} alt={"cluster view content"} />
      <BackButtonArea onClick={() => navigate(`../`, { relative: "path" })} />
      <SelectableIssueRow
        top="15.8%"
        onClick={() => navigate(clustersDemoRoutes.eventsPullerService)}
      />
      <SelectableIssueRow
        top="19.8%"
        onClick={() =>
          navigate(
            `../../${clustersDemoRoutes.workflows}/${clustersDemoRoutes.wfIcpMapGenerator}`,
            { relative: "path" }
          )
        }
      />
      <SelectableIssueRow
        top="23.8%"
        onClick={() =>
          navigate(
            `../../${clustersDemoRoutes.workflows}/${clustersDemoRoutes.wfLaneExtractorRegression}`,
            { relative: "path" }
          )
        }
      />
      <BestPracticesButton onClick={() => setIsShowBestPractices(true)} />
      {isShowBestPractices && <BestPracticesImg src={bestPractices} />}
      <RuntimeViolationsButton onClick={() => setIsShowBestPractices(false)} />
      <ManagePoliciesButton
        onClick={() => setDrawerImgSrc(drawerManagePolicy)}
      />
      <SelectableViolationRow
        top="36.6%"
        onClick={() => setDrawerImgSrc(drawerEol)}
      />
      <SelectableViolationRow
        top="39.6%"
        onClick={() => setDrawerImgSrc(drawerNoisyNeighbor)}
      />
      <SelectableViolationRow
        top="42.7%"
        onClick={() => setDrawerImgSrc(drawerLowResiliency)}
      />
      <SelectableViolationRow
        top="45.7%"
        onClick={() => setDrawerImgSrc(drawerExpiredCerts)}
      />
      <SaveButton onClick={() => setDrawerImgSrc(drawerReviewAndApplyRecs)} />
      <AutopilotButton onClick={() => setDrawerImgSrc(drawerCostAutopilot)} />
      <ViewSavingsButton onClick={() => navigate(clustersDemoRoutes.cost)} />
      <InventoryPodsButton onClick={() => navigate(clustersDemoRoutes.atm)} />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...drawerButtonPlacemenet[drawerImgSrc!]}
            onClick={() => setDrawerImgSrc(null)}
          />
        }
      >
        {drawerImgSrc === drawerEol ? (
          <ViolationDrawerRow onClick={() => setSecondaryImageSrc(drawerApi)} />
        ) : null}
      </MockDrawer>
      <MockDrawer
        open={secondaryDrawerImgSrc !== null}
        imgSrc={secondaryDrawerImgSrc}
        onClose={() => setSecondaryImageSrc(null)}
        closeButton={
          <CloseButtonVariant
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...drawerButtonPlacemenet[secondaryDrawerImgSrc!]}
            onClick={() => setSecondaryImageSrc(null)}
          />
        }
      />
    </RelativeContainer>
  );
};

type MockDrawerProps = {
  open: boolean;
  onClose: () => void;
  imgSrc: string | null;
  children?: React.ReactNode;
  closeButton: React.ReactNode;
};
export const MockDrawer: React.FC<MockDrawerProps> = ({
  open,
  onClose,
  imgSrc,
  children,
  closeButton,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      {imgSrc && <img src={imgSrc} width={864} />}
      {closeButton}
      {children}
    </Drawer>
  );
};
