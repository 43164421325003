import React, { useState } from "react";

import { SetTimeWindow, TimeWindow } from "../../shared/types/TimeWindow";
import { useInterval } from "../common/useInterval";

import ButtonGroup from "./ButtonGroup";
import { ControlButton, FBIcon, FFIcon } from "./controlButtons";

interface TimeWindowControlsProps {
  timeWindow: TimeWindow;
  setTimeWindow: SetTimeWindow;
}

const TimeWindowControls: React.FC<TimeWindowControlsProps> = ({
  timeWindow,
  setTimeWindow,
}) => {
  const [now, setNow] = useState(Date.now());
  useInterval(() => setNow(Date.now()), 10_000);

  return (
    <ButtonGroup>
      <ControlButton
        aria-label="time-window rewind"
        icon={FBIcon}
        onClick={() => {
          setTimeWindow({
            end: timeWindow.start,
            start: new Date(
              timeWindow.start.getTime() -
                (timeWindow.end.getTime() - timeWindow.start.getTime())
            ),
          });
        }}
      />
      <ControlButton
        aria-label="time-window forward"
        icon={FFIcon}
        onClick={() => {
          setTimeWindow({
            start: timeWindow.end,
            end: new Date(
              timeWindow.end.getTime() +
                (timeWindow.end.getTime() - timeWindow.start.getTime())
            ),
          });
        }}
        disabled={timeWindow.end.getTime() >= now - 30_000}
      />
    </ButtonGroup>
  );
};

export default TimeWindowControls;
