import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { formatDistanceToNow } from "date-fns";
import { TooltipWrapper } from "react-tooltip";
import cronstrue from "cronstrue";
import { palette } from "@komodorio/design-system";

import { HighlightedText } from "../common/HighlightedText";
import { dispatchEvent } from "../../shared/hooks/analytics";
import useDateFormatter from "../../shared/hooks/useDateFormatter";
import { ServiceInfo } from "../../shared/types/ServiceInfo";
import ServiceTileTag from "../servicesView/ServiceTileTag/ServiceTileTag";
import { S2 } from "../common/typography";
import { BlackLabel } from "../common/Label";
import { gray10, gray9 } from "../../Colors";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { JobState } from "../../shared/types/job";
import {
  Container,
  HealthStatus,
  Info,
  SpacerDot,
  Tags,
} from "../servicesView/styles";
import { DEFAULT_TOOLTIP } from "../../shared/constants/tooltipIds";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const TileContainer = styled(Container)`
  border-top: none;
  height: 10.5rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const Title = styled(S2)`
  grid-area: title;
  word-break: break-word;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const DeletedLabel = styled.div`
  color: ${gray10};
  :after {
    content: "Deleted";
  }
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const FilterStyledLabel = styled(BlackLabel)`
  border-radius: 0.25rem;
  background-color: ${gray9};
  font-style: light;
  color: black;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const Status = styled(HealthStatus)<{ state: JobState }>`
  color: ${({ state }) => {
    switch (state) {
      case JobState.completed:
      case JobState.resumed:
      case JobState.inprogress:
      case JobState.started:
        return palette.green[700];

      case JobState.deleted:
      case JobState.suspended:
      case JobState.noRuns:
      case JobState.unknown:
        return palette.gray[500];

      case JobState.failed:
        return palette.pink[500];

      default:
        return palette.gray[500];
    }
  }};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const JobTile: React.FC<{
  job: ServiceInfo;
  filterTerm: string;
}> = ({
  job: { id, title, tags, jobLastRunTime, isDeleted, jobState },
  filterTerm,
}) => {
  const { format } = useDateFormatter();
  const clickHandler = useCallback(() => {
    dispatchEvent(AnalyticEvents.JobsView.JobTile_Click, {
      jobName: title,
      jobState,
    });
  }, [jobState, title]);

  return (
    <TileContainer
      to={`/jobs/${encodeURIComponent(id)}`}
      onClick={clickHandler}
    >
      <Title>
        <HighlightedText text={title} term={filterTerm} />
      </Title>
      <Status state={jobState}>
        {jobState?.toUpperCase()}
        {isDeleted && (
          <>
            <SpacerDot />
            <DeletedLabel />
          </>
        )}
      </Status>
      {jobLastRunTime && (
        <Info>
          <TooltipWrapper
            tooltipId={DEFAULT_TOOLTIP}
            content={format(jobLastRunTime)}
          >
            Ran: {formatDistanceToNow(jobLastRunTime, { addSuffix: true })}
          </TooltipWrapper>
        </Info>
      )}
      <Tags>
        {tags.map((tag) =>
          tag.label === "schedule" ? (
            <FilterStyledLabel key={`${tag.label}: ${tag.value}`}>
              <TooltipWrapper
                tooltipId={DEFAULT_TOOLTIP}
                content={cronstrue.toString(tag.value, {
                  throwExceptionOnParseError: false,
                })}
              >
                schedule: <b>{tag.value}</b>
              </TooltipWrapper>
            </FilterStyledLabel>
          ) : (
            <ServiceTileTag
              key={`${tag.label}: ${tag.value}`}
              tag={tag}
              filterTerm={filterTerm}
            />
          )
        )}
      </Tags>
    </TileContainer>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export default memo(JobTile);
