import { useForm } from "react-hook-form";
import React from "react";
import styled from "styled-components";
import { IntegrationType } from "komodor-types";
import { Button } from "@komodorio/design-system/deprecated";

import { useUpsertInstallation } from "../useUpsertInstallation";
import Input from "../../../common/controls/Input";
import { pinkBrand } from "../../../../Colors";
import LinkToDocs from "../../../common/LinkToDocs";

export const FormTextInput = styled(Input)<{ isValid?: boolean }>`
  width: 25rem;
  height: 0.75rem;

  margin-top: 0.25rem;

  ${({ isValid }) =>
    isValid
      ? ""
      : `border: 1px solid ${pinkBrand}; &:focus{border: 1px solid ${pinkBrand};}`}
`;

const Form = styled.form`
  display: grid;
  font-family: "Poppins", sans-serif;
  justify-items: center;
  text-align: center;
  border-radius: 4px;
  width: 40rem;
  height: 22rem;
`;

const H4 = styled.div`
  top: 1.75rem;
  left: 17rem;
  right: 17rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  color: #333333;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const H1 = styled.h1`
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  color: #6e7a96;
  font-size: 27px;
  font-style: normal;
  font-weight: normal;
  line-height: 140.3%;
  text-align: center;
`;

interface GitlabConfiguration {
  privateToken: string;
}

const gitlabDocsHref =
  "https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html#create-a-personal-access-token";

const GitlabInstallation: React.FC<{ closeModalCallback: () => void }> = ({
  closeModalCallback,
}) => {
  const createGitlabInstallation = useUpsertInstallation<GitlabConfiguration>(
    IntegrationType.GITLAB
  );
  const { register, handleSubmit } = useForm<GitlabConfiguration>();
  return (
    <Form>
      <H4>GitLab</H4>
      <H1>Enter your gitlab private token</H1>
      <div>
        <FormTextInput
          id="privateToken"
          {...register("privateToken", { required: true })}
        />
        <LinkToDocs docsUrl={gitlabDocsHref} />
      </div>
      <Button
        variant="primary"
        onClick={handleSubmit(async ({ privateToken }) => {
          await createGitlabInstallation({ privateToken });
          closeModalCallback();
        })}
      >
        Install GitLab integration
      </Button>
    </Form>
  );
};

export default GitlabInstallation;
