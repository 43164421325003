import { useMemo } from "react";

import { NodeCount as GeneratedNodeCount } from "@/generated/accounts";
import {
  allMonths,
  RowData,
} from "@/components/Settings/General/NodesCount/consts";

export const useTableData = (
  clusters: (string | undefined)[],
  data: GeneratedNodeCount[]
): { [clusterName: string]: { [month: string]: number } } => {
  return useMemo(() => {
    // Create a matrix for the table
    const tableData: { [clusterName: string]: { [month: string]: number } } =
      {};

    clusters.forEach((clusterName) => {
      if (!clusterName) return;
      tableData[clusterName] = {};
      allMonths.forEach((month) => {
        tableData[clusterName][month] = 0;
      });
    });

    // Populate the matrix with data
    data.forEach((item) => {
      if (!item.clusterName) return;
      const date = new Date(String(item.createdAt));
      const month = allMonths[date.getUTCMonth()];
      if (!item.nodeCount) return;
      tableData[item.clusterName][month] += item.nodeCount;
    });

    return tableData;
  }, [clusters, data]);
};

export const useMonthSums = (
  tableData: { [clusterName: string]: { [month: string]: number } },
  clusters: (string | undefined)[]
): { [month: string]: number } => {
  return useMemo(() => {
    const monthSums: { [month: string]: number } = {};

    allMonths.forEach((month) => {
      monthSums[month] = clusters.reduce(
        (sum, cluster) => sum + (tableData[String(cluster)]?.[month] || 0),
        0
      );
    });

    return monthSums;
  }, [clusters, tableData]);
};

export const useGetRows = (
  clusters: string[],
  sortedMonths: string[],
  tableData: Record<string, Record<string, number>>,
  monthSums: Record<string, number>
): { rows: RowData[]; totalRow: RowData } => {
  return useMemo(() => {
    const rows = clusters.map((cluster, index) => {
      const row: RowData = { id: String(index), cluster: cluster };
      sortedMonths.forEach((month) => {
        row[month] = tableData[cluster][month];
      });
      return row;
    });

    const totalRow: RowData = {
      id: "total",
      cluster: "TOTAL",
      ...Object.fromEntries(
        sortedMonths.map((month) => [month, monthSums[month]])
      ),
    };

    return { rows, totalRow };
  }, [clusters, monthSums, sortedMonths, tableData]);
};
