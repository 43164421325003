import { useEffect, useMemo } from "react";

import { TimeWindow } from "../../shared/types/TimeWindow";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import useWorkflowIssues from "../common/EventGroup/workflowIssues/useWorkflowIssues";
import { useWorkflowsFlags } from "../monitorsView/common/availableWorkflows";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { EventsWindow } from "../common/EventsPagination/EventsPagination";
import { useHpaEvents } from "../common/EventGroup/commonEventGroup/HpaEvents/useHpaEvent";
import { useJobEvents } from "../../shared/hooks/resources-api/client/events/jobs/useJobEvents";
import {
  useBuildKomodorUidsFromPodUids,
  useNativePodEvents,
} from "../common/EventGroup/nativePodEvent/useNativePodEvents";
import {
  useNodeCreatedEvents,
  useNodeTerminatedEvents,
} from "../common/EventGroup/nodeEvent/useNodeChangeEvents";
import { useFetchCustomEvents } from "../common/EventGroup/customEvent/fetchCustomEventsHooks";
import { useAgentDowntimeRawData } from "../common/EventGroup/agentDowntimeEvent/useAgentDowntimeRawData";
import { useShouldShowAgentDowntime } from "../common/EventGroup/agentDowntimeEvent/useShouldShowAgentDowntime";
import { useGetGroupedPodEventForPodNames } from "../common/EventGroup/groupedPodEvent/useGetGroupedPodEventForPodNames";
import { useEventsViewStore } from "../../shared/store/eventsViewStore/eventsViewStore";
import { setFetchingStateSelector } from "../../shared/store/eventsViewStore/eventsViewSelectors";
import { deconstructUid } from "../../shared/utils/generateUid";
import { useGeneralEvents } from "../../shared/hooks/resources-api/client/events/useGetGeneralEvents";
import { usePdbEvents } from "../../shared/hooks/resources-api/client/events/pdbEvents/usePDBEvents";
import { useWorkspaces } from "../workspaces/WorkspacesTopBar/hooks";
import { useProccessedConfigMapEvents } from "../common/EventGroup/configMap/useConfigMaps";

import { useSelectedPodsForEvents } from "./PodsSelector/SelectedPodsProvider";

const getPodsIdentifiers = (podsUid: string[]) => {
  return podsUid.map((uid) => {
    return deconstructUid(uid);
  });
};

const useEventsViewEvents = (
  timeWindow: Pick<TimeWindow, "start" | "end">
): EventsWindow | undefined => {
  const { groupPodsByPhases } = useOverridableFlags();
  const { data: eventsFromPg } = useGeneralEvents({ timeWindow });
  const flags = useOverridableFlags();
  const wfFlags = useWorkflowsFlags();
  const {
    showConfigMapAndSecrets,
    showHpaEvent,
    showPdbEvent,
    showJobEvents,
    eventsViewPodsSelector: showPodEvents,
    nodeTerminatedEvents: showNodeTerminatedEvents,
    nodeCreatedEvents: showNodeCreatedEvents,
  } = flags;
  const showAgentDowntimeEvent = useShouldShowAgentDowntime();
  const { currentWorkspaceId, isLoading, isWorkspaceKindBackendFiltered } =
    useWorkspaces();

  // temp workaround for nodes events search api not supporting app view scope
  const enabled =
    (!isLoading && isWorkspaceKindBackendFiltered) ||
    (!isWorkspaceKindBackendFiltered && currentWorkspaceId === undefined);
  const nodeCreatedEventsPg = useNodeCreatedEvents(
    timeWindow,
    enabled ? showNodeCreatedEvents : undefined
  );
  const nodeTerminatedEventsPg = useNodeTerminatedEvents(
    timeWindow,
    enabled ? showNodeTerminatedEvents : undefined
  );
  ///////////////

  const [workflowIssues] = useWorkflowIssues(timeWindow);
  const wfResults = useMemo(
    () =>
      workflowIssues?.filter(
        (result) => result?.type && wfFlags[result.type]
      ) ?? [],
    [wfFlags, workflowIssues]
  );
  const configMapQuery = useProccessedConfigMapEvents(
    showConfigMapAndSecrets,
    timeWindow
  );
  const hpaQuery = useHpaEvents(showHpaEvent, timeWindow);
  const pdbQuery = usePdbEvents(showPdbEvent, timeWindow);
  const agentDowntimeQuery = useAgentDowntimeRawData(
    showAgentDowntimeEvent,
    timeWindow
  );
  const jobQuery = useJobEvents(showJobEvents, timeWindow);
  const { selectedPodUidsForEvents } = useSelectedPodsForEvents();
  const pods = getPodsIdentifiers(selectedPodUidsForEvents || []); // todo: get komodor UID instead
  const podsKomodorUids = useBuildKomodorUidsFromPodUids(pods);

  const customEvents = useFetchCustomEvents({ timeWindow });

  const { data: nativePodQuery, isFetching: isFetchingNativePodEvents } =
    useNativePodEvents(
      podsKomodorUids,
      showPodEvents,
      !groupPodsByPhases,
      timeWindow
    );
  const { data: podPhases, isFetching: isFetchingGroupedNativePodEvents } =
    useGetGroupedPodEventForPodNames(timeWindow, !!groupPodsByPhases);

  const setFetchingState = useEventsViewStore(setFetchingStateSelector);
  useEffect(() => {
    setFetchingState({
      key: "isFetchingNativePodEvents",
      value: isFetchingNativePodEvents || isFetchingGroupedNativePodEvents,
    });
  }, [
    isFetchingGroupedNativePodEvents,
    isFetchingNativePodEvents,
    setFetchingState,
  ]);

  return useMemo(() => {
    return eventsFromPg &&
      configMapQuery &&
      wfResults &&
      jobQuery &&
      hpaQuery &&
      pdbQuery &&
      agentDowntimeQuery &&
      nativePodQuery &&
      customEvents &&
      nodeTerminatedEventsPg &&
      nodeCreatedEventsPg &&
      podPhases
      ? {
          impactfulEvents: eventsFromPg,
          nodeEvents: [],
          configMapEvents: configMapQuery,
          workflowResults: wfResults,
          hpaEvents: hpaQuery,
          pdbEvents: pdbQuery,
          agentDowntimeEvents: agentDowntimeQuery,
          jobEvents: jobQuery,
          nativePodEvents: nativePodQuery,
          nodeCreatedEvents: nodeCreatedEventsPg,
          nodeTerminatedEvents: nodeTerminatedEventsPg,
          customEvents,
          groupedPodEvents: podPhases,
        }
      : undefined;
  }, [
    eventsFromPg,
    configMapQuery,
    wfResults,
    jobQuery,
    hpaQuery,
    pdbQuery,
    agentDowntimeQuery,
    nativePodQuery,
    customEvents,
    nodeTerminatedEventsPg,
    nodeCreatedEventsPg,
    podPhases,
  ]);
};

export default useEventsViewEvents;
