import React from "react";
import styled from "styled-components";

import { Divider } from "../common";

const Padding = styled.div`
  padding: 1rem;
`;
const EventDetailsTitleContainer = styled.div<{ color?: string }>`
  font-weight: 500;
  font-size: 1.25rem;
  ${({ color }) => color && `color: ${color};`}
`;

const EventDetailsTitle: React.FC<{
  title: string;
  color?: string;
  children?: React.ReactNode;
}> = ({ title, color, children }) => (
  <>
    <Padding>
      <EventDetailsTitleContainer color={color}>
        {title}
      </EventDetailsTitleContainer>
      {children}
    </Padding>
    <Divider />
  </>
);

export default EventDetailsTitle;
