import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { matchAgeWithUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellUtils";
import { DateUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellTypes";

const allDateStrUnits: DateUnits[] = ["d", "h", "m"];

type AgeCellProps = {
  age: string;
  includeDateUnits?: DateUnits[];
};

export const AgeCell: React.FC<AgeCellProps> = ({
  age,
  includeDateUnits = allDateStrUnits,
}) => {
  const valueToUse = useMemo(() => {
    if (includeDateUnits.length === 0) {
      return age;
    }

    const result = matchAgeWithUnits(includeDateUnits, age);
    const hasZeroValue = result.replace(includeDateUnits.join(""), "") === "0";
    if (!result || hasZeroValue) {
      // If the age string does not contain any of the includeDateUnits or has a value of 0, try to find the first excluded unit
      const excludedDateUnits = allDateStrUnits.filter(
        (unit) => !includeDateUnits.includes(unit)
      );
      if (excludedDateUnits.length > 0) {
        return matchAgeWithUnits([excludedDateUnits[0]], age);
      }
    }

    return result;
  }, [age, includeDateUnits]);

  return <Typography variant={"body2"}>{valueToUse}</Typography>;
};
