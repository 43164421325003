import React, { useMemo } from "react";
import { PagerDutyEventData } from "komodor-types";

import { PlayersSection } from "../components/PlayersSection";

const usePlayers = (properties?: Partial<PagerDutyEventData>) =>
  useMemo(() => {
    const actor = {
      type: "Actor",
      name: properties?.actorName ?? "N/A",
      url: properties?.actorUrl,
    };
    const assignees = (properties?.assignees ?? []).map((name) => ({
      type: "Assignee",
      name,
      url: undefined,
    }));
    return [actor, ...assignees];
  }, [properties]);

interface PagerDutyPlayersProps {
  properties?: Partial<PagerDutyEventData>;
}

const PagerDutyPlayers: React.FC<PagerDutyPlayersProps> = ({ properties }) => {
  const players = usePlayers(properties);
  const pdService = useMemo(
    () => ({
      type: "Impacted PagerDuty Service",
      name: properties?.serviceName ?? "N/A",
      url: properties?.servicePageUrl,
    }),
    [properties]
  );
  return <PlayersSection players={players} extra={pdService} />;
};

export default PagerDutyPlayers;
