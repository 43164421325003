import React from "react";
import {
  Button,
  Divider,
  Typography,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { add, format } from "date-fns";
import { AccountPlan as AccountPlanType } from "komodor-types";

import useUserMetadata from "../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { openPlansPage } from "../../../../shared/utils/freemium";
import { SettingsSectionLayout } from "../../SettingsSectionLayout";
import { PlanContainer } from "../../styles";
import useGetAccountPlanData from "../../../Freemium/useGetAccountPlanData";
import useTiersVisible from "../../../Freemium/useTiersVisible";
import useEventsLimit from "../../../Freemium/useEventsLimit";

import QuotaInformation from "./QuotaInformation";
import PlanTitle from "./PlanTitle";

const CenteredButton = styled(Button)`
  justify-content: center;
`;

const UpgradeCta: React.FC = () => {
  return (
    <CenteredButton variant="primary" width="100%" onClick={openPlansPage}>
      Upgrade
    </CenteredButton>
  );
};

const AccountPlan: React.FC = () => {
  const { trialEndAt, accountPlan } = useUserMetadata();
  const planData = useGetAccountPlanData();
  const isTiersVisible = useTiersVisible();
  const eventsLimitsData = useEventsLimit();

  if (!(planData && eventsLimitsData && isTiersVisible)) {
    return null;
  }

  const { eventsTotalSum, eventsCountStartEpoch } = eventsLimitsData;

  if (!(eventsCountStartEpoch && accountPlan)) {
    return null;
  }

  const nextCountCycleDateString = () => {
    const nextCountDate = add(new Date(eventsCountStartEpoch), { months: 1 });
    return format(nextCountDate, "MM/dd/yyyy");
  };

  const showUpgrade =
    accountPlan === AccountPlanType.free ||
    accountPlan === AccountPlanType.trial;

  return (
    <SettingsSectionLayout title={"My Plan"}>
      <div>
        <label>
          <Typography size={"medium"}>{"Your current plan"}</Typography>
        </label>
        <PlanContainer>
          <PlanTitle trialEnd={trialEndAt} accountPlan={accountPlan} />
          <Divider />
          <QuotaInformation
            accountPlan={accountPlan}
            nextCountCycleDateString={nextCountCycleDateString()}
            clustersLimit={planData.clustersLimit ?? null}
            eventsLimit={planData.eventsLimit ?? null}
            usersLimit={planData.usersLimit ?? null}
            eventsTotalSum={eventsTotalSum ?? 0}
          />
          {showUpgrade && <UpgradeCta />}
        </PlanContainer>
      </div>
    </SettingsSectionLayout>
  );
};

export default AccountPlan;
