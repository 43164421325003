import React from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { Clock16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { format } from "date-fns";

import useIsFreeTier from "../useIsFreeTier";
import useGetAccountPlanData from "../useGetAccountPlanData";
import useEventsLimit from "../useEventsLimit";

import FakeEventRow from "./FakeEventRow";
import LimitedEventsMessage from "./LimitedEventsMessage";

const StyledBr = styled.br`
  content: "";
  margin: 4rem;
  display: block;
  font-size: 24%;
`;

const StyledRow = styled.tr`
  position: absolute;
  width: 100%;
  justify-content: center;
  height: 4rem;
  align-content: center;
  display: flex;
  flex-direction: row;
`;

const StyledContainer = styled.tr`
  justify-content: center;
  align-content: center;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 0.6rem;
  align-items: center;
`;

const StyledPinkDottedLine = styled.hr`
  border-top: dotted 2.5px;
  color: ${palette.pink[500]};
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 18rem;
  white-space: nowrap;
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

const LimitedBlurredEvents: React.FC = () => {
  const isFreeTier = useIsFreeTier();
  const eventsLimitsData = useEventsLimit();
  const planData = useGetAccountPlanData();

  if (!(isFreeTier && eventsLimitsData && planData)) {
    return null;
  }

  const { eventsTotalSum, eventsLimitEpoch } = eventsLimitsData;
  const { eventsLimit } = planData;

  if (!(eventsTotalSum && eventsLimit && eventsLimitEpoch)) {
    return null;
  }

  const getFormattedEpoch = (eventsLimitEpoch: number) => {
    const date = new Date(eventsLimitEpoch);
    return format(date, "LLL dd, HH:mm");
  };

  return (
    <>
      <LimitedEventsMessage
        eventsLimit={eventsLimit}
        eventsTotalSum={eventsTotalSum}
      />
      <FakeEventRow variant={0} />
      <FakeEventRow variant={1} />
      <StyledRow>
        <StyledContainer>
          <StyledPinkDottedLine />
          <TextWrapper>
            <Clock16 fill={palette.pink[500]} />
            <Typography color={palette.pink[500]} variant="text" size="medium">
              {`Stopped recording events on ${getFormattedEpoch(
                eventsLimitEpoch
              )}`}
            </Typography>
          </TextWrapper>
          <StyledPinkDottedLine />
        </StyledContainer>
      </StyledRow>
      <StyledBr />
    </>
  );
};

export default LimitedBlurredEvents;
