import { useMemo } from "react";

const useTicks = (start: Date, end: Date, tickCount: number): number[] =>
  useMemo(() => {
    const first = start.getTime();
    const last = end.getTime();
    const step = (last - first) / (tickCount - 1);
    return [
      first,
      ...Array.from(
        { length: tickCount - 2 },
        (_, i) => first + Math.floor(step * (i + 1))
      ),
      last,
    ];
  }, [start, end, tickCount]);

export default useTicks;
