import React, { CSSProperties, useMemo } from "react";

import { greenForUIText, pinkBrand, yellowForUIText } from "../../../Colors";
import { ucFirstWord } from "../../../shared/utils/serviceHelpers";
import { TextBolder } from "../typography";

const greenStatuses = new Set([
  "healthy",
  "up",
  "resolved",
  "recovered",
  "completed",
  "closed",
]);
const yellowStatuses = new Set(["unknown"]);
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const redStatuses = new Set([
  "unhealthy",
  "down",
  "warn",
  "unresolved",
  "triggered",
  "failed",
  "Failed",
  "not ready",
  "open",
]);

const kubectlErrors = ["Error from server", "error:"];
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isKubectlErrorStatus = (status: string): boolean =>
  kubectlErrors.some((substr) => status.startsWith(substr));

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getColor = (lcStatus: string): string | undefined => {
  if (greenStatuses.has(lcStatus)) {
    return greenForUIText;
  }
  if (yellowStatuses.has(lcStatus)) {
    return yellowForUIText;
  }
  if (redStatuses.has(lcStatus)) {
    return pinkBrand;
  }
  return undefined;
};

const Status: React.FC<{ status: string }> = ({ status }) => {
  const style: CSSProperties = useMemo(() => {
    const lcStatus = status.toLowerCase();
    const color = getColor(lcStatus);
    return { color, fontWeight: 500 };
  }, [status]);
  const ucStatus = useMemo(() => ucFirstWord(status), [status]);
  return <TextBolder style={style}>{ucStatus}</TextBolder>;
};

export default Status;
