import React from "react";
import Stack from "@mui/material/Stack";

import {
  NodeCount,
  PlanDetails,
} from "@/pages/organization-settings/account/components";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";

export const UsagePage: React.FC = () => {
  return (
    <SettingsViewVerticalLayout title="Usage">
      <Stack direction="column" spacing={3}>
        <PlanDetails />
        <NodeCount />
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
