import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

import { ClickableArea, CloseButtonArea, RelativeContainer } from "../styles";
import laneExtractor from "../assets/screen-wfLaneExtractorRegressionMr697.png";
import drawerWfNodeTermination from "../assets/drawer-wfNodeTermination.png";
import drawerNodeTerminated from "../assets/drawer-nodeTerminated.png";

const BackButtonArea = styled(ClickableArea)`
  width: 3%;
  height: 4%;
  top: 8.3%;
  left: 1.6%;
  border-radius: 4px;
`;

const Issue1Button = styled(ClickableArea)`
  width: 3.1%;
  height: 3%;
  top: 76.8%;
  left: 78.2%;
  border-radius: 4px;
`;
const Issue2Button = styled(ClickableArea)`
  width: 3.1%;
  height: 3%;
  top: 82.8%;
  left: 78.2%;
  border-radius: 4px;
`;

const ViewRelatedEventButton = styled(ClickableArea)`
  width: 17.1%;
  height: 3%;
  top: 10.6%;
  right: 25px;
  border-radius: 4px;
`;

const ScaleDownButton = styled(ClickableArea)`
  width: 13.1%;
  height: 3%;
  top: 47.9%;
  right: 43.5%;
  border-radius: 4px;
`;

export const LaneExtractorPage: React.FC = () => {
  const navigate = useNavigate();
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  const [secondaryDrawerImgSrc, setSecondaryImageSrc] = useState<string | null>(
    null
  );

  return (
    <RelativeContainer>
      <img src={laneExtractor} alt={"lane extractor page"} />
      <BackButtonArea onClick={() => navigate(`../`, { relative: "path" })} />
      <Issue1Button onClick={() => setDrawerImgSrc(drawerWfNodeTermination)} />
      <Issue2Button onClick={() => setDrawerImgSrc(drawerWfNodeTermination)} />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={
          <CloseButtonVariant
            onClick={() => setDrawerImgSrc(null)}
            top="16px"
            left="24px"
          />
        }
      >
        <ViewRelatedEventButton
          onClick={() => setSecondaryImageSrc(drawerNodeTerminated)}
        />
        <ScaleDownButton
          onClick={() => setSecondaryImageSrc(drawerNodeTerminated)}
        />
      </MockDrawer>
      <MockDrawer
        open={secondaryDrawerImgSrc !== null}
        imgSrc={secondaryDrawerImgSrc}
        onClose={() => setSecondaryImageSrc(null)}
        closeButton={
          <CloseButtonVariant
            onClick={() => setSecondaryImageSrc(null)}
            top="16px"
            left="24px"
          />
        }
      ></MockDrawer>
    </RelativeContainer>
  );
};

const CloseButtonVariant = styled(CloseButtonArea)<{
  top: string;
  left: string;
}>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

type MockDrawerProps = {
  open: boolean;
  onClose: () => void;
  imgSrc: string | null;
  children?: React.ReactNode;
  closeButton?: React.ReactNode;
};
export const MockDrawer: React.FC<MockDrawerProps> = ({
  open,
  onClose,
  imgSrc,
  children,
  closeButton,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      {imgSrc && <img src={imgSrc} width={907} />}
      {closeButton}
      {children}
    </Drawer>
  );
};
