import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { UseQueryState } from "urql/dist/types/hooks/useQuery";
import { ServiceIdentifierType } from "komodor-types";
import { UseQueryResponse } from "urql";

import useKomodorServices from "../../../../../shared/hooks/useKomodorServices";
import { getServiceMatchByIdentifier } from "../types/getServiceMatchByIdentifier";
import { AppView } from "../types/appViewsTypes";
import {
  GetAppViewsQuery,
  useGetAppViewsQuery,
} from "../../../../../generated/graphql";
import { useIsAnonymousUser } from "../../../../../shared/hooks/useIsAnonymousUser";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  selectedAppViewIdSelector,
  serviceIdsSelector,
  setSelectedAppViewIdSelector,
  setServiceIdsSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useInterval } from "../../../../common/useInterval";
import { WORKSPACE_ROUTE } from "../../../../routes/routes";
import { useWorkspaces } from "../../../../workspaces/WorkspacesTopBar/hooks";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useAppViewStateInLocalStorage } from "./appViewPersistentState";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useFetchAndSetServiceIds } from "./fetchAppViewServices";

const FETCH_APP_VIEWS_INTERVAL = 30000;

export const useGetServiceIdsByServiceIdentifier = (): ((
  serviceIdentifiers: string[],
  type: ServiceIdentifierType
) => string[] | undefined) => {
  const komodorServices = useKomodorServices().unscopedServices;
  return useCallback(
    (serviceIdentifiers: string[], type: ServiceIdentifierType) => {
      return komodorServices
        ?.filter((service) =>
          serviceIdentifiers.some((identifier) =>
            getServiceMatchByIdentifier({ type, identifier, service })
          )
        )
        .map<string>((service) => service.id)
        .sort();
    },
    [komodorServices]
  );
};

export const usePollServiceIdsInAppView = (appViewId?: string) => {
  const { isWorkspaceKindBackendFiltered, isLoading } = useWorkspaces();
  const { refresh, error } = useFetchAndSetServiceIds(
    appViewId,
    !isLoading && isWorkspaceKindBackendFiltered
  );
  useFetchServiceIdsOnAppViewEdit(refresh);
  useInterval(refresh, appViewId ? FETCH_APP_VIEWS_INTERVAL : null);
  useHandleAppViewFetchingError(error);
};

const useHandleAppViewFetchingError = (error: string | null) => {
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const { setIsFetchingServicesData, isFetchingServicesData } =
    useKomodorServices();

  if (error && isFetchingServicesData) {
    // an error has occurred on initial fetch, bail out
    setSelectedAppViewId(undefined);
    setIsFetchingServicesData(false);
  }
};

const useFetchServiceIdsOnAppViewEdit = (refresh: () => void) => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const servicesPerAppViewRef = useRef<AppView | undefined>(undefined);
  useEffect(() => {
    const currentAppViewHasServices =
      !!currentAppView.serviceIdentifiers.length;
    const refHasServices =
      !!servicesPerAppViewRef.current?.serviceIdentifiers.length;

    if (
      refHasServices &&
      currentAppView.id !== servicesPerAppViewRef.current?.id
    ) {
      // app view has changed, reset ref
      servicesPerAppViewRef.current = currentAppView;
      return;
    }

    if (currentAppViewHasServices && !refHasServices) {
      // set ref to current app view
      servicesPerAppViewRef.current = currentAppView;
      return;
    }

    if (
      currentAppViewHasServices &&
      refHasServices &&
      !isEqual(
        currentAppView.serviceIdentifiers,
        servicesPerAppViewRef.current?.serviceIdentifiers
      )
    ) {
      // app view was edited, request new data
      servicesPerAppViewRef.current = currentAppView;
      refresh();
    }
  }, [currentAppView, refresh]);
};

type SetIsFetchingDataParams = {
  response: UseQueryState<GetAppViewsQuery>;
  appViewIdNotFound: boolean;
};
export const useSetIsFetchingData = ({
  response,
  appViewIdNotFound,
}: SetIsFetchingDataParams): void => {
  const serviceIds = useAppViewsStore(serviceIdsSelector);
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const appViewsIsSet = !!currentAppView?.id;
  const { setIsFetchingServicesData, all } = useKomodorServices();
  const [appViewInLocalStorage] = useAppViewStateInLocalStorage();
  const isAnonymous = useIsAnonymousUser();

  useEffect(() => {
    const hasAppViewsInLocalStorage =
      !!appViewInLocalStorage && !!Object.keys(appViewInLocalStorage).length;
    if (
      !hasAppViewsInLocalStorage ||
      response.error ||
      response.data?.app_view.length === 0 ||
      appViewIdNotFound ||
      isAnonymous
    ) {
      setIsFetchingServicesData(false);
    } else if (response.data?.app_view) {
      if (hasAppViewsInLocalStorage && !appViewsIsSet) return;
      if (all?.length && serviceIds.length === all.length) {
        // remove loading when appView's service ID's have been fetched
        setIsFetchingServicesData(false);
      }
    }
  }, [
    all,
    appViewIdNotFound,
    appViewInLocalStorage,
    isAnonymous,
    response.data?.app_view,
    response.error,
    response.fetching,
    appViewsIsSet,
    setIsFetchingServicesData,
    serviceIds.length,
  ]);
};

export const useExecuteAppViewsQuery = (pause?: boolean): UseQueryResponse => {
  const isAnonymous = useIsAnonymousUser();
  // don't fetch data for an anonymous user

  return useGetAppViewsQuery({ pause: isAnonymous ?? pause });
};

export const useIsInAppViewsPath = (): boolean => {
  const location = useLocation();
  return location.pathname.includes(WORKSPACE_ROUTE);
};

export const useOnAppViewChange = (cb: () => void): void => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const prevAppViewId = useRef<string | undefined>(selectedAppViewId);

  useLayoutEffect(() => {
    if (prevAppViewId.current) {
      cb();
    }
    prevAppViewId.current = selectedAppViewId;
  }, [cb, selectedAppViewId]);
};

export const useSetServiceIdsAndScope = (): ((
  serviceIds?: string[]
) => void) => {
  const setServiceIds = useAppViewsStore(setServiceIdsSelector);
  const { setServicesScope } = useKomodorServices();

  return useCallback(
    (serviceIds: string[] = []) => {
      setServiceIds(serviceIds);
      setServicesScope(serviceIds);
    },
    [setServiceIds, setServicesScope]
  );
};
