import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SectionContainer";
import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";
import { getLogLineColor } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/relatedEvidenceUtils";
import { errorCodeToColor } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/relatedEvidenceConstants";
import { RelatedEvidenceTable } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/RelatedEvidence/RelatedEvidenceTable";
import { ColoredTypography } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureStyles";

const LogsSectionContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const LogsContainer = styled.div`
  padding: 4px 16px;
  box-sizing: border-box;
  background-color: ${muiColors.gray[800]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const StyledAccordion = styled(Accordion)`
  &&& {
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    width: 100%;
    &:before {
      display: none;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RelatedEvidence: React.FC = () => {
  const { evidence } = useGetCascadingFailureSupportingData() ?? {};

  const content = useMemo(() => {
    if (!evidence?.length) return null;

    return evidence.map((e) => {
      const logs = e.logsSnippet.split("\n").map((line, idx) => {
        const color = errorCodeToColor[getLogLineColor(line)];
        return (
          <ColoredTypography variant={"code1"} key={idx} $color={color}>
            {line.trim()}
          </ColoredTypography>
        );
      });

      const title = (
        <FlexContainer>
          <Typography variant={"h5"}>Source:</Typography>
          <Typography variant={"body2"}>{e.source}</Typography>
        </FlexContainer>
      );
      const events = e.events;

      return (
        <StyledAccordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant={"body2"}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LogsSectionContainer key={e.source}>
              <LogsContainer>{logs}</LogsContainer>
            </LogsSectionContainer>
            <RelatedEvidenceTable events={events} />
          </AccordionDetails>
        </StyledAccordion>
      );
    });
  }, [evidence]);

  if (!evidence?.length) return null;

  return (
    <SectionContainer title={"Related evidence:"}>{content}</SectionContainer>
  );
};
