import { useViolation } from "../../../../hooks/useViolation";

import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { Addon, Entity } from "@/generated/addonsApi";
import { selectCertificate } from "@/components/k8sAddons/LiveStateDrawer/hooks/useAddonFullLiveState";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const useGetCertificateData = (uid: string, name: string) => {
  const { data } = useViolation();

  const { cluster } =
    parseKomodorUid(data?.data.violation.komodorUid ?? "") ?? {};
  return useGetAddonFullLiveState(
    {
      addon: Addon.CertManager,
      entity: Entity.Certificate,
      uid,
      name,
      getEntityRequest: {
        clusterName: cluster ?? "",
      },
    },
    selectCertificate
  );
};
