/* eslint-disable max-lines */
import { muiColors, palette, theme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Metadata } from "@komodorio/design-system/komodor-ui";

import {
  ContainerState,
  ContainerWithLogs,
  GeneralK8SData,
} from "../../workflowRunView/CommonTypes";
import { StatusTag } from "../../../ResourceView/tabs/DescribeTab/common/StatusTag";
import { sleep } from "../../../../shared/utils/sleep";
import { INVESTIGATION } from "../../../routes/routes";

import ContainerContent from "./ContainerContent";

const InvestigationContainerWidth = "25rem";
const ContainerWidth = "15rem";
const ContainerRowPadding = "24px";
const BorderWidth = "1px";
const ContainersPanel = styled.div<{
  hoverable?: boolean;
  borderColor?: string;
}>`
  background-color: ${palette.white[0]};
  border: ${({ borderColor }) =>
    borderColor
      ? `${BorderWidth} solid ${borderColor}`
      : `${BorderWidth} solid ${theme.border.borderGray}`};
  border-radius: 0px 4px 4px 4px;
  padding: ${ContainerRowPadding};
  cursor: pointer;
  &:hover {
    border: ${({ hoverable }) =>
      hoverable ? `1px solid ${muiColors.blue[700]}` : "none"};
  }
`;

const ContainerRow: React.FC<{
  container: ContainerWithLogs;
  k8sData: GeneralK8SData;
  podName: string;
  eventTime: Date | undefined;
  ariaLabel?: string;
  startExpanded?: boolean;
  cardTag?: ReactElement;
  borderColor?: string;
}> = ({
  container,
  k8sData,
  podName,
  eventTime,
  ariaLabel,
  startExpanded,
  cardTag,
  borderColor,
}) => {
  const calculatedContainerState = useMemo(() => {
    return container?.isLastState
      ? { isLast: true, chosenState: container.lastState }
      : { isLast: false, chosenState: container.currentState };
  }, [container]);
  const containerState = calculatedContainerState?.chosenState;
  return (
    <div>
      {cardTag}
      <ContainersPanel hoverable borderColor={borderColor}>
        <ExpandedContainerRow
          startExpanded={startExpanded}
          ariaLabel={ariaLabel}
          containerState={containerState}
          container={container}
        >
          <ContainerContent
            calculatedContainerState={calculatedContainerState}
            container={container}
            k8sData={k8sData}
            eventTime={eventTime}
            podName={podName}
          />
        </ExpandedContainerRow>
      </ContainersPanel>
    </div>
  );
};

export default ContainerRow;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 23px;
`;

const MiddleContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 32px;
`;
const StateContainer = styled.div<{ wide?: boolean }>`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  width: ${({ wide }) =>
    `calc( ${
      wide ? InvestigationContainerWidth : ContainerWidth
    } - ${ContainerRowPadding} - ${BorderWidth})`};
`;
const ExpandedContent = styled.div`
  padding-left: 1.5rem;
`;

const ContainerName = styled(Typography).attrs({
  variant: "h4",
  color: muiColors.gray[800],
})`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ExpandButton = styled(Button).attrs({
  variant: "text",
  size: "small",
})`
  && {
    width: max-content;
  }
`;

const ExpandedContainerRow: React.FC<{
  containerState: ContainerState | null;
  children: React.ReactNode;
  startExpanded?: boolean;
  container: ContainerWithLogs;
  ariaLabel?: string;
}> = ({ containerState, children, startExpanded, ariaLabel, container }) => {
  const [expanded, setExpanded] = useState(startExpanded ?? false);
  const rowRef = useRef<HTMLDivElement>(null);

  const onExpandClick = useCallback(() => {
    (async () => {
      if (!expanded) {
        await sleep(25);
        rowRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    })();
    setExpanded(!expanded);
  }, [expanded]);
  const isInvestigationMode = window.location.pathname.includes(
    `/${INVESTIGATION}/`
  );
  return (
    <>
      <Container ref={rowRef} onClick={onExpandClick} aria-label={ariaLabel}>
        <StateContainer wide={isInvestigationMode}>
          <Tooltip title={container.name} arrow={false}>
            <ContainerName>{container.name}</ContainerName>
          </Tooltip>
          <StatusTag value={containerState?.state} />
        </StateContainer>
        <MiddleContainer>
          <Metadata
            title="Started"
            value={container?.started ? "Yes" : "No"}
            tooltipProps={{ title: "Whether the container has started" }}
          />
          <Metadata
            title="Ready"
            value={container?.ready ? "Yes" : "No"}
            tooltipProps={{
              title:
                "Whether the container is ready to accept traffic (passed its readiness probe)",
            }}
          />
          <Metadata
            title="Restarts"
            value={String(container?.restartCount ?? 0)}
            tooltipProps={{
              title: "The number of times the container restarted",
            }}
          />
          <Metadata
            title="Exit Code"
            value={
              containerState?.exitCode || containerState?.exitCode === "0"
                ? containerState.exitCode
                : "-"
            }
            tooltipProps={{
              title:
                "The exit code indicates the outcome of a container's execution",
            }}
          />
          <Metadata
            title="Reason"
            value={containerState?.reason || "-"}
            tooltipProps={{
              title: "Explanation or cause behind a container's current state",
            }}
          />
        </MiddleContainer>
        <ExpandButton endIcon={expanded ? <ExpandLess /> : <ExpandMore />}>
          View Metrics & Logs
        </ExpandButton>
      </Container>
      {expanded && <ExpandedContent>{children}</ExpandedContent>}
    </>
  );
};
