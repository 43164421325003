import React from "react";
import {
  AlertTriangleOutlined16,
  InfoCircleOutlined16,
} from "@komodorio/design-system/icons";

import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import { EventDetailsContainer } from "../common";
import EventDetailsTitle from "../components/EventDetailsTitle";
import EventDetailsSection from "../components/EventDetailsSection";
import DetailsList from "../components/DetailsList";
import { EventType } from "../../../enums";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NativePodEventGroup from "../../../EventGroup/NativePodEventGroup";
import {
  buildKomodorUid,
  DESC,
} from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import {
  buildContainerStatuses,
  ContainerStatus,
  StatusCondition,
  statusConditions,
} from "../../../EventGroup/nativePodEvent/utils";
import { WORKLOADS_PODS_EVENT_SEARCH } from "../../../../../shared/hooks/resources-api/requestResponseMaps";

import { PodLogsButton } from "./podLogsButton";
import NodeSection from "./NodeSection";
import {
  ContainerStatuses,
  iconDimensions65,
  StatusConditions,
  useContainerNames,
  useNativePodDetails,
} from "./podUtils";
import { MotionalPodLinks } from "./podUtilsMotional";

const useGetPodContainersAndStatuses = (
  podName: string,
  namespace: string,
  clusterName: string,
  to: Date
): { containers: ContainerStatus[]; statusConditions: StatusCondition[] } => {
  const { data: eventsFromAPI } = useResourcesAPIGet(
    WORKLOADS_PODS_EVENT_SEARCH,
    {
      toEpoch: to.getTime().toString(),
      komodorUids: [
        buildKomodorUid({
          clusterName,
          namespace,
          resourceName: podName,
          kind: "Pod",
        }),
      ],
      order: DESC,
      limit: 1,
      fields: ["containerStatus", "statusConditions"],
    }
  );

  const podEvent = eventsFromAPI?.data[0];
  const containers: ContainerStatus[] = buildContainerStatuses(
    podEvent?.containerStatus
  );
  const conditions: StatusCondition[] = statusConditions(
    podEvent?.statusConditions
  );
  return { containers, statusConditions: conditions };
};

interface NativePodEventDetailsProps {
  eventGroup: NativePodEventGroup;
}

const NativePodEventDetails: React.FC<NativePodEventDetailsProps> = ({
  eventGroup,
}) => {
  const preparedDetails = useNativePodDetails(eventGroup);
  const { containers, statusConditions } = useGetPodContainersAndStatuses(
    eventGroup.podName,
    eventGroup.namespace,
    eventGroup.clusterName,
    eventGroup.endTime
  );
  const preparedContainerNames = useContainerNames(
    containers,
    eventGroup.podName,
    eventGroup.namespace,
    eventGroup.clusterName
  );

  return (
    <EventDetailsContainer>
      <EventDetailsTitle
        title={`Pod: ${eventGroup.podName || EventType.PodEvent}`}
      />
      <EventDetailsSection
        icon={InfoCircleOutlined16}
        iconDimensions={iconDimensions65}
        title="Details"
      >
        <DetailsList details={preparedDetails} />
        {preparedContainerNames && (
          <PodLogsButton
            clusterName={eventGroup.clusterName}
            podName={eventGroup.podName}
            namespace={eventGroup.namespace}
            containerNames={preparedContainerNames}
          />
        )}
      </EventDetailsSection>
      <EventDetailsSection
        icon={AlertTriangleOutlined16}
        iconDimensions={iconDimensions65}
        title="Reason"
      >
        {eventGroup.message}
      </EventDetailsSection>
      <NodeSection
        clusterName={eventGroup.clusterName}
        nodeName={eventGroup.nodeName}
        nodeHealthStatus={eventGroup.nodeHealthStatus ?? null}
      />
      <StatusConditions statusConditions={statusConditions} />
      <ContainerStatuses containerStatuses={containers} />
      <MotionalPodLinks eventGroup={eventGroup} containerStatus={containers} />
    </EventDetailsContainer>
  );
};

export default NativePodEventDetails;
