import { useCallback } from "react";
import { ServiceIdentifierType } from "komodor-types";

import { useUpdateAppViewMutation } from "../../../../../../../generated/graphql";
import { useCreationPageContext } from "../context/CreationPageContext";
import { boolDictionaryToStringList } from "../utils/creationPageUtils";
import { AppView } from "../../../types/appViewsTypes";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  onAppViewUpsertSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { useVerifyCreationForm } from "./useVerifyCreationForm";
import {
  useSetAppViewAndNavigate,
  useVerifyServerResponse,
} from "./creationPageHooks";

export const useUpdateCurrentAppView = (): (() => Promise<void>) => {
  const {
    setIsSavingAppView,
    selectedServiceIdentifiers,
    appViewType,
    appName,
    description,
    selectedServiceIds,
    setErrorState,
  } = useCreationPageContext();
  const { id } = useAppViewsStore(currentAppViewSelector);
  const onAppViewUpsert = useAppViewsStore(onAppViewUpsertSelector);
  const verifyCreationForm = useVerifyCreationForm();
  const verifyServerResponse = useVerifyServerResponse();
  const setAppViewAndNavigate = useSetAppViewAndNavigate();
  const [, updateAppView] = useUpdateAppViewMutation();

  return useCallback(async () => {
    if (!verifyCreationForm()) return;
    setErrorState({ appNameError: undefined });
    if (!id || !appName) return;

    const serviceIdentifiers =
      appViewType === ServiceIdentifierType.SERVICE_ID
        ? boolDictionaryToStringList(selectedServiceIds)
        : selectedServiceIdentifiers ?? [];

    const newAppViewData: AppView = {
      id,
      name: appName,
      description,
      serviceIdentifierType: appViewType ?? ServiceIdentifierType.SERVICE_ID,
      serviceIdentifiers,
    };

    setIsSavingAppView(true);
    const { data, error } = await updateAppView(newAppViewData);
    setIsSavingAppView(false);

    const updateId = data?.update_app_view_by_pk?.id || "";
    if (!verifyServerResponse(error, updateId)) {
      return;
    }
    setAppViewAndNavigate(newAppViewData);

    onAppViewUpsert(newAppViewData);
  }, [
    appName,
    appViewType,
    description,
    id,
    onAppViewUpsert,
    selectedServiceIdentifiers,
    selectedServiceIds,
    setAppViewAndNavigate,
    setErrorState,
    setIsSavingAppView,
    updateAppView,
    verifyCreationForm,
    verifyServerResponse,
  ]);
};
