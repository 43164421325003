import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1PodsInfoPostUrl,
  PodsInfo,
  PodsInfoRequest,
} from "../../../../generated/resourcesApi/api";

import { useResourcesApiClient } from "./apiClient";

const fetchPodsInfo = async (
  apiClient: AxiosInstance,
  body: PodsInfoRequest
) => {
  const { data } = await apiClient.post<PodsInfo[]>(
    apiV1PodsInfoPostUrl(
      { podsInfoRequest: body },
      apiClient.defaults.baseURL ?? ""
    ),
    body
  );
  return data;
};

export const useGetPodsInfo = (
  body: PodsInfoRequest,
  options?: { enabled?: boolean }
) => {
  const apiClient = useResourcesApiClient();
  const url = apiV1PodsInfoPostUrl({}, apiClient.defaults.baseURL ?? "");
  return useQuery([url, body], () => fetchPodsInfo(apiClient, body), options);
};
