/* eslint-disable max-lines */
import React, { useEffect, useRef } from "react";
import { Divider, Typography } from "@komodorio/design-system/deprecated";
import { parseISO } from "date-fns";
import styled from "styled-components";
import "xterm/css/xterm.css";

import { Table } from "../../../../../shared/components/Table/Table";
import { Dictionary } from "../../../../../shared/types/Dictionary";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../../resources/pod";
import { DescribeLoader } from "../common/DescribeLoader";
import {
  DescribeContainer,
  DescribePanel,
  DescribeSection,
} from "../common/DescribeSection";
import {
  DescribeDateItem,
  DescribeStatusItem,
  DescribeTextItem,
} from "../common/DescribeItem";
import {
  DescribeKeyValueItem,
  DescribeTagListItem,
} from "../common/DescribeTagListItem";
import { DescribeExpandedItem } from "../common/DescribeExpandedItem";
import { getAge } from "../../../../../shared/utils/dateUtils";
import { StatusTag } from "../common/StatusTag";
import { podStatusMapper } from "../common/PodStatusMapper";
import { OwnerResourcesLinks } from "../OwnerResourcesLinks/OwnerResourcesLinks";
import { DescribeLabelsAndAnnotations } from "../common/DescribeLabelsAndAnnotations";
import { DescribeConditions } from "../common/DescribeConditions";
import { useSetPortForwardingData } from "../utils/useSetPortForwardingData";
import { SupportedResourceKindEnum } from "../../../../../shared/context/PortForwardProvider";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { useReportDescribeTabToDatadog } from "../common/hooks/useReportDescribeTabToDatadog";
import { NodeResourceLink } from "../OwnerResourcesLinks/NodeResourceLink";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";

import { Containers } from "./containers/Containers";
import { usePodDescribeEvents } from "./usePodDescribeEvents";

import { useMergeCustomFlagsAttributes } from "@/shared/context/featureFlags/CustomFlagsAttributes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { CORRELATION_ENGINE_SERVICE_DEMO } from "@/components/ResourceView/tabs/DescribeTab/pod/demo/constants";
import klaudiaAnalysisPodDrawer from "@/components/ResourceView/tabs/DescribeTab/pod/demo/assets/klaudiaAnalysisPodDrawer.png";
import { WorkflowResourceLink } from "@/components/ResourceView/tabs/DescribeTab/OwnerResourcesLinks/WorkflowResourceLink";

const tolerationsColumns = [
  {
    header: "Key",
    accessor: "key",
  },
  {
    header: "Operator",
    accessor: "operator",
  },
  {
    header: "Value",
    accessor: "value",
  },
  {
    header: "Effect",
    accessor: "effect",
  },
  {
    header: "Seconds",
    accessor: "tolerationSeconds",
  },
];

const EventTitle = styled.div`
  display: flex;
  column-gap: 4rem;
  align-items: center;
`;
const EventTitleField = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

interface PodDescribeTabProps {
  resource: Pod;
  calculatedStatus: string;
  isDeletedPod?: boolean;
}
const PodDescribeTab: React.FC<PodDescribeTabProps> = ({
  resource,
  calculatedStatus,
  isDeletedPod = false,
}) => {
  const events = usePodDescribeEvents(
    resource.cluster,
    resource.namespace,
    resource.name
  );
  const pod = resource.fullObj;
  const {
    name,
    namespace,
    creationTimestamp,
    ownerReferences,
    labels,
    annotations,
    uid,
  } = pod?.metadata ?? {};
  const {
    nodeName,
    nodeSelector,
    priorityClassName,
    tolerations,
    initContainers,
    containers,
    volumes,
  } = pod?.spec ?? {};
  const {
    podIP,
    podIPs,
    conditions,
    qosClass,
    containerStatuses,
    initContainerStatuses,
  } = pod?.status ?? {};
  const owner = ownerReferences?.[0];
  const hasOwnerData = Boolean(owner?.kind && owner?.name);

  useSetPortForwardingData(resource, SupportedResourceKindEnum.Pod);

  const divRef = useRef<HTMLDivElement>(null);

  const { setOwnerResourcesLoading } = useReportDescribeTabToDatadog({
    viewName: dataDogViewNames.resourceViewPodDescribeTab,
    hasOwnerResources: true,
    resourceId: resource.id,
    divRef,
  });

  const mergeCustomFlagAttributes = useMergeCustomFlagsAttributes();

  useEffect(() => {
    // Adding cluster to user custom context in launchdarkly
    mergeCustomFlagAttributes({
      cluster: resource.cluster,
    });
  }, [mergeCustomFlagAttributes, resource.cluster]);

  const { demoKlaudiaAi } = useOverridableFlags();
  const shouldShowAiInvestigationMock =
    !!demoKlaudiaAi && !!name?.startsWith(CORRELATION_ENGINE_SERVICE_DEMO);

  return (
    <div ref={divRef}>
      {!resource.id && <DescribeLoader />}

      {resource.id && (
        <DescribeContainer aria-label={AriaLabels.Inspection.Pod.DescribeTab}>
          {shouldShowAiInvestigationMock && (
            <img
              src={klaudiaAnalysisPodDrawer}
              alt={"klaudia ai analysis"}
              width={844.2}
            />
          )}
          <DescribeSection title="Details" expandByDefault>
            <DescribePanel>
              <DescribeTextItem name="Name" value={name} />
              <DescribeTextItem name="UID" value={uid} />
              <DescribeStatusItem
                name="Status"
                value={calculatedStatus}
                statusesMapper={podStatusMapper()}
              />
              <DescribeDateItem name="Created At" value={creationTimestamp} />
              <DescribeTextItem name="Pod IP" value={podIP} />
              {podIPs?.length ? (
                <DescribeTagListItem
                  name="Pod IPs"
                  value={podIPs.map(({ ip }) => ip ?? "")}
                />
              ) : undefined}
              <Divider spacing="0.5rem" />
              {hasOwnerData && (
                <OwnerResourcesLinks
                  controlledResource={resource}
                  ownerMetadata={
                    owner
                      ? {
                          kind: owner.kind,
                          name: owner.name,
                          apiVersion: owner.apiVersion,
                        }
                      : undefined
                  }
                  onFetchingStateChange={setOwnerResourcesLoading}
                />
              )}
              <NodeResourceLink
                cluster={resource.cluster}
                nodeName={nodeName ?? ""}
                agentId={resource.agentId}
              />
              <WorkflowResourceLink
                cluster={resource.cluster}
                namespace={resource.namespace}
                labels={resource.labels}
              />
              <DescribeTextItem name="Namespace" value={namespace} />
              <DescribeTextItem
                name="Priority Class"
                value={priorityClassName}
              />
              <DescribeTextItem name="QoS Class" value={qosClass} />
              {nodeSelector && Object.keys(nodeSelector).length ? (
                <DescribeKeyValueItem
                  name="Node Selector"
                  value={nodeSelector}
                />
              ) : undefined}

              <DescribeLabelsAndAnnotations
                annotations={annotations ?? {}}
                labels={labels ?? {}}
              />
              <DescribeConditions
                conditions={conditions}
                shouldShowTooltip={false}
              />
            </DescribePanel>
          </DescribeSection>
          {initContainers && (
            <DescribeSection
              title={`InitContainers (${initContainers.length})`}
            >
              <Containers
                containers={initContainers}
                statuses={initContainerStatuses}
                agentId={resource.agentId}
                cluster={resource.cluster}
                isDeletedPod={isDeletedPod}
              />
            </DescribeSection>
          )}
          {containers && (
            <DescribeSection
              title={`Containers (${containers.length})`}
              expandByDefault
            >
              <Containers
                containers={containers}
                statuses={containerStatuses}
                agentId={resource.agentId}
                cluster={resource.cluster}
                isDeletedPod={isDeletedPod}
              />
            </DescribeSection>
          )}
          {tolerations && (
            <DescribeSection title={`Tolerations (${tolerations.length})`}>
              <Table
                data={tolerations as Dictionary<string>[]}
                columns={tolerationsColumns}
              />
            </DescribeSection>
          )}
          {volumes && (
            <DescribeSection title={`Volumes (${volumes.length})`}>
              {volumes.map((volume) => (
                <DescribePanel>
                  <DescribeExpandedItem
                    title={
                      <Typography variant="title">{volume.name}</Typography>
                    }
                  >
                    <DescribeTextItem
                      name="Type"
                      value={Object.keys(volume).find((k) => k !== "name")}
                    />
                  </DescribeExpandedItem>
                </DescribePanel>
              ))}
            </DescribeSection>
          )}
          {!isDeletedPod && events?.length ? (
            <DescribeSection
              title={`Events (${events.length})`}
              expandByDefault
            >
              {events.map((event) => {
                return (
                  <DescribePanel>
                    <DescribeExpandedItem
                      title={
                        <EventTitle>
                          <EventTitleField>
                            <Typography>{event.reason}</Typography>
                            <StatusTag value={event.severityType} />
                          </EventTitleField>
                          <EventTitleField>
                            <Typography variant="text">Age:</Typography>
                            <Typography>
                              {getAge(parseISO(event.lastTimestamp)) +
                                (event.count > 1 ? ` (x${event.count})` : "")}
                            </Typography>
                          </EventTitleField>
                        </EventTitle>
                      }
                    >
                      <DescribeTextItem
                        name="From"
                        value={`${event.sourceComponent}/${event.sourceHost}`}
                      />
                      <DescribeTextItem name="Message" value={event.message} />
                    </DescribeExpandedItem>
                  </DescribePanel>
                );
              })}
            </DescribeSection>
          ) : undefined}
        </DescribeContainer>
      )}
    </div>
  );
};

export default PodDescribeTab;
