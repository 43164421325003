import { UseQueryState } from "urql";
import { ServiceIdentifierType } from "komodor-types";

import { GetAppViewsQuery } from "../../../../../generated/graphql";
import { AppView } from "../types/appViewsTypes";
import { Dictionary } from "../../../../../shared/types/Dictionary";

export const toAppView = (
  fetchedAppView: GetAppViewsQuery["app_view"][number]
): AppView => ({
  ...fetchedAppView,
  description: fetchedAppView.description ?? undefined, // Eliminate potential `null` value.
  serviceIdentifierType:
    (fetchedAppView.serviceIdentifierType as ServiceIdentifierType) ||
    "serviceIds",
  serviceIdentifiers: getServiceIdentifiersWithFallback(fetchedAppView),
});

const getServiceIdentifiersWithFallback = ({
  serviceIdentifierType,
  serviceIdentifiers,
  serviceIds,
}: GetAppViewsQuery["app_view"][number]): string[] => {
  if (
    serviceIdentifierType !== ServiceIdentifierType.SERVICE_ID ||
    (serviceIdentifiers as string[]).length
  ) {
    return serviceIdentifiers as string[];
  }

  return serviceIds as string[];
};

/**
 * To be used specifically in a `reduce` function.
 */
export const toAppViewDictionary = (
  appViewById: Dictionary<AppView>,
  fetchedAppView: AppView
): Dictionary<AppView> => {
  appViewById[fetchedAppView.id] = fetchedAppView;

  return appViewById;
};

export const isValid = (response: UseQueryState): boolean =>
  !response.error && !response.fetching && response.data;
