import React, { useMemo } from "react";

import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SectionContainer";
import { useWorkflowIssuesQuery } from "@/components/common/EventGroup/workflowIssues/useWorkflowIssues";
import { useSharedProcessListProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useSharedProcessListProps";
import { useGetEpochsFromViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useGetEpochsFromViolation";
import { groupWorkflowIssuesEvents } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { ProcessListWrapper } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/ProcessListWrapper";
import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";

export const RelatedIssues: React.FC = () => {
  const { fromEpoch, toEpoch } = useGetEpochsFromViolation();

  const { affected_services, issues = [] } =
    useGetCascadingFailureSupportingData() ?? {};

  const {
    eventId,
    setEventId,
    setIntentId,
    timeWindow,
    setTimeWindow,
    serviceIds,
  } = useSharedProcessListProps({
    komodorUids: affected_services ?? [],
    fromEpoch,
    toEpoch,
  });

  const [workflowIssues, isLoadingIssues] = useWorkflowIssuesQuery(
    timeWindow,
    serviceIds
  );

  const issuesEventGroups = useMemo(() => {
    const filteredIssues = (workflowIssues?.flat() ?? []).filter((issue) => {
      return issues.includes(issue.id);
    });

    return groupWorkflowIssuesEvents(filteredIssues, serviceIds[0]);
  }, [issues, serviceIds, workflowIssues]);

  if (!isLoadingIssues && issuesEventGroups.length === 0) {
    return null;
  }

  return (
    <SectionContainer title={"Related Issues:"}>
      <ProcessListWrapper
        isLoadingIssues={isLoadingIssues}
        processListProps={{
          setTimeWindow: (tw) => {
            setTimeWindow({ ...timeWindow, ...tw });
          },
          timeWindow,
          eventGroups: issuesEventGroups,
          eventId,
          setEventId,
          setIntentId,
          highlightedEvents: [],
          showServiceColumn: false,
          showMoreEventsPanel: true,
        }}
      />
    </SectionContainer>
  );
};
