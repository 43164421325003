import { useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  composeServiceId,
  findServiceByDictionary,
} from "../utils/serviceHelpers";
import { ServiceInfo } from "../types/ServiceInfo";

import useKomodorServices from "./useKomodorServices";
import { useAccountName } from "./useAccountName";

type FindServiceParams = {
  value: string;
  clusterName: string;
  pageName: string;
  namespace?: string;
  controlledByRoot?: string;
};

export const useFindService = ({
  value,
  clusterName,
  pageName,
  namespace,
  controlledByRoot,
}: FindServiceParams): ServiceInfo | undefined => {
  const servicesAsDictionary = useKomodorServices().servicesAsDictionary.all;
  const accountName = useAccountName();

  return useMemo(() => {
    if (controlledByRoot) {
      const expectedServiceId = composeServiceId({
        accountName,
        clusterName,
        namespace,
        value: controlledByRoot,
      });
      const service = servicesAsDictionary[expectedServiceId];
      if (service) return service;
    }
    return findServiceByDictionary({
      servicesAsDictionary,
      value,
      clusterName,
      pageName,
      namespace,
      accountName,
    });
  }, [
    accountName,
    clusterName,
    controlledByRoot,
    namespace,
    pageName,
    servicesAsDictionary,
    value,
  ]);
};

export const useFindServiceById = (
  serviceId: string
): ServiceInfo | undefined => {
  const servicesAsDictionary = useKomodorServices().servicesAsDictionary.all;
  return servicesAsDictionary[serviceId];
};
