import React from "react";
import styled from "styled-components";

import { blueForUIText } from "../../../Colors";
import { BoldGrayText } from "../workflowRunView/checks/CheckDetailsDrawer";

import { NormalText } from "./styles";

const RecommendationsContainer = styled.div`
  padding: 0.5rem;
  border: 0.5px solid #dcdddf;
  border-left: 4px solid ${blueForUIText};
  border-radius: 4px;
`;
const Content = styled.div`
  margin-inline: 4px;
`;
const Recommendations: React.FC<{
  list?: string[];
  children?: React.ReactNode;
}> = ({ list, children }) => {
  return (
    <RecommendationsContainer>
      <BoldGrayText>Recommendations:</BoldGrayText>
      <Content>
        <NormalText>
          {children}
          {list?.map((rec, index) => (
            <span key={index}>
              {index + 1}. {rec} <br />
            </span>
          ))}
        </NormalText>
      </Content>
    </RecommendationsContainer>
  );
};

export default Recommendations;
