import React from "react";
import { useLocation } from "react-router-dom";

import {
  ACTIONS_ROUTE,
  AGENTS_SETTINGS_ROUTE,
  AUDIT_ROUTE,
  FEATURES_ROUTE,
  INTEGRATIONS_ROUTE,
  IP_WHITELIST_ROUTE,
  MONITORS_ROUTE,
  POLICIES_ROUTE,
  ROLES_ROUTE,
  USAGE_ROUTE,
  USERS_ROUTE,
} from "@/components/routes/routes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";

const SETTINGS_SECTIONS = {
  accessManagement: "ACCESS MANAGEMENT",
  account: "ACCOUNT",
  configurations: "CONFIGURATIONS",
};

interface Page {
  title: string;
  route: string;
  disabled?: boolean;
  permissions?: string[];
  flags?: string[];
  accountFeatures?: string[];
}

const PAGES: {
  [key: string]: Page[];
} = {
  [SETTINGS_SECTIONS.accessManagement]: [
    {
      title: "Users",
      route: USERS_ROUTE,
      permissions: ["canManageUsers"],
    },
    {
      title: "Roles",
      route: ROLES_ROUTE,
      permissions: ["canManageUsers"],
    },
    {
      title: "Policies",
      route: POLICIES_ROUTE,
      permissions: ["canManageUsers"],
    },
    {
      title: "Actions",
      route: ACTIONS_ROUTE,
      permissions: ["canManageUsers"],
      accountFeatures: ["rbacClusterSync"],
    },
    {
      title: "IP Whitelist",
      route: IP_WHITELIST_ROUTE,
      permissions: ["canManageAccountAccess"],
    },
  ],
  [SETTINGS_SECTIONS.account]: [
    {
      title: "Usage",
      route: USAGE_ROUTE,
      permissions: ["canManageUsage"],
    },
    {
      title: "Audit",
      route: AUDIT_ROUTE,
      permissions: ["canViewAudit"],
    },
    {
      title: "Agents",
      route: AGENTS_SETTINGS_ROUTE,
      permissions: ["canManageAgents"],
    },
  ],
  [SETTINGS_SECTIONS.configurations]: [
    {
      title: "Integrations",
      route: INTEGRATIONS_ROUTE,
      permissions: ["canManageIntegrations"],
    },
    {
      title: "Monitors",
      route: MONITORS_ROUTE,
      permissions: ["canManageMonitors"],
    },
    {
      title: "Features",
      route: FEATURES_ROUTE,
      permissions: ["canManageFeatures"],
    },
  ],
};

export const useSettings = () => {
  const flags = useOverridableFlags();
  const permissions = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const location = useLocation();

  const items = React.useMemo(
    () =>
      Object.entries(PAGES).map(([section, pages]) => ({
        title: section,
        pages: pages.map((page) => ({
          title: page.title,
          route: page.route,
          disabled:
            page.disabled ||
            page.permissions?.some((permission) => !permissions[permission]) ||
            page.flags?.some((flag) => !flags[flag]) ||
            page.accountFeatures?.some(
              (feature) => !accountFeatures[feature]
            ) ||
            false,
        })),
      })),
    [accountFeatures, flags, permissions]
  );

  const linkTabs = React.useMemo(() => {
    return items.flatMap((section) => section.pages);
  }, [items]);

  const getTabByRoute = React.useCallback(
    (route: string) => {
      const tab = linkTabs.find((t) => t.route === route);
      if (tab) {
        return tab;
      }

      return linkTabs.find((t) => route.includes(t.route)) || { route: false };
    },
    [linkTabs]
  );

  const currentTab = React.useMemo(
    () => getTabByRoute(location.pathname).route,
    [getTabByRoute, location.pathname]
  );

  return {
    items,
    currentTab,
  };
};
