import * as React from "react";
import { useEffect, useMemo } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

import { NodeCount as GeneratedNodeCount } from "@/generated/accounts";
import {
  useGetRows,
  useMonthSums,
  useTableData,
} from "@/components/Settings/General/NodesCount/hooks";
import { allMonths } from "@/components/Settings/General/NodesCount/consts";
import {
  Container,
  StyledCircularProgress,
} from "@/components/Settings/General/NodesCount/styles";

export const ClusterTable: React.FC<{
  data: GeneratedNodeCount[];
  isFetching?: boolean;
  isError?: boolean;
}> = ({ data, isFetching, isError }) => {
  // extract unique clusters
  const clusters = useMemo(() => {
    return Array.from(
      new Set(
        data
          .map((item) => item.clusterName)
          .filter((name): name is string => !!name)
      )
    );
  }, [data]);
  const tableData = useTableData(clusters, data);
  const apiRef = useGridApiRef();

  const monthSums = useMonthSums(tableData, clusters);
  const currentMonthIndex = allMonths.indexOf(
    new Date().toLocaleString("default", { month: "short" })
  );
  const sortedMonths = useMemo(() => {
    return currentMonthIndex === -1
      ? allMonths
      : [
          ...allMonths.slice(currentMonthIndex),
          ...allMonths.slice(0, currentMonthIndex),
        ];
  }, [currentMonthIndex]);

  const { rows, totalRow } = useGetRows(
    clusters,
    sortedMonths,
    tableData,
    monthSums
  );
  useEffect(() => {
    //The API object will be populated by the various plugins of the grid during the first render of the component.
    // If you try to use it in the first render of the component, it will crash since all methods are not registered yet
    const checkApiReady = () => {
      if (
        apiRef.current &&
        typeof apiRef.current.autosizeColumns === "function"
      ) {
        apiRef.current.autosizeColumns();
      } else {
        setTimeout(checkApiReady, 100);
      }
    };

    checkApiReady();
  }, [apiRef]);

  if (isError) {
    return <div>Failed to load data</div>;
  }
  if (isFetching) {
    return <StyledCircularProgress size={16} />;
  }

  const columns = [
    {
      field: "cluster",
      headerName: "Cluster/Month",
      pinned: "left",
      minWidth: 64,
    },
    ...sortedMonths.map((month) => ({
      field: month,
      headerName: month,
      type: "number",
      headerClassName:
        month === sortedMonths[sortedMonths.length - 1] ? "bold-column" : "",
      minWidth: 64,
    })),
  ];

  return (
    <Container>
      <DataGridPro
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        disableVirtualization={true}
        disableAutosize={true}
        autosizeOnMount={false}
        getRowClassName={(params) => (params.id === "total" ? "total-row" : "")}
        pinnedRows={{ bottom: [totalRow] }}
      />
    </Container>
  );
};
