import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Typography } from "@komodorio/design-system/deprecated";
import { groupBy } from "lodash";

import useKomodorServices from "../../shared/hooks/useKomodorServices";
import { darkGray, gray2 } from "../../Colors";
import { H3 } from "../common/typography";
import { dispatchEvent } from "../../shared/hooks/analytics";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { COMPARISON_COMPARATOR_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";

import { Flexbox } from "./common";
import { ServiceYamlFile, hasPrevPath } from "./common/types";
import backArrowIcon from "./assets/back-arrow.svg";
import ComparisonController from "./ComparisonController";
import YamlComparer from "./YamlComparer";

const DEEP_DIVE_VIEW_PATH = "/main/deep-dive";

const Container = styled.div`
  min-height: calc(100vh - 3rem);
  color: ${darkGray};
`;

const ResponsiveLayout = styled.div`
  margin: 1.5rem;
`;

const BackToServiceViewButton = styled.div`
  display: flex;
  width: fit-content;
  color: ${gray2};
  cursor: pointer;
  margin-block-end: 1rem;
`;

const BackIcon = styled.img.attrs({
  src: backArrowIcon,
  alt: "back-arrow",
})`
  margin-inline-end: 0.3rem;
`;

const Title = styled(Typography).attrs({ variant: "headline", size: "medium" })`
  padding: 1.5rem 0 1rem 0;
`;

type TypographyProps = ComponentProps<typeof Typography>;
type TextProps = Pick<TypographyProps, "bold" | "children">;

const Text: React.FC<TextProps> = ({ bold, children }: TextProps) => (
  <Typography variant="text" size="medium" bold={bold}>
    {children}
  </Typography>
);

const ComparisonView: React.FC = () => {
  const location = useLocation();

  // Since after the initial render we alter the search parameters, it effectively changes the location object, and we lose the state.
  // Therefore, we use `useRef` to preserve the location object as it was on the first render to use its state later.
  const locationOnFirstRender = useRef(location);

  const navigate = useNavigate();

  const services = useKomodorServices().all;

  const [searchParams] = useSearchParams();

  // While the user may change the comparator service from within the view, the basic parameters will always remain the same.
  // That pertains to the name and type of the service.
  const initialComparatorId = useRef(
    searchParams.get(COMPARISON_COMPARATOR_PARAM_KEY)
  );

  const initialComparatorService = useMemo(
    () =>
      services?.find((service) => service.id === initialComparatorId.current),
    [services]
  );

  // Whenever the page is rendered, dispatch an analytics event.
  // Since we want to make sure every visit is registered once for every different
  // service being compared, we registered the service name as the effect dependency.
  useEffect(() => {
    if (initialComparatorService?.title) {
      dispatchEvent(AnalyticEvents.ComparisonView.Comparison_view_visited);
    }
  }, [initialComparatorService?.title]);

  const servicesBySameName = useMemo(
    () =>
      services?.filter(
        (service) => service.title === initialComparatorService?.title
      ),
    [services, initialComparatorService]
  );

  const servicesByCluster = useMemo(
    () => groupBy(servicesBySameName, (service) => service.k8sCluster),
    [servicesBySameName]
  );

  const clusters = useMemo(
    () => Object.keys(servicesByCluster),
    [servicesByCluster]
  );

  const [yamls, setYamls] = useState<(ServiceYamlFile | undefined)[]>(
    new Array(2).fill(undefined)
  );

  const handleBackToServiceViewClick = useCallback(() => {
    if (
      locationOnFirstRender.current.state &&
      hasPrevPath(locationOnFirstRender.current.state) &&
      locationOnFirstRender.current.state.prevPath.includes(DEEP_DIVE_VIEW_PATH)
    ) {
      navigate(-1);
    } else {
      navigate({
        pathname: `${DEEP_DIVE_VIEW_PATH}/${initialComparatorId.current}`,
      });
    }
  }, [locationOnFirstRender, initialComparatorId, navigate]);

  return (
    <Container>
      <ResponsiveLayout>
        <BackToServiceViewButton
          data-e2e-selector="back-button"
          onClick={handleBackToServiceViewClick}
        >
          <BackIcon />
          <H3>Back to service view</H3>
        </BackToServiceViewButton>

        <Title>Comparison</Title>

        <div
          style={{ marginBottom: "0.6rem" }}
          data-e2e-selector="service-name"
        >
          <Typography variant="title" size="large">
            {initialComparatorService?.title}
          </Typography>
        </div>

        <div style={{ marginBottom: "0.6rem" }}>
          <Text>
            <Flexbox justifyContent="space-between">
              Type:{" "}
              <div style={{ marginLeft: "0.3rem" }}>
                <Text bold>{initialComparatorService?.kind}</Text>
              </div>
            </Flexbox>
          </Text>
        </div>

        {initialComparatorService?.title && (
          <ComparisonController
            clusters={clusters}
            services={servicesBySameName ?? []}
            servicesByCluster={servicesByCluster}
            yamls={yamls}
            setYamls={setYamls}
          />
        )}

        <div style={{ margin: "0.6rem 0" }}>
          <YamlComparer yamls={yamls} />
        </div>
      </ResponsiveLayout>
    </Container>
  );
};

export default ComparisonView;
