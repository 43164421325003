import { useMemo } from "react";

import { useResourcesAPIPost } from "../../../shared/hooks/resources-api/client";
import { NODE_STATE } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { useActiveAgent } from "../../../shared/hooks/useAgents";

import Node from "./Node";
import NodeStatus from "./NodeStatus";

const useNodesByClusterNameQuery = (clusterName?: string): Node[] => {
  const agentId = useActiveAgent(clusterName) ?? "";
  const { data } = useResourcesAPIPost(
    NODE_STATE,
    {
      agentId: agentId,
      fields: ["wide"],
    },
    !clusterName || !agentId
  );

  return useMemo(
    () =>
      data?.data?.wide?.map((node) => {
        return new Node(
          node.name,
          new NodeStatus(
            node.ready ?? "",
            node.diskPressure ?? "",
            node.memoryPressure ?? ""
          )
        );
      }) ?? [],
    [data]
  );
};

export default useNodesByClusterNameQuery;
