import { useCallback, useEffect } from "react";
import { isEqual } from "lodash";

import useKomodorServices from "../../../../../shared/hooks/useKomodorServices";
import {
  initialState,
  useAppViewsStore,
} from "../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  fetchAppViewServicesStateSelector,
  serviceIdsSelector,
  setFetchAppViewServicesStateSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useAccountName } from "../../../../../shared/hooks/useAccountName";
import { useResourcesAPIGet } from "../../../../../shared/hooks/resources-api/client";
import { KOMODOR_SERVICES_BY_APP_VIEW_ID } from "../../../../../shared/hooks/resources-api/requestResponseMaps";
import { parseKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { buildServiceId } from "../../../../../shared/utils/serviceHelpers";
import { FetchAppViewServicesState } from "../types/appViewsTypes";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  useGetServiceIdsByServiceIdentifier,
  useSetServiceIdsAndScope,
} from "./appViewsHooks";
import { useSetServicesUIDs } from "./useServicesUID";

export const useGetServiceIdsFromLocalServices = () => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const getServiceIdsByServiceIdentifier =
    useGetServiceIdsByServiceIdentifier();

  return useCallback(() => {
    const { serviceIdentifiers, serviceIdentifierType } = currentAppView;
    return getServiceIdsByServiceIdentifier(
      serviceIdentifiers,
      serviceIdentifierType
    );
  }, [currentAppView, getServiceIdsByServiceIdentifier]);
};

export const useFetchAndSetServiceIds = (
  appViewId?: string,
  isClusterWorkspace = false
) => {
  const currentServiceIds = useAppViewsStore(serviceIdsSelector);
  const accountName = useAccountName();
  const handleFetchAppViewServices = useHandleFetchAppViewServices();
  const serviceIdsAndScope = useSetServiceIdsAndScope();
  const getServiceIdsFromLocalServices = useGetServiceIdsFromLocalServices();
  const setServicesUIDs = useSetServicesUIDs();

  const updateFetchAppViewServicesState = useUpdateFetchAppViewServicesState();

  const res = useResourcesAPIGet(
    KOMODOR_SERVICES_BY_APP_VIEW_ID,
    {
      id: appViewId ?? "",
    },
    !appViewId || isClusterWorkspace
  );

  useEffect(() => {
    setServicesUIDs(res.data);
  }, [res.data, setServicesUIDs]);

  useEffect(() => {
    let komodorServiceIds: string[] | undefined = [];
    if (isClusterWorkspace) {
      handleFetchAppViewServices(res);
      return;
    }

    if (appViewId) {
      if (res.data?.length && res.dataRequestParams.includes(appViewId)) {
        res.error = null; // ignore error if data is valid
        komodorServiceIds = res.data?.reduce<string[]>((acc, komodorUid) => {
          const parsedKomodorUid = parseKomodorUid(komodorUid);
          if (parsedKomodorUid) {
            const { cluster, namespace, name } = parsedKomodorUid;
            acc.push(buildServiceId(accountName, cluster, namespace, name));
          }
          return acc;
        }, []);
      } else {
        // fallback to local services
        komodorServiceIds = getServiceIdsFromLocalServices();
        if (komodorServiceIds?.length) {
          res.data = komodorServiceIds;
          res.error = null;
        }
      }

      handleFetchAppViewServices(res);
    }

    const shouldUpdateServicesScope =
      ((!appViewId || isClusterWorkspace) && komodorServiceIds) ||
      (appViewId && !!komodorServiceIds?.length);

    if (
      shouldUpdateServicesScope &&
      !isEqual(currentServiceIds, komodorServiceIds)
    ) {
      serviceIdsAndScope(komodorServiceIds);
    }
  }, [
    accountName,
    appViewId,
    currentServiceIds,
    getServiceIdsFromLocalServices,
    handleFetchAppViewServices,
    isClusterWorkspace,
    res,
    serviceIdsAndScope,
    updateFetchAppViewServicesState,
  ]);

  return res;
};

const useHandleFetchAppViewServices = () => {
  const { setIsFetchingServicesData, isFetchingServicesData } =
    useKomodorServices();

  const updateFetchAppViewServicesState = useUpdateFetchAppViewServicesState();

  return useCallback(
    (
      fetchAppViewServicesRes: ReturnType<
        typeof useResourcesAPIGet<typeof KOMODOR_SERVICES_BY_APP_VIEW_ID>
      >
    ) => {
      const { loading, error, data } = fetchAppViewServicesRes;

      isFetchingServicesData && setIsFetchingServicesData(false);
      if ((!loading && data) || error) {
        if (error) {
          updateFetchAppViewServicesState({
            hasFetchError: true,
            hasFinishedFetching: true,
          });
          return;
        }

        const noServicesFound = data && data.length === 0;
        if (noServicesFound) {
          updateFetchAppViewServicesState({
            noServicesFound: true,
            hasFinishedFetching: true,
          });
          return;
        }

        updateFetchAppViewServicesState({
          ...initialState.fetchAppViewServicesState,
          hasFinishedFetching: true,
        });
      }
    },
    [
      isFetchingServicesData,
      setIsFetchingServicesData,
      updateFetchAppViewServicesState,
    ]
  );
};

export const useUpdateFetchAppViewServicesState = () => {
  const fetchAppViewServicesState = useAppViewsStore(
    fetchAppViewServicesStateSelector
  );
  const setFetchAppViewServicesState = useAppViewsStore(
    setFetchAppViewServicesStateSelector
  );

  return useCallback(
    (state: Partial<FetchAppViewServicesState>) => {
      const newState = {
        ...fetchAppViewServicesState,
        ...state,
      };

      if (!isEqual(fetchAppViewServicesState, newState)) {
        setFetchAppViewServicesState(state);
      }
    },
    [fetchAppViewServicesState, setFetchAppViewServicesState]
  );
};
