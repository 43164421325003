const podNotFoundRegex = /request to read log failed: pods ".*?" not found/;
const containerIsNotAvailableRegex =
  /request to read log failed: (container ".+" in pod ".+" is not available)/;

const getFormattedErrorMessage = (
  failureMessage: string | null,
  containerName = ""
): string | null => {
  if (
    failureMessage?.endsWith("PodInitializing") ||
    failureMessage?.endsWith("ContainerCreating")
  ) {
    const containerMeta = containerName ? ` (${containerName})` : "";
    return `The container${containerMeta} hasn't started yet.`;
  }

  if (failureMessage?.startsWith("logs was not produced yet by container")) {
    return "Logs have not been generated by the container yet.";
  }

  if (failureMessage && podNotFoundRegex.test(failureMessage)) {
    return "The pod can't be found.";
  }

  const containerIsNotAvailableErrorMessage = failureMessage
    ?.match(containerIsNotAvailableRegex)
    ?.at(1);
  if (containerIsNotAvailableErrorMessage) {
    return `The ${containerIsNotAvailableErrorMessage}.`;
  }

  return null;
};

export default getFormattedErrorMessage;
