import { useCallback } from "react";

import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import { setStoredAccountId } from "../../../shared/utils/auth0/localEnv/storedAccountId";
import { localStorageItemsToRemove } from "../LocalStorageItemsToRemove";
import { SERVICES } from "../../routes/routes";

import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";

export const useAccountChooseHandler = () => {
  const { accountId: currentAccountId } = useUserMetadata();

  return useCallback(
    (account: { value: string; label: string } | undefined) => {
      if (!account || account.value === currentAccountId) return;
      dispatchEvent(AnalyticEvents.App.Account_Switcher_Select, {
        page: window.location.pathname,
        to: account.label,
      });
      setStoredAccountId(account.value);
      localStorageItemsToRemove.forEach((item) => item.remove());
      window.location.assign(
        window.location.pathname.startsWith(`/${SERVICES}/`) ||
          window.location.pathname.startsWith("/main/resources")
          ? `/${SERVICES}`
          : window.location.pathname
      );
    },
    [currentAccountId]
  );
};
