import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { GrayHighlight } from "../../../common/styles";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import NodeOtherConditionFailureDetails from "./detailsComponents/NodeOtherConditionFailureDetails";

type ConditionStatus = "True" | "False" | "Unknown";
export interface NodeOtherConditionFailureCheckOutput {
  conditions: {
    type: string;
    status: ConditionStatus;
    reason: string;
    message: string;
    lastHeartbeat: number;
    lastTransition: number;
  }[];
}

export default class NodeOtherConditionFailureCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "More conditions statuses";
    this.action = (
      <>
        We ran{" "}
        <GrayHighlight>
          kubectl get pods --all-namespaces -o wide --field-selector
          spec.nodeName={"<NODE>"}
        </GrayHighlight>{" "}
        to check for more unhealthy conditions.
      </>
    );
    this.question = "Are there some other unhealthy conditions?";
    this.output = check.output as NodeOtherConditionFailureCheckOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <NodeOtherConditionFailureDetails check={this} />;
  }
}
