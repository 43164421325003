import Typography from "@mui/material/Typography";
import styled from "styled-components";
import SpaceDashboardOutlined from "@mui/icons-material/SpaceDashboardOutlined";
import { muiColors } from "@komodorio/design-system";
import Skeleton from "@mui/material/Skeleton";
import { Kubernetes24 } from "@komodorio/design-system/icons";

import { OverflowTextStyle } from "../../../shared/styles/styles";
import { WorkspaceKind } from "../../../generated/workspacesApi";
import { AriaLabels } from "../../../shared/config/ariaLabels";

import { WorkspaceOption } from "./constants";

const CustomValue = styled.div`
  display: flex;
  column-gap: 6px;
  padding-left: 4px;
  padding-right: 2px;
  align-items: center;
  ${OverflowTextStyle}
`;

const StyledTypography = styled(Typography)`
  && {
    ${OverflowTextStyle}
  }
`;

type SelectedWorkspaceValueProps = {
  selectedWorkspace: WorkspaceOption | string | undefined;
};

export const SelectedWorkspaceValue = ({
  selectedWorkspace,
}: SelectedWorkspaceValueProps) => {
  const isClusterWorkspace =
    typeof selectedWorkspace !== "string" &&
    (selectedWorkspace?.workspace?.kind === WorkspaceKind.Cluster ||
      selectedWorkspace?.workspace === undefined);

  const isClusterGroupWorkspace =
    typeof selectedWorkspace !== "string" &&
    selectedWorkspace?.workspace?.kind === WorkspaceKind.ClustersGroup;

  const label =
    typeof selectedWorkspace === "string"
      ? selectedWorkspace
      : selectedWorkspace?.label;

  return (
    <CustomValue>
      {isClusterWorkspace || isClusterGroupWorkspace ? (
        <Kubernetes24 color={muiColors.gray[500]} />
      ) : (
        <SpaceDashboardOutlined sx={{ color: muiColors.gray[500] }} />
      )}
      <Typography variant="h4" color="text.secondary">
        {isClusterWorkspace
          ? "Cluster"
          : isClusterGroupWorkspace
          ? "Cluster Group"
          : "Workspace"}
        {":"}
      </Typography>
      {selectedWorkspace ? (
        <StyledTypography
          variant="h4"
          color="text.primary"
          aria-label={AriaLabels.Workspaces.SelectedValue}
        >
          {isClusterGroupWorkspace ? selectedWorkspace.workspace?.name : label}
        </StyledTypography>
      ) : (
        <Skeleton
          aria-label={AriaLabels.Workspaces.Loader}
          variant="rounded"
          width={74}
          height={24}
        />
      )}
    </CustomValue>
  );
};
