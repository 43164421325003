import React, { useCallback, useMemo } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { TypographyProps } from "@mui/material/Typography/Typography";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";

const textRegex = /(\s+|[(),'])/; //match for white spaces, comma, parentheses or single quotation mark

const useOnResourceLinkClick = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    (komodorUid: string) => {
      const {
        kind = "",
        cluster = "",
        namespace = "",
        name = "",
      } = parseKomodorUid(komodorUid) ?? {};
      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByData,
        cluster,
        namespace,
        resourceType: kind,
        resourceName: name,
      });
    },
    [pushDrawer]
  );
};

type TextWithResourcesLinksProps = {
  text: string | undefined;
  resourceKomodorUids: string[] | undefined;
  typographyProps?: TypographyProps;
};

export const TextWithResourcesLinks: React.FC<TextWithResourcesLinksProps> = ({
  text,
  resourceKomodorUids,
  typographyProps,
}) => {
  const onResourceLinkClick = useOnResourceLinkClick();

  const content = useMemo(() => {
    if (!text) return null;

    const allResourceNames = resourceKomodorUids?.map((komodorUid) => {
      const { name } = parseKomodorUid(komodorUid) ?? {};
      return name;
    });

    if (!allResourceNames) return text;

    return text
      .split(textRegex)
      .filter(Boolean)
      .map((word) => {
        const foundKomodorUid = allResourceNames.find((resourceName) => {
          return resourceName === word;
        });
        return foundKomodorUid ? (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onResourceLinkClick(foundKomodorUid);
            }}
            sx={{ cursor: "pointer", textDecoration: "none" }}
          >
            {word}
          </Link>
        ) : (
          word
        );
      });
  }, [onResourceLinkClick, resourceKomodorUids, text]);

  return (
    <Typography variant={"body2"} {...typographyProps}>
      {content}
    </Typography>
  );
};
