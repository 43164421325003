import { useState } from "react";

import externalDNSImgSrc from "./images/external-dns.png";
import impactedWorkloadsDNSDrawer from "./images/impacted-workloads-dns-drawer.png";
import clusterDevEu2ExternalDNSDrawer from "./images/cluster-dev-eu-2-external-dns.png";
import sslCertificateValidationFailedDrawer from "./images/sslCertificateValidationFailedDrawer.png";
import dnsWebNotSyncedDrawer from "./images/dns-web-not-synced-drawer.png";
import {
  AlternateCloseButton,
  ClickableCard,
  CloseButton,
  RelativeContainer,
  SelectableDrawerRow,
  SelectableRow,
} from "./styles";
import { MockDrawer } from "./MockDrawer";

type SecondaryDrawer = {
  imgSrc: string;
  rowTop: string;
  nextDrawer?: string;
};

export const ExternalDNSView: React.FC = () => {
  const [drawerImgSrc, setDrawerImgSrc] = useState<string | null>(null);
  const [secondaryDrawer, setSecondaryDrawer] =
    useState<SecondaryDrawer | null>(null);
  const [violationsStack, setViolationsStack] = useState<string[]>([]);
  return (
    <RelativeContainer>
      <img src={externalDNSImgSrc}></img>
      <SelectableRow
        height="6%"
        top="28.3%"
        onClick={() => {
          setDrawerImgSrc(clusterDevEu2ExternalDNSDrawer);
        }}
      />
      <MockDrawer
        open={drawerImgSrc !== null}
        imgSrc={drawerImgSrc}
        onClose={() => setDrawerImgSrc(null)}
        closeButton={<CloseButton onClick={() => setDrawerImgSrc(null)} />}
      >
        <ClickableCard
          height="78px"
          width="28%"
          left="4.5%"
          top="229px"
          onClick={() => {
            setSecondaryDrawer({
              imgSrc: impactedWorkloadsDNSDrawer,
              rowTop: "151px",
              nextDrawer: dnsWebNotSyncedDrawer,
            });
          }}
        />
        <ClickableCard
          height="78px"
          width="29.7%"
          left="33.5%"
          top="229px"
          onClick={() => {
            setSecondaryDrawer({
              imgSrc: sslCertificateValidationFailedDrawer,
              rowTop: "1427px",
              nextDrawer: dnsWebNotSyncedDrawer,
            });
          }}
        />
      </MockDrawer>
      <MockDrawer
        open={secondaryDrawer !== null}
        imgSrc={secondaryDrawer?.imgSrc ?? ""}
        onClose={() => setSecondaryDrawer(null)}
        closeButton={
          <AlternateCloseButton onClick={() => setSecondaryDrawer(null)} />
        }
      >
        <SelectableDrawerRow
          height="60px"
          top={secondaryDrawer?.rowTop ?? ""}
          onClick={() => {
            setViolationsStack([secondaryDrawer?.nextDrawer ?? ""]);
          }}
        />
      </MockDrawer>
      {violationsStack.map((violationImgSrc) => {
        const nextImage =
          violationImgSrc === dnsWebNotSyncedDrawer
            ? sslCertificateValidationFailedDrawer
            : dnsWebNotSyncedDrawer;
        const top = nextImage === dnsWebNotSyncedDrawer ? "1427px" : "1302px";
        const onClose = () =>
          setViolationsStack((violationsStack) => violationsStack.slice(0, -1));
        return (
          <MockDrawer
            open={true}
            imgSrc={violationImgSrc}
            onClose={onClose}
            closeButton={<CloseButton onClick={onClose} />}
          >
            <SelectableDrawerRow
              height="60px"
              top={top}
              onClick={() => {
                setViolationsStack((violations) => [...violations, nextImage]);
              }}
            />
          </MockDrawer>
        );
      })}
    </RelativeContainer>
  );
};
