import { ViolationSupportingData } from "@/generated/reliabilityApi";
import { getCascadingFailureSupportingData } from "@/components/reliability/__test__/mockData/singleViolationSupportingData/getCascadingFailureSupportingData";

export const getCascadingFailureTitle = (
  supportingData: ViolationSupportingData | undefined
) => {
  const mockData = getCascadingFailureSupportingData();
  const servicesNum =
    mockData.cascadingFailure?.cascadingSessionInfo.affected_services.length;
  return `Cascading Failure - ${servicesNum} Services`;
};
