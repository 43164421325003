import React from "react";
import styled from "styled-components";

import EventDetailsSection from "../components/EventDetailsSection";
import slackIcon from "../../../../integrations/management/logos/slack.svg";

export const StaticHeight = styled.div`
  min-height: 0.9rem;
`;

const Link = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer",
})`
  color: #007aff;
  margin-inline-start: 0.2rem;
`;

interface SlackSectionProps {
  sentToChannels: string[] | undefined;
}

const SlackSection: React.FC<SlackSectionProps> = ({ sentToChannels }) => {
  return (
    <EventDetailsSection icon={slackIcon} title="Slack">
      <StaticHeight>
        {sentToChannels && sentToChannels.length > 0 && (
          <>
            Notification sent on
            {sentToChannels.map((ch) => (
              <Link
                key={ch}
                href={`https://slack.com/app_redirect?channel=${ch.slice(1)}`}
              >
                {ch}
              </Link>
            ))}
          </>
        )}
      </StaticHeight>
    </EventDetailsSection>
  );
};

export default SlackSection;
