import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import {
  Button,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
  Modal,
} from "@komodorio/design-system/deprecated";

import {
  useAgentInfoById,
  AgentProperties,
} from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { UpgradeCommands } from "../upgradeAgentCta/UpgradeAgentCTA";
import CopyCode from "../../integrations/installation/kubernetes/CopyCode";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";

import K8sIcon from "./k8s.svg?react";

const StyledModalContent = styled(ModalContent)`
  display: flex;
`;

const StyledIcon = styled(K8sIcon)`
  padding-right: 1rem;
`;

const NotUsingHelmContainer = styled.div`
  color: ${palette.gray[600]};
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useNeedUpgradeAgent = (
  agentId: string | undefined,
  cluster: string | undefined,
  agentSupportCheck: (agentProps: AgentProperties | null) => boolean
): boolean => {
  const { agentProperties: agentInfo } = useAgentInfoById(agentId, cluster);
  return useMemo(() => {
    return !agentSupportCheck(agentInfo);
  }, [agentInfo, agentSupportCheck]);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum UpgradeRequiredModalStep {
  Introduction = "introduction",
  ShowCommand = "showCommand",
}

export type UpgradeRequiredModalProps = {
  handleClose: () => void;
  updateMessage: JSX.Element | null;
  upgradeCommand: string;
  desiredFeature: string;
  initialStep?: UpgradeRequiredModalStep;
};

export const UpgradeRequired: React.FC<UpgradeRequiredModalProps> = ({
  handleClose,
  updateMessage,
  upgradeCommand,
  desiredFeature,
  initialStep = UpgradeRequiredModalStep.Introduction,
}) => {
  const [showStep, setShowStep] = useState(initialStep);
  const showMessageInShowCommandStep =
    updateMessage && initialStep === UpgradeRequiredModalStep.ShowCommand;
  const docsStr = useGetHelmInstallationCommand(UpgradeCommands.UPGRADE_DOCS);
  return (
    <>
      <Modal
        isOpen={showStep === UpgradeRequiredModalStep.Introduction}
        width="30rem"
        onRequestClose={handleClose}
      >
        <ModalHeader>
          <Typography
            variant="headline"
            aria-label={AriaLabels.updateAgent.introduction}
          >
            Update agent
          </Typography>
        </ModalHeader>
        <StyledModalContent>
          <StyledIcon />
          {updateMessage}
        </StyledModalContent>
        <ModalActions>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              setShowStep(UpgradeRequiredModalStep.ShowCommand);
            }}
          >
            Continue
          </Button>
        </ModalActions>
      </Modal>
      <Modal
        isOpen={showStep === UpgradeRequiredModalStep.ShowCommand}
        width="35rem"
        onRequestClose={handleClose}
      >
        <ModalHeader>
          <Typography
            variant="headline"
            aria-label={AriaLabels.updateAgent.command}
          >
            Update agent
          </Typography>
        </ModalHeader>
        <ModalContent>
          <Typography size="medium">
            The following command will update your Komodor agent to a version
            supporting {desiredFeature}
          </Typography>
          <CopyCode value={upgradeCommand} width="34rem" />
          <br />
          {showMessageInShowCommandStep && (
            <>
              {updateMessage}
              <br />
            </>
          )}
          <NotUsingHelmContainer>
            <Typography size="medium">
              Not using helm?
              <br />
              Read about other ways to install our agent in our{" "}
              <a rel="noreferrer" target="_blank" href={docsStr}>
                Installation Guide
              </a>
            </Typography>
          </NotUsingHelmContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpgradeRequired;
