import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { subHours } from "date-fns";
import { Typography } from "@komodorio/design-system/deprecated";

import { Timeframe } from "../../../shared/types/TimeWindow";
import useTimeWindowFromURL from "../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL";
import { H3 } from "../../common/typography";
import { useQueryStringInLocalStorage } from "../../../shared/hooks/state";
import ResponsiveLayout from "../../common/ResponsiveLayout";
import useWorkflowsRuns from "../useWorkflowsRuns";
import NoRuns from "../assets/search.svg?react";
import useWorkflowConfiguration from "../useWorkflowConfigurations";
import { blackGreyText } from "../../../Colors";
import { useWorkflowsFlags } from "../common/availableWorkflows";
import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import MonitorsViewContainer from "..";

import RunsTable from "./RunsTable";

const LightH3 = styled(H3)`
  font-weight: 400;
  font-size: 12px;
  color: ${blackGreyText};
`;

const BiggerH3 = styled(LightH3)`
  font-size: 16px;
`;

const NoRunsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  min-height: 25rem;
  margin: 7rem 0;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialTimeWindow = {
  start: subHours(new Date(), 1),
  end: new Date(),
  timeframe: Timeframe.LastHour,
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const storedTriggeredMonitors = new LocalStorageItem(
  "TriggeredMonitorsViewQS"
);

const TriggeredMonitors: React.FC = () => {
  const [timeWindow, setTimeWindow] = useTimeWindowFromURL(initialTimeWindow);

  useEffect(() => {
    const now = new Date();
    const diff = Math.abs((now.getTime() - timeWindow.end.getTime()) / 1000);
    const endToNowSeconds = Math.abs(diff);
    if (endToNowSeconds > 30) {
      setTimeWindow({ ...timeWindow, timeframe: Timeframe.Custom });
    }
  }, [setTimeWindow, timeWindow]);

  const { workflowsRuns, isFetching } = useWorkflowsRuns(timeWindow);
  const { isEmptyConfigurations } = useWorkflowConfiguration();

  const wfFlags = useWorkflowsFlags();
  const workflowRunsByFlags = useMemo(() => {
    if (!workflowsRuns) {
      return [];
    }
    return workflowsRuns?.filter((run) => wfFlags[run.type]);
  }, [wfFlags, workflowsRuns]);

  useQueryStringInLocalStorage(storedTriggeredMonitors);

  return (
    <MonitorsViewContainer>
      <ResponsiveLayout>
        {isEmptyConfigurations ? (
          <NoRunsContainer>
            <NoRuns />
            <br />
            <Typography variant="headline">
              No Monitor Configurations Available
            </Typography>
            <br />
            <BiggerH3>
              Whenever a Monitor is <b>triggered</b>, its result will appear in
              this page.
            </BiggerH3>
          </NoRunsContainer>
        ) : (
          <RunsTable
            workflowRuns={workflowRunsByFlags}
            timeWindow={timeWindow}
            setTimeWindow={setTimeWindow}
            isFetching={isFetching}
          />
        )}
      </ResponsiveLayout>
    </MonitorsViewContainer>
  );
};

export default TriggeredMonitors;
