import React, { RefObject, useCallback, useMemo } from "react";

import {
  ADDITIONAL_ITEMS_NOTIFICATION_PADDING,
  GROUP_EVENTS_ID,
  HORIZONTAL_PADDING,
  MAX_TIMELINE_COMPONENTS,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/timelineEventsConstants";
import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";

export const useCalculateGroupedEventsPosition = (
  containerRef: RefObject<HTMLDivElement>
) => {
  const [absoluteContainerX, setAbsoluteContainerX] = React.useState("0px");
  const [absoluteContainerWidth, setAbsoluteContainerWidth] =
    React.useState("0px");
  const addedPadding =
    useExcludedTimelineEventsNum() > 0
      ? ADDITIONAL_ITEMS_NOTIFICATION_PADDING
      : 0;

  const calculateGroupedEvents = useCallback(() => {
    if (containerRef.current) {
      const groupedItems = containerRef.current.querySelectorAll(
        `#${GROUP_EVENTS_ID}`
      );
      if (groupedItems.length < 2) return;

      const leftMostGroupedItem = groupedItems[0];
      const rightMostGroupedItem = groupedItems[groupedItems.length - 1];
      const leftX = leftMostGroupedItem.getBoundingClientRect().left;
      const rightX = rightMostGroupedItem.getBoundingClientRect().right;
      setAbsoluteContainerX(`${-HORIZONTAL_PADDING * 0.5}px`);
      const width = rightX - leftX + HORIZONTAL_PADDING + addedPadding;
      setAbsoluteContainerWidth(
        `${Math.min(width, containerRef.current.clientWidth)}px`
      );
    }
  }, [addedPadding, containerRef]);

  return { absoluteContainerX, absoluteContainerWidth, calculateGroupedEvents };
};

export const useExcludedTimelineEventsNum = () => {
  const { timeline } = useGetCascadingFailureSupportingData() ?? {};

  return useMemo(() => {
    return timeline?.length
      ? Math.max(timeline.length - MAX_TIMELINE_COMPONENTS, 0)
      : 0;
  }, [timeline]);
};

export const useDisplayedTotalTimelineEvents = () => {
  const { timeline } = useGetCascadingFailureSupportingData() ?? {};
  const excludedTimelineEventsNum = useExcludedTimelineEventsNum();

  return useMemo(() => {
    const totalTimelineEvents = timeline?.length ?? 0;
    return totalTimelineEvents - excludedTimelineEventsNum;
  }, [excludedTimelineEventsNum, timeline?.length]);
};
