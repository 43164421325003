import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import workflowsPage from "../assets/screen-workflows.png";
import { clustersDemoRoutes } from "../constants";
import { ClickableArea, RelativeContainer } from "../styles";

const SelectableRow = styled(ClickableArea)<{ top: string }>`
  width: 96.4%;
  height: 10%;
  left: 1.7%;
  top: ${({ top }) => top};
`;

export const WorkflowsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RelativeContainer>
      <img src={workflowsPage} alt={"workflows page"} />
      <SelectableRow
        top={"38.9%"}
        onClick={() => navigate(clustersDemoRoutes.wfLaneExtractorRegression)}
      />
      <SelectableRow
        top={"48.9%"}
        onClick={() => navigate(clustersDemoRoutes.wfIcpMapGenerator)}
      />
    </RelativeContainer>
  );
};
