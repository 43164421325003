import { notifyDDError } from "../hooks/exceptionManagement";
import { retry } from "../utils/retry";

export const connectWithRetry = async (
  connect: () => Promise<void>
): Promise<void> => {
  try {
    await retry<void>({
      func: connect,
      retries: 10,
      exponentialBackoff: true,
    });
  } catch (error) {
    notifyDDError(error as Error, {
      name: "failed to reconnect to socket",
      message: (error as Error).message,
    });
  }
};
