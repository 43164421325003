import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import cn from "classnames";

import { blueForUI2, darkBlueBrand } from "../../../Colors";

import i from "./iBlack.svg";

const OnStickyStyle = css`
  border-radius: 0;
  margin: 0;
  width: 100vw;
  left: 0;
  position: fixed;
  .content {
    width: 100rem;
  }
`;
const HeaderContainer = styled.div<{ topFromEventPage?: string }>`
  border-radius: 10px;
  background-color: ${blueForUI2};
  padding: 1rem;
  left: 0;
  width: 86rem;
  margin-top: 1rem;
  z-index: 16;
  ${({ topFromEventPage }) =>
    topFromEventPage
      ? topFromEventPage !== "0px"
        ? `
      position: fixed;
      top: 50px;
      margin: 0;
      .content {
        width: 100rem;
      }
      &.sticky {
        ${OnStickyStyle}
        top: ${topFromEventPage};
      }
  `
        : ""
      : `
      &.sticky {
        ${OnStickyStyle}
        top: 0;
      }
      `};
`;

const AlertContent = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2rem auto 2rem;
`;

const AlertTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: ${darkBlueBrand};
`;

export interface IntegrationPermissionAlertProps {
  title: string;
}

export const IntegrationPermissionAlert: React.FC<
  IntegrationPermissionAlertProps
> = ({ title }) => {
  const header = useRef<HTMLDivElement | null>(null);

  const [stickAlert, setStickAlert] = useState(false);
  useEffect(() => {
    const handleScroll = () =>
      header.current && window.pageYOffset > header.current.offsetTop
        ? setStickAlert(true)
        : setStickAlert(false);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [header]);
  return (
    <>
      <HeaderContainer className={cn({ sticky: stickAlert })} ref={header}>
        <AlertContent className={"content"}>
          <img src={i} alt="global alert" />
          <AlertTitle>{title}</AlertTitle>
        </AlertContent>
      </HeaderContainer>
    </>
  );
};
