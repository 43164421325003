/* eslint-disable max-lines */
import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { Drawer } from "@komodorio/design-system/deprecated";

import slackIcon from "../../integrations/management/logos/slack.svg";
import teamsIcon from "../../integrations/management/logos/teams.svg";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowRun } from "../useWorkflowsRuns";
import clockIcon from "../assets/clock.svg";
import { gray10 } from "../../../Colors";
import { GrayHighlight, NormalText, WrapWithIcon } from "../common/styles";
import {
  BoldStyleText,
  smallBoldStyle,
  SmallLightShortText,
} from "../monitorsConfiguration/common/styles";
import Divider from "../common/Divider";
import CloseButton from "../common/CloseButton";
import SensorLabels from "../common/SensorLabels";
import TabButtons from "../../common/TabButtons";
import Steps from "../../common/Steps";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowIssueEvent } from "../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WorkflowConfigType } from "../common/types";

const typeNames: { [key: string]: string } = {
  PVC: "PersistentVolumeClaim",
  node: "Node",
  availability: "Workload",
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const typeTriggers: { [key: string]: string } = {
  PVC: "is in pending state",
  node: "is unhealthy",
  availability: "is unhealthy",
  deploy: "failed",
  pod: "is unhealthy",
  workflow: "is unhealthy",
};

const explanations: { [key: string]: ReactElement } = {
  PVC: (
    <>
      Normally the PVCs get created and bound to the cluster quickly, however,{" "}
      <b>in some cases the PVCs fail to bind to the cluster</b>, which results
      in them getting stuck in the Pending state, this prevents the deployment
      from completing.
    </>
  ),
  node: (
    <>
      Nodes are a vital component of a Kubernetes cluster and are responsible
      for running the pods. Before a pod is scheduled on a node, Kubernetes
      checks whether the node is capable of running the pod or not. To
      effectively schedule pods to a node, he must be running correctly.
    </>
  ),
};

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1rem;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
`;

const TabContainer = styled.div`
  padding-top: 0.5rem;
`;

export const StyledSteps = styled(Steps).attrs({ height: "fit-content" })`
  justify-content: unset;
  align-items: unset;
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Section = styled.div<{ bottomDivider?: boolean }>`
  padding: 1.3rem;
  ${({ bottomDivider = true }) =>
    bottomDivider && "border-bottom: 1px solid #dfdddf;"}
`;

const SubTitle = styled.div`
  ${smallBoldStyle};
  color: ${gray10};
  margin-bottom: 0.75rem;
`;

export const ClockIcon = styled.img.attrs({ src: clockIcon, alt: "Clock" })`
  margin-inline-end: 0.5rem;
`;

const SinkIcon = styled.img`
  height: 1rem;
  margin-inline-end: 0.5rem;
  padding: 0.25rem;
`;

const SlackIcon = styled(SinkIcon).attrs({ src: slackIcon, alt: "Slack" })``;

const TeamsIcon = styled(SinkIcon).attrs({ src: teamsIcon, alt: "Teams" })``;

export const IssueDetailsTabContent: React.FC<{
  workflowRun: WorkflowRun | WorkflowIssueEvent | null;
  hideSummary?: boolean;
}> = ({ workflowRun, hideSummary = false }) => {
  const { format } = useDateFormatter();

  const {
    type = "",
    closedAt,
    closeReason,
    shortResourceName = "",
  } = workflowRun ?? {};

  const closeReasons: { [key: string]: string } = {
    user: "manually",
    recovered: `automatically as the ${typeNames[type]} was recovered`,
    modified: `automatically as the ${typeNames[type]} was modified`,
    deleted: `automatically as the ${typeNames[type]} was deleted`,
  };

  return (
    <>
      {!hideSummary && (
        <Section>
          <SubTitle>Summary</SubTitle>
          <NormalText>
            <b>We detected that {typeNames[type]} </b>
            <GrayHighlight>{shortResourceName}</GrayHighlight>
            <b> {typeTriggers[type]}.</b>
          </NormalText>
        </Section>
      )}
      {closedAt && (
        <Section>
          <SubTitle>Timeline</SubTitle>
          <TimelineContainer>
            <div>
              <SmallLightShortText>
                {format(new Date(closedAt))}
              </SmallLightShortText>
              {type !== WorkflowConfigType.Deploy && (
                <BoldStyleText>
                  Issue was closed {closeReason && closeReasons[closeReason]}
                </BoldStyleText>
              )}
            </div>
          </TimelineContainer>
        </Section>
      )}
      {explanations[type] && (
        <Section bottomDivider={false}>
          <SubTitle>Explanation</SubTitle>
          <SmallLightShortText>{explanations[type]}</SmallLightShortText>
        </Section>
      )}
    </>
  );
};

const ConfigurationTabContent: React.FC<{
  workflowRun: WorkflowRun | null;
}> = ({ workflowRun }) => {
  const { sensors, sinks, variables } =
    workflowRun?.workflowConfiguration ?? {};
  const { duration, minAvailable } = variables ?? {};

  return (
    <>
      {sensors && (
        <Section>
          <SubTitle>Monitor Sensor</SubTitle>
          <SensorLabels sensor={sensors[0]} showAllNamespaces />
        </Section>
      )}
      {(duration || minAvailable) && (
        <Section>
          <SubTitle>Threshold Configuration</SubTitle>
          {duration && (
            <SmallLightShortText>
              Minimum event duration <b>{duration} sec</b>.
            </SmallLightShortText>
          )}
          {minAvailable && (
            <SmallLightShortText>
              Minimum replicas available <b>{minAvailable}</b>.
            </SmallLightShortText>
          )}
        </Section>
      )}
      {sinks && (
        <Section>
          <SubTitle>Sinks</SubTitle>
          {sinks.slack && sinks.slack.length !== 0 && (
            <WrapWithIcon>
              <SlackIcon />
              <SmallLightShortText>
                {sinks.slack.map((s) => "#" + s).join(", ")}
              </SmallLightShortText>
            </WrapWithIcon>
          )}
          {sinks.teams && sinks.teams.length !== 0 && (
            <WrapWithIcon>
              <TeamsIcon />
              <SmallLightShortText>
                {sinks.teams.map((s) => "@" + s).join(", ")}
              </SmallLightShortText>
            </WrapWithIcon>
          )}
        </Section>
      )}
    </>
  );
};

const StyledDrawer = styled(Drawer)`
  position: fixed;
`;

const IssueDetailsDrawer: React.FC<{
  workflowRun: WorkflowRun | null;
  handleClose: () => void;
  isOpen: boolean;
}> = ({ workflowRun, handleClose, isOpen }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <StyledDrawer
        open={isOpen}
        direction="right"
        width="25rem"
        openInScope
        zIndex={4}
      >
        <Header>
          <TabContainer>
            <TabButtons
              tabs={["Issue Details", "Configuration"]}
              currentTabIndex={selectedTab}
              onTabClick={(tab) => setSelectedTab(tab)}
            />
          </TabContainer>
          <CloseButton onClick={handleClose} />
        </Header>
        <Divider />
        <StyledSteps curStep={selectedTab}>
          <IssueDetailsTabContent workflowRun={workflowRun} />
          <ConfigurationTabContent workflowRun={workflowRun} />
        </StyledSteps>
      </StyledDrawer>
    </>
  );
};

export default IssueDetailsDrawer;
