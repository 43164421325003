import { useMemo } from "react";

import { ServiceInfo } from "../../types/ServiceInfo";
import useKomodorServices from "../useKomodorServices";
import { getNamespaceFromService } from "../useNamespaceFromService";
import Resource from "../../../components/ResourceView/resources";
import { Uid } from "../../utils/generateUid";

export const DESC = "DESC";
export const ASC = "ASC";
export const Create = "Create";
export const Update = "Update";
export const Delete = "Delete";
export const Wide = "wide";
export const Json = "json";

export type BuildKomodorUidParams = {
  kind: string; // Enforce the type to be Kubernetes kind
  clusterName: string;
  namespace: string;
  resourceName: string;
};

export const buildKomodorUid = ({
  resourceName,
  clusterName,
  namespace,
  kind,
}: BuildKomodorUidParams): string => {
  const uid = [kind, clusterName, namespace, resourceName];
  return uid.join("|");
};

export type KomodorUidParams = {
  kind: string;
  cluster: string;
  namespace: string;
  name: string;
};
export const parseKomodorUid = (
  komodorUid: string
): KomodorUidParams | undefined => {
  const splittedKomodorUid = komodorUid.split("|");
  if (splittedKomodorUid.length !== 4) {
    return undefined;
  }
  const [kind, cluster, namespace, name] = splittedKomodorUid;
  return {
    kind,
    cluster,
    namespace,
    name,
  };
};

export function useBuildKomodorUidForJob(
  resourceId: string
): string | undefined {
  const service = useKomodorServices().jobs?.filter(
    (service) => service.id === resourceId
  )?.[0];

  return useMemo(() => buildKomodorUidFromService(service), [service]);
}

export function useBuildKomodorUidForService(
  resourceId: string
): string | undefined {
  const service = useKomodorServices().services?.filter(
    (service) => service.id === resourceId
  )?.[0];

  return useMemo(() => buildKomodorUidFromService(service), [service]);
}

export function useBuildKomodorUidForKomodorService(
  serviceId: string
): string | undefined {
  const service = useKomodorServices().all?.filter(
    (service) => service.id === serviceId
  )?.[0];

  return useMemo(() => buildKomodorUidFromService(service), [service]);
}

export function buildKomodorUidFromService(
  service: ServiceInfo | undefined
): string | undefined {
  const namespace = getNamespaceFromService(service?.tags) ?? "";
  const kind = service?.kind ?? "";
  if (!service) {
    return undefined;
  }
  return buildKomodorUid({
    kind,
    namespace,
    resourceName: service?.title ?? "",
    clusterName: service?.k8sCluster ?? "",
  });
}

export function buildKomodorUidFromResource(
  resource: Resource | undefined
): string | undefined {
  if (!resource) {
    return undefined;
  }
  return buildKomodorUid({
    kind: resource.kind,
    namespace: resource.namespace,
    resourceName: resource.name,
    clusterName: resource.cluster,
  });
}

export const useUidFromKomodorUid = (komodorUid: string): Uid | undefined => {
  return useMemo<Uid | undefined>(() => {
    const parsedKomodorUid = parseKomodorUid(komodorUid);
    if (!parsedKomodorUid) {
      return undefined;
    }
    return {
      cluster: parsedKomodorUid.cluster,
      namespace: parsedKomodorUid.namespace,
      name: parsedKomodorUid.name,
    };
  }, [komodorUid]);
};
