import { useMemo } from "react";

import {
  FetchExternalLinksQuery,
  useFetchExternalLinksQuery,
} from "../../../../../../generated/graphql";
import useQueryPolling from "../../../../../../shared/hooks/useQueryPolling";
import { useOverridableFlags } from "../../../../../../shared/context/featureFlags/OverridableFlags";
import { useGetExternalLinks } from "../../../../../../shared/hooks/resources-api/client/metadata/useGetExternalLinks";
import { ExternalLinksResponse } from "../../../../../../generated/resourcesApi";

import { getLinkIcon } from "./utils"; // [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ExternalLinkProps, Link } from "./useKomodorAnnotations";

const pollingInterval = 5000;

const useExternalLinkResult = (
  result: FetchExternalLinksQuery["external_link"] | undefined
): Link[] => {
  return useMemo(() => {
    return (
      result?.map((e) => ({
        icon: getLinkIcon(e.link, e.displayName),
        text: e.displayName,
        url: e.link,
        externalLinkProps: {
          linkId: e.id,
          externalLinkContext: e.externalLinkContextValue,
          serviceId: e.serviceId,
          k8sClusterName: e.k8sClusterName,
          namespace: e.namespace,
        } as ExternalLinkProps,
      })) ?? []
    );
  }, [result]);
};

export const useExternalLinks = (
  k8sClusterName?: string,
  serviceId?: string,
  namespace?: string
): Link[] => {
  const { fetchJobsFilterFromResourcesApi } = useOverridableFlags();

  const externalLinksApiVariables = useMemo(() => {
    if (!k8sClusterName && !serviceId && !namespace) {
      return {};
    }
    return {
      clusterName: k8sClusterName,
      serviceId: serviceId,
      entireAccount: true,
      namespace: namespace,
    };
  }, [k8sClusterName, serviceId, namespace]);

  const { data: externalLinksFromAPI } = useGetExternalLinks(
    externalLinksApiVariables,
    !!fetchJobsFilterFromResourcesApi
  );

  const externalLinksVariables = useMemo(() => {
    if (!k8sClusterName && !serviceId && !namespace) {
      return {};
    }
    return {
      k8sClusterName: k8sClusterName,
      serviceId: serviceId,
      namespace: namespace,
    };
  }, [k8sClusterName, serviceId, namespace]);

  const pauseFetch = Object.keys(externalLinksVariables).length === 0;

  const [result] = useQueryPolling(
    useFetchExternalLinksQuery({
      variables: externalLinksVariables,
      pause: pauseFetch || !!fetchJobsFilterFromResourcesApi,
    }),
    pollingInterval,
    pauseFetch
  );
  const apiDataConverted = useConvertToExternalLinks(externalLinksFromAPI);

  return useExternalLinkResult(
    fetchJobsFilterFromResourcesApi ? apiDataConverted : result?.external_link
  );
};

export type ExternalLinks = FetchExternalLinksQuery["external_link"];

const useConvertToExternalLinks = (
  data: ExternalLinksResponse | undefined
): ExternalLinks | undefined => {
  return useMemo(() => {
    return data?.data?.map(
      (externalLink) => externalLink as ExternalLinks[0]
    ) as ExternalLinks;
  }, [data]);
};
