import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { IntegrationType } from "komodor-types";

import { FormContainer } from "../CustomFormComponents";
import { useCreateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useCreateInstallation";

import { ArgocdForm } from "./ArgocdForm";

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  min-width: 53rem;
`;

const PaddedButton = styled.div`
  margin: 15.8px;
`;

const HorizontalPaddedDiv = styled.div<{ padding?: number }>`
  padding: ${({ padding }) => padding}px 0;
  display: flex;
`;

const ArgocdInstallation: React.FC<{ closeModalCallback: () => void }> = ({
  closeModalCallback,
}) => {
  const inputValueRef = useRef("");
  const handleFormSubmit = (inputValue: string) => {
    inputValueRef.current = inputValue;
    setDisableButton(false);
  };
  const handleButtonClick = async () => {
    try {
      await createInstallation({
        id: inputValueRef.current,
        integration: IntegrationType.ARGOCD,
        configuration: {},
      });
      setErrorText("");
      closeModalCallback();
    } catch (error) {
      setErrorText(
        "An error occurred, please contact support or try again later."
      );
      closeModalCallback();
      throw error;
    }
  };
  const [disableButton, setDisableButton] = useState(true);
  const { mutateAsync: createInstallation, error: createInstallationError } =
    useCreateInstallation();
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    if (createInstallationError) {
      setErrorText((createInstallationError as Error).message);
    }
  }, [createInstallation, createInstallationError, setErrorText]);

  return (
    <Container>
      <HeaderContainer>
        <Typography variant={"headline"} size={"small"}>
          Argo CD - External Cluster Support
        </Typography>
      </HeaderContainer>
      <ArgocdForm onFormSubmit={handleFormSubmit} />
      <br />
      {errorText && (
        <div>
          <Typography color={"error"}>
            An error occurred, please contact support
          </Typography>
          <Typography color={"error"}>{errorText}</Typography>
        </div>
      )}
      <FormContainer>
        <HorizontalPaddedDiv>
          <PaddedButton>
            <Button variant={"secondary"} onClick={closeModalCallback}>
              Close
            </Button>
          </PaddedButton>
          <PaddedButton>
            <Button
              variant="primary"
              onClick={handleButtonClick}
              disabled={disableButton}
            >
              Save And Close
            </Button>
          </PaddedButton>
        </HorizontalPaddedDiv>
      </FormContainer>
    </Container>
  );
};

export default ArgocdInstallation;
