import React, { ReactElement } from "react";
import styled from "styled-components";

import { Action, DetailsExternalLink, DisabledContent } from "../../common";
import EventDetailsSection from "../../components/EventDetailsSection";
import { dispatchEvent } from "../../../../../../shared/hooks/analytics";
import definitions from "../../../../../integrations/management/definitions";
import { AnalyticEvents } from "../../../../../../shared/config/analyticsEvents";
import { SOURCE_CONTROL_DOCS_LINK } from "../../../../../../shared/constants/docsLinks";

import {
  GitProviderData,
  GitProviders,
  GitType,
  GitTypeFinder,
} from "./GetGitTypeAndProviderData";
import {
  getText,
  isGitTypeNeedInstallation,
  GitChangeComponent,
} from "./GitSectionUtils";

const NotRecognizedGit = `Configure your source control to display pull requests and commits on each deploy event.`;
const LinkToAnnotation = `Link this service to repositories in you source control to show
related code changes by using annotations`;
const GithubInstallationSuggestion = `We wanted to show you the PRs and commits but the integration for
Github wasn’t configured yet.`;

const GitChange = styled.div`
  margin-block-end: 0.75rem;
`;

interface NewGitSectionProps {
  gitTypeFinder: GitTypeFinder;
  gitProvider?: GitProviderData;
}

const SectionTemplate: React.FC<{
  icon: string;
  info: string;
  title: string;
  content: string;
  linkTitle: string;
  action?: ReactElement;
}> = ({ icon, info, title, content, linkTitle, action }) => {
  return (
    <EventDetailsSection icon={icon} title={title} info={info} disabled={false}>
      <DisabledContent>
        <>
          {content}
          {action ? (
            { action }
          ) : (
            <DetailsExternalLink
              href={SOURCE_CONTROL_DOCS_LINK}
              title={linkTitle}
            />
          )}
        </>
      </DisabledContent>
    </EventDetailsSection>
  );
};

// Here link explanined the complexaty over git integration
// https://miro.com/app/board/uXjVOX_zTlU=/
const GitSection: React.FC<NewGitSectionProps> = ({
  gitTypeFinder,
  gitProvider: gitProviderData,
}) => {
  const gitType = gitTypeFinder.type;
  const icon = GitProviders.get(gitType)?.icon ?? "";
  const info = GitProviders.get(gitType)?.info ?? "";
  const name = GitProviders.get(gitType)?.name ?? "";

  if (gitType === GitType.NotRecognized) {
    return (
      <SectionTemplate
        icon={icon}
        title={name}
        info={info}
        content={NotRecognizedGit}
        linkTitle={"Add source control connection"}
      />
    );
  }

  if (isGitTypeNeedInstallation(gitType) && !gitTypeFinder.hasInstallation) {
    const githubInstallation = (
      <Action
        onClick={() => {
          definitions.github.onInstallClick?.();
          dispatchEvent(AnalyticEvents.CTA.Github_Pressed);
        }}
      >
        Add Github integration
      </Action>
    );
    if (gitType === GitType.Github) {
      <SectionTemplate
        icon={icon}
        title={name}
        info={info}
        content={GithubInstallationSuggestion}
        linkTitle={""}
        action={githubInstallation}
      />;
    } else {
      <SectionTemplate
        icon={icon}
        title={name}
        info={info}
        content={NotRecognizedGit}
        linkTitle={"Add source control connection"}
      />;
    }
  }

  if (!gitTypeFinder.hasAnnotations && !gitProviderData) {
    return (
      <SectionTemplate
        icon={icon}
        title={name}
        info={info}
        content={LinkToAnnotation}
        linkTitle={"How to add annotations"}
      />
    );
  }

  // if there isn't gitProviderData means there isn't gitCompare so hide section
  if (!gitProviderData) {
    return null;
  }

  const commitUrl = GitProviders.get(gitType)?.commitUrl ?? (() => "");
  const prPrefix = GitProviders.get(gitType)?.prPrefix ?? "";
  const title = name + gitProviderData.gitGroup;
  return (
    <EventDetailsSection icon={icon} title={title} info={info}>
      <>
        {gitProviderData.diff?.map((commit, index) => (
          <GitChange key={index}>
            <GitChangeComponent
              key={commit.pr_url}
              id={index}
              href={commit.pr_url ?? commitUrl(commit)}
              title={getText(commit, prPrefix)}
              authorName={commit.author_name}
            />
          </GitChange>
        ))}
        <DetailsExternalLink
          href={gitProviderData.url}
          title={`View diff on ${name}`}
        />
      </>
    </EventDetailsSection>
  );
};

export default GitSection;
