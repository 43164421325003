import styled from "styled-components";
import React from "react";
import useClipboard from "react-use-clipboard";
import cn from "classnames";

import copyIcon from "./copy.svg";

const Container = styled.div`
  display: grid;
  grid-template:
    "label label" auto
    "value copy" 1fr / 1fr auto;
  gap: 0 0.5rem;
  align-items: center;
`;

const Label = styled.div`
  grid-area: label;
`;

const Value = styled.div<{ backgroundColor?: string }>`
  ${({ backgroundColor }) =>
    backgroundColor
      ? `padding: 0 0.2rem;
        background: #eaedf0;`
      : `font-weight: bold;`}
  grid-area: value;
  cursor: default;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

const CopyIconContainer = styled.div`
  grid-area: copy;
  cursor: pointer;
  position: relative;
  > img {
    display: block;
    width: 18px;
    height: 19px;
  }
`;

export const CopiedLabel = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  margin-inline-start: 0.25rem;
  padding: 0.2rem 0.5rem 0.1rem 0.6rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #3d4048;
  background: #dadbdc;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  &.hide {
    display: none;
  }

  ::after {
    content: "copied!";
  }
`;

const CopyComponent: React.FC<{
  label?: string;
  value: string;
  backgroundColor?: string;
  className?: string;
  valueClassName?: string;
}> = ({ label, value, backgroundColor, className, valueClassName }) => {
  const [copied, copy] = useClipboard(value, { successDuration: 3000 });

  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <Value
        onClick={copy}
        backgroundColor={backgroundColor}
        className={valueClassName}
      >
        {value}
      </Value>
      <CopyIconContainer onClick={copy}>
        <img src={copyIcon} alt="copy" />
        <CopiedLabel className={cn({ hide: !copied })} />
      </CopyIconContainer>
    </Container>
  );
};

export default CopyComponent;
