import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";

import { useActiveAgent } from "../../shared/hooks/useAgents";
import useResourceWithInterval from "../ResourceView/useResource";
import { ARGO_WORKFLOW_KIND } from "../ResourceView/resources/argoWorkflow";
import { ARGO_WORKFLOWS } from "../routes/routes";
import {
  buildKomodorUid,
  parseKomodorUid,
} from "../../shared/hooks/resources-api/resourcesAPIUtils";
import { ARGO_CRON_WORKFLOW_KIND } from "../ResourceView/resources/argoCronWorkflow";
import { ARGO_WORKFLOW_TEMPLATE_KIND } from "../ResourceView/resources/argoWorkflowTemplate";
import { ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND } from "../ResourceView/resources/argoClusterWorkflowTemplate";
import { ARGO_WORKFLOW_RUN_PARAM_KEY } from "../../shared/config/urlSearchParamsKeys";
import { ArgoWorkflowFullObject } from "../ResourceView/tabs/ArgoWorkflows/ArgoWorkflowDetailsTab";

import { argoKomodorKindToK8sKind } from "./convertArgoKinds";

type ArgoExternalLinkParams = {
  cluster?: string | null;
  namespace?: string | null;
  name?: string | null;
};
const useArgoExternalLinkParams = (): ArgoExternalLinkParams => {
  const [searchParams] = useSearchParams();

  const cluster = searchParams.get("cluster") ?? "";
  const namespace = searchParams.get("namespace") ?? "";
  const name = searchParams.get("name") ?? "";
  return {
    cluster,
    namespace,
    name,
  };
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useArgoWorkflowsRootOwner = (
  workflowKomodorUid: string | undefined
): string | undefined => {
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(workflowKomodorUid ?? "") ?? {};

  const agentId = useActiveAgent(cluster) ?? "";

  const { resource } = useResourceWithInterval({
    agentId,
    cluster,
    namespace,
    resourceName: name,
    resourceType: argoKomodorKindToK8sKind[kind] ?? kind,
  });

  if (!resource) return undefined;
  const obj = resource.fullObj as ArgoWorkflowFullObject;

  const templateName = obj?.spec?.workflowTemplateRef?.name;
  const isClusterTemplate =
    !!templateName && obj?.spec?.workflowTemplateRef?.clusterScope;

  const isWorkflowTemplate =
    !!templateName && !obj?.spec?.workflowTemplateRef?.clusterScope;

  const cronWorkflowName =
    resource?.labels?.["workflows.argoproj.io/cron-workflow"];
  const isCronWorkflow = !!cronWorkflowName;

  const isStandaloneWorkflow =
    !isWorkflowTemplate && !isCronWorkflow && !isClusterTemplate;

  const workflowsOwnerKomodorUid = buildKomodorUid({
    clusterName: cluster,
    namespace: namespace,
    resourceName: isCronWorkflow ? cronWorkflowName ?? "" : templateName ?? "",
    kind: isCronWorkflow
      ? ARGO_CRON_WORKFLOW_KIND
      : isClusterTemplate
      ? ARGO_CLUSTER_WORKFLOW_TEMPLATE_KIND
      : ARGO_WORKFLOW_TEMPLATE_KIND,
  });

  return isStandaloneWorkflow ? workflowKomodorUid : workflowsOwnerKomodorUid;
};

export const ArgoExternalLink: React.FC = () => {
  const navigate = useNavigate();
  const params = useArgoExternalLinkParams();

  const workflowId = buildKomodorUid({
    clusterName: params.cluster ?? "",
    namespace: params.namespace ?? "",
    resourceName: params.name ?? "",
    kind: ARGO_WORKFLOW_KIND,
  });

  const rootOwnerKomodorUid = useArgoWorkflowsRootOwner(workflowId);

  useEffect(() => {
    if (!rootOwnerKomodorUid) {
      return;
    }
    navigate(
      `/${ARGO_WORKFLOWS}/${rootOwnerKomodorUid}${
        workflowId !== rootOwnerKomodorUid
          ? `?${ARGO_WORKFLOW_RUN_PARAM_KEY}=${workflowId}`
          : ""
      }`
    );
  }, [navigate, rootOwnerKomodorUid, workflowId]);

  return null;
};
