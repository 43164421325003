import styled from "styled-components";

import { linkStyle } from "../typography";

const TextButton = styled.span<{ isSandbox?: boolean; disabled?: boolean }>`
  ${linkStyle}
  cursor: pointer;
  ${(props) =>
    props.isSandbox || props.disabled
      ? `
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      pointer-events: auto;
    }
    &:active {
      pointer-events: none; 
    }`
      : `&.disabled {
      pointer-events: none;
      opacity: 0.5;
  }`}
`;

export default TextButton;
