import { muiColors, palette } from "@komodorio/design-system";
import { IconProps } from "@komodorio/design-system/icons";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { Divider, Info } from "../common";

const DEFAULT_ICON_SIZE = "62%";

const Container = styled.div`
  padding: 0.75rem 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2rem minmax(0, auto) 1rem;
  align-items: center;
  gap: 0.25rem 0.75rem;
`;

interface IconDimensions {
  height: string;
  width: string;
}

interface IconCircleProps {
  icon: string;
  iconDimensions?: IconDimensions;
}
interface ComponentCircleProps {
  fill: string;
  iconDimensions?: IconDimensions;
}

const Circle = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #e7e9f0;
  border-radius: 50%;
`;

const IconCircle = styled(Circle)<IconCircleProps>`
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ iconDimensions }) =>
    iconDimensions
      ? `${iconDimensions.width} ${iconDimensions.height}`
      : `${DEFAULT_ICON_SIZE} ${DEFAULT_ICON_SIZE}`};
`;
export const ComponentCircle = styled(Circle)<ComponentCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    fill: ${({ fill }) => `${fill}`};
    stroke: transparent;
    --widthDimension: ${({ iconDimensions }) =>
      iconDimensions ? `${iconDimensions.width}` : `${DEFAULT_ICON_SIZE}`};
    --heightDimension: ${({ iconDimensions }) =>
      iconDimensions ? `${iconDimensions.height}` : `${DEFAULT_ICON_SIZE}`};
    width: var(--widthDimension);
    height: var(--heightDimension);
    margin: calc((100% - var(--widthDimension)) / 2)
      calc((100% - var(--heightDimension)) / 2);
  }
`;
const disabledProps = `
  width: 1.5rem;
  height: 1.5rem;
  justify-self: center;
`;

const DisabledIcon = styled.img`
  ${disabledProps}
`;

const DisabledComponent = styled.div<{ fill: string }>`
  .icon {
    ${disabledProps}
    fill: ${({ fill }) => `${fill}`};
    stroke: transparent;
  }
`;

const Header = styled.div`
  display: flex;
`;

const Subtitle = styled(Typography)`
  font-weight: 500;
  font-size: 0.75rem;
  margin-inline-start: 0.2rem;
`;

const Content = styled.div`
  grid-column: 2;
`;

interface GetIconProps {
  icon: string | FC<IconProps>;
  title: React.ReactNode;
  iconDimensions?: IconDimensions;
  disabled?: boolean;
  fillColor?: string;
}

interface EventDetailsSectionProps extends GetIconProps {
  subtitle?: ReactNode;
  info?: string;
  disableDivider?: boolean;
  e2eSelector?: string;
  children?: React.ReactNode;
  className?: string;
  withDivider?: boolean;
}

const SectionIcon: React.FC<GetIconProps> = ({
  icon,
  title,
  iconDimensions,
  disabled,
  fillColor,
}): JSX.Element => {
  if (typeof icon === "string") {
    return disabled ? (
      <DisabledIcon src={icon} alt={`disabled ${title} logo`} />
    ) : (
      <IconCircle icon={icon} iconDimensions={iconDimensions} />
    );
  }
  const fill = fillColor ?? palette.darkBlue[500];
  return disabled ? (
    <DisabledComponent role="img" title={`disabled ${title} logo`} fill={fill}>
      {icon({ className: "icon" })}
    </DisabledComponent>
  ) : (
    <ComponentCircle iconDimensions={iconDimensions} fill={fill}>
      {icon({ className: "icon" })}
    </ComponentCircle>
  );
};

const EventDetailsSection: React.FC<EventDetailsSectionProps> = ({
  icon,
  iconDimensions,
  title,
  subtitle,
  info,
  disabled = false,
  fillColor,
  children,
  e2eSelector,
  withDivider = true,
}) => (
  <>
    <Container>
      <Grid>
        <SectionIcon
          icon={icon}
          fillColor={fillColor}
          title={title}
          iconDimensions={iconDimensions}
          disabled={disabled}
        />
        <Header>
          {typeof title === "string" ? (
            <Typography color={muiColors.gray[500]} variant="h4">
              {title}
            </Typography>
          ) : (
            title
          )}
          <Subtitle>{subtitle}</Subtitle>
        </Header>
        {info && <Info data-info={info} />}
        <Content data-e2e-selector={e2eSelector}>{children}</Content>
      </Grid>
    </Container>
    {withDivider && <Divider />}
  </>
);

export default EventDetailsSection;
