import React from "react";
import { parseISO } from "date-fns";
import { palette } from "@komodorio/design-system";

import EventGroup, { EventBase } from "..";
import { agentDowntimeType } from "../commonEventGroup/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { withServices, mapAndSort } from "../groupEvents";
import { blueForUIText } from "../../../../Colors";
import KomoBotAgent from "../shapes/agent.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import AgentDowntimeEventDetails from "../../ProcessList/details/AgentDowntimeEventDetails";

import { AgentDowntimeRawKubernetesAgentInfo } from "./agentDowntimeTypes";
import { Downtime, calculateAgentDowntimes } from "./calculateAgentDowntimes";

export interface AgentDowntimeEvent extends EventBase {
  readonly name: string;
  readonly namespace?: string;
  readonly clusterName: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly duration: {
    milliseconds: number;
    readable: string;
  };
}

const toAgentDowntimeEvent = (
  e: Downtime,
  serviceId: string
): AgentDowntimeEvent => ({
  ...e,
  id: serviceId + e.clusterName + e.startTime,
  startTime: e.startTime,
  endTime: e.endTime,
  duration: e.duration,
  serviceId,
  eventTime: parseISO(e.startTime),
  type: agentDowntimeType,
  name: "Agent Downtime",
  namespace: "",
  clusterName: e.clusterName,
});

export default class AgentDowntimeEventGroup implements EventGroup {
  readonly events: AgentDowntimeEvent[];
  readonly type;
  readonly id;
  readonly summary;
  readonly startTime;
  readonly endTime;
  readonly details;
  readonly serviceId;
  readonly clusterName;
  readonly backgroundColor = palette.yellow[200];
  readonly fillColor = blueForUIText;
  readonly icon = KomoBotAgent;
  readonly status = "Closed";

  constructor(e: AgentDowntimeEvent) {
    this.events = [e];
    this.id = e.id;
    this.type = agentDowntimeType;
    this.summary = `The agent was not connected to cluster: ${e.clusterName}`;
    this.startTime = parseISO(e.startTime);
    this.endTime = parseISO(e.endTime);
    this.details = `${e.duration.readable} of agent downtime`;
    this.serviceId = e.serviceId;
    this.clusterName = e.clusterName;
  }

  renderEventDetails(): JSX.Element {
    return <AgentDowntimeEventDetails eventGroup={this} />;
  }
}

export const groupAgentDowntimeEvents = (
  events: AgentDowntimeRawKubernetesAgentInfo[] | undefined,
  serviceId: string
): AgentDowntimeEventGroup[] => {
  const calculatedAgentDowntimes = calculateAgentDowntimes(events);
  return mapAndSort(
    withServices(calculatedAgentDowntimes, serviceId),
    toAgentDowntimeEvent
  ).map((e) => new AgentDowntimeEventGroup(e));
};
