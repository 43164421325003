/* eslint-disable max-lines */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { parseISO } from "date-fns";
import { sortBy } from "lodash";
import styled from "styled-components";
import { AxiosInstance } from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  SetTimeWindow,
  Timeframe,
  TimeWindow,
} from "../../../shared/types/TimeWindow";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import EventGroup from "../EventGroup";
import IntersectionDetector from "../IntersectionDetector";
import { useTimezone } from "../../../shared/context/TimezoneProvider";
import useBatches from "../../../shared/hooks/useBatches";
import { useStringifiedStateInSearchParams } from "../../../shared/hooks/state";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { EventTableHeader } from "../enums";
import useKomodorServices from "../../../shared/hooks/useKomodorServices";
import {
  INVESTIGATED,
  PROCESS_LIST_SORT_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import LimitedBlurredEvents from "../../Freemium/LimitedBlurredEvents/LimitedBlurredEvents";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { ARGO_WORKFLOWS, RELIABILITY } from "../../routes/routes";
import {
  apiV1DeploysEventsSearchGetUrl,
  DeployEventsResponse,
  EventsApiApiV1DeploysEventsSearchGetRequest,
} from "../../../generated/resourcesApi";
import { DEPLOY_EVENTS_SEARCH } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { useResourcesApiClient } from "../../../shared/hooks/resources-api/client/apiClient";

import {
  Container,
  Table,
  Hidden,
  MoreEventsLinkText,
  MoreEventsPanelContainer,
  MoreEventsPanelText,
} from "./styles";
import { Row } from "./ListItem";
import SortTitle, { Direction } from "./SortTitle";
import EventDrawer from "./EventDrawer";

const BATCH_SIZE = 50;
interface ProcessListProps {
  highlightedEvents: string[];
  showServiceColumn: boolean;
  eventGroups: EventGroup[];
  className?: string;
  showMoreEventsPanel?: boolean;
  eventId: string | null;
  setEventId: (eventId: string | null) => void;
  defaultSortKey?: ProcessListSortKeys;
  setIntentId: (eventId: string | null) => void;
  loadNextPage?: () => void;
  timeWindow: TimeWindow;
  setTimeWindow: SetTimeWindow;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum ProcessListSortKeys {
  starttime = "starttime",
  endtime = "endtime",
}

interface MoreEventsPanelProps {
  onClick: () => void;
  children?: React.ReactNode;
}

const MoreEventsPanel: React.FC<MoreEventsPanelProps> = ({ onClick }) => {
  return (
    <MoreEventsPanelContainer>
      <MoreEventsPanelText>
        No other changes occurred during this time
      </MoreEventsPanelText>
      <MoreEventsLinkText onClick={onClick}>
        SEE EARLIER CHANGES
      </MoreEventsLinkText>
    </MoreEventsPanelContainer>
  );
};
const StyledTable = styled(Table)`
  th {
    position: unset;
    top: unset;
  }
`;

const fetchLatestDeployEventTime = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1DeploysEventsSearchGetRequest | null
): Promise<DeployEventsResponse | null> => {
  if (!params) {
    return null;
  }
  const { data } = await apiClient.get(
    apiV1DeploysEventsSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

const excludedPaths = [ARGO_WORKFLOWS, RELIABILITY];
const shouldShowHasMoreEventsPanelAccordingToURL = () => {
  return !excludedPaths.some((path) => window.location.pathname.includes(path));
};

export const ProcessList: React.FC<ProcessListProps> = memo(
  ({
    highlightedEvents,
    showServiceColumn,
    eventGroups,
    className,
    eventId,
    showMoreEventsPanel,
    setEventId,
    defaultSortKey = ProcessListSortKeys.endtime,
    setIntentId,
    loadNextPage,
    timeWindow: currentTimeWindow,
    setTimeWindow,
  }) => {
    const [eventGroupIntent, setEventGroupIntent] = useState<EventGroup>();
    const [, setInvestigated] =
      useStringifiedStateInSearchParams<boolean>(INVESTIGATED);
    const onEventGroupIntent = useCallback(
      (eventGroup: EventGroup) => {
        setEventGroupIntent(eventGroup);
        setIntentId(eventGroup.id);
      },
      [setIntentId]
    );

    const services = useKomodorServices().all;
    const [servicesMap, setServicesMap] = useState<Map<string, ServiceInfo>>();
    useEffect(() => {
      if (!servicesMap && services) {
        setServicesMap(new Map(services.map((s) => [s.id, s])));
      }
    }, [services, servicesMap]);

    const { timeZoneName } = useTimezone();
    const params: EventsApiApiV1DeploysEventsSearchGetRequest | null =
      useMemo(() => {
        return eventGroups.length && showMoreEventsPanel
          ? {
              toEpoch: eventGroups[eventGroups.length - 1].startTime
                .getTime()
                .toString(),
              serviceIds: [eventGroups[eventGroups.length - 1].serviceId],
              order: "DESC",
              limit: 1,
              fields: ["eventTime"],
            }
          : null;
      }, [eventGroups, showMoreEventsPanel]);

    const apiClient = useResourcesApiClient();
    const { data: latestDeploy } = useQuery(
      [DEPLOY_EVENTS_SEARCH, params],
      () => fetchLatestDeployEventTime(apiClient, params)
    );
    const latestDeployEventTime = useMemo(() => {
      if (!latestDeploy?.data || !latestDeploy?.data?.length) {
        return;
      }
      return parseISO(latestDeploy.data[0].eventTime);
    }, [latestDeploy]);

    const selectedEventGroup = useMemo(() => {
      if (!eventId) {
        return;
      }
      const result = eventGroups.find(
        (eg) => eg.id === eventId || eg.events.some((e) => e.id === eventId)
      );
      if (result?.sendEventWhenClicked) {
        dispatchEvent(AnalyticEvents.EventsView.ChangeEvent_Row_Clicked, {
          ResourceType: result.type,
        });
      }
      dispatchEvent(AnalyticEvents.ProcessList.Drawer_Open, {
        page: window.location.pathname,
        eventType: result?.type,
        eventId: result?.id,
      });

      return result;
    }, [eventGroups, eventId]);

    const highlightedEventsSet = useMemo(() => {
      return new Set(highlightedEvents);
    }, [highlightedEvents]);

    const [selectedSort, setSelectedSort] = useStringifiedStateInSearchParams<{
      key: ProcessListSortKeys;
      direction: Direction;
    }>(PROCESS_LIST_SORT_PARAM_KEY);

    useEffect(() => {
      if (!selectedSort) {
        setSelectedSort(
          { key: defaultSortKey, direction: Direction.down },
          true
        );
      }
    }, [defaultSortKey, selectedSort, setSelectedSort]);

    const sortedEvents = useMemo(
      () =>
        selectedSort
          ? sortBy(eventGroups, (eg) => {
              if (selectedSort.key === ProcessListSortKeys.endtime) {
                return selectedSort.direction * eg.endTime.getTime();
              } else {
                return selectedSort.direction * eg.startTime.getTime();
              }
            })
          : eventGroups,
      [eventGroups, selectedSort]
    );

    const handleSortClick = (sortKey: ProcessListSortKeys) => {
      if (!selectedSort) return;
      const wasActive = selectedSort.key === sortKey;
      setSelectedSort({
        key: sortKey,
        direction: wasActive ? -selectedSort.direction : Direction.down,
      });
    };

    const {
      limited: limitedEvents,
      resetBatches,
      loadMore,
    } = useBatches(BATCH_SIZE, sortedEvents, loadNextPage);

    const closeDrawer = useCallback(() => {
      setEventId(null);
      setInvestigated(null);
    }, [setEventId, setInvestigated]);

    const eventDetailsIntent = useMemo(() => {
      return eventGroupIntent?.renderEventDetails?.(closeDrawer);
    }, [closeDrawer, eventGroupIntent]);

    const eventDetails = useMemo(() => {
      if (eventGroupIntent === selectedEventGroup) return eventDetailsIntent;
      return selectedEventGroup?.renderEventDetails?.(closeDrawer);
    }, [closeDrawer, eventDetailsIntent, eventGroupIntent, selectedEventGroup]);

    const onDrawerClose = useCallback(() => {
      dispatchEvent(AnalyticEvents.ProcessList.Drawer_Close, {
        page: window.location.pathname,
        eventType: selectedEventGroup?.type,
        eventId: selectedEventGroup?.id,
      });
      setEventId(null);
      setInvestigated(null);
    }, [setEventId, selectedEventGroup, setInvestigated]);

    return (
      <Container
        className={className}
        aria-label={AriaLabels.ProcessList.Table}
      >
        <IntersectionDetector onShow={resetBatches} />
        <StyledTable
          onMouseLeave={() => setIntentId(null)}
          data-e2e-selector="process-list-table"
        >
          <thead>
            <tr>
              <th>{EventTableHeader.EventType}</th>
              <th>{EventTableHeader.Summary}</th>
              <th>
                <SortTitle
                  title={`Start time (${timeZoneName})`}
                  onClick={() => handleSortClick(ProcessListSortKeys.starttime)}
                  direction={selectedSort?.direction ?? Direction.down}
                  active={selectedSort?.key === ProcessListSortKeys.starttime}
                />
              </th>
              <th>
                <SortTitle
                  title="Last updated time"
                  onClick={() => handleSortClick(ProcessListSortKeys.endtime)}
                  direction={selectedSort?.direction ?? Direction.down}
                  active={selectedSort?.key === ProcessListSortKeys.endtime}
                />
              </th>
              <th>{EventTableHeader.Details}</th>
              {showServiceColumn && <th>{EventTableHeader.Service}</th>}
              <th>{EventTableHeader.Status}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <LimitedBlurredEvents />
            {limitedEvents.map((eventGroup) => (
              <Row
                key={eventGroup.id}
                eventGroup={eventGroup}
                service={servicesMap?.get(eventGroup.serviceId)}
                timeWindow={currentTimeWindow}
                onClick={eventGroup.renderEventDetails && setEventId}
                onClickIntent={onEventGroupIntent}
                showServiceColumn={showServiceColumn}
                highlighted={highlightedEventsSet.has(eventGroup.id)}
                selected={eventGroup.id === selectedEventGroup?.id}
              />
            ))}
          </tbody>
        </StyledTable>
        <IntersectionDetector onShow={loadMore} />
        {latestDeployEventTime &&
        shouldShowHasMoreEventsPanelAccordingToURL() ? (
          <MoreEventsPanel
            onClick={() => {
              dispatchEvent(AnalyticEvents.ProcessList.ShowMoreEvents_Click);
              setTimeWindow({
                start: latestDeployEventTime,
                end: currentTimeWindow.end,
                timeframe: Timeframe.Custom,
              });
            }}
          >
            Show events since last change
          </MoreEventsPanel>
        ) : null}
        <EventDrawer
          open={!!selectedEventGroup}
          event={selectedEventGroup}
          onClose={onDrawerClose}
          ariaLabel={AriaLabels.ProcessList.EventDrawer}
        >
          <Hidden>{eventDetailsIntent}</Hidden>
          {eventDetails}
        </EventDrawer>
      </Container>
    );
  }
);
