import NodeStatus from "./NodeStatus";

class Node {
  private name: string;
  private status: NodeStatus;

  constructor(name: string, status: NodeStatus) {
    this.name = name;
    this.status = status;
  }

  getName(): string {
    return this.name;
  }

  getStatus(): string {
    return this.status.get();
  }
}

export default Node;
