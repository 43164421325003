import React, { useMemo } from "react";

import EventDetailsSection from "../components/EventDetailsSection";
import DetailsList from "../components/DetailsList";
import infoIcon from "../../assets/details.svg";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LaunchDarklyEvent } from "../../../EventGroup/LaunchDarkly";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";

export interface LaunchDarklyInfoProps {
  event: LaunchDarklyEvent;
}

const iconDimentions = { height: "50%", width: "50%" };

const useInfo = (event: LaunchDarklyEvent) => {
  const { format } = useDateFormatter();
  return useMemo(() => {
    const info = [];
    if (event.member) {
      info.push({
        label: "Change owner",
        value: event.member || "",
      });
    }

    if (event.eventTime) {
      info.push({
        label: "Change time",
        value: format(event.eventTime),
      });
    }
    return info;
  }, [format, event]);
};

export const LaunchDarklyInfo: React.FC<LaunchDarklyInfoProps> = ({
  event,
}) => {
  const info = useInfo(event);
  if (!info.length) {
    return null;
  }
  return (
    <EventDetailsSection
      title="Info"
      icon={infoIcon}
      iconDimensions={iconDimentions}
    >
      <DetailsList details={info} rows={2} />
    </EventDetailsSection>
  );
};
