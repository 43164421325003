import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { CombinedError, UseQueryArgs, UseQueryResponse } from "urql";

export function useQueryRefreshWithVariables<Data, Variables>(
  useOriginalQuery: (
    args: Omit<UseQueryArgs<Variables>, "query">
  ) => UseQueryResponse<Data, Variables>,
  variables: Variables | null
): [Data | null, () => void, boolean, CombinedError | undefined] {
  const context = useMemo(() => ({ variables }), [variables]);
  const [{ data, fetching, operation, error }, refresh] = useOriginalQuery({
    variables: variables ?? undefined,
    context: context,
    pause: !variables,
  });

  const refreshFromNetwork = useCallback(() => {
    if (!variables) return;
    refresh({ requestPolicy: "network-only" });
  }, [refresh, variables]);

  return [
    data &&
    variables &&
    !fetching &&
    isEqual(operation?.context.variables, variables)
      ? data
      : null,
    refreshFromNetwork,
    fetching,
    error,
  ];
}

function useQueryWithVariables<Data, Variables>(
  useOriginalQuery: (
    args: Omit<UseQueryArgs<Variables>, "query">
  ) => UseQueryResponse<Data, Variables>,
  variables: Variables | null
): Data | null {
  const [data] = useQueryRefreshWithVariables(useOriginalQuery, variables);
  return data;
}

export default useQueryWithVariables;
