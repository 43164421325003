import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { K8S_ADDONS_CERT_MANAGER } from "@/components/routes/routes";
import { CertManager } from "@/components/k8sAddons/addons/CertManager/CertManager";

const PageContainer = styled.div`
  margin: 0 24px 8px 24px;
`;

export const K8sAddonsView: React.FC = () => {
  return (
    <PageContainer>
      <Routes>
        <Route
          path={`${K8S_ADDONS_CERT_MANAGER}/*`}
          element={<CertManager />}
        />
      </Routes>
    </PageContainer>
  );
};
