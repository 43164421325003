import { isEqual } from "lodash";
import React from "react";
import styled from "styled-components";

import { blueBrand } from "../../../../Colors";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { H2 } from "../../../common/typography";
import Selected from "../../../../shared/components/FilterBar/Interfaces/Selected";
import TrashIcon from "../../../common/SVGs/trash.svg?react";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";

import FilterLabel from "./FilterLabel";
import ISavedFilter from "./SavedFilter";

const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

const FiltersGrid = styled.div`
  display: grid;
  gap: 0.5rem;
  justify-items: start;
`;

const DeleteButton = styled(FilterLabel)`
  visibility: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0.5rem;
  margin-inline-start: 2px;
`;

const SavedFilter = styled(FilterLabel)`
  cursor: pointer;
`;

const FilterContainer = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  ${FilterLabel} {
    color: ${({ active }) => (active ? "white" : undefined)};
    background-color: ${({ active }) => (active ? blueBrand : undefined)};
  }
  &:hover > ${DeleteButton} {
    visibility: visible;
  }
`;

const EventsViewSavedFilters: React.FC<{
  savedFilters: ISavedFilter[];
  activeFilter: Selected;
  applyFilter: (filter: Selected) => void;
  deleteFilter: (name: string) => void;
}> = ({ savedFilters, activeFilter, applyFilter, deleteFilter }) => (
  <Container>
    <H2>Saved Filters</H2>
    <FiltersGrid>
      {savedFilters.map(({ id, name, selected }) => (
        <FilterContainer key={id} active={isEqual(activeFilter, selected)}>
          <SavedFilter
            onClick={() => {
              applyFilter(selected);
              dispatchEvent(AnalyticEvents.EventsView.SavedFilter_Apply, {
                filterId: id,
                filterName: name,
                filterData: selected,
              });
            }}
          >
            {name}
          </SavedFilter>
          <DeleteButton
            onClick={() => {
              if (window.confirm("Are you sure?")) {
                deleteFilter(id);
              }
            }}
          >
            <TrashIcon height="1rem" />
          </DeleteButton>
        </FilterContainer>
      ))}
    </FiltersGrid>
  </Container>
);

export default EventsViewSavedFilters;
