import React, { useMemo } from "react";

import { useGetNodesCounts } from "@/shared/hooks/accounts-service/client/customers-pricing/useGetNodesCounts";
import { SettingsSectionLayout } from "@/components/Settings/SettingsSectionLayout";
import { ClusterTable } from "@/components/Settings/General/NodesCount/ClustersTable";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const NodeCount: React.FC = () => {
  const oneYearBack = useMemo(() => {
    const currentDate = new Date();
    return new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth()
    ).toISOString();
  }, []);

  const {
    data: nodesCounts,
    isFetching,
    isError,
  } = useGetNodesCounts({
    collectedFrom: String(oneYearBack.valueOf()),
  });

  const { showClusterTable } = useOverridableFlags();
  if (!showClusterTable) {
    return;
  }
  return (
    <SettingsSectionLayout title={"Nodes Count"}>
      <ClusterTable
        data={nodesCounts || []}
        isFetching={isFetching}
        isError={isError}
      />
    </SettingsSectionLayout>
  );
};
