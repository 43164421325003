import React from "react";
import { Scale16 } from "@komodorio/design-system/icons";

import { NODE_AUTOSCALERS_DEMO_ROUTE } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

const DISPLAY_NAME = "Autoscalers";

export const NodeAutoscalersDemo: React.FC = () => {
  return (
    <AppBarLink
      text={DISPLAY_NAME}
      route={NODE_AUTOSCALERS_DEMO_ROUTE}
      icon={<Scale16 width={20} height={20} />}
      tag="beta"
    />
  );
};
