import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { blackGreyText, blueBrand } from "../../Colors";
import Button from "../common/controls/Button";
import { H2Poppins, Text } from "../common/typography";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../shared/hooks/analytics";
import { INSPECTION_USER_PERMISSIONS_ERROR } from "../../shared/hooks/ATM/constants/atmResources";

import RefreshIcon from "./assets/refreshTwoArrows.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceListResponse } from "./InspectionViews/common";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const resourceListAgentHasError = (
  resourceList: ResourceListResponse,
  failureMessage: string,
  retries: number,
  maxRetries: number
): boolean => {
  if (resourceList.rows.length) return false;
  return !(!failureMessage && retries <= maxRetries);
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Content = styled.div`
  height: 10rem;
  text-align: center;
  color: ${blackGreyText};
`;

const StyledText = styled(Text)`
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  cursor: pointer;
  background-color: ${blueBrand};
  display: inline-flex;
  align-items: center;

  > svg {
    margin-right: 0.5rem;
    vertical-align: bottom;
  }
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum InspectionPageErrorTitle {
  InsufficientPermissions = "Insufficient Permissions",
  General = "Something went wrong...",
}

const ResourceListErrorState: React.FC<{
  errorMessage: string;
  resourceName: string;
  internalErrorMessage?: string;
  refreshCallback?: () => void;
}> = ({
  errorMessage,
  resourceName,
  internalErrorMessage,
  refreshCallback,
}) => {
  useEffect(() => {
    dispatchEvent(AnalyticEvents.Inspection.Inspection_resource_list_error, {
      resourceName: resourceName,
      message: internalErrorMessage || errorMessage,
    });
  }, [resourceName, errorMessage, internalErrorMessage]);
  const [isPermissionError, setIsPermissionError] = useState(false);
  useEffect(() => {
    if (errorMessage === INSPECTION_USER_PERMISSIONS_ERROR) {
      setIsPermissionError(true);
    }
  }, [errorMessage]);
  return (
    <Container>
      <Content>
        {refreshCallback && (
          <div data-e2e-selector={"inspectionError"}>
            {isPermissionError ? (
              <H2Poppins>
                {InspectionPageErrorTitle.InsufficientPermissions}
              </H2Poppins>
            ) : (
              <H2Poppins>{InspectionPageErrorTitle.General}</H2Poppins>
            )}
            <br />
          </div>
        )}
        <StyledText>{errorMessage}</StyledText>
        {refreshCallback && !isPermissionError && (
          <>
            <br />
            <br />
            <StyledButton onClick={refreshCallback}>
              <RefreshIcon />
              Refresh page
            </StyledButton>
          </>
        )}
      </Content>
    </Container>
  );
};

export default ResourceListErrorState;
