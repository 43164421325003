import React, { useMemo } from "react";
import sortBy from "lodash/sortBy";
import remove from "lodash/remove";
import styled from "styled-components";
import { IntegrationType } from "komodor-types";

import useInstallationSubscription, {
  Installation,
} from "./useInstallationSubscription";
import InstalledIntegrationTile from "./InstalledIntegrationTile";

const Container = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, 43rem);
  grid-auto-rows: 1fr;
`;

const keepOneSlackInstallation = (installations: Installation[]) => {
  const slackInstallations = remove(
    installations,
    (i) => i.integration === IntegrationType.SLACK
  );
  if (slackInstallations.length) {
    installations.push(slackInstallations[0]);
  }
};

const InstalledIntegrations: React.FC = () => {
  const { data: installations, error: installationSubscriptionError } =
    useInstallationSubscription();
  const sortedInstallations = useMemo(() => {
    if (!installations) {
      return [];
    }

    const filteredInstallations = installations.filter((i) => i.showInWeb);
    keepOneSlackInstallation(filteredInstallations);
    return sortBy(
      filteredInstallations,
      (i: Installation) => -new Date(i.createdAt).getTime()
    );
  }, [installations]);

  if (installationSubscriptionError) {
    return (
      <div>
        Error loading integrations: {installationSubscriptionError.message}
      </div>
    );
  }
  if (!sortedInstallations) {
    return <div>Unknown error occurred. Please refresh the page.</div>;
  }
  if (sortedInstallations.length === 0) {
    return (
      <div>You don't have any integrations installed on your account.</div>
    );
  }

  return (
    <Container>
      {sortedInstallations.map((installation: Installation) => (
        <InstalledIntegrationTile
          key={installation.id}
          installation={installation}
        />
      ))}
    </Container>
  );
};

export default InstalledIntegrations;
