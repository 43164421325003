import React from "react";
import Modal from "react-modal";

import { DarkenOverlay } from "../../../../components/common/Overlay";
import ModalComponent from "../../../common/Modal";
import StepsModal from "../../../monitorsView/common/ModalComponent";
import { shadowCenteredModalStyles } from "../../../common/Modal/modal.styles";
import { WorkflowConfigModalStyle } from "../../../monitorsView/monitorsConfiguration/common/styles";

const StepsModalStyle = {
  ...WorkflowConfigModalStyle,
  ...{ top: "50%", overflow: "visible" },
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum ModalType {
  Steps,
  Empty,
}

const ModalFactory: React.FC<{
  modalType?: ModalType;
  closeModal: () => void;
  isShow: boolean;
  modalStyle?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ modalType, closeModal, isShow, modalStyle, children }) => {
  switch (modalType) {
    case ModalType.Steps:
      return (
        <>
          <DarkenOverlay open={isShow} onClick={closeModal} />
          <StepsModal
            isOpen={isShow}
            title={""}
            customStyle={StepsModalStyle}
            handleCloseModal={closeModal}
          >
            {children}
          </StepsModal>
        </>
      );
    case ModalType.Empty:
      return (
        <>
          <DarkenOverlay open={isShow} onClick={closeModal} />
          <Modal
            isOpen={isShow}
            style={shadowCenteredModalStyles}
            onRequestClose={closeModal}
          >
            {children}
          </Modal>
        </>
      );
    default:
      return (
        <>
          <DarkenOverlay open={isShow} onClick={closeModal} />
          <ModalComponent
            isOpen={isShow}
            handleCloseModal={closeModal}
            title={""}
            customStyle={modalStyle}
          >
            {children}
          </ModalComponent>
        </>
      );
  }
};

export default ModalFactory;
