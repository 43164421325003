import React, { useMemo } from "react";
import { OptionType } from "@komodorio/design-system/deprecated";
import Button from "@mui/material/Button";

import {
  ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY,
  ARGO_WORKFLOW_STATUS_PARAM_KEY,
  ARGO_WORKFLOW_TYPE_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { ArgoWorkflowTableData } from "../types";
import { useStateInMultipleSearchParams } from "../../../shared/hooks/state";

import { FilterContainer } from "./styles";
import { buildUniqueValues } from "./uniqueFilters";
import { ArgoTableFilter } from "./ArgoTableFilter";
import { ArgoTableClusterFilter } from "./ArgoTableClusterFilter";
import { ArgoSearchFilter } from "./ArgoSearchFilter";

export const ArgoFilters: React.FC<{
  resourceList: ArgoWorkflowTableData[];
  selectedCluster?: string;
  setCluster: (cluster: string) => void;
  clustersOptions: OptionType<string>[];
}> = ({ selectedCluster, setCluster, resourceList, clustersOptions }) => {
  const { allTypes, allStatuses } = useMemo(
    () => buildUniqueValues(resourceList),
    [resourceList]
  );

  const { searchParams, clearSearchParams } = useStateInMultipleSearchParams([
    ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY,
    ARGO_WORKFLOW_STATUS_PARAM_KEY,
    ARGO_WORKFLOW_TYPE_PARAM_KEY,
  ]);

  return (
    <FilterContainer>
      <ArgoSearchFilter />
      <ArgoTableClusterFilter
        cluster={selectedCluster}
        options={clustersOptions}
        setCluster={setCluster}
      />
      <ArgoTableFilter
        filterName={ARGO_WORKFLOW_STATUS_PARAM_KEY}
        filterTitle={"Status"}
        items={allStatuses}
      />
      <ArgoTableFilter
        filterName={ARGO_WORKFLOW_TYPE_PARAM_KEY}
        filterTitle={"Type"}
        items={allTypes}
      />
      <Button
        sx={{ alignSelf: "flex-end" }}
        disabled={Object.keys(searchParams).length === 0}
        variant="text"
        onClick={() => {
          clearSearchParams();
        }}
      >
        Reset filters
      </Button>
    </FilterContainer>
  );
};
