import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import useUserMetadata from "../../shared/hooks/useUserMetadata/useUserMetadata";

const useTiersVisible = (): boolean => {
  const { accountPlan } = useUserMetadata();
  const { freemiumFlow } = useOverridableFlags();
  if (accountPlan === "") return false;
  if (!freemiumFlow) return false;
  return true;
};

export default useTiersVisible;
