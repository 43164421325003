import React from "react";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Lock16 } from "../../../../icons";
import { MenuItemProps } from "@mui/material";
import { lightMuiTooltipStyle } from "../../../../styles/styles";
import { muiTheme } from "../../../../themes";

const NO_PERMISSIONS_MESSAGE = "Access denied due to lack of permissions";

const LINK_TAB_STYLE = {
  alignItems: "flex-start",
  marginLeft: "8px",
  fontSize: "14px",
  minHeight: "32px",
  padding: "4px 16px",
  letterSpacing: "0.4px",
  fontWeight: 400,
  // Put opacity in "&&" to override the default opacity of the disabled Tab component
  "&&": {
    opacity: 1,
  },
};

interface LinkTabProps {
  linkComponent: React.ElementType;
  title?: string;
  route?: string;
}

export const LinkTab = (props: LinkTabProps) => {
  const { linkComponent, ...rest } = props;
  return (
    <Tab
      component={linkComponent}
      to={rest.route}
      sx={LINK_TAB_STYLE}
      label={rest.title}
      aria-label={rest.title}
      {...rest}
    />
  );
};

interface LockedTabProps {
  title: string;
  route: string;
}

export const LockedTab = ({ title, route }: LockedTabProps) => {
  const Label = (
    <Stack direction="row" alignItems="center" spacing={1} aria-label={title}>
      <Typography variant="body2" color={muiTheme.palette.text.secondary}>
        {title}
      </Typography>
      <Lock16 color={muiTheme.palette.action.active} />
    </Stack>
  );

  return (
    <Tooltip
      title={<Typography variant="body2">{NO_PERMISSIONS_MESSAGE}</Typography>}
      placement="left-end"
      componentsProps={lightMuiTooltipStyle}
      slotProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: 170,
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
              {
                marginRight: "-10px",
              },
          },
        },
      }}
    >
      <Stack>
        <Tab
          disabled
          key={route}
          label={Label}
          value={route}
          sx={LINK_TAB_STYLE}
        />
      </Stack>
    </Tooltip>
  );
};

export const HeaderTab = (props: MenuItemProps) => {
  return (
    <Tab
      sx={{
        alignItems: "flex-start",
        color: muiTheme.palette.text.secondary,
        pointerEvents: "none",
        fontSize: "12px",
        textTransform: "uppercase",
        letterSpacing: "1.5px",
      }}
      {...props}
    />
  );
};
