import React from "react";
import Drawer from "@mui/material/Drawer";
import { FormProvider, useForm } from "react-hook-form";

import { ReliabilityPolicyDrawerProps } from "../../violations/ViolationsDrawer/violationDrawerTypes";

import { PolicyDrawerContent } from "./PolicyDrawerContent";

import { InputFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { PolicyDrawerContextProvider } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/PolicyDrawerContext";
import { useHasWritePermission } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useHasWritePermission";

const TRANSITION_DURATION = 250;

type Props = ReliabilityPolicyDrawerProps & {
  open: boolean;
  onClose: () => void;
};

export const ReliabilityPolicyDrawer: React.FC<Props> = ({
  policyId,
  open,
  onClose,
}) => {
  const formData = useForm<InputFields>({
    mode: "onChange",
  });

  const hasWritePermission = useHasWritePermission(policyId);

  return (
    <Drawer
      anchor="right"
      open={open}
      transitionDuration={TRANSITION_DURATION}
      PaperProps={{
        sx: { width: "60%", minWidth: "900px" },
      }}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <PolicyDrawerContextProvider
        onClose={onClose}
        policyId={policyId}
        isReadOnlyMode={!hasWritePermission}
      >
        <FormProvider {...formData}>
          <PolicyDrawerContent />
        </FormProvider>
      </PolicyDrawerContextProvider>
    </Drawer>
  );
};
