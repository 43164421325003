import styled, { css } from "styled-components";

import { disabledBlue } from "../../../Colors";

export const inputStyle = css`
  display: block;
  appearance: none;
  color: #3d4246;
  border: 1px solid #bcc0c8;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #fff;
`;

export default styled.input`
  ${inputStyle}

  ::placeholder {
    color: #9e9fa3;
  }

  :focus {
    outline: none;
    border-color: #007aff;
  }

  :disabled {
    background-color: rgba(0, 122, 255, 0.3);
    color: ${disabledBlue};
    cursor: not-allowed;
  }
`;
