import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { Status, StatusColor } from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";
import Close from "@mui/icons-material/Close";

import K8sConfig from "../../../EventGroup/deployEvent/K8sConfig";
import EventDetailsSection from "../components/EventDetailsSection";
import configChangesIcon from "../../assets/config-changes.svg";
import { ObjectDiffAsYaml } from "../../../ObjectDiff";

import DiffEntry from "./DiffEntry";

const PREVIEW_LIMIT = 8;

const Entry = styled.div`
  display: grid;
  gap: 0.5rem;
  justify-content: start;
  justify-items: start;

  &:nth-child(n + 2) {
    margin-block-start: 1.5rem;
  }
`;

const DiffEntriesWidth = styled.div`
  width: 52vw;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const getConfigStatus = (config: K8sConfig) =>
  config.action === "Delete"
    ? "Deleted"
    : config.action === "Create"
    ? "New"
    : "Modified";

const statusVariant: { [key: string]: StatusColor } = {
  New: "success",
  Modified: "neutral",
  Deleted: "error",
};

const EntryTitle: React.FC<{
  name: string;
  type: string;
  status: string;
  size: "small" | "large";
}> = ({ name, type, status, size }) => {
  const variant = size === "small" ? "h5" : "h3";
  return (
    <TitleContainer>
      <Typography variant={variant}>{name}</Typography>
      <Typography variant={variant} sx={{ color: muiColors.gray["500"] }}>
        ({type})
      </Typography>
      <Status size={size} color={statusVariant[status]} label={status} />
    </TitleContainer>
  );
};

const ConfigDiffModal: React.FC<{
  config: K8sConfig | undefined;
  onClose: () => void;
}> = ({ config, onClose }) => {
  if (!config) {
    return null;
  }

  const configStatus = getConfigStatus(config);
  const isNewData = !isEmpty(config.newData);
  const isOldData = !isEmpty(config.oldData);

  return (
    <Dialog open onClose={onClose} maxWidth={false}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <EntryTitle
          name={config.name}
          type={config.type}
          status={configStatus}
          size="large"
        />
        <IconButton onClick={onClose} sx={{ color: muiColors.gray[400] }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ObjectDiffAsYaml
          oldObj={isOldData ? config.oldData : undefined}
          newObj={isNewData ? config.newData : undefined}
          overridingProps={{ splitView: isOldData && isNewData }}
        />
      </DialogContent>
    </Dialog>
  );
};

export const ConfigEntry: React.FC<{
  config: K8sConfig;
  onViewClick: () => void;
}> = ({ config, onViewClick }) => {
  const configStatus = getConfigStatus(config);

  return (
    <Entry>
      <EntryTitle
        name={config.name}
        type={config.type}
        status={configStatus}
        size="small"
      />
      <DiffEntriesWidth>
        {config.changes.slice(0, PREVIEW_LIMIT).map((c) => (
          <DiffEntry key={c.name} change={c} />
        ))}
      </DiffEntriesWidth>
      <Link onClick={onViewClick} sx={{ cursor: "pointer" }}>
        {config.changes.length > PREVIEW_LIMIT
          ? "View all changes on diff"
          : "View diff"}
      </Link>
    </Entry>
  );
};

interface ConfigChangesSectionProps {
  k8sConfig: K8sConfig[] | undefined;
}
const ConfigChangesSection: React.FC<ConfigChangesSectionProps> = ({
  k8sConfig,
}) => {
  const [configToView, setConfigToView] = useState<K8sConfig>();
  return (
    <EventDetailsSection icon={configChangesIcon} title="Config changes">
      {k8sConfig?.map((config) => (
        <ConfigEntry
          key={`${config.type}.${config.name}`}
          config={config}
          onViewClick={() => setConfigToView(config)}
        />
      ))}
      <ConfigDiffModal
        config={configToView}
        onClose={() => setConfigToView(undefined)}
      />
    </EventDetailsSection>
  );
};
export default ConfigChangesSection;
