import React from "react";
import useClipboard from "react-use-clipboard";
import { muiTheme, muiColors, muiPalette } from "@komodorio/design-system";
import { Copy16 } from "@komodorio/design-system/icons";
import Button from "@mui/material/Button";
import styled from "styled-components";

const CopyContainer = styled.div<{
  width?: string;
  height?: string;
}>`
  display: flex;
  gap: 16px;
  background: white;
  border: 1px solid ${muiPalette.divider};
  border-radius: 4px;
  max-width: ${({ width }) => width ?? "100%"};
  height: ${({ height }) => height ?? "auto"};
  background-color: ${muiColors.gray["50"]};
  padding: 8px;
`;

const CopyText = styled.div`
  overflow: auto;
  width: 92%;
  display: flex;
  align-items: center;
`;

const CopyButton = styled(Button)`
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: space-evenly;
  flex-direction: column;
  margin: auto 0;
  min-width: 5rem;
  position: relative;
`;

const CopyButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPre = styled.pre({
  fontSize: muiTheme.typography.body2.fontSize,
  lineHeight: muiTheme.typography.body2.lineHeight,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  textAlign: "left",
  margin: 0,
});

const CopyCode: React.FC<{
  className?: string;
  height?: string;
  width?: string;
  value: string;
  onClick?: () => void;
}> = ({ value, className, width, height, onClick }) => {
  const [copied, copy] = useClipboard(value, { successDuration: 3000 });

  return (
    <CopyContainer
      className={className}
      height={height}
      width={width}
      data-e2e-selector="copy-container"
    >
      <CopyText>
        <StyledPre>{value}</StyledPre>
      </CopyText>
      <CopyButtonWrapper
        onClick={() => {
          if (!onClick) return;
          onClick();
        }}
      >
        <CopyButton
          variant="contained"
          onClick={copy}
          sx={{ padding: "8px 24px", minWidth: "unset" }}
        >
          <Copy16 />
          {copied ? "Copied!" : "Copy"}
        </CopyButton>
      </CopyButtonWrapper>
    </CopyContainer>
  );
};

export default CopyCode;
