import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";

import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { useUpdateUser } from "../../../shared/hooks/auth-service/client/users/useUpdateUser";
import { User } from "../../../generated/auth";

const DeleteUserModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  user: User;
  refreshUsers: () => void;
}> = ({ open, handleClose, user, refreshUsers }) => {
  const { mutateAsync: deleteUser, isLoading: deleteLoading } = useUpdateUser();

  const handleDelete = async () => {
    const result = await deleteUser({
      id: user.id,
      updateUserRequest: {
        deletedAt: new Date().toISOString(),
      },
    });
    dispatchEvent(AnalyticEvents.Settings.DeleteUser, {
      email: result.email,
    });
    refreshUsers();
    handleClose();
  };

  return (
    <Modal isOpen={open} onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Delete User</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Are you sure you want to delete this user?
          <br />
          <br />
          Name: <b>{user.displayName}</b>
          <br />
          Email: <b>{user.email}</b>
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          disabled={deleteLoading}
        >
          {deleteLoading ? "Deleting..." : "Delete"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteUserModal;
