import React from "react";
import { Button } from "@komodorio/design-system/deprecated";
import { Download16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

import AuditObject from "../details/AuditObject";

import { generateCsv } from "./csvUtils";

const Container = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: right;
`;

const renderCsvData = (data: AuditObject[]): Record<string, unknown>[] => {
  return data.map((d) => d.renderJson());
};

const ExportCSVButton: React.FC<{ data: AuditObject[] }> = ({ data }) => {
  return (
    <Container>
      <Button
        variant={"default"}
        size={"large"}
        icon={Download16}
        onClick={() => {
          generateCsv(renderCsvData(data), "komodor_audit");
        }}
      >
        Export to CSV
      </Button>
    </Container>
  );
};

export default ExportCSVButton;
