import { useMemo } from "react";
import { groupBy, sortBy } from "lodash";

import { WorkflowPod } from "@/generated/addonsApi";
import { LineGroup } from "@/components/common/EventsChart/TimelineChart/types";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { groupGroupedPodEvents } from "@/components/common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { groupWorkflowIssuesEvents } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import {
  NodeEventsResponseDataInner,
  PodGroups,
} from "@/generated/resourcesApi";
import EventGroup from "@/components/common/EventGroup";
import { MonitorData } from "@/generated/monitorsApi";
import { groupNodeChangeEvents } from "@/components/common/EventGroup/nodeEvent/NodeChangeGroup";

const DEFAULT_SERVICE_ID = "default";

export const useWorkflowChartSwimlanes = ({
  nodeTerminations,
  nodeIssues,
  workflowPods,
}: {
  nodeTerminations: NodeEventsResponseDataInner[] | undefined;
  nodeIssues: MonitorData[] | undefined;
  workflowPods: WorkflowPod[] | undefined;
}): LineGroup[] => {
  const nodeTerminationsEventGroups = groupNodeChangeEvents(
    nodeTerminations?.flatMap((e) => e) ?? [],
    DEFAULT_SERVICE_ID
  );
  const nodeIssuesEventGroups = groupWorkflowIssuesEvents(
    nodeIssues?.flatMap((e) => e) ?? [],
    ""
  );
  const infraSwimlanes = useMemo(() => {
    const events = [...nodeTerminationsEventGroups, ...nodeIssuesEventGroups];
    return events.length > 0
      ? [
          {
            label: {
              prefix: "Infrastructure",
              name: "",
            },
            events,
          },
        ]
      : [];
  }, [nodeIssuesEventGroups, nodeTerminationsEventGroups]);

  const tasksSwimlanes = useMemo(() => {
    return Object.entries(
      groupBy(
        sortBy(workflowPods ?? [], (pod) => pod.startTime),
        (pod) => pod.taskId
      )
    )
      .map(
        ([taskId, pods]) =>
          pods.map((pod) => {
            const komodorUid = buildKomodorUid({
              clusterName: pod.cluster,
              namespace: pod.namespace,
              kind: "Pod",
              resourceName: pod.name,
            });
            const events = [
              ...groupGroupedPodEvents(
                [{ komodorUid, groups: pod.phases }] as PodGroups[],
                ""
              ),
              ...groupWorkflowIssuesEvents(
                (pod.issues ?? []) as MonitorData[],
                ""
              ),
            ] as EventGroup[];

            return {
              label:
                pods.length > 1
                  ? [
                      { prefix: "Task ID", name: taskId },
                      { prefix: "Pod", name: pod.name },
                    ]
                  : { prefix: "Task ID", name: taskId },
              events,
            };
          }) ?? []
      )
      .flat();
  }, [workflowPods]);

  return [...infraSwimlanes, ...tasksSwimlanes];
};
