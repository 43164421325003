import React, { useState } from "react";
import {
  Divider,
  Tabs,
  TabPanel,
  Typography,
  TabType,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { ServiceIdentifierType } from "komodor-types";

import { ServicesTable } from "../ServicesTable/ServicesTable";
import { useCreationPageContext } from "../context/CreationPageContext";

import { AppViewScopeHandler } from "./AppViewScopeHandler/AppViewScopeHandler";

const Container = styled.div`
  margin-top: 4rem;
`;
const TypographyWithMargin = styled(Typography)`
  margin: 1rem 0;
`;
const StyledDivider = styled(Divider)`
  margin-top: -1px;
`;
const PaddedContainer = styled.div`
  padding: 1rem 0;
`;

enum AppViewScopingTypes {
  DYNAMIC,
  STATIC,
}

const tabs: TabType[] = [{ label: "Dynamic Scope" }, { label: "Static Scope" }];
export const CreationPageScopingTabs: React.FC = () => {
  const { setAppViewType, setErrorState } = useCreationPageContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(
    AppViewScopingTypes.DYNAMIC
  );
  const onChange = (value: number) => {
    setCurrentTabIndex(value);
    setErrorState({ servicesSelectedError: undefined });

    if (value === AppViewScopingTypes.STATIC) {
      setAppViewType(ServiceIdentifierType.SERVICE_ID);
    } else {
      setAppViewType(undefined);
    }
  };

  return (
    <Container>
      <Typography variant={"headline"} size={"small"}>
        Select Workspace Scope
      </Typography>
      <TypographyWithMargin size={"medium"}>
        Define what services (Deployments, DaemonSets, StatefulSets, Jobs and
        CronJobs) compose your workspace. Komodor will automatically detect and
        add additional related resources and provide you full overview for your
        workspace.
      </TypographyWithMargin>
      <Tabs tabs={tabs} value={currentTabIndex} onChange={onChange} />
      <StyledDivider />
      <PaddedContainer />
      <TabPanel value={currentTabIndex} index={AppViewScopingTypes.DYNAMIC}>
        <AppViewScopeHandler />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={AppViewScopingTypes.STATIC}>
        <ServicesTable />
      </TabPanel>
    </Container>
  );
};
