import React from "react";

import { PageContainer } from "../../../ReliabilityStyles";
import { FiltersSection } from "../../FiltersSection/FiltersSection";

import { ViolationsBreakdown } from "./ViolationsBreakdown/ViolationsBreakdown";

import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { RELIABILITY_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { SEARCH_TYPE_FILTER_OPERATOR } from "@/components/reliability/components/FiltersSection/filterSectionConstants";

export const ViolationsPage: React.FC = () => {
  return (
    <PageContainer>
      <FiltersContextProvider
        urlParamKey={RELIABILITY_PARAM_KEY}
        searchTypeFilterOperator={SEARCH_TYPE_FILTER_OPERATOR}
      >
        <FiltersSection />
        <div>
          <ViolationsBreakdown />
        </div>
      </FiltersContextProvider>
    </PageContainer>
  );
};
