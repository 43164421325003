import React, { useMemo } from "react";
import styled from "styled-components";

import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SectionContainer";
import { useGetCascadingFailureSupportingData } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/cascadingFailureHooks";
import { DataBox } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/Summary/DataBox";
import { SummaryTitle } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/Summary/SummaryTitle";

const Container = styled.div`
  display: flex;
  gap: 16px;
`;

export const Summary: React.FC = () => {
  const { affected_services, timeline, tldr } =
    useGetCascadingFailureSupportingData() ?? {};

  const totalIncidentTimeInMinutes = useMemo(() => {
    if (!timeline || timeline.length === 0) {
      return undefined;
    }
    const elapsedTime =
      new Date(timeline[timeline.length - 1].real_time).getTime() -
      new Date(timeline[0].real_time).getTime();

    return Math.abs(Math.round(elapsedTime / 1000 / 60));
  }, [timeline]);

  if (!affected_services) {
    return null;
  }

  return (
    <SectionContainer title={"Summary:"}>
      <SummaryTitle title={tldr} />
      <Container>
        <DataBox
          title={"Affected services"}
          value={affected_services.length.toString()}
        />
        {!!totalIncidentTimeInMinutes && (
          <DataBox
            title={"Total Impact time"}
            value={`${totalIncidentTimeInMinutes} minutes`}
          />
        )}
      </Container>
    </SectionContainer>
  );
};
