import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import { PageRoutes } from "../../ReliabilityTypes";
import { PagePadding } from "../../ReliabilityStyles";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { ReliabilityRoutes } from "../../constants/reliabilityConstants";

const Container = styled.div`
  ${PagePadding};
  padding-block: 16px 0;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: -1px;
  }
`;

export const TopSection: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const { showReliabilityPolicies, reliabilityBestPracticesTab } =
    useOverridableFlags();

  const isRouteSelected = (route: PageRoutes) =>
    location.pathname.includes(route);

  const tabValue = useMemo(() => {
    if (location.pathname.includes(ReliabilityRoutes.policies))
      return ReliabilityRoutes.policies;
    if (location.pathname.includes(ReliabilityRoutes.checks))
      return ReliabilityRoutes.checks;
    if (location.pathname.includes(ReliabilityRoutes.bestPractices))
      return ReliabilityRoutes.bestPractices;
    return ReliabilityRoutes.violations;
  }, [location.pathname]);

  const onTabChange = (_: React.SyntheticEvent, newValue: string) => {
    if (isRouteSelected(newValue as PageRoutes)) return;
    navigate(newValue);
  };

  return (
    <Container ref={divRef}>
      <TabContext value={tabValue}>
        <TabList onChange={onTabChange}>
          <Tab
            label="Violations"
            value={ReliabilityRoutes.violations}
            aria-label={`${ReliabilityRoutes.violations} tab`}
          />
          {!!reliabilityBestPracticesTab && (
            <Tab
              label="Best Practices"
              value={ReliabilityRoutes.bestPractices}
              aria-label={`${ReliabilityRoutes.bestPractices} tab`}
            />
          )}
          {!!showReliabilityPolicies && (
            <Tab
              label="Reliability Policies"
              value={ReliabilityRoutes.policies}
              aria-label={`${ReliabilityRoutes.policies} tab`}
            />
          )}
          <Tab
            label="Ignored checks"
            value={ReliabilityRoutes.checks}
            aria-label={`${ReliabilityRoutes.checks} tab`}
          />
        </TabList>
      </TabContext>
      <ButtonsContainer></ButtonsContainer>
      <StyledDivider />
    </Container>
  );
};
