import React, { useCallback } from "react";
import styled from "styled-components";
import {
  GridColDef,
  GridRowParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import {
  useCertificateControllerLoadingColumns,
  useCertificateControllerTableColumns,
} from "@/components/k8sAddons/addons/CertManager/ControllersPage/hooks/certificateControllerTableHooks";
import { controllersPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/ControllersPage/controllersPageAriaLabels";
import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import {
  LiveListTableTopSection,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import { CertificateController } from "@/generated/addonsApi";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { KubernetesDeploymentResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";

const Container = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const CertificateControllersPageTable: React.FC = () => {
  const { searchFilter, searchFieldComponent } = useSearchFieldAsGenericFilter({
    searchFieldProps: { placeholder: "Search issuers" },
  });
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const response = useFetchLiveStateList({
    ...(searchFilter && { additionalRequestFilters: [searchFilter] }),
  });
  const { rowCount, rowsToUse, columnsToUse, title, isFetchingData, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useCertificateControllerLoadingColumns(),
      dataStateColumns: useCertificateControllerTableColumns(),
      tableTitle: "Controllers",
    });

  const ariaLabel = isFetchingData
    ? `${controllersPageAriaLabels.table} loading`
    : `${controllersPageAriaLabels.table} content`;

  const onRowClick = useCallback(
    (rowData: GridRowParams<CertificateController>) => {
      const {
        row: { name, namespace, clusterName },
      } = rowData;
      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByData,
        resourceType: KubernetesDeploymentResource.Kind, // treat this kind as deployment
        cluster: clusterName,
        namespace,
        resourceName: name,
      });
    },
    [pushDrawer]
  );

  return (
    <Container>
      <LiveListTableTopSection>
        <Typography variant="h4">{title}</Typography>
        {searchFieldComponent}
      </LiveListTableTopSection>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...useCommonLiveStateTableProps(isFetchingData)}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        onRowClick={onRowClick}
      />
    </Container>
  );
};
