import { JobsSortStrategy } from "komodor-types";
import { useCallback, useState } from "react";

import { useFetchJobsByPageMutation } from "../../../generated/graphql";
import { ServiceInfo } from "../../../shared/types/ServiceInfo";
import { CheckedFilters } from "../../servicesView/pagination/utils";
import { processDataFromHasuraAction } from "../../servicesView/ProcessServiceFetchRequests";

const useJobsByPage = (): {
  jobs: ServiceInfo[] | undefined;
  fetching: boolean;
  updateJobs: (
    checkedFilters: CheckedFilters,
    textFilter: string | null,
    sortStrategy: JobsSortStrategy | null,
    page: number
  ) => Promise<void>;
} => {
  const [{ fetching }, fetchJobs] = useFetchJobsByPageMutation();
  const [paginatedJobs, setPaginatedJobs] = useState<ServiceInfo[]>();

  const updateJobs = useCallback(
    async (
      checkedFilters: CheckedFilters,
      textFilter: string | null,
      sortStrategy: JobsSortStrategy | null,
      page: number
    ) => {
      const { data } = await fetchJobs({
        checkedFilters,
        textFilter: textFilter ?? "",
        sortStrategy: sortStrategy ?? JobsSortStrategy.Status,
        page,
      });
      setPaginatedJobs(processDataFromHasuraAction(data?.fetchJobsByPage));
    },
    [fetchJobs]
  );

  return {
    jobs: paginatedJobs,
    fetching,
    updateJobs: updateJobs,
  };
};

export default useJobsByPage;
