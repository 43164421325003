import { useCallback } from "react";

import { PodEvent } from "@/components/monitorsView/workflowRunView/CommonTypes";
import useDateFormatter from "@/shared/hooks/useDateFormatter";

type PodEventTimestamps = Pick<PodEvent, "firstTimestamp" | "lastTimestamp">;

export const useFormatPodEventTimestamp = () => {
  const { format } = useDateFormatter();

  return useCallback(
    (podEvent: PodEventTimestamps) => {
      if (podEvent.lastTimestamp) {
        return format(new Date(podEvent.lastTimestamp));
      }
      if (podEvent.firstTimestamp) {
        return format(new Date(podEvent.firstTimestamp));
      }
      return "";
    },
    [format]
  );
};
