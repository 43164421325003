import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: wrap;
  gap: 16px;
  & > *:first-child {
    width: 100px;
  }
`;

const StyledResultTitle = styled(Typography)`
  && {
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const DetailsContainer = styled.div`
  flex: 1;
`;

type SectionContainerProps = PropsWithChildren<{
  title: string;
}>;

export const SectionContainer: React.FC<SectionContainerProps> = ({
  title,
  children,
}) => {
  return (
    <Container>
      <StyledResultTitle variant={"h6"} color={"text.secondary"}>
        {title}
      </StyledResultTitle>
      <DetailsContainer>{children}</DetailsContainer>
    </Container>
  );
};
