import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { GROUP_EVENTS_ID } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/timelineEventsConstants";
import { useDisplayedTotalTimelineEvents } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/hooks/timelineEventsHooks";
import { getTimelineComponentTextMaxWidth } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/timelineEventsUtils";

const Container = styled.div`
  position: relative;
`;

const StepContainer = styled.div<{ variant: Variant }>`
  width: fit-content;
  border-radius: 50%;
  padding: 8px;
  border: ${({ variant }) => `2px solid ${colorScheme[variant].foreground}`};
  background-color: ${({ variant }) => colorScheme[variant].background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div<{
  position: "top" | "bottom";
  textWidth: number;
}>`
  position: absolute;
  white-space: nowrap;
  left: 50%;
  width: ${({ textWidth }) => textWidth}px;
  text-align: center;
  word-break: break-word;
  transform: ${({ position }) =>
    position === "top" ? "translate(-50%, -100%)" : "translate(-50%, 100%)"};
  ${({ position }) => (position === "top" ? "top: 0" : "bottom: 0")};
`;

type Variant = "info" | "error";

const colorScheme: Record<Variant, { foreground: string; background: string }> =
  {
    info: { foreground: muiColors.gray[500] ?? "", background: "transparent" },
    error: {
      foreground: muiColors.pink[700] ?? "",
      background: muiColors.pink[50] ?? "",
    },
  };

type StepContainerWithInfoProps = {
  icon: React.ReactNode;
  variant: Variant;
  topTitle?: React.ReactNode;
  bottomTitle?: React.ReactNode;
  isGrouped?: boolean;
};

export const StepContainerWithInfo: React.FC<StepContainerWithInfoProps> = ({
  icon,
  variant,
  topTitle,
  bottomTitle,
  isGrouped,
}) => {
  const totalDisplayedEvents = useDisplayedTotalTimelineEvents();
  const textWidth = getTimelineComponentTextMaxWidth(totalDisplayedEvents);

  return (
    <Container id={isGrouped ? GROUP_EVENTS_ID : undefined}>
      <TitleContainer position={"top"} textWidth={textWidth}>
        {topTitle}
      </TitleContainer>
      <StepContainer variant={variant}>{icon}</StepContainer>
      <TitleContainer position={"bottom"} textWidth={textWidth}>
        {bottomTitle}
      </TitleContainer>
    </Container>
  );
};
