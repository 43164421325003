import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { ChipTypeMap } from "@mui/material/Chip/Chip";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useSetMaxChildrenInContainer } from "./scopeCellHooks";

import { PolicyScope } from "@/generated/reliabilityApi";

const chipSize: ChipTypeMap["props"]["size"] = "medium";
const ITEM_GAP = 6;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${ITEM_GAP}px;
`;

const StyledChip = styled(Chip)<{ maxwidth?: number }>`
  && {
    font-size: 12px;
    margin-right: 2px;
    ${({ maxwidth }) => maxwidth && `max-width: ${maxwidth}px`};
  }
`;

const ChipsWrapper = styled.div`
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: ${ITEM_GAP}px;
  max-width: inherit;
  position: relative;
`;

const HiddenChipsWrapper = styled(ChipsWrapper)`
  position: absolute;
  border: 1px solid blue;
  visibility: hidden;
  top: -100px;
`;

const TooltipContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type ScopeCellProps = {
  scope?: PolicyScope;
};

export const ScopeCell: React.FC<ScopeCellProps> = ({ scope }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const chipRef = useRef<HTMLDivElement>(null);
  const hiddenContentRef = useRef<HTMLDivElement>(null);
  const [hiddenContentChipWidth, setHiddenContentChipWidth] =
    useState<number>(0);
  const scopeItems = useMemo(
    () => scope?.clusterRegexes ?? [],
    [scope?.clusterRegexes]
  );

  const maxWidth = containerRef.current?.clientWidth ?? 0;

  const getChipsList = useCallback(
    (list: string[]) => {
      const maxSize = maxWidth - hiddenContentChipWidth - ITEM_GAP;
      return list.map((item) => {
        return (
          <StyledChip
            key={item}
            label={item}
            size={chipSize}
            maxwidth={maxSize}
          />
        );
      });
    },
    [maxWidth, hiddenContentChipWidth]
  );

  const fullContent = useMemo(() => {
    return getChipsList(scopeItems);
  }, [getChipsList, scopeItems]);

  useEffect(() => {
    if (!hiddenContentChipWidth && chipRef.current) {
      setHiddenContentChipWidth(chipRef.current.clientWidth);
    }
  }, [hiddenContentChipWidth]);

  const maxChildren = useSetMaxChildrenInContainer({
    containerEl: containerRef.current,
    hiddenContentEl: hiddenContentRef.current,
    hiddenContentChipWidth: hiddenContentChipWidth,
    selectedItems: scopeItems,
  });

  const slicedContent = useMemo(() => {
    const itemsNumToUse = maxChildren
      ? scopeItems.slice(0, maxChildren)
      : scopeItems;
    return getChipsList(itemsNumToUse);
  }, [getChipsList, maxChildren, scopeItems]);

  const unshownItems = useMemo(() => {
    const itemEntries = scopeItems.slice(maxChildren).map((itemText) => (
      <Typography key={itemText} variant={"body2"} color={"white"}>
        {itemText}
      </Typography>
    ));

    return <TooltipContentContainer>{itemEntries}</TooltipContentContainer>;
  }, [maxChildren, scopeItems]);

  const hiddenChildrenNum = !maxChildren ? 0 : scopeItems.length - maxChildren;

  return (
    <>
      <HiddenChipsWrapper ref={hiddenContentRef}>
        {fullContent}
      </HiddenChipsWrapper>

      <Container ref={containerRef}>
        <ChipsWrapper>{slicedContent}</ChipsWrapper>

        {hiddenChildrenNum > 0 && (
          <Tooltip title={unshownItems}>
            <StyledChip label={`+${hiddenChildrenNum}`} size={chipSize} />
          </Tooltip>
        )}

        {!hiddenContentChipWidth && (
          <StyledChip
            label="+99"
            ref={chipRef}
            size={chipSize}
            style={{ visibility: "hidden" }}
          />
        )}
      </Container>
    </>
  );
};
