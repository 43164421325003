import React, { memo } from "react";
import {
  Array as RTArray,
  Boolean,
  Dictionary,
  Partial,
  Record,
  Static,
  String,
} from "runtypes";
import styled from "styled-components";
import { groupBy } from "lodash";

import useDateFormatter from "../../../shared/hooks/useDateFormatter";

const Container = styled.div`
  font-size: 12px;
  line-height: 2;
  padding: 0.5em 0.55em;
  font-weight: 500;
  user-select: none;

  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
`;

const Entry = styled.div<{ bulletColor: string }>`
  font-weight: 400;
  &::before {
    content: "\u2b24";
    color: ${({ bulletColor }) => bulletColor};
    margin-inline-end: 0.5em;
    font-size: 8px;
    vertical-align: middle;
  }
`;

const Events = Dictionary(
  RTArray(
    Record({ type: String }).And(
      Partial({
        isFailed: Boolean,
      })
    )
  )
);

const MemoizedTooltip = memo(
  ({ events, label }: { events: Static<typeof Events>; label: number }) => {
    const { format } = useDateFormatter({
      year: undefined,
      month: undefined,
      day: undefined,
    });
    return (
      <Container>
        <div>{format(label)}</div>
        {Object.entries(events).map(([color, colorEvents]) => (
          <Entry bulletColor={color} key={color}>
            {Object.entries(
              groupBy(colorEvents, (e) => {
                const state =
                  e.isFailed !== undefined
                    ? e.isFailed
                      ? "Failed "
                      : "Completed "
                    : "";
                return `${state}${e.type}`;
              })
            )
              .map(([type, es]) => `${es.length} ${type}`)
              .join(", ")}
          </Entry>
        ))}
      </Container>
    );
  }
);

const WithEvents = Record({ payload: Record({ events: Events }) });
const getEvents = (v: unknown) =>
  WithEvents.guard(v) ? v.payload.events : null;

type Props = { payload?: unknown[]; label?: number };
const LineChartTooltip: React.FC<Props> = ({ payload, label }) => {
  const events = getEvents(payload?.[0]);
  if (label == null || !events) {
    return null;
  }
  return <MemoizedTooltip events={events} label={label} />;
};

export default LineChartTooltip;
