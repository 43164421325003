// This implementation is taken as a whole from https://developer.pagerduty.com/docs/app-integration-development/oauth-2-pkce/
// If you have a better client-side pkce generation lib I'll be happy to use it.

import { LocalStorageItem } from "./localStorageSettings";

const base64Url = function (buffer: ArrayBuffer) {
  const uint6ToB64 = function (nUint6: number) {
    return nUint6 < 26
      ? nUint6 + 65
      : nUint6 < 52
      ? nUint6 + 71
      : nUint6 < 62
      ? nUint6 - 4
      : nUint6 === 62
      ? 43
      : nUint6 === 63
      ? 47
      : 65;
  };

  const base64EncArr = function (aBytes: Uint8Array) {
    const eqLen = (3 - (aBytes.length % 3)) % 3;
    let sB64Enc = "";

    for (
      let nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0;
      nIdx < nLen;
      nIdx++
    ) {
      nMod3 = nIdx % 3;
      nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
      if (nMod3 === 2 || aBytes.length - nIdx === 1) {
        sB64Enc += String.fromCharCode(
          uint6ToB64((nUint24 >>> 18) & 63),
          uint6ToB64((nUint24 >>> 12) & 63),
          uint6ToB64((nUint24 >>> 6) & 63),
          uint6ToB64(nUint24 & 63)
        );
        nUint24 = 0;
      }
    }

    return eqLen === 0
      ? sB64Enc
      : sB64Enc.substring(0, sB64Enc.length - eqLen) +
          (eqLen === 1 ? "=" : "==");
  };

  const base64 = base64EncArr(new Uint8Array(buffer));

  const base64url_no_padding = base64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return base64url_no_padding;
};

const gen128x8bitNonce = (): Uint8Array => {
  const array = new Uint8Array(Math.floor(128 / 1.37));
  window.crypto.getRandomValues(array);
  return array;
};

export interface PKCE {
  codeVerifier: string;
  codeChallenge: string;
  challengeMethod: string;
}

export default async (): Promise<PKCE> => {
  const code_verifier = gen128x8bitNonce();

  const base64_verifier = base64Url(code_verifier.buffer);
  const base64_arraybuffer = new TextEncoder().encode(base64_verifier);
  const code_challenge = await crypto.subtle.digest(
    "SHA-256",
    base64_arraybuffer
  );
  return {
    codeVerifier: base64_verifier,
    codeChallenge: base64Url(code_challenge),
    challengeMethod: "S256",
  };
};

const storedCodeVerifier = new LocalStorageItem("pager-duty-code-verifier");

export const saveCodeVerifier = (codeVerifier: string): void => {
  storedCodeVerifier.set(codeVerifier);
};

export const getCodeVerifier = (): string | null => storedCodeVerifier.get();
