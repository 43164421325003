import React, { Dispatch } from "react";
import styled from "styled-components";

import { FormTextInput } from "../../gitlab/GitlabInstallation";
import { TextRegular } from "../../../../common/typography";
import InputTitleMaxLength from "../../../../common/controls/InputTitleMaxLength";
import ClusterNameValidation, {
  maxClusterLength,
} from "../ClusterNameValidation";

import { PreInstallError, StepContent } from "./steps.styles";

const StyledTextInput = styled(FormTextInput)`
  width: unset;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepEnterClusterName: React.FC<{
  isClusterNameValid: boolean;
  clusterName: string;
  setClusterName: React.Dispatch<React.SetStateAction<string | null>>;
  setIsClusterNameValid: Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string;
}> = ({
  isClusterNameValid,
  clusterName,
  setClusterName,
  setIsClusterNameValid,
  errorMessage,
}) => {
  return errorMessage ? (
    <PreInstallError>{errorMessage}</PreInstallError>
  ) : (
    <StepContent>
      <InputContainer>
        <InputTitleMaxLength
          length={clusterName.length}
          maxLength={maxClusterLength}
        >
          <TextRegular>Enter your cluster name</TextRegular>
        </InputTitleMaxLength>
        <StyledTextInput
          data-e2e-selector="k8s-integration-cluster-input"
          isValid={isClusterNameValid || clusterName.length === 0}
          value={clusterName}
          onChange={(e) => setClusterName(e.target.value)}
          placeholder="cluster name"
          maxLength={maxClusterLength}
        />
      </InputContainer>
      <ClusterNameValidation
        clusterName={clusterName}
        setIsNameValid={setIsClusterNameValid}
      />
    </StepContent>
  );
};
export default StepEnterClusterName;
