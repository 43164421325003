import React from "react";
import styled from "styled-components";

import { SingleSmartSelect } from "../../controls/Select/SingleSmartSelect";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";

const Container = styled.div`
  width: 10rem;
`;
interface ContainerPickerProps {
  containersOptions: {
    label: string;
    value: string;
  }[];
  selectedContainer: {
    label: string;
    value: string;
  };
  setContainer: (value: React.SetStateAction<string | undefined>) => void;
  isPreviousLogs: boolean;
}

const ContainerPicker: React.FC<ContainerPickerProps> = ({
  containersOptions,
  selectedContainer,
  setContainer,
  isPreviousLogs,
}) => {
  return containersOptions.length > 1 ? (
    <Container>
      <SingleSmartSelect
        placeholder="Select container"
        options={containersOptions}
        value={selectedContainer}
        onChange={(selected) => {
          setContainer(selected.label);
          dispatchEvent(AnalyticEvents.PodViewV2.Logs_Container_Selected, {
            container: selected.label,
            isPreviousLogs,
          });
        }}
      />
    </Container>
  ) : null;
};

export default ContainerPicker;
