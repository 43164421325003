export const violationsGroupByImpactGroup =
  "reliability|overview|group_by_impact_group";
export const violationsGroupByCheckType =
  "reliability|overview|group_by_check_type";
export const violationsGroupByCluster = "reliability|overview|group_by_cluster";
export const violationsGroupByNone = "reliability|overview|group_by_none";
export const expandedImpactGroup = "reliability|impact_group_violation_list";
export const historyTab = "reliability|violation_drawer|history";

export const getViolationDrawerViewName = (checkType: string) =>
  `reliability|violation_drawer|${checkType}`;
