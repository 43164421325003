import { muiColors } from "@komodorio/design-system";
import React, { ReactNode } from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";

const StyledFormControl = styled(FormControl)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px 4px 2px 2px;
    &:hover {
      background-color: ${muiColors.gray[100]};
      > a {
        visibility: visible;
      }
    }
  }
`;

const ExcludeCheckbox: React.FC<{
  id: string;
  fieldName: ReactNode;
  checked: boolean;
  disabled?: boolean;
  handleCheckboxClick: () => void;
  handleExcludeClick: () => void;
}> = ({
  id,
  disabled = false,
  fieldName,
  checked,
  handleCheckboxClick,
  handleExcludeClick,
}) => (
  <StyledFormControl
    onClick={(e) => {
      e.preventDefault();
      handleCheckboxClick();
    }}
  >
    <FormControlLabel
      htmlFor={id}
      aria-label={id}
      control={
        <Checkbox
          id={id}
          checked={checked}
          size="small"
          sx={{
            padding: "0 4px 0 0",
            "& .MuiSvgIcon-root": { fontSize: "18px" },
          }}
          disableRipple
        />
      }
      disabled={disabled}
      label={fieldName}
      componentsProps={{
        typography: { variant: "body3", sx: { wordBreak: "break-word" } },
      }}
      onClick={handleCheckboxClick}
      sx={{ margin: 0 }}
    />
    <Link
      onClick={(e) => {
        e.stopPropagation();
        handleExcludeClick();
      }}
      variant="body3"
      sx={{ visibility: "hidden" }}
    >
      Exclude
    </Link>
  </StyledFormControl>
);

export default ExcludeCheckbox;
