import styled from "styled-components";
import React, { Dispatch, SetStateAction } from "react";

import { blackGreyText, blueForUIText, offWhite } from "../../../Colors";

export const Switch = styled.div`
  font-family: Roboto;
  position: relative;
  margin-left: auto;
  height: 2rem;
  width: 12rem;
  background-color: ${offWhite};
  border: 1px solid #dcdddf;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const SwitchRadio = styled.input`
  display: none;
`;

export const SwitchSelection = styled.span`
  display: block;
  position: absolute;
  margin-left: auto;
  z-index: 1;
  top: 0rem;
  left: 0rem;
  width: 3.948rem;
  height: 1.9rem;
  background: ${blueForUIText};
  transition: left 0.25s ease-out;
`;

export const SwitchLabel = styled.label`
  position: relative;
  margin-left: auto;
  z-index: 2;
  float: left;
  width: 3.938rem;
  line-height: 2rem;
  font-size: 14px;
  color: ${blackGreyText};
  text-align: center;
  cursor: pointer;

  ${SwitchRadio}:checked + & {
    transition: 0.15s ease-out;
    color: #fff;
    font-weight: bold;
  }
`;

interface ClickableLableProps {
  title: string;
  onChange: (title: string) => void;
}

interface ConcealedRadioProps {
  value: string;
  selected: string;
}
const ClickableLabel: React.FC<ClickableLableProps> = ({ title, onChange }) => (
  <SwitchLabel
    onClick={() => {
      onChange(title);
    }}
  >
    {title}
  </SwitchLabel>
);

const ConcealedRadio: React.FC<ConcealedRadioProps> = ({ value, selected }) => (
  <SwitchRadio type="radio" name="switch" checked={value === selected} />
);

const ToggleSwitch: React.FC<{
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  values: string[];
}> = ({ values, selected, setSelected }) => {
  const selectionStyle = () => {
    return {
      left: `${(values.indexOf(selected) / 3) * 100}%`,
    };
  };

  const handleChange = (val: string) => {
    setSelected(val);
  };

  return (
    <Switch>
      {values.map((val, index) => {
        return (
          <span key={val}>
            <ConcealedRadio value={val} selected={selected} />
            <ClickableLabel title={val} onChange={handleChange} />
          </span>
        );
      })}
      <SwitchSelection style={selectionStyle()} />
    </Switch>
  );
};

export default ToggleSwitch;
