import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Bar as BarComponent,
  CartesianGrid,
  BarChart as RechartBarChart,
  Tooltip as RechartTooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import { CustomGraphDataPoint, EventData, TooltipItemProps } from "../types";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { lastTickFormatter } from "../../common/EventsChart/ticks";
import {
  GraphBackgroundColor,
  GraphChartMargin,
  GraphGridColor,
} from "../styles";
import { useMetricsZoom } from "../Zoom";
import { SmartPopper } from "../../common/EventsChart/SmartPopper";
import { useMouseHover } from "../../common/EventsChart/useMouseHover";
import { Tooltip } from "../Tooltip/Tooltip";
import { defaultFormatTooltipTick } from "../utils";
import { useGraphEvents } from "../hooks/useGraphEvents";
import { minimalEventIconsHeight } from "../constants";

import { INVALID_DATAPOINT_VALUE } from "./hooks";

const { Graph, Yaxis, Xaxis } = AriaLabels.MetricsAvailability.BarChart;

const Container = styled.div`
  height: 100%;
`;

export type MetricBar = Partial<BarComponent>;

interface Props {
  data: CustomGraphDataPoint[];
  bars: MetricBar[];
  tooltipItems: TooltipItemProps[];
  yAxisLabel?: string;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  events?: EventData[] | undefined;
}

export const BarChart: React.FC<Props> = ({
  data,
  bars,
  yAxisLabel,
  tooltipItems,
  yAxisProps,
  xAxisProps,
  events,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const dataWithoutNegatives = useMemo<CustomGraphDataPoint[]>(
    () =>
      data.map((dataPoint) => {
        const values = Object.entries(dataPoint).map(([key, value]) => [
          key,
          value === INVALID_DATAPOINT_VALUE ? null : value,
        ]);
        return Object.fromEntries(values);
      }),
    [data]
  );

  const { onMouseDown, onMouseMove, onMouseUp, ticks, TickComponent, domain } =
    useMetricsZoom({
      data,
    });

  const { mouseOver, setMouseOver, mousePos, setMousePos } = useMouseHover();
  const boundingRect = componentRef.current?.getBoundingClientRect();

  const eventsOnGraph = useGraphEvents(events);
  const graphMargins = {
    ...GraphChartMargin,
    top: events ? minimalEventIconsHeight : 0,
  };

  return (
    <Container
      ref={componentRef}
      aria-label={Graph}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <AutoSizer>
        {({ width, height }) => (
          <RechartBarChart
            maxBarSize={40}
            width={width}
            height={height}
            data={dataWithoutNegatives}
            margin={graphMargins}
            onMouseDown={onMouseDown}
            onMouseMove={(state, event: React.MouseEvent<SVGElement>) => {
              setMousePos({ x: event.clientX, y: event.clientY });
              onMouseMove(state);
            }}
            onMouseUp={onMouseUp}
          >
            <CartesianGrid
              stroke={GraphGridColor}
              strokeWidth="1"
              fill={GraphBackgroundColor}
            />
            <YAxis
              aria-label={Yaxis}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
              label={{
                value: yAxisLabel,
                style: { textAnchor: "middle", fontSize: 12 },
                angle: -90,
                position: "left",
                offset: -10,
                hide: !yAxisLabel,
              }}
              {...yAxisProps}
            />
            <XAxis
              aria-label={Xaxis}
              scale="linear"
              dataKey="time"
              domain={domain}
              type="number"
              tick={<TickComponent />}
              ticks={[...new Set(ticks)]}
              tickLine={false}
              axisLine={false}
              interval={0}
              tickFormatter={lastTickFormatter}
              {...xAxisProps}
            />
            <RechartTooltip
              isAnimationActive={false}
              content={(props) => (
                <SmartPopper
                  {...props}
                  boundingRect={boundingRect}
                  open={mouseOver}
                  tooltipContent={(tooltipProps) => (
                    <Tooltip
                      tooltipProps={tooltipProps}
                      dataTransformFunction={defaultFormatTooltipTick}
                      tooltipItems={tooltipItems}
                    />
                  )}
                  mousePos={mousePos}
                />
              )}
            />
            {eventsOnGraph}
            {bars}
          </RechartBarChart>
        )}
      </AutoSizer>
    </Container>
  );
};
