import React from "react";
import { palette, theme } from "@komodorio/design-system";
import {
  Typography,
  ProgressBarComponent as ProgressBar,
} from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { AccountPlan as AccountPlanType } from "komodor-types";

import {
  useGetClustersCountSubscription,
  useGetUsersCountSubscription,
} from "../../../../generated/graphql";
import { restrictionsNames } from "../../../Freemium/Restrictions";
import isElectronContext from "../../../../shared/context/electron/isElectronContext";

const MonthlyQuoteReset: React.FC<{ resetDate: string }> = ({ resetDate }) => (
  <Typography
    color={theme.foreground.fgDisabled}
  >{`Monthly quota will reset on ${resetDate}`}</Typography>
);

const CurrentNumber = styled.span<{ isLimit: boolean }>`
  color: ${({ isLimit }) =>
    isLimit ? palette.red["500"] : theme.foreground.fgPrimary};
`;

const NumberLimit: React.FC<{
  currentNum: number;
  maxNum: number | null;
}> = ({ currentNum, maxNum }) => {
  const isLimit = maxNum ? currentNum > maxNum : false;
  return (
    <span>
      <CurrentNumber isLimit={isLimit}>
        {currentNum.toLocaleString("en-US")}
      </CurrentNumber>
      {maxNum && `/${maxNum.toLocaleString("en-US")}`}
    </span>
  );
};

const EventsRecorded: React.FC<{
  eventsTotalSum: number;
  maxEventsNumber: number | null;
}> = ({ eventsTotalSum, maxEventsNumber }) => (
  <div>
    <Typography size={"medium"} color={theme.foreground.fgSubtle}>
      <NumberLimit currentNum={eventsTotalSum} maxNum={maxEventsNumber} />{" "}
      monthly events recorded
    </Typography>
  </div>
);

const getPercentageColor = (percentage: number): string => {
  if (percentage <= 33) return palette.green["500"];
  if (percentage <= 66) return palette.yellow["500"];
  return palette.red["500"];
};

const PlanProgressBar: React.FC<{
  eventsTotalSum: number;
  maxEventsNum: number | undefined | null;
}> = ({ eventsTotalSum, maxEventsNum }) => {
  const progressPercentage =
    maxEventsNum && Math.ceil((eventsTotalSum / maxEventsNum) * 100);
  const progressColor = progressPercentage
    ? getPercentageColor(progressPercentage)
    : undefined;
  return (
    <ProgressBar
      progressPercentage={progressPercentage || 0}
      height={"0.5rem"}
      backgroundColor={theme.foreground.fgOnDark}
      lineColor={progressColor}
      isRound={true}
    />
  );
};

const QuotaInformation: React.FC<{
  clustersLimit: number | null;
  eventsLimit: number | null;
  usersLimit: number | null;
  eventsTotalSum: number;
  nextCountCycleDateString: string;
  accountPlan: AccountPlanType | "";
}> = ({
  clustersLimit,
  eventsLimit,
  usersLimit,
  eventsTotalSum,
  nextCountCycleDateString,
  accountPlan,
}) => {
  const [{ data: userCountData }] = useGetUsersCountSubscription();
  const [{ data: clusterCountData }] = useGetClustersCountSubscription();

  const showProgressBar =
    accountPlan === AccountPlanType.free ||
    accountPlan === AccountPlanType.business;
  return (
    <>
      {showProgressBar && (
        <>
          <PlanProgressBar
            eventsTotalSum={eventsTotalSum}
            maxEventsNum={eventsLimit}
          />
          <MonthlyQuoteReset resetDate={nextCountCycleDateString} />
        </>
      )}
      <EventsRecorded
        eventsTotalSum={eventsTotalSum}
        maxEventsNumber={eventsLimit}
      />
      {!isElectronContext() && (
        <Typography size={"medium"} color={theme.foreground.fgSubtle}>
          <NumberLimit
            currentNum={
              userCountData?.[restrictionsNames.USERS]?.aggregate?.count || 0
            }
            maxNum={usersLimit}
          />{" "}
          Users
        </Typography>
      )}
      {!isElectronContext() && (
        <Typography size={"medium"} color={theme.foreground.fgSubtle}>
          <NumberLimit
            currentNum={clusterCountData?.clusters?.aggregate?.count || 0}
            maxNum={clustersLimit}
          />{" "}
          Clusters
        </Typography>
      )}
    </>
  );
};

export default QuotaInformation;
