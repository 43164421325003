import React, { useRef } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import MuiStepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { SectionContainer } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/SectionContainer";
import { GroupBackground } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/GroupBackground";
import { useExcludedTimelineEventsNum } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/hooks/timelineEventsHooks";
import { TimelineDetails } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/TimelineDetails";
import { useTimelineEventsComponents } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/hooks/useTimelineEventsComponents";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TimelineContainer = styled.div`
  position: relative;
  margin: 52px 0 48px 0;
  padding: 0 42px;
  display: flex;
  .MuiStepper-root {
    flex: 1;
  }
  .MuiStep-root {
    padding: 0;
  }
  .MuiStepConnector-line {
    border-width: 3px;
    border-color: ${muiColors.gray[500]};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AdditionalEventsTypography = styled(Typography)`
  && {
    position: relative;
    max-width: 42px;
    left: 42px;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const TimelineEvents: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const timelineEventsComponents = useTimelineEventsComponents();
  const excludedTimelineEventsNum = useExcludedTimelineEventsNum();

  if (!timelineEventsComponents.length) return null;

  return (
    <SectionContainer title={"What Happened:"}>
      <Container>
        <TimelineContainer ref={containerRef}>
          <GroupBackground containerRef={containerRef} />
          <FlexContainer>
            <MuiStepper>{timelineEventsComponents}</MuiStepper>
            {!!excludedTimelineEventsNum && (
              <AdditionalEventsTypography variant={"body2"}>
                +{excludedTimelineEventsNum} Others
              </AdditionalEventsTypography>
            )}
          </FlexContainer>
        </TimelineContainer>
        <TimelineDetails />
      </Container>
    </SectionContainer>
  );
};
