import React, { useState } from "react";
import styled from "styled-components";
import { TooltipWrapper } from "react-tooltip";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { FilterBarHeaderProps, SaveFilter } from "../FilterBar";
import { H2 } from "../../../../components/common/typography";
import TextButton from "../../../../components/common/TextButton";
import { isSandbox } from "../../../utils/sandbox";
import { DEFAULT_TOOLTIP } from "../../../constants/tooltipIds";

import SaveFilterPopup from "./SaveFilterPopup";

const Head = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 0.5rem;
  align-items: center;
`;

const Relative = styled.div`
  position: relative;
  display: flex;
`;

const Header: React.FC<FilterBarHeaderProps> = ({
  saveFilter,
  title,
  showSave,
}) => {
  const SaveFilterButton = ({ saveFilter }: { saveFilter: SaveFilter }) => {
    const [open, setOpen] = useState(false);

    return (
      <Relative>
        {showSave && (
          <TooltipWrapper
            tooltipId={DEFAULT_TOOLTIP}
            content={
              isSandbox()
                ? "This option is disabled in sandbox version"
                : undefined
            }
          >
            <TextButton
              className={saveFilter ? "" : "disabled"}
              onClick={saveFilter && (() => setOpen(true))}
              isSandbox={isSandbox()}
            >
              Save filter
            </TextButton>
          </TooltipWrapper>
        )}
        {open && saveFilter && (
          <SaveFilterPopup onSave={saveFilter} onClose={() => setOpen(false)} />
        )}
      </Relative>
    );
  };

  return (
    <Head data-e2e-selector="filtersBar-header">
      {title && <H2>{title}</H2>}
      <SaveFilterButton saveFilter={saveFilter} />
    </Head>
  );
};

export default Header;
