import * as React from "react";
import styled from "styled-components";

import CopyComponent from "../../../common/Copy";
import { H3 } from "../../../common/typography";

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const Steps = styled.ol`
  width: 30rem;
  padding-inline-end: 1rem;
`;

const Step = styled.li`
  font-size: 0.85rem;
  margin-block-end: 0.5rem;
`;

const StyledCopy = styled(CopyComponent)`
  width: 25rem;
`;

const GrafanaInstallationInstructions: React.FC<{ webhookUrl: string }> = ({
  webhookUrl,
}) => {
  return (
    <Container>
      <H3>Installation Steps</H3>
      <Steps>
        <Step>
          In the Grafana side bar, hover your cursor over the Alerting (bell)
          icon and then click Notification channels.
        </Step>
        <Step>
          Click <b>New channel</b>
        </Step>
        <Step>
          In the <b>Name</b> field, enter a name for the channel.
        </Step>
        <Step>
          In the <b>Type</b> field, choose <i>webhook</i>
        </Step>
        <Step>
          In the <b>Url</b> field, enter the value:
          <StyledCopy value={webhookUrl} />
        </Step>
        <Step>
          Check the <b>Default</b> option under the Notifications settings.
          Unchecking this will require to manually add this channel to alerts.
        </Step>
      </Steps>
    </Container>
  );
};

export default GrafanaInstallationInstructions;
