import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { CascadingIncidentReport } from "@/generated/reliabilityApi";

export const useGetCascadingFailureSupportingData = ():
  | CascadingIncidentReport
  | undefined => {
  const { data } = useViolation();
  return data?.data.violation.supportingData?.cascadingFailure
    ?.cascadingSessionInfo;
};
