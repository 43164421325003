import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { CronJobVariables, Variables } from "../../common/types";
import { Label } from "../common/styles";
import Select from "../../../common/controls/Select";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const WithPadding = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const FailedJobText = (
  <>
    <b>failed</b> job
  </>
);

const CronJobTriggerConditionsSection: React.FC<{
  variables: Variables | undefined;
  handleChange: (variables: Variables) => void;
}> = ({ variables, handleChange }) => {
  const [condition, setCondition] = useState<string>(CronJobVariables.FIRST);

  const options = Object.entries(CronJobVariables).map(([value, label]) => ({
    label,
    value,
  }));

  useEffect(() => {
    if (variables?.cronJobCondition) {
      setCondition(variables.cronJobCondition ?? CronJobVariables.FIRST);
    }
  }, [condition, handleChange, variables]);

  const handleConditionChange = (value: string) => {
    setCondition(value);
    handleChange({ ...variables, cronJobCondition: value });
  };

  return (
    <>
      <Container>
        <Label>Alert on</Label>
        <WithPadding>
          <Select
            onChange={(e) => {
              handleConditionChange(e.target.value);
            }}
            value={condition}
          >
            {options.map(({ label, value }) => (
              <option key={value} value={label}>
                {label}
              </option>
            ))}
          </Select>
        </WithPadding>
        <Label>{FailedJobText}</Label>
      </Container>
    </>
  );
};

export default CronJobTriggerConditionsSection;
