/* eslint-disable max-lines */
import { muiColors } from "@komodorio/design-system";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Divider } from "@komodorio/design-system/deprecated";
import React, { useState } from "react";
import styled from "styled-components";
import pluralize from "pluralize";
import { useDebouncedCallback } from "use-debounce";

import { parseKomodorUid } from "../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useOpenIssueByResource } from "../../../../../OpenIssues/useOpenIssuesByResource";
import EventGroup from "../../../../EventGroup";
import {
  HealthyIcon,
  InactiveIcon,
  OpenIssueAlertIcon,
} from "../../../../../OpenIssues/IndicationIconStyles";
import { CurrentAvailabilityHealthTooltip } from "../../../../../OpenIssues/tooltips/CurrentAvailabilityHealthTooltip";
import { useMinifiedMonitorEventGroupById } from "../../../../../monitorsView/useWorkflowsRuns";
import { SYSTEM_WIDE_SERVICE_ID } from "../../../../../../shared/constants/systemWideServiceId";
import { AriaLabels } from "../../../../../../shared/config/ariaLabels";
import { useStateInSearchParams } from "../../../../../../shared/hooks/state";
import {
  ARGO_WORKFLOW_KIND,
  ARGO_WORKFLOW_KOMODOR_KIND,
} from "../../../../../ResourceView/resources/argoWorkflow";
import { ARGO_WORKFLOW_RUN_PARAM_KEY } from "../../../../../../shared/config/urlSearchParamsKeys";
import { argoKomodorKindToK8sKind } from "../../../../../argoWorkflowsView/convertArgoKinds";
import { useArgoWorkflowsRootOwner } from "../../../../../argoWorkflowsView/ArgoExternalLink";
import { useFindService } from "../../../../../../shared/hooks/useFindService";

import { parseGeneralWorkflowName } from "@/components/common/ProcessList/details/NodeChangeDetails/ImpactedResourcesSection/utils";
import { Addon, Entity, WorkflowDagFromJSON } from "@/generated/addonsApi";
import WorkflowDag from "@/components/ResourceView/resources/workflowDag";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import Resource from "@/components/ResourceView/resources";
import { buildKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";

const Tile = styled.div<{ clickable: boolean }>`
  display: grid;
  grid-template-columns: max-content auto 1px max-content;
  column-gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]}}
  ${({ clickable }) =>
    clickable &&
    `
    &:hover {
      cursor: pointer;
      border-color: ${muiColors.blue[500]};
    }
  `}
`;

const HealthIndicationWrapper = styled.div`
  height: fit-content;
  margin-block-start: 0.1rem;
`;

const TileItem = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ImpactedKomodorServiceTile: React.FC<{
  komodorUid: string;
  terminatedPodsCount: number;
  setSelectedServiceId: (serviceId: string | null) => void;
  setCorrelatedEvent?: (event: EventGroup | null) => void;
  showHealthIndication?: boolean;
}> = ({
  komodorUid,
  terminatedPodsCount,
  setSelectedServiceId,
  setCorrelatedEvent,
  showHealthIndication = true,
}) => {
  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};

  const service = useFindService({
    value: name,
    clusterName: cluster,
    pageName: kind,
    namespace,
  });

  const openIssue = useOpenIssueByResource(
    cluster,
    namespace,
    kind,
    name,
    service?.id
  );
  const correlatedIssue = useMinifiedMonitorEventGroupById({
    runId: openIssue?.id ?? "",
    serviceId: service?.id ?? SYSTEM_WIDE_SERVICE_ID,
    type: openIssue?.type,
  });

  const onTileClick = service
    ? () => setSelectedServiceId(service.id)
    : undefined;

  return (
    <Tile onClick={onTileClick} clickable={!!onTileClick}>
      {showHealthIndication ? (
        <HealthIndicationWrapper>
          <CurrentAvailabilityHealthTooltip
            issue={openIssue}
            correlatedIssue={correlatedIssue}
            setCorrelatedEvent={setCorrelatedEvent}
            service={service}
          >
            {openIssue ? (
              <OpenIssueAlertIcon
                onClick={(e) => {
                  e.stopPropagation();
                  if (correlatedIssue) setCorrelatedEvent?.(correlatedIssue);
                }}
                aria-label={
                  AriaLabels.OpenIssue.CurrentAvailabilityHealthTooltipLink
                }
              />
            ) : service ? (
              <HealthyIcon />
            ) : (
              <InactiveIcon />
            )}
          </CurrentAvailabilityHealthTooltip>
        </HealthIndicationWrapper>
      ) : (
        <div />
      )}
      <ImpactedResourceTileContent
        kind={kind}
        name={name}
        terminatedPodsCount={terminatedPodsCount}
      />
    </Tile>
  );
};

export const ImpactedOrphanPodTile: React.FC<{
  komodorUid: string;
}> = ({ komodorUid }) => {
  const { kind = "", name = "" } = parseKomodorUid(komodorUid) ?? {};

  return (
    <Tile clickable={false}>
      <div />
      <ImpactedResourceTileContent
        kind={kind}
        name={name}
        terminatedPodsCount={1}
      />
    </Tile>
  );
};

export const ImpactedArgoWorkflowTile: React.FC<{
  komodorUid: string;
  terminatedPodsCount: number;
  setSelectedKomodorUid: (komodorUid: string | null) => void;
}> = ({ komodorUid, terminatedPodsCount, setSelectedKomodorUid }) => {
  const [, setSelectedRunId] = useStateInSearchParams(
    ARGO_WORKFLOW_RUN_PARAM_KEY
  );
  const { kind = "", name = "" } = parseKomodorUid(komodorUid) ?? {};

  const [clickIntent, setClickIntent] = useState(false);
  const { callback: debouncedSetClickIntent } = useDebouncedCallback(
    (intent: boolean) => setClickIntent(intent),
    100
  );

  const rootOwnerKomodorUid = useArgoWorkflowsRootOwner(
    clickIntent ? komodorUid : undefined
  );

  const onTileClick = rootOwnerKomodorUid
    ? () => {
        const { kind = "" } = parseKomodorUid(rootOwnerKomodorUid) ?? {};
        if (kind !== ARGO_WORKFLOW_KOMODOR_KIND) {
          setSelectedRunId(
            komodorUid.replace(ARGO_WORKFLOW_KOMODOR_KIND, ARGO_WORKFLOW_KIND)
          );
        }
        setSelectedKomodorUid(rootOwnerKomodorUid);
      }
    : undefined;

  return (
    <Tile
      onClick={onTileClick}
      clickable={!!onTileClick}
      onMouseEnter={() => debouncedSetClickIntent(true)}
      onMouseLeave={() => debouncedSetClickIntent(false)}
    >
      <div />
      <ImpactedResourceTileContent
        kind={argoKomodorKindToK8sKind[kind] ?? kind}
        name={name}
        terminatedPodsCount={terminatedPodsCount}
      />
    </Tile>
  );
};

export const ImpactedGeneralWorkflowTile: React.FC<{
  komodorUid: string;
  terminatedPodsCount: number;
  setSelectedResource: (resource: Resource | undefined) => void;
}> = ({ komodorUid, terminatedPodsCount, setSelectedResource }) => {
  const [, setSelectedRunId] = useStateInSearchParams(
    ARGO_WORKFLOW_RUN_PARAM_KEY
  );
  const {
    cluster = "",
    namespace = "",
    name = "",
  } = parseKomodorUid(komodorUid) ?? {};
  const {
    engine = "",
    dag = "",
    run = "",
  } = parseGeneralWorkflowName(name) ?? {};

  const [clickIntent, setClickIntent] = useState(false);
  const { callback: debouncedSetClickIntent } = useDebouncedCallback(
    (intent: boolean) => setClickIntent(intent),
    100
  );

  const { data, isFetching } = useGetAddonFullLiveState(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      uid:
        buildKomodorWorkflowId({
          cluster,
          namespace,
          engine: engine,
          dagId: dag,
        }) ?? "",
      name: dag,
      getEntityRequest: {
        clusterName: cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: WorkflowDagFromJSON(json?.data?.data),
    }),
    { enabled: clickIntent }
  );

  const onTileClick =
    !isFetching && data?.data
      ? () => {
          setSelectedRunId(run);
          setSelectedResource(new WorkflowDag(data.data));
        }
      : undefined;

  return (
    <Tile
      onClick={onTileClick}
      clickable={!!onTileClick}
      onMouseEnter={() => debouncedSetClickIntent(true)}
      onMouseLeave={() => debouncedSetClickIntent(false)}
    >
      <div />
      <ImpactedResourceTileContent
        kind={"Workflow"}
        name={dag}
        terminatedPodsCount={terminatedPodsCount}
      />
    </Tile>
  );
};

const ImpactedResourceTileContent: React.FC<{
  name: string;
  kind: string;
  terminatedPodsCount: number;
}> = ({ name, kind, terminatedPodsCount }) => {
  return (
    <>
      <TileItem>
        <Tooltip title={name} placement="top">
          <Typography variant="h5" noWrap sx={{ width: "fit-content" }}>
            {name}
          </Typography>
        </Tooltip>
        <Typography
          variant="overline"
          color={muiColors.gray[500]}
          lineHeight={1.5}
          fontWeight={500}
        >
          {kind}
        </Typography>
      </TileItem>
      <Divider variant="vertical" />
      <TileItem>
        <Typography variant="h5">
          {pluralize("Pods", terminatedPodsCount, true)}
        </Typography>
        <Typography
          variant="overline"
          color={muiColors.gray[500]}
          lineHeight={1.5}
          fontWeight={500}
        >
          Terminated
        </Typography>
      </TileItem>
    </>
  );
};
