import styled from "styled-components";

import backIcon from "../assets/back.svg";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  padding-left: 1.5rem;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3b3d45;

  background-image: url(${backIcon});
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
`;
