import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { capitalize } from "lodash";

import { AddonStatusChip } from "../../components/AddonStatusChip";
import { certificateStatusToColor } from "../../addons/CertManager/CertificatesPage/certificatePageConstants";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import {
  CertificateRelatedResource,
  CertificateStatusEnum,
  StatusCondition,
} from "@/generated/addonsApi";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
const StyleLink = styled(Typography).attrs({
  variant: "body2",
  color: "primary",
})`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SingleLinkContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

type ResourceLinkProps = {
  relatedResources: CertificateRelatedResource[];
};

export const ResourceLink: React.FC<ResourceLinkProps> = (props) => {
  const { relatedResources } = props;
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const onResourceClick = useCallback(
    (uid: string, type: string) => {
      const {
        kind = "",
        name = "",
        cluster = "",
        namespace = "",
      } = parseKomodorUid(uid) ?? {};

      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByData,
        cluster,
        namespace,
        resourceType: kind,
        resourceName: name,
        buildPreloadResource: true,
        additionalData: {
          isCustomResource: type === "CustomResource",
        },
      });
    },
    [pushDrawer]
  );

  const content = useMemo(() => {
    return (
      <>
        {relatedResources.map((relatedResource, idx) => {
          const { name } = parseKomodorUid(relatedResource.komodorUid) ?? {};
          const addComma = idx < relatedResources.length - 1;
          return (
            <SingleLinkContainer key={relatedResource.komodorUid}>
              <StyleLink
                onClick={() =>
                  onResourceClick(
                    relatedResource.komodorUid,
                    relatedResource.type
                  )
                }
              >
                {name}
              </StyleLink>
              <ResourceStatus status={relatedResource.status} />
              {addComma ? ", " : ""}
            </SingleLinkContainer>
          );
        })}
      </>
    );
  }, [relatedResources, onResourceClick]);

  return <Container>{content}</Container>;
};

export const ResourceStatus: React.FC<{
  status: StatusCondition | undefined;
}> = ({ status }) => {
  if (!status?.status) return null;

  return (
    <Tooltip title={status.message} placement={"top"}>
      <div>
        <AddonStatusChip
          color={
            certificateStatusToColor[status.status as CertificateStatusEnum] ??
            certificateStatusToColor["unknown"]
          }
          label={capitalize(status.status)}
        />
      </div>
    </Tooltip>
  );
};
