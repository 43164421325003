import React, { useState } from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Skeleton from "@mui/material/Skeleton";

import { MonitorData } from "../../../../../generated/monitorsApi";
import WorkflowIssueEventGroup, {
  toWorkflowIssueEvent,
} from "../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { useIssueDuration } from "../../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/sections/ImpactSection";
import EventDrawer from "../../../../common/ProcessList/EventDrawer";
import { TriageDataDb } from "../../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/types";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";

import { IssueInsightsCol } from "./IssueInsightsCol";
import { IssueReasonsCol } from "./IssueReasonsCol";

const Container = styled.div`
  row-gap: 8px;
  padding: 8px 16px;
  grid-column: 1 / -1;

  &:not(:nth-child(5)) {
    border-bottom: 1px solid ${muiColors.gray[200]};
  }
`;

const Title = styled.div`
  display: flex;
  column-gap: 2px;
  align-items: center;
`;

const IssueLine = styled.div`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  border-radius: 4px;
  padding: 3px 8px;
  background-color: ${muiColors.pink[50]};
`;

const IssueLineCol = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightAlignedIssueLineCol = styled(IssueLineCol)`
  justify-self: end;
`;

export const IssueRowSkeleton = () => (
  <Container>
    <Skeleton
      width="201px"
      height="23px"
      sx={{ transform: "none", marginBlock: "4px" }}
    />
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        marginBlock: "4px",
      }}
    >
      <Skeleton width="201px" height="12px" sx={{ transform: "none" }} />
      <Skeleton width="271px" height="12px" sx={{ transform: "none" }} />
    </Box>
  </Container>
);

interface IssueRowProps {
  issue: MonitorData;
  showClusterName?: boolean;
  className?: string;
}
export const IssueRow: React.FC<IssueRowProps> = ({
  issue,
  showClusterName,
  className,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const issueEventGroup = new WorkflowIssueEventGroup(
    toWorkflowIssueEvent(issue, issue.services?.[0] ?? "")
  );
  const issueEvent = issueEventGroup.events[0];
  const issueDuration = useIssueDuration(
    issueEvent.eventTime,
    issueEvent.closedAt
  );
  return (
    <Container className={className}>
      <Box
        sx={{
          display: "flex",
          gridColumn: "1 / -1",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>
          {showClusterName && (
            <>
              <Typography variant="body2" color="text.secondary">
                {issue.cluster}
              </Typography>
              <ChevronRight
                sx={{ width: 16, height: 16, color: muiColors.gray[500] }}
              />
            </>
          )}
          <Typography variant="body2" color="text.secondary">
            {issueEvent.namespace}
          </Typography>
          <ChevronRight
            sx={{ width: 16, height: 16, color: muiColors.gray[500] }}
          />
          <Typography variant="body2">
            {issueEvent.shortResourceName}
          </Typography>
        </Title>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenDrawer(true)}
          aria-label={
            AriaLabels.ClusterOverview.UnhealthyServicesSection.MoreInfoButton
          }
        >
          More Info
        </Button>
      </Box>
      <IssueLine>
        <IssueLineCol>
          <IssueReasonsCol issueReasons={issueEvent.reasons} />
        </IssueLineCol>
        <IssueLineCol>
          <IssueInsightsCol
            issueTriageData={issue.triageData as TriageDataDb}
          />
        </IssueLineCol>
        <RightAlignedIssueLineCol>
          <Typography variant="h5">Duration</Typography>
          <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
            {issueDuration}
          </Typography>
        </RightAlignedIssueLineCol>
      </IssueLine>
      <EventDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        event={issueEventGroup}
        ariaLabel={
          AriaLabels.ClusterOverview.UnhealthyServicesSection.IssueDrawer
        }
      >
        {issueEventGroup?.renderEventDetails(() => setOpenDrawer(false)) ??
          null}
      </EventDrawer>
    </Container>
  );
};
