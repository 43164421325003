import { useMemo } from "react";
import { ResourceTableModelRow } from "komodor-types";

import { useDrawerUrlState } from "../../../shared/hooks/state";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { initialTimeWindow } from "..";
import { TimeWindow } from "../../../shared/types/TimeWindow";
import {
  DELETED_PODS_PREFIX,
  INSPECTION_SHOW_DELETED_PODS_PARAM_KEY,
  TIME_WINDOW_PARAM_KEY,
  TIMEFRAME_PARAM_KEY,
} from "../../../shared/config/urlSearchParamsKeys";
import { useResourcesAPIGet } from "../../../shared/hooks/resources-api/client";
import { WORKLOADS_DELETED_PODS_EVENT_ID } from "../../../shared/hooks/resources-api/requestResponseMaps";
import { EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest } from "../../../generated/resourcesApi";
import { useDrawerTimeWindowFromUrl } from "../../ResourceView/tabs/EventsTab/content/useTimeWindowFromURL/useDrawerTimeWindowFromUrl";

import { useParsePodsResultFromAPI } from "./useParsingResourceList";

import { useGetDeletedPodsState } from "@/shared/hooks/resources-api/client/useGetDeletedPodsState";
export const DELETED_PODS_TIMEFRAME_PARAM_KEY =
  DELETED_PODS_PREFIX + TIMEFRAME_PARAM_KEY;
export const useDeletedPodsProperties = () => {
  const [showDeletedPodsUrlParam, setShowDeletedPodsUrlParam] =
    useDrawerUrlState<boolean>(INSPECTION_SHOW_DELETED_PODS_PARAM_KEY);
  const [deletedPodsTimeWindowUrlParam, setDeletedPodsTimeWindowUrlParam] =
    useDrawerTimeWindowFromUrl(
      initialTimeWindow,
      DELETED_PODS_PREFIX + TIME_WINDOW_PARAM_KEY,
      DELETED_PODS_TIMEFRAME_PARAM_KEY
    );

  return useMemo(
    () => ({
      showDeletedPodsUrlParam,
      setShowDeletedPodsUrlParam,
      deletedPodsTimeWindowUrlParam,
      setDeletedPodsTimeWindowUrlParam,
    }),
    [
      deletedPodsTimeWindowUrlParam,
      setDeletedPodsTimeWindowUrlParam,
      setShowDeletedPodsUrlParam,
      showDeletedPodsUrlParam,
    ]
  );
};

export type DeletedPodsResult = {
  data: ResourceTableModelRow[];
  fetching: boolean;
  emptyResult: boolean;
  refresh: () => void;
  errorMessage: string;
};

export const useResourceListOfDeletedPods = (
  timeWindow: TimeWindow,
  params: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest | null,
  pause = false
) => {
  const { data, isFetching, isError, error, refetch } = useGetDeletedPodsState(
    {
      ...params,
      fromEpoch: timeWindow.start.getTime().toString(),
      toEpoch: timeWindow.end.getTime().toString(),
      getIps: true,
      fields: [
        "id",
        "nodeName",
        "eventTime",
        "createdAt",
        "podName",
        "namespace",
        "labels",
        "phase",
        "statusReason",
        "containerStatuses",
        "resourceOwnerName",
        "readinessGates",
        "resourcesLimits",
        "resourcesRequests",
        "clusterName",
      ],
    },
    { enabled: !!params && !pause }
  );
  const pods = useParsePodsResultFromAPI(data);

  return useMemo(
    () => ({
      data: pods ?? [],
      fetching: isFetching,
      emptyResult: pods?.length === 0 && !isFetching && !isError,
      refresh: refetch,
      errorMessage: (error as Error)?.message ?? "",
    }),
    [pods, isFetching, isError, refetch, error]
  );
};

export const useDeletedPodById = (
  id: string,
  pause = false
): DeletedPodsResult => {
  const {
    data,
    loading: fetching,
    error,
    refresh,
  } = useResourcesAPIGet(
    WORKLOADS_DELETED_PODS_EVENT_ID,
    {
      id,
      fields: [
        "id",
        "nodeName",
        "eventTime",
        "createdAt",
        "podName",
        "namespace",
        "labels",
        "phase",
        "statusReason",
        "containerStatuses",
        "resourceOwnerName",
        "readinessGates",
        "resourcesLimits",
        "resourcesRequests",
        "clusterName",
        "metadata",
        "spec",
        "status",
      ],
    },
    pause
  );
  const pods = useParsePodsResultFromAPI(data);

  return useMemo(
    () => ({
      data: pods ?? [],
      fetching,
      emptyResult: pods?.length === 0 && !fetching && !error,
      refresh: refresh,
      errorMessage: error ?? "",
    }),
    [pods, error, fetching, refresh]
  );
};
