import pluralize from "pluralize";

import { INVALID_TIME, now } from "@/components/k8sAddons/constants/time";
import { ONE_DAY_IN_MS } from "@/shared/constants/time";

export const getTimeElapsedStr = (targetDateString: string) => {
  const dateStrAsDateMs = new Date(targetDateString).getTime();
  if (dateStrAsDateMs.toString() === "NaN") {
    return {
      outputStr: "N/A",
      daysElapsed: INVALID_TIME,
      timeElapsedInMs: INVALID_TIME,
    };
  }

  const timeElapsedInMs = dateStrAsDateMs - now;
  const daysElapsed = Math.floor(timeElapsedInMs / ONE_DAY_IN_MS);
  let outputStr = "";

  if (daysElapsed === 0) {
    outputStr = `Today`;
  } else if (daysElapsed > 0) {
    outputStr = `${daysElapsed}d`;
  } else {
    const daysElapsedAbs = Math.abs(daysElapsed);
    outputStr = `${daysElapsedAbs} ${pluralize("day", daysElapsedAbs)} ago`;
  }

  return { outputStr, daysElapsed, timeElapsedInMs };
};
