import { useMemo } from "react";

import { TrackedFile } from "../../../../../shared/types/git";

type TrackedFiles = TrackedFile[] | null | undefined;
const useFilteredTrackedFiles = (trackedFiles: TrackedFiles): TrackedFiles =>
  useMemo(() => {
    return trackedFiles;
  }, [trackedFiles]);

export default useFilteredTrackedFiles;
