import React from "react";
import { parseISO } from "date-fns";
import { groupBy, uniqBy } from "lodash";
import { muiColors, palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";

import { IssueFragment } from "../../../../generated/graphql";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { mapAndSort } from "../groupEvents";
import EventGroup, { EventBase, EventGroupTooltipProps } from "..";
import BellScatterShape from "../../../monitorsView/assets/bell.svg?react";
import EventNodeShape from "../../../monitorsView/assets/eventNode.svg?react";
import EventStorageShape from "../../../monitorsView/assets/eventStorage.svg?react";
import EventWorkloadShape from "../../../monitorsView/assets/eventWorkload.svg?react";
import JobScatterShape from "../../../common/EventGroup/shapes/job.svg?react";
import PodShape from "../../../common/EventGroup/shapes/pod.svg?react";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  WorkflowConfigType,
  WorkflowConfiguration,
} from "../../../monitorsView/common/types";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { typeTriggers } from "../../../monitorsView/workflowRunView/IssueDetailsDrawer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowIssueEventDetails from "../../ProcessList/details/WorkflowIssueEventDetails";
import { MonitorData } from "../../../../generated/monitorsApi";
import { EventsChartTooltipComponent } from "../../EventsChart/TimelineChart/EventTooltip";

import { MonitorsResponseData } from "./useWorkflowIssues";
import { WORKFLOW_CONFIG_TYPES } from "./constants";

export enum WorkflowIssueStatus {
  open = "open",
  closed = "closed",
}

const isMonitorWithReasons: Record<WorkflowConfigType, boolean> = {
  [WorkflowConfigType.Availability]: true,
  [WorkflowConfigType.Pod]: true,
  [WorkflowConfigType.Workflow]: true,
  [WorkflowConfigType.PVC]: false,
  [WorkflowConfigType.NodeIssue]: false,
  [WorkflowConfigType.Job]: false,
  [WorkflowConfigType.CronJob]: false,
  [WorkflowConfigType.Deploy]: false,
};

export interface WorkflowIssueEvent extends EventBase {
  type: WorkflowConfigType;
  status: WorkflowIssueStatus;
  shortResourceName: string;
  sourceEventId: string;
  closedAt: Date | null;
  closeReason: string | undefined;
  workflowConfiguration: WorkflowConfiguration | null;
  resultsSummary: { [check: string]: boolean };
  clusterName: string | undefined;
  namespace: string | undefined;
  reasons: string[];
}

export const toWorkflowIssueEvent = (
  e: MonitorData | IssueFragment,
  serviceId: string
): WorkflowIssueEvent => ({
  serviceId,
  type: e.type as WorkflowConfigType,
  id: e.id,
  eventTime: e.eventTime ? parseISO(e?.eventTime) : new Date(),
  status: e.closedAt ? WorkflowIssueStatus.closed : WorkflowIssueStatus.open,
  shortResourceName: e.shortResourceName ?? "",
  sourceEventId: e.sourceEventId ?? "",
  closedAt: e.closedAt ? parseISO(e.closedAt) : null,
  closeReason: e.closeReason ?? undefined,
  workflowConfiguration:
    e.workflowConfiguration as WorkflowConfiguration | null,
  resultsSummary: e.resultsSummary as { [check: string]: boolean },
  clusterName: e.cluster ?? undefined,
  namespace: e.namespace ?? undefined,
  reasons: (e.reasons as string[]) ?? [],
});

const getSummary = (events: WorkflowIssueEvent[]) => {
  const firstEvent = events[0];

  const fullName = !firstEvent.clusterName
    ? `${firstEvent.shortResourceName}`
    : !firstEvent.namespace
    ? `${firstEvent.clusterName}/${firstEvent.shortResourceName}`
    : `${firstEvent.clusterName}/${firstEvent.namespace}/${firstEvent.shortResourceName}`;
  return `${fullName} ${typeTriggers[firstEvent.type] ?? ""}`;
};

const getIconByIssueType = (type: WorkflowConfigType): React.FC => {
  if (type === WorkflowConfigType.PVC) {
    return EventStorageShape;
  }
  if (type === WorkflowConfigType.Availability) {
    return EventWorkloadShape;
  }
  if (type === WorkflowConfigType.NodeIssue) {
    return EventNodeShape;
  }
  if (type === WorkflowConfigType.Job) {
    return JobScatterShape;
  }
  if (type === WorkflowConfigType.CronJob) {
    return JobScatterShape;
  }
  if (type === WorkflowConfigType.Pod) {
    return PodShape;
  }
  if (type === WorkflowConfigType.Workflow) {
    return PodShape;
  }
  return BellScatterShape;
};

export default class WorkflowIssueEventGroup implements EventGroup {
  icon = BellScatterShape;

  readonly backgroundColor = palette.pink[100];
  readonly fillColor = palette.pink[500];

  readonly events: [WorkflowIssueEvent];
  readonly type;
  readonly id;
  readonly startTime;
  readonly endTime;
  readonly status;
  readonly details;
  readonly clusterName;
  readonly namespace;
  readonly isCompleted;
  workflowType;
  workflowConfig;
  resourceName;
  serviceId;
  reasons;

  constructor(event: WorkflowIssueEvent) {
    this.events = [event];
    this.type = WORKFLOW_CONFIG_TYPES[event.type];
    this.workflowType = event.type;
    this.icon = getIconByIssueType(this.workflowType);
    this.id = event.id;
    this.serviceId = event.serviceId;
    this.status = event.status;
    this.startTime = event.eventTime;
    this.endTime = event.closedAt ?? event.eventTime;
    this.workflowConfig = event.workflowConfiguration;
    this.resourceName = event.shortResourceName;
    this.isCompleted = event.status !== WorkflowIssueStatus.open;
    this.details = isMonitorWithReasons[this.workflowType]
      ? event.reasons.sort().join("\n")
      : `${
          Object.entries(event.resultsSummary).filter(([, passed]) => !passed)
            .length
        } possible causes`;
    this.reasons = event.reasons;
    this.clusterName = event.clusterName;
    this.namespace = event.namespace;
  }

  get summary(): string {
    return getSummary(this.events);
  }

  renderEventDetails(onClose?: () => void): JSX.Element {
    return <WorkflowIssueEventDetails eventGroup={this} onClose={onClose} />;
  }

  renderTooltipContent(props: EventGroupTooltipProps): JSX.Element {
    if (this.workflowType === WorkflowConfigType.NodeIssue) {
      return (
        <EventsChartTooltipComponent
          title={"Node Issue"}
          data={[
            {
              key: "Started",
              value:
                props.durationFormatter?.(this.startTime, new Date()) + " ago",
            },
            {
              key: "Status",
              value: (
                <Typography
                  variant={"body3"}
                  style={{ textTransform: "uppercase" }}
                  color={
                    this.status === WorkflowIssueStatus.open
                      ? muiColors.pink[600]
                      : muiColors.green[800]
                  }
                >
                  {this.status}
                </Typography>
              ),
            },
          ]}
        />
      );
    }
    return <></>;
  }
}

export const groupWorkflowIssuesEvents = (
  events: MonitorsResponseData | undefined,
  serviceId: string
): WorkflowIssueEventGroup[] => {
  if (!events) return [];
  //Discard same event fetched multiple times because of batches query
  const uniqEventsById = uniqBy(events, (e) => e.id);

  const sortedEvents = mapAndSort(
    uniqEventsById
      .map((e) =>
        e.services && !e.services.length ? { ...e, services: [serviceId] } : e
      )
      .map((e) => (e.closedAt ? e : { ...e, closedAt: undefined })),
    toWorkflowIssueEvent
  );
  return groupParsedWorkflowIssues(sortedEvents, serviceId);
};

export const groupWorkflowIssuesEventsOld = (
  events: IssueFragment[] | undefined,
  serviceId: string
): WorkflowIssueEventGroup[] => {
  if (!events) return [];
  //Discard same event fetched multiple times because of batches query
  const uniqEventsById = uniqBy(events, (e) => e.id);

  const sortedEvents = mapAndSort(
    uniqEventsById
      .map((e) =>
        e.services && !(e.services as string[]).length
          ? { ...e, services: [serviceId] }
          : e
      )
      .map((e) => (e.closedAt ? e : { ...e, closedAt: undefined })),
    toWorkflowIssueEvent
  );
  return groupParsedWorkflowIssues(sortedEvents, serviceId);
};

const groupParsedWorkflowIssues = (
  events: WorkflowIssueEvent[],
  serviceId: string
) => {
  const groups = events.map((g) => new WorkflowIssueEventGroup(g));
  return Object.values(groupBy(groups, (g) => g.id))
    .map((sameGroups) => {
      const singleUniqGroup = sameGroups[0];
      if (sameGroups.length > 1) {
        singleUniqGroup.serviceId = serviceId;
      }
      return singleUniqGroup;
    })
    .filter(
      (e) =>
        e.type !== WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Job] &&
        e.type !== WORKFLOW_CONFIG_TYPES[WorkflowConfigType.CronJob] &&
        e.type !== WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Deploy]
    );
};
