import { useViolations } from "../../../../../../hooks/reliabilityHooks";
import { useReportDDRumWithReactQuery } from "../../../../../../../../shared/hooks/datadog-rum/useReportDDRumWithReactQuery";
import {
  expandedImpactGroup,
  violationsGroupByNone,
} from "../../../../../../constants/dataDogReporting";

import { useViolationsRequestParams } from "./useViolationsRequestParams";

export const useReportTableLoadingTime = () => {
  const { impactGroupId } = useViolationsRequestParams();
  const violationsRes = useViolations();

  useReportDDRumWithReactQuery({
    result: violationsRes,
    viewName: impactGroupId ? expandedImpactGroup : violationsGroupByNone,
  });
};
