import React, { useCallback, useMemo, useState } from "react";
import { ResourceTableModelRow } from "komodor-types";
import { datadogRum } from "@datadog/browser-rum";

import { dispatchEvent } from "../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import EventDrawer from "../ProcessList/EventDrawer";
import { useActiveAgent } from "../../../shared/hooks/useAgents";
import { CurrentService } from "../../../shared/hooks/useCurrentService";
import { KubernetesResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import EventGroup from "../EventGroup";
import { DELETED_POD_KIND } from "../../ResourceView/resources/deletedPod";
import { useDrawersStackStore } from "../../../shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "../../../shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "../../../shared/store/drawersStackStore/types";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ResourceListTableRow } from "./ResourceListTableRow";
import { Properties } from "./TableProperties";

export const IsDeletedPodField = "isDeletedPod";

const ResourceListTableBody: React.FC<{
  tableRows: ResourceTableModelRow[];
  currentService: CurrentService;
  headers: string[];
  resourceType: KubernetesResource;
  clusterName: string;
  properties: Properties;
  refreshResults: () => void;
  includeActionsColumn?: boolean;
}> = ({
  tableRows,
  headers,
  resourceType,
  clusterName,
  properties,
  currentService,
  refreshResults,
  includeActionsColumn = true,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const [correlatedEvent, setCorrelatedEvent] = useState<EventGroup | null>(
    null
  );

  const agentId = useActiveAgent(clusterName);

  const onRowClick = useCallback(
    (r: { [key: string]: string }) => {
      dispatchEvent(
        AnalyticEvents.Inspection.Inspection_resource_table_row_clicked,
        {
          resource: resourceType.NavBarName,
          status: r?.status,
          health: r?.health,
          [IsDeletedPodField]: !!r?.[IsDeletedPodField],
        }
      );
      if (r?.status || r?.health) {
        datadogRum.addAction(
          AnalyticEvents.Inspection.Inspection_resource_table_row_clicked,
          {
            resource: resourceType.NavBarName,
            status: r?.status,
            health: r?.health,
            name: r?.name,
          }
        );
      }

      if (properties.overrideRowClick) {
        properties.overrideRowClick(r);
        return;
      }

      let name = r["name"];
      const namespace = resourceType.Namespaced ? r["namespace"] : "";

      if (
        resourceType.NameInK8S === "storageclasses" &&
        name.includes("(default)")
      ) {
        name = name.slice(0, name.indexOf("(default)")).trim();
      }

      const resourceKind =
        r[IsDeletedPodField] === "true" ? DELETED_POD_KIND : resourceType.Kind;
      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByData,
        cluster: clusterName,
        namespace: namespace,
        resourceType: resourceKind,
        resourceName: name,
        buildPreloadResource: true,
        additionalData: {
          id: r.id,
          controlledBy: r.controlledBy,
          calculatedStatus: r.status,
        },
      });
    },
    [
      clusterName,
      properties,
      pushDrawer,
      resourceType.Kind,
      resourceType.NameInK8S,
      resourceType.Namespaced,
      resourceType.NavBarName,
    ]
  );

  const rows = useMemo(() => {
    return tableRows.map((row, idx) => (
      <ResourceListTableRow
        headers={headers}
        resourceType={resourceType}
        row={row}
        // we must create here unique key by combination of name and namespace because the order of the row can be change
        key={`${row.id ?? idx}`}
        id={`${row.id ?? idx}`}
        properties={properties}
        clusterName={clusterName}
        onRowClick={onRowClick}
        agentId={agentId}
        currentService={currentService}
        refreshResults={refreshResults}
        includeActionsCell={includeActionsColumn}
        setCorrelatedEvent={setCorrelatedEvent}
      />
    ));
  }, [
    agentId,
    clusterName,
    currentService,
    headers,
    includeActionsColumn,
    onRowClick,
    properties,
    refreshResults,
    resourceType,
    tableRows,
  ]);

  return (
    <>
      <tbody data-testid="table-rows">{rows}</tbody>
      {correlatedEvent && (
        <EventDrawer
          open={!!correlatedEvent}
          onClose={() => setCorrelatedEvent(null)}
          event={correlatedEvent}
        >
          {correlatedEvent?.renderEventDetails?.(() =>
            setCorrelatedEvent(null)
          ) ?? null}
        </EventDrawer>
      )}
    </>
  );
};

export default ResourceListTableBody;
