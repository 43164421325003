import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { EmptyState } from "../common/UpgradeAgentVersionAlert/EmptyState";
import { ARGO_WORKFLOWS } from "../routes/routes";

const Container = styled.div`
  height: 100vh;
  width: 100%;
`;

export const ArgoWorkflowNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <EmptyState
        title={"Workflow could not be found"}
        subtitle={
          "The workflow you're attempting to access might have been deleted, or it could simply be a broken link."
        }
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate(`/${ARGO_WORKFLOWS}`)}
        >
          View all Workflows
        </Button>
      </EmptyState>
    </Container>
  );
};
