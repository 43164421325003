import { muiColors } from "@komodorio/design-system";
import Chip from "@mui/material/Chip";
import { IconButton, Typography } from "@komodorio/design-system/deprecated";
import {
  Eye16,
  Pencil16,
  Plus16,
  Trash16,
} from "@komodorio/design-system/icons";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import { useSearchParams } from "react-router-dom";

import DataTable, { Column } from "../../common/table/DataTable";
import { ActionsCol, Buttons, CopyText, Header } from "../styles";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import { ButtonSupportFreemium } from "../../Freemium/Buttons";
import { restrictionsNames } from "../../Freemium/Restrictions";
import useIsFreeTier from "../../Freemium/useIsFreeTier";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useGetRbacPolicies } from "../../../shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicies";
import { RbacPolicy } from "../../../generated/auth";

import PolicyFormModal from "./policyForm/PolicyFormModal";
import DeleteRoleModal from "./DeletePolicyModal";
import { policyTagsToArray } from "./policyForm/PolicyTags";
import { SetStateFn } from "./policyForm/common";

import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";

const DEFAULT_ALLOW_ALL_RBAC_POLICY = "default-allow-all";
const isPolicyEditable = (policy: RbacPolicy) =>
  policy.name !== DEFAULT_ALLOW_ALL_RBAC_POLICY;

const PolicyTypeTitle = styled(Typography).attrs({
  variant: "uppercase",
  size: "small",
})``;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

const TagChip = styled(Chip)`
  && {
    border: 1px solid ${muiColors.blue[300]};
    background-color: ${muiColors.blue[50]};
    color: ${muiColors.blue[600]};
    font-size: 12px;
    font-weight: 500;
    height: 16px;
  }
`;

const buildRbacPolicyExtraTypesColumns = (
  showExtraPolicyTypes: boolean
): Column<RbacPolicy>[] => {
  if (!showExtraPolicyTypes) {
    return [];
  }

  return [
    {
      name: "Type",
      selector: (p: RbacPolicy) => (
        <PolicyTypeTitle>{p.type.replace("_", " ")}</PolicyTypeTitle>
      ),
    },
    {
      name: "Tags",
      selector: (p: RbacPolicy) => (
        <TagsContainer>
          {policyTagsToArray(p.tags).map((t) => (
            <TagChip size={"small"} key={t} label={t} />
          ))}
        </TagsContainer>
      ),
    },
  ];
};

const buildRbacPolicyColumns = (
  isFreeTier: boolean,
  setPolicyToDelete: SetStateFn<RbacPolicy>,
  setPolicyToEdit: SetStateFn<RbacPolicy>,
  showExtraPolicyTypes: boolean
): Column<RbacPolicy>[] => {
  return [
    { name: "Name", selector: (p: RbacPolicy) => p.name },
    ...buildRbacPolicyExtraTypesColumns(showExtraPolicyTypes),
    { name: "ID", selector: (p: RbacPolicy) => <CopyText text={p.id} /> },
    {
      name: "",
      selector: (p: RbacPolicy) => (
        <ActionsCol>
          {isPolicyEditable(p) && !isFreeTier && (
            <IconButton
              icon={Trash16}
              variant="danger"
              noBorder
              onClick={() => setPolicyToDelete(p)}
            />
          )}
          <IconButton
            icon={isPolicyEditable(p) && !isFreeTier ? Pencil16 : Eye16}
            noBorder
            onClick={() => setPolicyToEdit(p)}
          />
        </ActionsCol>
      ),
    },
  ];
};

const Policies: React.FC = () => {
  const { showExtraPolicyTypes } = useOverridableFlags();
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const { data: policies, refetch } = useGetRbacPolicies();

  const [openAddPolicy, setOpenAddPolicy] = useState(false);
  const [policyToEdit, setPolicyToEdit] = useState<RbacPolicy>();
  const [policyToDelete, setPolicyToDelete] = useState<RbacPolicy>();
  const isFreeTier = useIsFreeTier();

  const columns: Column<RbacPolicy>[] = buildRbacPolicyColumns(
    isFreeTier,
    setPolicyToDelete,
    setPolicyToEdit,
    showExtraPolicyTypes as boolean
  );

  const filteredPolicies = useMemo(() => {
    const lowerCaseFilterOrs = search.toLowerCase().split("|");

    return policies
      ? sortBy(
          policies.filter((p) => {
            return lowerCaseFilterOrs.some((filter) => {
              return p.name.toLowerCase().includes(filter);
            });
          }),
          (p) => p.name
        )
      : [];
  }, [policies, search]);

  useEffect(() => {
    if (searchParams.has("q")) {
      setSearch(searchParams.get("q") ?? "");
    }
  }, [searchParams, setSearch]);

  return (
    <SettingsViewLayoutWrapper title={"Policies"}>
      <Header>
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={search}
          showCloseIcon={true}
          ariaLabel="Search policies"
          onSearch={(value) => setSearch(value)}
        />
        <Buttons>
          <ButtonSupportFreemium
            variant="primary"
            icon={Plus16}
            onClick={() => setOpenAddPolicy(true)}
            freemiumKey={restrictionsNames.POLICIES}
          >
            Add policy
          </ButtonSupportFreemium>
        </Buttons>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredPolicies}
        columns={columns}
        getRowId={(u) => u.id}
        highlightedRows
      />
      {openAddPolicy && (
        <ValidationsProvider>
          <PolicyFormModal
            open
            handleClose={() => setOpenAddPolicy(false)}
            refreshPolicies={refetch}
          />
        </ValidationsProvider>
      )}
      {!!policyToEdit && (
        <ValidationsProvider>
          <PolicyFormModal
            open
            handleClose={() => setPolicyToEdit(undefined)}
            policy={policyToEdit}
            readOnly={!isPolicyEditable(policyToEdit) || isFreeTier}
            refreshPolicies={refetch}
          />
        </ValidationsProvider>
      )}
      {policyToDelete && (
        <DeleteRoleModal
          open
          handleClose={() => setPolicyToDelete(undefined)}
          policy={policyToDelete}
          refreshPolicies={refetch}
        />
      )}
    </SettingsViewLayoutWrapper>
  );
};

export default Policies;
