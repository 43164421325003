import Modal from "react-modal";
import React from "react";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const customModalStyles = {
  overlay: { zIndex: 10000, background: "rgb(0,0,0,0)" },
  content: {
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
  },
};

const ModalComponent: React.FC<{
  isOpen: boolean;
  handleCloseModal?: () => void;
  title: string;
  customStyle?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ isOpen, handleCloseModal, title, children, customStyle = {} }) => {
  customModalStyles.content = Object.assign(
    {},
    customModalStyles.content,
    customStyle || {}
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={customModalStyles}
      contentLabel={title}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
