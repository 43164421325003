import { ServiceIdentifierType } from "komodor-types";
import { isMatch } from "lodash";

import { notifyDDError } from "../../../../../shared/hooks/exceptionManagement";
import { ServiceInfo } from "../../../../../shared/types/ServiceInfo";
import { tryParseLabel } from "../utils/tryParseLabel";

type GetServiceMatchByIdentifierParams = {
  service: ServiceInfo;
  type: ServiceIdentifierType;
  identifier: string;
};

export const getServiceMatchByIdentifier = ({
  type,
  service,
  identifier,
}: GetServiceMatchByIdentifierParams): boolean => {
  switch (type) {
    case ServiceIdentifierType.SERVICE_ID:
      return service.id === identifier;
    case ServiceIdentifierType.NAMESPACE:
      return service.env === identifier;
    case ServiceIdentifierType.LABEL:
      try {
        const labelIdentifier = tryParseLabel(identifier);

        return service.labels
          ? isMatch(service.labels, labelIdentifier)
          : false;
      } catch (error) {
        notifyDDError(error as Error);

        return false;
      }
    /* TODO: add support for other type matchers */
  }
  return false;
};
