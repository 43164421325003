import { theme, palette } from "@komodorio/design-system";
import { IconButton, Tag } from "@komodorio/design-system/deprecated";
import {
  Eye16,
  Pencil16,
  Plus16,
  Trash16,
} from "@komodorio/design-system/icons";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";

import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import DataTable, { Column } from "../../common/table/DataTable";
import { ActionsCol, Buttons, CopyText, Flex, Header } from "../styles";
import { ButtonSupportFreemium } from "../../Freemium/Buttons";
import { restrictionsNames } from "../../Freemium/Restrictions";
import useIsFreeTier from "../../Freemium/useIsFreeTier";
import { useGetRbacRoles } from "../../../shared/hooks/auth-service/client/rbacRoles/useGetRbacRoles";
import { RbacRole } from "../../../generated/auth";

import DeleteRoleModal from "./DeleteRoleModal";
import RoleFormModal from "./RoleFormModal";

import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";

export const ACCOUNT_ADMIN_RBAC_ROLE = "account-admin";
const isRoleEditable = (role: RbacRole) =>
  role.name !== ACCOUNT_ADMIN_RBAC_ROLE;

const getPolicies = (r: RbacRole) => (
  <Flex>
    {r.rbacPolicies?.map((policy) => (
      <Tag key={policy.id} color={theme.background.bgGray}>
        {policy.name}
      </Tag>
    ))}
  </Flex>
);

const DefaultRoleTextStyle = styled.span`
  color: ${palette.gray[600]};
`;

const DefaultRoleText: React.FC<{ roleName: string; isDefault: boolean }> = ({
  roleName,
  isDefault,
}) => {
  return (
    <>
      {roleName}{" "}
      {isDefault && <DefaultRoleTextStyle>(default role)</DefaultRoleTextStyle>}
    </>
  );
};

const Roles: React.FC = () => {
  const [search, setSearch] = useState("");
  const { data: roles, refetch } = useGetRbacRoles();

  const [openAddRole, setOpenAddRole] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<RbacRole>();
  const [roleToDelete, setRoleToDelete] = useState<RbacRole>();
  const isFreeTier = useIsFreeTier();

  const columns: Column<RbacRole>[] = [
    {
      name: "Name",
      selector: (r) => (
        <DefaultRoleText roleName={r.name} isDefault={r.isDefault} />
      ),
    },
    { name: "Policies", selector: getPolicies },
    { name: "ID", selector: (r) => <CopyText text={r.id} /> },
    {
      name: "",
      selector: (r) => (
        <ActionsCol>
          {isRoleEditable(r) && !isFreeTier && (
            <IconButton
              icon={Trash16}
              variant="danger"
              noBorder
              onClick={() => setRoleToDelete(r)}
            />
          )}
          <IconButton
            icon={isRoleEditable(r) && !isFreeTier ? Pencil16 : Eye16}
            noBorder
            onClick={() => setRoleToEdit(r)}
          />
        </ActionsCol>
      ),
    },
  ];

  const filteredRoles = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return roles
      ? sortBy(
          roles.filter((r) => {
            return [r.name].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          (r) => r.name
        )
      : [];
  }, [roles, search]);

  return (
    <SettingsViewLayoutWrapper title={"Roles"}>
      <Header>
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={search}
          showCloseIcon={true}
          ariaLabel="Search roles"
          onSearch={(value) => setSearch(value)}
        />
        <Buttons>
          <ButtonSupportFreemium
            variant="primary"
            icon={Plus16}
            onClick={() => setOpenAddRole(true)}
            freemiumKey={restrictionsNames.ROLES}
          >
            Add role
          </ButtonSupportFreemium>
        </Buttons>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredRoles}
        columns={columns}
        getRowId={(r) => r.id}
        highlightedRows
      />
      {openAddRole && (
        <ValidationsProvider>
          <RoleFormModal
            open
            handleClose={() => setOpenAddRole(false)}
            refreshRoles={refetch}
          />
        </ValidationsProvider>
      )}
      {!!roleToEdit && (
        <ValidationsProvider>
          <RoleFormModal
            open
            handleClose={() => setRoleToEdit(undefined)}
            role={roleToEdit}
            refreshRoles={refetch}
            readOnly={!isRoleEditable(roleToEdit) || isFreeTier}
          />
        </ValidationsProvider>
      )}
      {roleToDelete && !roleToDelete.isDefault && (
        <DeleteRoleModal
          open
          handleClose={() => setRoleToDelete(undefined)}
          role={roleToDelete}
          refreshRoles={refetch}
        />
      )}
    </SettingsViewLayoutWrapper>
  );
};

export default Roles;
