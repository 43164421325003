import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid4 } from "uuid";
import styled from "styled-components";
import { Button } from "@komodorio/design-system/deprecated";

import { FormContainer } from "../CustomFormComponents";
import CustomIntegrationInstallation from "../CustomIntegrationInstallation";
import { getAppConfig } from "../../../../shared/config/appConfig";
import { contactMessage } from "../../../../constants";

import { useLaunchDarklyInstallation } from "./useLaunchDarklyInstallation";
import LaunchDarklyInstallationInstructions from "./LaunchDarklyInstallationInstructions";

const HelpText = styled.div`
  margin-top: 2.5rem;
  color: darkred;
`;

const LaunchDarklyInstallation: React.FC<{
  closeModalCallback: () => void;
}> = ({ closeModalCallback }) => {
  const { handleSubmit } = useForm();
  const installationId = useMemo(() => uuid4(), []);
  const [error, setError] = useState(false);
  const install = useLaunchDarklyInstallation();
  const appConfig = getAppConfig();

  const webhookPath = useMemo(() => {
    return appConfig.env === "production"
      ? "https://app.komodor.com/collector/launchdarkly/"
      : "https://staging.app.komodor.com/collector/launchdarkly/";
  }, [appConfig.env]);

  return (
    <CustomIntegrationInstallation name="LaunchDarkly">
      <LaunchDarklyInstallationInstructions
        webhookUrl={`${webhookPath}${installationId}`}
      />
      <br />
      <FormContainer>
        <Button
          variant="primary"
          onClick={handleSubmit(async () => {
            try {
              await install(installationId, `${webhookPath}${installationId}`);
            } catch (e) {
              setError(true);
              return;
            }
            closeModalCallback();
          })}
        >
          Install LaunchDarkly integration
        </Button>
      </FormContainer>
      {error && (
        <HelpText>
          Error while creating LaunchDarkly Integration.
          <br />
          {contactMessage}
        </HelpText>
      )}
    </CustomIntegrationInstallation>
  );
};

export default LaunchDarklyInstallation;
