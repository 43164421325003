/* tslint:disable */
/* eslint-disable */
/**
 * Unified Resources API
 * This is an API that serve Kubernetes resources
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'eventTime': string;
    /**
     * 
     * @type {object}
     * @memberof Action
     */
    'action'?: object;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'result'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Action
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'taskId': string;
    /**
     * 
     * @type {object}
     * @memberof Action
     */
    'metadata'?: object;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'userId'?: string;
}
        /**
 * 
 * @export
 * @interface AffectedService
 */
export interface AffectedService {
    /**
     * 
     * @type {string}
     * @memberof AffectedService
     */
    'komodorUid': string;
    /**
     * 
     * @type {number}
     * @memberof AffectedService
     */
    'terminatedPodsCount': number;
    /**
     * 
     * @type {string}
     * @memberof AffectedService
     */
    'healthState'?: string;
}
        /**
 * 
 * @export
 * @interface ApiV1ClustersAnnotationsGet200Response
 */
export interface ApiV1ClustersAnnotationsGet200Response {
    /**
     * 
     * @type {Array<object>}
     * @memberof ApiV1ClustersAnnotationsGet200Response
     */
    'annotations'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface ApiV1ClustersLabelsGet200Response
 */
export interface ApiV1ClustersLabelsGet200Response {
    /**
     * 
     * @type {Array<object>}
     * @memberof ApiV1ClustersLabelsGet200Response
     */
    'labels'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface ApiV1KomodorServicesRelatedResourcesPostRequest
 */
export interface ApiV1KomodorServicesRelatedResourcesPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1KomodorServicesRelatedResourcesPostRequest
     */
    'fields': Array<ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1KomodorServicesRelatedResourcesPostRequest
     */
    'komodorUids': Array<string>;
}

export const ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum = {
    ConfigMapUids: 'ConfigMapUids',
    SecretUids: 'SecretUids',
    PvcUids: 'PvcUids',
    HpaUids: 'HpaUids',
    K8sServicesUids: 'K8sServicesUids',
    IngressUids: 'IngressUids',
    NodesUids: 'NodesUids',
    PodUids: 'PodUids'
} as const;

export type ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum = typeof ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum[keyof typeof ApiV1KomodorServicesRelatedResourcesPostRequestFieldsEnum];

        /**
 * 
 * @export
 * @interface ApiV1KomodorServicesRelatedResourcesPostRequestAllOf
 */
export interface ApiV1KomodorServicesRelatedResourcesPostRequestAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiV1KomodorServicesRelatedResourcesPostRequestAllOf
     */
    'komodorUids': Array<string>;
}
        /**
 * 
 * @export
 * @interface ApiV1NodesTerminationImpactEventsPut200Response
 */
export interface ApiV1NodesTerminationImpactEventsPut200Response {
    /**
     * 
     * @type {number}
     * @memberof ApiV1NodesTerminationImpactEventsPut200Response
     */
    'rowsAffected'?: number;
}
        /**
 * 
 * @export
 * @interface ArgoRollout
 */
export interface ArgoRollout {
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'upToDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'available'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoRollout
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface ArgoRolloutsStateResponse
 */
export interface ArgoRolloutsStateResponse {
    /**
     * 
     * @type {ArgoRolloutsStateResponseAllOfData}
     * @memberof ArgoRolloutsStateResponse
     */
    'data'?: ArgoRolloutsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface ArgoRolloutsStateResponseAllOf
 */
export interface ArgoRolloutsStateResponseAllOf {
    /**
     * 
     * @type {ArgoRolloutsStateResponseAllOfData}
     * @memberof ArgoRolloutsStateResponseAllOf
     */
    'data'?: ArgoRolloutsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface ArgoRolloutsStateResponseAllOfData
 */
export interface ArgoRolloutsStateResponseAllOfData {
    /**
     * 
     * @type {Array<ArgoRollout>}
     * @memberof ArgoRolloutsStateResponseAllOfData
     */
    'wide'?: Array<ArgoRollout>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ArgoRolloutsStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsClustersResponse
 */
export interface ArgoWorkflowsClustersResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ArgoWorkflowsClustersResponse
     */
    'data': Array<string>;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsCronWorkflowEventResponse
 */
export interface ArgoWorkflowsCronWorkflowEventResponse {
    /**
     * 
     * @type {Array<ArgoWorkflowsCronWorkflowEventResponseDataInner>}
     * @memberof ArgoWorkflowsCronWorkflowEventResponse
     */
    'data': Array<ArgoWorkflowsCronWorkflowEventResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsCronWorkflowEventResponseDataInner
 */
export interface ArgoWorkflowsCronWorkflowEventResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'schedule'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'suspend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'status'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'newObj'?: object;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsCronWorkflowEventResponseDataInner
     */
    'oldObj'?: object;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsWorkflowEventResponse
 */
export interface ArgoWorkflowsWorkflowEventResponse {
    /**
     * 
     * @type {Array<ArgoWorkflowsWorkflowEventResponseDataInner>}
     * @memberof ArgoWorkflowsWorkflowEventResponse
     */
    'data': Array<ArgoWorkflowsWorkflowEventResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsWorkflowEventResponseDataInner
 */
export interface ArgoWorkflowsWorkflowEventResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'ownerUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'selector'?: object;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'templateId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'isStandalone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'isRetry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'status'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'newObj'?: object;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsWorkflowEventResponseDataInner
     */
    'oldObj'?: object;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsWorkflowTemplateEventResponse
 */
export interface ArgoWorkflowsWorkflowTemplateEventResponse {
    /**
     * 
     * @type {Array<ArgoWorkflowsWorkflowTemplateEventResponseDataInner>}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponse
     */
    'data': Array<ArgoWorkflowsWorkflowTemplateEventResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface ArgoWorkflowsWorkflowTemplateEventResponseDataInner
 */
export interface ArgoWorkflowsWorkflowTemplateEventResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'scope'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'newObj'?: object;
    /**
     * 
     * @type {object}
     * @memberof ArgoWorkflowsWorkflowTemplateEventResponseDataInner
     */
    'oldObj'?: object;
}
        /**
 * 
 * @export
 * @interface AttachedVolume
 */
export interface AttachedVolume {
    /**
     * 
     * @type {string}
     * @memberof AttachedVolume
     */
    'devicePath': string;
    /**
     * 
     * @type {string}
     * @memberof AttachedVolume
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface ConfigChange
 */
export interface ConfigChange {
    /**
     * 
     * @type {object}
     * @memberof ConfigChange
     */
    'newConfig'?: object;
    /**
     * 
     * @type {object}
     * @memberof ConfigChange
     */
    'oldConfig'?: object;
    /**
     * 
     * @type {string}
     * @memberof ConfigChange
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigChange
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigChange
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigChange
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigChange
     */
    'namespace'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigChange
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigChange
     */
    'updatedAt'?: string;
}
        /**
 * 
 * @export
 * @interface ConfigMap
 */
export interface ConfigMap {
    /**
     * 
     * @type {string}
     * @memberof ConfigMap
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMap
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMap
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMap
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface ConfigMapEventsResponse
 */
export interface ConfigMapEventsResponse {
    /**
     * 
     * @type {Array<ConfigMapEventsResponseDataInner>}
     * @memberof ConfigMapEventsResponse
     */
    'data': Array<ConfigMapEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface ConfigMapEventsResponseDataInner
 */
export interface ConfigMapEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'configMapName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'k8sDefault'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'newData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ConfigMapEventsResponseDataInner
     */
    'oldData'?: object;
}
        /**
 * 
 * @export
 * @interface ConfigMapNodeConfigSource
 */
export interface ConfigMapNodeConfigSource {
    /**
     * 
     * @type {string}
     * @memberof ConfigMapNodeConfigSource
     */
    'kubeletConfigKey': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapNodeConfigSource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapNodeConfigSource
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapNodeConfigSource
     */
    'resourceVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigMapNodeConfigSource
     */
    'uid'?: string;
}
        /**
 * 
 * @export
 * @interface ConfigMapsStateResponse
 */
export interface ConfigMapsStateResponse {
    /**
     * 
     * @type {Array<ConfigMapsStateResponseAllOfData>}
     * @memberof ConfigMapsStateResponse
     */
    'data'?: Array<ConfigMapsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface ConfigMapsStateResponseAllOf
 */
export interface ConfigMapsStateResponseAllOf {
    /**
     * 
     * @type {Array<ConfigMapsStateResponseAllOfData>}
     * @memberof ConfigMapsStateResponseAllOf
     */
    'data'?: Array<ConfigMapsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface ConfigMapsStateResponseAllOfData
 */
export interface ConfigMapsStateResponseAllOfData {
    /**
     * 
     * @type {ConfigMap}
     * @memberof ConfigMapsStateResponseAllOfData
     */
    'wide'?: ConfigMap;
    /**
     * 
     * @type {object}
     * @memberof ConfigMapsStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface ContainerImage
 */
export interface ContainerImage {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerImage
     */
    'names'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ContainerImage
     */
    'sizeBytes'?: number;
}
        /**
 * 
 * @export
 * @interface ContainerRunningState
 */
export interface ContainerRunningState {
    /**
     * 
     * @type {string}
     * @memberof ContainerRunningState
     */
    'startedAt': string;
}
        /**
 * 
 * @export
 * @interface ContainerState
 */
export interface ContainerState {
    /**
     * 
     * @type {ContainerTerminatedState}
     * @memberof ContainerState
     */
    'terminated'?: ContainerTerminatedState;
    /**
     * 
     * @type {ContainerRunningState}
     * @memberof ContainerState
     */
    'running'?: ContainerRunningState;
    /**
     * 
     * @type {ContainerWaitingState}
     * @memberof ContainerState
     */
    'waiting'?: ContainerWaitingState;
}
        /**
 * 
 * @export
 * @interface ContainerStatus
 */
export interface ContainerStatus {
    /**
     * 
     * @type {string}
     * @memberof ContainerStatus
     */
    'containerID': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerStatus
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerStatus
     */
    'imageID': string;
    /**
     * 
     * @type {ContainerState}
     * @memberof ContainerStatus
     */
    'lastState': ContainerState;
    /**
     * 
     * @type {string}
     * @memberof ContainerStatus
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerStatus
     */
    'ready': boolean;
    /**
     * 
     * @type {number}
     * @memberof ContainerStatus
     */
    'restartCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerStatus
     */
    'started': boolean;
    /**
     * 
     * @type {ContainerState}
     * @memberof ContainerStatus
     */
    'state': ContainerState;
}
        /**
 * 
 * @export
 * @interface ContainerTerminatedState
 */
export interface ContainerTerminatedState {
    /**
     * 
     * @type {string}
     * @memberof ContainerTerminatedState
     */
    'containerID': string;
    /**
     * 
     * @type {number}
     * @memberof ContainerTerminatedState
     */
    'exitCode': number;
    /**
     * 
     * @type {string}
     * @memberof ContainerTerminatedState
     */
    'finishedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerTerminatedState
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerTerminatedState
     */
    'startedAt': string;
}
        /**
 * 
 * @export
 * @interface ContainerWaitingState
 */
export interface ContainerWaitingState {
    /**
     * 
     * @type {string}
     * @memberof ContainerWaitingState
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerWaitingState
     */
    'reason': string;
}
        /**
 * 
 * @export
 * @interface CrdRequest
 */
export interface CrdRequest {
    /**
     * 
     * @type {string}
     * @memberof CrdRequest
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof CrdRequest
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof CrdRequest
     */
    'namespace'?: string;
}
        /**
 * 
 * @export
 * @interface CrdResponse
 */
export interface CrdResponse {
    /**
     * 
     * @type {Array<CrdResponseAllOfData>}
     * @memberof CrdResponse
     */
    'data'?: Array<CrdResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface CrdResponseAllOf
 */
export interface CrdResponseAllOf {
    /**
     * 
     * @type {Array<CrdResponseAllOfData>}
     * @memberof CrdResponseAllOf
     */
    'data'?: Array<CrdResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface CrdResponseAllOfData
 */
export interface CrdResponseAllOfData {
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'age'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'creationTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'labels'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'resource'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'scope'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrdResponseAllOfData
     */
    'version'?: string;
}
        /**
 * 
 * @export
 * @interface CreateHelmRepoRequest
 */
export interface CreateHelmRepoRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateHelmRepoRequest
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHelmRepoRequest
     */
    'repoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHelmRepoRequest
     */
    'repoUrl'?: string;
}
        /**
 * 
 * @export
 * @interface CronJob
 */
export interface CronJob {
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'active'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'suspend'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJob
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface CronJobAllOf
 */
export interface CronJobAllOf {
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'active'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'suspend'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof CronJobAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface CronJobEventsResponse
 */
export interface CronJobEventsResponse {
    /**
     * 
     * @type {Array<CronJobEventsResponseDataInner>}
     * @memberof CronJobEventsResponse
     */
    'data': Array<CronJobEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface CronJobEventsResponseDataInner
 */
export interface CronJobEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof CronJobEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof CronJobEventsResponseDataInner
     */
    'newObj'?: object;
}
        /**
 * 
 * @export
 * @interface CronJobsStateResponse
 */
export interface CronJobsStateResponse {
    /**
     * 
     * @type {Array<CronJobsStateResponseAllOfData>}
     * @memberof CronJobsStateResponse
     */
    'data'?: Array<CronJobsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface CronJobsStateResponseAllOf
 */
export interface CronJobsStateResponseAllOf {
    /**
     * 
     * @type {Array<CronJobsStateResponseAllOfData>}
     * @memberof CronJobsStateResponseAllOf
     */
    'data'?: Array<CronJobsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface CronJobsStateResponseAllOfData
 */
export interface CronJobsStateResponseAllOfData {
    /**
     * 
     * @type {CronJob}
     * @memberof CronJobsStateResponseAllOfData
     */
    'wide'?: CronJob;
    /**
     * 
     * @type {object}
     * @memberof CronJobsStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface CustomEventResponse
 */
export interface CustomEventResponse {
    /**
     * 
     * @type {Array<CustomEventResponseDataInner>}
     * @memberof CustomEventResponse
     */
    'data': Array<CustomEventResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface CustomEventResponseDataInner
 */
export interface CustomEventResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof CustomEventResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomEventResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomEventResponseDataInner
     */
    'servicesNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomEventResponseDataInner
     */
    'clusters'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomEventResponseDataInner
     */
    'namespaces'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomEventResponseDataInner
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomEventResponseDataInner
     */
    'summary'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CustomEventResponseDataInner
     */
    'details'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CustomEventResponseDataInner
     */
    'severity'?: CustomEventResponseDataInnerSeverityEnum;
}

export const CustomEventResponseDataInnerSeverityEnum = {
    Information: 'information',
    Warning: 'warning',
    Error: 'error'
} as const;

export type CustomEventResponseDataInnerSeverityEnum = typeof CustomEventResponseDataInnerSeverityEnum[keyof typeof CustomEventResponseDataInnerSeverityEnum];

        /**
 * 
 * @export
 * @interface CustomResource
 */
export interface CustomResource {
    /**
     * 
     * @type {string}
     * @memberof CustomResource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomResource
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomResource
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResource
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface CustomResourceAllOf
 */
export interface CustomResourceAllOf {
    /**
     * 
     * @type {string}
     * @memberof CustomResourceAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface CustomResourceRequest
 */
export interface CustomResourceRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomResourceRequest
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceRequest
     */
    'customResourceDefenitionKind'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceRequest
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceRequest
     */
    'cluster'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomResourceRequest
     */
    'namespace'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomResourceRequest
     */
    'fields'?: Array<CustomResourceRequestFieldsEnum>;
}

export const CustomResourceRequestFieldsEnum = {
    Wide: 'wide',
    Json: 'json'
} as const;

export type CustomResourceRequestFieldsEnum = typeof CustomResourceRequestFieldsEnum[keyof typeof CustomResourceRequestFieldsEnum];

        /**
 * 
 * @export
 * @interface CustomResourcesResponse
 */
export interface CustomResourcesResponse {
    /**
     * 
     * @type {CustomResourcesResponseAllOfData}
     * @memberof CustomResourcesResponse
     */
    'data'?: CustomResourcesResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface CustomResourcesResponseAllOf
 */
export interface CustomResourcesResponseAllOf {
    /**
     * 
     * @type {CustomResourcesResponseAllOfData}
     * @memberof CustomResourcesResponseAllOf
     */
    'data'?: CustomResourcesResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface CustomResourcesResponseAllOfData
 */
export interface CustomResourcesResponseAllOfData {
    /**
     * 
     * @type {Array<object>}
     * @memberof CustomResourcesResponseAllOfData
     */
    'wide'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof CustomResourcesResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface DaemonSet
 */
export interface DaemonSet {
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'labels'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'desired'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'current'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'upToDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'available'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSet
     */
    'node selector'?: string;
}
        /**
 * 
 * @export
 * @interface DaemonSetAllOf
 */
export interface DaemonSetAllOf {
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'labels'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'desired'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'current'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'upToDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'available'?: string;
    /**
     * 
     * @type {string}
     * @memberof DaemonSetAllOf
     */
    'node selector'?: string;
}
        /**
 * 
 * @export
 * @interface DaemonSetsStateResponse
 */
export interface DaemonSetsStateResponse {
    /**
     * 
     * @type {DaemonSetsStateResponseAllOfData}
     * @memberof DaemonSetsStateResponse
     */
    'data'?: DaemonSetsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface DaemonSetsStateResponseAllOf
 */
export interface DaemonSetsStateResponseAllOf {
    /**
     * 
     * @type {DaemonSetsStateResponseAllOfData}
     * @memberof DaemonSetsStateResponseAllOf
     */
    'data'?: DaemonSetsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface DaemonSetsStateResponseAllOfData
 */
export interface DaemonSetsStateResponseAllOfData {
    /**
     * 
     * @type {Array<DaemonSet>}
     * @memberof DaemonSetsStateResponseAllOfData
     */
    'wide'?: Array<DaemonSet>;
    /**
     * 
     * @type {Array<object>}
     * @memberof DaemonSetsStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface DatadogData
 */
export interface DatadogData {
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'datadogEnv'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'datadogServiceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'datadogVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatadogData
     */
    'eventTime'?: string;
}
        /**
 * 
 * @export
 * @interface DeleteHelmRepoRequest
 */
export interface DeleteHelmRepoRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteHelmRepoRequest
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHelmRepoRequest
     */
    'repoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteHelmRepoRequest
     */
    'repoUrl'?: string;
}
        /**
 * 
 * @export
 * @interface DeletedPodsResponse
 */
export interface DeletedPodsResponse {
    /**
     * 
     * @type {Array<DeletedPodsResponseDataInner>}
     * @memberof DeletedPodsResponse
     */
    'data': Array<DeletedPodsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface DeletedPodsResponseDataInner
 */
export interface DeletedPodsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'nodeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'podName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {object}
     * @memberof DeletedPodsResponseDataInner
     */
    'labels'?: object;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'phase'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'statusReason'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof DeletedPodsResponseDataInner
     */
    'containerStatuses'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'resourceOwnerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'readinessGates'?: string;
    /**
     * 
     * @type {object}
     * @memberof DeletedPodsResponseDataInner
     */
    'resourcesLimits'?: object;
    /**
     * 
     * @type {object}
     * @memberof DeletedPodsResponseDataInner
     */
    'resourcesRequests'?: object;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedPodsResponseDataInner
     */
    'ip'?: string;
    /**
     * 
     * @type {object}
     * @memberof DeletedPodsResponseDataInner
     */
    'metadata'?: object;
    /**
     * 
     * @type {object}
     * @memberof DeletedPodsResponseDataInner
     */
    'status'?: object;
    /**
     * 
     * @type {object}
     * @memberof DeletedPodsResponseDataInner
     */
    'spec'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeletedPodsResponseDataInner
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface Deploy
 */
export interface Deploy {
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'endEventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'deploymentName': string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'versionFrom': string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'versionTo': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Deploy
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof Deploy
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof Deploy
     */
    'oldSpec'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof Deploy
     */
    'kubernetesConfigChanges'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Deploy
     */
    'deploymentDiffKeys'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Deploy
     */
    'gitCompare': Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof Deploy
     */
    'isEmptyDeployment': boolean;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'statusMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'statusReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'changeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Deploy
     */
    'generation'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Deploy
     */
    'trackedFiles'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'failed_container'?: string;
    /**
     * 
     * @type {object}
     * @memberof Deploy
     */
    'strategy'?: object;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deploy
     */
    'namespace': string;
}
        /**
 * 
 * @export
 * @interface DeployEventsResponse
 */
export interface DeployEventsResponse {
    /**
     * 
     * @type {Array<Deploy>}
     * @memberof DeployEventsResponse
     */
    'data': Array<Deploy>;
}
        /**
 * 
 * @export
 * @interface DeployState
 */
export interface DeployState {
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'lastDeployEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'lastDeployStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeployState
     */
    'readyReplicas'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeployState
     */
    'desiredReplicas'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployState
     */
    'deploymentStatus'?: string;
}
        /**
 * 
 * @export
 * @interface Deployment
 */
export interface Deployment {
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'upToDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'available'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deployment
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface DeploymentAllOf
 */
export interface DeploymentAllOf {
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'upToDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'available'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface DeploymentsStateResponse
 */
export interface DeploymentsStateResponse {
    /**
     * 
     * @type {DeploymentsStateResponseAllOfData}
     * @memberof DeploymentsStateResponse
     */
    'data'?: DeploymentsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface DeploymentsStateResponseAllOf
 */
export interface DeploymentsStateResponseAllOf {
    /**
     * 
     * @type {DeploymentsStateResponseAllOfData}
     * @memberof DeploymentsStateResponseAllOf
     */
    'data'?: DeploymentsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface DeploymentsStateResponseAllOfData
 */
export interface DeploymentsStateResponseAllOfData {
    /**
     * 
     * @type {Array<Deployment>}
     * @memberof DeploymentsStateResponseAllOfData
     */
    'wide'?: Array<Deployment>;
    /**
     * 
     * @type {Array<object>}
     * @memberof DeploymentsStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface Endpoint
 */
export interface Endpoint {
    /**
     * 
     * @type {string}
     * @memberof Endpoint
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Endpoint
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Endpoint
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof Endpoint
     */
    'endpoints'?: string;
    /**
     * 
     * @type {string}
     * @memberof Endpoint
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface EndpointAllOf
 */
export interface EndpointAllOf {
    /**
     * 
     * @type {string}
     * @memberof EndpointAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointAllOf
     */
    'endpoints'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface EndpointEventsResponse
 */
export interface EndpointEventsResponse {
    /**
     * 
     * @type {Array<EndpointEventsResponseDataInner>}
     * @memberof EndpointEventsResponse
     */
    'data': Array<EndpointEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface EndpointEventsResponseDataInner
 */
export interface EndpointEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'endpointName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EndpointEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EndpointEventsResponseDataInner
     */
    'podNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EndpointEventsResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof EndpointEventsResponseDataInner
     */
    'subsets'?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof EndpointEventsResponseDataInner
     */
    'metadata'?: object;
}
        /**
 * 
 * @export
 * @interface EndpointSlice
 */
export interface EndpointSlice {
    /**
     * 
     * @type {string}
     * @memberof EndpointSlice
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSlice
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSlice
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSlice
     */
    'endpoints'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSlice
     */
    'addresstype'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSlice
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface EndpointSliceAllOf
 */
export interface EndpointSliceAllOf {
    /**
     * 
     * @type {string}
     * @memberof EndpointSliceAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSliceAllOf
     */
    'endpoints'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSliceAllOf
     */
    'addresstype'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointSliceAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface EndpointSlicesStateResponse
 */
export interface EndpointSlicesStateResponse {
    /**
     * 
     * @type {Array<EndpointSlicesStateResponseAllOfData>}
     * @memberof EndpointSlicesStateResponse
     */
    'data'?: Array<EndpointSlicesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface EndpointSlicesStateResponseAllOf
 */
export interface EndpointSlicesStateResponseAllOf {
    /**
     * 
     * @type {Array<EndpointSlicesStateResponseAllOfData>}
     * @memberof EndpointSlicesStateResponseAllOf
     */
    'data'?: Array<EndpointSlicesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface EndpointSlicesStateResponseAllOfData
 */
export interface EndpointSlicesStateResponseAllOfData {
    /**
     * 
     * @type {EndpointSlice}
     * @memberof EndpointSlicesStateResponseAllOfData
     */
    'wide'?: EndpointSlice;
    /**
     * 
     * @type {object}
     * @memberof EndpointSlicesStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface EndpointsStateResponse
 */
export interface EndpointsStateResponse {
    /**
     * 
     * @type {Array<EndpointsStateResponseAllOfData>}
     * @memberof EndpointsStateResponse
     */
    'data'?: Array<EndpointsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface EndpointsStateResponseAllOf
 */
export interface EndpointsStateResponseAllOf {
    /**
     * 
     * @type {Array<EndpointsStateResponseAllOfData>}
     * @memberof EndpointsStateResponseAllOf
     */
    'data'?: Array<EndpointsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface EndpointsStateResponseAllOfData
 */
export interface EndpointsStateResponseAllOfData {
    /**
     * 
     * @type {Endpoint}
     * @memberof EndpointsStateResponseAllOfData
     */
    'wide'?: Endpoint;
    /**
     * 
     * @type {object}
     * @memberof EndpointsStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface ExternalLinksResponse
 */
export interface ExternalLinksResponse {
    /**
     * 
     * @type {Array<ExternalLinksResponseDataInner>}
     * @memberof ExternalLinksResponse
     */
    'data': Array<ExternalLinksResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface ExternalLinksResponseDataInner
 */
export interface ExternalLinksResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'serviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLinksResponseDataInner
     */
    'externalLinkContextValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalLinksResponseDataInner
     */
    'entireAccount'?: boolean;
}
        /**
 * 
 * @export
 * @interface GeneralEventsResponse
 */
export interface GeneralEventsResponse {
    /**
     * 
     * @type {Array<KomodorAlert>}
     * @memberof GeneralEventsResponse
     */
    'event_komodor_alert'?: Array<KomodorAlert>;
    /**
     * 
     * @type {Array<SentryIssue>}
     * @memberof GeneralEventsResponse
     */
    'event_sentry_issue'?: Array<SentryIssue>;
    /**
     * 
     * @type {Array<Pagerduty>}
     * @memberof GeneralEventsResponse
     */
    'event_pagerduty'?: Array<Pagerduty>;
    /**
     * 
     * @type {Array<Deploy>}
     * @memberof GeneralEventsResponse
     */
    'event_deploy'?: Array<Deploy>;
    /**
     * 
     * @type {Array<Topology>}
     * @memberof GeneralEventsResponse
     */
    'event_topology'?: Array<Topology>;
    /**
     * 
     * @type {Array<SecretEventBase>}
     * @memberof GeneralEventsResponse
     */
    'event_secret'?: Array<SecretEventBase>;
    /**
     * 
     * @type {Array<Launchdarkly>}
     * @memberof GeneralEventsResponse
     */
    'event_launchdarkly'?: Array<Launchdarkly>;
    /**
     * 
     * @type {Array<Action>}
     * @memberof GeneralEventsResponse
     */
    'event_actions'?: Array<Action>;
    /**
     * 
     * @type {Array<LimitRangeEventBase>}
     * @memberof GeneralEventsResponse
     */
    'event_limit_ranges'?: Array<LimitRangeEventBase>;
    /**
     * 
     * @type {Array<NetworkPolicyEventBaseInner>}
     * @memberof GeneralEventsResponse
     */
    'event_network_policies'?: Array<NetworkPolicyEventBaseInner>;
    /**
     * 
     * @type {Array<K8sServiceEventBase>}
     * @memberof GeneralEventsResponse
     */
    'event_kubernetes_service_resource'?: Array<K8sServiceEventBase>;
    /**
     * 
     * @type {Array<IngressBaseEvent>}
     * @memberof GeneralEventsResponse
     */
    'event_ingress'?: Array<IngressBaseEvent>;
    /**
     * 
     * @type {Array<ResourceQuotaBaseEvent>}
     * @memberof GeneralEventsResponse
     */
    'event_resource_quota'?: Array<ResourceQuotaBaseEvent>;
}
        /**
 * 
 * @export
 * @interface GroupedPodEventsRequest
 */
export interface GroupedPodEventsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupedPodEventsRequest
     */
    'komodorUids': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GroupedPodEventsRequest
     */
    'fromEpoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupedPodEventsRequest
     */
    'toEpoch'?: number;
}
        /**
 * 
 * @export
 * @interface GroupedPodEventsResponse
 */
export interface GroupedPodEventsResponse {
    /**
     * 
     * @type {Array<PodGroups>}
     * @memberof GroupedPodEventsResponse
     */
    'data'?: Array<PodGroups>;
}
        /**
 * 
 * @export
 * @interface HealthState
 */
export interface HealthState {
    /**
     * 
     * @type {string}
     * @memberof HealthState
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthState
     */
    'healthStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthState
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthState
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthState
     */
    'eventTime'?: string;
}
        /**
 * 
 * @export
 * @interface HelmChart
 */
export interface HelmChart {
    /**
     * 
     * @type {string}
     * @memberof HelmChart
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChart
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChart
     */
    'schema'?: string;
    /**
     * 
     * @type {Array<HelmTemplate>}
     * @memberof HelmChart
     */
    'templates'?: Array<HelmTemplate>;
    /**
     * 
     * @type {Array<HelmFile>}
     * @memberof HelmChart
     */
    'files'?: Array<HelmFile>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof HelmChart
     */
    'values'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof HelmChart
     */
    'metadata'?: { [key: string]: object; };
}
        /**
 * 
 * @export
 * @interface HelmChartInfo
 */
export interface HelmChartInfo {
    /**
     * 
     * @type {string}
     * @memberof HelmChartInfo
     */
    'secretId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartInfo
     */
    'cluster'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartInfo
     */
    'manifest'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof HelmChartInfo
     */
    'parsedManifest'?: Array<object>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof HelmChartInfo
     */
    'values'?: { [key: string]: object; } | null;
    /**
     * 
     * @type {string}
     * @memberof HelmChartInfo
     */
    'notes'?: string;
}
        /**
 * 
 * @export
 * @interface HelmChartResponse
 */
export interface HelmChartResponse {
    /**
     * 
     * @type {string}
     * @memberof HelmChartResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartResponse
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartResponse
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartResponse
     */
    'action': string;
    /**
     * 
     * @type {HelmChartSummary}
     * @memberof HelmChartResponse
     */
    'helmSummary': HelmChartSummary;
    /**
     * 
     * @type {HelmRelease}
     * @memberof HelmChartResponse
     */
    'helmData': HelmRelease;
}
        /**
 * 
 * @export
 * @interface HelmChartSummary
 */
export interface HelmChartSummary {
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'secretId': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'revision'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'lastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof HelmChartSummary
     */
    'action': string;
}
        /**
 * 
 * @export
 * @interface HelmData
 */
export interface HelmData {
    /**
     * 
     * @type {HelmDataRelease}
     * @memberof HelmData
     */
    'data'?: HelmDataRelease;
    /**
     * 
     * @type {string}
     * @memberof HelmData
     */
    'kind'?: HelmDataKindEnum;
    /**
     * 
     * @type {string}
     * @memberof HelmData
     */
    'type'?: HelmDataTypeEnum;
    /**
     * 
     * @type {HelmDataMetadata}
     * @memberof HelmData
     */
    'metadata'?: HelmDataMetadata;
}

export const HelmDataKindEnum = {
    Secret: 'Secret'
} as const;

export type HelmDataKindEnum = typeof HelmDataKindEnum[keyof typeof HelmDataKindEnum];
export const HelmDataTypeEnum = {
    HelmShReleaseV1: 'helm.sh/release.v1'
} as const;

export type HelmDataTypeEnum = typeof HelmDataTypeEnum[keyof typeof HelmDataTypeEnum];

        /**
 * 
 * @export
 * @interface HelmDataLabels
 */
export interface HelmDataLabels {
    /**
     * 
     * @type {string}
     * @memberof HelmDataLabels
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmDataLabels
     */
    'owner'?: HelmDataLabelsOwnerEnum;
    /**
     * 
     * @type {string}
     * @memberof HelmDataLabels
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmDataLabels
     */
    'version'?: string;
}

export const HelmDataLabelsOwnerEnum = {
    Helm: 'helm'
} as const;

export type HelmDataLabelsOwnerEnum = typeof HelmDataLabelsOwnerEnum[keyof typeof HelmDataLabelsOwnerEnum];

        /**
 * 
 * @export
 * @interface HelmDataMetadata
 */
export interface HelmDataMetadata {
    /**
     * 
     * @type {string}
     * @memberof HelmDataMetadata
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmDataMetadata
     */
    'name'?: string;
    /**
     * 
     * @type {HelmDataLabels}
     * @memberof HelmDataMetadata
     */
    'labels'?: HelmDataLabels;
    /**
     * 
     * @type {string}
     * @memberof HelmDataMetadata
     */
    'namespace'?: string;
}
        /**
 * 
 * @export
 * @interface HelmDataRelease
 */
export interface HelmDataRelease {
    /**
     * 
     * @type {HelmRelease}
     * @memberof HelmDataRelease
     */
    'release'?: HelmRelease;
}
        /**
 * 
 * @export
 * @interface HelmFile
 */
export interface HelmFile {
    /**
     * 
     * @type {string}
     * @memberof HelmFile
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmFile
     */
    'data'?: string;
}
        /**
 * 
 * @export
 * @interface HelmManifest
 */
export interface HelmManifest {
    /**
     * 
     * @type {string}
     * @memberof HelmManifest
     */
    'apiVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmManifest
     */
    'kind'?: string;
    /**
     * 
     * @type {HelmManifestMetadata}
     * @memberof HelmManifest
     */
    'metadata'?: HelmManifestMetadata;
}
        /**
 * 
 * @export
 * @interface HelmManifestMetadata
 */
export interface HelmManifestMetadata {
    /**
     * 
     * @type {string}
     * @memberof HelmManifestMetadata
     */
    'name'?: string;
}
        /**
 * 
 * @export
 * @interface HelmRelease
 */
export interface HelmRelease {
    /**
     * 
     * @type {string}
     * @memberof HelmRelease
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof HelmRelease
     */
    'secretId': string;
    /**
     * 
     * @type {string}
     * @memberof HelmRelease
     */
    'name': string;
    /**
     * 
     * @type {HelmReleaseInfo}
     * @memberof HelmRelease
     */
    'info': HelmReleaseInfo;
    /**
     * 
     * @type {string}
     * @memberof HelmRelease
     */
    'manifest': string;
    /**
     * 
     * @type {number}
     * @memberof HelmRelease
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof HelmRelease
     */
    'namespace': string;
    /**
     * 
     * @type {HelmChart}
     * @memberof HelmRelease
     */
    'chart': HelmChart;
}
        /**
 * 
 * @export
 * @interface HelmReleaseInfo
 */
export interface HelmReleaseInfo {
    /**
     * 
     * @type {string}
     * @memberof HelmReleaseInfo
     */
    'first_deployed'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmReleaseInfo
     */
    'last_deployed'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmReleaseInfo
     */
    'deleted'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmReleaseInfo
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmReleaseInfo
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmReleaseInfo
     */
    'notes'?: string;
}
        /**
 * 
 * @export
 * @interface HelmRepo
 */
export interface HelmRepo {
    /**
     * 
     * @type {string}
     * @memberof HelmRepo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmRepo
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmRepo
     */
    'repoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmRepo
     */
    'repoUrl'?: string;
}
        /**
 * 
 * @export
 * @interface HelmTemplate
 */
export interface HelmTemplate {
    /**
     * 
     * @type {string}
     * @memberof HelmTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HelmTemplate
     */
    'data'?: string;
}
        /**
 * 
 * @export
 * @interface HorizontalPodAutoscaler
 */
export interface HorizontalPodAutoscaler {
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'maxpods'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'minpods'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'replicas'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscaler
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface HorizontalPodAutoscalerAllOf
 */
export interface HorizontalPodAutoscalerAllOf {
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscalerAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscalerAllOf
     */
    'maxpods'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscalerAllOf
     */
    'minpods'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscalerAllOf
     */
    'replicas'?: string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalPodAutoscalerAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface HorizontalPodAutoscalerStateResponse
 */
export interface HorizontalPodAutoscalerStateResponse {
    /**
     * 
     * @type {HorizontalPodAutoscalerStateResponseAllOfData}
     * @memberof HorizontalPodAutoscalerStateResponse
     */
    'data'?: HorizontalPodAutoscalerStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface HorizontalPodAutoscalerStateResponseAllOf
 */
export interface HorizontalPodAutoscalerStateResponseAllOf {
    /**
     * 
     * @type {HorizontalPodAutoscalerStateResponseAllOfData}
     * @memberof HorizontalPodAutoscalerStateResponseAllOf
     */
    'data'?: HorizontalPodAutoscalerStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface HorizontalPodAutoscalerStateResponseAllOfData
 */
export interface HorizontalPodAutoscalerStateResponseAllOfData {
    /**
     * 
     * @type {Array<HorizontalPodAutoscaler>}
     * @memberof HorizontalPodAutoscalerStateResponseAllOfData
     */
    'wide'?: Array<HorizontalPodAutoscaler>;
    /**
     * 
     * @type {Array<object>}
     * @memberof HorizontalPodAutoscalerStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface HpaEventsResponse
 */
export interface HpaEventsResponse {
    /**
     * 
     * @type {Array<HpaEventsResponseDataInner>}
     * @memberof HpaEventsResponse
     */
    'data': Array<HpaEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface HpaEventsResponseDataInner
 */
export interface HpaEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof HpaEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HpaEventsResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HpaEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof HpaEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof HpaEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HpaEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof HpaEventsResponseDataInner
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof HpaEventsResponseDataInner
     */
    'oldSpec'?: object;
    /**
     * 
     * @type {number}
     * @memberof HpaEventsResponseDataInner
     */
    'oldDesiredReplicas'?: number;
    /**
     * 
     * @type {number}
     * @memberof HpaEventsResponseDataInner
     */
    'newDesiredReplicas'?: number;
    /**
     * 
     * @type {number}
     * @memberof HpaEventsResponseDataInner
     */
    'minReplicas'?: number;
    /**
     * 
     * @type {number}
     * @memberof HpaEventsResponseDataInner
     */
    'maxReplicas'?: number;
    /**
     * 
     * @type {string}
     * @memberof HpaEventsResponseDataInner
     */
    'action'?: string;
}
        /**
 * 
 * @export
 * @interface IngressBaseEvent
 */
export interface IngressBaseEvent {
    /**
     * 
     * @type {string}
     * @memberof IngressBaseEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IngressBaseEvent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IngressBaseEvent
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof IngressBaseEvent
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof IngressBaseEvent
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressBaseEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IngressBaseEvent
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface IngressEventsResponse
 */
export interface IngressEventsResponse {
    /**
     * 
     * @type {Array<IngressEventsResponseDataInner>}
     * @memberof IngressEventsResponse
     */
    'data': Array<IngressEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface IngressEventsResponseDataInner
 */
export interface IngressEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof IngressEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IngressEventsResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IngressEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof IngressEventsResponseDataInner
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof IngressEventsResponseDataInner
     */
    'oldSpec'?: object;
    /**
     * 
     * @type {string}
     * @memberof IngressEventsResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IngressEventsResponseDataInner
     */
    'kubernetesServiceResourceNames'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface IngressState
 */
export interface IngressState {
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'hosts'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'ports'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressState
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface IngressStateAllOf
 */
export interface IngressStateAllOf {
    /**
     * 
     * @type {string}
     * @memberof IngressStateAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressStateAllOf
     */
    'hosts'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressStateAllOf
     */
    'ports'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressStateAllOf
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngressStateAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface IngressesStateResponse
 */
export interface IngressesStateResponse {
    /**
     * 
     * @type {IngressesStateResponseAllOfData}
     * @memberof IngressesStateResponse
     */
    'data'?: IngressesStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface IngressesStateResponseAllOf
 */
export interface IngressesStateResponseAllOf {
    /**
     * 
     * @type {IngressesStateResponseAllOfData}
     * @memberof IngressesStateResponseAllOf
     */
    'data'?: IngressesStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface IngressesStateResponseAllOfData
 */
export interface IngressesStateResponseAllOfData {
    /**
     * 
     * @type {Array<IngressState>}
     * @memberof IngressesStateResponseAllOfData
     */
    'wide'?: Array<IngressState>;
    /**
     * 
     * @type {Array<object>}
     * @memberof IngressesStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'completions'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface JobAllOf
 */
export interface JobAllOf {
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'completions'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface JobCondition
 */
export interface JobCondition {
    /**
     * 
     * @type {string}
     * @memberof JobCondition
     */
    'lastProbeTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCondition
     */
    'lastTransitionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCondition
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCondition
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCondition
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof JobCondition
     */
    'type': string;
}
        /**
 * 
 * @export
 * @interface JobEventsResponse
 */
export interface JobEventsResponse {
    /**
     * 
     * @type {Array<JobEventsResponseDataInner>}
     * @memberof JobEventsResponse
     */
    'data': Array<JobEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface JobEventsResponseDataInner
 */
export interface JobEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {JobStatus}
     * @memberof JobEventsResponseDataInner
     */
    'status'?: JobStatus;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'cronJobUid'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {LabelSelector}
     * @memberof JobEventsResponseDataInner
     */
    'selector'?: LabelSelector;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof JobEventsResponseDataInner
     */
    'subEvents'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'podRestartPolicy'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobEventsResponseDataInner
     */
    'backoffLimit'?: number;
    /**
     * 
     * @type {object}
     * @memberof JobEventsResponseDataInner
     */
    'newObj'?: object;
    /**
     * 
     * @type {string}
     * @memberof JobEventsResponseDataInner
     */
    'accountId'?: string;
}
        /**
 * 
 * @export
 * @interface JobStatus
 */
export interface JobStatus {
    /**
     * 
     * @type {number}
     * @memberof JobStatus
     */
    'active'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    'completedIndexes'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    'completionTime'?: string;
    /**
     * 
     * @type {Array<JobCondition>}
     * @memberof JobStatus
     */
    'conditions'?: Array<JobCondition>;
    /**
     * 
     * @type {number}
     * @memberof JobStatus
     */
    'failed'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    'failedIndexes'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatus
     */
    'ready'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatus
     */
    'startTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatus
     */
    'succeeded'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatus
     */
    'terminating'?: number;
    /**
     * 
     * @type {UncountedTerminatedPods}
     * @memberof JobStatus
     */
    'uncountedTerminatedPods'?: UncountedTerminatedPods;
}
        /**
 * 
 * @export
 * @interface JobsStateResponse
 */
export interface JobsStateResponse {
    /**
     * 
     * @type {JobsStateResponseAllOfData}
     * @memberof JobsStateResponse
     */
    'data'?: JobsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface JobsStateResponseAllOf
 */
export interface JobsStateResponseAllOf {
    /**
     * 
     * @type {JobsStateResponseAllOfData}
     * @memberof JobsStateResponseAllOf
     */
    'data'?: JobsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface JobsStateResponseAllOfData
 */
export interface JobsStateResponseAllOfData {
    /**
     * 
     * @type {Array<Job>}
     * @memberof JobsStateResponseAllOfData
     */
    'wide'?: Array<Job>;
    /**
     * 
     * @type {Array<object>}
     * @memberof JobsStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface K8sMetadata
 */
export interface K8sMetadata {
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sMetadata
     */
    'annotations'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'jobState'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sMetadata
     */
    'podLabels'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sMetadata
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'replicaSetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'k8sUid'?: string;
    /**
     * 
     * @type {LabelSelector}
     * @memberof K8sMetadata
     */
    'selector'?: LabelSelector;
    /**
     * 
     * @type {Array<string>}
     * @memberof K8sMetadata
     */
    'pvcNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sMetadata
     */
    'schedule'?: string;
}
        /**
 * 
 * @export
 * @interface K8sObjectMeta
 */
export interface K8sObjectMeta {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sObjectMeta
     */
    'annotations'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'creationTimestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof K8sObjectMeta
     */
    'deletionGracePeriodSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'deletionTimestamp'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof K8sObjectMeta
     */
    'finalizers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'generateName'?: string;
    /**
     * 
     * @type {number}
     * @memberof K8sObjectMeta
     */
    'generation'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sObjectMeta
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ManagedFieldsEntry>}
     * @memberof K8sObjectMeta
     */
    'managedFields'?: Array<ManagedFieldsEntry>;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'namespace'?: string;
    /**
     * 
     * @type {Array<OwnerReference>}
     * @memberof K8sObjectMeta
     */
    'ownerReferences'?: Array<OwnerReference>;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'resourceVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'selfLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sObjectMeta
     */
    'uid'?: string;
}
        /**
 * 
 * @export
 * @interface K8sPersistentVolumeClaimSpec
 */
export interface K8sPersistentVolumeClaimSpec {
    /**
     * 
     * @type {Array<string>}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'accessModes'?: Array<string>;
    /**
     * 
     * @type {K8sTypedLocalObjectReference}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'dataSource'?: K8sTypedLocalObjectReference;
    /**
     * 
     * @type {K8sTypedObjectReference}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'dataSourceRef'?: K8sTypedObjectReference;
    /**
     * 
     * @type {K8sVolumeResourceRequirements}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'resources'?: K8sVolumeResourceRequirements;
    /**
     * 
     * @type {LabelSelector}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'selector'?: LabelSelector;
    /**
     * 
     * @type {string}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'storageClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'volumeMode'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sPersistentVolumeClaimSpec
     */
    'volumeName'?: string;
}
        /**
 * 
 * @export
 * @interface K8sServiceEvent
 */
export interface K8sServiceEvent {
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEvent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEvent
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEvent
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof K8sServiceEvent
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEvent
     */
    'action': string;
    /**
     * 
     * @type {object}
     * @memberof K8sServiceEvent
     */
    'newData'?: object;
    /**
     * 
     * @type {object}
     * @memberof K8sServiceEvent
     */
    'oldData'?: object;
}
        /**
 * 
 * @export
 * @interface K8sServiceEventBase
 */
export interface K8sServiceEventBase {
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEventBase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEventBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEventBase
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEventBase
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEventBase
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof K8sServiceEventBase
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof K8sServiceEventBase
     */
    'action': string;
}
        /**
 * 
 * @export
 * @interface K8sServiceEventsResponse
 */
export interface K8sServiceEventsResponse {
    /**
     * 
     * @type {Array<K8sServiceEvent>}
     * @memberof K8sServiceEventsResponse
     */
    'data': Array<K8sServiceEvent>;
}
        /**
 * 
 * @export
 * @interface K8sTypedLocalObjectReference
 */
export interface K8sTypedLocalObjectReference {
    /**
     * 
     * @type {string}
     * @memberof K8sTypedLocalObjectReference
     */
    'apiGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sTypedLocalObjectReference
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof K8sTypedLocalObjectReference
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface K8sTypedObjectReference
 */
export interface K8sTypedObjectReference {
    /**
     * 
     * @type {string}
     * @memberof K8sTypedObjectReference
     */
    'apiGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof K8sTypedObjectReference
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof K8sTypedObjectReference
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof K8sTypedObjectReference
     */
    'namespace'?: string;
}
        /**
 * 
 * @export
 * @interface K8sVolumeResourceRequirements
 */
export interface K8sVolumeResourceRequirements {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sVolumeResourceRequirements
     */
    'limits'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof K8sVolumeResourceRequirements
     */
    'requests'?: { [key: string]: string; };
}
        /**
 * 
 * @export
 * @interface KomodorAlert
 */
export interface KomodorAlert {
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'actor'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'alertCycleId': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'alertPriority': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'alertSeverity': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'alertSource': string;
    /**
     * 
     * @type {object}
     * @memberof KomodorAlert
     */
    'alertTags': object;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'alertType': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorAlert
     */
    'assignees'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof KomodorAlert
     */
    'properties': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorAlert
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorAlert
     */
    'url': string;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const KomodorJobState = {
    Running: 'Running',
    Failed: 'Failed',
    Suspended: 'Suspended',
    Completed: 'Completed',
    NoRunsYet: 'No runs yet',
    Resumed: 'Resumed'
} as const;

export type KomodorJobState = typeof KomodorJobState[keyof typeof KomodorJobState];


        /**
 * 
 * @export
 * @interface KomodorServiceAggregations
 */
export interface KomodorServiceAggregations {
    /**
     * 
     * @type {number}
     * @memberof KomodorServiceAggregations
     */
    'count': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceAggregations
     */
    'group': Array<string>;
}
        /**
 * 
 * @export
 * @interface KomodorServiceAggregationsResponse
 */
export interface KomodorServiceAggregationsResponse {
    /**
     * 
     * @type {KomodorServiceAggregationsResponseServices}
     * @memberof KomodorServiceAggregationsResponse
     */
    'services': KomodorServiceAggregationsResponseServices;
    /**
     * 
     * @type {KomodorServiceAggregationsResponseServices}
     * @memberof KomodorServiceAggregationsResponse
     */
    'jobs': KomodorServiceAggregationsResponseServices;
}
        /**
 * 
 * @export
 * @interface KomodorServiceAggregationsResponseServices
 */
export interface KomodorServiceAggregationsResponseServices {
    /**
     * 
     * @type {Array<KomodorServiceAggregations>}
     * @memberof KomodorServiceAggregationsResponseServices
     */
    'data'?: Array<KomodorServiceAggregations>;
    /**
     * 
     * @type {number}
     * @memberof KomodorServiceAggregationsResponseServices
     */
    'total'?: number;
}
        /**
 * 
 * @export
 * @interface KomodorServiceAttributes
 */
export interface KomodorServiceAttributes {
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceAttributes
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceAttributes
     */
    'serviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceAttributes
     */
    'value'?: string;
}
        /**
 * 
 * @export
 * @interface KomodorServiceAttributesParams
 */
export interface KomodorServiceAttributesParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceAttributesParams
     */
    'fields'?: Array<KomodorServiceAttributesParamsFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceAttributesParams
     */
    'servicesIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceAttributesParams
     */
    'keys': Array<string>;
}

export const KomodorServiceAttributesParamsFieldsEnum = {
    Key: 'key',
    ServiceId: 'serviceId',
    Value: 'value'
} as const;

export type KomodorServiceAttributesParamsFieldsEnum = typeof KomodorServiceAttributesParamsFieldsEnum[keyof typeof KomodorServiceAttributesParamsFieldsEnum];

        /**
 * 
 * @export
 * @interface KomodorServiceAttributesResponse
 */
export interface KomodorServiceAttributesResponse {
    /**
     * 
     * @type {Array<KomodorServiceAttributes>}
     * @memberof KomodorServiceAttributesResponse
     */
    'data'?: Array<KomodorServiceAttributes>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const KomodorServiceDeploymentStatus = {
    Successful: 'Successful',
    Failure: 'Failure',
    Started: 'Started'
} as const;

export type KomodorServiceDeploymentStatus = typeof KomodorServiceDeploymentStatus[keyof typeof KomodorServiceDeploymentStatus];


        /**
 * 
 * @export
 * @interface KomodorServiceFetchJobsFiltersParams
 */
export interface KomodorServiceFetchJobsFiltersParams {
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceFetchJobsFiltersParams
     */
    'filterType'?: KomodorServiceFetchJobsFiltersParamsFilterTypeEnum;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof KomodorServiceFetchJobsFiltersParams
     */
    'checkedFilters'?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceFetchJobsFiltersParams
     */
    'textFilter'?: string;
}

export const KomodorServiceFetchJobsFiltersParamsFilterTypeEnum = {
    Static: 'static',
    Dynamic: 'dynamic'
} as const;

export type KomodorServiceFetchJobsFiltersParamsFilterTypeEnum = typeof KomodorServiceFetchJobsFiltersParamsFilterTypeEnum[keyof typeof KomodorServiceFetchJobsFiltersParamsFilterTypeEnum];

        /**
 * 
 * @export
 * @interface KomodorServiceFetchJobsFiltersResponse
 */
export interface KomodorServiceFetchJobsFiltersResponse {
    /**
     * 
     * @type {KomodorServiceFetchJobsFiltersResponseData}
     * @memberof KomodorServiceFetchJobsFiltersResponse
     */
    'data'?: KomodorServiceFetchJobsFiltersResponseData;
}
        /**
 * 
 * @export
 * @interface KomodorServiceFetchJobsFiltersResponseData
 */
export interface KomodorServiceFetchJobsFiltersResponseData {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof KomodorServiceFetchJobsFiltersResponseData
     */
    'Filters'?: { [key: string]: { [key: string]: number; }; };
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const KomodorServiceFields = {
    Id: 'id',
    AccountId: 'accountId',
    AgentId: 'agentId',
    Inactive: 'inactive',
    IsDeleted: 'isDeleted',
    DeletedAt: 'deletedAt',
    K8sClusterName: 'k8sClusterName',
    Namespace: 'namespace',
    DisplayName: 'displayName',
    Kind: 'kind',
    UpdatedAt: 'updatedAt',
    CreatedAt: 'createdAt'
} as const;

export type KomodorServiceFields = typeof KomodorServiceFields[keyof typeof KomodorServiceFields];


        /**
 * 
 * @export
 * @enum {string}
 */

export const KomodorServiceHealthStatus = {
    Healthy: 'HEALTHY',
    Unhealthy: 'UNHEALTHY'
} as const;

export type KomodorServiceHealthStatus = typeof KomodorServiceHealthStatus[keyof typeof KomodorServiceHealthStatus];


        /**
 * 
 * @export
 * @interface KomodorServiceMetadata
 */
export interface KomodorServiceMetadata {
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KomodorServiceMetadata
     */
    'annotations'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'jobState'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KomodorServiceMetadata
     */
    'podLabels'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KomodorServiceMetadata
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'replicaSetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'k8sUid'?: string;
    /**
     * 
     * @type {LabelSelector}
     * @memberof KomodorServiceMetadata
     */
    'selector'?: LabelSelector;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceMetadata
     */
    'pvcNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadata
     */
    'accountId'?: string;
}
        /**
 * 
 * @export
 * @interface KomodorServiceMetadataAllOf
 */
export interface KomodorServiceMetadataAllOf {
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadataAllOf
     */
    'kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadataAllOf
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadataAllOf
     */
    'jobState'?: string;
}
        /**
 * 
 * @export
 * @interface KomodorServiceMetadataParams
 */
export interface KomodorServiceMetadataParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceMetadataParams
     */
    'fields'?: Array<KomodorServiceMetadataParamsFieldsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceMetadataParams
     */
    'id'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceMetadataParams
     */
    'accountId': string;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServiceMetadataParams
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServiceMetadataParams
     */
    'inactive': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceMetadataParams
     */
    'kind'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServiceMetadataParams
     */
    'pvcNames'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KomodorServiceMetadataParams
     */
    'podLabels'?: { [key: string]: string; };
}

export const KomodorServiceMetadataParamsFieldsEnum = {
    Id: 'id',
    Kind: 'kind',
    AccountId: 'accountId',
    Annotations: 'annotations',
    PodLabels: 'podLabels',
    Labels: 'labels',
    ReplicaSetName: 'replicaSetName',
    UpdatedAt: 'updatedAt',
    CreatedAt: 'createdAt',
    EventTime: 'eventTime',
    K8sUid: 'k8sUid',
    Selector: 'selector',
    PvcNames: 'pvcNames',
    Status: 'status',
    Schedule: 'schedule',
    JobState: 'jobState'
} as const;

export type KomodorServiceMetadataParamsFieldsEnum = typeof KomodorServiceMetadataParamsFieldsEnum[keyof typeof KomodorServiceMetadataParamsFieldsEnum];

        /**
 * 
 * @export
 * @interface KomodorServicePodMapParams
 */
export interface KomodorServicePodMapParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof KomodorServicePodMapParams
     */
    'podsKomodorUids': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServicePodMapParams
     */
    'resolveRootOwner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicePodMapParams
     */
    'ownerType'?: KomodorServicePodMapParamsOwnerTypeEnum;
}

export const KomodorServicePodMapParamsOwnerTypeEnum = {
    WithRootOwner: 'with-root-owner',
    WithoutRootOwner: 'without-root-owner',
    WithDirectOwner: 'with-direct-owner'
} as const;

export type KomodorServicePodMapParamsOwnerTypeEnum = typeof KomodorServicePodMapParamsOwnerTypeEnum[keyof typeof KomodorServicePodMapParamsOwnerTypeEnum];

        /**
 * a map bwteen pods komodor uids to the root owners komodor uids
 * @export
 * @interface KomodorServicePodsMapResponse
 */
export interface KomodorServicePodsMapResponse {
    /**
     * 
     * @type {object}
     * @memberof KomodorServicePodsMapResponse
     */
    'data'?: object;
}
        /**
 * 
 * @export
 * @interface KomodorServiceStatusResponse
 */
export interface KomodorServiceStatusResponse {
    /**
     * 
     * @type {Array<KomodorServiceStatusResponseDataInner>}
     * @memberof KomodorServiceStatusResponse
     */
    'data': Array<KomodorServiceStatusResponseDataInner>;
    /**
     * 
     * @type {StatusResponseMeta}
     * @memberof KomodorServiceStatusResponse
     */
    'meta'?: StatusResponseMeta;
}
        /**
 * 
 * @export
 * @interface KomodorServiceStatusResponseDataInner
 */
export interface KomodorServiceStatusResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'kind': string;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServiceStatusResponseDataInner
     */
    'isInactive': boolean;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const KomodorServicesAggregationGroupBy = {
    Cluster: 'cluster',
    Kind: 'kind',
    HealthStatus: 'healthStatus'
} as const;

export type KomodorServicesAggregationGroupBy = typeof KomodorServicesAggregationGroupBy[keyof typeof KomodorServicesAggregationGroupBy];


        /**
 * 
 * @export
 * @enum {string}
 */

export const KomodorServicesAggregationsDataType = {
    Services: 'services',
    Jobs: 'jobs'
} as const;

export type KomodorServicesAggregationsDataType = typeof KomodorServicesAggregationsDataType[keyof typeof KomodorServicesAggregationsDataType];


        /**
 * 
 * @export
 * @interface KomodorServicesResponse
 */
export interface KomodorServicesResponse {
    /**
     * 
     * @type {Array<KomodorServicesResponseDataInner>}
     * @memberof KomodorServicesResponse
     */
    'data'?: Array<KomodorServicesResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface KomodorServicesResponseDataInner
 */
export interface KomodorServicesResponseDataInner {
    /**
     * 
     * @type {K8sMetadata}
     * @memberof KomodorServicesResponseDataInner
     */
    'k8s_metadata'?: K8sMetadata;
    /**
     * 
     * @type {DeployState}
     * @memberof KomodorServicesResponseDataInner
     */
    'deploy_state'?: DeployState;
    /**
     * 
     * @type {DatadogData}
     * @memberof KomodorServicesResponseDataInner
     */
    'datadog_data'?: DatadogData;
    /**
     * 
     * @type {HealthState}
     * @memberof KomodorServicesResponseDataInner
     */
    'health_state'?: HealthState;
    /**
     * 
     * @type {SentryData}
     * @memberof KomodorServicesResponseDataInner
     */
    'sentry_data'?: SentryData;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'agentId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServicesResponseDataInner
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KomodorServicesResponseDataInner
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'k8sClusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof KomodorServicesResponseDataInner
     */
    'deletedAt'?: string;
}
        /**
 * 
 * @export
 * @interface KubernetesService
 */
export interface KubernetesService {
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'cluster-ip'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'external-ip'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'port(s)'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesService
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface KubernetesServiceAllOf
 */
export interface KubernetesServiceAllOf {
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'cluster-ip'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'external-ip'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'port(s)'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'selector'?: string;
    /**
     * 
     * @type {string}
     * @memberof KubernetesServiceAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface KubernetesServicesStateResponse
 */
export interface KubernetesServicesStateResponse {
    /**
     * 
     * @type {Array<KubernetesServicesStateResponseAllOfData>}
     * @memberof KubernetesServicesStateResponse
     */
    'data'?: Array<KubernetesServicesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface KubernetesServicesStateResponseAllOf
 */
export interface KubernetesServicesStateResponseAllOf {
    /**
     * 
     * @type {Array<KubernetesServicesStateResponseAllOfData>}
     * @memberof KubernetesServicesStateResponseAllOf
     */
    'data'?: Array<KubernetesServicesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface KubernetesServicesStateResponseAllOfData
 */
export interface KubernetesServicesStateResponseAllOfData {
    /**
     * 
     * @type {KubernetesService}
     * @memberof KubernetesServicesStateResponseAllOfData
     */
    'wide'?: KubernetesService;
    /**
     * 
     * @type {object}
     * @memberof KubernetesServicesStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface LabelSelector
 */
export interface LabelSelector {
    /**
     * 
     * @type {Array<LabelSelectorRequirement>}
     * @memberof LabelSelector
     */
    'matchExpressions'?: Array<LabelSelectorRequirement>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LabelSelector
     */
    'matchLabels'?: { [key: string]: string; };
}
        /**
 * 
 * @export
 * @interface LabelSelectorRequirement
 */
export interface LabelSelectorRequirement {
    /**
     * 
     * @type {string}
     * @memberof LabelSelectorRequirement
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof LabelSelectorRequirement
     */
    'operator': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LabelSelectorRequirement
     */
    'values'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface Launchdarkly
 */
export interface Launchdarkly {
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'member'?: string;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'eventTime': string;
    /**
     * 
     * @type {object}
     * @memberof Launchdarkly
     */
    'currentVersion': object;
    /**
     * 
     * @type {object}
     * @memberof Launchdarkly
     */
    'previousVersion': object;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'createdAt': string;
    /**
     * 
     * @type {object}
     * @memberof Launchdarkly
     */
    'target': object;
    /**
     * 
     * @type {string}
     * @memberof Launchdarkly
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Launchdarkly
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface LimitRange
 */
export interface LimitRange {
    /**
     * 
     * @type {string}
     * @memberof LimitRange
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRange
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRange
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof LimitRange
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LimitRange
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface LimitRangeAllOf
 */
export interface LimitRangeAllOf {
    /**
     * 
     * @type {string}
     * @memberof LimitRangeAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeAllOf
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface LimitRangeEvent
 */
export interface LimitRangeEvent {
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEvent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEvent
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEvent
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LimitRangeEvent
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof LimitRangeEvent
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof LimitRangeEvent
     */
    'oldSpec'?: object;
}
        /**
 * 
 * @export
 * @interface LimitRangeEventAllOf
 */
export interface LimitRangeEventAllOf {
    /**
     * 
     * @type {object}
     * @memberof LimitRangeEventAllOf
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof LimitRangeEventAllOf
     */
    'oldSpec'?: object;
}
        /**
 * 
 * @export
 * @interface LimitRangeEventBase
 */
export interface LimitRangeEventBase {
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEventBase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEventBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEventBase
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEventBase
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof LimitRangeEventBase
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LimitRangeEventBase
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface LimitRangeEventsResponse
 */
export interface LimitRangeEventsResponse {
    /**
     * 
     * @type {Array<LimitRangeEvent>}
     * @memberof LimitRangeEventsResponse
     */
    'data': Array<LimitRangeEvent>;
}
        /**
 * 
 * @export
 * @interface LimitRangesStateResponse
 */
export interface LimitRangesStateResponse {
    /**
     * 
     * @type {Array<LimitRangesStateResponseAllOfData>}
     * @memberof LimitRangesStateResponse
     */
    'data'?: Array<LimitRangesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface LimitRangesStateResponseAllOf
 */
export interface LimitRangesStateResponseAllOf {
    /**
     * 
     * @type {Array<LimitRangesStateResponseAllOfData>}
     * @memberof LimitRangesStateResponseAllOf
     */
    'data'?: Array<LimitRangesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface LimitRangesStateResponseAllOfData
 */
export interface LimitRangesStateResponseAllOfData {
    /**
     * 
     * @type {LimitRange}
     * @memberof LimitRangesStateResponseAllOfData
     */
    'wide'?: LimitRange;
    /**
     * 
     * @type {object}
     * @memberof LimitRangesStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface ManagedFieldsEntry
 */
export interface ManagedFieldsEntry {
    /**
     * 
     * @type {string}
     * @memberof ManagedFieldsEntry
     */
    'apiVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedFieldsEntry
     */
    'fieldsType'?: string;
    /**
     * 
     * @type {object}
     * @memberof ManagedFieldsEntry
     */
    'fieldsV1'?: object;
    /**
     * 
     * @type {string}
     * @memberof ManagedFieldsEntry
     */
    'manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedFieldsEntry
     */
    'operation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedFieldsEntry
     */
    'subresource'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedFieldsEntry
     */
    'time'?: string;
}
        /**
 * 
 * @export
 * @interface NativeEventsResponse
 */
export interface NativeEventsResponse {
    /**
     * 
     * @type {Array<NativeEventsResponseDataInner>}
     * @memberof NativeEventsResponse
     */
    'data': Array<NativeEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface NativeEventsResponseDataInner
 */
export interface NativeEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'kind'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'reason'?: string;
    /**
     * 
     * @type {number}
     * @memberof NativeEventsResponseDataInner
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'objectName'?: string;
    /**
     * 
     * @type {object}
     * @memberof NativeEventsResponseDataInner
     */
    'metadata'?: object;
    /**
     * 
     * @type {object}
     * @memberof NativeEventsResponseDataInner
     */
    'involvedObject'?: object;
    /**
     * 
     * @type {string}
     * @memberof NativeEventsResponseDataInner
     */
    'lastObservedTime'?: string;
}
        /**
 * 
 * @export
 * @interface NetworkMapEventsResponse
 */
export interface NetworkMapEventsResponse {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof NetworkMapEventsResponse
     */
    'meta'?: ResponseMeta;
    /**
     * 
     * @type {Array<NetworkMapEventsResponseDataInner>}
     * @memberof NetworkMapEventsResponse
     */
    'data': Array<NetworkMapEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface NetworkMapEventsResponseDataInner
 */
export interface NetworkMapEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'srcService'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'srcServiceKind'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'srcNamespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'destService'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'destServiceKind'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'destNamespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'networkType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'changeType'?: NetworkMapEventsResponseDataInnerChangeTypeEnum;
    /**
     * 
     * @type {NetworkMapEventsResponseDataInnerTags}
     * @memberof NetworkMapEventsResponseDataInner
     */
    'tags'?: NetworkMapEventsResponseDataInnerTags;
}

export const NetworkMapEventsResponseDataInnerChangeTypeEnum = {
    Added: 'Added',
    Deleted: 'Deleted'
} as const;

export type NetworkMapEventsResponseDataInnerChangeTypeEnum = typeof NetworkMapEventsResponseDataInnerChangeTypeEnum[keyof typeof NetworkMapEventsResponseDataInnerChangeTypeEnum];

        /**
 * 
 * @export
 * @interface NetworkMapEventsResponseDataInnerTags
 */
export interface NetworkMapEventsResponseDataInnerTags {
    /**
     * 
     * @type {NetworkMapEventsResponseDataInnerTagsSource}
     * @memberof NetworkMapEventsResponseDataInnerTags
     */
    'source'?: NetworkMapEventsResponseDataInnerTagsSource;
    /**
     * 
     * @type {NetworkMapEventsResponseDataInnerTagsSource}
     * @memberof NetworkMapEventsResponseDataInnerTags
     */
    'destination'?: NetworkMapEventsResponseDataInnerTagsSource;
}
        /**
 * 
 * @export
 * @interface NetworkMapEventsResponseDataInnerTagsSource
 */
export interface NetworkMapEventsResponseDataInnerTagsSource {
    /**
     * 
     * @type {Array<NetworkMapEventsResponseDataInnerTagsSourceLabelsInner>}
     * @memberof NetworkMapEventsResponseDataInnerTagsSource
     */
    'labels'?: Array<NetworkMapEventsResponseDataInnerTagsSourceLabelsInner>;
}
        /**
 * 
 * @export
 * @interface NetworkMapEventsResponseDataInnerTagsSourceLabelsInner
 */
export interface NetworkMapEventsResponseDataInnerTagsSourceLabelsInner {
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInnerTagsSourceLabelsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapEventsResponseDataInnerTagsSourceLabelsInner
     */
    'value'?: string;
}
        /**
 * 
 * @export
 * @interface NetworkMapGraphResponse
 */
export interface NetworkMapGraphResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof NetworkMapGraphResponse
     */
    'nodes'?: Array<string>;
    /**
     * 
     * @type {Array<NetworkMapGraphResponseEdgesInner>}
     * @memberof NetworkMapGraphResponse
     */
    'edges'?: Array<NetworkMapGraphResponseEdgesInner>;
}
        /**
 * 
 * @export
 * @interface NetworkMapGraphResponseEdgesInner
 */
export interface NetworkMapGraphResponseEdgesInner {
    /**
     * 
     * @type {string}
     * @memberof NetworkMapGraphResponseEdgesInner
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkMapGraphResponseEdgesInner
     */
    'destination'?: string;
}
        /**
 * 
 * @export
 * @interface NetworkPoliciesStateResponse
 */
export interface NetworkPoliciesStateResponse {
    /**
     * 
     * @type {Array<NetworkPoliciesStateResponseAllOfData>}
     * @memberof NetworkPoliciesStateResponse
     */
    'data'?: Array<NetworkPoliciesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface NetworkPoliciesStateResponseAllOf
 */
export interface NetworkPoliciesStateResponseAllOf {
    /**
     * 
     * @type {Array<NetworkPoliciesStateResponseAllOfData>}
     * @memberof NetworkPoliciesStateResponseAllOf
     */
    'data'?: Array<NetworkPoliciesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface NetworkPoliciesStateResponseAllOfData
 */
export interface NetworkPoliciesStateResponseAllOfData {
    /**
     * 
     * @type {NetworkPolicy}
     * @memberof NetworkPoliciesStateResponseAllOfData
     */
    'wide'?: NetworkPolicy;
    /**
     * 
     * @type {object}
     * @memberof NetworkPoliciesStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface NetworkPolicy
 */
export interface NetworkPolicy {
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicy
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicy
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicy
     */
    'podSelector'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicy
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface NetworkPolicyAllOf
 */
export interface NetworkPolicyAllOf {
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyAllOf
     */
    'podSelector'?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface NetworkPolicyEvent
 */
export interface NetworkPolicyEvent {
    /**
     * 
     * @type {object}
     * @memberof NetworkPolicyEvent
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof NetworkPolicyEvent
     */
    'oldSpec'?: object;
}
        /**
 * 
 * @export
 * @interface NetworkPolicyEventBaseInner
 */
export interface NetworkPolicyEventBaseInner {
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyEventBaseInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyEventBaseInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyEventBaseInner
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyEventBaseInner
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof NetworkPolicyEventBaseInner
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NetworkPolicyEventBaseInner
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface NetworkPolicyEventsResponse
 */
export interface NetworkPolicyEventsResponse {
    /**
     * 
     * @type {Array<NetworkPolicyEvent>}
     * @memberof NetworkPolicyEventsResponse
     */
    'data': Array<NetworkPolicyEvent>;
}
        /**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'labels'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'roles'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'memoryPressure'?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'diskPressure'?: string;
}
        /**
 * 
 * @export
 * @interface NodeAddress
 */
export interface NodeAddress {
    /**
     * 
     * @type {string}
     * @memberof NodeAddress
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof NodeAddress
     */
    'type': string;
}
        /**
 * 
 * @export
 * @interface NodeAllOf
 */
export interface NodeAllOf {
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'labels'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'roles'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'memoryPressure'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeAllOf
     */
    'diskPressure'?: string;
}
        /**
 * 
 * @export
 * @interface NodeCondition
 */
export interface NodeCondition {
    /**
     * 
     * @type {string}
     * @memberof NodeCondition
     */
    'lastHeartbeatTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeCondition
     */
    'lastTransitionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeCondition
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeCondition
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeCondition
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof NodeCondition
     */
    'type': string;
}
        /**
 * 
 * @export
 * @interface NodeConfigSource
 */
export interface NodeConfigSource {
    /**
     * 
     * @type {ConfigMapNodeConfigSource}
     * @memberof NodeConfigSource
     */
    'configMap'?: ConfigMapNodeConfigSource;
}
        /**
 * 
 * @export
 * @interface NodeConfigStatus
 */
export interface NodeConfigStatus {
    /**
     * 
     * @type {NodeConfigSource}
     * @memberof NodeConfigStatus
     */
    'active'?: NodeConfigSource;
    /**
     * 
     * @type {NodeConfigSource}
     * @memberof NodeConfigStatus
     */
    'assigned'?: NodeConfigSource;
    /**
     * 
     * @type {string}
     * @memberof NodeConfigStatus
     */
    'error'?: string;
    /**
     * 
     * @type {NodeConfigSource}
     * @memberof NodeConfigStatus
     */
    'lastKnownGood'?: NodeConfigSource;
}
        /**
 * 
 * @export
 * @interface NodeDaemonEndpoints
 */
export interface NodeDaemonEndpoints {
    /**
     * 
     * @type {SingleDaemonEndpoint}
     * @memberof NodeDaemonEndpoints
     */
    'kubeletEndpoint'?: SingleDaemonEndpoint;
}
        /**
 * 
 * @export
 * @interface NodeEnrichment
 */
export interface NodeEnrichment {
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'nodeName': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'nodeUid': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEnrichment
     */
    'kind': string;
}
        /**
 * 
 * @export
 * @interface NodeEventsResponse
 */
export interface NodeEventsResponse {
    /**
     * 
     * @type {Array<NodeEventsResponseDataInner>}
     * @memberof NodeEventsResponse
     */
    'data': Array<NodeEventsResponseDataInner>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof NodeEventsResponse
     */
    'meta'?: ResponseMeta;
}
        /**
 * 
 * @export
 * @interface NodeEventsResponseDataInner
 */
export interface NodeEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'nodeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'readyReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'readyMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'memoryPressure'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'diskPressure'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'lastHeartbeatTime'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NodeEventsResponseDataInner
     */
    'capacity'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NodeEventsResponseDataInner
     */
    'allocatable'?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof NodeEventsResponseDataInner
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'MemoryPressureMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'DiskPressureMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'nodeCreationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'instanceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'allocationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'os'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'cloudProvider'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'terminationReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeEventsResponseDataInner
     */
    'agentId'?: string;
    /**
     * 
     * @type {NodeObject}
     * @memberof NodeEventsResponseDataInner
     */
    'newObj'?: NodeObject;
    /**
     * 
     * @type {object}
     * @memberof NodeEventsResponseDataInner
     */
    'oldObj'?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeEventsResponseDataInner
     */
    'nodeInfo'?: object;
    /**
     * 
     * @type {Array<Taint>}
     * @memberof NodeEventsResponseDataInner
     */
    'taints'?: Array<Taint>;
    /**
     * 
     * @type {NodeTerminationImpact}
     * @memberof NodeEventsResponseDataInner
     */
    'event_node_termination_impact'?: NodeTerminationImpact;
    /**
     * 
     * @type {NodeEnrichment}
     * @memberof NodeEventsResponseDataInner
     */
    'event_node_enrichment'?: NodeEnrichment;
}
        /**
 * 
 * @export
 * @interface NodeObj
 */
export interface NodeObj {
    /**
     * 
     * @type {string}
     * @memberof NodeObj
     */
    'kind'?: string;
    /**
     * 
     * @type {object}
     * @memberof NodeObj
     */
    'spec'?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeObj
     */
    'status'?: object;
    /**
     * 
     * @type {NodeObjMetadata}
     * @memberof NodeObj
     */
    'metadata'?: NodeObjMetadata;
}
        /**
 * 
 * @export
 * @interface NodeObjMetadata
 */
export interface NodeObjMetadata {
    /**
     * 
     * @type {string}
     * @memberof NodeObjMetadata
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeObjMetadata
     */
    'name'?: string;
    /**
     * 
     * @type {object}
     * @memberof NodeObjMetadata
     */
    'labels'?: object;
    /**
     * 
     * @type {object}
     * @memberof NodeObjMetadata
     */
    'annotations'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof NodeObjMetadata
     */
    'managedFields'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof NodeObjMetadata
     */
    'resourceVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeObjMetadata
     */
    'creationTimestamp'?: string;
}
        /**
 * 
 * @export
 * @interface NodeObject
 */
export interface NodeObject {
    /**
     * 
     * @type {string}
     * @memberof NodeObject
     */
    'apiVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeObject
     */
    'kind'?: string;
    /**
     * 
     * @type {K8sObjectMeta}
     * @memberof NodeObject
     */
    'metadata'?: K8sObjectMeta;
    /**
     * 
     * @type {NodeSpec}
     * @memberof NodeObject
     */
    'spec'?: NodeSpec;
    /**
     * 
     * @type {NodeStatus}
     * @memberof NodeObject
     */
    'status'?: NodeStatus;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const NodeReadyReason = {
    NodeStatusNeverUpdated: 'NodeStatusNeverUpdated',
    NodeStatusUnknown: 'NodeStatusUnknown',
    KubeletNotReady: 'KubeletNotReady',
    KubeletReady: 'KubeletReady',
    Empty: ''
} as const;

export type NodeReadyReason = typeof NodeReadyReason[keyof typeof NodeReadyReason];


        /**
 * 
 * @export
 * @interface NodeSpec
 */
export interface NodeSpec {
    /**
     * 
     * @type {NodeConfigSource}
     * @memberof NodeSpec
     */
    'configSource'?: NodeConfigSource;
    /**
     * 
     * @type {string}
     * @memberof NodeSpec
     */
    'externalID'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeSpec
     */
    'podCIDR'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeSpec
     */
    'podCIDRs'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NodeSpec
     */
    'providerID'?: string;
    /**
     * 
     * @type {Array<Taint>}
     * @memberof NodeSpec
     */
    'taints'?: Array<Taint>;
    /**
     * 
     * @type {boolean}
     * @memberof NodeSpec
     */
    'unschedulable'?: boolean;
}
        /**
 * 
 * @export
 * @interface NodeStatus
 */
export interface NodeStatus {
    /**
     * 
     * @type {Array<NodeAddress>}
     * @memberof NodeStatus
     */
    'addresses'?: Array<NodeAddress>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NodeStatus
     */
    'allocatable'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NodeStatus
     */
    'capacity'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<NodeCondition>}
     * @memberof NodeStatus
     */
    'conditions'?: Array<NodeCondition>;
    /**
     * 
     * @type {NodeConfigStatus}
     * @memberof NodeStatus
     */
    'config'?: NodeConfigStatus;
    /**
     * 
     * @type {NodeDaemonEndpoints}
     * @memberof NodeStatus
     */
    'daemonEndpoints'?: NodeDaemonEndpoints;
    /**
     * 
     * @type {Array<ContainerImage>}
     * @memberof NodeStatus
     */
    'images'?: Array<ContainerImage>;
    /**
     * 
     * @type {NodeSystemInfo}
     * @memberof NodeStatus
     */
    'nodeInfo'?: NodeSystemInfo;
    /**
     * 
     * @type {string}
     * @memberof NodeStatus
     */
    'phase'?: string;
    /**
     * 
     * @type {Array<AttachedVolume>}
     * @memberof NodeStatus
     */
    'volumesAttached'?: Array<AttachedVolume>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeStatus
     */
    'volumesInUse'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface NodeStatusResponse
 */
export interface NodeStatusResponse {
    /**
     * 
     * @type {Array<NodeStatusResponseDataInner>}
     * @memberof NodeStatusResponse
     */
    'data': Array<NodeStatusResponseDataInner>;
    /**
     * 
     * @type {StatusResponseMeta}
     * @memberof NodeStatusResponse
     */
    'meta'?: StatusResponseMeta;
}
        /**
 * 
 * @export
 * @interface NodeStatusResponseDataInner
 */
export interface NodeStatusResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof NodeStatusResponseDataInner
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof NodeStatusResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NodeStatusResponseDataInner
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof NodeStatusResponseDataInner
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof NodeStatusResponseDataInner
     */
    'isDeleted': boolean;
}
        /**
 * 
 * @export
 * @interface NodeSystemInfo
 */
export interface NodeSystemInfo {
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'architecture': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'bootID': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'containerRuntimeVersion': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'kernelVersion': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'kubeProxyVersion': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'kubeletVersion': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'machineID': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'operatingSystem': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'osImage': string;
    /**
     * 
     * @type {string}
     * @memberof NodeSystemInfo
     */
    'systemUUID': string;
}
        /**
 * 
 * @export
 * @interface NodeTerminationImpact
 */
export interface NodeTerminationImpact {
    /**
     * 
     * @type {string}
     * @memberof NodeTerminationImpact
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeTerminationImpact
     */
    'nodeEventId': string;
    /**
     * 
     * @type {string}
     * @memberof NodeTerminationImpact
     */
    'accountId': string;
    /**
     * 
     * @type {Array<AffectedService>}
     * @memberof NodeTerminationImpact
     */
    'affectedServices': Array<AffectedService>;
    /**
     * 
     * @type {Array<AffectedService>}
     * @memberof NodeTerminationImpact
     */
    'affectedJobs': Array<AffectedService>;
    /**
     * 
     * @type {Array<AffectedService>}
     * @memberof NodeTerminationImpact
     */
    'affectedArgoWorkflows': Array<AffectedService>;
    /**
     * 
     * @type {Array<object>}
     * @memberof NodeTerminationImpact
     */
    'affectedOrphanPods': Array<object>;
    /**
     * 
     * @type {Array<AffectedService>}
     * @memberof NodeTerminationImpact
     */
    'affectedDaemonSets': Array<AffectedService>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const NodesAggregationType = {
    Cluster: 'cluster',
    Status: 'status'
} as const;

export type NodesAggregationType = typeof NodesAggregationType[keyof typeof NodesAggregationType];


        /**
 * 
 * @export
 * @interface NodesAggregationsCountByCluster
 */
export interface NodesAggregationsCountByCluster {
    /**
     * 
     * @type {Array<NodesAggregationsCountByClusterDataInner>}
     * @memberof NodesAggregationsCountByCluster
     */
    'data'?: Array<NodesAggregationsCountByClusterDataInner>;
}
        /**
 * 
 * @export
 * @interface NodesAggregationsCountByClusterAndStatus
 */
export interface NodesAggregationsCountByClusterAndStatus {
    /**
     * 
     * @type {Array<NodesAggregationsCountByClusterAndStatusDataInner>}
     * @memberof NodesAggregationsCountByClusterAndStatus
     */
    'data'?: Array<NodesAggregationsCountByClusterAndStatusDataInner>;
}
        /**
 * 
 * @export
 * @interface NodesAggregationsCountByClusterAndStatusDataInner
 */
export interface NodesAggregationsCountByClusterAndStatusDataInner {
    /**
     * 
     * @type {string}
     * @memberof NodesAggregationsCountByClusterAndStatusDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {Array<NodesAggregationsCountByStatusDataInner>}
     * @memberof NodesAggregationsCountByClusterAndStatusDataInner
     */
    'statuses'?: Array<NodesAggregationsCountByStatusDataInner>;
}
        /**
 * 
 * @export
 * @interface NodesAggregationsCountByClusterDataInner
 */
export interface NodesAggregationsCountByClusterDataInner {
    /**
     * 
     * @type {string}
     * @memberof NodesAggregationsCountByClusterDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodesAggregationsCountByClusterDataInner
     */
    'count'?: number;
}
        /**
 * 
 * @export
 * @interface NodesAggregationsCountByStatus
 */
export interface NodesAggregationsCountByStatus {
    /**
     * 
     * @type {Array<NodesAggregationsCountByStatusDataInner>}
     * @memberof NodesAggregationsCountByStatus
     */
    'data'?: Array<NodesAggregationsCountByStatusDataInner>;
}
        /**
 * 
 * @export
 * @interface NodesAggregationsCountByStatusDataInner
 */
export interface NodesAggregationsCountByStatusDataInner {
    /**
     * 
     * @type {string}
     * @memberof NodesAggregationsCountByStatusDataInner
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof NodesAggregationsCountByStatusDataInner
     */
    'count'?: number;
}
        /**
 * 
 * @export
 * @interface NodesAggregationsResponse
 */
export interface NodesAggregationsResponse {
    /**
     * 
     * @type {number}
     * @memberof NodesAggregationsResponse
     */
    'total'?: number;
    /**
     * 
     * @type {NodesAggregationsCountByCluster}
     * @memberof NodesAggregationsResponse
     */
    'cluster'?: NodesAggregationsCountByCluster;
    /**
     * 
     * @type {NodesAggregationsCountByStatus}
     * @memberof NodesAggregationsResponse
     */
    'status'?: NodesAggregationsCountByStatus;
    /**
     * 
     * @type {NodesAggregationsCountByClusterAndStatus}
     * @memberof NodesAggregationsResponse
     */
    'clusterAndStatus'?: NodesAggregationsCountByClusterAndStatus;
}
        /**
 * 
 * @export
 * @interface NodesStateResponse
 */
export interface NodesStateResponse {
    /**
     * 
     * @type {NodesStateResponseAllOfData}
     * @memberof NodesStateResponse
     */
    'data'?: NodesStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface NodesStateResponseAllOf
 */
export interface NodesStateResponseAllOf {
    /**
     * 
     * @type {NodesStateResponseAllOfData}
     * @memberof NodesStateResponseAllOf
     */
    'data'?: NodesStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface NodesStateResponseAllOfData
 */
export interface NodesStateResponseAllOfData {
    /**
     * 
     * @type {Array<Node>}
     * @memberof NodesStateResponseAllOfData
     */
    'wide'?: Array<Node>;
    /**
     * 
     * @type {Array<object>}
     * @memberof NodesStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'name': string;
}
        /**
 * 
 * @export
 * @interface OwnerReference
 */
export interface OwnerReference {
    /**
     * 
     * @type {string}
     * @memberof OwnerReference
     */
    'apiVersion': string;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerReference
     */
    'blockOwnerDeletion'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerReference
     */
    'controller'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OwnerReference
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof OwnerReference
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OwnerReference
     */
    'uid': string;
}
        /**
 * 
 * @export
 * @interface Pagerduty
 */
export interface Pagerduty {
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'actor'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pagerduty
     */
    'assignees'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'incidentKey': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'incidentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'pdServiceName': string;
    /**
     * 
     * @type {object}
     * @memberof Pagerduty
     */
    'pdTags': object;
    /**
     * 
     * @type {object}
     * @memberof Pagerduty
     */
    'properties': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pagerduty
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof Pagerduty
     */
    'updatedAt': string;
}
        /**
 * 
 * @export
 * @interface PdbEventsResponse
 */
export interface PdbEventsResponse {
    /**
     * 
     * @type {Array<PdbEventsResponseDataInner>}
     * @memberof PdbEventsResponse
     */
    'data': Array<PdbEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface PdbEventsResponseDataInner
 */
export interface PdbEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof PdbEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PdbEventsResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PdbEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PdbEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PdbEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PdbEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof PdbEventsResponseDataInner
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof PdbEventsResponseDataInner
     */
    'oldSpec'?: object;
    /**
     * 
     * @type {string}
     * @memberof PdbEventsResponseDataInner
     */
    'action'?: string;
}
        /**
 * 
 * @export
 * @interface PersistentVolume
 */
export interface PersistentVolume {
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'claim'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'capacity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'storageclass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolume
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface PersistentVolumeAllOf
 */
export interface PersistentVolumeAllOf {
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeAllOf
     */
    'claim'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeAllOf
     */
    'capacity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeAllOf
     */
    'storageclass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface PersistentVolumeClaim
 */
export interface PersistentVolumeClaim {
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'capacity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'volumemode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'access modes'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'storageclass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'volume'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaim
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface PersistentVolumeClaimAllOf
 */
export interface PersistentVolumeClaimAllOf {
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'capacity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'volumemode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'access modes'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'storageclass'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'volume'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeClaimAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface PersistentVolumeClaimsStateResponse
 */
export interface PersistentVolumeClaimsStateResponse {
    /**
     * 
     * @type {Array<PersistentVolumeClaimsStateResponseAllOfData>}
     * @memberof PersistentVolumeClaimsStateResponse
     */
    'data'?: Array<PersistentVolumeClaimsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface PersistentVolumeClaimsStateResponseAllOf
 */
export interface PersistentVolumeClaimsStateResponseAllOf {
    /**
     * 
     * @type {Array<PersistentVolumeClaimsStateResponseAllOfData>}
     * @memberof PersistentVolumeClaimsStateResponseAllOf
     */
    'data'?: Array<PersistentVolumeClaimsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface PersistentVolumeClaimsStateResponseAllOfData
 */
export interface PersistentVolumeClaimsStateResponseAllOfData {
    /**
     * 
     * @type {PersistentVolumeClaim}
     * @memberof PersistentVolumeClaimsStateResponseAllOfData
     */
    'wide'?: PersistentVolumeClaim;
    /**
     * 
     * @type {object}
     * @memberof PersistentVolumeClaimsStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface PersistentVolumesStateResponse
 */
export interface PersistentVolumesStateResponse {
    /**
     * 
     * @type {Array<PersistentVolumesStateResponseAllOfData>}
     * @memberof PersistentVolumesStateResponse
     */
    'data'?: Array<PersistentVolumesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface PersistentVolumesStateResponseAllOf
 */
export interface PersistentVolumesStateResponseAllOf {
    /**
     * 
     * @type {Array<PersistentVolumesStateResponseAllOfData>}
     * @memberof PersistentVolumesStateResponseAllOf
     */
    'data'?: Array<PersistentVolumesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface PersistentVolumesStateResponseAllOfData
 */
export interface PersistentVolumesStateResponseAllOfData {
    /**
     * 
     * @type {PersistentVolume}
     * @memberof PersistentVolumesStateResponseAllOfData
     */
    'wide'?: PersistentVolume;
    /**
     * 
     * @type {object}
     * @memberof PersistentVolumesStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface PodDisruptionBudget
 */
export interface PodDisruptionBudget {
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'minAvailable'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'maxUnavailable'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'allowedDisruptions'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudget
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface PodDisruptionBudgetAllOf
 */
export interface PodDisruptionBudgetAllOf {
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudgetAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudgetAllOf
     */
    'minAvailable'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudgetAllOf
     */
    'maxUnavailable'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudgetAllOf
     */
    'allowedDisruptions'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodDisruptionBudgetAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface PodDisruptionBudgetStateResponse
 */
export interface PodDisruptionBudgetStateResponse {
    /**
     * 
     * @type {Array<PodDisruptionBudgetStateResponseAllOfData>}
     * @memberof PodDisruptionBudgetStateResponse
     */
    'data'?: Array<PodDisruptionBudgetStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface PodDisruptionBudgetStateResponseAllOf
 */
export interface PodDisruptionBudgetStateResponseAllOf {
    /**
     * 
     * @type {Array<PodDisruptionBudgetStateResponseAllOfData>}
     * @memberof PodDisruptionBudgetStateResponseAllOf
     */
    'data'?: Array<PodDisruptionBudgetStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface PodDisruptionBudgetStateResponseAllOfData
 */
export interface PodDisruptionBudgetStateResponseAllOfData {
    /**
     * 
     * @type {PodDisruptionBudget}
     * @memberof PodDisruptionBudgetStateResponseAllOfData
     */
    'wide'?: PodDisruptionBudget;
    /**
     * 
     * @type {object}
     * @memberof PodDisruptionBudgetStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface PodEvent
 */
export interface PodEvent {
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'severityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'eventKind'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'firstTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'lastTimestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof PodEvent
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'message'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEvent
     */
    'statusConditions'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEvent
     */
    'containerStatus'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEvent
     */
    'initContainerStatus'?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof PodEvent
     */
    'highlight'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'statusReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'waitingMessage'?: string;
}
        /**
 * 
 * @export
 * @interface PodEventsResponse
 */
export interface PodEventsResponse {
    /**
     * 
     * @type {Array<PodEventsResponseDataInner>}
     * @memberof PodEventsResponse
     */
    'data': Array<PodEventsResponseDataInner>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof PodEventsResponse
     */
    'meta'?: ResponseMeta;
}
        /**
 * 
 * @export
 * @interface PodEventsResponseDataInner
 */
export interface PodEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'podName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'phase'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'podHealth'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'statusReason'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEventsResponseDataInner
     */
    'statusConditions'?: Array<object>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof PodEventsResponseDataInner
     */
    'containerStatus'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof PodEventsResponseDataInner
     */
    'initContainerStatus'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'nodeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'nodeHealthStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'exitCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'failedContainer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'waitingReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'terminatedReason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PodEventsResponseDataInner
     */
    'ready'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof PodEventsResponseDataInner
     */
    'status'?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PodEventsResponseDataInner
     */
    'metadata'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'podUid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PodEventsResponseDataInner
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof PodEventsResponseDataInner
     */
    'priorityClassName'?: string;
    /**
     * 
     * @type {object}
     * @memberof PodEventsResponseDataInner
     */
    'podResources'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodEventsResponseDataInner
     */
    'pvcNames'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PodEventsResponseDataInner
     */
    'resourceVersion'?: number;
}
        /**
 * 
 * @export
 * @interface PodGroup
 */
export interface PodGroup {
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'serviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'endTime'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodGroup
     */
    'statusConditions'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'status'?: PodGroupStatusEnum;
    /**
     * 
     * @type {Array<PodEvent>}
     * @memberof PodGroup
     */
    'events'?: Array<PodEvent>;
}

export const PodGroupStatusEnum = {
    Normal: 'Normal',
    Warning: 'Warning',
    Error: 'Error'
} as const;

export type PodGroupStatusEnum = typeof PodGroupStatusEnum[keyof typeof PodGroupStatusEnum];

        /**
 * 
 * @export
 * @interface PodGroups
 */
export interface PodGroups {
    /**
     * 
     * @type {string}
     * @memberof PodGroups
     */
    'komodorUid'?: string;
    /**
     * 
     * @type {Array<PodGroup>}
     * @memberof PodGroups
     */
    'groups'?: Array<PodGroup>;
}
        /**
 * 
 * @export
 * @interface PodJson
 */
export interface PodJson {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PodJson
     */
    'spec'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PodJson
     */
    'status'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PodJson
     */
    'metadata'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PodJson
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface PodNativeEventsResponse
 */
export interface PodNativeEventsResponse {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof PodNativeEventsResponse
     */
    'meta'?: ResponseMeta;
    /**
     * 
     * @type {Array<PodNativeEventsResponseDataInner>}
     * @memberof PodNativeEventsResponse
     */
    'data': Array<PodNativeEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface PodNativeEventsResponseDataInner
 */
export interface PodNativeEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'firstTimestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'lastTimestamp'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodNativeEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PodNativeEventsResponseDataInner
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'severityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'podName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'nodeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'nodeHealthStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'sourceComponent'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'sourceHost'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodNativeEventsResponseDataInner
     */
    'podUid'?: string;
}
        /**
 * 
 * @export
 * @interface PodStateRequest
 */
export interface PodStateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PodStateRequest
     */
    'includeServices'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'resourceOwnerUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'komodorUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'rootOwnerKomodorUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'nodeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'namespace'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodStateRequest
     */
    'fields'?: Array<PodStateRequestFieldsEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PodStateRequest
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'labelSelector'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStateRequest
     */
    'fieldSelector'?: string;
    /**
     * 
     * @type {number}
     * @memberof PodStateRequest
     */
    'limit'?: number;
}

export const PodStateRequestFieldsEnum = {
    Json: 'json'
} as const;

export type PodStateRequestFieldsEnum = typeof PodStateRequestFieldsEnum[keyof typeof PodStateRequestFieldsEnum];

        /**
 * 
 * @export
 * @interface PodStatusResponse
 */
export interface PodStatusResponse {
    /**
     * 
     * @type {Array<PodStatusResponseDataInner>}
     * @memberof PodStatusResponse
     */
    'data': Array<PodStatusResponseDataInner>;
    /**
     * 
     * @type {StatusResponseMeta}
     * @memberof PodStatusResponse
     */
    'meta'?: StatusResponseMeta;
}
        /**
 * 
 * @export
 * @interface PodStatusResponseDataInner
 */
export interface PodStatusResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'name': string;
    /**
     * 
     * @type {Owner}
     * @memberof PodStatusResponseDataInner
     */
    'rootOwner': Owner;
    /**
     * 
     * @type {boolean}
     * @memberof PodStatusResponseDataInner
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodStatusResponseDataInner
     */
    'deletedPodId'?: string;
}
        /**
 * 
 * @export
 * @interface PodsInfo
 */
export interface PodsInfo {
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'podName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'podUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'komodorServiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'nodeName'?: string;
    /**
     * 
     * @type {Owner}
     * @memberof PodsInfo
     */
    'rootOwner'?: Owner;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof PodsInfo
     */
    'ownerRef'?: Array<Owner>;
    /**
     * 
     * @type {string}
     * @memberof PodsInfo
     */
    'templateGroupId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PodsInfo
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PodsInfo
     */
    'annotations'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<PodsInfoResourcesInner>}
     * @memberof PodsInfo
     */
    'resources'?: Array<PodsInfoResourcesInner>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof PodsInfo
     */
    'volumes'?: Array<{ [key: string]: object; }>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const PodsInfoFieldsParams = {
    Id: 'id',
    AccountId: 'accountId',
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    EventTime: 'eventTime',
    ClusterName: 'clusterName',
    Namespace: 'namespace',
    PodName: 'podName',
    PodUid: 'podUid',
    KomodorServiceId: 'komodorServiceId',
    NodeName: 'nodeName',
    RootOwner: 'rootOwner',
    OwnerRef: 'ownerRef',
    TemplateGroupId: 'templateGroupId',
    Labels: 'labels',
    Annotations: 'annotations',
    Resources: 'resources',
    Volumes: 'volumes'
} as const;

export type PodsInfoFieldsParams = typeof PodsInfoFieldsParams[keyof typeof PodsInfoFieldsParams];


        /**
 * 
 * @export
 * @interface PodsInfoRequest
 */
export interface PodsInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof PodsInfoRequest
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfoRequest
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfoRequest
     */
    'podName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodsInfoRequest
     */
    'komodorServiceIds'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PodsInfoRequest
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PodsInfoRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PodsInfoRequest
     */
    'fromEpoch'?: string;
    /**
     * 
     * @type {string}
     * @memberof PodsInfoRequest
     */
    'toEpoch'?: string;
    /**
     * 
     * @type {Array<PodsInfoFieldsParams>}
     * @memberof PodsInfoRequest
     */
    'fields'?: Array<PodsInfoFieldsParams>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodsInfoRequest
     */
    'komodorUids'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface PodsInfoResourcesInner
 */
export interface PodsInfoResourcesInner {
    /**
     * 
     * @type {string}
     * @memberof PodsInfoResourcesInner
     */
    'name'?: string;
    /**
     * 
     * @type {Resource}
     * @memberof PodsInfoResourcesInner
     */
    'requests'?: Resource;
    /**
     * 
     * @type {Resource}
     * @memberof PodsInfoResourcesInner
     */
    'limits'?: Resource;
}
        /**
 * 
 * @export
 * @interface PodsStateResponse
 */
export interface PodsStateResponse {
    /**
     * 
     * @type {PodsStateResponseAllOfData}
     * @memberof PodsStateResponse
     */
    'data'?: PodsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface PodsStateResponseAllOf
 */
export interface PodsStateResponseAllOf {
    /**
     * 
     * @type {PodsStateResponseAllOfData}
     * @memberof PodsStateResponseAllOf
     */
    'data'?: PodsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface PodsStateResponseAllOfData
 */
export interface PodsStateResponseAllOfData {
    /**
     * 
     * @type {Array<PodJson>}
     * @memberof PodsStateResponseAllOfData
     */
    'json'?: Array<PodJson>;
}
        /**
 * 
 * @export
 * @interface PodsStatusAggregationByCluster
 */
export interface PodsStatusAggregationByCluster {
    /**
     * 
     * @type {number}
     * @memberof PodsStatusAggregationByCluster
     */
    'total'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PodsStatusAggregationByCluster
     */
    'statuses'?: { [key: string]: number; };
}
        /**
 * 
 * @export
 * @interface PodsStatusAggregationsResponse
 */
export interface PodsStatusAggregationsResponse {
    /**
     * 
     * @type {{ [key: string]: PodsStatusAggregationByCluster; }}
     * @memberof PodsStatusAggregationsResponse
     */
    'cluster'?: { [key: string]: PodsStatusAggregationByCluster; };
}
        /**
 * 
 * @export
 * @interface PvcEventsResponse
 */
export interface PvcEventsResponse {
    /**
     * 
     * @type {Array<PvcEventsResponseDataInner>}
     * @memberof PvcEventsResponse
     */
    'data': Array<PvcEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface PvcEventsResponseDataInner
 */
export interface PvcEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PvcEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'pvcName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'storageClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'phase'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'accessModes'?: string;
    /**
     * 
     * @type {K8sPersistentVolumeClaimSpec}
     * @memberof PvcEventsResponseDataInner
     */
    'spec'?: K8sPersistentVolumeClaimSpec;
    /**
     * 
     * @type {K8sObjectMeta}
     * @memberof PvcEventsResponseDataInner
     */
    'metadata'?: K8sObjectMeta;
    /**
     * 
     * @type {boolean}
     * @memberof PvcEventsResponseDataInner
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'resourceVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof PvcEventsResponseDataInner
     */
    'creationTimestamp'?: string;
}
        /**
 * 
 * @export
 * @interface RelatedResourceFieldsParam
 */
export interface RelatedResourceFieldsParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourceFieldsParam
     */
    'fields': Array<RelatedResourceFieldsParamFieldsEnum>;
}

export const RelatedResourceFieldsParamFieldsEnum = {
    ConfigMapUids: 'ConfigMapUids',
    SecretUids: 'SecretUids',
    PvcUids: 'PvcUids',
    HpaUids: 'HpaUids',
    K8sServicesUids: 'K8sServicesUids',
    IngressUids: 'IngressUids',
    NodesUids: 'NodesUids',
    PodUids: 'PodUids'
} as const;

export type RelatedResourceFieldsParamFieldsEnum = typeof RelatedResourceFieldsParamFieldsEnum[keyof typeof RelatedResourceFieldsParamFieldsEnum];

        /**
 * 
 * @export
 * @interface RelatedResourcesResponse
 */
export interface RelatedResourcesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'ConfigMapUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'SecretUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'PvcUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'HpaUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'K8sServicesUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'IngressUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'NodesUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedResourcesResponse
     */
    'PodUids'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface ReplicaSet
 */
export interface ReplicaSet {
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'current'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'desired'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'controlled by'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSet
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface ReplicaSetAllOf
 */
export interface ReplicaSetAllOf {
    /**
     * 
     * @type {string}
     * @memberof ReplicaSetAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSetAllOf
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSetAllOf
     */
    'current'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSetAllOf
     */
    'desired'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSetAllOf
     */
    'controlled by'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplicaSetAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface ReplicaSetsStateResponse
 */
export interface ReplicaSetsStateResponse {
    /**
     * 
     * @type {Array<ReplicaSetsStateResponseAllOfData>}
     * @memberof ReplicaSetsStateResponse
     */
    'data'?: Array<ReplicaSetsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface ReplicaSetsStateResponseAllOf
 */
export interface ReplicaSetsStateResponseAllOf {
    /**
     * 
     * @type {Array<ReplicaSetsStateResponseAllOfData>}
     * @memberof ReplicaSetsStateResponseAllOf
     */
    'data'?: Array<ReplicaSetsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface ReplicaSetsStateResponseAllOfData
 */
export interface ReplicaSetsStateResponseAllOfData {
    /**
     * 
     * @type {ReplicaSet}
     * @memberof ReplicaSetsStateResponseAllOfData
     */
    'wide'?: ReplicaSet;
    /**
     * 
     * @type {object}
     * @memberof ReplicaSetsStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'cpu'?: number;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'memory'?: number;
}
        /**
 * 
 * @export
 * @interface ResourceQuota
 */
export interface ResourceQuota {
    /**
     * 
     * @type {string}
     * @memberof ResourceQuota
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuota
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuota
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface ResourceQuotaAllOf
 */
export interface ResourceQuotaAllOf {
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface ResourceQuotaBaseEvent
 */
export interface ResourceQuotaBaseEvent {
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaBaseEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaBaseEvent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaBaseEvent
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaBaseEvent
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaBaseEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceQuotaBaseEvent
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface ResourceQuotaEventsResponse
 */
export interface ResourceQuotaEventsResponse {
    /**
     * 
     * @type {Array<ResourceQuotaEventsResponseDataInner>}
     * @memberof ResourceQuotaEventsResponse
     */
    'data': Array<ResourceQuotaEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface ResourceQuotaEventsResponseDataInner
 */
export interface ResourceQuotaEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'newSpec'?: object;
    /**
     * 
     * @type {object}
     * @memberof ResourceQuotaEventsResponseDataInner
     */
    'oldSpec'?: object;
}
        /**
 * 
 * @export
 * @interface ResourceQuotasStateResponse
 */
export interface ResourceQuotasStateResponse {
    /**
     * 
     * @type {Array<ResourceQuotasStateResponseAllOfData>}
     * @memberof ResourceQuotasStateResponse
     */
    'data'?: Array<ResourceQuotasStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface ResourceQuotasStateResponseAllOf
 */
export interface ResourceQuotasStateResponseAllOf {
    /**
     * 
     * @type {Array<ResourceQuotasStateResponseAllOfData>}
     * @memberof ResourceQuotasStateResponseAllOf
     */
    'data'?: Array<ResourceQuotasStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface ResourceQuotasStateResponseAllOfData
 */
export interface ResourceQuotasStateResponseAllOfData {
    /**
     * 
     * @type {ResourceQuota}
     * @memberof ResourceQuotasStateResponseAllOfData
     */
    'wide'?: ResourceQuota;
    /**
     * 
     * @type {object}
     * @memberof ResourceQuotasStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'fromEpoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'toEpoch'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseMeta
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseMeta
     */
    'afterId'?: string;
}
        /**
 * 
 * @export
 * @interface Secret
 */
export interface Secret {
    /**
     * 
     * @type {string}
     * @memberof Secret
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Secret
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Secret
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof Secret
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Secret
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface SecretAllOf
 */
export interface SecretAllOf {
    /**
     * 
     * @type {string}
     * @memberof SecretAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretAllOf
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface SecretEvent
 */
export interface SecretEvent {
    /**
     * 
     * @type {string}
     * @memberof SecretEvent
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEvent
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEvent
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEvent
     */
    'secretName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretEvent
     */
    'k8sDefault': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecretEvent
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof SecretEvent
     */
    'newData'?: object;
    /**
     * 
     * @type {object}
     * @memberof SecretEvent
     */
    'oldData'?: object;
}
        /**
 * 
 * @export
 * @interface SecretEventAllOf
 */
export interface SecretEventAllOf {
    /**
     * 
     * @type {object}
     * @memberof SecretEventAllOf
     */
    'newData'?: object;
    /**
     * 
     * @type {object}
     * @memberof SecretEventAllOf
     */
    'oldData'?: object;
}
        /**
 * 
 * @export
 * @interface SecretEventBase
 */
export interface SecretEventBase {
    /**
     * 
     * @type {string}
     * @memberof SecretEventBase
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEventBase
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEventBase
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEventBase
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEventBase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SecretEventBase
     */
    'secretName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretEventBase
     */
    'k8sDefault': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecretEventBase
     */
    'services'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface SecretEventsResponse
 */
export interface SecretEventsResponse {
    /**
     * 
     * @type {Array<SecretEvent>}
     * @memberof SecretEventsResponse
     */
    'data': Array<SecretEvent>;
}
        /**
 * 
 * @export
 * @interface SecretsStateResponse
 */
export interface SecretsStateResponse {
    /**
     * 
     * @type {Array<SecretsStateResponseAllOfData>}
     * @memberof SecretsStateResponse
     */
    'data'?: Array<SecretsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface SecretsStateResponseAllOf
 */
export interface SecretsStateResponseAllOf {
    /**
     * 
     * @type {Array<SecretsStateResponseAllOfData>}
     * @memberof SecretsStateResponseAllOf
     */
    'data'?: Array<SecretsStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface SecretsStateResponseAllOfData
 */
export interface SecretsStateResponseAllOfData {
    /**
     * 
     * @type {Secret}
     * @memberof SecretsStateResponseAllOfData
     */
    'wide'?: Secret;
    /**
     * 
     * @type {object}
     * @memberof SecretsStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface SentryData
 */
export interface SentryData {
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'sentryEnv'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'sentryProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'sentryServiceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'sentryVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentryData
     */
    'eventTime'?: string;
}
        /**
 * 
 * @export
 * @interface SentryIssue
 */
export interface SentryIssue {
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'actor'?: string;
    /**
     * 
     * @type {object}
     * @memberof SentryIssue
     */
    'additionalData': object;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'kind': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SentryIssue
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof SentryIssue
     */
    'updatedAt': string;
}
        /**
 * 
 * @export
 * @interface SingleDaemonEndpoint
 */
export interface SingleDaemonEndpoint {
    /**
     * 
     * @type {number}
     * @memberof SingleDaemonEndpoint
     */
    'Port': number;
}
        /**
 * 
 * @export
 * @interface StateBaseObject
 */
export interface StateBaseObject {
    /**
     * 
     * @type {string}
     * @memberof StateBaseObject
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StateBaseObject
     */
    'creationTimestamp': string;
}
        /**
 * 
 * @export
 * @interface StateRequest
 */
export interface StateRequest {
    /**
     * 
     * @type {string}
     * @memberof StateRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateRequest
     */
    'clusterName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StateRequest
     */
    'komodorUids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StateRequest
     */
    'fields'?: Array<StateRequestFieldsEnum>;
}

export const StateRequestFieldsEnum = {
    Wide: 'wide',
    Json: 'json'
} as const;

export type StateRequestFieldsEnum = typeof StateRequestFieldsEnum[keyof typeof StateRequestFieldsEnum];

        /**
 * 
 * @export
 * @interface StatefulSet
 */
export interface StatefulSet {
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSet
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface StatefulSetAllOf
 */
export interface StatefulSetAllOf {
    /**
     * 
     * @type {string}
     * @memberof StatefulSetAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSetAllOf
     */
    'ready'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSetAllOf
     */
    'images'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSetAllOf
     */
    'containers'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatefulSetAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface StatefulSetsStateResponse
 */
export interface StatefulSetsStateResponse {
    /**
     * 
     * @type {StatefulSetsStateResponseAllOfData}
     * @memberof StatefulSetsStateResponse
     */
    'data'?: StatefulSetsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface StatefulSetsStateResponseAllOf
 */
export interface StatefulSetsStateResponseAllOf {
    /**
     * 
     * @type {StatefulSetsStateResponseAllOfData}
     * @memberof StatefulSetsStateResponseAllOf
     */
    'data'?: StatefulSetsStateResponseAllOfData;
}
        /**
 * 
 * @export
 * @interface StatefulSetsStateResponseAllOfData
 */
export interface StatefulSetsStateResponseAllOfData {
    /**
     * 
     * @type {Array<StatefulSet>}
     * @memberof StatefulSetsStateResponseAllOfData
     */
    'wide'?: Array<StatefulSet>;
    /**
     * 
     * @type {Array<object>}
     * @memberof StatefulSetsStateResponseAllOfData
     */
    'json'?: Array<object>;
}
        /**
 * 
 * @export
 * @interface StatusResponseMeta
 */
export interface StatusResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof StatusResponseMeta
     */
    'fromEpoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusResponseMeta
     */
    'toEpoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusResponseMeta
     */
    'nextEpoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusResponseMeta
     */
    'afterId'?: string;
}
        /**
 * 
 * @export
 * @interface StorageClass
 */
export interface StorageClass {
    /**
     * 
     * @type {string}
     * @memberof StorageClass
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StorageClass
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof StorageClass
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClass
     */
    'provisioner'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClass
     */
    'reclaimpolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClass
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface StorageClassAllOf
 */
export interface StorageClassAllOf {
    /**
     * 
     * @type {string}
     * @memberof StorageClassAllOf
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassAllOf
     */
    'provisioner'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassAllOf
     */
    'reclaimpolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassAllOf
     */
    'labels'?: string;
}
        /**
 * 
 * @export
 * @interface StorageClassEventsResponse
 */
export interface StorageClassEventsResponse {
    /**
     * 
     * @type {Array<StorageClassEventsResponseDataInner>}
     * @memberof StorageClassEventsResponse
     */
    'data': Array<StorageClassEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface StorageClassEventsResponseDataInner
 */
export interface StorageClassEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof StorageClassEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassEventsResponseDataInner
     */
    'provisioner'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassEventsResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassEventsResponseDataInner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageClassEventsResponseDataInner
     */
    'eventTime'?: string;
}
        /**
 * 
 * @export
 * @interface StorageClassesStateResponse
 */
export interface StorageClassesStateResponse {
    /**
     * 
     * @type {Array<StorageClassesStateResponseAllOfData>}
     * @memberof StorageClassesStateResponse
     */
    'data'?: Array<StorageClassesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface StorageClassesStateResponseAllOf
 */
export interface StorageClassesStateResponseAllOf {
    /**
     * 
     * @type {Array<StorageClassesStateResponseAllOfData>}
     * @memberof StorageClassesStateResponseAllOf
     */
    'data'?: Array<StorageClassesStateResponseAllOfData>;
}
        /**
 * 
 * @export
 * @interface StorageClassesStateResponseAllOfData
 */
export interface StorageClassesStateResponseAllOfData {
    /**
     * 
     * @type {StorageClass}
     * @memberof StorageClassesStateResponseAllOfData
     */
    'wide'?: StorageClass;
    /**
     * 
     * @type {object}
     * @memberof StorageClassesStateResponseAllOfData
     */
    'json'?: object;
}
        /**
 * 
 * @export
 * @interface Taint
 */
export interface Taint {
    /**
     * 
     * @type {string}
     * @memberof Taint
     */
    'effect': string;
    /**
     * 
     * @type {string}
     * @memberof Taint
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Taint
     */
    'timeAdded'?: string;
    /**
     * 
     * @type {string}
     * @memberof Taint
     */
    'value'?: string;
}
        /**
 * 
 * @export
 * @interface Topology
 */
export interface Topology {
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'details': string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'kind': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Topology
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Topology
     */
    'namespace'?: string;
}
        /**
 * 
 * @export
 * @interface UncountedTerminatedPods
 */
export interface UncountedTerminatedPods {
    /**
     * 
     * @type {Array<string>}
     * @memberof UncountedTerminatedPods
     */
    'failed'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UncountedTerminatedPods
     */
    'succeeded'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface WorkloadEventsResponse
 */
export interface WorkloadEventsResponse {
    /**
     * 
     * @type {Array<WorkloadEventsResponseDataInner>}
     * @memberof WorkloadEventsResponse
     */
    'data': Array<WorkloadEventsResponseDataInner>;
}
        /**
 * 
 * @export
 * @interface WorkloadEventsResponseDataInner
 */
export interface WorkloadEventsResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'agentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'clusterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'kind'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadEventsResponseDataInner
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof WorkloadEventsResponseDataInner
     */
    'readyReplicas'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkloadEventsResponseDataInner
     */
    'desiredReplicas'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkloadEventsResponseDataInner
     */
    'generation'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkloadEventsResponseDataInner
     */
    'resourceVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkloadEventsResponseDataInner
     */
    'creationTimestamp'?: string;
}
    
        /**
* Count the number of services and jobs in the system, return count per kind, and health / job status
* @param params AggregationsApiApiV1AggregationsKomodorServicesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AggregationsKomodorServicesGetUrl = (params: AggregationsApiApiV1AggregationsKomodorServicesGetRequest, baseUrl: string): string => {
    // verify required parameter 'groupBy' is not null or undefined
    assertParamExists('apiV1AggregationsKomodorServicesGet', 'groupBy', params.groupBy)
    const localVarPath = `/api/v1/aggregations/komodor-services`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.groupBy) {
        localVarQueryParameter['groupBy'] = params.groupBy;
    }
    if (params.type) {
        localVarQueryParameter['type'] = params.type;
    }
    if (params.unhealthyServicesOnly !== undefined) {
            localVarQueryParameter['unhealthyServicesOnly'] = params.unhealthyServicesOnly;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Aggregates current nodes by cluster, status
* @param params AggregationsApiApiV1AggregationsNodesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AggregationsNodesGetUrl = (params: AggregationsApiApiV1AggregationsNodesGetRequest, baseUrl: string): string => {
    // verify required parameter 'groupBy' is not null or undefined
    assertParamExists('apiV1AggregationsNodesGet', 'groupBy', params.groupBy)
    const localVarPath = `/api/v1/aggregations/nodes`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.groupBy) {
        localVarQueryParameter['groupBy'] = params.groupBy;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Gets pods status aggregation
* @param params AggregationsApiApiV1AggregationsPodsStatusGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1AggregationsPodsStatusGetUrl = (params: AggregationsApiApiV1AggregationsPodsStatusGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/aggregations/pods/status`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1AggregationsKomodorServicesGet operation in AggregationsApi.
* @export
* @interface AggregationsApiApiV1AggregationsKomodorServicesGetRequest
*/
export interface AggregationsApiApiV1AggregationsKomodorServicesGetRequest {
    /**
    * 
    * @type {Array<KomodorServicesAggregationGroupBy>}
    * @memberof AggregationsApiApiV1AggregationsKomodorServicesGet
    */
    readonly groupBy: Array<KomodorServicesAggregationGroupBy>

    /**
    * 
    * @type {Array<string>}
    * @memberof AggregationsApiApiV1AggregationsKomodorServicesGet
    */
    readonly clusterNames?: Array<string>

    /**
    * 
    * @type {Array<KomodorServicesAggregationsDataType>}
    * @memberof AggregationsApiApiV1AggregationsKomodorServicesGet
    */
    readonly type?: Array<KomodorServicesAggregationsDataType>

    /**
    * 
    * @type {boolean}
    * @memberof AggregationsApiApiV1AggregationsKomodorServicesGet
    */
    readonly unhealthyServicesOnly?: boolean

    /**
    * 
    * @type {string}
    * @memberof AggregationsApiApiV1AggregationsKomodorServicesGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1AggregationsNodesGet operation in AggregationsApi.
* @export
* @interface AggregationsApiApiV1AggregationsNodesGetRequest
*/
export interface AggregationsApiApiV1AggregationsNodesGetRequest {
    /**
    * 
    * @type {Array<NodesAggregationType>}
    * @memberof AggregationsApiApiV1AggregationsNodesGet
    */
    readonly groupBy: Array<NodesAggregationType>

    /**
    * 
    * @type {string}
    * @memberof AggregationsApiApiV1AggregationsNodesGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof AggregationsApiApiV1AggregationsNodesGet
    */
    readonly clusterName?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof AggregationsApiApiV1AggregationsNodesGet
    */
    readonly order?: 'ASC' | 'DESC'
}

/**
* Request parameters for apiV1AggregationsPodsStatusGet operation in AggregationsApi.
* @export
* @interface AggregationsApiApiV1AggregationsPodsStatusGetRequest
*/
export interface AggregationsApiApiV1AggregationsPodsStatusGetRequest {
    /**
    * 
    * @type {string}
    * @memberof AggregationsApiApiV1AggregationsPodsStatusGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof AggregationsApiApiV1AggregationsPodsStatusGet
    */
    readonly clusterName?: string
}


    
        /**
* 
* @param params AppViewApiApiV1KomodorServicesAppViewIdClustersGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesAppViewIdClustersGetUrl = (params: AppViewApiApiV1KomodorServicesAppViewIdClustersGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1KomodorServicesAppViewIdClustersGet', 'id', params.id)
    const localVarPath = `/api/v1/komodor-services/app-view/:id/clusters`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1KomodorServicesAppViewIdClustersGet operation in AppViewApi.
* @export
* @interface AppViewApiApiV1KomodorServicesAppViewIdClustersGetRequest
*/
export interface AppViewApiApiV1KomodorServicesAppViewIdClustersGetRequest {
    /**
    * 
    * @type {string}
    * @memberof AppViewApiApiV1KomodorServicesAppViewIdClustersGet
    */
    readonly id: string
}


    
        /**
* 
* @param params CrossClusterDataTODOApiApiV1ClustersAnnotationsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersAnnotationsGetUrl = (params: CrossClusterDataTODOApiApiV1ClustersAnnotationsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters/annotations`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params CrossClusterDataTODOApiApiV1ClustersLabelsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersLabelsGetUrl = (params: CrossClusterDataTODOApiApiV1ClustersLabelsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters/labels`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ClustersAnnotationsGet operation in CrossClusterDataTODOApi.
* @export
* @interface CrossClusterDataTODOApiApiV1ClustersAnnotationsGetRequest
*/
export interface CrossClusterDataTODOApiApiV1ClustersAnnotationsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof CrossClusterDataTODOApiApiV1ClustersAnnotationsGet
    */
    readonly clusterName?: string
}

/**
* Request parameters for apiV1ClustersLabelsGet operation in CrossClusterDataTODOApi.
* @export
* @interface CrossClusterDataTODOApiApiV1ClustersLabelsGetRequest
*/
export interface CrossClusterDataTODOApiApiV1ClustersLabelsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof CrossClusterDataTODOApiApiV1ClustersLabelsGet
    */
    readonly clusterName?: string
}


    
        /**
* 
* @param params EventsApiApiV1ConfigurationsConfigMapsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsConfigMapsEventsIdGetUrl = (params: EventsApiApiV1ConfigurationsConfigMapsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ConfigurationsConfigMapsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/configurations/config-maps/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsConfigMapsEventsSearchGetUrl = (params: EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/config-maps/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsHpasEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsHpasEventsIdGetUrl = (params: EventsApiApiV1ConfigurationsHpasEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ConfigurationsHpasEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/configurations/hpas/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsHpasEventsSearchGetUrl = (params: EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/hpas/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsLimitRangesEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsLimitRangesEventsIdGetUrl = (params: EventsApiApiV1ConfigurationsLimitRangesEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ConfigurationsLimitRangesEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/configurations/limit-ranges/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsPdbsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsPdbsEventsIdGetUrl = (params: EventsApiApiV1ConfigurationsPdbsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ConfigurationsPdbsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/configurations/pdbs/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsPdbsEventsSearchGetUrl = (params: EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/pdbs/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsResourceQuotasEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsResourceQuotasEventsIdGetUrl = (params: EventsApiApiV1ConfigurationsResourceQuotasEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ConfigurationsResourceQuotasEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/configurations/resource-quotas/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsSecretsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsSecretsEventsIdGetUrl = (params: EventsApiApiV1ConfigurationsSecretsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1ConfigurationsSecretsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/configurations/secrets/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsSecretsEventsSearchGetUrl = (params: EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/secrets/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsClustersGetUrl = (baseUrl: string): string => {
    const localVarPath = `/api/v1/crd/argoworkflows/clusters`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsCronworkflowsEventsIdGetUrl = (params: EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1CrdArgoworkflowsCronworkflowsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/crd/argoworkflows/cronworkflows/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsCronworkflowsEventsSearchGetUrl = (params: EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/crd/argoworkflows/cronworkflows/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGetUrl = (params: EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/crd/argoworkflows/cronworkflows/events/search/grouped`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsWorkflowsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsWorkflowsEventsIdGetUrl = (params: EventsApiApiV1CrdArgoworkflowsWorkflowsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1CrdArgoworkflowsWorkflowsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/crd/argoworkflows/workflows/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsWorkflowsEventsSearchGetUrl = (params: EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/crd/argoworkflows/workflows/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.k8sUid !== undefined) {
            localVarQueryParameter['k8sUid'] = params.k8sUid;
    }
    if (params.ownerKomodorUid !== undefined) {
            localVarQueryParameter['ownerKomodorUid'] = params.ownerKomodorUid;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGetUrl = (params: EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/crd/argoworkflows/workflows/events/search/grouped`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGetUrl = (params: EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/crd/argoworkflows/workflowtemplates/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGetUrl = (params: EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/crd/argoworkflows/workflowtemplates/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.scope !== undefined) {
            localVarQueryParameter['scope'] = params.scope;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CustomEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CustomEventsSearchGetUrl = (params: EventsApiApiV1CustomEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/custom/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1CustomServiceEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CustomServiceEventsSearchGetUrl = (params: EventsApiApiV1CustomServiceEventsSearchGetRequest, baseUrl: string): string => {
    // verify required parameter 'komodorUids' is not null or undefined
    assertParamExists('apiV1CustomServiceEventsSearchGet', 'komodorUids', params.komodorUids)
    const localVarPath = `/api/v1/custom/service/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Those event are taken from event_deploy table
* @param params EventsApiApiV1DeploysEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1DeploysEventsIdGetUrl = (params: EventsApiApiV1DeploysEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1DeploysEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/deploys/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Those event are taken from event_deploy table
* @param params EventsApiApiV1DeploysEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1DeploysEventsSearchGetUrl = (params: EventsApiApiV1DeploysEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/deploys/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.serviceIds) {
        localVarQueryParameter['serviceIds'] = params.serviceIds;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.filterOutAutoDeploys !== undefined) {
            localVarQueryParameter['filterOutAutoDeploys'] = params.filterOutAutoDeploys;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1EventsGeneralGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1EventsGeneralGetUrl = (params: EventsApiApiV1EventsGeneralGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/events/general`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NetworkIngressesEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkIngressesEventsIdGetUrl = (params: EventsApiApiV1NetworkIngressesEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1NetworkIngressesEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/network/ingresses/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NetworkIngressesEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkIngressesEventsSearchGetUrl = (params: EventsApiApiV1NetworkIngressesEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/ingresses/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.servicesIds) {
        localVarQueryParameter['servicesIds'] = params.servicesIds.join(COLLECTION_FORMATS.csv);
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NetworkMapsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkMapsEventsSearchGetUrl = (params: EventsApiApiV1NetworkMapsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network-maps/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NetworkNetworkPoliciesEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkNetworkPoliciesEventsIdGetUrl = (params: EventsApiApiV1NetworkNetworkPoliciesEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1NetworkNetworkPoliciesEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/network/network-policies/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NetworkServicesEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkServicesEventsIdGetUrl = (params: EventsApiApiV1NetworkServicesEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1NetworkServicesEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/network/services/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NetworkServicesEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkServicesEventsSearchGetUrl = (params: EventsApiApiV1NetworkServicesEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/services/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NodesByAffectedResourcesEventsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesByAffectedResourcesEventsGetUrl = (params: EventsApiApiV1NodesByAffectedResourcesEventsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/nodes/by-affected-resources/events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NodesEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesEventsIdGetUrl = (params: EventsApiApiV1NodesEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1NodesEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/nodes/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NodesEventsIdsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesEventsIdsGetUrl = (params: EventsApiApiV1NodesEventsIdsGetRequest, baseUrl: string): string => {
    // verify required parameter 'ids' is not null or undefined
    assertParamExists('apiV1NodesEventsIdsGet', 'ids', params.ids)
    const localVarPath = `/api/v1/nodes/events/ids`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.ids) {
        localVarQueryParameter['ids'] = params.ids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NodesEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesEventsSearchGetUrl = (params: EventsApiApiV1NodesEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/nodes/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.ready !== undefined) {
            localVarQueryParameter['ready'] = params.ready;
    }
    if (params.readyMessage !== undefined) {
            localVarQueryParameter['readyMessage'] = params.readyMessage;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions.join(COLLECTION_FORMATS.csv);
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1NodesTerminationImpactEventsPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesTerminationImpactEventsPutUrl = (params: EventsApiApiV1NodesTerminationImpactEventsPutRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/nodes/termination-impact/events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1StorageLatestPvcsEventsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StorageLatestPvcsEventsGetUrl = (params: EventsApiApiV1StorageLatestPvcsEventsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/storage/latest-pvcs/events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fromCreationTimestampEpoch !== undefined) {
            localVarQueryParameter['fromCreationTimestampEpoch'] = params.fromCreationTimestampEpoch;
    }
    if (params.creationTimestampOrder !== undefined) {
            localVarQueryParameter['creationTimestampOrder'] = params.creationTimestampOrder;
    }
    if (params.resourceVersionOrder !== undefined) {
            localVarQueryParameter['resourceVersionOrder'] = params.resourceVersionOrder;
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    if (params.resourceVersion !== undefined) {
            localVarQueryParameter['resourceVersion'] = params.resourceVersion;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1StoragePvcsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StoragePvcsEventsIdGetUrl = (params: EventsApiApiV1StoragePvcsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1StoragePvcsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/storage/pvcs/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1StoragePvcsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StoragePvcsEventsSearchGetUrl = (params: EventsApiApiV1StoragePvcsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/storage/pvcs/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.fromCreationTimestampEpoch !== undefined) {
            localVarQueryParameter['fromCreationTimestampEpoch'] = params.fromCreationTimestampEpoch;
    }
    if (params.creationTimestampOrder !== undefined) {
            localVarQueryParameter['creationTimestampOrder'] = params.creationTimestampOrder;
    }
    if (params.resourceVersionOrder !== undefined) {
            localVarQueryParameter['resourceVersionOrder'] = params.resourceVersionOrder;
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    if (params.resourceVersionBiggerOrEqual !== undefined) {
            localVarQueryParameter['resourceVersionBiggerOrEqual'] = params.resourceVersionBiggerOrEqual;
    }
    if (params.phase !== undefined) {
            localVarQueryParameter['phase'] = params.phase;
    }
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1StorageStorageClassesEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StorageStorageClassesEventsSearchGetUrl = (params: EventsApiApiV1StorageStorageClassesEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/storage/storage-classes/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.provisioner !== undefined) {
            localVarQueryParameter['provisioner'] = params.provisioner;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1WorkloadsCronJobsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsCronJobsEventsSearchGetUrl = (params: EventsApiApiV1WorkloadsCronJobsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/cron-jobs/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions.join(COLLECTION_FORMATS.csv);
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1WorkloadsDeletedPodsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsDeletedPodsEventsIdGetUrl = (params: EventsApiApiV1WorkloadsDeletedPodsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkloadsDeletedPodsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/workloads/deleted-pods/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.ownerKomodorUid !== undefined) {
            localVarQueryParameter['ownerKomodorUid'] = params.ownerKomodorUid;
    }
    if (params.resourceOwnerUid !== undefined) {
            localVarQueryParameter['resourceOwnerUid'] = params.resourceOwnerUid;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.nodeNames) {
        localVarQueryParameter['nodeNames'] = params.nodeNames;
    }
    if (params.getIps !== undefined) {
            localVarQueryParameter['getIps'] = params.getIps;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsDeletedPodsEventsSearchGetUrl = (params: EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/deleted-pods/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.ownerKomodorUid !== undefined) {
            localVarQueryParameter['ownerKomodorUid'] = params.ownerKomodorUid;
    }
    if (params.resourceOwnerUid !== undefined) {
            localVarQueryParameter['resourceOwnerUid'] = params.resourceOwnerUid;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.nodeNames) {
        localVarQueryParameter['nodeNames'] = params.nodeNames;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.getIps !== undefined) {
            localVarQueryParameter['getIps'] = params.getIps;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.statusReason !== undefined) {
            localVarQueryParameter['statusReason'] = params.statusReason;
    }
    if (params.metadata !== undefined) {
            localVarQueryParameter['metadata'] = params.metadata;
    }
    if (params.labels !== undefined) {
            localVarQueryParameter['labels'] = params.labels;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* those are taken from `event_workload` table
* @param params EventsApiApiV1WorkloadsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsEventsIdGetUrl = (params: EventsApiApiV1WorkloadsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkloadsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/workloads/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* those are taken from `event_workload` table
* @param params EventsApiApiV1WorkloadsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsEventsSearchGetUrl = (params: EventsApiApiV1WorkloadsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.fromCreationTimestampEpoch !== undefined) {
            localVarQueryParameter['fromCreationTimestampEpoch'] = params.fromCreationTimestampEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.servicesIds) {
        localVarQueryParameter['servicesIds'] = params.servicesIds.join(COLLECTION_FORMATS.csv);
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.queryRR !== undefined) {
            localVarQueryParameter['queryRR'] = params.queryRR;
    }
    if (params.creationTimestampOrder !== undefined) {
            localVarQueryParameter['creationTimestampOrder'] = params.creationTimestampOrder;
    }
    if (params.resourceVersionOrder !== undefined) {
            localVarQueryParameter['resourceVersionOrder'] = params.resourceVersionOrder;
    }
    if (params.k8sUid !== undefined) {
            localVarQueryParameter['k8sUid'] = params.k8sUid;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.generation !== undefined) {
            localVarQueryParameter['generation'] = params.generation;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1WorkloadsJobsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsJobsEventsIdGetUrl = (params: EventsApiApiV1WorkloadsJobsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkloadsJobsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/workloads/jobs/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1WorkloadsJobsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsJobsEventsSearchGetUrl = (params: EventsApiApiV1WorkloadsJobsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/jobs/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.serviceIds) {
        localVarQueryParameter['serviceIds'] = params.serviceIds;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.action !== undefined) {
            localVarQueryParameter['action'] = params.action;
    }
    if (params.uid !== undefined) {
            localVarQueryParameter['uid'] = params.uid;
    }
    if (params.cronJobUid !== undefined) {
            localVarQueryParameter['cronJobUid'] = params.cronJobUid;
    }
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsApiApiV1WorkloadsPodsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsPodsEventsSearchGetUrl = (params: EventsApiApiV1WorkloadsPodsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/pods/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    if (params.reason !== undefined) {
            localVarQueryParameter['reason'] = params.reason;
    }
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.podUid !== undefined) {
            localVarQueryParameter['podUid'] = params.podUid;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.ids) {
        localVarQueryParameter['ids'] = params.ids;
    }
    if (params.ownerUid !== undefined) {
            localVarQueryParameter['ownerUid'] = params.ownerUid;
    }
    if (params.pvcNamesLengthBiggerThen !== undefined) {
            localVarQueryParameter['pvcNamesLengthBiggerThen'] = params.pvcNamesLengthBiggerThen;
    }
    if (params.pvcNamesIncludes !== undefined) {
            localVarQueryParameter['pvcNamesIncludes'] = params.pvcNamesIncludes;
    }
    if (params.phase !== undefined) {
            localVarQueryParameter['Phase'] = params.phase;
    }
    if (params.distinctPodName !== undefined) {
            localVarQueryParameter['distinctPodName'] = params.distinctPodName;
    }
    if (params.podNameOrder !== undefined) {
            localVarQueryParameter['podNameOrder'] = params.podNameOrder;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ConfigurationsConfigMapsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsConfigMapsEventsIdGetRequest
*/
export interface EventsApiApiV1ConfigurationsConfigMapsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'configMapName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsIdGet
    */
    readonly fields?: Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'configMapName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>
}

/**
* Request parameters for apiV1ConfigurationsConfigMapsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest
*/
export interface EventsApiApiV1ConfigurationsConfigMapsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'configMapName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>}
    * @memberof EventsApiApiV1ConfigurationsConfigMapsEventsSearchGet
    */
    readonly fields?: Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'configMapName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>
}

/**
* Request parameters for apiV1ConfigurationsHpasEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsHpasEventsIdGetRequest
*/
export interface EventsApiApiV1ConfigurationsHpasEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'oldDesiredReplicas' | 'newDesiredReplicas' | 'minReplicas' | 'maxReplicas' | 'newSpec' | 'oldSpec' | 'eventTime' | 'services' | 'action'>}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'oldDesiredReplicas' | 'newDesiredReplicas' | 'minReplicas' | 'maxReplicas' | 'newSpec' | 'oldSpec' | 'eventTime' | 'services' | 'action'>
}

/**
* Request parameters for apiV1ConfigurationsHpasEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest
*/
export interface EventsApiApiV1ConfigurationsHpasEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'oldDesiredReplicas' | 'newDesiredReplicas' | 'minReplicas' | 'maxReplicas' | 'newSpec' | 'oldSpec' | 'eventTime' | 'services' | 'action'>}
    * @memberof EventsApiApiV1ConfigurationsHpasEventsSearchGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'oldDesiredReplicas' | 'newDesiredReplicas' | 'minReplicas' | 'maxReplicas' | 'newSpec' | 'oldSpec' | 'eventTime' | 'services' | 'action'>
}

/**
* Request parameters for apiV1ConfigurationsLimitRangesEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsLimitRangesEventsIdGetRequest
*/
export interface EventsApiApiV1ConfigurationsLimitRangesEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsLimitRangesEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>}
    * @memberof EventsApiApiV1ConfigurationsLimitRangesEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>
}

/**
* Request parameters for apiV1ConfigurationsPdbsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsPdbsEventsIdGetRequest
*/
export interface EventsApiApiV1ConfigurationsPdbsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action'>}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action'>
}

/**
* Request parameters for apiV1ConfigurationsPdbsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest
*/
export interface EventsApiApiV1ConfigurationsPdbsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action'>}
    * @memberof EventsApiApiV1ConfigurationsPdbsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action'>
}

/**
* Request parameters for apiV1ConfigurationsResourceQuotasEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsResourceQuotasEventsIdGetRequest
*/
export interface EventsApiApiV1ConfigurationsResourceQuotasEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsResourceQuotasEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>}
    * @memberof EventsApiApiV1ConfigurationsResourceQuotasEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>
}

/**
* Request parameters for apiV1ConfigurationsSecretsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsSecretsEventsIdGetRequest
*/
export interface EventsApiApiV1ConfigurationsSecretsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'secretName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsIdGet
    */
    readonly fields?: Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'secretName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>
}

/**
* Request parameters for apiV1ConfigurationsSecretsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest
*/
export interface EventsApiApiV1ConfigurationsSecretsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'secretName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>}
    * @memberof EventsApiApiV1ConfigurationsSecretsEventsSearchGet
    */
    readonly fields?: Array<'action' | 'clusterName' | 'eventTime' | 'namespace' | 'id' | 'secretName' | 'k8sDefault' | 'services' | 'newData' | 'oldData'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsCronworkflowsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsIdGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'templateId' | 'schedule' | 'suspend' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsIdGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'templateId' | 'schedule' | 'suspend' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsCronworkflowsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'templateId' | 'schedule' | 'suspend' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'templateId' | 'schedule' | 'suspend' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'templateId' | 'schedule' | 'suspend' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsCronworkflowsEventsSearchGroupedGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'templateId' | 'schedule' | 'suspend' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsWorkflowsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsWorkflowsEventsIdGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsWorkflowsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'uid' | 'ownerUid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'selector' | 'templateId' | 'isStandalone' | 'isRetry' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsIdGet
    */
    readonly fields?: Array<'id' | 'uid' | 'ownerUid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'selector' | 'templateId' | 'isStandalone' | 'isRetry' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsWorkflowsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly k8sUid?: string

    /**
    * The owner Id of the Argo workflow.
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly ownerKomodorUid?: string

    /**
    * 
    * @type {Array<'id' | 'uid' | 'ownerUid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'selector' | 'templateId' | 'isStandalone' | 'isRetry' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'uid' | 'ownerUid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'selector' | 'templateId' | 'isStandalone' | 'isRetry' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'uid' | 'ownerUid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'selector' | 'templateId' | 'isStandalone' | 'isRetry' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowsEventsSearchGroupedGet
    */
    readonly fields?: Array<'id' | 'uid' | 'ownerUid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'selector' | 'templateId' | 'isStandalone' | 'isRetry' | 'eventTime' | 'status' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'scope' | 'eventTime' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsIdGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'scope' | 'eventTime' | 'services' | 'newObj' | 'oldObj'>
}

/**
* Request parameters for apiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGetRequest
*/
export interface EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'scope' | 'eventTime' | 'services' | 'newObj' | 'oldObj'>}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'namespace' | 'clusterName' | 'action' | 'scope' | 'eventTime' | 'services' | 'newObj' | 'oldObj'>

    /**
    * 
    * @type {'cluster' | 'namespace' | '*'}
    * @memberof EventsApiApiV1CrdArgoworkflowsWorkflowtemplatesEventsSearchGet
    */
    readonly scope?: 'cluster' | 'namespace' | '*'
}

/**
* Request parameters for apiV1CustomEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CustomEventsSearchGetRequest
*/
export interface EventsApiApiV1CustomEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CustomEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CustomEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CustomEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CustomEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'clusters' | 'namespaces' | 'servicesNames' | 'severity' | 'eventType' | 'summary' | 'details'>}
    * @memberof EventsApiApiV1CustomEventsSearchGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'clusters' | 'namespaces' | 'servicesNames' | 'severity' | 'eventType' | 'summary' | 'details'>
}

/**
* Request parameters for apiV1CustomServiceEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1CustomServiceEventsSearchGetRequest
*/
export interface EventsApiApiV1CustomServiceEventsSearchGetRequest {
    /**
    * note that only one uid is supported (the first one only will be used)
    * @type {Array<string>}
    * @memberof EventsApiApiV1CustomServiceEventsSearchGet
    */
    readonly komodorUids: Array<string>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CustomServiceEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1CustomServiceEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1CustomServiceEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1CustomServiceEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'clusters' | 'namespaces' | 'servicesNames' | 'severity' | 'eventType' | 'summary' | 'details'>}
    * @memberof EventsApiApiV1CustomServiceEventsSearchGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'clusters' | 'namespaces' | 'servicesNames' | 'severity' | 'eventType' | 'summary' | 'details'>
}

/**
* Request parameters for apiV1DeploysEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1DeploysEventsIdGetRequest
*/
export interface EventsApiApiV1DeploysEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1DeploysEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>}
    * @memberof EventsApiApiV1DeploysEventsIdGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>
}

/**
* Request parameters for apiV1DeploysEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1DeploysEventsSearchGetRequest
*/
export interface EventsApiApiV1DeploysEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly serviceIds?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'endEventTime' | 'deploymentName' | 'versionFrom' | 'versionTo' | 'services' | 'newSpec' | 'oldSpec' | 'kubernetesConfigChanges' | 'deploymentDiffKeys' | 'updatedAt' | 'gitCompare' | 'isEmptyDeployment' | 'status' | 'statusMessage' | 'statusReason' | 'changeType' | 'generation' | 'trackedFiles' | 'failed_container' | 'strategy' | 'clusterName' | 'namespace'>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1DeploysEventsSearchGet
    */
    readonly filterOutAutoDeploys?: boolean
}

/**
* Request parameters for apiV1EventsGeneralGet operation in EventsApi.
* @export
* @interface EventsApiApiV1EventsGeneralGetRequest
*/
export interface EventsApiApiV1EventsGeneralGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1EventsGeneralGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1EventsGeneralGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1EventsGeneralGet
    */
    readonly komodorUids?: Array<string>
}

/**
* Request parameters for apiV1NetworkIngressesEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NetworkIngressesEventsIdGetRequest
*/
export interface EventsApiApiV1NetworkIngressesEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkIngressesEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action' | 'kubernetesServiceResourceNames'>}
    * @memberof EventsApiApiV1NetworkIngressesEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action' | 'kubernetesServiceResourceNames'>
}

/**
* Request parameters for apiV1NetworkIngressesEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NetworkIngressesEventsSearchGetRequest
*/
export interface EventsApiApiV1NetworkIngressesEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly servicesIds?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action' | 'kubernetesServiceResourceNames'>}
    * @memberof EventsApiApiV1NetworkIngressesEventsSearchGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec' | 'action' | 'kubernetesServiceResourceNames'>
}

/**
* Request parameters for apiV1NetworkMapsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NetworkMapsEventsSearchGetRequest
*/
export interface EventsApiApiV1NetworkMapsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly afterId?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'eventTime' | 'clusterName' | 'srcService' | 'srcServiceKind' | 'srcNamespace' | 'destService' | 'destServiceKind' | 'destNamespace' | 'networkType' | 'tags' | 'changeType'>}
    * @memberof EventsApiApiV1NetworkMapsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'eventTime' | 'clusterName' | 'srcService' | 'srcServiceKind' | 'srcNamespace' | 'destService' | 'destServiceKind' | 'destNamespace' | 'networkType' | 'tags' | 'changeType'>
}

/**
* Request parameters for apiV1NetworkNetworkPoliciesEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NetworkNetworkPoliciesEventsIdGetRequest
*/
export interface EventsApiApiV1NetworkNetworkPoliciesEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkNetworkPoliciesEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>}
    * @memberof EventsApiApiV1NetworkNetworkPoliciesEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>
}

/**
* Request parameters for apiV1NetworkServicesEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NetworkServicesEventsIdGetRequest
*/
export interface EventsApiApiV1NetworkServicesEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkServicesEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newData' | 'oldData' | 'action'>}
    * @memberof EventsApiApiV1NetworkServicesEventsIdGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newData' | 'oldData' | 'action'>
}

/**
* Request parameters for apiV1NetworkServicesEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NetworkServicesEventsSearchGetRequest
*/
export interface EventsApiApiV1NetworkServicesEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkServicesEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NetworkServicesEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NetworkServicesEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1NetworkServicesEventsSearchGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1NetworkServicesEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newData' | 'oldData' | 'action'>}
    * @memberof EventsApiApiV1NetworkServicesEventsSearchGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newData' | 'oldData' | 'action'>
}

/**
* Request parameters for apiV1NodesByAffectedResourcesEventsGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NodesByAffectedResourcesEventsGetRequest
*/
export interface EventsApiApiV1NodesByAffectedResourcesEventsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesByAffectedResourcesEventsGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesByAffectedResourcesEventsGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1NodesByAffectedResourcesEventsGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1NodesByAffectedResourcesEventsGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NodesByAffectedResourcesEventsGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>}
    * @memberof EventsApiApiV1NodesByAffectedResourcesEventsGet
    */
    readonly fields?: Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>
}

/**
* Request parameters for apiV1NodesEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NodesEventsIdGetRequest
*/
export interface EventsApiApiV1NodesEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>}
    * @memberof EventsApiApiV1NodesEventsIdGet
    */
    readonly fields?: Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1NodesEventsIdGet
    */
    readonly queryRR?: boolean
}

/**
* Request parameters for apiV1NodesEventsIdsGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NodesEventsIdsGetRequest
*/
export interface EventsApiApiV1NodesEventsIdsGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NodesEventsIdsGet
    */
    readonly ids: Array<string>

    /**
    * 
    * @type {Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>}
    * @memberof EventsApiApiV1NodesEventsIdsGet
    */
    readonly fields?: Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>
}

/**
* Request parameters for apiV1NodesEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1NodesEventsSearchGetRequest
*/
export interface EventsApiApiV1NodesEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly afterId?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly ready?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly readyMessage?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'Create' | 'Update' | 'Delete'>}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly actions?: Array<'Create' | 'Update' | 'Delete'>

    /**
    * 
    * @type {Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly fields?: Array<'id' | 'nodeName' | 'eventTime' | 'ready' | 'readyReason' | 'readyMessage' | 'memoryPressure' | 'diskPressure' | 'lastHeartbeatTime' | 'capacity' | 'allocatable' | 'isDeleted' | 'services' | 'clusterName' | 'action' | 'nodeCreationTime' | 'region' | 'zone' | 'instanceType' | 'allocationType' | 'os' | 'cloudProvider' | 'terminationReason' | 'accountId' | 'agentId' | 'newObj' | 'oldObj' | 'nodeInfo' | 'event_node_termination_impact' | 'event_node_enrichment'>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly queryRR?: boolean

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1NodesEventsSearchGet
    */
    readonly clusterName?: string
}

/**
* Request parameters for apiV1NodesTerminationImpactEventsPut operation in EventsApi.
* @export
* @interface EventsApiApiV1NodesTerminationImpactEventsPutRequest
*/
export interface EventsApiApiV1NodesTerminationImpactEventsPutRequest {
    /**
    * 
    * @type {NodeTerminationImpact}
    * @memberof EventsApiApiV1NodesTerminationImpactEventsPut
    */
    readonly nodeTerminationImpact?: NodeTerminationImpact
}

/**
* Request parameters for apiV1StorageLatestPvcsEventsGet operation in EventsApi.
* @export
* @interface EventsApiApiV1StorageLatestPvcsEventsGetRequest
*/
export interface EventsApiApiV1StorageLatestPvcsEventsGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'pvcName' | 'storageClassName' | 'phase' | 'message' | 'reason' | 'accessModes' | 'spec' | 'metadata' | 'isDeleted' | 'resourceVersion' | 'creationTimestamp'>}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'pvcName' | 'storageClassName' | 'phase' | 'message' | 'reason' | 'accessModes' | 'spec' | 'metadata' | 'isDeleted' | 'resourceVersion' | 'creationTimestamp'>

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly fromCreationTimestampEpoch?: string

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly creationTimestampOrder?: 'ASC' | 'DESC'

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly resourceVersionOrder?: 'ASC' | 'DESC'

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly queryRR?: boolean

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StorageLatestPvcsEventsGet
    */
    readonly resourceVersion?: string
}

/**
* Request parameters for apiV1StoragePvcsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1StoragePvcsEventsIdGetRequest
*/
export interface EventsApiApiV1StoragePvcsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StoragePvcsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'pvcName' | 'storageClassName' | 'phase' | 'message' | 'reason' | 'accessModes' | 'spec' | 'metadata' | 'isDeleted' | 'resourceVersion' | 'creationTimestamp'>}
    * @memberof EventsApiApiV1StoragePvcsEventsIdGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'pvcName' | 'storageClassName' | 'phase' | 'message' | 'reason' | 'accessModes' | 'spec' | 'metadata' | 'isDeleted' | 'resourceVersion' | 'creationTimestamp'>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1StoragePvcsEventsIdGet
    */
    readonly queryRR?: boolean
}

/**
* Request parameters for apiV1StoragePvcsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1StoragePvcsEventsSearchGetRequest
*/
export interface EventsApiApiV1StoragePvcsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'pvcName' | 'storageClassName' | 'phase' | 'message' | 'reason' | 'accessModes' | 'spec' | 'metadata' | 'isDeleted' | 'resourceVersion' | 'creationTimestamp'>}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'pvcName' | 'storageClassName' | 'phase' | 'message' | 'reason' | 'accessModes' | 'spec' | 'metadata' | 'isDeleted' | 'resourceVersion' | 'creationTimestamp'>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly fromCreationTimestampEpoch?: string

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly creationTimestampOrder?: 'ASC' | 'DESC'

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly resourceVersionOrder?: 'ASC' | 'DESC'

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly queryRR?: boolean

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly resourceVersionBiggerOrEqual?: number

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly phase?: string

    /**
    * isDeleted can be used only with phase param, causing \&#39;AND \&quot;isDeleted\&quot; &#x3D; true OR \&quot;phase\&quot; &#x3D; \&quot;pending\&quot;\&#39; to be added to the query
    * @type {boolean}
    * @memberof EventsApiApiV1StoragePvcsEventsSearchGet
    */
    readonly isDeleted?: boolean
}

/**
* Request parameters for apiV1StorageStorageClassesEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1StorageStorageClassesEventsSearchGetRequest
*/
export interface EventsApiApiV1StorageStorageClassesEventsSearchGetRequest {
    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1StorageStorageClassesEventsSearchGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1StorageStorageClassesEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1StorageStorageClassesEventsSearchGet
    */
    readonly provisioner?: string

    /**
    * 
    * @type {Array<'id' | 'provisioner' | 'account' | 'createdAt' | 'updatedAt' | 'eventTime'>}
    * @memberof EventsApiApiV1StorageStorageClassesEventsSearchGet
    */
    readonly fields?: Array<'id' | 'provisioner' | 'account' | 'createdAt' | 'updatedAt' | 'eventTime'>
}

/**
* Request parameters for apiV1WorkloadsCronJobsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsCronJobsEventsSearchGetRequest
*/
export interface EventsApiApiV1WorkloadsCronJobsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'Create' | 'Update' | 'Delete'>}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly actions?: Array<'Create' | 'Update' | 'Delete'>

    /**
    * 
    * @type {Array<'id' | 'newObj'>}
    * @memberof EventsApiApiV1WorkloadsCronJobsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'newObj'>
}

/**
* Request parameters for apiV1WorkloadsDeletedPodsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsDeletedPodsEventsIdGetRequest
*/
export interface EventsApiApiV1WorkloadsDeletedPodsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly ownerKomodorUid?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly resourceOwnerUid?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly clusterName?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly namespace?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly nodeNames?: Array<string>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly getIps?: boolean

    /**
    * 
    * @type {Array<'id' | 'nodeName' | 'eventTime' | 'createdAt' | 'podName' | 'namespace' | 'labels' | 'phase' | 'statusReason' | 'containerStatuses' | 'resourceOwnerName' | 'readinessGates' | 'resourcesLimits' | 'resourcesRequests' | 'clusterName' | 'metadata' | 'spec' | 'status'>}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly fields?: Array<'id' | 'nodeName' | 'eventTime' | 'createdAt' | 'podName' | 'namespace' | 'labels' | 'phase' | 'statusReason' | 'containerStatuses' | 'resourceOwnerName' | 'readinessGates' | 'resourcesLimits' | 'resourcesRequests' | 'clusterName' | 'metadata' | 'spec' | 'status'>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsIdGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkloadsDeletedPodsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest
*/
export interface EventsApiApiV1WorkloadsDeletedPodsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly ownerKomodorUid?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly resourceOwnerUid?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly clusterName?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly namespace?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly nodeNames?: Array<string>

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly getIps?: boolean

    /**
    * 
    * @type {Array<'id' | 'nodeName' | 'eventTime' | 'createdAt' | 'podName' | 'namespace' | 'labels' | 'phase' | 'statusReason' | 'containerStatuses' | 'resourceOwnerName' | 'readinessGates' | 'resourcesLimits' | 'resourcesRequests' | 'clusterName' | 'metadata' | 'spec' | 'status'>}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'nodeName' | 'eventTime' | 'createdAt' | 'podName' | 'namespace' | 'labels' | 'phase' | 'statusReason' | 'containerStatuses' | 'resourceOwnerName' | 'readinessGates' | 'resourcesLimits' | 'resourcesRequests' | 'clusterName' | 'metadata' | 'spec' | 'status'>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly statusReason?: string

    /**
    * A JSON object to filter the results by metadata fields. For example, you can filter by specific keys and values in the &#x60;metadata&#x60; field. The JSON object can contain top-level keys or nested keys, such as labels. Example:   &#x60;{\&quot;uid\&quot;: \&quot;3314bc00-2a8d-4a74-89d8-602c0f177e7a\&quot;, \&quot;labels\&quot;: {\&quot;app.komodor.com/WorkflowDagId\&quot;: \&quot;my-dag\&quot;}}&#x60; 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly metadata?: string

    /**
    * A JSON object to filter the results by labels fields. For example, you can filter by specific keys and values in the &#x60;metadata&#x60; field. The JSON object can contain top-level keys or nested keys, such as labels. Example:   &#x60;{\&quot;app.komodor.com/WorkflowDagId\&quot;: \&quot;my-dag\&quot;}&#x60; 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsDeletedPodsEventsSearchGet
    */
    readonly labels?: string
}

/**
* Request parameters for apiV1WorkloadsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsEventsIdGetRequest
*/
export interface EventsApiApiV1WorkloadsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'agentId' | 'action' | 'createdAt' | 'updatedAt' | 'eventTime' | 'clusterName' | 'namespace' | 'resourceName' | 'kind' | 'services' | 'readyReplicas' | 'desiredReplicas' | 'selector' | 'generation' | 'resourceVersion' | 'creationTimestamp'>}
    * @memberof EventsApiApiV1WorkloadsEventsIdGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'agentId' | 'action' | 'createdAt' | 'updatedAt' | 'eventTime' | 'clusterName' | 'namespace' | 'resourceName' | 'kind' | 'services' | 'readyReplicas' | 'desiredReplicas' | 'selector' | 'generation' | 'resourceVersion' | 'creationTimestamp'>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1WorkloadsEventsIdGet
    */
    readonly queryRR?: boolean
}

/**
* Request parameters for apiV1WorkloadsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsEventsSearchGetRequest
*/
export interface EventsApiApiV1WorkloadsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly fromCreationTimestampEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly servicesIds?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'agentId' | 'action' | 'createdAt' | 'updatedAt' | 'eventTime' | 'clusterName' | 'namespace' | 'resourceName' | 'kind' | 'services' | 'readyReplicas' | 'desiredReplicas' | 'selector' | 'generation' | 'resourceVersion' | 'creationTimestamp'>}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'agentId' | 'action' | 'createdAt' | 'updatedAt' | 'eventTime' | 'clusterName' | 'namespace' | 'resourceName' | 'kind' | 'services' | 'readyReplicas' | 'desiredReplicas' | 'selector' | 'generation' | 'resourceVersion' | 'creationTimestamp'>

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly queryRR?: boolean

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly creationTimestampOrder?: 'ASC' | 'DESC'

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly resourceVersionOrder?: 'ASC' | 'DESC'

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly k8sUid?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly clusterName?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsEventsSearchGet
    */
    readonly generation?: number
}

/**
* Request parameters for apiV1WorkloadsJobsEventsIdGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsJobsEventsIdGetRequest
*/
export interface EventsApiApiV1WorkloadsJobsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsJobsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'eventTime' | 'status' | 'cronJobUid' | 'services' | 'action' | 'selector' | 'clusterName' | 'namespace' | 'subEvents' | 'podRestartPolicy' | 'backoffLimit' | 'newObj'>}
    * @memberof EventsApiApiV1WorkloadsJobsEventsIdGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'eventTime' | 'status' | 'cronJobUid' | 'services' | 'action' | 'selector' | 'clusterName' | 'namespace' | 'subEvents' | 'podRestartPolicy' | 'backoffLimit' | 'newObj'>
}

/**
* Request parameters for apiV1WorkloadsJobsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsJobsEventsSearchGetRequest
*/
export interface EventsApiApiV1WorkloadsJobsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly serviceIds?: Array<string>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'uid' | 'name' | 'eventTime' | 'status' | 'cronJobUid' | 'services' | 'action' | 'selector' | 'clusterName' | 'namespace' | 'subEvents' | 'podRestartPolicy' | 'backoffLimit' | 'newObj'>}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'uid' | 'name' | 'eventTime' | 'status' | 'cronJobUid' | 'services' | 'action' | 'selector' | 'clusterName' | 'namespace' | 'subEvents' | 'podRestartPolicy' | 'backoffLimit' | 'newObj'>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly action?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly uid?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly cronJobUid?: string

    /**
    * 
    * @type {Array<'Create' | 'Update' | 'Delete'>}
    * @memberof EventsApiApiV1WorkloadsJobsEventsSearchGet
    */
    readonly actions?: Array<'Create' | 'Update' | 'Delete'>
}

/**
* Request parameters for apiV1WorkloadsPodsEventsSearchGet operation in EventsApi.
* @export
* @interface EventsApiApiV1WorkloadsPodsEventsSearchGetRequest
*/
export interface EventsApiApiV1WorkloadsPodsEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly afterId?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly reason?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly nodeName?: string

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly podUid?: string

    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'services' | 'items' | 'podName' | 'phase' | 'podHealth' | 'statusReason' | 'statusConditions' | 'containerStatus' | 'nodeName' | 'nodeHealthStatus' | 'clusterName' | 'namespace' | 'ready' | 'status'>}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'services' | 'items' | 'podName' | 'phase' | 'podHealth' | 'statusReason' | 'statusConditions' | 'containerStatus' | 'nodeName' | 'nodeHealthStatus' | 'clusterName' | 'namespace' | 'ready' | 'status'>

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly ids?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly ownerUid?: string

    /**
    * 
    * @type {number}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly pvcNamesLengthBiggerThen?: number

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly pvcNamesIncludes?: string

    /**
    * 
    * @type {string}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly phase?: string

    /**
    * 
    * @type {boolean}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly distinctPodName?: boolean

    /**
    * 
    * @type {'ASC' | 'DESC'}
    * @memberof EventsApiApiV1WorkloadsPodsEventsSearchGet
    */
    readonly podNameOrder?: 'ASC' | 'DESC'
}


    
        /**
* 
* @param params EventsTODOApiApiV1EvictedPodsOnNodeEventsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1EvictedPodsOnNodeEventsGetUrl = (params: EventsTODOApiApiV1EvictedPodsOnNodeEventsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/evicted-pods-on-node/events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.nodeName !== undefined) {
            localVarQueryParameter['nodeName'] = params.nodeName;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsTODOApiApiV1NetworkEndpointsEventsIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkEndpointsEventsIdGetUrl = (params: EventsTODOApiApiV1NetworkEndpointsEventsIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1NetworkEndpointsEventsIdGet', 'id', params.id)
    const localVarPath = `/api/v1/network/endpoints/events/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsTODOApiApiV1NetworkEndpointsEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkEndpointsEventsSearchGetUrl = (params: EventsTODOApiApiV1NetworkEndpointsEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/endpoints/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.actions) {
        localVarQueryParameter['actions'] = params.actions.join(COLLECTION_FORMATS.csv);
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkNetworkPoliciesEventsSearchGetUrl = (params: EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/network-policies/events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params EventsTODOApiApiV1PendingPodsOnPvcsEventsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PendingPodsOnPvcsEventsGetUrl = (params: EventsTODOApiApiV1PendingPodsOnPvcsEventsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/pending-pods-on-pvcs/events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.pvcNames) {
        localVarQueryParameter['pvcNames'] = params.pvcNames;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1EvictedPodsOnNodeEventsGet operation in EventsTODOApi.
* @export
* @interface EventsTODOApiApiV1EvictedPodsOnNodeEventsGetRequest
*/
export interface EventsTODOApiApiV1EvictedPodsOnNodeEventsGetRequest {
    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'services' | 'items' | 'podName' | 'phase' | 'podHealth' | 'statusReason' | 'statusConditions' | 'containerStatus' | 'nodeName' | 'nodeHealthStatus' | 'clusterName' | 'namespace' | 'ready' | 'status'>}
    * @memberof EventsTODOApiApiV1EvictedPodsOnNodeEventsGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'services' | 'items' | 'podName' | 'phase' | 'podHealth' | 'statusReason' | 'statusConditions' | 'containerStatus' | 'nodeName' | 'nodeHealthStatus' | 'clusterName' | 'namespace' | 'ready' | 'status'>

    /**
    * 
    * @type {string}
    * @memberof EventsTODOApiApiV1EvictedPodsOnNodeEventsGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsTODOApiApiV1EvictedPodsOnNodeEventsGet
    */
    readonly nodeName?: string
}

/**
* Request parameters for apiV1NetworkEndpointsEventsIdGet operation in EventsTODOApi.
* @export
* @interface EventsTODOApiApiV1NetworkEndpointsEventsIdGetRequest
*/
export interface EventsTODOApiApiV1NetworkEndpointsEventsIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'account' | 'endpointName' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'podNames' | 'action' | 'subsets' | 'metadata'>}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsIdGet
    */
    readonly fields?: Array<'id' | 'account' | 'endpointName' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'podNames' | 'action' | 'subsets' | 'metadata'>
}

/**
* Request parameters for apiV1NetworkEndpointsEventsSearchGet operation in EventsTODOApi.
* @export
* @interface EventsTODOApiApiV1NetworkEndpointsEventsSearchGetRequest
*/
export interface EventsTODOApiApiV1NetworkEndpointsEventsSearchGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {number}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'Create' | 'Update' | 'Delete'>}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsSearchGet
    */
    readonly actions?: Array<'Create' | 'Update' | 'Delete'>

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'account' | 'endpointName' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'podNames' | 'action' | 'subsets' | 'metadata'>}
    * @memberof EventsTODOApiApiV1NetworkEndpointsEventsSearchGet
    */
    readonly fields?: Array<'id' | 'account' | 'endpointName' | 'clusterName' | 'namespace' | 'createdAt' | 'updatedAt' | 'eventTime' | 'services' | 'podNames' | 'action' | 'subsets' | 'metadata'>
}

/**
* Request parameters for apiV1NetworkNetworkPoliciesEventsSearchGet operation in EventsTODOApi.
* @export
* @interface EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGetRequest
*/
export interface EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {number}
    * @memberof EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>}
    * @memberof EventsTODOApiApiV1NetworkNetworkPoliciesEventsSearchGet
    */
    readonly fields?: Array<'id' | 'name' | 'namespace' | 'clusterName' | 'eventTime' | 'services' | 'newSpec' | 'oldSpec'>
}

/**
* Request parameters for apiV1PendingPodsOnPvcsEventsGet operation in EventsTODOApi.
* @export
* @interface EventsTODOApiApiV1PendingPodsOnPvcsEventsGetRequest
*/
export interface EventsTODOApiApiV1PendingPodsOnPvcsEventsGetRequest {
    /**
    * 
    * @type {Array<'id' | 'eventTime' | 'services' | 'items' | 'podName' | 'phase' | 'podHealth' | 'statusReason' | 'statusConditions' | 'containerStatus' | 'nodeName' | 'nodeHealthStatus' | 'clusterName' | 'namespace' | 'ready' | 'status'>}
    * @memberof EventsTODOApiApiV1PendingPodsOnPvcsEventsGet
    */
    readonly fields?: Array<'id' | 'eventTime' | 'services' | 'items' | 'podName' | 'phase' | 'podHealth' | 'statusReason' | 'statusConditions' | 'containerStatus' | 'nodeName' | 'nodeHealthStatus' | 'clusterName' | 'namespace' | 'ready' | 'status'>

    /**
    * 
    * @type {string}
    * @memberof EventsTODOApiApiV1PendingPodsOnPvcsEventsGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof EventsTODOApiApiV1PendingPodsOnPvcsEventsGet
    */
    readonly pvcNames?: Array<string>
}


    
        /**
* 
* @param params GroupedNativeEventsApiApiV1WorkloadsPodsGroupedNativeEventsSearchPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsPodsGroupedNativeEventsSearchPostUrl = (params: GroupedNativeEventsApiApiV1WorkloadsPodsGroupedNativeEventsSearchPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/pods/grouped-native-events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1WorkloadsPodsGroupedNativeEventsSearchPost operation in GroupedNativeEventsApi.
* @export
* @interface GroupedNativeEventsApiApiV1WorkloadsPodsGroupedNativeEventsSearchPostRequest
*/
export interface GroupedNativeEventsApiApiV1WorkloadsPodsGroupedNativeEventsSearchPostRequest {
    /**
    * 
    * @type {GroupedPodEventsRequest}
    * @memberof GroupedNativeEventsApiApiV1WorkloadsPodsGroupedNativeEventsSearchPost
    */
    readonly groupedPodEventsRequest?: GroupedPodEventsRequest
}


    
        /**
* 
* @param params HelmApiApiV1HelmChartIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HelmChartIdGetUrl = (params: HelmApiApiV1HelmChartIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1HelmChartIdGet', 'id', params.id)
    const localVarPath = `/api/v1/helm/chart/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params HelmApiApiV1HelmChartRevisionsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HelmChartRevisionsGetUrl = (params: HelmApiApiV1HelmChartRevisionsGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1HelmChartRevisionsGet', 'clusterName', params.clusterName)
    // verify required parameter 'namespace' is not null or undefined
    assertParamExists('apiV1HelmChartRevisionsGet', 'namespace', params.namespace)
    // verify required parameter 'name' is not null or undefined
    assertParamExists('apiV1HelmChartRevisionsGet', 'name', params.name)
    const localVarPath = `/api/v1/helm/chart/revisions`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params HelmApiApiV1HelmChartsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HelmChartsGetUrl = (params: HelmApiApiV1HelmChartsGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterName' is not null or undefined
    assertParamExists('apiV1HelmChartsGet', 'clusterName', params.clusterName)
    const localVarPath = `/api/v1/helm/charts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.name !== undefined) {
            localVarQueryParameter['name'] = params.name;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1HelmChartIdGet operation in HelmApi.
* @export
* @interface HelmApiApiV1HelmChartIdGetRequest
*/
export interface HelmApiApiV1HelmChartIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof HelmApiApiV1HelmChartIdGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1HelmChartRevisionsGet operation in HelmApi.
* @export
* @interface HelmApiApiV1HelmChartRevisionsGetRequest
*/
export interface HelmApiApiV1HelmChartRevisionsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof HelmApiApiV1HelmChartRevisionsGet
    */
    readonly clusterName: string

    /**
    * 
    * @type {string}
    * @memberof HelmApiApiV1HelmChartRevisionsGet
    */
    readonly namespace: string

    /**
    * 
    * @type {string}
    * @memberof HelmApiApiV1HelmChartRevisionsGet
    */
    readonly name: string
}

/**
* Request parameters for apiV1HelmChartsGet operation in HelmApi.
* @export
* @interface HelmApiApiV1HelmChartsGetRequest
*/
export interface HelmApiApiV1HelmChartsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof HelmApiApiV1HelmChartsGet
    */
    readonly clusterName: string

    /**
    * 
    * @type {string}
    * @memberof HelmApiApiV1HelmChartsGet
    */
    readonly name?: string
}


    
        /**
* 
* @param params HelmReposApiApiV1HelmReposDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HelmReposDeleteUrl = (params: HelmReposApiApiV1HelmReposDeleteRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/helm-repos`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params HelmReposApiApiV1HelmReposGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HelmReposGetUrl = (params: HelmReposApiApiV1HelmReposGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/helm-repos`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName) {
        localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params HelmReposApiApiV1HelmReposPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1HelmReposPostUrl = (params: HelmReposApiApiV1HelmReposPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/helm-repos`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1HelmReposDelete operation in HelmReposApi.
* @export
* @interface HelmReposApiApiV1HelmReposDeleteRequest
*/
export interface HelmReposApiApiV1HelmReposDeleteRequest {
    /**
    * 
    * @type {DeleteHelmRepoRequest}
    * @memberof HelmReposApiApiV1HelmReposDelete
    */
    readonly deleteHelmRepoRequest?: DeleteHelmRepoRequest
}

/**
* Request parameters for apiV1HelmReposGet operation in HelmReposApi.
* @export
* @interface HelmReposApiApiV1HelmReposGetRequest
*/
export interface HelmReposApiApiV1HelmReposGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof HelmReposApiApiV1HelmReposGet
    */
    readonly clusterName?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'clusterName' | 'repoName' | 'repoUrl'>}
    * @memberof HelmReposApiApiV1HelmReposGet
    */
    readonly fields?: Array<'id' | 'clusterName' | 'repoName' | 'repoUrl'>
}

/**
* Request parameters for apiV1HelmReposPost operation in HelmReposApi.
* @export
* @interface HelmReposApiApiV1HelmReposPostRequest
*/
export interface HelmReposApiApiV1HelmReposPostRequest {
    /**
    * 
    * @type {CreateHelmRepoRequest}
    * @memberof HelmReposApiApiV1HelmReposPost
    */
    readonly createHelmRepoRequest?: CreateHelmRepoRequest
}


    
        /**
* 
* @param params KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesAppViewIdServicesGetUrl = (params: KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1KomodorServicesAppViewIdServicesGet', 'id', params.id)
    const localVarPath = `/api/v1/komodor-services/app-view/:id/services`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.shouldSync !== undefined) {
            localVarQueryParameter['shouldSync'] = params.shouldSync;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesAttributesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesAttributesPostUrl = (params: KomodorServicesApiApiV1KomodorServicesAttributesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/komodor-services/attributes`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesFetchJobsFiltersPostUrl = (params: KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/komodor-services/fetch-jobs-filters`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesIdGetUrl = (params: KomodorServicesApiApiV1KomodorServicesIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1KomodorServicesIdGet', 'id', params.id)
    const localVarPath = `/api/v1/komodor-services/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    if (params.inactive !== undefined) {
            localVarQueryParameter['inactive'] = params.inactive;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesMetadataSearchPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesMetadataSearchPostUrl = (params: KomodorServicesApiApiV1KomodorServicesMetadataSearchPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/komodor-services/metadata/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesPodsMapPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesPodsMapPostUrl = (params: KomodorServicesApiApiV1KomodorServicesPodsMapPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/komodor-services/pods-map`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesRelatedResourcesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesRelatedResourcesPostUrl = (params: KomodorServicesApiApiV1KomodorServicesRelatedResourcesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/komodor-services/related-resources`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params KomodorServicesApiApiV1KomodorServicesSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1KomodorServicesSearchGetUrl = (params: KomodorServicesApiApiV1KomodorServicesSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/komodor-services/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields;
    }
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespaces) {
        localVarQueryParameter['namespaces'] = params.namespaces;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.healthStatus) {
        localVarQueryParameter['healthStatus'] = params.healthStatus;
    }
    if (params.jobState) {
        localVarQueryParameter['jobState'] = params.jobState;
    }
    if (params.deploymentStatus) {
        localVarQueryParameter['deploymentStatus'] = params.deploymentStatus;
    }
    if (params.type) {
        localVarQueryParameter['type'] = params.type;
    }
    if (params.servicesIds) {
        localVarQueryParameter['servicesIds'] = params.servicesIds;
    }
    if (params.serviceDisplayNames) {
        localVarQueryParameter['serviceDisplayNames'] = params.serviceDisplayNames;
    }
    if (params.deletedFromEpoch !== undefined) {
            localVarQueryParameter['deletedFromEpoch'] = params.deletedFromEpoch;
    }
    if (params.deletedToEpoch !== undefined) {
            localVarQueryParameter['deletedToEpoch'] = params.deletedToEpoch;
    }
    if (params.createdAtFromEpoch !== undefined) {
            localVarQueryParameter['createdAtFromEpoch'] = params.createdAtFromEpoch;
    }
    if (params.kind) {
        localVarQueryParameter['kind'] = params.kind;
    }
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    if (params.inactive !== undefined) {
            localVarQueryParameter['inactive'] = params.inactive;
    }
    if (params.updatedAtFromEpoch !== undefined) {
            localVarQueryParameter['updatedAtFromEpoch'] = params.updatedAtFromEpoch;
    }
    if (params.includeDeletedAndInactive !== undefined) {
            localVarQueryParameter['includeDeletedAndInactive'] = params.includeDeletedAndInactive;
    }
    if (params.status !== undefined) {
            localVarQueryParameter['status'] = params.status;
    }
    if (params.labels !== undefined) {
            localVarQueryParameter['labels'] = params.labels;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1KomodorServicesAppViewIdServicesGet operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGetRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGet
    */
    readonly id: string

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesAppViewIdServicesGet
    */
    readonly shouldSync?: boolean
}

/**
* Request parameters for apiV1KomodorServicesAttributesPost operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesAttributesPostRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesAttributesPostRequest {
    /**
    * 
    * @type {KomodorServiceAttributesParams}
    * @memberof KomodorServicesApiApiV1KomodorServicesAttributesPost
    */
    readonly komodorServiceAttributesParams?: KomodorServiceAttributesParams
}

/**
* Request parameters for apiV1KomodorServicesFetchJobsFiltersPost operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPostRequest {
    /**
    * 
    * @type {KomodorServiceFetchJobsFiltersParams}
    * @memberof KomodorServicesApiApiV1KomodorServicesFetchJobsFiltersPost
    */
    readonly komodorServiceFetchJobsFiltersParams?: KomodorServiceFetchJobsFiltersParams
}

/**
* Request parameters for apiV1KomodorServicesIdGet operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesIdGetRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesIdGetRequest {
    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesIdGet
    */
    readonly id: string

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'agentId' | 'inactive' | 'isDeleted' | 'deletedAt' | 'k8sClusterName' | 'namespace' | 'displayName' | 'kind' | 'updatedAt' | 'createdAt' | 'k8s_metadata' | 'k8s_metadata.id' | 'k8s_metadata.updatedAt' | 'k8s_metadata.createdAt' | 'k8s_metadata.annotations' | 'k8s_metadata.labels' | 'k8s_metadata.accountId' | 'k8s_metadata.kind' | 'k8s_metadata.eventTime' | 'k8s_metadata.k8sUid' | 'k8s_metadata.replicaSetName' | 'k8s_metadata.podLabels' | 'k8s_metadata.selector' | 'k8s_metadata.pvcNames' | 'k8s_metadata.phase' | 'k8s_metadata.status' | 'k8s_metadata.schedule' | 'k8s_metadata.jobState' | 'k8s_metadata.resourceVersion' | 'deploy_state' | 'deploy_state.id' | 'deploy_state.updatedAt' | 'deploy_state.createdAt' | 'deploy_state.lastDeployEndTime' | 'deploy_state.lastDeployStartTime' | 'deploy_state.accountId' | 'deploy_state.desiredReplicas' | 'deploy_state.readyReplicas' | 'deploy_state.eventTime' | 'deploy_state.currentNotificationConfig' | 'deploy_state.deploymentStatus' | 'deploy_state.resourceVersion' | 'deploy_state.deploymentStatus' | 'deploy_state.uid' | 'datadog_data' | 'datadog_data.id' | 'datadog_data.updatedAt' | 'datadog_data.createdAt' | 'datadog_data.datadogVersion' | 'datadog_data.datadogEnv' | 'datadog_data.datadogServiceName' | 'datadog_data.accountId' | 'datadog_data.eventTime' | 'health_state' | 'health_state.id' | 'health_state.updatedAt' | 'health_state.createdAt' | 'health_state.accountId' | 'health_state.eventTime' | 'health_state.healthStatus' | 'sentry_data' | 'sentry_data.id' | 'sentry_data.updatedAt' | 'sentry_data.createdAt' | 'sentry_data.sentryEnv' | 'sentry_data.sentryProjectId' | 'sentry_data.sentryServiceName' | 'sentry_data.sentryVersion' | 'sentry_data.accountId' | 'sentry_data.eventTime'>}
    * @memberof KomodorServicesApiApiV1KomodorServicesIdGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'agentId' | 'inactive' | 'isDeleted' | 'deletedAt' | 'k8sClusterName' | 'namespace' | 'displayName' | 'kind' | 'updatedAt' | 'createdAt' | 'k8s_metadata' | 'k8s_metadata.id' | 'k8s_metadata.updatedAt' | 'k8s_metadata.createdAt' | 'k8s_metadata.annotations' | 'k8s_metadata.labels' | 'k8s_metadata.accountId' | 'k8s_metadata.kind' | 'k8s_metadata.eventTime' | 'k8s_metadata.k8sUid' | 'k8s_metadata.replicaSetName' | 'k8s_metadata.podLabels' | 'k8s_metadata.selector' | 'k8s_metadata.pvcNames' | 'k8s_metadata.phase' | 'k8s_metadata.status' | 'k8s_metadata.schedule' | 'k8s_metadata.jobState' | 'k8s_metadata.resourceVersion' | 'deploy_state' | 'deploy_state.id' | 'deploy_state.updatedAt' | 'deploy_state.createdAt' | 'deploy_state.lastDeployEndTime' | 'deploy_state.lastDeployStartTime' | 'deploy_state.accountId' | 'deploy_state.desiredReplicas' | 'deploy_state.readyReplicas' | 'deploy_state.eventTime' | 'deploy_state.currentNotificationConfig' | 'deploy_state.deploymentStatus' | 'deploy_state.resourceVersion' | 'deploy_state.deploymentStatus' | 'deploy_state.uid' | 'datadog_data' | 'datadog_data.id' | 'datadog_data.updatedAt' | 'datadog_data.createdAt' | 'datadog_data.datadogVersion' | 'datadog_data.datadogEnv' | 'datadog_data.datadogServiceName' | 'datadog_data.accountId' | 'datadog_data.eventTime' | 'health_state' | 'health_state.id' | 'health_state.updatedAt' | 'health_state.createdAt' | 'health_state.accountId' | 'health_state.eventTime' | 'health_state.healthStatus' | 'sentry_data' | 'sentry_data.id' | 'sentry_data.updatedAt' | 'sentry_data.createdAt' | 'sentry_data.sentryEnv' | 'sentry_data.sentryProjectId' | 'sentry_data.sentryServiceName' | 'sentry_data.sentryVersion' | 'sentry_data.accountId' | 'sentry_data.eventTime'>

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesIdGet
    */
    readonly isDeleted?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesIdGet
    */
    readonly inactive?: boolean
}

/**
* Request parameters for apiV1KomodorServicesMetadataSearchPost operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesMetadataSearchPostRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesMetadataSearchPostRequest {
    /**
    * 
    * @type {KomodorServiceMetadataParams}
    * @memberof KomodorServicesApiApiV1KomodorServicesMetadataSearchPost
    */
    readonly komodorServiceMetadataParams?: KomodorServiceMetadataParams
}

/**
* Request parameters for apiV1KomodorServicesPodsMapPost operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesPodsMapPostRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesPodsMapPostRequest {
    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesPodsMapPost
    */
    readonly accountId?: string

    /**
    * 
    * @type {KomodorServicePodMapParams}
    * @memberof KomodorServicesApiApiV1KomodorServicesPodsMapPost
    */
    readonly komodorServicePodMapParams?: KomodorServicePodMapParams
}

/**
* Request parameters for apiV1KomodorServicesRelatedResourcesPost operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesRelatedResourcesPostRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesRelatedResourcesPostRequest {
    /**
    * 
    * @type {ApiV1KomodorServicesRelatedResourcesPostRequest}
    * @memberof KomodorServicesApiApiV1KomodorServicesRelatedResourcesPost
    */
    readonly apiV1KomodorServicesRelatedResourcesPostRequest?: ApiV1KomodorServicesRelatedResourcesPostRequest
}

/**
* Request parameters for apiV1KomodorServicesSearchGet operation in KomodorServicesApi.
* @export
* @interface KomodorServicesApiApiV1KomodorServicesSearchGetRequest
*/
export interface KomodorServicesApiApiV1KomodorServicesSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly accountId?: string

    /**
    * 
    * @type {Array<'id' | 'accountId' | 'agentId' | 'inactive' | 'isDeleted' | 'deletedAt' | 'k8sClusterName' | 'namespace' | 'displayName' | 'kind' | 'updatedAt' | 'createdAt' | 'k8s_metadata' | 'k8s_metadata.id' | 'k8s_metadata.updatedAt' | 'k8s_metadata.createdAt' | 'k8s_metadata.annotations' | 'k8s_metadata.labels' | 'k8s_metadata.accountId' | 'k8s_metadata.kind' | 'k8s_metadata.eventTime' | 'k8s_metadata.k8sUid' | 'k8s_metadata.replicaSetName' | 'k8s_metadata.podLabels' | 'k8s_metadata.selector' | 'k8s_metadata.pvcNames' | 'k8s_metadata.phase' | 'k8s_metadata.status' | 'k8s_metadata.schedule' | 'k8s_metadata.jobState' | 'k8s_metadata.resourceVersion' | 'deploy_state' | 'deploy_state.id' | 'deploy_state.updatedAt' | 'deploy_state.createdAt' | 'deploy_state.lastDeployEndTime' | 'deploy_state.lastDeployStartTime' | 'deploy_state.accountId' | 'deploy_state.desiredReplicas' | 'deploy_state.readyReplicas' | 'deploy_state.eventTime' | 'deploy_state.currentNotificationConfig' | 'deploy_state.deploymentStatus' | 'deploy_state.resourceVersion' | 'deploy_state.deploymentStatus' | 'deploy_state.uid' | 'datadog_data' | 'datadog_data.id' | 'datadog_data.updatedAt' | 'datadog_data.createdAt' | 'datadog_data.datadogVersion' | 'datadog_data.datadogEnv' | 'datadog_data.datadogServiceName' | 'datadog_data.accountId' | 'datadog_data.eventTime' | 'health_state' | 'health_state.id' | 'health_state.updatedAt' | 'health_state.createdAt' | 'health_state.accountId' | 'health_state.eventTime' | 'health_state.healthStatus' | 'sentry_data' | 'sentry_data.id' | 'sentry_data.updatedAt' | 'sentry_data.createdAt' | 'sentry_data.sentryEnv' | 'sentry_data.sentryProjectId' | 'sentry_data.sentryServiceName' | 'sentry_data.sentryVersion' | 'sentry_data.accountId' | 'sentry_data.eventTime'>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly fields?: Array<'id' | 'accountId' | 'agentId' | 'inactive' | 'isDeleted' | 'deletedAt' | 'k8sClusterName' | 'namespace' | 'displayName' | 'kind' | 'updatedAt' | 'createdAt' | 'k8s_metadata' | 'k8s_metadata.id' | 'k8s_metadata.updatedAt' | 'k8s_metadata.createdAt' | 'k8s_metadata.annotations' | 'k8s_metadata.labels' | 'k8s_metadata.accountId' | 'k8s_metadata.kind' | 'k8s_metadata.eventTime' | 'k8s_metadata.k8sUid' | 'k8s_metadata.replicaSetName' | 'k8s_metadata.podLabels' | 'k8s_metadata.selector' | 'k8s_metadata.pvcNames' | 'k8s_metadata.phase' | 'k8s_metadata.status' | 'k8s_metadata.schedule' | 'k8s_metadata.jobState' | 'k8s_metadata.resourceVersion' | 'deploy_state' | 'deploy_state.id' | 'deploy_state.updatedAt' | 'deploy_state.createdAt' | 'deploy_state.lastDeployEndTime' | 'deploy_state.lastDeployStartTime' | 'deploy_state.accountId' | 'deploy_state.desiredReplicas' | 'deploy_state.readyReplicas' | 'deploy_state.eventTime' | 'deploy_state.currentNotificationConfig' | 'deploy_state.deploymentStatus' | 'deploy_state.resourceVersion' | 'deploy_state.deploymentStatus' | 'deploy_state.uid' | 'datadog_data' | 'datadog_data.id' | 'datadog_data.updatedAt' | 'datadog_data.createdAt' | 'datadog_data.datadogVersion' | 'datadog_data.datadogEnv' | 'datadog_data.datadogServiceName' | 'datadog_data.accountId' | 'datadog_data.eventTime' | 'health_state' | 'health_state.id' | 'health_state.updatedAt' | 'health_state.createdAt' | 'health_state.accountId' | 'health_state.eventTime' | 'health_state.healthStatus' | 'sentry_data' | 'sentry_data.id' | 'sentry_data.updatedAt' | 'sentry_data.createdAt' | 'sentry_data.sentryEnv' | 'sentry_data.sentryProjectId' | 'sentry_data.sentryServiceName' | 'sentry_data.sentryVersion' | 'sentry_data.accountId' | 'sentry_data.eventTime'>

    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly clusterName?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly namespaces?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<KomodorServiceHealthStatus>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly healthStatus?: Array<KomodorServiceHealthStatus>

    /**
    * 
    * @type {Array<KomodorJobState>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly jobState?: Array<KomodorJobState>

    /**
    * 
    * @type {Array<KomodorServiceDeploymentStatus>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly deploymentStatus?: Array<KomodorServiceDeploymentStatus>

    /**
    * 
    * @type {Array<KomodorServicesAggregationsDataType>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly type?: Array<KomodorServicesAggregationsDataType>

    /**
    * 
    * @type {Array<string>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly servicesIds?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly serviceDisplayNames?: Array<string>

    /**
    * The deleted epoch filters are added as addition to the other filter with or operator, if you only want deleted services you can use the &#x60;isDeleted&#x60; filter
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly deletedFromEpoch?: string

    /**
    * The deleted epoch filters are added as addition to the other filter with or operator, if you only want deleted services you can use the &#x60;isDeleted&#x60; filter
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly deletedToEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly createdAtFromEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly kind?: Array<string>

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly isDeleted?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly inactive?: boolean

    /**
    * 
    * @type {string}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly updatedAtFromEpoch?: string

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly includeDeletedAndInactive?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly status?: boolean

    /**
    * 
    * @type {{ [key: string]: string; }}
    * @memberof KomodorServicesApiApiV1KomodorServicesSearchGet
    */
    readonly labels?: { [key: string]: string; }
}


    
        /**
* The parameters are used in the query with OR logic. For example, the query will search for records where clusterName = clusterName OR namespace = namespace...
* @param params MetadataApiApiV1MetadataExternalLinksGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1MetadataExternalLinksGetUrl = (params: MetadataApiApiV1MetadataExternalLinksGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/metadata/external-links`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterName !== undefined) {
            localVarQueryParameter['clusterName'] = params.clusterName;
    }
    if (params.namespace !== undefined) {
            localVarQueryParameter['namespace'] = params.namespace;
    }
    if (params.serviceId !== undefined) {
            localVarQueryParameter['serviceId'] = params.serviceId;
    }
    if (params.entireAccount !== undefined) {
            localVarQueryParameter['entireAccount'] = params.entireAccount;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1MetadataExternalLinksGet operation in MetadataApi.
* @export
* @interface MetadataApiApiV1MetadataExternalLinksGetRequest
*/
export interface MetadataApiApiV1MetadataExternalLinksGetRequest {
    /**
    * 
    * @type {string}
    * @memberof MetadataApiApiV1MetadataExternalLinksGet
    */
    readonly clusterName?: string

    /**
    * 
    * @type {string}
    * @memberof MetadataApiApiV1MetadataExternalLinksGet
    */
    readonly namespace?: string

    /**
    * 
    * @type {string}
    * @memberof MetadataApiApiV1MetadataExternalLinksGet
    */
    readonly serviceId?: string

    /**
    * 
    * @type {boolean}
    * @memberof MetadataApiApiV1MetadataExternalLinksGet
    */
    readonly entireAccount?: boolean
}


    
        /**
* 
* @param params NativeEventsApiApiV1NativeEventsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NativeEventsGetUrl = (params: NativeEventsApiApiV1NativeEventsGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/native-events`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.names) {
        localVarQueryParameter['names'] = params.names.join(COLLECTION_FORMATS.csv);
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.type !== undefined) {
            localVarQueryParameter['type'] = params.type;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsPodsNativeEventsSearchGetUrl = (params: NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/pods/native-events/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    if (params.serviceIds) {
        localVarQueryParameter['serviceIds'] = params.serviceIds;
    }
    if (params.podUid !== undefined) {
            localVarQueryParameter['podUid'] = params.podUid;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.order !== undefined) {
            localVarQueryParameter['order'] = params.order;
    }
    if (params.fields) {
        localVarQueryParameter['fields'] = params.fields.join(COLLECTION_FORMATS.csv);
    }
    if (params.excludeSynthetic !== undefined) {
            localVarQueryParameter['excludeSynthetic'] = params.excludeSynthetic;
    }
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1NativeEventsGet operation in NativeEventsApi.
* @export
* @interface NativeEventsApiApiV1NativeEventsGetRequest
*/
export interface NativeEventsApiApiV1NativeEventsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly names?: Array<string>

    /**
    * 
    * @type {number}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly limit?: number

    /**
    * 
    * @type {Array<string>}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<'id' | 'updatedAt' | 'createdAt' | 'eventTime' | 'kind' | 'type' | 'message' | 'reason' | 'count' | 'accountId' | 'clusterName' | 'namespace' | 'objectName' | 'metadata' | 'involvedObject' | 'lastObservedTime'>}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly fields?: Array<'id' | 'updatedAt' | 'createdAt' | 'eventTime' | 'kind' | 'type' | 'message' | 'reason' | 'count' | 'accountId' | 'clusterName' | 'namespace' | 'objectName' | 'metadata' | 'involvedObject' | 'lastObservedTime'>

    /**
    * 
    * @type {'Warning' | 'Error'}
    * @memberof NativeEventsApiApiV1NativeEventsGet
    */
    readonly type?: 'Warning' | 'Error'
}

/**
* Request parameters for apiV1WorkloadsPodsNativeEventsSearchGet operation in NativeEventsApi.
* @export
* @interface NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest
*/
export interface NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGetRequest {
    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly komodorUids?: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly serviceIds?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly podUid?: string

    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly afterId?: string

    /**
    * 
    * @type {number}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly limit?: number

    /**
    * if no order was specified, the order will be randomly, the order is by the eventTime field
    * @type {'ASC' | 'DESC'}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly order?: 'ASC' | 'DESC'

    /**
    * 
    * @type {Array<'id' | 'firstTimestamp' | 'lastTimestamp' | 'services' | 'count' | 'message' | 'reason' | 'severityType: type' | 'podName' | 'namespace' | 'clusterName' | 'nodeName' | 'nodeHealthStatus' | 'sourceComponent' | 'sourceHost'>}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly fields?: Array<'id' | 'firstTimestamp' | 'lastTimestamp' | 'services' | 'count' | 'message' | 'reason' | 'severityType: type' | 'podName' | 'namespace' | 'clusterName' | 'nodeName' | 'nodeHealthStatus' | 'sourceComponent' | 'sourceHost'>

    /**
    * 
    * @type {boolean}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly excludeSynthetic?: boolean

    /**
    * 
    * @type {string}
    * @memberof NativeEventsApiApiV1WorkloadsPodsNativeEventsSearchGet
    */
    readonly accountId?: string
}


    
        /**
* 
* @param params NetworkMapApiApiV1NetworkMapsGraphGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkMapsGraphGetUrl = (params: NetworkMapApiApiV1NetworkMapsGraphGetRequest, baseUrl: string): string => {
    // verify required parameter 'clusterNames' is not null or undefined
    assertParamExists('apiV1NetworkMapsGraphGet', 'clusterNames', params.clusterNames)
    const localVarPath = `/api/v1/network-maps/graph`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.clusterNames) {
        localVarQueryParameter['clusterNames'] = params.clusterNames;
    }
    if (params.namespaces) {
        localVarQueryParameter['namespaces'] = params.namespaces;
    }
    if (params.interval !== undefined) {
            localVarQueryParameter['interval'] = params.interval;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.komodorUids) {
        localVarQueryParameter['komodorUids'] = params.komodorUids;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1NetworkMapsGraphGet operation in NetworkMapApi.
* @export
* @interface NetworkMapApiApiV1NetworkMapsGraphGetRequest
*/
export interface NetworkMapApiApiV1NetworkMapsGraphGetRequest {
    /**
    * 
    * @type {Array<string>}
    * @memberof NetworkMapApiApiV1NetworkMapsGraphGet
    */
    readonly clusterNames: Array<string>

    /**
    * 
    * @type {Array<string>}
    * @memberof NetworkMapApiApiV1NetworkMapsGraphGet
    */
    readonly namespaces?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof NetworkMapApiApiV1NetworkMapsGraphGet
    */
    readonly interval?: string

    /**
    * 
    * @type {string}
    * @memberof NetworkMapApiApiV1NetworkMapsGraphGet
    */
    readonly fromEpoch?: string

    /**
    * 
    * @type {string}
    * @memberof NetworkMapApiApiV1NetworkMapsGraphGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {Array<string>}
    * @memberof NetworkMapApiApiV1NetworkMapsGraphGet
    */
    readonly komodorUids?: Array<string>
}


    
        /**
* 
* @param params PodsInfoApiApiV1PodsInfoPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1PodsInfoPostUrl = (params: PodsInfoApiApiV1PodsInfoPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/pods-info`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1PodsInfoPost operation in PodsInfoApi.
* @export
* @interface PodsInfoApiApiV1PodsInfoPostRequest
*/
export interface PodsInfoApiApiV1PodsInfoPostRequest {
    /**
    * 
    * @type {PodsInfoRequest}
    * @memberof PodsInfoApiApiV1PodsInfoPost
    */
    readonly podsInfoRequest?: PodsInfoRequest
}


    
        /**
* 
* @param params StateApiApiV1ConfigurationsHpasPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsHpasPostUrl = (params: StateApiApiV1ConfigurationsHpasPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/hpas`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1CrdsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CrdsPostUrl = (params: StateApiApiV1CrdsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/crds`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1NetworkIngressesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkIngressesPostUrl = (params: StateApiApiV1NetworkIngressesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/ingresses`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1NodesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NodesPostUrl = (params: StateApiApiV1NodesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/nodes`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1WorkloadsArgoRolloutsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsArgoRolloutsPostUrl = (params: StateApiApiV1WorkloadsArgoRolloutsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/argo-rollouts`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1WorkloadsDaemonSetsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsDaemonSetsPostUrl = (params: StateApiApiV1WorkloadsDaemonSetsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/daemon-sets`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1WorkloadsDeploymentsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsDeploymentsPostUrl = (params: StateApiApiV1WorkloadsDeploymentsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/deployments`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1WorkloadsJobsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsJobsPostUrl = (params: StateApiApiV1WorkloadsJobsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/jobs`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1WorkloadsPodsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsPodsPostUrl = (params: StateApiApiV1WorkloadsPodsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/pods`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateApiApiV1WorkloadsStatefulSetsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsStatefulSetsPostUrl = (params: StateApiApiV1WorkloadsStatefulSetsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/stateful-sets`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ConfigurationsHpasPost operation in StateApi.
* @export
* @interface StateApiApiV1ConfigurationsHpasPostRequest
*/
export interface StateApiApiV1ConfigurationsHpasPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1ConfigurationsHpasPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1CrdsPost operation in StateApi.
* @export
* @interface StateApiApiV1CrdsPostRequest
*/
export interface StateApiApiV1CrdsPostRequest {
    /**
    * 
    * @type {CrdRequest}
    * @memberof StateApiApiV1CrdsPost
    */
    readonly crdRequest?: CrdRequest
}

/**
* Request parameters for apiV1NetworkIngressesPost operation in StateApi.
* @export
* @interface StateApiApiV1NetworkIngressesPostRequest
*/
export interface StateApiApiV1NetworkIngressesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1NetworkIngressesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1NodesPost operation in StateApi.
* @export
* @interface StateApiApiV1NodesPostRequest
*/
export interface StateApiApiV1NodesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1NodesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsArgoRolloutsPost operation in StateApi.
* @export
* @interface StateApiApiV1WorkloadsArgoRolloutsPostRequest
*/
export interface StateApiApiV1WorkloadsArgoRolloutsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1WorkloadsArgoRolloutsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsDaemonSetsPost operation in StateApi.
* @export
* @interface StateApiApiV1WorkloadsDaemonSetsPostRequest
*/
export interface StateApiApiV1WorkloadsDaemonSetsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1WorkloadsDaemonSetsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsDeploymentsPost operation in StateApi.
* @export
* @interface StateApiApiV1WorkloadsDeploymentsPostRequest
*/
export interface StateApiApiV1WorkloadsDeploymentsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1WorkloadsDeploymentsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsJobsPost operation in StateApi.
* @export
* @interface StateApiApiV1WorkloadsJobsPostRequest
*/
export interface StateApiApiV1WorkloadsJobsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1WorkloadsJobsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsPodsPost operation in StateApi.
* @export
* @interface StateApiApiV1WorkloadsPodsPostRequest
*/
export interface StateApiApiV1WorkloadsPodsPostRequest {
    /**
    * 
    * @type {string}
    * @memberof StateApiApiV1WorkloadsPodsPost
    */
    readonly accountId?: string

    /**
    * 
    * @type {PodStateRequest}
    * @memberof StateApiApiV1WorkloadsPodsPost
    */
    readonly podStateRequest?: PodStateRequest
}

/**
* Request parameters for apiV1WorkloadsStatefulSetsPost operation in StateApi.
* @export
* @interface StateApiApiV1WorkloadsStatefulSetsPostRequest
*/
export interface StateApiApiV1WorkloadsStatefulSetsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateApiApiV1WorkloadsStatefulSetsPost
    */
    readonly stateRequest?: StateRequest
}


    
        /**
* 
* @param params StateTODOApiApiV1ConfigurationsConfigMapsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsConfigMapsPostUrl = (params: StateTODOApiApiV1ConfigurationsConfigMapsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/config-maps`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1ConfigurationsLimitRangesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsLimitRangesPostUrl = (params: StateTODOApiApiV1ConfigurationsLimitRangesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/limit-ranges`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1ConfigurationsPdbsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsPdbsPostUrl = (params: StateTODOApiApiV1ConfigurationsPdbsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/pdbs`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1ConfigurationsResourceQuotasPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsResourceQuotasPostUrl = (params: StateTODOApiApiV1ConfigurationsResourceQuotasPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/resource-quotas`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1ConfigurationsSecretsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ConfigurationsSecretsPostUrl = (params: StateTODOApiApiV1ConfigurationsSecretsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/configurations/secrets`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1CustomResourcesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1CustomResourcesPostUrl = (params: StateTODOApiApiV1CustomResourcesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/custom-resources`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1NetworkEndpointsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkEndpointsPostUrl = (params: StateTODOApiApiV1NetworkEndpointsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/endpoints`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1NetworkEndpointsSlicesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkEndpointsSlicesPostUrl = (params: StateTODOApiApiV1NetworkEndpointsSlicesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/endpoints-slices`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1NetworkNetworkPoliciesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkNetworkPoliciesPostUrl = (params: StateTODOApiApiV1NetworkNetworkPoliciesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/network-policies`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1NetworkServicesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1NetworkServicesPostUrl = (params: StateTODOApiApiV1NetworkServicesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/network/services`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1StoragePvcsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StoragePvcsPostUrl = (params: StateTODOApiApiV1StoragePvcsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/storage/pvcs`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1StoragePvsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StoragePvsPostUrl = (params: StateTODOApiApiV1StoragePvsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/storage/pvs`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1StorageStorageClassesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StorageStorageClassesPostUrl = (params: StateTODOApiApiV1StorageStorageClassesPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/storage/storage-classes`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1WorkloadsCronJobsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsCronJobsPostUrl = (params: StateTODOApiApiV1WorkloadsCronJobsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/cron-jobs`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StateTODOApiApiV1WorkloadsReplicaSetsPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkloadsReplicaSetsPostUrl = (params: StateTODOApiApiV1WorkloadsReplicaSetsPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workloads/replica-sets`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ConfigurationsConfigMapsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1ConfigurationsConfigMapsPostRequest
*/
export interface StateTODOApiApiV1ConfigurationsConfigMapsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1ConfigurationsConfigMapsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1ConfigurationsLimitRangesPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1ConfigurationsLimitRangesPostRequest
*/
export interface StateTODOApiApiV1ConfigurationsLimitRangesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1ConfigurationsLimitRangesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1ConfigurationsPdbsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1ConfigurationsPdbsPostRequest
*/
export interface StateTODOApiApiV1ConfigurationsPdbsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1ConfigurationsPdbsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1ConfigurationsResourceQuotasPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1ConfigurationsResourceQuotasPostRequest
*/
export interface StateTODOApiApiV1ConfigurationsResourceQuotasPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1ConfigurationsResourceQuotasPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1ConfigurationsSecretsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1ConfigurationsSecretsPostRequest
*/
export interface StateTODOApiApiV1ConfigurationsSecretsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1ConfigurationsSecretsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1CustomResourcesPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1CustomResourcesPostRequest
*/
export interface StateTODOApiApiV1CustomResourcesPostRequest {
    /**
    * 
    * @type {CustomResourceRequest}
    * @memberof StateTODOApiApiV1CustomResourcesPost
    */
    readonly customResourceRequest?: CustomResourceRequest
}

/**
* Request parameters for apiV1NetworkEndpointsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1NetworkEndpointsPostRequest
*/
export interface StateTODOApiApiV1NetworkEndpointsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1NetworkEndpointsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1NetworkEndpointsSlicesPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1NetworkEndpointsSlicesPostRequest
*/
export interface StateTODOApiApiV1NetworkEndpointsSlicesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1NetworkEndpointsSlicesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1NetworkNetworkPoliciesPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1NetworkNetworkPoliciesPostRequest
*/
export interface StateTODOApiApiV1NetworkNetworkPoliciesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1NetworkNetworkPoliciesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1NetworkServicesPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1NetworkServicesPostRequest
*/
export interface StateTODOApiApiV1NetworkServicesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1NetworkServicesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1StoragePvcsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1StoragePvcsPostRequest
*/
export interface StateTODOApiApiV1StoragePvcsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1StoragePvcsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1StoragePvsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1StoragePvsPostRequest
*/
export interface StateTODOApiApiV1StoragePvsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1StoragePvsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1StorageStorageClassesPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1StorageStorageClassesPostRequest
*/
export interface StateTODOApiApiV1StorageStorageClassesPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1StorageStorageClassesPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsCronJobsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1WorkloadsCronJobsPostRequest
*/
export interface StateTODOApiApiV1WorkloadsCronJobsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1WorkloadsCronJobsPost
    */
    readonly stateRequest?: StateRequest
}

/**
* Request parameters for apiV1WorkloadsReplicaSetsPost operation in StateTODOApi.
* @export
* @interface StateTODOApiApiV1WorkloadsReplicaSetsPostRequest
*/
export interface StateTODOApiApiV1WorkloadsReplicaSetsPostRequest {
    /**
    * 
    * @type {StateRequest}
    * @memberof StateTODOApiApiV1WorkloadsReplicaSetsPost
    */
    readonly stateRequest?: StateRequest
}


    
        /**
* 
* @param params StatusApiApiV1StatusKomodorServicesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StatusKomodorServicesGetUrl = (params: StatusApiApiV1StatusKomodorServicesGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1StatusKomodorServicesGet', 'fromEpoch', params.fromEpoch)
    const localVarPath = `/api/v1/status/komodor-services`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountIds) {
        localVarQueryParameter['accountIds'] = params.accountIds;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StatusApiApiV1StatusNodesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StatusNodesGetUrl = (params: StatusApiApiV1StatusNodesGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1StatusNodesGet', 'fromEpoch', params.fromEpoch)
    const localVarPath = `/api/v1/status/nodes`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountIds) {
        localVarQueryParameter['accountIds'] = params.accountIds;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params StatusApiApiV1StatusPodsGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1StatusPodsGetUrl = (params: StatusApiApiV1StatusPodsGetRequest, baseUrl: string): string => {
    // verify required parameter 'fromEpoch' is not null or undefined
    assertParamExists('apiV1StatusPodsGet', 'fromEpoch', params.fromEpoch)
    const localVarPath = `/api/v1/status/pods`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountIds) {
        localVarQueryParameter['accountIds'] = params.accountIds;
    }
    if (params.fromEpoch !== undefined) {
            localVarQueryParameter['fromEpoch'] = params.fromEpoch;
    }
    if (params.toEpoch !== undefined) {
            localVarQueryParameter['toEpoch'] = params.toEpoch;
    }
    if (params.limit !== undefined) {
            localVarQueryParameter['limit'] = params.limit;
    }
    if (params.afterId !== undefined) {
            localVarQueryParameter['afterId'] = params.afterId;
    }
    if (params.isDeleted !== undefined) {
            localVarQueryParameter['isDeleted'] = params.isDeleted;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1StatusKomodorServicesGet operation in StatusApi.
* @export
* @interface StatusApiApiV1StatusKomodorServicesGetRequest
*/
export interface StatusApiApiV1StatusKomodorServicesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusKomodorServicesGet
    */
    readonly fromEpoch: string

    /**
    * 
    * @type {Array<string>}
    * @memberof StatusApiApiV1StatusKomodorServicesGet
    */
    readonly accountIds?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusKomodorServicesGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof StatusApiApiV1StatusKomodorServicesGet
    */
    readonly limit?: number

    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusKomodorServicesGet
    */
    readonly afterId?: string
}

/**
* Request parameters for apiV1StatusNodesGet operation in StatusApi.
* @export
* @interface StatusApiApiV1StatusNodesGetRequest
*/
export interface StatusApiApiV1StatusNodesGetRequest {
    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusNodesGet
    */
    readonly fromEpoch: string

    /**
    * 
    * @type {Array<string>}
    * @memberof StatusApiApiV1StatusNodesGet
    */
    readonly accountIds?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusNodesGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof StatusApiApiV1StatusNodesGet
    */
    readonly limit?: number

    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusNodesGet
    */
    readonly afterId?: string
}

/**
* Request parameters for apiV1StatusPodsGet operation in StatusApi.
* @export
* @interface StatusApiApiV1StatusPodsGetRequest
*/
export interface StatusApiApiV1StatusPodsGetRequest {
    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusPodsGet
    */
    readonly fromEpoch: string

    /**
    * 
    * @type {Array<string>}
    * @memberof StatusApiApiV1StatusPodsGet
    */
    readonly accountIds?: Array<string>

    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusPodsGet
    */
    readonly toEpoch?: string

    /**
    * 
    * @type {number}
    * @memberof StatusApiApiV1StatusPodsGet
    */
    readonly limit?: number

    /**
    * 
    * @type {string}
    * @memberof StatusApiApiV1StatusPodsGet
    */
    readonly afterId?: string

    /**
    * 
    * @type {boolean}
    * @memberof StatusApiApiV1StatusPodsGet
    */
    readonly isDeleted?: boolean
}


    
