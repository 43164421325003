import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  Account,
  AccountsApiApiV1AccountsGetRequest,
  apiV1AccountsGetUrl,
} from "../../../../../generated/accounts";
import { useAccountsApiClient } from "../apiClient";

const ACCOUNTS_PATH = "/api/v1/accounts";

const getAccounts = async (
  apiClient: AxiosInstance,
  params: AccountsApiApiV1AccountsGetRequest
): Promise<Account[]> => {
  const { data } = await apiClient.get(
    apiV1AccountsGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useListAccounts = ({
  enabled,
  params,
}: {
  enabled?: boolean;
  params: AccountsApiApiV1AccountsGetRequest;
}) => {
  const apiClient = useAccountsApiClient();
  return useQuery([ACCOUNTS_PATH], () => getAccounts(apiClient, params), {
    enabled,
  });
};
