import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { useDebouncedCallback } from "use-debounce";
import Typography from "@mui/material/Typography";

import { useCalculateGroupedEventsPosition } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/CascadingFailure/TimelineEvents/hooks/timelineEventsHooks";
import { useWindowResize } from "@/shared/hooks/useWindowResize";

const Container = styled.div`
  position: absolute;
  background-color: ${muiColors.pink[50]};
  height: 120px;
  z-index: -1;
  border-radius: 8px;
  top: 50%;
`;

const GroupTitle = styled(Typography)`
  && {
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }
`;

type GroupBackgroundProps = {
  containerRef: React.RefObject<HTMLDivElement>;
};

export const GroupBackground: React.FC<GroupBackgroundProps> = ({
  containerRef,
}) => {
  const { absoluteContainerX, absoluteContainerWidth, calculateGroupedEvents } =
    useCalculateGroupedEventsPosition(containerRef);

  const { callback: debouncedCalculateGroupedEvents } = useDebouncedCallback(
    calculateGroupedEvents,
    100
  );
  useWindowResize(debouncedCalculateGroupedEvents);

  return (
    <Container
      style={{
        transform: `translate(${absoluteContainerX}, -50%)`,
        width: absoluteContainerWidth,
      }}
    >
      <GroupTitle variant={"body3"}>Grouped issues</GroupTitle>
    </Container>
  );
};
