import { useEffect, useMemo } from "react";
import semver from "semver";

import { ConfigurationType } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import useAgents from "../../../shared/hooks/useAgents";
import { REQUIRED_AGENT_VERSION_RESOURCE_LIST } from "../../../shared/utils/agent/agent";
import { useWorkspaces } from "../../workspaces/WorkspacesTopBar/hooks";
import {
  ClusterWorkspace,
  ClustersGroupWorkspace,
  Workspace,
} from "../../../generated/workspacesApi";

import { storedLastCluster } from "./localStorage";

function lookForProductionCluster(clustersNames: string[]): string | null {
  const productionNicknames = ["production", "prod"];
  let productionClusterName = null;
  productionNicknames.forEach((pn) => {
    const clusterName = clustersNames.find((c) => c.startsWith(pn));
    if (clusterName) {
      productionClusterName = clusterName;
      return;
    }
  });
  return productionClusterName;
}

export interface AgentInfoToDefaultClusterName {
  clusterName: string;
  configuration: unknown;
}

export function getDefaultClusterName(
  activeAgents: AgentInfoToDefaultClusterName[] | null,
  clusterNameFromStorage: string | null,
  clusterNameFromWorkspace: string | null
): string | null {
  if (!activeAgents || !activeAgents.length) return null;

  const clusterName = clusterNameFromWorkspace || clusterNameFromStorage;
  if (clusterName && activeAgents.some((a) => a.clusterName === clusterName)) {
    return clusterName;
  }

  const supportedClustersNames = activeAgents
    .filter((activeAgent) =>
      ConfigurationType.guard(activeAgent.configuration)
        ? semver.gte(
            activeAgent.configuration?.appmetadata?.version ?? "0.0.0",
            REQUIRED_AGENT_VERSION_RESOURCE_LIST
          )
        : false
    )
    .map((c) => c.clusterName);

  const productionCluster = lookForProductionCluster(supportedClustersNames);
  if (productionCluster) return productionCluster;
  if (supportedClustersNames.length) return supportedClustersNames[0];
  return activeAgents[0].clusterName;
}

export default (): string | null => {
  const agents = useAgents();
  const activeAgents = useMemo(() => {
    if (!agents || !agents.activeAgents.length) return null;
    return agents.activeAgents;
  }, [agents]);

  const clusterNameFromStorage = storedLastCluster.get();
  const {
    currentWorkspace,
    isLoading,
    isClusterWorkspace,
    isClustersGroupWorkspace,
  } = useWorkspaces();
  const defaultCluster = useMemo(() => {
    let workspaceClusterName = null;
    if (!isLoading && isClusterWorkspace && currentWorkspace) {
      workspaceClusterName =
        getOnlyClusterFromClusterWorkspace(currentWorkspace);
    }

    if (!isLoading && isClustersGroupWorkspace && currentWorkspace) {
      workspaceClusterName = getFirstValidClusterFromClusterGroupWorkspace(
        clusterNameFromStorage,
        currentWorkspace
      );
    }

    return getDefaultClusterName(
      activeAgents,
      clusterNameFromStorage,
      workspaceClusterName
    );
  }, [
    activeAgents,
    clusterNameFromStorage,
    currentWorkspace,
    isClusterWorkspace,
    isClustersGroupWorkspace,
    isLoading,
  ]);

  useEffect(() => {
    if (defaultCluster) {
      storedLastCluster.set(defaultCluster);
    }
  }, [defaultCluster]);

  return defaultCluster;
};

function getOnlyClusterFromClusterWorkspace(workspace: Workspace) {
  return (workspace.value as ClusterWorkspace).clusterName;
}

function getFirstValidClusterFromClusterGroupWorkspace(
  clusterNameFromStorage: string | null,
  currentWorkspace: Workspace
) {
  const clusters = (currentWorkspace.value as ClustersGroupWorkspace).clusters;
  if (!clusters || !clusters.length) {
    return null;
  }

  if (!clusterNameFromStorage || !clusters.includes(clusterNameFromStorage)) {
    return clusters[0];
  }

  return null;
}
