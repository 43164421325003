import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";

import { APIKey } from "../../../generated/auth";
import { useDeleteApiKey } from "../../../shared/hooks/auth-service/client/apiKeys/useDeleteApiKey";

const DeleteKeyModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  apiKey: APIKey;
  refreshKeys: () => void;
}> = ({ open, handleClose, apiKey, refreshKeys }) => {
  const { mutateAsync: deleteRole, isLoading: deleteLoading } =
    useDeleteApiKey();

  const handleDelete = async () => {
    await deleteRole({ id: apiKey.id });
    refreshKeys();
    handleClose();
  };

  return (
    <Modal isOpen={open} onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Delete API Key</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          Are you sure you want to delete this key?
        </Typography>
        <br />
        <br />
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          disabled={deleteLoading}
        >
          {deleteLoading ? "Deleting..." : "Delete"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteKeyModal;
