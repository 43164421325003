import { ServiceIdentifierType } from "komodor-types";
import { useMemo } from "react";

import useKomodorServices from "../../../../../../../../shared/hooks/useKomodorServices";
import { Dictionary } from "../../../../../../../../shared/types/Dictionary";

type AggregatedServiceProperties = {
  namespaces: Set<string>;
  labels: Dictionary<Set<string>>;
  annotations: Dictionary<Set<string>>;
};

type ServiceProperty = keyof AggregatedServiceProperties;
const servicePropertyToServiceIdentifierType: Record<
  ServiceProperty,
  ServiceIdentifierType
> = {
  namespaces: ServiceIdentifierType.NAMESPACE,
  labels: ServiceIdentifierType.LABEL,
  annotations: ServiceIdentifierType.ANNOTATION,
};

export const getServiceIdentifierTypeBy = (
  serviceProperty: string
): ServiceIdentifierType => {
  const key = serviceProperty as ServiceProperty;
  return servicePropertyToServiceIdentifierType[key];
};

export const useGetAggregatedServicesScopes = ():
  | AggregatedServiceProperties
  | undefined => {
  const fetchedServices = useKomodorServices().unscopedServices;
  return useMemo(() => {
    const aggregatedServiceProperties: AggregatedServiceProperties = {
      namespaces: new Set(),
      labels: {},
      annotations: {},
    };

    return fetchedServices?.reduce((acc, curr) => {
      /** TODO: add support for future service properties such as annotations */
      curr.env && acc.namespaces.add(curr.env);

      if (curr.labels) {
        Object.entries(curr.labels).forEach(([key, value]) => {
          if (!acc.labels[key]) {
            acc.labels[key] = new Set();
          }

          acc.labels[key].add(value);
        });
      }

      return acc;
    }, aggregatedServiceProperties);
  }, [fetchedServices]);
};
