import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { ActionMetadataRerunJob, ActionTypes, TaskType } from "komodor-types";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Job } from "kubernetes-types/batch/v1.d";

import {
  AnalyticEvents,
  SegmentIntegrations,
} from "../../../../shared/config/analyticsEvents";
import { extractPathFromLocation, omitFields } from "../../common/utils";
import { ErrorMessage } from "../../common/ErrorMessage";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import useAnalyticsApi from "../../../../shared/context/analyticsProvider";
import {
  ActionSentMessage,
  useShouldShowActionSentMessage,
} from "../../common/useActionSent";
import { DisabledRunButton } from "../../common/DisabledRunButton";
import {
  buildKomodorUid,
  Json,
} from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useResourcesAPIPost } from "../../../../shared/hooks/resources-api/client";
import { jsonToYaml } from "../../../../shared/utils/yaml/yaml";

interface RerunJobModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  afterEffect?: () => void;
}

const TIME_TO_WAIT_BEFORE_CLEARING_RESULT = 5000; // 5 seconds

const JobRerunModal: React.FC<RerunJobModalProps> = ({
  isModalOpen,
  handleClose,
  resourceId,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const location = useLocation();

  const uid = buildKomodorUid({
    kind: "Job",
    clusterName: cluster,
    namespace: namespace,
    resourceName: resourceName,
  });

  const { data: dataFromAPI } = useResourcesAPIPost("/workloads/jobs", {
    komodorUids: [uid],
    agentId: agentId,
    fields: [Json],
  });

  const jobObj = dataFromAPI?.data?.json?.[0] as Job;

  const spec: string = useMemo(() => {
    let stringifiedJsonValue;
    let o = omitFields(jobObj);

    _.update(
      o,
      "metadata.annotations['komodor.manual-actions.job-rerun']",
      () => `true`
    );

    try {
      o = JSON.parse(JSON.stringify(o));
      stringifiedJsonValue = jsonToYaml(JSON.stringify(o));
    } catch (err) {
      stringifiedJsonValue = "";
    }
    return stringifiedJsonValue;
  }, [jobObj]);

  const metadata: ActionMetadataRerunJob = {
    namespace,
    cluster,
    serviceId: resourceId,
    type: ActionTypes.JobRerun,
    resourceType,
    resourceName,
    data: {
      newJson: spec,
    },
  };

  const { execute, result, deniedObject, isFetching, resetAgentTask } =
    useAgentTask(agentId, TaskType.ACTION_COMMAND, metadata);

  const shouldShowActionSentMessage =
    useShouldShowActionSentMessage(isFetching);

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  const analytics = useAnalyticsApi();
  const executeAndReport = () => {
    setShowErrorMessage(false);
    execute();
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: "jobRerun",
        path: extractPathFromLocation(location),
        resourceType,
      },
      true,
      false,
      [SegmentIntegrations.Hubspot]
    );
  };

  useEffect(() => {
    let timeoutID: NodeJS.Timeout;

    if (result && !deniedObject) {
      afterEffect?.();
      handleClose();

      timeoutID = setTimeout(() => {
        resetAgentTask(); // this will allow to reinvoke the action
        setActionSent(false);
      }, TIME_TO_WAIT_BEFORE_CLEARING_RESULT);
    }

    return () => {
      timeoutID && clearTimeout(timeoutID);
    };
  }, [afterEffect, deniedObject, handleClose, result, resetAgentTask]);

  useEffect(() => {
    if (deniedObject) {
      setActionSent(false);
      setShowErrorMessage(true);
    }
  }, [deniedObject]);

  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }
  const btnText = `Run ${actionSent ? "..." : ""}`;

  return (
    <Modal isOpen={isModalOpen} width="25rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">Rerun {resourceName}</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          When triggered, this action will delete this current job configuration
          and create a replacement with the same name.
          <br />
        </Typography>
        {showErrorMessage && deniedObject !== undefined && (
          <ErrorMessage reason={deniedObject} />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        {jobObj && spec ? (
          <Button
            variant="primary"
            size="small"
            disabled={actionSent}
            onClick={() => {
              executeAndReport();
              setActionSent(true);
            }}
          >
            {btnText}
          </Button>
        ) : (
          <DisabledRunButton text={btnText} />
        )}
      </ModalActions>
    </Modal>
  );
};

export default JobRerunModal;
