import React from "react";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { HeaderTab, LinkTab, LockedTab } from "./components";
import { MenuItem, isPage } from "../types";
import { muiTheme } from "../../../../themes";

const TABS_CONTAINER_WIDTH = "192px";

export const VerticalTabsMenu: React.FC<{
  title: string;
  items: MenuItem[];
  currentTab: string | boolean;
  linkComponent: React.ElementType;
  children?: React.ReactNode;
  height?: string;
}> = ({
  title,
  items,
  currentTab,
  children,
  linkComponent,
  height = "100%",
}) => {
  const hasOnlyPages = items.every((item) => isPage(item));
  const tabs = React.useMemo(
    () =>
      items.map((item) => {
        // check if it is a sectioned list or not
        if (isPage(item)) {
          const Component = item.disabled ? LockedTab : LinkTab;
          return (
            <Component
              value={item.route}
              key={item.route}
              linkComponent={linkComponent}
              {...item}
            />
          );
        }

        return [
          // if it is a sectioned list, then render a header tab
          <HeaderTab key={item.title} label={item.title} />,
          // render the pages
          ...item.pages.map((page) => {
            const Component = page.disabled ? LockedTab : LinkTab;
            return (
              <Component
                value={page.route}
                key={page.route}
                linkComponent={linkComponent}
                {...page}
              />
            );
          }),
        ];
      }),
    [items]
  );

  return (
    <Grid
      container
      direction="row"
      columnSpacing={2}
      sx={{ paddingRight: "32px", height }}
      flexWrap={"nowrap"}
    >
      <Grid
        item
        sx={{
          "&&": {
            width: TABS_CONTAINER_WIDTH,
            paddingLeft: 0,
            marginLeft: "16px",
            borderRight: `1px solid ${muiTheme.palette.divider}`,
            height: "100%",
          },
        }}
      >
        <Tabs
          sx={{ paddingTop: hasOnlyPages ? "8px" : "0px" }}
          value={currentTab}
          orientation="vertical"
        >
          {tabs}
        </Tabs>
      </Grid>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item sx={{ padding: "8px 0 24px 0" }}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
