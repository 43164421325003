import React, { useMemo } from "react";
import styled from "styled-components";

import { H4 } from "../../../../../common/typography";
import { gray3, gray5 } from "../../../../../../Colors";
import dropdownArrow from "../../../../../common/controls/Select/dropdownArrow";
import { EventType } from "../../../../../common/enums";

import { Options, Option, ActionableOption } from "./Options";

const Container = styled.div`
  border-top: 1px solid ${gray5};
`;

const Content = styled.div<{ disabled: boolean }>`
  padding: 1rem 0 1rem 1em;
  display: grid;
  grid-template:
    "icon title arrow" auto
    ". subtitle ." auto / min-content auto min-content;
  gap: 0 0.5em;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : undefined)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : undefined)};
  > svg {
    height: 1.25em;
    width: 1.5em;
  }
`;

const Title = styled(H4)`
  cursor: default;
`;

const Arrow = styled.div<{ up: boolean }>`
  ${dropdownArrow};
  transform: ${({ up }) => (up ? "scaleY(-1)" : undefined)};
  align-self: stretch;
`;

const Subtitle = styled.div<{ visible: boolean }>`
  grid-area: subtitle;
  color: ${gray3};
  display: ${({ visible }) => (visible ? null : "none")};
`;

export interface CategoryInfo {
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  options: (Option | ActionableOption)[];
}

export interface Option {
  count: number;
  label: string;
  value: string;
  isSelected: boolean;
  onChange: () => void;
}

export interface ActionableOption extends Option {
  actionButton: JSX.Element;
}

const isActionableOption = (
  object: Option | ActionableOption
): object is ActionableOption =>
  object.count !== undefined &&
  object.label !== undefined &&
  object.value !== undefined &&
  object.isSelected !== undefined &&
  object.onChange !== undefined &&
  (object as ActionableOption).actionButton !== undefined;

const EventFiltersCategory: React.FC<{
  info: CategoryInfo;
  open: boolean;
  onClick: () => void;
}> = ({ info: { title, Icon, options }, open, onClick }) => {
  const summary = useMemo(() => {
    return options
      .filter((o) => o.isSelected)
      .map((o) => o.label)
      .join(", ");
  }, [options]);

  const disabled = options.length === 0;
  const [expanded, subtitle] = useMemo(() => {
    if (disabled) return [false, "No options for this timeframe"];
    if (open) return [true, ""];
    return [false, summary];
  }, [disabled, open, summary]);

  return (
    <Container>
      <Content onClick={onClick} disabled={disabled}>
        <Icon />
        <Title>{title}</Title>
        <Arrow up={expanded} />
        <Subtitle visible={!!subtitle}>{subtitle}</Subtitle>
      </Content>
      <Options open={expanded} onClick={(e) => e.stopPropagation()}>
        {options
          .sort(putOptionLastByValue(EventType.PodEvent))
          .map((option) => {
            const optionProps = {
              key: option.value,
              id: `filters.${title}.${option.value}`,
              fieldName: `${option.label} (${option.count})`,
              disabled:
                !option.isSelected &&
                option.count === 0 &&
                option.label !== "Pod event",
              checked: option.isSelected,
              handleCheckboxChange: option.onChange,
            };

            return isActionableOption(option) ? (
              <ActionableOption
                actionButton={option.actionButton}
                {...optionProps}
              />
            ) : (
              <Option {...optionProps} />
            );
          })}
      </Options>
    </Container>
  );
};

const putOptionLastByValue =
  (valueToBeLast: string) =>
  (option: { value: string }, otherOption: { value: string }) => {
    if (option.value === otherOption.value) return 0;
    if (option.value === valueToBeLast) return 1;
    if (otherOption.value === valueToBeLast) return -1;

    return option.value > otherOption.value ? 1 : -1;
  };

export default EventFiltersCategory;
