import {
  IResourceDrawerByData,
  IResourceDrawerByServiceId,
} from "@/components/ResourceView/types";
import {
  IReliabilityByScope,
  ViolationDrawerProps,
} from "@/components/reliability";
import { ReliabilityPolicyDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/violationDrawerTypes";
import {
  IEventDrawer,
  IEventDrawerById,
} from "@/components/common/ProcessList/types";
import { LiveStateDrawerProps } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerTypes";

export interface DrawerStateBase {
  index: number;
}

export type UrlStates = { [key: string]: unknown };
export interface DrawerUrlState {
  urlStates?: UrlStates;
}

export enum DrawerType {
  ResourceDrawerByData = "ResourceDrawerByData",
  ResourceDrawerByServiceId = "ResourceDrawerByServiceId",
  ViolationsDrawerByScope = "ViolationsDrawerByScope",
  ViolationDrawer = "ViolationDrawer",
  ReliabilityPolicyDrawer = "ReliabilityPolicyDrawer",
  EventDrawer = "EventDrawer",
  EventDrawerById = "EventDrawerById",
  K8sAddonLiveStateDrawer = "K8sAddonLiveStateDrawer",
}

export interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

export type DrawerState = DrawerUrlState & { drawerType: DrawerType };

type DrawerStateByDrawerType<T extends DrawerType, U> = DrawerUrlState & {
  drawerType: T;
} & U;

export type ResourceDrawerByDataType = DrawerStateByDrawerType<
  DrawerType.ResourceDrawerByData,
  IResourceDrawerByData
>;

export type ResourceDrawerByServiceIdType = DrawerStateByDrawerType<
  DrawerType.ResourceDrawerByServiceId,
  IResourceDrawerByServiceId
>;

export type ViolationsDrawerType = DrawerStateByDrawerType<
  DrawerType.ViolationDrawer,
  ViolationDrawerProps
>;

export type ViolationsDrawerByScopeType = DrawerStateByDrawerType<
  DrawerType.ViolationsDrawerByScope,
  IReliabilityByScope
>;

export type ReliabilityPolicyDrawerType = DrawerStateByDrawerType<
  DrawerType.ReliabilityPolicyDrawer,
  ReliabilityPolicyDrawerProps
>;

export type EventDrawerType = DrawerStateByDrawerType<
  DrawerType.EventDrawer,
  IEventDrawer
>;

export type EventDrawerByIdType = DrawerStateByDrawerType<
  DrawerType.EventDrawerById,
  IEventDrawerById
>;

export type K8sAddonLiveStateDrawerType = DrawerStateByDrawerType<
  DrawerType.K8sAddonLiveStateDrawer,
  LiveStateDrawerProps
>;

export type DrawerStateType =
  | (DrawerStateBase & ResourceDrawerByDataType)
  | (DrawerStateBase & ResourceDrawerByServiceIdType)
  | (DrawerStateBase & ViolationsDrawerType)
  | (DrawerStateBase & ViolationsDrawerByScopeType)
  | (DrawerStateBase & ReliabilityPolicyDrawerType)
  | (DrawerStateBase & EventDrawerType)
  | (DrawerStateBase & EventDrawerByIdType)
  | (DrawerStateBase & K8sAddonLiveStateDrawerType);

export type DrawerStatePush =
  | ResourceDrawerByDataType
  | ResourceDrawerByServiceIdType
  | ViolationsDrawerType
  | ViolationsDrawerByScopeType
  | ReliabilityPolicyDrawerType
  | EventDrawerType
  | EventDrawerByIdType
  | K8sAddonLiveStateDrawerType;

export type DrawerStateSet = UrlStates;
