import React from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import WorkflowCheck from "..";
import { BaseWorkflowCheck } from "../../../useWorkflowsRuns";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PVCSpecChangedDetails from "./detailsComponents/PVCSpecChangedDetails";

interface PVCSpec {
  accessModes: string[];
  volumeMode: string;
  volumeName: string;
  resources: {
    limits: { memory?: string; cpu?: string };
    requests: { memory?: string; cpu?: string };
  };
  storageClassName: string;
}
interface PVCSpecChangedOutput {
  changes?: {
    oldSpec?: PVCSpec;
    newSpec?: PVCSpec;
  }[];
}

export default class PVCSpecChangedCheck extends WorkflowCheck {
  readonly output;

  constructor(check: BaseWorkflowCheck) {
    super(check);
    this.title = "PVC Spec Change";
    this.description =
      "Spec changes can sometimes indicate why you reached a pending state. We want to let you know if there are changes.";
    this.action = "We compare between your previous spec to the current one.";
    this.question = "Has the PVC spec changed?";
    this.output = check.output as PVCSpecChangedOutput;
  }

  renderCheckDetails(): JSX.Element {
    return <PVCSpecChangedDetails check={this} />;
  }
}
