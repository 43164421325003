import { noop } from "lodash";
import React from "react";
import styled from "styled-components";

import { blueBrand } from "../../../../../Colors";

const ScopeButton = styled.div<{ checked: boolean; disabled?: boolean }>`
  display: flex;
  gap: 0.3rem;
  padding: 0.4rem;
  padding-left: 0.1rem;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  height: 3.5rem;
  font-weight: normal;
  border: 2px solid
    ${({ checked, disabled }) => (disabled || !checked ? "#dce3ef" : blueBrand)};
  border-radius: 0.375rem;
  width: 100%;
  ${({ disabled }) => disabled && `color: #B3B6BC; pointer-events: none;`}
`;

const MainTextLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-block-end: 0.5rem;
`;

const SecondaryTextLabel = styled.div<{ disabled: boolean }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  ${({ disabled }) => !disabled && "color: #707583"};
`;

const RadioButton = styled.input.attrs({ type: "radio", onChange: noop })`
  cursor: pointer;
`;

const ScopeTypeButton: React.FC<{
  mainText: string;
  subText: string;
  handleScopeChange: (scope: string) => void;
  checked: boolean;
  disabled?: boolean;
}> = ({ mainText, subText, handleScopeChange, checked, disabled = false }) => {
  return (
    <ScopeButton
      checked={checked}
      onClick={() => handleScopeChange(mainText)}
      disabled={disabled}
    >
      <RadioButton disabled={disabled} checked={checked} />
      <div>
        <MainTextLabel>{mainText}</MainTextLabel>
        <SecondaryTextLabel disabled={disabled}>{subText}</SecondaryTextLabel>
      </div>
    </ScopeButton>
  );
};

export default ScopeTypeButton;
