import { useEffect, useState } from "react";

import {
  FetchKomodorServiceQuery,
  Maybe,
  useFetchKomodorServiceQuery,
} from "../../generated/graphql";
import useQueryPolling from "../../shared/hooks/useQueryPolling";
import { useParsedKomodorService } from "../../shared/hooks/resources/useParsedResource";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";

import Resource from "./resources";

const SERVICE_POLLING_INTERVAL = 7500;
const INCLUDED_SERVICES = {
  includeDeletedServices: true,
  includeInactiveServices: true,
} as const;

export const useKomodorServiceAsResourceWithInterval = (
  serviceId: string | undefined,
  expectedKind?: string,
  resultData?: unknown
): [Resource | undefined, boolean] => {
  const [data, isFetching, error] = useQueryPolling(
    useFetchKomodorServiceQuery({
      variables: {
        serviceId,
        ...INCLUDED_SERVICES,
      },
    }),
    SERVICE_POLLING_INTERVAL
  );

  const [resource, komodorService] = useParsedResourceFromData(
    data,
    serviceId,
    resultData
  );

  const cachedResource = useCachedResource(resource);

  if (isUnexpectedKind(expectedKind, komodorService?.kind)) {
    return [undefined, false];
  }
  return [error ? cachedResource : resource, isFetching];
};

export const useKomodorServiceAsResource = (
  serviceId: string | undefined,
  expectedKind?: string,
  resultData?: unknown
): [Resource | undefined, boolean] => {
  const [{ data, fetching, error }] = useFetchKomodorServiceQuery({
    variables: {
      serviceId,
      ...INCLUDED_SERVICES,
    },
  });

  const [resource, komodorService] = useParsedResourceFromData(
    data,
    serviceId,
    resultData
  );

  const cachedResource = useCachedResource(resource);

  if (isUnexpectedKind(expectedKind, komodorService?.kind)) {
    return [undefined, false];
  }

  return [error ? cachedResource : resource, fetching];
};

const useCachedResource = (resource: Resource | undefined) => {
  const [cachedResource, setCachedResource] = useState<Resource | undefined>(
    undefined
  );

  useEffect(() => {
    if (resource) {
      setCachedResource(resource);
    }
  }, [resource]);

  return cachedResource;
};

const isUnexpectedKind = (
  expectedKind: string | undefined,
  kind: Maybe<string> | undefined
) => {
  return expectedKind !== undefined && expectedKind !== kind;
};

const useParsedResourceFromData = (
  data: FetchKomodorServiceQuery | undefined,
  serviceId: string | undefined,
  resultData?: unknown
): [
  Resource | undefined,
  FetchKomodorServiceQuery["komodor_service"][number] | undefined
] => {
  const featureFlags = useOverridableFlags();
  const komodorService = data?.komodor_service.find(
    ({ id }) => id === serviceId
  );

  return [
    useParsedKomodorService(komodorService, featureFlags, resultData),
    komodorService,
  ];
};
